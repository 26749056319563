
import { CheckCircleTwoTone } from '@ant-design/icons';
import FormatUtils from '../../utils/FormatUtils';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import Button from '../button/Button';
import Tag from '../tag/Tag';
import styles from './TaskPopover.scss';
import Colors from '../../constants/Colors';
import TaskService from '../../services/TaskService';

const TaskPopover = ({ task, isTask = false }) => {

    const onComplete = async () => {
        let error;
        if  (isTask) {
            error = (await TaskService.updateTask(task.uuid, {status: 'completed'})).error;
        }

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <Tag color={isTask ? Colors.blueLight : Colors.pinkDark} text={isTask ? 'Task' : 'Milestone'} />
                {
                    isTask && (
                        task?.status !== 'completed' 
                    ? <Button text={'Complete'} onClick={onComplete} small />
                    : <CheckCircleTwoTone twoToneColor={Colors.green} />
                    )
                }
            </div>
            <div className={styles.taskName}>{GenUtils.capitalizeFirstLetter(task.name)}</div>
            <div className={styles.dueDate}>Due:{FormatUtils.getFormattedDateFromTimestamp(task.date)}</div>
        </div>
    )
}

export default TaskPopover;