import { IRole } from './FirmReducer';
import IPermission from '../../interfaces/IPermission';
import { IAddress } from '../../interfaces/IUser';
import FormatUtils from '../../utils/FormatUtils';

export interface ITimesheetsSummaryData {
    startDate: string;
    endDate: string;
    totalHours: number;
}

export interface ITimesheetsSummary {
    thisWeekData?: ITimesheetsSummaryData;
    lastWeekData?: ITimesheetsSummaryData;
    secondLastWeekData?: ITimesheetsSummaryData;
}

export interface ITeamState {
    members: object;
    membersArray: IMember[];
    timesheetsSummary: ITimesheetsSummary | null;
    loading: boolean;
}

export const initialTeamState: ITeamState = {
    members: {},
    membersArray: [],
    loading: false,
    timesheetsSummary: null
};

export interface IMember {
    uuid?: string;
    compensations?: ICompensation;
    firstName?: string;
    lastName?: string;
    firmName?: string;
    firmType?: string;
    dob?: string;
    address?: IAddress;
    email?: string;
    contact?: string;
    permission?: IPermission;
    permissionId?: string;
    office?: string;
    invite?: boolean;
    role?: IRole;
    roleUuid?: string;
    userUuid?: string;
    directReportees?: string[];
    status?: 'active' | 'inactive' | 'invited' | 'not_invited';
    profilePicFileId?: string;
    profilePicUrl?: string;
}

export interface ICompensation {
    hoursPerWeek: number;
    startAt: string;
    type: 'hourly' | 'salary';
    amount: number;
    teamMemberId?: string;
}

function mutateTimesheetsSummaryData(payload: any): ITimesheetsSummary {
    if (!payload) return null;
    const { thisWeekData, lastWeekData, secondLastWeekData } = payload;
    return {
        thisWeekData: {
            startDate: thisWeekData?.startDate,
            endDate: thisWeekData?.endDate,
            totalHours: FormatUtils.getHoursFromSeconds(thisWeekData?.total_hours)
        },
        lastWeekData: {
            startDate: lastWeekData?.startDate,
            endDate: lastWeekData?.endDate,
            totalHours: FormatUtils.getHoursFromSeconds(lastWeekData?.total_hours)
        },
        secondLastWeekData: {
            startDate: secondLastWeekData?.startDate,
            endDate: secondLastWeekData?.endDate,
            totalHours: FormatUtils.getHoursFromSeconds(secondLastWeekData?.total_hours)
        }
    };
}

const teamReducer = (state: ITeamState, action: { type: string; payload: any; loading: boolean }) => {
    switch (action.type) {
        case 'SET_MEMBER':
            return {
                ...state,
                members: {
                    ...state.members,
                    [action.payload.uuid]: action.payload
                }
            };
        case 'SET_TEAM':
            return {
                ...state,
                ...action.payload
            };
        case 'SET_TEAM_TIMESHEETS_SUMMARY':
            return {
                ...state,
                timesheetsSummary: mutateTimesheetsSummaryData(action.payload),
                loading: action.loading
            };
        default:
            return state || initialTeamState;
    }
};

export default teamReducer;
