import IPayment from '../../interfaces/IPayment';

export interface IPaymentState {
    payments: {
        [key: string]: IPayment;
    };
}

export const initialPaymentState: IPaymentState = {
    payments: {},
};

const paymentReducer = (state: IPaymentState, action: { type: string; payload: IPayment }) => {
    switch (action.type) {
        case 'SET_PAYMENTS':
            return {
                ...state,
                payments: action.payload,
            };
        case 'UPDATE_PAYMENT':
            return {
                ...state,
                payments: {
                    ...state.payments,
                    [action.payload.uuid]: {
                        ...state.payments[action.payload.uuid],
                        ...action.payload,
                    },
                },
            };
        default:
            return state || initialPaymentState;
    }
};

export default paymentReducer;