import { Tag } from 'antd';
import React from 'react';
import Colors from '../../constants/Colors';
import styles from './ProfileMenuItem.scss';

interface IProfileMenuItemComingSoon {
    text: string,
    icon?: any,
    link?: string,
    onClick?: () => void
}

const ProfileMenuItemComingSoon = ({text, icon, link, onClick}: IProfileMenuItemComingSoon) => {
    return (
        <div
            className={styles.container}
            onClick={onClick || (() => {
                window.open(link, '_blank');
            })}
        >
            {
                icon ? (
                    <div className={styles.icon}>
                        {icon}
                    </div>
                ): null
            }
            <div className={styles.text}>{text}</div>
            <Tag className={styles.tag} color='blue'>Coming Soon</Tag>
        </div>
    );
}

export default ProfileMenuItemComingSoon;