import React, { useEffect, useState } from 'react';

import { Button, Checkbox, Col, Divider, Form, Input, Modal, Popconfirm, Row, Switch, Table, TimePicker } from 'antd';
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Colors from '../../constants/Colors';
import OptionService from '../../services/OptionService';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import { EOptionType } from '../../redux/reducers/OptionReducer';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import { getUserDetails } from '../../redux/selectors/UserSelector';
import UserService from '../../services/UserService';
import CallLimiter from '../../utils/CallLimiter';
import moment from 'moment';

const format = 'HH:mm';

const limitedUpdate = new CallLimiter(() => {
}, 3000);

const PersonalInfo = () => {
    const [showCreateModal, setCreateModal] = useState(false);
    const [isUpdating, setUpdating] = useState(false);

    const [reminderEnabled, setReminderEnabled] = useState(false);
    const [timesheetsEnabled, setTimesheetsEnabled] = useState(false);
    const [timesheetLockingDays, setTimesheetLockingDays] = useState(0);
    const [timesheetsLockingTime, setTimesheetLockingTime] = useState('00:00');

    const [name, setName] = useState(null);
    const [isBillable, setIsBillable] = useState(null);

    const activities = useSelector((state: IStore) => state.options.activity);
    const user = useSelector((state: IStore) => getUserDetails(state));

    const dividerClass = 'hp-border-color-black-40 hp-border-color-dark-80';
    const columnClass = 'hp-p1-body hp-text-color-black-100 hp-text-color-dark-0 ';

    useEffect(() => {
        if (user.timesheetsLockEnabled !== timesheetsEnabled) setTimesheetsEnabled(user.timesheetsLockEnabled);
        if (user.timesheetsLockingDays !== timesheetLockingDays) setTimesheetLockingDays(user.timesheetsLockingDays);
        if (user.timesheetsLockingTime !== timesheetsLockingTime) setTimesheetLockingTime(user.timesheetsLockingTime);
        if (user.timesheetsReminder !== reminderEnabled) setReminderEnabled(user.timesheetsReminder);
    }, [user.timesheetsLockEnabled, user.timesheetsLockingDays, user.timesheetsLockingTime, user.timesheetsReminder]);

    const ActivityColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            width: 200,
            render: (text) => <span className={columnClass}>{GenUtils.capitalizeFirstLetter(text)}</span>
        },
        {
            title: 'Is Billable',
            dataIndex: 'billType',
            width: 200,
            render: (type) => <span className={columnClass}>{type === 'billable' ? 'Billable' : 'Non Billable'}</span>
        },
        {
            title: '',
            dataIndex: 'edit',
            width: 100,
            render: (text, data) => (<div>
                <a onClick={() => openEditDialog(data)}>Edit</a>
                <Popconfirm
                    title='Are you sure to delete this Activity?'
                    onConfirm={() => deleteOption(data.uuid)}
                    okText='Yes'
                    cancelText='No'
                >
                    <CloseOutlined style={{ color: 'IndianRed', marginLeft: 20 }} />
                </Popconfirm>
            </div>)
        }
    ];

    const deleteOption = async (id) => {
        const { data, error } = await OptionService.deleteOption(id);
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Delete', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Successfully deleted', <notificationIcon.icon
            style={notificationIcon.style} />);
    };

    const updateOption = async () => {
        const { data, error } = await OptionService.updateOption(isUpdating, {
            name,
            billType: isBillable ? 'billable' : 'non_billable'
        });
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Update', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Successfully updated', <notificationIcon.icon
            style={notificationIcon.style} />);
        setCreateModal(false);
        setUpdating(false);
    };

    const createOption = async () => {
        const { data, error } = await OptionService.createOption({
            type: EOptionType.activity,
            name,
            billType: isBillable ? 'billable' : 'non_billable'
        });
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Create', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Successfully created', <notificationIcon.icon
            style={notificationIcon.style} />);
        setCreateModal(false);
        setUpdating(false);
    };

    const openEditDialog = (item) => {
        setUpdating(item.uuid);
        setName(item.name);
        setIsBillable(item.billType === 'billable');
    };

    const updateValues = async (detailsToUpdate) => {
        const { data, error } = await UserService.updateUser(detailsToUpdate);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Update', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Successfully updated information', <notificationIcon.icon
            style={notificationIcon.style} />);
    };

    limitedUpdate.setAction(updateValues);

    return (
        <div>
            <Modal
                title={isUpdating ? 'Update Item' : 'Create New'}
                width={500}
                centered
                visible={showCreateModal || isUpdating}
                onCancel={() => {
                    setCreateModal(false);
                    setUpdating(false);
                }}
                footer={null}
                closeIcon={
                    <CloseOutlined
                        style={{ color: Colors.red }}
                        className='remix-icon text-color-black-100'
                        size={24} />
                }
            >
                <Form layout='vertical' name='basic' initialValues={{ remember: true }}
                      style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                    <Col
                        span={24}
                        className='hp-profile-content-list hp-mt-8 hp-pb-sm-0'
                        style={{ justifyContent: 'center', padding: '20px' }}
                    >
                        <ul style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
                            <li>
                                <span className='hp-p1-body'>Name</span>
                                <Input
                                    value={name}
                                    style={{ width: 300 }}
                                    onChange={e => setName(e.target.value)}
                                />
                            </li>
                        </ul>
                    </Col>
                    <Col
                        span={24}
                        className='hp-profile-content-list hp-mt-8 hp-pb-sm-0'
                        style={{ justifyContent: 'center', padding: '20px' }}
                    >
                        <ul style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
                            <li>
                                <span className='hp-p1-body'>Is Billable</span>
                                <Checkbox
                                    checked={isBillable}
                                    onChange={e => setIsBillable(e.target.checked)}
                                />
                            </li>
                        </ul>
                    </Col>
                    <Button type='primary' onClick={() => isUpdating ? updateOption() : createOption()}>Save</Button>
                </Form>
            </Modal>

            <Col>
                <h2>TimeSheets</h2>
                <p className='hp-p1-body hp-mb-0'>
                    Manage data locking, reminder emails and list of activities for your timesheets.
                </p>
            </Col>

            <Divider className={dividerClass} />

            <Row align='middle' justify='space-between'>
                <Col>
                    <h3>TimeSheet Locking</h3>
                    <p className='hp-p1-body hp-mb-0'>
                        Manage data locking, reminder emails and list of activities for your timesheets.
                    </p>
                </Col>

            </Row>
            <Row align='middle' style={{ display: 'flex' }}>
                <Switch
                    checked={timesheetsEnabled}
                    onChange={() => {
                        updateValues({ timesheetsLockEnabled: !timesheetsEnabled });
                        setTimesheetsEnabled(!timesheetsEnabled);
                    }}
                />
                <Col sm={20} style={{ margin: '20px' }}>
                    {
                        !timesheetsEnabled
                            ? <p style={{ margin: 0, padding: 0 }}>Timesheets lock disabled</p>
                            : <p style={{ margin: 0, padding: 0 }}>Timesheets lock enabled</p>
                    }
                </Col>
            </Row>
            {
                timesheetsEnabled && <Row>
                    <Form layout='vertical' name='basic' style={{ display: 'flex', margin: '10px' }}>
                        <Form.Item
                            style={{ display: 'flex', flex: 1 }}
                            label={
                                <span className='hp-p1-body hp-mb-0'>
                                    Day(s) after the week ends
                                </span>
                            }
                        >
                            <Input
                                placeholder='2'
                                type='number'
                                style={{ width: 200 }}
                                value={timesheetLockingDays || 0}
                                onChange={e => {
                                    setTimesheetLockingDays(Number(e.target.value));
                                    limitedUpdate.call({ timesheetsLockingDays: Number(e.target.value) });
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            style={{ marginLeft: '10px', display: 'flex', flex: 1 }}
                            label={
                                <span className='hp-p1-body hp-mb-0'>
                                    Time of day
                                </span>
                            }
                        >
                            <TimePicker
                                className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80  hp-border-radius'
                                style={{ width: 200 }}
                                format={format}
                                placeholder={'00:00'}
                                value={timesheetsLockingTime ? (moment().hours(Number(timesheetsLockingTime.split(':')[0])).minutes(Number(timesheetsLockingTime.split(':')[1]))) : null}
                                onChange={(value) => {
                                    setTimesheetLockingTime(value.format('HH:mm'));
                                    updateValues({ timesheetsLockingTime: value.format('HH:mm') });
                                }}
                            />
                        </Form.Item>
                    </Form>
                </Row>
            }
            <Divider className={dividerClass} />
            <Row align='middle' justify='space-between'>
                <Col>
                    <h3>Reminder Email</h3>
                    <p className='hp-p1-body hp-mb-0'>
                        Send automatic reminder emails to your team to fill out their timesheets.
                    </p>

                </Col>

            </Row>
            <Row align='middle'>
                <Switch
                    checked={reminderEnabled}
                    onChange={() => {
                        updateValues({ timesheetsReminder: !reminderEnabled });
                        setReminderEnabled(!reminderEnabled);
                    }}
                />
                <Col sm={20} style={{ margin: '20px' }}>
                    {
                        !reminderEnabled
                            ? <p style={{ margin: 0, padding: 0 }}>Reminder email disabled</p>
                            : <p style={{ margin: 0, padding: 0 }}>Reminder email enabled</p>
                    }
                </Col>
            </Row>

            <Divider className={dividerClass} />

            <Row align='middle' justify='space-between'>
                <Col md={12} span={24}>
                    <h3>Activity list</h3>
                    <p className='hp-p1-body hp-mb-0'>
                        Activities are used in timesheets to categorize project related work like meetings, basic
                        services, administration etc.
                    </p>
                </Col>
            </Row>
            <Button
                type='primary'
                style={{ margin: '20px 0' }}
                onClick={() => {
                    setCreateModal(true);
                }}
                icon={<PlusCircleOutlined style={{ marginRight: 10 }} />}
            >
                Add Activity
            </Button>
            <Table
                className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                columns={ActivityColumns}
                dataSource={activities.map((c) => {
                    return { ...c, key: c.uuid };
                })}
                size='middle'
                pagination={false}
            />
        </div>
    );
};

export default PersonalInfo;
