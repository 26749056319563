const domain = process.env.REACT_APP_DOMAIN;

const baseUrl = `${domain}/api`;

const ApiURLs = {
    // auth
    LOGIN: `${baseUrl}/auth/login`,
    SIGNUP: `${baseUrl}/auth/register`,
    CHECK_AUTH: `${baseUrl}/auth`,
    VERIFY: `${baseUrl}/auth`,
    VERIFICATION_LINK: `${baseUrl}/auth/verify`,
    FORGOT_PASSWORD_LINK: `${baseUrl}/auth/forgot`,
    RESET_PASSWORD: `${baseUrl}/auth/password`,
    ACTIVITY_LOG: `${baseUrl}/login-activity`,
    FETCH_FIRMS: `${baseUrl}/auth/firms`,

    // file
    FILE: `${baseUrl}/file`,

    // user
    GET_USER: `${baseUrl}/user`,
    UPDATE_USER: `${baseUrl}/user`,

    // client
    GET_CLIENT: `${baseUrl}/client`,
    CREATE_CLIENT: `${baseUrl}/client`,
    UPDATE_CLIENT: `${baseUrl}/client`,

    // client contacts
    ADD_CONTACT: `${baseUrl}/contact`,
    UPDATE_CONTACT: `${baseUrl}/contact`,
    DELETE_CONTACT: `${baseUrl}/contact`,

    // team
    GET_TEAM: `${baseUrl}/team`,
    CREATE_TEAM: `${baseUrl}/team`,
    DELETE_MEMBER: `${baseUrl}/team`,
    UPDATE_MEMBER: `${baseUrl}/team`,
    INVITE: `${baseUrl}/team/invite`,
    GET_TEAM_TIMESHEETS: `${baseUrl}/team/timesheets`,

    // team member's compensations
    ADD_COMPENSATION: `${baseUrl}/compensation`,
    STOP_COMPENSATION: `${baseUrl}/compensation`,
    DELETE_COMPENSATION: `${baseUrl}/compensation`,
    UPDATE_COMPENSATION: `${baseUrl}/compensation`,

    // option
    GET_OPTIONS: `${baseUrl}/option`,
    GET_ROLE: `${baseUrl}/option/role`,
    CREATE_ROLE: `${baseUrl}/option`,
    UPDATE_ROLE: `${baseUrl}/option`,
    DELETE_ROLE: `${baseUrl}/option`,

    // permissions
    GET_PERMISSION: `${baseUrl}/permission`,
    CREATE_PERMISSION: `${baseUrl}/permission`,
    UPDATE_PERMISSION: `${baseUrl}/permission`,
    DELETE_PERMISSION: `${baseUrl}/permission`,

    // firm
    GET_FIRM: `${baseUrl}/firm`,
    UPDATE_FIRM: `${baseUrl}/user`,

    // communication
    GET_COMMUNICATION: `${baseUrl}/communication`,
    UPDATE_COMMUNICATION: `${baseUrl}/communication`,
    DELETE_COMMUNICATION: `${baseUrl}/communication`,
    CREATE_COMMUNICATION: `${baseUrl}/communication`,

    // project
    GET_PROJECT: `${baseUrl}/project`,
    GET_PROJECT_FOR_CLIENT: `${baseUrl}/project/client`,
    CREATE_PROJECT: `${baseUrl}/project`,
    UPDATE_PROJECT: `${baseUrl}/project`,
    DELETE_PROJECT: `${baseUrl}/project`,
    CREATE_PROJECT_ROLE: `${baseUrl}/role`,
    UPDATE_PROJECT_ROLE: `${baseUrl}/role`,
    DELETE_PROJECT_ROLE: `${baseUrl}/role`,
    PROJECT_FINANCE: `${baseUrl}/reports/phase`,

    // phase
    GET_PHASE: `${baseUrl}/phase`,
    GET_PHASES_FOR_PROJECT: `${baseUrl}/phase/project`,
    CREATE_PHASE: `${baseUrl}/phase`,

    //consultants
    CREATE_CONSULTANT: `${baseUrl}/consultant`,
    GET_CONSULTANT: `${baseUrl}/consultant`,
    GET_CONSULTANT_FOR_PROJECT: `${baseUrl}/consultant/project`,

    //members
    ADD_MEMBER: `${baseUrl}/member`,
    GET_MEMBER: `${baseUrl}/member`,
    GET_MEMBER_FOR_PHASE: `${baseUrl}/member/phase`,
    UPDATE_PHASE_MEMBER: `${baseUrl}/member`,

    //timesheet allocations
    ADD_ALLOCATION: `${baseUrl}/timesheet/allocation`,
    GET_ALLOCATION: `${baseUrl}/timesheet/allocation`,
    PUT_ALLOCATION: `${baseUrl}/timesheet/allocation`,
    GET_TIMESHEET: `${baseUrl}/timesheet`,
    ADD_TIMESHEET: `${baseUrl}/timesheet`,
    UPDATE_TIMESHEET: `${baseUrl}/timesheet`,
    DELETE_TIMESHEET: `${baseUrl}/timesheet`,
    UPDATE_TIMESHEET_WEEK: `${baseUrl}/timesheet/week`,
    UPDATE_TIMESHEET_ACTIVITY_WEEK: `${baseUrl}/timesheet/activity`,

    ADD_MEMBER_TIMESHEET: `${baseUrl}/timesheet/member`,
    GET_TIME_ENTRIES: `${baseUrl}/timesheet/entries`,
    GET_TIMESHEET_SUBMISSIONS: `${baseUrl}/timesheet/submissions`,
    GET_AUDITS: `${baseUrl}/timesheet/audits`,
    PUT_MEMBER_TIMESHEET: `${baseUrl}/timesheet/status`,
    SUBMIT_TIMESHEET: `${baseUrl}/timesheet/submit`,
    GET_DETAILS: `${baseUrl}/timesheet/details`,

    //paid time off
    GET_PTO: `${baseUrl}/paid-time-off`,

    // expenses
    GET_EXPENSES: `${baseUrl}/expense`,
    CREATE_EXPENSE: `${baseUrl}/expense`,
    UPDATE_EXPENSES: `${baseUrl}/expense`,
    UPDATE_EXPENSE_STATUS: `${baseUrl}/expense/status`,
    DELETE_EXPENSES: `${baseUrl}/expense`,

    //leaves
    GET_LEAVES: `${baseUrl}/leave`,
    APPLY_LEAVE: `${baseUrl}/leave/apply`,

    // Invoice
    CREATE_INVOICE: `${baseUrl}/invoice`,
    FETCH_INVOICE: `${baseUrl}/invoice`,
    UPDATE_INVOICE: `${baseUrl}/invoice`,
    UPDATE_INVOICE_DETAILS: `${baseUrl}/invoice/detail`,
    UPDATE_INVOICE_STATUS: `${baseUrl}/invoice/status`,
    DELETE_INVOICE: `${baseUrl}/invoice`,
    DELETE_INVOICE_DETAILS: `${baseUrl}/invoice/detail`,

    // Tax
    CREATE_TAX: `${baseUrl}/tax`,
    FETCH_TAX: `${baseUrl}/tax`,
    UPDATE_TAX: `${baseUrl}/tax`,
    DELETE_TAX: `${baseUrl}/tax`,

    // Task
    CREATE_TASK: `${baseUrl}/task`,
    FETCH_TASKS: `${baseUrl}/task`,
    UPDATE_TASK: `${baseUrl}/task`,
    DELETE_TASK: `${baseUrl}/task`,

    // Milestone
    CREATE_MILESTONE: `${baseUrl}/milestone`,
    FETCH_MILESTONES: `${baseUrl}/milestone`,
    UPDATE_MILESTONE: `${baseUrl}/milestone`,
    DELETE_MILESTONE: `${baseUrl}/milestone`,

    //Mail
    SEND_MAIL: `${baseUrl}/email`,

    // Reports
    FETCH_REPORT: `${baseUrl}/reports`,
    HIRING_FORECAST: `${baseUrl}/reports/hiring_forecast`,
    GET_UNBILLED_INVOICES: `${baseUrl}/reports/unbilled_invoices`
};

export default ApiURLs;
