import React, { useEffect, useState } from 'react';

import { Col, Divider, Select, Space, Table, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import { getUserDetails } from '../../redux/selectors/UserSelector';
import { useLocation } from 'react-router-dom';
import TeamMemberSelector from '../../components/selector/TeamMemberSelector';
import NavigationUtils from '../../utils/NavigationUtils';
import TeamService from '../../services/TeamService';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import moment from 'moment';

const { Column } = Table;

const { Option } = Select;

const memberDetails = [
    { label: 'MH', name: 'Mark Hamster', key: 1, role: 'Developer' },
    { label: 'JW', name: 'John Watson', key: 2, role: 'Developer' },
    { label: 'BR', name: 'Bucky Roberts', key: 3, role: 'Developer' },
    { label: 'DS', name: 'Danial Scorpion', key: 4, role: 'Designer' },
    { label: 'BD', name: 'Bob Dylan', key: 5, role: 'Developer' },
    { label: 'CD', name: 'Celine Dion', key: 6, role: 'Designer' }
];

interface DataType {
    key: React.Key;
    label: string;
    name: string;
    role: string;
}

const columns: ColumnsType<DataType> = [
    {
        title: 'MEMBER',
        dataIndex: 'label'
    },
    {
        title: '',
        dataIndex: 'name'

    },
    {
        title: 'DEFAULT ROLE',
        dataIndex: 'role'
    },
    {
        title: '',
        key: 'action',
        render: (_, record) => (
            <Space>
                <a>X</a>
            </Space>
        )
    }

];

const data: DataType[] = [
    { label: 'MH', name: 'Mark Hamster', key: 1, role: 'Developer' },
    { label: 'JW', name: 'John Watson', key: 2, role: 'Dev' },
    { label: 'BR', name: 'Bucky Roberts', key: 3, role: 'Developer' }
];


const Access = () => {
    const dividerClass = 'hp-border-color-black-40 hp-border-color-dark-80';
    const [selectedUserId, setSelectedUserId] = useState('');

    const firmId = useSelector((state: IStore) => state?.firm?.uuid);
    const user = useSelector((state: IStore) => getUserDetails(state, selectedUserId));
    const roles = useSelector((state: IStore) => state.options.role);

    const permissions = useSelector((state: IStore) => state.permission?.permissionsArray);

    const [selectedMembers, setSelectedMembers] = useState([]);

    const location = useLocation();
    const { pathname } = location;

    useEffect(() => {
        if (user.directReportees?.length) {
            setSelectedMembers(user.directReportees);
        }
    }, [user]);

    useEffect(() => {
        const pathSplit = pathname.split('/');
        const selectedId = pathSplit[pathSplit.indexOf('team') + 1];
        selectedId && setSelectedUserId(selectedId);
    }, [pathname]);

    const updateUserDefaultRole = async (roleUuid) => {
        const { data, error } = await TeamService.updateUserDetails(user.uuid,
            {
                dob: user.dob || moment().toISOString(),
                roleUuid
            }
        );

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Update', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Default Role updated Successfully', <notificationIcon.icon
            style={notificationIcon.style} />);
    };

    const updateUserDefaultPermission = async (permissionId) => {
        const { data, error } = await TeamService.updateUserDetails(user.uuid,
            {
                dob: user.dob || moment().toISOString(),
                permissionId
            }
        );

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Update', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Default Permission updated Successfully', <notificationIcon.icon
            style={notificationIcon.style} />);
    };

    const updateAssignedMembers = async (directReportees) => {
        const { data, error } = await TeamService.updateUserDetails(user.uuid,
            {
                directReportees: Object.values(directReportees)
            }
        );

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Update', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Assigned Members updated Successfully', <notificationIcon.icon
            style={notificationIcon.style} />);
    };
    const [req, setReq] = useState(true);
    return (
        <div>
            <Col md={15} span={24}>
                <Col>
                    <h2>Role, Permission & Team</h2>
                </Col>
                <p className='hp-p1-body' style={{ padding: '20' }}>
                    Set role and permission, and assign team members to {user.firstName} in the firm
                </p>
            </Col>
            <Divider className={dividerClass} />
            <div>
                <Col md={12} span={24}>
                    <h3>Default Role</h3>
                </Col>
                <p className='hp-p1-body' style={{ padding: '20' }}> Set default role and default billable rate
                    for {user.firstName}.<br />
                    These can be changed for a project in the project planner.</p>
                <Select
                    placeholder='Select'
                    value={user.role?.uuid}
                    className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                    style={{ width: 300 }}
                    onChange={(value) => {
                        if (value === 'add') {
                            NavigationUtils.navigateTo(`/${firmId}/firm-settings/roles`);
                        } else {
                            updateUserDefaultRole(value);
                        }
                    }}
                >
                    {
                        roles.map((role) => <Option key={role.uuid}
                            value={role.uuid}>{`${role.name} - ${role.hourRate}/hour`}</Option>)
                    }
                    <Option key='add' value='add'><Tag><PlusOutlined /> Add a role</Tag></Option>
                </Select>
            </div>
            <Divider className={dividerClass} />
            <div>
                <Col md={12} span={24}>
                    <h3>Permission</h3>
                </Col>
                <p className='hp-p1-body' style={{ padding: '20' }}>Set ResourcePlan account permissions
                    for {user.firstName}</p>
                <Select
                    placeholder='Select'
                    value={user.permissionId}
                    className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                    style={{ width: 300 }}
                    onChange={(value) => {
                        if (value === 'add') {
                            NavigationUtils.navigateTo(`/${firmId}/firm-settings/permissions`);
                        } else {
                            updateUserDefaultPermission(value);
                        }
                    }}
                >
                    {
                        permissions.map((permission) => <Option key={permission.uuid}
                            value={permission.uuid}>{permission.name}</Option>)
                    }
                    <Option key='add' value='add'><Tag><PlusOutlined /> Add a Permission</Tag></Option>
                </Select>
            </div>
            <Divider className={dividerClass} />
            <div>
                <Col md={12} span={24}>
                    <h3>Assigned Team Members</h3>
                </Col>
                <p className='hp-p1-body' style={{ padding: '20' }}>{user.firstName} can manage time sheets, tasks,
                    expenses and communications for below assigned members
                </p>
                <TeamMemberSelector
                    selectedMembers={selectedMembers}
                    setSelectedMembers={(m, insert = true) => {
                        if (insert) {
                            const combinedArray = [...selectedMembers, ...m];
                            const uniqueMembers = Array.from(new Set(combinedArray));
                            setSelectedMembers(uniqueMembers);
                            updateAssignedMembers(uniqueMembers.map(member => member.uuid));
                        }
                        else {
                            setSelectedMembers(m);
                            updateAssignedMembers(m.map(member => member.uuid));
                        }
                    }}
                    isMultiple
                    showSelected
                />
            </div>
        </div>
    );
};

export default Access;
