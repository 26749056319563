export interface ILeavesState {
    leaves: any;
}

export const initialLeavesState: ILeavesState = {
    leaves: {}
};

const leavesReducer = (state: ILeavesState, action: { type: string; payload }) => {
    switch (action.type) {
        case 'SET_LEAVES':
            return {
                ...state,
                leaves: {
                    ...state.leaves,
                    balance: { ...state.leaves.balance, ...action.payload.balance },
                    record: { ...state.leaves.record, ...action.payload.record }
                }
            };
        default:
            return state || initialLeavesState;
    }
};

export default leavesReducer;
