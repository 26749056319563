import GenUtils from '../../utils/GenUtils';

export const setMember = (member) => {
    return {
        type: 'SET_MEMBER',
        payload: member
    };
};

export const setTeam = (team) => {
    return {
        type: 'SET_TEAM',
        payload: {
            members: GenUtils.arrayToIdMap(team),
            membersArray: team
        }
    };
};

export const setTeamTimesheetsSummary = (timesheetSummary, loading) => {
    return {
        type: 'SET_TEAM_TIMESHEETS_SUMMARY',
        payload: timesheetSummary,
        loading: loading
    };
};
