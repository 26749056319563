import { IStore } from '../store';
import IUser from '../../interfaces/IUser';
import GenUtils from '../../utils/GenUtils';
import { IMember } from '../reducers/TeamReducer';

export const getUserDetails = (state: IStore, userUuid?: string): IUser => {
    const loggedInUser = state.user;
    const authInfo = state.auth.info;
    const members = state.team.members;
    const membersArray: IMember[] = state.team.membersArray;

    let details = {} as IMember;
    let userId = userUuid;

    if (GenUtils.isEmpty(userId)) {
        userId = loggedInUser.uuid;
    }

    const isLoggedInUser = userId === loggedInUser.uuid || userId === authInfo.uuid;

    if (members) {
        details = (isLoggedInUser ? membersArray.find(member => member.email === loggedInUser.email): members[userId]) || {};
    }

    return (isLoggedInUser ? { ...loggedInUser, ...authInfo, ...(details || {}), uuid: userId, memberUuid: authInfo?.uuid } : details) || {};
};