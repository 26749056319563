import { PlusCircleFilled } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import LeftTextCell from './LeftTextCell';
import RowItem from './RowItem';
import styles from './styles.module.scss';
import { IStore } from '../../../../redux/store';
import TimeSheetService from '../../../../services/TimeSheetService';
import MomentUtils from '../../../../utils/MomentUtils';
import { useObservable } from '../../../../hooks/Observable';
import { teamMemberId$ } from '../../shared/TimesheetTab';


const Rows = ({ project, weekCount, weekStart, onSave, addActivityRow, deleteTimesheet, updateTimeLogData }) => {
    const { allocation, timesheet: userTimesheet, projectsData } = useSelector((state: IStore) => state.timeLogs);
    const { phaseMember } = allocation;
    const { phasesObj, phaseActivitiesObj } = projectsData;
    const { projects } = userTimesheet;
    const phaseKeys = Object.keys(projects[project.uuid]);
    const state = useSelector((state: IStore) => state);
    const selectedUser = useObservable(teamMemberId$) 

    return (
        <div className={styles.body}>
            {phaseKeys.map((phaseKey) => {
                const phaseMemberId = phasesObj[phaseKey]?.members?.[0];
                const phaseMemberData = phaseMember?.[phaseMemberId];
                const activitiesObj = userTimesheet?.projects[project.uuid][phaseKey];
                const activitiesInPhase = phaseActivitiesObj[phaseKey];

                return (
                    <Row className={styles.borderBottom} key={phaseKey}>
                        <Col span={6}>
                            <LeftTextCell phaseData={phasesObj[phaseKey]} project={project} phaseMemberData={phaseMemberData} />
                        </Col>
                        <Col span={18} className={styles.dataColumn}>
                            {activitiesInPhase?.map((activity, index) => (
                                <RowItem
                                    phaseKey={phaseKey}
                                    project={project}
                                    key={`key-${index}`}
                                    activity={activity}
                                    activitiesObj={activitiesObj}
                                    index={index}
                                    weekStart={weekStart}
                                    activitiesInPhase={activitiesInPhase}
                                    phaseMember={phaseMember}
                                    weekCount={weekCount}
                                    onSave={onSave}
                                    deleteTimesheet={deleteTimesheet}
                                    updateTimeLogData={updateTimeLogData}
                                    phaseMemberId={phaseMemberId}
                                />
                            ))}
                            {!userTimesheet.isLocked && (
                                <div>
                                    <PlusCircleFilled
                                        disabled={userTimesheet.isLocked}
                                        className={styles.plusIcon}
                                        onClick={async () => {
                                            const act_phase = activitiesInPhase.map((a) => a.uuid);
                                            const filtered = state.options.activity.filter((f) => !act_phase.includes(f.uuid));
                                            if (filtered.length > 0 && selectedUser !== null) {
                                                await TimeSheetService.postTimelogData(MomentUtils.getGmtDayStart(weekStart), selectedUser, {
                                                    timesheets: [
                                                        {
                                                            phaseMemberId,
                                                            activityId: filtered[0].uuid,
                                                            date: MomentUtils.getGmtDayStart(weekStart),
                                                            seconds: 0
                                                        }
                                                    ]
                                                });
                                            }
                                        }}
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>
                );
            })}
        </div>
    );
};

export default Rows;
