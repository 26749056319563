import GenUtils from '../../utils/GenUtils';
import { IPhase } from '../../interfaces/IPhase';

export const updateMember = (...phaseDetails) => {
  return {
    type: 'UPDATE_MEMBER',
    payload: [{
      ...phaseDetails,
    }],
  }
}

export const setMembers = (members, phaseId: string) => {
  return {
    type: 'SET_MEMBERS',
    payload: {
      members: GenUtils.arrayToIdMap(members),
      phaseId
    },
  }
}