import { Dropdown } from 'antd';
import React from 'react';
import styles from './TeamAvatar.scss';
import Tag from '../tag/Tag';
import classnames from 'classnames';

const ProjectAvatar = ({ abbreviation = null, name = null, role = null, members = [], image = null, userId = null, isTeamMember = false,serial=null,color='#fff'}) => {



    const goToUser = (_userId) => {
        // todo navigate to the particular user
    };

    const renderTeamListItem = (user, index) => {
        return {
            key: index,
            label: (
                <ProjectAvatar
                    image={require('../../assets/user.png')}
                    userId={user.uuid}
                    name={user.name}
                    role={user.role}
                    abbreviation={user.abbreviation}
                />
            )
        };
    };

    const getTeamMenuItems = (): any[] => {
        return members?.map(renderTeamListItem) || [];
    };

    return members.length > 0 ? (
        <Dropdown menu={{ items: getTeamMenuItems() }} placement='bottomRight'>
            <div className={styles.row} onClick={() => goToUser(userId)}>
                {
                    image
                    && <div style={{ width: 42 }}
                            className='hp-border-radius-xl hp-cursor-pointer hp-border-1 hp-border-color-dark-80'>
                        <div className='hp-border-radius-lg hp-overflow-hidden hp-bg-info-4 hp-m-4 hp-d-flex'
                             style={{ width: 32, height: 32 }}>
                            <img src={image || require('../../assets/user.png')} alt='User'
                                 height='100%' />
                        </div>
                    </div>
                }
                <div className={styles.column}>
                    {abbreviation && <div className={styles.abbreviation}>{role}</div>}
                    {name && <div className={styles.name}>{name}</div>}
                    {role && <div className={styles.role}>{role}</div>}
                </div>
            </div>
        </Dropdown>
    ) : (
        <div className={styles.row} onClick={() => goToUser(userId)}>
            {
                image
                && <div style={{ width: 42 }}
                        className='hp-border-radius-xl hp-cursor-pointer hp-border-1 hp-border-color-dark-80'>
                    <div className='hp-border-radius-lg hp-overflow-hidden hp-bg-info-4 hp-m-4 hp-d-flex'
                         style={{ width: 32, height: 32 }}>
                        <img src={image || require('../../assets/user.png')} alt='User'
                             height='100%' />
                    </div>
                </div>
            }
            <div className={styles.column}>
                {abbreviation && <div className={styles.abbreviation}>{abbreviation}</div>}
                {name && <div className={classnames(styles.name)}> <Tag text={serial} color={color} style={{fontSize:10, marginRight: 5}}/> {name}</div>}
                {role && <div className={styles.role}>{role}</div>}
            </div>
        </div>
    );
};

export default ProjectAvatar;
