import React from 'react';

const HomePage = (): JSX.Element => {
    return (
        <div>
            <h1>This is the Home Page</h1>
        </div>
    )
}

export default HomePage;