import React, { useEffect, useState } from 'react'

import Table from '../../../components/table/custom/Table';
import { useSelector } from 'react-redux';
import CustomInput from '../../../components/input/CustomInput';
import { IInvoiceDetail } from '../../../interfaces/IInvoice';
import { IPhase } from '../../../interfaces/IPhase';
import { IStore } from '../../../redux/store';
import { InvoiceService } from '../../../services/InvoiceService';
import FormatUtils from '../../../utils/FormatUtils';
import GenUtils, { ENotificationType } from '../../../utils/GenUtils';

import styles from './InvoiceStyle.scss';
import Tag from '../../../components/tag/Tag';
import InvoiceUtils from '../../../utils/InvoiceUtils';

function ConsultantServicesTab({ data, projectId }: any) {
    const [_data, setData] = useState(data);
    const project = useSelector((state: IStore) => state.project.projects[projectId]);
    const phases = useSelector((state: IStore) => projectId && state.phases?.projectPhases && state.phases?.projectPhases[projectId] || {})
    const consultantTypes = useSelector((state: IStore) => GenUtils.arrayToIdMap(state.options.consultant));

    useEffect(() => {
        setData(data);
    }, [data]);

    const consultantColumns = [
        {
            name: 'Fixed Fee phase',
            key: 'phase',
            span: 6,
            render: (d) => phases[d.phaseId] ? <PhaseDetail phaseId={d.phaseId} /> : '-',
        },
        {
            name: 'Status',
            key: 'status',
            span: 3,
            render: (d) => d.name === 'Consultant Services Total' ? null : <div style={{ display: 'flex', height: '100%', alignItems: 'flex-start' }}>
                <Tag text={project?.status} color={GenUtils.getTagColorByStatus(project?.status)} />
            </div>,
        },
        {
            name: 'Planned',
            key: 'fee',
            span: 3,
            render: (d) => FormatUtils.getAmountInFormat(d.fee, true, 2, 0),
        },
        {
            name: 'Previously Invoiced',
            key: 'invoiced',
            span: 4,
            render: (d) => `${FormatUtils.getAmountInFormat(d.invoiced, true, 2, '0')} / ${d.fee ? (d.invoiced * 100 / d.fee).toFixed(2) : 0}%`
        },
        {
            name: 'Complete %',
            key: 'due',
            span: 4,
            render: (d) => <CustomInput
                value={d.complete}
                style={{ width: '90%' }}
                updateOnBlur
                suffix='%'
                onChange={complete => updateInvoiceDetail(d.uuid, { complete, amount: ((d.fee * complete) / 100) - d.invoiced })}
            />
        },
        {
            name: 'Amount',
            key: 'due',
            span: 4,
            render: (d) => <CustomInput
                value={d.due}
                prefix={FormatUtils.getCurrency()}
                style={{ width: '90%' }}
                updateOnBlur
                onChange={amount => updateInvoiceDetail(d.uuid, { complete: (d.invoiced + amount) * 100 / d.fee, amount })}
            />
        }
    ];

    const PhaseDetail = ({ phaseId }) => {
        const phase: IPhase = phases && phases[phaseId] || {}

        return (
            <div className={styles.phaseDetail}>
                <div className={styles.titleContainer}>
                    <Tag text={phase.type?.abbreviation} color={project?.color} />
                    <div className={styles.title}>{GenUtils.capitalizeInitials(phase.type?.name)}</div>
                </div>
                {/* {FormatUtils.getFormattedDateFromTimestamp(phase.startAt)} - {FormatUtils.getFormattedDateFromTimestamp(phase.endAt)} */}
            </div>
        )
    }

    const updateDetail = (detailId, detail) => {
        setData(
            InvoiceUtils.processConsultantData(
                data
                    .map(d => d.uuid === detailId
                        ? { ...d, ...detail }
                        : d
                    )
            )
        )
    }

    const updateInvoiceDetail = async (detailId, detail) => {
        if(!detailId||!detail) return;
        updateDetail(detailId, detail);
        const { data, error } = await InvoiceService.updateInvoiceDetails(detailId, detail);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to draft', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }

        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Saved as draft', <notificationIcon.icon
            style={notificationIcon.style} />);
    }

    const columns = [
        {
            name: 'Consultant',
            key: 'consultant',
            span: 9,
            render: (d) => d.name === 'Consultant Services Total' ? d.name : GenUtils.capitalizeInitials(consultantTypes[d.consultantTypeId]?.name),
        },
        {
            name: 'Planned',
            key: 'fee',
            span: 3,
            render: (d) => FormatUtils.getAmountInFormat(d.fee, true, 2, 0)
        },
        {
            name: 'Previously invoiced',
            key: 'invoiced',
            span: 4,
            render: (d) => `${FormatUtils.getAmountInFormat(d.invoiced, true, 2, '0')} / ${d.fee ? (d.invoiced * 100 / d.fee).toFixed(2) : 0}%`
        },
        {
            name: 'Complete %',
            key: 'rate',
            span: 4,
            render: (d) => d.details
                ? (FormatUtils.getAmountInFormat(d.complete, false, 0, '0') + ' %')
                : <CustomInput
                    value={d.complete}
                    style={{ width: '90%' }}
                    updateOnBlur
                    suffix='%'
                    onChange={complete => updateInvoiceDetail(d.uuid, { complete, amount: ((d.fee * complete) / 100) - d.invoiced })}
                />
        },
        {
            name: 'Current Due',
            key: 'due',
            span: 4,
            render: (d) => d.details
                ? FormatUtils.getAmountInFormat(d.due, true, 2, 0)
                : <CustomInput
                    value={d.due}
                    prefix={FormatUtils.getCurrency()}
                    style={{ width: '90%' }}
                    updateOnBlur
                    onChange={amount => updateInvoiceDetail(d.uuid, { complete: (d.invoiced + amount) * 100 / d.fee, amount })}
                />
        }
    ];

    return (
        <Table
            data={[..._data, ...(_data.length > 0 ? [{
                name: 'Consultant Services Total',
                status: null,
                fee: _data?.reduce((acc, d) => acc + d.fee, 0),
                invoiced: _data?.reduce((acc, d) => acc + d.invoiced, 0),
                unbilled: _data?.reduce((acc, d) => acc + d.unbilled, 0),
                due: _data?.reduce((acc, d) => acc + d.due, 0),
            }] : [])].sort(GenUtils.sortByCreatedAt) || []}
            columns={columns}
            lastRowBold
            isExpandableRow={(d: IInvoiceDetail) => d.details || false}
            expandableRow={(d: IInvoiceDetail[]) =>
                <div style={{ padding: 10, marginTop: -25 }}>
                    <Table
                        data={d}
                        columns={consultantColumns}
                    />
                </div>
            }
        />
    )
};

export default ConsultantServicesTab
