import { Input } from 'antd';
import { useState } from 'react';
import CallLimiter from '../../utils/CallLimiter';

const limitedOnChange = new CallLimiter(() => {}, 5000);

function FocusFormattedInput({ originalData, formattedData,onChange, disabled=false }) {
  const [isFocused, setIsFocused] = useState(false);
  const [input,setInput] = useState(originalData)
  const dataToShow = isFocused ? input : formattedData;

  limitedOnChange.setAction(onChange);

  const handleChange=(value)=>{
    setInput(value)
    limitedOnChange.call(value);
  }

  return (
    <Input
      type="text"
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      value={dataToShow}
      onChange={(e)=>handleChange(e.target.value)}
      disabled={disabled}
    />
  );
}
export default FocusFormattedInput;