import { APIUtil } from '../utils/ApiUtils';
import ApiURLs from '../constants/ApiURLs';
import GenUtils from '../utils/GenUtils';
import { setMembers } from '../redux/actions/MemberActions';

export default class MembersService {
    static async addMember(member) {
        const _member = GenUtils.removeEmptyKeysFromObject(member);
        const { data, error } = await APIUtil.makePostRequest(`${ApiURLs.ADD_MEMBER}`, _member);

        if (error) {
            GenUtils.log('addMember', error);
            return { error };
        }

        await this.fetchMemberForPhase(member.phaseId);
        return { data };
    }

    static async fetchMemberForPhase(phaseId: string) {
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.GET_MEMBER_FOR_PHASE}/${phaseId}`);

        if (error) {
            GenUtils.log('fetchMemberForPhase', { error });
            return;
        }
        GenUtils.dispatch(setMembers(data, phaseId));
        return { data };
    }

    static async fetchMember(memberId: string) {
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.GET_PHASE}/${memberId}`);

        if (error) {
            GenUtils.log('fetchMember', { error });
            return;
        }

        return { data };
    }

    static async updateMember(member, memberId, phaseId) {
        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.UPDATE_PHASE_MEMBER}/${memberId}`, member);

        if (error) {
            GenUtils.log('updateMember', { error });
            return { error };
        }
        await this.fetchMemberForPhase(phaseId);
        return { data };
    }

    static async deleteMember(memberId, phaseId) {
        const { data, error } = await APIUtil.makeDeleteRequest(`${ApiURLs.UPDATE_PHASE_MEMBER}/${memberId}`);

        if (error) {
            GenUtils.log('deleteMember', { error });
            return { error };
        }
        await this.fetchMemberForPhase(phaseId);
        return { data };
    }
}