import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// redux
import { IStore } from '../../redux/store';

// Utils
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import FormatUtils from '../../utils/FormatUtils';
import NavigationUtils from '../../utils/NavigationUtils';

// Components
import Tag from '../../components/tag/Tag';

// styles
import styles from './Milestone.scss';
import { Dropdown, Modal, Popconfirm, Table } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import Button from '../../components/button/Button';
import IMilestone from '../../interfaces/IMilestone';
import MilestoneService from '../../services/MilestoneService';
import { RiCloseFill } from 'react-icons/ri';
import CreateMilestone from './CreateMilestone';
import ProjectService from '../../services/ProjectService';
import { EFilterCategory, EFilterTypes } from '../../components/filterBars/FiltersBarMultiselect';
import GroupedTable from '../../components/table/groupedTable/GroupedTable';

const Milestone = () => {
    const [editingMilestone, setEditingMilestone] = useState(null);

    const { phase } = useSelector((state: IStore) => state.options);
    const phases = useSelector((state: IStore) => state?.phases?.projectPhases);
    const projects = useSelector((state: IStore) => state?.project?.projects);
    const milestonesArray = useSelector((state: IStore) => state.milestones?.milestonesArray || []);

    const fetchProjects = () => {
        ProjectService.fetchAllProjects();
    };

    const deleteMilestone = async (milestoneId) => {
        const { data, error } = await MilestoneService.deleteMilestone(milestoneId);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification(
                'Failed to delete',
                error,
                <notificationIcon.icon style={notificationIcon.style} />
            );
            return;
        }

        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification(
            'Milestone deleted successfully',
            '',
            <notificationIcon.icon style={notificationIcon.style} />
        );
    };

    const getMenuItems = (milestone: IMilestone) => {
        return [
            {
                key: 0,
                label: (
                    <span onClick={() => {
                        setEditingMilestone(milestone);
                    }}
                    >Edit</span>
                )
            },
            {
                key: 1,
                label: (
                    <Popconfirm
                        title='Are you sure to delete this Milestone?'
                        onConfirm={() => deleteMilestone(milestone.uuid)}
                        okText='Yes'
                        cancelText='No'
                    >
                        <Button
                            danger
                            onClick={() => null}
                            text='Delete Milestone'
                        />
                    </Popconfirm>
                )
            }];
    };

    const columns = [
        {
            title: 'Milestone',
            dataIndex: 'name',
            span: 4,
            render: (n) => n
        },
        {
            title: 'Project',
            dataIndex: 'projectId',
            span: 4,
            render: (projectId) => (
                <div style={{ display: 'flex', gap: 10 }}>
                    <Tag text={projects[projectId]?.serial} color={projects[projectId]?.color} />
                    {GenUtils.capitalizeInitials(projects[projectId]?.name) || '-'}
                </div>
            )
        },
        {
            title: 'Phase',
            dataIndex: 'phaseId',
            span: 3,
            render: (phaseId, milestone) => {
                const abb = phases[milestone.projectId][milestone.phaseId].type.abbreviation
                return (
                    <div style={{ display: 'flex' }}>
                        <Tag color={projects[milestone.projectId]?.color} text={abb ? abb : '-'} />
                    </div>
                );
            }
        },
        {
            title: 'Description',
            dataIndex: 'description',
            span: 4,
            render: (m) => m
        },
        {
            title: 'Due Date',
            dataIndex: 'due_date',
            span: 2,
            render: (m) => FormatUtils.getFormattedDateFromTimestamp(m?.date)
        },
        {
            title: '',
            dataIndex: 'menu',
            span: 2,
            render: (m, milestone) => (
                <Dropdown menu={{ items: getMenuItems(milestone) }} trigger={['click']}>
                    <MoreOutlined />
                </Dropdown>
            )
        }
    ];
    const filters = [
        {
            name: 'Due-Date',
            dataIndex: 'date',
            type: EFilterTypes.dateRange,
            category: EFilterCategory.simple
        },
        {
            name: 'Search Milestone',
            dataIndex: 'name',
            type: EFilterTypes.dropdown,
            category: EFilterCategory.simple,
        },
        {
            name: 'Project',
            dataIndex: 'projectId',
            type: EFilterTypes.dropdown,
            category: EFilterCategory.advance,
        }
    ]

    return (
        <div className={styles.container}>
            <Modal
                title='Editing milestone'
                width={500}
                centered
                open={editingMilestone}
                onCancel={() => setEditingMilestone(null)}
                footer={null}
                closeIcon={<RiCloseFill className='remix-icon text-color-black-100' size={24} />}>
                <CreateMilestone
                    hideModal={() => setEditingMilestone(null)}
                    onSuccess={() => setEditingMilestone(null)}
                    milestone={editingMilestone}
                />
            </Modal>
            <GroupedTable columns={columns} data={milestonesArray} filters={filters} />
        </div>
    );
};
export default Milestone;
