import { IInvoiceDetail } from "../../interfaces/IInvoice";
import FormatUtils from "../../utils/FormatUtils";
import styles from './UnbilledDetails.scss';

const UnbilledDetails = ({ detail, suffix = '', prefix = '' }: { detail: IInvoiceDetail, suffix?: string, prefix?: string }) => {
    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <div className={styles.label}>
                    Unbilled Hours
                </div>:
                <div className={styles.value}>
                    {prefix}{FormatUtils.getAmountInFormat(detail.unbilled, false, 2, 0)}{suffix}
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.label}>
                    Since Last Invoice
                </div>:
                <div className={styles.value}>
                {prefix}{FormatUtils.getAmountInFormat(detail.unbilledSinceLastInvoice, false, 2, 0)}{suffix}
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.label}>
                    Remaining Unbilled
                </div>:
                <div className={styles.value}>
                {prefix}{FormatUtils.getAmountInFormat(detail.unbilledRemaining, false, 2, 0)}{suffix}
                </div>
            </div>
        </div>
    )
}

export default UnbilledDetails;