import { Tag } from 'antd';
import GenUtils from '../../utils/GenUtils';
import React from 'react';
import Colors from '../../constants/Colors';

const VerifiedEmailTag = ({
                              user,
                              onClick = () => {
                              }
                          }) => {
    if (user.status === 'active') {
        return <Tag color={GenUtils.getTagColorByStatus('active')} onClick={onClick}>Verified</Tag>;
        // } else if (user.status === 'invited') {
        //     return <>Not Verified <Tag color={Colors.primary} onClick={onClick}>Resend Invitation</Tag></>;
    } else if (user.email) {
        return <Tag color={Colors.primary} onClick={onClick}>Resend Invitation</Tag>;
    }
};

export default VerifiedEmailTag;