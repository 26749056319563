import { useEffect, useState } from 'react';
import styles from './Phase.scss';
import { Button, Card, Col, Dropdown, MenuProps, Modal, Popconfirm, Row, Table, Typography } from 'antd';
import { DeleteOutlined, DollarOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import FormatUtils from '../../utils/FormatUtils';
import EditableCellsTable from '../../components/table/EditableCellsTable';
import OptionService from '../../services/OptionService';
import { RiCloseFill, RiMore2Line } from 'react-icons/ri';
import AssignTeamMember from './AssignTeamMember';
import AddPhase from './AddPhase';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import MembersService from '../../services/MembersService';
import _ from 'lodash';
import PhaseService from '../../services/PhaseService';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import CustomInput from '../../components/input/CustomInput';
import Colors from '../../constants/Colors';
import Tag from '../../components/tag/Tag';
import { summary } from '../../view/components/data-display/table/code';

const { Text } = Typography;

const Phase = ({ phase, project }) => {
    const [members, setMembers] = useState([]);
    const [showSelectMember, setShowSelectMember] = useState(false);
    const [showEditPhase, setShowEditPhase] = useState(false);
    const [selectedMemberId, setSelectedMemberId] = useState();
    const phaseMembers = useSelector((state: IStore) => state.members.phaseMembers[phase.uuid]);
    const projectPhases = useSelector((state: IStore) => project.uuid && state.phases.projectPhases[project.uuid]);

    useEffect(() => {
        OptionService.fetchRoles();
        MembersService.fetchMemberForPhase(phase.uuid);
    }, [phase.uuid]);

    useEffect(() => {
        if (!_.isEqual(phaseMembers, members)) {
            phaseMembers && setMembers(Object.values(phaseMembers));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phaseMembers]);

    const updatePhase = async (phase) => {
        const response = await PhaseService.updatePhase(phase, phase.uuid);
        if (response.error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Error', `Unable to Update Phase`, <notificationIcon.icon
                style={notificationIcon.style} />);
        } else {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
            GenUtils.showNotification('Success', `Phase Updated Successfully`, <notificationIcon.icon
                style={notificationIcon.style} />);
        }
    };

    const deleteMember = async (memberId: string) => {
        const response = await MembersService.deleteMember(memberId, phase.uuid);
        if (response.error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Error', `Unable to Delete Member`, <notificationIcon.icon
                style={notificationIcon.style} />);
        } else {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
            GenUtils.showNotification('Success', `Member Deleted Successfully`, <notificationIcon.icon
                style={notificationIcon.style} />);
        }
    };

    const deletePhase = async () => {
        const response = await PhaseService.deletePhase(phase.uuid, phase.projectId);
        if (response.error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Error', `Unable to Delete Phase`, <notificationIcon.icon
                style={notificationIcon.style} />);
        } else {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
            GenUtils.showNotification('Success', `Phase Deleted Successfully`, <notificationIcon.icon
                style={notificationIcon.style} />);
        }
    };

    const columns = [
        {
            title: 'Team Member',
            dataIndex: 'teamMember',
            key: 'teamMember',
            render: (text, data, index) => <div>{data.teamMember.firstName + ' ' + data.teamMember.lastName}</div>
        },
        {
            title: 'Role & Billable Rate',
            dataIndex: 'role',
            key: 'role',
            render: (text, data, index) => (
                <div>
                    {GenUtils.capitalizeInitials(data.role?.name)} - {FormatUtils.getAmountInFormat(data.role?.hourRate, true, 0, 0)}
                </div>
            )
        },
        {
            title: 'Budgeted Hours',
            dataIndex: 'budgetHours',
            key: 'budgetHours',
            editable: true,
            hourCell: true,
            render: (text, data) => <CustomInput
                type={'number'}
                value={text}
                suffix='Hrs'
                style={{ width: '100%' }}
                onChange={() => {}}
                updateOnBlur
            />
        },
        {
            title: '%',
            dataIndex: 'hourPercent',
            key: 'hourPercent',
            editable: true,
            render: (text, data) => <CustomInput
                value={Math.round(((data.role.hourRate * data.budgetHours * 100) / phase.amount)*100)/100}
                suffix='%'
                style={{ width: '100%' }}
                onChange={() => {}}
                updateOnBlur
            />
        },
        {
            title: 'Budget',
            dataIndex: 'budget',
            key: 'budget',
            render: (text, data) => {
                return <div>{FormatUtils.getAmountInFormat(data.budgetHours * data.role.hourRate, true, 2, '0')}</div>;
            }
        },
        {
            title: '',
            width: 10,
            render: (text, data, index) => <div>{moreBtn(data, index)}</div>
        }
    ];

    const rowMenu = (data, index): MenuProps => ({
        items: [
            {
                label: 'Change Team Member',
                key: 1
            },
            {
                label: 'Assign to All Phase',
                key: 2
            },
            {
                label: (
                    <Popconfirm
                        title='Are you sure to delete this member?'
                        onConfirm={() => deleteMember(data.uuid)}
                        okText='Yes'
                        cancelText='No'
                    >
                        Delete
                    </Popconfirm>
                ),
                key: 3
            }
        ],
        onClick: (e) => {
            switch (e.key) {
                case '1':
                    setSelectedMemberId(data.uuid);
                    setShowSelectMember(true);
                    break;
                case '2':
                    GenUtils.log('Assign to All phase');
                    break;
            }
        }
    });

    const showAddMember = () => {
        setShowSelectMember(true);
    };
    const hideAddMember = () => {
        setShowSelectMember(false);
    };

    function moreBtn(data, index) {
        return (
            <Dropdown menu={rowMenu(data, index)} placement='bottomLeft'>
                <Button type='text'
                        icon={<RiMore2Line className='hp-text-color-black-100 hp-text-color-dark-0' size={24} />} />
            </Dropdown>
        );
    }

    const updateTable = (data) => {
        setMembers(data);
    };

    const onRowChange = async (data) => {
        let member = {
            budgetHours: Math.round(data.budgetHours)
        };
        const response = await MembersService.updateMember(member, data.uuid, data.phaseId);
        if (response.error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Error', `Unable to Update Member`, <notificationIcon.icon
                style={notificationIcon.style} />);
        } else {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
            GenUtils.showNotification('Success', `Member Updated Successfully`, <notificationIcon.icon
                style={notificationIcon.style} />);
        }
    };

    return (
        <Card className='hp-border-color-black-40' style={{ marginBottom: 20 }}>
            <Row style={{ marginBottom: 20 }}>
                <Col span={14}>
                    <div className={styles.head}>
                        <Tag color={project.color} text={phase.type?.abbreviation} style={{ marginRight: 10 }}/>
                        <Text className={styles.fontSize18} strong>
                            {GenUtils.capitalizeInitials(phase.type?.name)}
                        </Text>
                    </div>
                    <div className={styles.subHead}>
                        <Text type='secondary' style={{ margin: 0, display: 'flex' }}>
                            {FormatUtils.getFormattedDateFromTimestamp(phase.startAt)}-{FormatUtils.getFormattedDateFromTimestamp(phase.endAt)}
                            <Tag color={phase.feeType === 'fixed' ? Colors.indigo : Colors.violet} style={{ marginLeft: 10 }} text={phase.feeType === 'fixed' ? 'Fixed Fee' : 'Hourly Fee'}/>
                        </Text>
                        {phase.linkedPhaseId && projectPhases[phase.linkedPhaseId] && (
                            <Text type='secondary' style={{ margin: 0 }}>
                                Linked Phase: {projectPhases[phase.linkedPhaseId].type?.name}
                            </Text>
                        )}
                    </div>
                </Col>
                <Col span={5}>
                    {phase.feeType === 'fixed' && (
                        <div className={styles.textAlignRight}>
                            <Text className={styles.fontSize18} strong>
                                {Math.floor((phase.amount * 100) / project.projectFee)}%
                            </Text>
                            <br />
                            <Text className={styles.fontSize16} >
                                % of Project Fee
                            </Text>
                        </div>
                    )}
                </Col>
                <Col span={4} className={styles.textAlignRight}>
                    <Text className={styles.fontSize18} strong>
                        {FormatUtils.getAmountInFormat(phase.amount, true, 0, 0)}
                    </Text>
                    <br />
                    {phase.feeType === 'fixed' ? (
                        <Text className={styles.fontSize16}>
                            Phase Fee
                        </Text>
                    ) : (
                        <Text className={styles.fontSize16}>
                            Max Cap
                        </Text>
                    )}
                </Col>
                <Col span={1}>
                    <div className={styles.icons}>
                        <EditOutlined style={{ fontSize: 18 }} onClick={() => setShowEditPhase(true)} />
                        <Popconfirm title='Are you sure to delete this Phase?' onConfirm={() => deletePhase()}
                                    okText='Yes' cancelText='No'>
                            <DeleteOutlined style={{ fontSize: 18 }} />
                        </Popconfirm>
                    </div>
                </Col>
            </Row>
            {Boolean(members.length) && (
                <EditableCellsTable
                    columns={columns}
                    dataSource={
                        (members || [])
                            .filter(m => !!m.role)
                            .sort(GenUtils.sortByCreatedAt).map((member) => ({ ...member, key: member.uuid }))
                    }
                    setDataSource={updateTable}
                    location='phase'
                    total={phase.amount}
                    onRowChange={onRowChange}
                    summary={(data) => {
                        let totalHours = 0, totalFee = 0;
                        data.forEach((row) => {
                            totalHours += row.budgetHours;
                            totalFee += row.budgetAmount;
                        });
                        const percent = (totalFee * 100) / phase.amount;

                        return (
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>
                                    <strong>Total</strong>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                <Table.Summary.Cell index={2}>
                                    <strong>{FormatUtils.getAmountInFormat(totalHours, false, 2, '0')} Hrs</strong>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={3}>
                                    <strong>{FormatUtils.getAmountInFormat(percent, false, 2, '0')} %</strong>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={4}>
                                    <strong
                                        style={{ color: percent > 100 ? 'red' : 'black' }}>{FormatUtils.getAmountInFormat(totalFee, true, 0, '0')}</strong>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={5}>
                                    <DollarOutlined
                                        style={{ fontSize: 24 }}
                                        onClick={() => updatePhase({
                                            uuid: phase.uuid,
                                            amount: totalFee,
                                            projectId: phase.projectId
                                        })}
                                    />
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        );
                    }}
                />
            )}
            <Modal
                title='Add Member'
                width={700}
                centered
                style={{ margin: '20px' }}
                open={showSelectMember}
                onCancel={hideAddMember}
                footer={null}
                destroyOnClose={true}
                closeIcon={<RiCloseFill className='remix-icon text-color-black-100' size={24} />}>
                <AssignTeamMember
                    hideModal={() => {
                        setSelectedMemberId(null);
                        setShowSelectMember(false);
                    }}
                    onSuccess={() => {
                        setSelectedMemberId(null);
                        setShowSelectMember(false);
                    }}
                    phase={phase}
                    memberId={selectedMemberId}
                    projectId={project.uuid}
                />
            </Modal>
            <Modal
                title='Edit Phase'
                width={800}
                centered
                style={{ margin: '20px' }}
                open={showEditPhase}
                onCancel={() => setShowEditPhase(false)}
                footer={null}
                closeIcon={<RiCloseFill className='remix-icon text-color-black-100' size={24} />}>
                <AddPhase
                    hideModal={() => setShowEditPhase(false)}
                    onSuccess={() => {
                        setShowEditPhase(false);
                    }}
                    totalFee={project.projectFee}
                    initialPhase={phase}
                    project={project}
                />
            </Modal>
            <Button className={styles.marginTop20} icon={<PlusCircleOutlined style={{ marginRight: 10 }} />}
                    onClick={showAddMember}>
                Assign Member
            </Button>
        </Card>
    );
};

export default Phase;
