import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

// utils
import FormatUtils from '../../utils/FormatUtils';
import GenUtils from '../../utils/GenUtils';

//styles
import styles from './Invoice.scss';
import InvoiceTable from '../../components/table/custom/InvoiceTable';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { IStore } from '../../redux/store';
import { IInvoiceDetail } from '../../interfaces/IInvoice';
import InvoiceUtils, { EInvoiceDetailTypes } from '../../utils/InvoiceUtils';
import TotalRow from './preview/TotalRow';

const Invoice = ({ invoiceData = {}, options = {} }) => {
    const [invoiceId, setInvoiceId] = useState(null);
    const [totals, setTotals] = useState({});

    const invoice = useSelector((state: IStore) => state.invoices?.invoices[invoiceId]);
    const firm = useSelector((state: IStore) => state?.firm);
    const phases = useSelector((state: IStore) => (invoice?.projectId && state.phases?.projectPhases && state.phases?.projectPhases[invoice?.projectId]) || {});
    const phaseMembers = useSelector((state: IStore) => state.members.phaseMembers);
    const consultantTypes = useSelector((state: IStore) => GenUtils.arrayToIdMap(state.options.consultant));
    const { expenses } = useSelector((state: IStore) => state.expenses);

    // Location
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const firmUUID = firm.firm.uuid;
    const firmImage = firm.firms[firmUUID]?.profilePic?.url;

    useEffect(() => {
        setInvoiceId(searchParams.get('invoiceId'));
    }, [searchParams, pathname]);

    useEffect(() => {
        if (invoice) {
            setTotals(InvoiceUtils.calculateTotals(invoice));
        }
    }, [invoice]);

    const columnsFixedFee = [
        {
            name: 'Phase',
            key: 'phaseId',
            render: (data) => phases[data?.phaseId]?.type?.name,
            renderTotal: (data) => 'Total for Fixed Fee Services'
        },
        ...(invoice?.showPlanned
            ? [
                  {
                      name: 'Planned',
                      key: 'fee',
                      render: (data) => FormatUtils.getAmountInFormat(data.fee, true, 2, 0),
                      renderTotal: (data, datas) =>
                          FormatUtils.getAmountInFormat(
                              datas.reduce((acc, d) => acc + d.fee, 0),
                              true,
                              2,
                              0
                          )
                  }
              ]
            : []),
        ...(invoice?.showPrevious
            ? [
                  {
                      name: `Previously Billed ${FormatUtils.getCurrency()}`,
                      key: 'invoice',
                      render: (data) => FormatUtils.getAmountInFormat(data.invoiced, true, 2, 0),
                      renderTotal: (data, datas) =>
                          FormatUtils.getAmountInFormat(
                              datas.reduce((acc, d) => acc + d.invoiced, 0),
                              true,
                              2,
                              0
                          )
                  }
              ]
            : []),
        ...(invoice?.showPrevious
            ? [
                  {
                      name: 'Previously Billed %',
                      key: 'invoice',
                      render: (data) => ((data.invoiced * 100) / data.fee || 0) + ' %',
                      renderTotal: (data, datas) => (datas.reduce((acc, d) => acc + d.invoiced, 0) * 100) / datas.reduce((acc, d) => acc + d.fee, 0) + ' %'
                  }
              ]
            : []),
        ...(invoice?.showComplete
            ? [
                  {
                      name: 'Complete %',
                      key: 'complete',
                      render: (data) => data.complete + ' %',
                      renderTotal: (data, datas) => datas.reduce((acc, d) => acc + d.complete, 0) / datas.length + ' %'
                  }
              ]
            : []),
        {
            name: 'Current Due',
            key: 'due',
            render: (data) => FormatUtils.getAmountInFormat(data.due, true, 2, 0),
            renderTotal: (data, datas) => FormatUtils.getAmountInFormat(totals[EInvoiceDetailTypes.FIXED], true, 2, 0)
        }
    ];

    const columnsHourlyFee = [
        {
            name: 'Role',
            key: 'name',
            render: (data) => (phaseMembers[data?.phaseId] ? GenUtils.capitalizeInitials(phaseMembers[data?.phaseId][data.phaseMemberId]?.role?.name) : '-'),
            renderTotal: (data, datas) => 'Total for ' + GenUtils.capitalizeInitials(phases[datas[0]?.phaseId]?.type?.name)
        },
        {
            name: `Previously Billed`,
            key: 'invoiced',
            render: (data) => ((data.invoiced || 0) / 3600).toFixed(2) + 'hrs',
            renderTotal: (data) => <>&nbsp;</>
        },
        {
            name: 'Hours',
            key: 'invoice',
            render: (data) => {
                return ((data.due || 0) / 3600).toFixed(2) + 'hrs';
            },
            renderTotal: ({ data }) => <>&nbsp;</>
        },
        {
            name: 'Hourly rate',
            key: 'rate',
            render: (data) => data.complete,
            renderTotal: (data, datas) => <>&nbsp;</>
        },
        {
            name: 'Current Due',
            key: 'due',
            render: (d) => FormatUtils.getAmountInFormat(d.total, true, 2, 0),
            renderTotal: (data, datas) =>
                FormatUtils.getAmountInFormat(
                    datas.reduce((acc, d) => acc + d.total, 0),
                    true,
                    2,
                    0
                )
        }
    ];

    const columnsConsultant = [
        {
            name: 'Consultant',
            key: 'consultant',
            span: 9,
            render: (d) => GenUtils.capitalizeInitials(consultantTypes[d.consultantTypeId]?.name),
            renderTotal: (data, datas) => 'Total for Consultant Services'
        },
        ...(invoice?.showPlanned
            ? [
                  {
                      name: 'Planned',
                      key: 'fee',
                      span: 3,
                      render: (d) => FormatUtils.getAmountInFormat(d.fee, true, 2, 0),
                      renderTotal: (data, datas) =>
                          FormatUtils.getAmountInFormat(
                              datas.reduce((acc, d) => acc + d.fee, 0),
                              true,
                              2,
                              0
                          )
                  }
              ]
            : []),
        ...(invoice?.showPrevious
            ? [
                  {
                      name: `Previously Billed ${FormatUtils.getCurrency()}`,
                      key: 'invoice',
                      span: 4,
                      render: (data) => FormatUtils.getAmountInFormat(data.invoiced, true, 2, 0),
                      renderTotal: (data, datas) =>
                          FormatUtils.getAmountInFormat(
                              datas.reduce((acc, d) => acc + d.invoiced, 0),
                              true,
                              2,
                              0
                          )
                  }
              ]
            : []),
        ...(invoice?.showPrevious
            ? [
                  {
                      name: 'Previously Billed %',
                      key: 'invoice',
                      span: 4,
                      render: (data) => ((data.invoiced * 100) / data.fee || 0) + ' %',
                      renderTotal: (data, datas) => (datas.reduce((acc, d) => acc + d.invoiced, 0) * 100) / datas.reduce((acc, d) => acc + d.fee, 0) + ' %'
                  }
              ]
            : []),
        ...(invoice?.showComplete
            ? [
                  {
                      name: 'Complete %',
                      key: 'rate',
                      span: 4,
                      render: (d) => FormatUtils.getAmountInFormat(d.complete, false, 0, '0') + ' %',
                      renderTotal: (data, datas) => datas.reduce((acc, d) => acc + d.complete, 0) / datas.length + ' %'
                  }
              ]
            : []),
        {
            name: 'Current Due',
            key: 'due',
            span: 4,
            render: (d) => FormatUtils.getAmountInFormat(d.due, true, 2, 0),
            renderTotal: (data, datas) => FormatUtils.getAmountInFormat(totals[EInvoiceDetailTypes.CONSULTANT], true, 2, 0)
        }
    ];

    const columnsExpense = [
        {
            name: 'Category',
            key: 'category',
            span: 3,
            render: (d) => GenUtils.capitalizeFirstLetter(expenses[d.expenseId]?.category?.name),
            renderTotal: (data) => 'Total for Expenses'
        },
        {
            name: 'Team Member',
            key: 'person',
            span: 3,
            render: (d) => GenUtils.capitalizeFirstLetter(expenses[d.expenseId]?.teamMember?.firstName),
            renderTotal: (data) => <>&nbsp;</>
        },
        {
            name: 'Note',
            key: 'note',
            span: 5,
            render: (d) => GenUtils.capitalizeFirstLetter(expenses[d.expenseId]?.note) || '-',
            renderTotal: (data) => <>&nbsp;</>
        },
        {
            name: 'Date',
            key: 'date',
            span: 3,
            render: (d) => FormatUtils.getFormattedDateFromTimestamp(expenses[d.expenseId]?.date),
            renderTotal: (data) => <>&nbsp;</>
        },
        {
            name: 'Phase',
            key: 'phase',
            span: 3,
            render: (d) => (expenses[d.expenseId]?.phase ? GenUtils.capitalizeInitials(expenses[d.expenseId]?.phase?.type?.name) : '-'),
            renderTotal: (data) => <>&nbsp;</>
        },
        {
            name: 'Current Due',
            key: 'due',
            span: 2,
            render: (d) => FormatUtils.getAmountInFormat(d.due, true, 2, 0),
            renderTotal: (data, datas) => FormatUtils.getAmountInFormat(totals[EInvoiceDetailTypes.EXPENSE], true, 2, 0)
        }
    ];

    const columnsOther = [
        {
            name: 'Item',
            key: 'name',
            render: (data) => GenUtils.capitalizeInitials(data?.name) || <>&nbsp;</>,
            renderTotal: (data) => 'Total Custom Items'
        },
        {
            name: 'Description',
            key: 'desc',
            render: (data) => data.desc,
            renderTotal: (data) => <>&nbsp;</>
        },
        {
            name: `Quantity`,
            key: 'count',
            render: (data) => data.count,
            renderTotal: (data) => <>&nbsp;</>
        },
        {
            name: 'Rate',
            key: 'rate',
            render: (d) => FormatUtils.getAmountInFormat(d.fee, true, 2, 0) || 0,
            renderTotal: (data) => <>&nbsp;</>
        },
        {
            name: 'Current Due',
            key: 'due',
            render: (d) => FormatUtils.getAmountInFormat(d.due, true, 2, 0),
            renderTotal: (data, datas) => FormatUtils.getAmountInFormat(totals[EInvoiceDetailTypes.OTHER], true, 2, 0)
        }
    ];

    return (
        <div className={styles.container} id="invoice">
            <div className={styles.header}>
                <div className={styles.left}>
                    <img src={firmImage} alt="Firm" height={70} width={70} />
                    <b>{firm.firms[invoice?.userUuid]?.firmName}</b>
                    <p>{firm.firms[invoice?.userUuid]?.firmLocation}</p>
                </div>
                <div className={styles.right}>
                    <h1>Invoice</h1>
                    <h5>
                        <b>#{invoice?.serial}</b>
                    </h5>
                    <div>Balance Due</div>
                    <h4>
                        <b>{FormatUtils.getAmountInFormat(InvoiceUtils.calculateTotals(invoice, EInvoiceDetailTypes.ALL), true, 2, 0)}</b>
                    </h4>
                </div>
            </div>
            <div className={classnames(styles.header, styles.second)}>
                <div className={styles.left} /*style={{ maxWidth: '40%' }}*/>
                    <h5>Bill To:</h5>
                    <div>
                        <span>{invoice?.client?.name},</span>
                        <br />
                        {invoice?.address ?? ''}
                    </div>

                    {!!invoice?.note?.length && (
                        <>
                            <h5 style={{ marginTop: '10px' }}>Note: </h5>
                            <b>{invoice?.note ?? ''}</b>
                        </>
                    )}
                </div>
                <div className={styles.right}>
                    <div className={styles.field}>
                        <h5>Invoice Date</h5>
                        <h5>:</h5>
                        <>{FormatUtils.getFormattedDateFromTimestamp(invoice?.date)}</>
                    </div>
                    {invoice?.term?.name ? (
                        <div className={styles.field}>
                            <h5>Terms</h5>
                            <b>:</b>
                            <>{GenUtils.capitalizeFirstLetter(invoice?.term?.name)}</>
                        </div>
                    ) : null}
                    <div className={styles.field}>
                        <h5>Due Date</h5>
                        <h5>:</h5>
                        <>{FormatUtils.getFormattedDateFromTimestamp(invoice?.dueDate)}</>
                    </div>
                    {/* <div className={styles.field}>
                        <h5>P.O. #</h5><b>:</b>
                        <>3124</>
                    </div> */}
                    <div className={styles.field}>
                        <h5>Project Name</h5>
                        <h5>:</h5>
                        <>{GenUtils.capitalizeFirstLetter(invoice?.project?.name)}</>
                    </div>
                    <div className={styles.field}>
                        <h5>Service Through</h5>
                        <h5>:</h5>
                        <>
                            {FormatUtils.getFormattedDateFromTimestamp(invoice?.fromDate)}-{FormatUtils.getFormattedDateFromTimestamp(invoice?.toDate)}
                        </>
                    </div>
                </div>
            </div>
            <div className={styles.section}>
                {/* <h5>Fixed Fee Services</h5> */}
                <InvoiceTable
                    data={InvoiceUtils.getInvoiceDetailsByType(invoice, EInvoiceDetailTypes.FIXED)}
                    columns={columnsFixedFee}
                    tableText="Fixed Fee Services"
                />
            </div>
            {totals[EInvoiceDetailTypes.HOURLY] > 0 && (
                <div className={styles.section}>
                    <h5>Hourly Services</h5>
                    {InvoiceUtils.getInvoiceDetailsByType(invoice, EInvoiceDetailTypes.HOURLY).map((d: IInvoiceDetail) => (
                        <div key={d.uuid}>
                            {GenUtils.capitalizeInitials((phases && phases[d?.phaseId]?.type.name) || '')}
                            <div style={{ paddingLeft: 10 }}>
                                <InvoiceTable data={d.details} columns={columnsHourlyFee} tableText="" />
                            </div>
                        </div>
                    ))}
                    <TotalRow label="Total for Hourly Services" total={totals[EInvoiceDetailTypes.HOURLY]} />
                </div>
            )}
            {totals[EInvoiceDetailTypes.CONSULTANT] > 0 && (
                <div className={styles.section}>
                    {/* <h5>Consultant Services</h5> */}
                    <InvoiceTable
                        data={InvoiceUtils.getInvoiceDetailsByType(invoice, EInvoiceDetailTypes.CONSULTANT)}
                        columns={columnsConsultant}
                        tableText="Consultant Services"
                    />
                </div>
            )}
            {totals[EInvoiceDetailTypes.EXPENSE] > 0 && (
                <div className={styles.section}>
                    {/* <h5>Expenses</h5> */}
                    <InvoiceTable
                        data={InvoiceUtils.getInvoiceDetailsByType(invoice, EInvoiceDetailTypes.EXPENSE)}
                        columns={columnsExpense}
                        tableText="Expenses"
                    />
                </div>
            )}
            {totals[EInvoiceDetailTypes.OTHER] > 0 && (
                <div className={styles.section}>
                    {/* <h5>Other Items</h5> */}
                    <InvoiceTable
                        data={InvoiceUtils.getInvoiceDetailsByType(invoice, EInvoiceDetailTypes.OTHER)}
                        columns={columnsOther}
                        tableText="Other Items"
                    />
                </div>
            )}
            <div>
                <TotalRow label="Sub Total" total={invoice?.total} />
                {invoice?.discount > 0 && <TotalRow label="Discount" total={invoice?.discount} isNegative />}
                {invoice?.taxed > 0 && <TotalRow label="Tax" total={invoice?.taxed} />}
                <TotalRow label="Grand Total" total={InvoiceUtils.calculateTotals(invoice, EInvoiceDetailTypes.ALL)} />
            </div>

            <div>
                <span className={styles.footerText}>{invoice?.footer ?? ''}</span>
            </div>
        </div>
    );
};

export default Invoice;
