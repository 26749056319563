import { useEffect, useState } from 'react';
import styles from './Consultant.scss';
import { Card, Col, Modal, Popconfirm, Row, Typography } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import EditableCellsTable from '../../components/table/EditableCellsTable';
import { RiCloseFill } from 'react-icons/ri';
import AddConsultant from './AddConsultant';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import ConsultantService from '../../services/ConsultantService';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import FormatUtils from '../../utils/FormatUtils';

const { Title, Text } = Typography;

const Consultant = ({ consultant , onDelete = () => { }, project }) => {
    const [showAddConsultantModal, setShowAddConsultantModal] = useState(false);
    const projectPhases = useSelector((state: IStore) => project.uuid && state.phases.projectPhases[project.uuid]);
    const [payout, setPayout] = useState(0);

    useEffect(() => {
        if (consultant.involvementType === 'project') {
            setPayout(consultant?.payout)
        } else {
            setPayout(consultant.phases?.reduce((totalPayout, phase) => totalPayout + phase.payout, 0))
        }
    }, []);

    const columns = [
        {
            title: 'phase',
            dataIndex: 'phaseId',
            key: 'phaseId',
            render: (text) => <div>{projectPhases[text].type.name}</div>
        },
        {
            title: 'Consultant Fee',
            dataIndex: 'fee',
            key: 'fee'
        },
        {
            title: 'Payout = Fee',
            dataIndex: 'isFullPayout',
            key: 'isFullPayout',
            render: (text, data) => <div>{data.isFullPayout ? 'True' : 'False'}</div>
        },
        {
            title: 'Payout',
            dataIndex: 'payout',
            key: 'payout'
        }
    ];

    const deletConsultant = async() => {
        const response = await ConsultantService.deleteConsultant(consultant.uuid, consultant.projectId)
        if (response.error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Error', `Unable to Delete Consultant`, <notificationIcon.icon style={notificationIcon.style} />);
        } else {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
            GenUtils.showNotification('Success', `Consultant Deleted Successfully`, <notificationIcon.icon style={notificationIcon.style} />);
        }

    }

    return (
        <Card className="hp-border-color-black-40" style={{ marginBottom: 20 }}>
            <Row style={{ marginBottom: 20 }}>
                <Col span={14}>
                    <div className={styles.head}>
                        <Text className={styles.fontSize18} strong>
                            {GenUtils.capitalizeInitials(consultant?.type?.name)}
                        </Text>
                    </div>
                    <div className={styles.subHead}>
                        <Text type="secondary" style={{ margin: 0 }}>
                            Total Payout: {FormatUtils.getAmountInFormat(payout, true, 2, 0)}
                        </Text>
                    </div>
                </Col>
                <Col span={3}></Col>
                <Col span={6} className={styles.textAlignRight}>
                    <Text className={styles.fontSize18} strong>
                        {FormatUtils.getAmountInFormat(consultant.fee || consultant.phases?.reduce((acc,curr)=>acc+curr.fee,0), true, 0,0)}
                    </Text>
                    <br/>
                    <Text className={styles.fontSize18} strong>
                        Total Consultant Fee
                    </Text>
                </Col>
                <Col span={1}>
                    <div className={styles.icons}>
                        <EditOutlined style={{ fontSize: 18 }} onClick={() => setShowAddConsultantModal(true)} />
                        <Popconfirm title="Are you sure to delete this role?" onConfirm={deletConsultant} okText="Yes" cancelText="No">
                            <DeleteOutlined style={{ fontSize: 18 }} onClick={onDelete} />
                        </Popconfirm>
                    </div>
                </Col>
            </Row>
            {consultant.involvementType !== 'project' && <EditableCellsTable columns={columns} dataSource={consultant.phases} />}
            <Modal
                title="Add Consultant"
                centered
                width={800}
                visible={showAddConsultantModal}
                onCancel={() => setShowAddConsultantModal(false)}
                footer={null}
                closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}>
                <AddConsultant
                    hideModal={() => setShowAddConsultantModal(false)}
                    onSuccess={() => {
                        setShowAddConsultantModal(false);
                    }}
                    project={project}
                    initailConsultant={consultant}
                />
            </Modal>
        </Card>
    );
};

export default Consultant;
