export interface IConsultantState {
    consultants: {},
    consultantsByProject: {},
}

export const initialConsultantsState: IConsultantState = {
    consultants: {},
    consultantsByProject: {}
};

const consultantReducer = (state: IConsultantState, action: { type: string, payload }) => {
    switch (action.type) {
        case 'SET_CONSULTANTS':
            return {
                ...state,
                consultants: action.payload.consultants,
                consultantsByProject: {
                    ...state.consultantsByProject,
                    ...(action.payload.projectId ? { [action.payload.projectId]: action.payload.consultants } : {})
                }
            };
        case 'UPDATE_CONSULTANT':
            return {
                ...state,
                projects: {
                    ...state.consultants,
                    [action.payload[0].uuid]: action.payload[0]
                }
            };
        default:
            return state || initialConsultantsState;
    }
};

export default consultantReducer;