import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IStore } from '../../redux/store';

import { Dropdown, MenuProps } from 'antd';
import AccountItem from './AccountItem';

import styles from './AccountSelector.scss';
import IFirm from '../../interfaces/IFirm';
import { setCurrentFirm } from '../../redux/actions/FirmActions';

const AccountSelector = ({ collapsed }: { collapsed: boolean }) => {
    const firm = useSelector((state: IStore) => state?.firm);

    const dispatch = useDispatch();

    const [accountOptions, setAccountOptions] = useState([] as MenuProps['items']);

    useEffect(() => {
        setAccountOptions(
            firm.firmsArray
                .filter((f: IFirm) => f.uuid !== firm.uuid)
                .map((f) => {
                        return {
                            key: f.uuid,
                            label: (
                                <AccountItem
                                    uuid={firm.firm.uuid}
                                    collapsed={collapsed}
                                    onClick={() => onChangeAccount(f.uuid)}
                                />
                            )
                        };
                    }
                ));
    }, [firm.uuid, firm.firmsArray]);

    const onChangeAccount = (firmId) => {
        dispatch(setCurrentFirm(firmId));
    };

    return (
        <div className={styles.container}>
            <Dropdown menu={{ items: accountOptions }} trigger={accountOptions.length > 0 ? ['click'] : []}>
                <div className={styles.dropDown} onClick={(e) => e.preventDefault()}>
                    <AccountItem
                        uuid={firm.firm.uuid}
                        collapsed={collapsed}
                        expandable={accountOptions.length > 0}
                    />
                </div>
            </Dropdown>
        </div>
    );
};

export default AccountSelector;
