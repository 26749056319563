import { Card } from 'antd';
import styles from './InfoCard.scss';

const InfoCard = ({ title = '', data = {}, action = null, alignVertically = false }) => {

    const renderItem = (key) => {
        return (
            <div className={alignVertically ? styles.vertical : styles.horizontal} key={key}>
                <div className={styles.key}>{key}:</div>
                <div className={styles.value}>{data[key]}</div>
            </div>
        );
    };

    return (
        <Card
            title={title}
            extra={action}
        >
            <div className={styles.itemContainer}>
                {
                    Object.keys(data).map(renderItem)
                }
            </div>
        </Card>
    );
};

export default InfoCard;
