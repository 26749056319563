import { Checkbox, Divider, Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import Button from '../../components/button/Button';

import styles from './AddTeam.scss';
import formStyles from '../../pages/form.scss';
import TeamService from '../../services/TeamService';
import OptionService from '../../services/OptionService';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import TeamMemberSelector from '../../components/selector/TeamMemberSelector';
import { Countries } from '../../constants/Options';
import moment from 'moment';

const { Option } = Select;

const AddTeamMember = ({ hideModal, onSuccess, timestamp = moment() }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [roleUuid, setRoleUuid] = useState('');
    const [permission, setPermission] = useState('');
    const [office, setOffice] = useState('');
    const [hoursPerWeek, setHoursPerWeek] = useState('');
    const [hoursPerDay, setHoursPerDay] = useState('');
    const [startAt, setStartAt] = useState(Date.now().toString());
    const [type, setType] = useState('hourly');
    const [amount, setAmount] = useState('');
    const [teamMemberId, setTeamMemberId] = useState('');
    const [directReportees, setDirectReportees] = useState([]);
    const [stamp, setStamp] = useState({});

    const [inviteCheck, setInviteCheck] = useState(false);
    const roles = useSelector((state: IStore) => state.options.role);

    const permissions = useSelector((state: IStore) => state.permission?.permissionsArray);

    useEffect(() => {
        fetchRoles();
    }, []);

    useEffect(() => {
            setFirstName('')
            setLastName('')
            setEmail('')
            setContact('')
            setRoleUuid('')
            setPermission('')
            setOffice('')
            setHoursPerWeek('')
            setHoursPerDay('');
            setStartAt(Date.now().toString())
            setType('')
            setAmount('')
            setTeamMemberId('')
            setDirectReportees([])
            setInviteCheck(false)
    }, []);

    const fetchRoles = async () => {
        const { data, error } = await OptionService.fetchRoles();

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('An error occurred', error, <notificationIcon.icon
                style={notificationIcon.style} />);
        }
    };

    const submitForm = async () => {
        const {
            data,
            error
        } = await TeamService.addTeamMember(firstName, lastName, email, contact, roleUuid, permission, office, hoursPerWeek, hoursPerDay,
startAt, type, amount, teamMemberId, directReportees.map(d => d.uuid), inviteCheck);
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Creating Team Member Failed', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        setFirstName('')
        setLastName('')
        setEmail('')
        setContact('')
        setRoleUuid('')
        setPermission('')
        setHoursPerWeek('')
        setHoursPerDay('');
        setStartAt(Date.now().toString())
        setType('hourly')
        setAmount('')
        setTeamMemberId('')
        setDirectReportees([])
        setInviteCheck(false);
        onSuccess();
        hideModal();
    };

    return (
        <div className={styles.container}>
            <form>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <Form.Item
                            label='First Name'
                            className={styles.margin0}
                            colon={false}>
                        </Form.Item>
                        <Input
                            placeholder='First Name'
                            type={'name'}
                            className={formStyles.marginBottom20}
                            value={firstName}
                            onChange={(e) => {
                                setFirstName(e.target.value);
                            }}
                        />
                    </div>
                    <div className={styles.column}>
                        <Form.Item
                            label='Last Name'
                            className={styles.margin0}
                            colon={false}>
                        </Form.Item>
                        <Input
                            placeholder='Last Name'
                            type={'name'}
                            className={formStyles.marginBottom20}
                            value={lastName}
                            onChange={(e) => {
                                setLastName(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <Form.Item
                            label='Work Email'
                            className={styles.margin0}
                            colon={false}>
                        </Form.Item>
                        <Input
                            placeholder='Work email'
                            type={'email'}
                            className={formStyles.marginBottom20}
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                        />
                    </div>
                    <div className={styles.column}>
                        <Form.Item
                            label='Mobile Number'
                            className={styles.margin0}
                            colon={false}>
                        </Form.Item>
                        <Input
                            placeholder='Mobile Number'
                            type={'phone'}
                            className={formStyles.marginBottom20}
                            value={contact}
                            onChange={(e) => {
                                setContact(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <Form.Item label='Default Role' className={styles.margin0} colon={false} />
                        {/* todo select default value here */}
                        <Select
                            placeholder='Select Role'
                            value={roleUuid}
                            onChange={(e) => {
                                setRoleUuid(e);
                            }}
                        >
                            {
                                roles.map((role) => <Option key={role.uuid}
                                                            value={role.uuid}>{`${role.name} - ${role.hourRate}/hour`}</Option>)
                            }
                        </Select>
                    </div>
                    <div className={styles.column}>
                        <Form.Item label='Permission' className={styles.margin0} colon={false} />
                        {/* todo select default value here */}
                        <Select
                            placeholder='Select permission'
                            value={permission}
                            onChange={(value) => {
                                setPermission(value);
                            }}
                        >
                            {
                                permissions.map((permission) => <Option key={permission.uuid}
                                                                        value={permission.uuid}>{permission.name}</Option>)
                            }
                        </Select>
                    </div>
                    {/* <div className={styles.column}>
                        <Form.Item label='Office' className={styles.margin0} />
                            todo select default value here
                        <Select
                            showSearch
                            placeholder='Select office location'
                            value={office}
                            onChange={(value) => {
                                setOffice(value);
                            }}
                        >
                            {
                                Countries.map((country) => <Option key={country}
                                                                  value={country}>{country}</Option>)
                            }
                        </Select>
                    </div> */}
                </div>
                <Divider className='hp-border-color-black-40 hp-border-color-dark-80' />
                <div className={styles.heading}>Add Compensation</div>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <Form.Item
                            label="Effective From"
                            className={styles.margin0}
                            colon={false}>
                        
                        </Form.Item>
                        <Input
                            placeholder='Start date'
                            type={'date'}
                            className={formStyles.marginBottom20}
                            value={ startAt }
                            onChange={(e) => {
                                setStartAt(e.target.value);
                            }}
                        />
                    </div>
                    <div className={styles.column}>
                        <Form.Item
                            label="Hours Per Day"
                            className={styles.margin0}
                            colon={false}>
                        </Form.Item>
                        <Input
                            placeholder='hours'
                            type={'number'}
                            min="0"
                            max="24"
                            className={formStyles.marginBottom20}
                            value={hoursPerDay}
                            onChange={(e) => {
                                if (+e.target.value >= 0 && +e.target.value <= 24) {
                                    setHoursPerDay(e.target.value);
                                }
                            }}
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <Form.Item
                            rules={[{ required: true }]}
                            label='Compensation Type'
                            className={styles.margin0}
                            colon={false}>
                        </Form.Item>
                        <Select
                            placeholder='Compensation Type'
                            style={{ width: 300 }}
                            onChange={(value) => {
                                setType(value);
                            }}
                        >
                            <Option key='hourly' value='hourly'>Hourly</Option>
                            <Option key='salary' value='salary'>Salary</Option>
                        </Select>
                    </div>
                    <div className={styles.column}>
                        <Form.Item
                            label={type === 'salary' ? 'Annual Salary' : 'Hourly Salary'}
                            className={styles.margin0}
                            colon={false}>
                        </Form.Item>
                        <Input
                            placeholder=''
                            type={'number'}
                            className={formStyles.marginBottom20}
                            value={amount}
                            onChange={(e) => {
                                setAmount(e.target.value);
                            }}
                        />
                    </div>
                    <div className={styles.column}>
                        <Form.Item 
                            label="Hours Per Week"
                            className={styles.margin0}
                            colon={false}>
                        </Form.Item>
                        <Input
                            placeholder='hours'
                            type={'number'}
                            className={formStyles.marginBottom20}
                            value={hoursPerWeek}
                            onChange={(e) => {
                                setHoursPerWeek(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <Divider className='hp-border-color-black-40 hp-border-color-dark-80' />
                <div className={styles.heading}>Assigned Team Members</div>
                <div className={styles.subHeading}>This user can manage timesheets, tasks, expenses and communications
                    for below assigned members.
                </div>
                <TeamMemberSelector
                    selectedMembers={directReportees}
                    setSelectedMembers={setDirectReportees}
                    isMultiple
                    showSelected
                />
            </form>
            <div className={classnames(styles.row, styles.center)}>
                <div className={styles.checkbox}>
                    <Checkbox
                        className='hp-mb-8'
                        id='view-all'
                        checked={inviteCheck}
                        onChange={(e) => setInviteCheck(e.target.checked)}
                    > Send Invite Email </Checkbox>
                </div>
                <Button
                    type={'ghost'}
                    text='Cancel'
                    onClick={hideModal}
                />
                <Button
                    text='Add'
                    onClick={() => {
                        submitForm();

                    }}
                />
            </div>
        </div>
    );
};
export default AddTeamMember;
