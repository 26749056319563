import GenUtils from '../../utils/GenUtils';
import { ITimeOffType } from '../../interfaces/ITimeOffType';

export interface ITimeOffTypeState {
    timeOffTypes: any;
    timeOffTypesArray: ITimeOffType[];
}

export const initialTimeOffTypeState: ITimeOffTypeState = {
    timeOffTypes: {},
    timeOffTypesArray: []
};

const timeOffReducer = (state: ITimeOffTypeState, action: { type: string; payload }) => {
    switch (action.type) {
        case 'SET_TIME_OFF_TYPES':
            return {
                timeOffTypes: GenUtils.arrayToIdMap(action.payload),
                timeOffTypesArray: action.payload
            };
        case 'UPDATE_TIME_OFF_TYPE':
            const timeOff = state.timeOffTypes[action.payload?.uuid];
            const map = {
                ...state.timeOffTypes,
                ...(timeOff
                    ? {
                          [timeOff.uuid]: {
                              ...timeOff,
                              ...action.payload
                          }
                      }
                    : {
                          [action.payload.uuid]: action.payload
                      })
            };

            return {
                timeOffTypes: map,
                timeOffTypesArray: Object.values(map)
            };
        default:
            return state || initialTimeOffTypeState;
    }
};

export default timeOffReducer;
