import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Form, Select, Steps } from 'antd';

import styles from './SignupStep3.css';
import formStyles from '../form.scss';
import { Currencies, DateFormatOptions, TimeZones } from '../../constants/Options';
import { ENotificationType } from '../../utils/GenUtils';
import { proceedSignup } from '../../redux/actions/AuthActions';

const { Option } = Select;

interface ISignupStep3 {
    currency: string,
    setCurrency: any,
    timeZone: string,
    setTimeZone: any,
    dateFormat: string,
    setDateFormat: any,
    onSubmit: any,
}

const SignupStep3 = ({
                         currency,
                         setCurrency,
                         timeZone,
                         setTimeZone,
                         dateFormat,
                         setDateFormat,
                         onSubmit,
                     }: ISignupStep3) => {

    const [start, setStart] = useState(false);
    const dispatch = useDispatch();
    // const [current, setCurrent]=useState(0)

    const onNext = () => {

        onSubmit();
    };
    const onPrevious=(n)=>{
        dispatch(proceedSignup(n+1));
    }

    // const onChange = (value: number) => {
    //     setCurrent(value);
    // };

    return (
        <div className={styles.stepContainer}>
            <Steps
                size='small'
                current={2}
                onChange={onPrevious}
                className={styles.steps}
                items={[
                    {
                        title: ''
                    },
                    {
                        title: ''
                    },
                    {
                        title: ''
                    }
                ]}
            />
            <p>Set local information to convert</p>
            <Form.Item name='Currency' label='Currency' className={formStyles.label}>
            </Form.Item>
            <Select
                showSearch
                placeholder='Select'
                className={formStyles.input}
                onSelect={(e) => {
                    setCurrency(e);
                }}
                value={currency}
                // className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                style={{ width: '100%', border: 'none', paddingBottom: '20px'}}
            >
                {
                   Currencies.map(currency => <Option key={currency.iso} value={currency.iso}>{currency.name} - {currency.symbol}</Option>)
                }
            </Select>
            <Form.Item name='TimeZone' label='TimeZone' className={formStyles.label}>
            </Form.Item>
            <Select
                showSearch
                placeholder='Select'
                className={formStyles.input}
                onSelect={(e) => {
                    setTimeZone(e);
                }}
                value={timeZone}
                // className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                style={{ width: '100%', border: 'none', paddingBottom: '20px'}}
            >
                {
                    TimeZones.map(timeZone => <option key={timeZone.value} value={timeZone.value}>{timeZone.name}</option>)
                }
            </Select>
            <Form.Item name='DateFormat' label='Date Format' className={formStyles.label}>
            </Form.Item>
            <Select
                showSearch
                placeholder='Select'
                className={formStyles.input}
                onSelect={(e) => {
                    setDateFormat(e);
                }}
                value={dateFormat}
                // className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                style={{ width: '100%', border: 'none', paddingBottom: '20px'}}
            >
                {
                    DateFormatOptions.map(dateFormat => <option key={dateFormat.key} value={dateFormat.key}>{dateFormat.value}</option>)
                }
            </Select>
            <Form.Item>
                <Button type='primary'
                        className={styles.start}
                        onClick={onNext}
                        block
                >
                    Create Account

                </Button>
            </Form.Item>

        </div>
    );
};
export default SignupStep3;
