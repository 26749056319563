import React from 'react';
// import styles from './PhaseDetailPopover.scss';
import styles from './MemberAllocationDetail.scss';
import FormatUtils from '../../utils/FormatUtils';
import { IStore } from '../../redux/store';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

const MemberAllocationDetail = ({
                                    data= {},
                                    teamMember,
                                    budgetHours,
                                    budgetAmount,
                                    loggedHours,
                                    loggedAmount,
                                    allocatedHours,
                                    allocatedAmount
                                }) => {
    const { showFinancials } = useSelector((state: IStore) => state.config);
    // const teamMembers: IMember = useSelector((state: IStore) => state.team.members);

    console.log({data,
        teamMember,
        budgetHours,
        budgetAmount,
        loggedHours,
        loggedAmount,
        allocatedHours,
        allocatedAmount});
    return (
        <div className={styles.container}>
            <div style={{
                display: 'flex',
                padding: 5,
                fontSize: 13,
                justifyContent: 'center',
                margin: '0 5px 0 5px',
                // textAlign: 'center',
                flexDirection: 'column'
            }}>
                <div>
                    {teamMember?.firstName || 'Members'} will finish this phase
                    in {FormatUtils.getAmountInFormat(budgetHours - loggedHours, false, 0, '0', true)} hours
                    in {FormatUtils.getAmountInFormat(budgetAmount - loggedAmount, true, 0, '0', true)}
                </div>
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            marginRight: 5,
                            color: ((allocatedAmount + loggedAmount) < budgetAmount) ? 'green' : 'red'
                        }}>
                        {
                            ((allocatedAmount + loggedAmount) < budgetAmount) ?
                                (FormatUtils.getAmountInFormat(((allocatedAmount + loggedAmount) / budgetAmount) * 100, false, 0, 0, false) + '% under budget ')
                                : (FormatUtils.getAmountInFormat(((allocatedAmount + loggedAmount) / budgetAmount) * 100, false, 0, 0, false) + '% over budget ')
                        }
                    </div>
                    based on current schedule.
                </div>
            </div>
            <div style={{ display: 'flex', padding: 16 }}>
                <div className={styles.tableContent} style={{ flex: 2, display: 'flex' }}>
                    <div className={styles.allottedBox}>{''}</div>
                    Scheduled
                </div>
                <div className={styles.tableContent} style={{ flex: 1 }}> {allocatedHours} hrs</div>
                {
                    showFinancials &&
                    <div className={styles.tableContent}
                         style={{ flex: 1 }}> {FormatUtils.getAmountInFormat(allocatedAmount, true, 0, '0')}</div>
                }
                <div
                    className={styles.tableContent}
                    style={{ flex: 0 }}> {FormatUtils.getAmountInFormat(budgetAmount ? (allocatedAmount / budgetAmount) * 100 : 0, false, 0, '0')}%
                </div>
            </div>
            <div className={styles.table}>
                <div style={{ flex: 2 }}>
                    <div className={styles.tableHeader}>{teamMember?.firstName || 'Member'}'s Budget</div>
                    <div className={styles.tableContent}>
                        <div className={classNames(styles.box, styles.loggedBox)}>{''}</div>
                        Logged
                    </div>
                    <div className={styles.tableContent}>
                        <div className={classNames(styles.box, styles.remainingBox)}>{''}</div>
                        Remaining
                    </div>
                </div>
                <div style={{ flex: 1 }}>
                    <div
                        className={styles.tableHeader}>{FormatUtils.getAmountInFormat(budgetHours, false, 0, '0')} hrs
                    </div>
                    <div
                        className={styles.tableContent}>{FormatUtils.getAmountInFormat(loggedHours, false, 0, '0', true)} hrs
                    </div>
                    <div
                        className={styles.tableContent}>{FormatUtils.getAmountInFormat(budgetHours - loggedHours, false, 0, '0', true)} hrs
                    </div>
                </div>
                <div style={{ flex: 1 }}>
                    <div
                        className={styles.tableHeader}>
                        {
                            showFinancials &&
                            <div> {FormatUtils.getAmountInFormat(budgetAmount, true, 0, '0')}</div>
                        }
                    </div>
                    <div
                        className={styles.tableContent}>
                        {
                            showFinancials &&
                            <div>{FormatUtils.getAmountInFormat(loggedAmount, true, 0, '0')}</div>
                        }
                    </div>
                    <div
                        className={styles.tableContent}>
                        {
                            showFinancials &&
                            <div>{FormatUtils.getAmountInFormat(budgetAmount - loggedAmount, true, 0, '0', true)}</div>
                        }
                    </div>
                </div>
                <div style={{ flex: 0 }}>
                    <div className={styles.tableHeader}>100%</div>
                    <div
                        className={styles.tableContent}> {FormatUtils.getAmountInFormat((loggedAmount / budgetAmount) * 100 || 0, false, 0, '0')}%
                    </div>
                    <div
                        className={styles.tableContent}> {FormatUtils.getAmountInFormat(((budgetAmount - loggedAmount) / budgetAmount) * 100 || 0, false, 0, '0', true)}%
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MemberAllocationDetail;
