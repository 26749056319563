import { Col, Row } from 'antd';
import classNames from 'classnames';
import ActivityCell from './ActivityCell';
import HoursInputCell from './HoursInputCell';
import TotalCell from './TotalCell';
import styles from './styles.module.scss';
import classnames from 'classnames';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../redux/store';
import { TimesheetEntry } from '../../../../redux/reducers/TimeSheetReducer';

//todo: move to utils
function roundToTwoDecimals(num) {
    return Math.round(num * 100) / 100;
}

const rej_bg = 'repeating-linear-gradient(45deg, #f3d1d5, #f3d1d5 10px, #f8e7ea 10px, #f8e7ea 20px)'

const RowItem = ({ activity, index, phaseMember, activitiesInPhase, weekCount, activitiesObj, weekStart, onSave, deleteTimesheet, updateTimeLogData, phaseMemberId, project, phaseKey }) => {
    const [entries, setEntries] = React.useState<{ [date: string]: TimesheetEntry[]; }>({})
    const [rej, setRej] = React.useState(false)
    const [total, setTotal] = React.useState(0);
    const userTimesheet = useSelector((state: IStore) => state.timeLogs.timesheet);
    const [activityHoursByDay, setActivityHoursByDay] = React.useState<
        {
            day: string;
            time: number;
            activity: any;
            index: number;
            note: any;
        }[]
    >([]);
    let dayEntries = activitiesObj && activitiesObj[activity.uuid];
    let formattedWeekStart = moment(weekStart).format('MM/DD/YYYY');
    React.useMemo(() => {
        let a =
            dayEntries === undefined
                ? []
                : Object.keys(dayEntries).map((dayEntryKey) => {
                      let dayEntryArr = dayEntries[dayEntryKey];
                      let daySummary = dayEntryArr.reduce((acc, dayEntry) => {
                          let { seconds } = dayEntry;
                          return acc + seconds;
                      }, 0);
                      let daySummaryHours = roundToTwoDecimals(daySummary / 3600);
                      return {
                          day: dayEntryKey,
                          time: daySummaryHours,
                          activity: activity,
                          index: moment(dayEntryKey).diff(formattedWeekStart, 'days'),
                          note: dayEntryArr[0].note
                      };
                  });
        setActivityHoursByDay(a);
    }, [activity, dayEntries, formattedWeekStart]);

    React.useMemo(() => {
        setEntries({})
        if (activitiesObj && activity && activitiesObj[activity.uuid]) {
            const entries_l: { [date: string]: TimesheetEntry[] } = activitiesObj[activity.uuid]
            setEntries(entries_l)
        }
    }, [activitiesObj, activity])

    React.useMemo(() => {
        setRej(false)
        Object.keys(entries).forEach(e => {
            entries[e].forEach(f => {
                if (f.rejectionNote !== null) {
                    setRej(true)
                }
            }) 
        })
    }, [entries])

    React.useMemo(() => {
        let t = 0;
        [...Array(weekCount)].map((e, i) => {
            let timeLog = activityHoursByDay.find((item) => item.index === i);
            let value = { time: timeLog?.time, note: timeLog?.note };
            t += value.time === undefined ? 0 : value.time;
        });
        setTotal(t);
    }, [activityHoursByDay, weekCount]);

    return (
        <Row style={{background: rej ? rej_bg : undefined}} justify={'space-between'} className={classNames(styles.borderBottom, activity.isRejected && styles.rejectCell)}>
            <Col style={{background: 'white'}} span={5} className={styles.padding10}>
                <ActivityCell
                    weekStart={weekStart}
                    project={project}
                    activity={activity}
                    phaseMember={phaseMemberId}
                    activitiesInPhase={activitiesInPhase}
                />
            </Col>
            {[...Array(weekCount)].map((e, i) => {
                let timeLog = activityHoursByDay.find((item) => item.index === i);
                let value = { time: timeLog?.time, note: timeLog?.note };
                return (
                    <Col className={classnames(styles.padding5_0, styles.centerVertical)} span={Math.floor(18 / weekCount)} key={i}>
                        <HoursInputCell
                            name={`day${i + 1}`}
                            data={value}
                            index={index}
                            phaseMember={phaseMember}
                            onSave={(value: number, note?: string) =>
                                onSave(moment(userTimesheet.startDate).subtract(1, 'd').toISOString(), {
                                    activityId: activity.uuid,
                                    seconds: parseInt((value * 3600).toString()),
                                    note,
                                    date: moment(userTimesheet.startDate).add(i, 'd').toISOString(),
                                    phaseMemberId
                                })
                            }
                            updateTimeLogData={() => {
                                // updateTimeLogData()
                            }}
                        />
                    </Col>
                );
            })}
            <Col style={{background: 'white'}} span={2} className={classNames(styles.alignCenter, styles.leftBorder)}>
                <TotalCell total={total} data={activity} index={index} phaseKey={phaseKey} deleteTimesheet={deleteTimesheet} />
            </Col>
        </Row>
    );
};

export default RowItem;
