export interface IConfigState {
    showFinancials: boolean;
}

export const initialConfigState: IConfigState = {
    showFinancials: true
};

const configReducer = (state: IConfigState, action: { type: string, payload }) => {
    switch (action.type) {
        case 'SHOW_FINANCIALS':
            return {
                ...state,
                showFinancials: action.payload,
            };
        default:
            return state || initialConfigState;
    }
};

export default configReducer;