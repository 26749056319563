import FiltersBar from '../../../components/filterBars/FiltersBar';
import styles from '../../client/AllClientsPage.scss';
import FeatureCard from '../../../components/card/FeatureCard';
import FormatUtils from '../../../utils/FormatUtils';
import IClient from '../../../interfaces/IClient';
import { DollarOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../../redux/store';
import { InvoiceService } from '../../../services/InvoiceService';
import GroupedTable, { EColumnType } from '../../../components/table/groupedTable/GroupedTable';
import Tag from '../../../components/tag/Tag';
import { EFilterCategory, EFilterTypes } from '../../../components/filterBars/FiltersBarMultiselect';

const UnBilled = () => {
    const [invoiceStatusFilter, setInvoiceStatusFilter] = useState(null);
    const [dueDateFilter, setDueDateFilter] = useState(null);
    const [projectFilter, setProjectFilter] = useState(null);
    const [clientsFilter, setClientsFilter] = useState(null);
    const [projectStatusFilter, setProjectStatusFilter] = useState(null);
    const [unbilledData, setUnbilledData] = useState(null);

    const projects = useSelector((state: IStore) => state.project.projectsArray);
    const clients = useSelector((state: IStore) => Object.values(state.client.clients));

    useEffect(() => {
        const fetchUnbilledInvoices = async () => {
            const { data, error } = await InvoiceService.fetchUnbilledInvoices();
            setUnbilledData(Object.values(data));
        };
        fetchUnbilledInvoices();
    }, []);

    const columns = [
        {
            title: 'Project #',
            dataIndex: 'project',
            render: (x)=>{
                return <div className={styles.tag}><Tag color={x.color} text={x.serial}/>{x.name}</div>
            },    
        },
        {
            title: 'Client',
            dataIndex: 'client'
        },
        {
            title: 'Planned Service',
            dataIndex: 'plannedService',
            sorter: (a, b) => a.plannedService - b.plannedService,
            render: (x)=>{
                return FormatUtils.getAmountInFormat(x, true, 2, '0')
            },
            align: "right"
        },
        {
            title: 'Complete',
            dataIndex: 'complete',
            render: (_, record) => {
                const data = Math.floor((record.plannedService / record.invoicedService) * 100)
                if(data==Infinity)
                 return "-"
                return `${data}%`
            },
            sorter: (a, b) => Math.floor((a.plannedService / a.invoicedService) * 100) - Math.floor((b.plannedService / b.invoicedService) * 100),
            align: "right"
        },
        {
            title: 'Invoiced Services',
            dataIndex: 'invoicedService',
            sorter: (a, b) => a.invoicedService - b.invoicedService,
            render: (x)=>{
                return FormatUtils.getAmountInFormat(x, true, 2, '0')
            },
            align: "right"
        },
        {
            title: 'Total Unbilled',
            dataIndex: 'totalUnbilled',
            sorter: (a, b) => a.totalUnbilled - b.totalUnbilled,
            render: (x)=>{
                return  { 
                props: { style: {background: "#EBFAFA"}},
                children : <>{FormatUtils.getAmountInFormat(x, true, 2, '0')}</>
            }

            },
            align: 'right'
        }
        // {
        //     title: '',
        //     dataIndex: 'action',
        //     render: () => (
        //         <Button
        //             type="primary"
        //             style={{ height: '30px' }}
        //             icon={<PlusOutlined style={{ marginRight: 10, marginLeft: -5 }} />}

        //             // disable={invoice.status !== EInvoiceStatus.Submitted || !canApproveThisInvoice(invoice)}
        //             // onClick={() => updateInvoiceStatus(invoice.uuid, EInvoiceStatus.Approved)}
        //         >
        //             invoice
        //         </Button>
        //     )
        // }
        // {
        //     title: 'Due Date',
        //     dataIndex: 'dueDate'
        // }
    ];

    const filters = [
        {
            name: 'Project',
            dataIndex: 'project.uuid',
            type: EFilterTypes.dropdown,
            category: EFilterCategory.simple,
        },
        {
            name: 'Client',
            dataIndex: 'client',
            type: EFilterTypes.dropdown,
            category: EFilterCategory.simple,
        },
    ];

    const GroupByFilters = [
        {
            name: 'Client',
            options: [
                {
                    name: 'Any Status',
                    uuid: 'any'
                },
                {
                    name: 'Active',
                    uuid: 'active'
                },
                {
                    name: 'Inactive',
                    uuid: 'inactive'
                }
            ]
        },
        {
            name: 'Export',
            options: [
                {
                    name: 'Any Status',
                    uuid: 'any'
                },
                {
                    name: 'Active',
                    uuid: 'active'
                },
                {
                    name: 'Inactive',
                    uuid: 'inactive'
                }
            ]
        }
    ];
    const renderCards = (filteredData) => {
        return (
            <div className={styles.cardContainer}>
                <FeatureCard
                    title={FormatUtils.getAmountInFormat(
                        Number(filteredData?.reduce((acc: number, p: any) => acc + p.plannedService || 0, 0)),
                        true
                    )}
                    subtitle="Planned Services"
                    Icon={DollarOutlined}
                />
                <FeatureCard
                    title={FormatUtils.getAmountInFormat(
                        Number(filteredData?.reduce((acc: number, p: any) => acc + p.invoicedService || 0, 0)),
                        true
                    )}
                    subtitle="Invoiced Services"
                    Icon={DollarOutlined}
                />

                <FeatureCard
                    title={FormatUtils.getAmountInFormat(
                        Number(filteredData?.reduce((acc: number, p: any) => acc + p.invoicedService || 0, 0)),
                        true
                    )}
                    subtitle="Total Unbilled"
                    Icon={DollarOutlined}
                />

                {/* <FeatureCard title={Object.keys(clients).length} subtitle="# Pending Invoices" Icon={UserOutlined} />
                <FeatureCard title={Object.keys(clients).length} subtitle="# Projects with pending amounts" Icon={UserOutlined} />
                <FeatureCard title={Object.keys(clients).length} subtitle="# Clients who owe" Icon={UserOutlined} /> */}
            </div>
        )
    }
    return (
        <div>
            
            <GroupedTable columns={columns} data={unbilledData} filters={filters} renderCards={renderCards}/>
            {/* <Row style={{ display: 'flex', alignItems: 'center' }}>
                <h2 style={{ flex: 3, fontWeight: 400 }}>Summary #</h2>
                <p style={{ paddingRight: 10 }}>Group by:</p>
                <div style={{ marginBottom: -20 }}>
                    <FiltersBar filters={GroupByFilters} selectedFilters={{}} />
                </div>
            </Row> */}
        </div>
    );
};
export default UnBilled;
