import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import styles from './Header.scss';

const HeaderSecondary = ({
                             text = null,
                             subtext = null,
                             renderImage = null,
                             renderAction = undefined,
                             renderSubAction = undefined,
                             customStyles = {},
                         }) => {
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const { pathname } = useLocation();

    useEffect(() => {
        setTitleAndSubtitleForPath();
    }, [pathname]);

    const setTitleAndSubtitleForPath = () => {
        const splitLocation = pathname.split('/');

        let _title, _subtitle;
        switch (splitLocation[splitLocation.length - 1]) {
            case 'clients':
                _title = 'Clients';
                _subtitle = 'Access all your client information in one place';
                break;
            case 'projects':
                _title = 'Projects';
                _subtitle = 'this is project\'s header';
                break;
            default:
                _title = ``;
                _subtitle ='';
        }
        setTitle(_title);
        setSubTitle(subTitle);
    };

    return (
        <div className={styles.container} style={{...((subtext || subTitle) ? {} : {marginBottom: 0}), ...customStyles}}>
            {
                renderImage && renderImage()
            }
            <div className={styles.searchbarContainer}>
                <div className={styles.headingContainer}>
                    <div className={styles.title}>
                        {text || title}
                    </div>
                    {(subtext || subTitle) && <div className={styles.subTitle}>{subtext || subTitle}</div>}
                </div>
            </div>
            {
                renderSubAction && renderSubAction()
            }
            {
                renderAction && renderAction()
            }
        </div>
    );
};

export default HeaderSecondary;
