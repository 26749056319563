import React, { useState } from 'react';

import { Col, Dropdown, Modal, Popconfirm, Row, Timeline } from 'antd';
import { BankOutlined, MailOutlined, MessageOutlined, MoreOutlined, PhoneOutlined } from '@ant-design/icons';

import FormatUtils, { EDateTimeFormat } from '../../utils/FormatUtils';

import styles from './Timeline.scss';
import CommunicationService from '../../services/CommunicationService';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import {ICommunication } from '../../redux/reducers/CommunicationReducer';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import { RiCloseFill } from 'react-icons/ri';
import AddCommunication from '../modals/AddCommunication';
import CommunicationModal from '../modals/NewCommunication';

const CommunicationTimeline = ({ data,fetchCommunications=null }: { data: ICommunication[],fetchCommunications?:any  }) => {
    const [showEditCommunication, setShowEditCommunication] = useState(false);
    const [communicationId, setCommunicationId]= useState(null)

    const team = useSelector((state: IStore) => state.team.members);
    
    const getIconByType = (type) => {
        switch (type?.toLowerCase()) {
            case 'email':
                return <MailOutlined />;
            case 'call':
                return <PhoneOutlined />;
            case 'site visit':
                return <BankOutlined />;
            case 'message':
            default:
                return <MessageOutlined />;
        }
    };

    const TimeLabel = ({ time }) => {
        return (
            <div className={styles.timeContainer}>
                {FormatUtils.getDateTimeInFormat(time, EDateTimeFormat.HH_MM_day)}<br />
                {FormatUtils.getDateTimeInFormat(time, EDateTimeFormat.MMM_DD_YYYY)}
            </div>
        );
    };

    // const editCommunication = () => {
    //     const {
    //         data,
    //         error
    //     } = await CommunicationService.updateCommunications({category, type, notes, date.toISOString(), nextFollowup?.toISOString() || '', selectedMemberId, clientOrProjectId);
    //     if (error) {
    //         const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
    //         GenUtils.showNotification('Failed to Add Communication', error, <notificationIcon.icon
    //             style={notificationIcon.style} />);
    //         return;
    //     }
    //     const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
    //     GenUtils.showNotification('Success', 'Added Communication successfully', <notificationIcon.icon
    //         style={notificationIcon.style} />);
    // };

    const deleteCommunication = async (communication: ICommunication) => {
        const {
            data,
            error
        } = await CommunicationService.deleteCommunications(communication.uuid, communication.type, communication.clientId || communication.projectId);
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to delete Communication', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        fetchCommunications();
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Deleted Communication successfully', <notificationIcon.icon
            style={notificationIcon.style} />);
    };
    
    const items = (communication: ICommunication) => [
        // todo uncomment this
        {
            key: 0,
            label: (
                <span
                    onClick={() =>{setShowEditCommunication(true)
                    setCommunicationId(communication)} }>
                        Edit
                    </span>
            )
        },
        {
            key: 1,
            label: <Popconfirm
                title='Are you sure to delete this Communication?'
                onConfirm={() => deleteCommunication(communication)}
                okText='Yes'
                cancelText='No'
            >
                <span>Delete</span>
            </Popconfirm>
        }
    ];

    return (
        <div style={{ border: '0px', padding: 0, margin: 0 }}>
            <Modal
                title="Edit Communication"
                // width={400}
                centered
                open={showEditCommunication}
                onCancel={() => setShowEditCommunication(false)}
                footer={null}
                closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}>
                <CommunicationModal
                    onSubmit={() => {
                        setShowEditCommunication(false)
                        fetchCommunications()
                    }}
                    onClose={()=>{
                        setShowEditCommunication(false)
                    }}
                    currData={communicationId}
                />
            </Modal>
            <Row>
                <Col span={24}>
                    <Timeline mode={'left'}>
                        {
                            data.map((item: any) => {
                                    return <Timeline.Item key={item.uuid} label={<TimeLabel time={item.communicationAt} />}
                                                          dot={getIconByType(item.category)} style={{ marginLeft: '-30%' }}>
                                        <div className={styles.timelineLabelContainer}>
                                            <div className={styles.row}>
                                                <div className={styles.title}>
                                                    {GenUtils.capitalizeFirstLetter(item.category)}
                                                </div>
                                                <div className={styles.subTitle}>
                                                    {`- by ${item.teamMember.firstName} ${item.teamMember.lastName}`}
                                                </div>
                                                <Dropdown
                                                    menu={{ items: items(item) }}
                                                    trigger={['click']}
                                                >
                                                    <MoreOutlined />
                                                </Dropdown>
                                            </div>
                                            <div className={styles.description}>
                                                {item.note}
                                            </div>
                                        </div>
                                    </Timeline.Item>;
                                }
                            )
                        }
                    </Timeline>
                </Col>
            </Row>
        </div>
    );
};

export default CommunicationTimeline;
