import React, { useState } from 'react';
import styles from './ProjectExpense.scss';
import { Col, Dropdown, Menu, Modal, Row, Select, Table } from 'antd';
import type { MenuProps } from 'antd';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import Button from '../../components/button/Button';
import IExpense, { EExpenseStatus } from '../../interfaces/IExpense';
import FormatUtils from '../../utils/FormatUtils';
import { CheckOutlined, CloseOutlined, DownOutlined, PaperClipOutlined, UserOutlined } from '@ant-design/icons';
import Tag from '../../components/tag/Tag';
import Colors from '../../constants/Colors';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import ExpenseService from '../../services/ExpenseService';
import NewExpense from './NewExpense';
import PermissionUtils from '../../utils/PermissionUtils';
import GroupedTable, { EColumnType } from '../../components/table/groupedTable/GroupedTable';
import { EFilterTypes,EFilterCategory } from '../../components/filterBars/FiltersBarMultiselect';

const ProjectExpense = () => {
    const [showExpenseDetails, showDetailsPopup] = useState(null);

    const columnClass = 'hp-p1-body hp-text-color-black-100 hp-text-color-dark-0 ';
    const { expensesArray } = useSelector((state: IStore) => state.expenses);
    const invoices = useSelector((state: IStore) => state?.invoices?.invoices);

    const updateStatus = async (uuid, status: EExpenseStatus.approved | EExpenseStatus.rejected) => {
        const { error, data } = await ExpenseService.updateExpenseStatus(uuid, status);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to update', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Updated Successfully', <notificationIcon.icon
            style={notificationIcon.style} />);
    };
    const filters = [
        {
            name: 'Date',
            dataIndex: 'date',
            type: EFilterTypes.dateRange,
            category:EFilterCategory.simple,
        },
        {
            name: 'Status',
            dataIndex: 'status',
            type: EFilterTypes.dropdown,
            category:EFilterCategory.simple,
        },
        {
            name: 'Project',
            dataIndex: 'projectId',
            type: EFilterTypes.dropdown,
            category:EFilterCategory.advance,
        },
        {
            name: 'Phase',
            dataIndex: 'phaseId',
            type: EFilterTypes.dropdown,
            category:EFilterCategory.advance,
        },
        {
            name: 'Member',
            dataIndex: 'teamMemberId',
            type: EFilterTypes.dropdown,
            category:EFilterCategory.advance,
        },
        {
            name: 'Category',
            dataIndex: 'category.name',
            type: EFilterTypes.dropdown,
            category:EFilterCategory.advance,
        }
    ]
    

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            type: 'date',
        },
        {
            title: 'Project',
            dataIndex: 'projectId',
            type: 'project',
        },
        {
            title: 'Phase',
            dataIndex: 'phaseId',
            type: 'phase',
        },
        {
            title: 'Member',
            dataIndex: 'teamMemberId',
            type: 'member',
        },
        {
            title: 'Category',
            dataIndex: 'category',
            render: (category) => <span className={columnClass}>
                {GenUtils.capitalizeFirstLetter(category?.name) || '-'}
            </span>,
            sorter: (a, b) => a.category?.name.localeCompare(b.category?.name),
        },
        {
            title: 'Note',
            dataIndex: 'note',
            type: 'text',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            type: 'amount',
        },
        {
            title: 'Link',
            dataIndex: 'fileUrl',
            render: (url) => <span className={columnClass}>
                {
                    url
                        ? <a href={url} target='_blank'><PaperClipOutlined /></a>
                        : <PaperClipOutlined />
                }
            </span>
        },
        {
            title: 'Billable',
            dataIndex: 'billable',
            type: 'boolean',
            render: (billable, project) => <span className={columnClass} > {project.isBillable ? <CheckOutlined style={{color: Colors.blue}} /> : '-'}</span>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            type: 'tag'
        },
        {
            title: 'Invoice',
            dataIndex: 'invoiceId',
            render: (invoiceId) => <span className={columnClass}>
                        {invoiceId ? GenUtils.capitalizeFirstLetter(invoices[invoiceId]?.serial) : '-'}
                    </span>
        },
        {
            title: '',
            dataIndex: 'action',
            render: (project, expense) => <span style={{ display: 'flex', gap: 5 }} className={columnClass}>
                {PermissionUtils.canSeeAllExpenses() ? (
                    <Button
                        text={'View'}
                        type='link'
                        small
                        onClick={() => {
                            showDetailsPopup(expense);
                        }}
                    />
                ) : null}
                {PermissionUtils.canSeeExpensesForAssignedProjects() ? (
                    <>
                        <Button
                            text={'Approve'}
                            small
                            onClick={() =>
                                updateStatus(expense?.uuid, EExpenseStatus.approved)
                            }
                            disable={expense?.status === EExpenseStatus.approved || expense?.status === EExpenseStatus.invoiced}
                        />
                        <Button
                            text={'Reject'}
                            small
                            danger
                            onClick={() =>
                                updateStatus(expense?.uuid, EExpenseStatus.rejected)
                            }
                            disable={expense?.status === EExpenseStatus.rejected || expense?.status === EExpenseStatus.invoiced}
                        />
                    </>
                ) : null}
            </span>
        }
    ];
    const groups = [
        {title: 'Project', dataIndex: 'projectId', type: EColumnType.project},
        {title: 'Client', dataIndex: 'project.clientId', type: EColumnType.client},
        {title: 'Member', dataIndex: 'teamMemberId', type: EColumnType.member},
        {title: 'Category', dataIndex: 'category.name', type: EColumnType.text},
    ]
    
    return (
        <div>
            <GroupedTable
                filters={filters}
                columns={columns.map((c) => ({ ...c }))}
                data={expensesArray}
                scrollX={1200}
                groups={groups}
            />
            <Modal
                title={`Expense Details`}
                width={600}
                centered
                open={showExpenseDetails}
                onCancel={() => showDetailsPopup(false)}
                footer={null}
                closeIcon={<CloseOutlined style={{ color: Colors.red }} size={24} />}>
                <NewExpense onHide={() => showDetailsPopup(false)} expenseType={'project'}
                    editMode={showExpenseDetails} />
            </Modal>
        </div>
    );
};

export default ProjectExpense;
