import IMilestone from '../../interfaces/IMilestone';

export interface IMilestoneState {
    milestones: { [key: string]: IMilestone };
    milestonesArray: IMilestone[];
}

export const initialMilestoneState: IMilestoneState = {
    milestones: {},
    milestonesArray: [],
};

const milestoneReducer = (state: IMilestoneState, action: { type: string; payload: IMilestone }) => {
    switch (action.type) {
        case 'SET_MILESTONES':
            return {
                ...state,
                ...action.payload,
            };
        case 'UPDATE_MILESTONE':
            return {
                ...state,
                milestones: {
                    ...state.milestones,
                    [action.payload.uuid]: {
                        ...state.milestones[action.payload.uuid],
                        ...action.payload,
                    },
                },
            };
        default:
            return state || initialMilestoneState;
    }
};

export default milestoneReducer;
