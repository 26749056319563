import ProjectService from "../../services/ProjectService"
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IStore } from "../../redux/store";
import GroupedTable from "../../components/table/groupedTable/GroupedTable";
import GenUtils from "../../utils/GenUtils";
import FormatUtils from "../../utils/FormatUtils";
import TeamAvatar from "../../components/avatar/TeamAvatar";
import { Spin } from "antd";


export default function ProjectFinance() {

    const [selectedProjectId, setSelectedProjectId] = useState('');
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const members = useSelector((state: IStore) => state.team.members);
    const { pathname } = useLocation();

    async function FetchFinance() {
        await ProjectService.fetchProjectFinance(selectedProjectId).then((res) => {
            setData(res?.data)
            setLoading(false)
        })
    }

    useEffect(() => {
        const pathSplit = pathname.split('/');
        const selectedId = pathSplit[pathSplit.indexOf('projects') + 1];
        selectedId && setSelectedProjectId(selectedId);
    }, []);

    useEffect(() => {
        { selectedProjectId && FetchFinance() }
    }, [selectedProjectId])


    const consultantColumn = [
        {
            key: 1,
            dataIndex: "consultantData",
            title: "Consultants",
            render: ((option) => { return GenUtils.capitalizeInitials(option.type.name) })
        },
        {
            key: 2,
            dataIndex: "feeToClient",
            title: "Fee To Client",
            render: (text)=>{
                return FormatUtils.getAmountInFormat(text,true,0,0)
            },
            align: "right"
        },
        {
            key: 3,
            dataIndex: "billedPercentage",
            title: "% Billed",
            render: (text)=>{
                return {
                    props:{
                        style: { background: "#EBFAFA"}
                    },
                    children: `${text.toFixed(2)}%`
                }
            },
            align: "right"
        },
        {
            key: 4,
            dataIndex: "billedAmount",
            title: "Billed",
            render: (text)=>{
                return {
                    props:{
                        style: { background: "#EBFAFA"}
                    },
                    children: FormatUtils.getAmountInFormat(text,true,0,0)
                }
            },
            align: "right"
        },
        {
            key: 5,
            dataIndex: "remaining",
            title: "Remaining",
            render: (text)=>{
                return {
                    props:{
                        style: { background: "#EBFAFA"}
                    },
                    children: FormatUtils.getAmountInFormat(text,true,0,0)
                }
            },
            align: "right"
        },
    ]

    const serviceColumn = [
        {
            key: 1,
            title: "Phase",
            dataIndex: "phase",
            render: ((option) => { return GenUtils.capitalizeInitials(option.type.name) })
        },
        {
            key: 2,
            title: "Fee",
            dataIndex: "phase",
            render: ((option) => { return FormatUtils.getAmountInFormat(option.amount,true,0,0) }),
            align: "right"
        },
        {
            key: 3,
            title: "% Logged",
            dataIndex: "loggedPercent",
            render: (text)=>{
                return `${text.toFixed(2)}%`
            },
            align: "right"
        },
        {
            key: 3,
            title: "Logged",
            dataIndex: "logged",
            render: (text)=>{
                return FormatUtils.getAmountInFormat(text,true,0,0)
            },
            align: "right"
        },
        {
            key: 4,
            title: "Unbilled",
            dataIndex: "unbilled",
            render: (text)=>{
                const amount = Math.abs(text)
                return FormatUtils.getAmountInFormat(amount,true,0,0)
            },
            align: "right"
        },
        {
            key: 4,
            title: "% Billed",
            dataIndex: "billedPercent",
            render: (text)=>{
                return {
                    props:{
                        style: { background: "#EBFAFA"}
                    },
                    children: `${text.toFixed(2)}%`
                }
            },
            align: "right"
        },
        {
            key: 5,
            title: "Billed",
            dataIndex: "billed",
            render: (text)=>{
                return {
                    props:{
                        style: { background: "#EBFAFA"}
                    },
                    children: FormatUtils.getAmountInFormat(text,true,0,0)
                }
            },
            align: "right"
        },
        {
            key: 6,
            title: "Remaining",
            dataIndex: "remaining",
            render: (text)=>{
                return {
                    props:{
                        style: { background: "#EBFAFA"}
                    },
                    children: FormatUtils.getAmountInFormat(text,true,0,0)
                }
            },
            align: "right"
        }
    ]

    const expenseColumn = [
        {
            key: 1,
            title: "Date",
            dataIndex: "createdAt",
            render: ((option) => { return FormatUtils.getFormattedDateFromTimestamp(option) })
        },
        {
            key: 4,
            title: "Category",
            dataIndex: "category",
            render:(text)=>{
                return text.name
            }
        },
        {
            key: 2,
            title: "Amount",
            dataIndex: "totalAmount",
            render: (text)=>{
                return FormatUtils.getAmountInFormat(text,true,0,0)
            },
            align: "right"
        },
        {
            title: 'Phase',
            dataIndex: 'phaseId',
            type: 'phase',
        },
        {
            title: 'Member',
            dataIndex: 'teamMemberId',
            render: ((option) => {
                return <TeamAvatar name={members[option]?.firstName + ' ' + members[option]?.lastName} />
            })
        },
        {
            key: 3,
            title: "Note",
            dataIndex: "note"
        },
        {
            title: 'Status',
            dataIndex: 'status',
            type: 'tag'
        },
        {
            title: 'Billable',
            dataIndex: 'isBillable',
            render: ((option) => {
                if (option === null) return <span>-</span>
            })
        },
    ]

    return (
        <>
            <Spin tip="Loading" size="large" style={{ display: loading ? "block" : "none" }} />
            {
                data ?
                    <div>
                        <p style={{ fontWeight: "400", fontSize: "18px", margin: "16px 0" }}>Services</p>
                        {data && <GroupedTable data={data.finalServices} columns={serviceColumn} />}
                        <p style={{ fontWeight: "400", fontSize: "18px", margin: "16px 0" }}> Sub-Consultants </p>
                        {data && <GroupedTable data={data.finalConsultants} columns={consultantColumn} />}
                        <p style={{ fontWeight: "400", fontSize: "18px", margin: "16px 0" }}>Expenses</p>
                        {data && <GroupedTable data={data.expenses} columns={expenseColumn} />}
                    </div>
                    : null
            }
        </>
    )
}