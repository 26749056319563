export enum ECommunicationCategory {
    call = 'call',
    email = 'email',
    message = 'message',
}

export enum ECommunicationType {
    project = 'project',
    client = 'client',
    finance = 'finance',
    task = 'task',
    other = 'other',
    communication = 'communication'
}

export interface ICommunication {
    uuid?: string;
    category?: ECommunicationCategory,
    type?: ECommunicationType,
    note?: string,
    communicationAt?: string,
    followUpAt?: string,
    teamMemberId?: string,
    clientId?: string,
    projectId?: string,
    taskId?: string,
    contactId?: string
}

export interface IAllCommunicationState {
    client: ICommunicationState,
    project: ICommunicationState,
    finance: ICommunicationState,
    task: ICommunicationState,
    other: ICommunicationState,
}

export const initialCommunicationState: IAllCommunicationState = {
    client: {},
    project: {},
    finance: {},
    task: {},
    other: {},
};

export interface ICommunicationState {
    [key: string]: {
        communications: {},
        communicationsArray: ICommunication[]
    }
}


const communicationReducer = (state: IAllCommunicationState, action: { type: string, payload }) => {
    switch (action.type) {
        case 'SET_COMMUNICATION':
            return {
                ...state,
                [action.payload.type]: {
                    ...state[action.payload.type],
                    [action.payload.typeId]: {
                        communications: action.payload.communications,
                        communicationsArray: action.payload.communicationsArray
                    }
                }
            };
        default:
            return state || initialCommunicationState;
    }
};

export default communicationReducer;