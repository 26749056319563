import styles from './TaskDetail.scss';
import { Col } from 'antd';
import Tag from '../../components/tag/Tag';
import GenUtils from '../../utils/GenUtils';
import FormatUtils from '../../utils/FormatUtils';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import { communicationSelector } from '../../redux/selectors/CommunicationSelector';
import { ECommunicationType } from '../../redux/reducers/CommunicationReducer';

const TaskDetailCard = ({ task, selectTask,id}) => {
    const { communicationsArray } = useSelector((state: IStore) => communicationSelector(state, ECommunicationType.task, task.uuid));

const selected = id===task.uuid

  
    return (
                <div className={classnames(styles.AllTaskDetail,selected && styles.containerSelected)} onClick={() => selectTask(task.uuid)} >
                    <div className={styles.taskName} >{GenUtils.capitalizeInitials(task.name)}</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Col>
                            <div style={{ paddingBottom: 5 }}>{GenUtils.capitalizeInitials(task.type.name)}</div>
                            <div style={{ display: 'flex', gap: 5, fontSize: "13px" }}>
                                <Tag text={task.project?.serial} color={task.project?.color} />
                                {GenUtils.capitalizeInitials(task.project?.name)}
                            </div>
                        </Col>
                        <Col>
                            <div>{FormatUtils.getFormattedDateFromTimestamp(task.date)}</div>
                            <div>{communicationsArray.length} Comments</div>
                        </Col>
                    </div>
                </div>
    );
};
export default TaskDetailCard;
