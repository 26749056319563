import IInvoice from '../../interfaces/IInvoice';
import GenUtils from '../../utils/GenUtils';

export const setInvoices = (invoices: IInvoice[]) => {
    return {
        type: 'SET_INVOICES',
        payload: GenUtils.arrayToIdMap(invoices),
    };
}

export const setLastInvoice = (invoice: IInvoice) => {
    return {
        type: 'SET_LAST_INVOICE',
        payload: invoice,
    };
}

export const updateInvoiceAction = (invoiceId, invoice: IInvoice) => {
    return {
        type: 'UPDATE_INVOICE',
        payload: { uuid: invoiceId, invoice },
    };
}