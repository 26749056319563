import IMilestone from '../../interfaces/IMilestone';
import GenUtils from '../../utils/GenUtils';

export const setMilestones = (milestones: IMilestone[]) => {
    return {
        type: 'SET_MILESTONES',
        payload: {
            milestones: GenUtils.arrayToIdMap(milestones),
            milestonesArray: milestones,
        },
    };
};

export const updateMilestone = (milestoneId, milestone: IMilestone) => {
    return {
        type: 'UPDATE_MILESTONE',
        payload: { uuid: milestoneId, ...milestone }
    };
};
