import IExpense from '../../interfaces/IExpense';

export const setExpenses = (expenses: IExpense[]) => {
    return {
        type: 'SET_EXPENSES',
        payload: expenses
    };
};

export const updateExpense = (uuid, expense) => {
    return {
        type: 'UPDATE_EXPENSE',
        payload: { uuid, ...expense }
    };
};