// @ts-nocheck
import React from 'react';

import { Divider, Table } from 'antd';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import FormatUtils from '../../utils/FormatUtils';
import GenUtils from '../../utils/GenUtils';

const ActivityMonitor = () => {
    const columnClass = 'hp-p1-body hp-text-color-black-100 hp-text-color-dark-0 ';
    const dividerClass = 'hp-border-color-black-40 hp-border-color-dark-80';

    // const [selectedUserId, setSelectedUserId] = useState('');
    // const user = useSelector((state: IStore) => getUserDetails(state, selectedUserId));
    //
    // const location = useLocation();
    // const { pathname } = location;
    //
    // useEffect(() => {
    //   const pathSplit = pathname.split('/');
    //   const selectedId = pathSplit[pathSplit.indexOf('team') + 1];
    //   selectedId && setSelectedUserId(selectedId);
    // }, [pathname])

    const activity = useSelector((state: IStore) => state.activity.activityLog);

    const columns = [
        {
            title: 'Client',
            dataIndex: 'client',
            width: 200,
            render: (text, data) => <span className={columnClass}>{data.agent.client.name}</span>
        },
        {
            title: 'OS',
            dataIndex: 'os',
            width: 200,
            render: (text, data) => <span className={columnClass}>{data.agent.os?.name}</span>
        },
        {
            title: 'Time',
            dataIndex: 'time',
            render: (text, data) => <span
                className={columnClass}>{FormatUtils.getFormattedDateFromTimestamp(data.createdAt, true)}</span>
        },
        {
            title: 'Device',
            dataIndex: 'device',
            render: (text, data) => <span className={columnClass}>{data.agent.device?.brand}</span>
        }
        // {
        //     title: '#',
        //     dataIndex: 'action',
        //     align: 'right',
        //     render: (text) => (
        //         <Button
        //             type='text'
        //             className='hp-p-0 hp-p1-body hp-text-color-black-100 hp-text-color-dark-0 hp-font-weight-500'
        //         >
        //             {text}
        //         </Button>
        //     )
        // }
    ];

    return (
        <div className='hp-profile-activity'>
            <h2 className='hp-mb-4'>Login Activity</h2>
            <p className='hp-p1-body hp-mb-0'>
                Monitor account activity, location and time.
            </p>

            <Divider className={dividerClass} />

            <Table
                className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                columns={columns}
                dataSource={activity.map(ac => {
                    return { ...ac, key: ac.uuid };
                })}
                pagination={{ position: ['none'] }}
                size='middle'
            />
        </div>
    );
};
export default ActivityMonitor;
