import moment from 'moment';
import ApiURLs from '../constants/ApiURLs';
import { setProjects, setTimesheetAudits, setTimesheetLog, setTimesheetSubmissions } from '../redux/actions/TimesheetActions';
import { APIUtil } from '../utils/ApiUtils';
import GenUtils from '../utils/GenUtils';
import MomentUtils from '../utils/MomentUtils';

export interface TAudits {
    projectsObj: { [key: string]: ProjectsObj };
    phasesObj: { [key: string]: PhasesObj };
    phasesMembersObj: { [key: string]: PhasesMembersObj };
    timesheetsObj: TimesheetsObj;
    activitiesObj: { [key: string]: ActivitiesObj };
}

export interface ActivitiesObj {
    billType: null | string;
    name: string;
    type: string;
    userUuid: string;
    uuid: string;
    createdAt: Date;
    updatedAt: Date;
    feeType?: string;
    abbreviation?: string;
}

export interface PhasesMembersObj {
    teamMemberId: string;
    roleId: string;
    budgetHours: number;
    budgetAmount: number;
    phaseId: string;
    projectId: string;
    userUuid: string;
    uuid: string;
    createdAt: Date;
    updatedAt: Date;
    role: Role;
    teamMember: TeamMember;
}

export interface Role {
    hourRate: number;
    name: string;
    userUuid: string;
    uuid: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface TeamMember {
    email: string;
    firstName: string;
    lastName: string;
    dob: Date;
    profilePicUrl: null;
    profilePicFileId: null | string;
    contact: string;
    permissionId: string;
    status: string;
    office: null | string;
    userUuid: string;
    userId: number;
    uuid: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface PhasesObj {
    linkedPhaseId: null;
    startAt: Date;
    endAt: Date;
    days: null;
    feeType: string;
    amount: number;
    projectId: string;
    typeId: string;
    isAllocationActive: boolean;
    userUuid: string;
    uuid: string;
    createdAt: Date;
    updatedAt: Date;
    type: ActivitiesObj;
}

export interface ProjectsObj {
    name: string;
    serial: number;
    color: string;
    templateId: null;
    clientId: string;
    type: string;
    projectFee: number;
    consultantFee: number;
    status: string;
    startAt: Date;
    endAt: Date;
    addressId: null;
    note: null;
    invoiceApproval: boolean;
    userUuid: string;
    uuid: string;
    createdAt: Date;
    updatedAt: Date;
    phaseMemberActivities: string[];
}

export interface TimesheetsObj {
    [key: string]: number | string;
}

export default class TimeSheetService {
    static isLocked(entry): boolean {
        // const isLockedBefore = moment(entry.lockedAt).isBefore(moment());
        return entry.lockEnable;
    }

    static async fetchTimeSheetDataForMember(date = '', teamMemberId = '') {
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.GET_TIMESHEET}?date=${date}&teamMemberId=${teamMemberId}`);
        if (error) {
            GenUtils.log('Get Allocation', error);
            return { error };
        }
        GenUtils.dispatch(setTimesheetLog(data));
        await this.fetchProjectsData(date, teamMemberId);
        return { data };
    }

    static async fetchProjectsData(date = '', teamMemberId = '') {
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.GET_DETAILS}?date=${date}&teamMemberId=${teamMemberId}`);
        if (error) {
            GenUtils.log('Get Allocation', error);
            return { error };
        }
        GenUtils.dispatch(setProjects(data));
    }

    static async postTimelogData(date: string, teamMemberId, timelogData) {
        const { data, error } = await APIUtil.makePostRequest(`${ApiURLs.ADD_TIMESHEET}`, { ...timelogData, teamMemberId });
        if (error) {
            GenUtils.log('post timelog', error);
            return { error };
        }
        this.fetchTimeSheetDataForMember(date, teamMemberId);
        return { data };
    }
    static async updateTimesheet(teamMemberId = '', timelogData, date?: Date) {
        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.UPDATE_TIMESHEET}`, { ...timelogData, teamMemberId });
        if (error) {
            GenUtils.log('Get Allocation', error);
            return { error };
        }

        this.fetchTimeSheetDataForMember(date.toISOString(), teamMemberId);
        return { data };
    }

    static async updateTimesheetWeek(
        dataForUpdate: { date: string; seconds: number; activityId: string; phaseMemberId: string; note?: string },
        date: string,
        teamMemberId: string
    ) {
        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.UPDATE_TIMESHEET_WEEK}`, dataForUpdate);
        if (error) {
            GenUtils.log('Get Allocation', error);
            return { error };
        }
        console.log(data, 'data');
        this.fetchTimeSheetDataForMember(date, teamMemberId);
        return { data };
    }

    static async updateTimesheetActivityWeek(dataForUpdate: {
        date: string;
        activityId: string;
        // projectId: string;
        teamMemberId: string;
        newActivityId: string;
        phaseMemberId: string;
    }) {
        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.UPDATE_TIMESHEET_ACTIVITY_WEEK}`, dataForUpdate);
        if (error) {
            GenUtils.log('Get Allocation', error);
            return { error };
        }
        console.log(data, 'data');
        this.fetchTimeSheetDataForMember(dataForUpdate.date, dataForUpdate.teamMemberId);
        return { data };
    }

    static async fetchTimeEntries(weekRange) {
        const { data, error } = await APIUtil.makeGetRequest(
            `${ApiURLs.GET_TIME_ENTRIES}?fromDate=${MomentUtils.getGmtDayStart(moment(weekRange[0]))}&toDate=${MomentUtils.getGmtDayStart(
                moment(weekRange[1])
            )}`
        );
        if (error) {
            GenUtils.log('fetch Time Entries', error);
            return { error };
        }
        return { data };
    }

    static async fetchTimesheetSubmission(date = new Date(), isWeekly = true) {
        let qStringObject = {
            date: MomentUtils.getGmtDayStart(moment(date)),
            isWeekly
        };
        const { data, error } = await APIUtil.makeGetRequest(
            `${ApiURLs.GET_TIMESHEET_SUBMISSIONS}?${Object.keys(qStringObject)
                .map((key) => `${key}=${qStringObject[key]}`)
                .join('&')}`
        );
        if (error) {
            GenUtils.log('fetch Time Entries', error);
            return { error };
        }
        GenUtils.dispatch(setTimesheetSubmissions(data));
        return { data };
    }
    static async fetchTimesheetAudits(date = new Date()) {
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.GET_AUDITS}?date=${MomentUtils.getGmtDayStart(moment(date))}`);
        if (error) {
            GenUtils.log('fetch Time Entries', error);
            return { error };
        }
        GenUtils.dispatch(setTimesheetAudits(data));
        return { data };
    }

    static async updateMemberTimesheet(updatedData) {
        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.PUT_MEMBER_TIMESHEET}`, updatedData);
        if (error) {
            GenUtils.log('Get Allocation', error);
            return { error };
        }
        return { data };
    }

    static async submitTimesheet(submissionData) {
        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.SUBMIT_TIMESHEET}`, submissionData);
        if (error) {
            GenUtils.log('submit timesheet', error);
            return { error };
        }
        return { data };
    }

    static async deleteTimesheet(uuids = [], teamMemberId: string, date?: string) {
        const { data, error } = await APIUtil.makeDeleteRequest(`${ApiURLs.GET_TIMESHEET}/${uuids.join(',')}?teamMemberId=${teamMemberId}`);
        if (error) {
            GenUtils.log('Get Allocation', error);
            return { error };
        }
        this.fetchTimeSheetDataForMember(date, teamMemberId);
        return { data };
    }
}
