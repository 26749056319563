import { Dropdown, Popover } from "antd";
import ProjectService from "../../services/ProjectService";
import GenUtils, { ENotificationType } from "../../utils/GenUtils";
import EditProjectStatus from "../modals/EditProjectStatus";
import Button from "./Button";
import { DownOutlined } from "@ant-design/icons";
import { useState } from "react";

const ProjectMoreButton = ({ project, style = {} }) => {
    const [showPop, setShowPop] = useState(false);

    const editProjectStatus = async (projectUuid, status) => {
        setShowPop(false);
        const { data, error } = await ProjectService.updateProjectStatus({
            uuid: projectUuid,
            status,
            clientId: projectUuid.clientId
        });
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Update', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
    };

    const renderEditProjectStatus = (project) => {
        return (
            <EditProjectStatus
                project={project}
                onClick={(status) => {
                    editProjectStatus(project.uuid, status);
                }}
            />
        );
    };

    const deleteProject = async (projectId) => {
        const { data, error } = await ProjectService.deleteProject(projectId);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification(
                'Failed',
                error,
                <notificationIcon.icon style={notificationIcon.style} />
            );
            return;
        }

        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification(
            'Success',
            '',
            <notificationIcon.icon style={notificationIcon.style} />
        );
    }

    const getMenuItems = (project) => [
        // todo complete with reports module
        // {
        //     key: 0,
        //     label: <span onClick={openProjectReport}>Project Accounting Report</span>
        // },
        {
            key: 1,
            label: (
                <Popover
                    content={renderEditProjectStatus(project)}
                    title='Edit Status'
                    trigger='click'
                    open={showPop}
                >
                    <div onClick={() => setShowPop(true)}>Edit Project Status</div>
                </Popover>
            )
        },
        // {
        //     key: 1,
        //     label: (
        //         <span
        //             // onClick={() => setRecordingPayment(project.uuid)}
        //         >
        //                     Save As Template
        //                 </span>
        //     )
        // },
        // {
        //     key: 2,
        //     label: (
        //         <Popconfirm
        //             title='Are you sure to delete this Project?'
        //             onConfirm={() => deleteProject(project.uuid)}
        //             okText='Yes'
        //             cancelText='No'
        //         >
        //             <Button
        //                 danger
        //                 onClick={()=>{}}
        //                 text='Delete Project'
        //             />
        //         </Popconfirm>
        //     )
        // },
    ];

    return (
        <Dropdown menu={{ items: getMenuItems(project) }} trigger={['click']}>
            <Button text='More' onClick={() => null} type='default' icon={<DownOutlined style={{ marginRight: 10, marginLeft: -5}} />} style={style} />
        </Dropdown>
    )
}

export default ProjectMoreButton;