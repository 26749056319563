import React, { useState, useEffect } from 'react';
import FormatUtils from '../../utils/FormatUtils';

function TimeDifference({ startTime, endTime }) {
    const [timeDifference, setTimeDifference] = useState(0);

    useEffect(() => {
        if (!startTime) return;
        if(endTime){
          const timeDifference = Math.floor((new Date(endTime).getTime() -new Date(startTime).getTime()) / 1000);
            setTimeDifference(timeDifference);
            return;
        }
        const timer = setInterval(() => {
            const currentTime = new Date();
            const timeDifference = Math.floor((currentTime.getTime() -new Date(startTime).getTime()) / 1000);
            setTimeDifference(timeDifference);
        }, 1000);
        return () => clearInterval(timer);
    }, [startTime, endTime]);

    return <p>{FormatUtils.formatTime(timeDifference)}</p>;
}

export default TimeDifference;
