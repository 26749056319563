import { useState } from 'react';
import classnames from 'classnames';
import styles from './SignupPage.scss';
import SignupStep3 from './SignupStep3';
import SignupStep1 from './SignupStep1';
import SignupStep2 from './SignupStep2';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import CopyrightFooter from '../../components/footer/CopyrightFooter';
import AuthService from '../../services/AuthService';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import { Forbidden } from '../../components/Forbidden';

const SignupPage = () => {

    const [email, setEmail] = useState('');
    const [firmName, setFirmName] = useState('');
    const [firmSize, setFirmSize] = useState('');
    const [firmLocation, setFirmLocation] = useState('');
    const [industry, setIndustry] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');

    const [currency, setCurrency] = useState('');
    const [timeZone, setTimeZone] = useState('');
    const [dateFormat, setDateFormat] = useState('');

    const step = useSelector((state: IStore) => state?.auth?.signupStep || 0);

    const renderSignupForm = () => {
        switch (step) {
            case 1:
                return <SignupStep1
                    email={email}
                    setEmail={setEmail}
                    firmName={firmName}
                    setFirmName={setFirmName}
                    firmSize={firmSize}
                    setFirmSize={setFirmSize}
                    firmLocation={firmLocation}
                    setFirmLocation={setFirmLocation}
                    industry={industry}
                    setIndustry={setIndustry}
                />;
            case 2:
                return <SignupStep2
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    password={password}
                    setPassword={setPassword}
                />;
            case 3:
                return <SignupStep3
                    currency={currency}
                    setCurrency={setCurrency}
                    timeZone={timeZone}
                    setTimeZone={setTimeZone}
                    dateFormat={dateFormat}
                    setDateFormat={setDateFormat}
                    onSubmit={onFinish}
                />;
            default:
                return <SignupStep1
                    email={email}
                    setEmail={setEmail}
                    firmName={firmName}
                    setFirmName={setFirmName}
                    firmSize={firmSize}
                    setFirmSize={setFirmSize}
                    firmLocation={firmLocation}
                    setFirmLocation={setFirmLocation}
                    industry={industry}
                    setIndustry={setIndustry}
                />;
        }
    };

    const navigateTo = useNavigate();
    const dispatch = useDispatch();

    const onFinish = async (values: any) => {
        const { data, error } = await AuthService.signup({
            email,
            firmName,
            firmSize,
            firmLocation,
            industry,
            firstName,
            lastName,
            password,
            currency,
            timeZone,
            dateFormat
        }, dispatch);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Signup Failed', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Signup Successful', error, <notificationIcon.icon style={notificationIcon.style} />);

        setEmail('');
        setFirmName('');
        setFirmSize('');
        setFirmLocation('');
        setIndustry('');
        setFirstName('');
        setLastName('');
        setPassword('');
        setCurrency('');
        setTimeZone('');
        setDateFormat('');

        navigateTo(`/verify`, { state: { email: data.email } });
    };

    // return (

    //     <form className={classnames(styles.section, styles.form)}>
    //         <p className={styles.subtitle}>SIGN UP FOR FREE</p>
    //         <h1 className={styles.title}>Create Account</h1>
    //         {
    //             renderSignupForm()
    //         }
    //         <div className={styles.loginNavigate}>
    //             <p className={styles.loginText}>Already have an account?</p>
    //             <div
    //                 className={styles.loginButton}
    //                 onClick={() => {
    //                     navigateTo('/login');
    //                 }}>Login
    //             </div>
    //         </div>
    //         <CopyrightFooter />
    //     </form>
    // );

    return <Forbidden style={{width: '100%'}} />
};

export default SignupPage;
