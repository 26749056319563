import styles from './StepTabs.scss';
import classnames from 'classnames';

const StepTabs = ({ tabs = [], activeIndex = 0, activeOption = null, onClick=(i) => {}}) => {

        const isActive = (indexOrTitle) => {
            return indexOrTitle === activeOption || indexOrTitle === activeIndex;
        };

        return (
            <div
                className={
                    classnames(
                        styles.container,
                        isActive(0) && styles.firstSelected,
                        isActive(tabs.length - 1) && styles.lastSelected,
                    )
                }
            >
                {
                    tabs.map((tab, index) => (
                        <div className={styles.tabContainer} onClick={() => onClick(index)} key={tab.title}>
                            <div
                                className={
                                    classnames(
                                        styles.arrowTop,
                                        index === 0 && styles.first,
                                        index === tabs.length - 1 && styles.last,
                                        isActive(index) && styles.selected,
                                        !isActive(index) && styles.notSelected,
                                        isActive(index + 1) && styles.last
                                    )
                                }
                            />
                            <div
                                className={
                                    classnames(
                                        styles.arrowBottom,
                                        index === 0 && styles.first,
                                        index === tabs.length - 1 && styles.last,
                                        isActive(index) && styles.selected,
                                        !isActive(index) && styles.notSelected,
                                        isActive(index + 1) && styles.last
                                    )
                                }
                            />
                            <div className={styles.textContainer}>
                                <b className={isActive(index) ? styles.selected : ''}>{tab.title}</b>
                                <p>{tab.subTitle}</p>
                            </div>
                        </div>
                    ))
                }
            </div>
        );
    }
;

export default StepTabs;