import moment from 'moment';
import { IAddress } from '../interfaces/IUser';

export enum EDateTimeFormat {
    MMM_DD_YYYY = 'MMM DD, YYYY',
    DD_MMM_YYYY = 'DD MMM, YYYY',
    DD_MMM_YY = 'DD MMM, YY',
    DD_MMM = 'DD MMM',
    HH_MM_a_day = 'HH:mm a, dddd',
    HH_MM_day = 'HH:mm, ddd',
    HH_MM_a = 'HH:mm a',
    HH_mm_ss = 'HH:mm:ss'
}

export default class FormatUtils {
    static timeFormat = 'hh:mm a';
    static dateFormat = 'DD/MM/YYYY';
    static amountFormat = 'indian';
    static currencyIso = 'INR';

    static getFormattedDateFromTimestamp(date: any, dateTime = false): string {
        return moment(date).isValid()
            ? moment(date).format(dateTime ? FormatUtils.dateFormat.toUpperCase() + ' ' + FormatUtils.timeFormat : FormatUtils.dateFormat.toUpperCase())
            : '-';
    }
    static getMonthAndYearFromDateTime(date: any): string {
        return moment(date).format('MMMM YYYY');
    }
    static getRemDaysInFormat(dueDate: any): string {
        const today = moment().startOf('day');
        const dueDateMoment = moment(dueDate).startOf('day');
        let diff = today.diff(dueDateMoment, 'days');
        if (diff < 0) diff = 0;
        const val = Math.floor(diff / 10);
        if (val <= 1) return 'Current';
        else if (val <= 2) return '31-60 Days';
        else if (val <= 3) return '61-90 Days';
        return 'More than 90 days';
    }
    static getOverDueStatus(date: any, status: any): string {
        const today = moment().startOf('day');
        const inputDate = moment(date).startOf('day');
        if (today.isAfter(inputDate) && (status === 'pending' || status === 'partially_paid')) return 'Overdue';
        return 'Not Overdue';
    }
    static getTimeInFormatFromTimeStamp(date: Date): string {
        return moment(date).format(FormatUtils.timeFormat);
    }

    static getDayDiff(date: any): string {
        // todo in 10 days | today | 3 days ago;
        return '3 days ago';
    }

    static getHoursDiff(date: any) {
        // todo in 10 hours | now | 3 hours ago;
        return '3 hours ago';
    }

    static getDateTimeInFormat(date: any, format: EDateTimeFormat): string {
        return moment(date).format(format);
    }

    static getAddressString(address?: IAddress) {
        return address ? `${address.building} ${address.street}, ${address.city}, \n${address.state} - (${address.zipCode})\n${address.country}` : '-';
    }

    static getAmountInFormat(
        amount: number,
        showCurrency = false,
        maximumFractionDigits = 0,
        defaultValue: number | string = '-',
        showNegativeAsDefaultValue = false
    ) {
        return amount && (!showNegativeAsDefaultValue || amount >= 0)
            ? amount.toLocaleString(FormatUtils.amountFormat === 'indian' ? 'en-IN' : 'en-US', {
                  maximumFractionDigits,
                  ...(showCurrency
                      ? {
                            style: 'currency',
                            currency: FormatUtils.currencyIso
                        }
                      : {})
              })
            : defaultValue;
    }

    static getDaysDiffText(date, week = false) {
        if (!date) return '-';
        const now = moment();
        let days = now.diff(date, 'days');
        const weeks = now.diff(date, 'weeks');
        let result = '';

        if (week) {
            result += weeks + (weeks === 1 ? ' week ' : ' weeks ');
            days = days % 7;
        } else if (days || weeks === 0) {
            days = days < 0 ? Math.abs(days) : days;
            result += days + (days === 1 ? ' day ' : ' days ');
        }
        return result + 'ago';
    }

    static formatTime = (time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    static getCurrency = () => {
        return (FormatUtils.getAmountInFormat(1, true) + '').replace('1', '');
    };

    static getHoursFromSeconds = (seconds) => {
        return Number((seconds / 3600).toFixed(2));
    };
}
