import HeaderSecondary from '../components/header/HeaderSecondary';
import Button from '../components/button/Button';
import DashboardIcon from '../assets/images/dashboard.svg';
import Gantt from '../components/timeline/Gantt';
import { useSelector } from 'react-redux';
import { IStore } from '../redux/store';
import { Card, Dropdown, Popconfirm, Select, Table, TablePaginationConfig } from 'antd';
import NavigationUtils from '../utils/NavigationUtils';
import Tag from '../components/tag/Tag';
import GenUtils, { ENotificationType } from '../utils/GenUtils';
import TeamAvatar from '../components/avatar/TeamAvatar';
import FormatUtils from '../utils/FormatUtils';
import { MoreOutlined } from '@ant-design/icons';
import ITask from '../interfaces/ITask';
import TaskService from '../services/TaskService';
import { getUserDetails } from '../redux/selectors/UserSelector';
import MomentUtils from '../utils/MomentUtils';
import moment from 'moment';
import styles from './Dashboard.scss';
import { APIUtil } from '../utils/ApiUtils';
import TeamService from '../services/TeamService';
import _ from 'lodash';
import PieChart from '../components/dashboard/PieChart';
import { useEffect, useState } from 'react';

//TODO: Move this to a common place and add Types

const TaskTable = ({ tasksArray, loggedInUser, columns, loading }) => {
    const sortedTasksArray = _.sortBy(_.cloneDeep(tasksArray), ['date']);

    const filteredTasks = sortedTasksArray?.filter((task) => {
        const isAssignedToUser = task.assignees.some((assignee) => assignee.email === loggedInUser.email);
        const isInProgress = task.status === 'pending';
        return isAssignedToUser && isInProgress;
    });

    const dataSource = filteredTasks.map((task) => ({ ...task, key: task.uuid }));

    const pagination: false | TablePaginationConfig =
        filteredTasks?.length > 10
            ? {
                  position: ['bottomRight'],
                  showSizeChanger: false,
                  showQuickJumper: false,
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                  pageSize: 10
              }
            : false;

    return <Table pagination={pagination} dataSource={dataSource} columns={columns} loading={loading} />;
};

const Dashboard = () => {
    const loggedInUser = useSelector(getUserDetails);
    const { phase } = useSelector((state: IStore) => state.options);
    const firmId = useSelector((state: IStore) => state?.firm?.uuid);
    const projectsArray = useSelector((state: IStore) => state.project.projectsArray);
    const tasksArray = useSelector((state: IStore) => state.tasks.tasksArray || []);
    const timesheetsSummary = useSelector((state: IStore) => state.team.timesheetsSummary);
    const isTasksLoading = useSelector((state: IStore) => state.tasks.loading);

    const deleteTask = async (taskId) => {
        const { error } = await TaskService.deleteTask(taskId);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to delete', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }

        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Task deleted successfully', '', <notificationIcon.icon style={notificationIcon.style} />);
    };

    const getTimesheetsSummaryData = async () => {
        const { data, error } = await TeamService.fetchTeamTimesheetsSummary();

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to fetch timesheet data', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }
    };

    useEffect(() => {
        getTimesheetsSummaryData();
        fetchData('last_week');
    }, []);

    const getMenuItems = (task: ITask) => {
        return [
            {
                key: 1,
                label: (
                    <Popconfirm title="Are you sure to delete this task?" onConfirm={() => deleteTask(task.uuid)} okText="Yes" cancelText="No">
                        <Button danger onClick={() => null} text="Delete Task" />
                    </Popconfirm>
                )
            }
        ];
    };

    const columns = [
        {
            title: 'Task',
            dataIndex: 'name',
            span: 4,
            render: (name, task) => <span onClick={() => NavigationUtils.navigateTo(`/${firmId}/task/${task.uuid}`)}>{name}</span>
        },
        {
            title: 'Project',
            dataIndex: 'project',
            span: 4,
            render: (project) => (
                <div style={{ display: 'flex', gap: 10 }}>
                    <Tag text={project?.serial} color={project?.color} />
                    {GenUtils.capitalizeInitials(project?.name)}
                </div>
            )
        },
        {
            title: 'Phase',
            dataIndex: 'phase',
            span: 3,
            render: (_phase) => (
                <div style={{ display: 'flex' }}>
                    <Tag color={_phase?.project?.color} text={phase?.find((p) => p.uuid === _phase?.typeId)?.abbreviation} />
                </div>
            )
        },
        {
            title: 'Task Type',
            dataIndex: 'type',
            span: 3,
            render: (type) => type?.name || '-'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            span: 3,
            render: (status) => (
                <div style={{ display: 'flex' }}>
                    <Tag text={status} color={GenUtils.getTagColorByStatus(status)} />
                </div>
            )
        },
        {
            title: 'Assignees',
            dataIndex: 'assignees',
            span: 3,
            render: (members) => <div>
                <TeamAvatar image={members && members[0]?.profilePicUrl} members={members} name={members[0]?.firstName}/>
            </div>
        },
        {
            title: 'Due Date',
            dataIndex: 'date',
            span: 2,
            render: (date) => {
                return FormatUtils.getFormattedDateFromTimestamp(date);
            }
        },
        {
            title: '',
            dataIndex: 'menu',
            span: 2,
            render: (t, task) => (
                <Dropdown menu={{ items: getMenuItems(task) }} trigger={['click']}>
                    <MoreOutlined />
                </Dropdown>
            )
        }
    ];
    const [selected, setSelected] = useState('last_week');
    const [pieChartData, setPieChartData] = useState([]);
    const fetchData = async (type: string) => {
        const { data } = await APIUtil.makeGetRequest(`${process.env.REACT_APP_DOMAIN}/api/team/work/?workType=${type}`);
        setPieChartData(data);
    };

    const currentDate = moment();
    const { thisWeekData, lastWeekData, secondLastWeekData } = timesheetsSummary || {};
    const individualTimesheet = [
        {
            hrs: thisWeekData?.totalHours,
            text: 'This Week',
            range: MomentUtils.getWeekDates(currentDate.toDate())
        },
        {
            hrs: lastWeekData?.totalHours,
            text: 'Last Week',
            range: MomentUtils.getWeekDates(currentDate.subtract(1, 'w').toDate())
        },
        {
            hrs: secondLastWeekData?.totalHours,
            text: 'Last to last Week',
            range: MomentUtils.getWeekDates(currentDate.subtract(1, 'w').toDate())
        }
    ];
    return (
        <div>
            <HeaderSecondary text={'Welcome, ' + loggedInUser.firstName + '👋'} renderImage={() => <img src={DashboardIcon} alt="project" width={70} />} />
            <div className={styles.title}>ToDo</div>
            <TaskTable tasksArray={tasksArray} loggedInUser={loggedInUser} columns={columns} loading={isTasksLoading} />
            <div className={styles.container}>
                <div className={styles.subContainer}>
                    <div className={styles.title}>Timesheet</div>
                    <Card className={styles.cardWrapper}>
                        <div className={styles.card}>
                            {individualTimesheet.map((ts) => (
                                <div className={styles.cardContent}>
                                    <div className={styles.hours}>{FormatUtils.getAmountInFormat(ts.hrs, false, 0, 0)} Hrs</div>
                                    <div style={{ flex: 2 }}>
                                        <div className={styles.cardText}>{ts.text}</div>
                                        <div>
                                            {GenUtils.getDateAndMonth(ts.range[0])} -{GenUtils.getDateAndMonth(ts.range[1])}
                                        </div>
                                    </div>
                                    <Button
                                        type={'primary'}
                                        onClick={() => {
                                            const queryParams = new URLSearchParams({
                                                teamMemberId: loggedInUser.uuid,
                                                weeksRange: JSON.stringify(ts.range)
                                            });
                                            const url = `${firmId}/timesheet/timesheets?${queryParams.toString()}`;
                                            NavigationUtils.navigateTo(url);
                                        }}
                                        text={'View'}
                                    />
                                </div>
                            ))}
                        </div>
                    </Card>
                </div>
                <div className={styles.subContainer}>
                    <div className={styles.title}>Work Distribution</div>
                    <Card className={styles.cardWrapper}>
                        <div className={styles.dropdownContainer}>
                            <Select
                                placeholder={'time'}
                                value={selected}
                                onChange={(type) => {
                                    setSelected(type);
                                    fetchData(type);
                                }}
                                className={styles.dropdown}>
                                <Select.Option value="all_time">All Time</Select.Option>
                                <Select.Option value="previous_month">Past Month</Select.Option>
                                <Select.Option value="last_week">Past Week</Select.Option>
                            </Select>
                        </div>
                        <div className={styles.chart}>{pieChartData.length > 0 ? <PieChart pieChartData={pieChartData} /> : 'No Distribution Available'}</div>
                    </Card>
                </div>
            </div>
            <div className={styles.title}>Ongoing Projects</div>
            <div className={styles.projects}>
                {projectsArray.filter((project) => !!project.members.find((m) => m === loggedInUser.uuid)).length > 0 ? (
                    <Gantt data={projectsArray.filter((project) => !!project.members.find((m) => m === loggedInUser.uuid))} />
                ) : (
                    <Card className={styles.emptyCard}>No Projects assigned</Card>
                )}
            </div>
        </div>
    );
};
export default Dashboard;
