// import AppUtils from "./AppUtils";
import StorageUtils from './StorageUtils';
import ApiURLs from '../constants/ApiURLs';
import GenUtils from './GenUtils';

export enum ERequestTypes {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}

export interface APIResponse {
    data?: any;
    error?: string;
    isOfflineDataAvailable?: boolean;
}

export interface IApiRequest {
    url: string;
    type: ERequestTypes;
    data: any;
}

export class APIUtil {
    static currentFirm: string;
    static customHeaders: {token?: string, firmId?: string} = {};

    static async makePostRequest(url: string, data: any): Promise<APIResponse> {
        return await this.startRequest(url, ERequestTypes.POST, data);
    }

    static async makePutRequest(url: string, data: any): Promise<APIResponse> {
        return await this.startRequest(url, ERequestTypes.PUT, data);
    }

    static async makeGetRequest(url: string): Promise<APIResponse> {
        return await this.startRequest(url, ERequestTypes.GET);
    }

    static async makePatchRequest(url: string, data: any): Promise<APIResponse> {
        return await this.startRequest(url, ERequestTypes.PATCH, data);
    }

    static async makeDeleteRequest(url: string): Promise<APIResponse> {
        return await this.startRequest(url, ERequestTypes.DELETE);
    }

    static async uploadFile(url:string, formData:any): Promise<APIResponse> {
        return await this.startRequest(url, ERequestTypes.POST, null, formData);
    }

    static isUnauthenticatedRoute(url: string) {
        return url.includes(ApiURLs.LOGIN) || url.includes(ApiURLs.SIGNUP)|| url.includes(ApiURLs.VERIFY)||url.includes(ApiURLs.FORGOT_PASSWORD_LINK)||url.includes(ApiURLs.VERIFICATION_LINK);
    }

    static async startRequest(
        url: string,
        type: ERequestTypes,
        data?: any,
        formData?: any,
    ): Promise<APIResponse> {
        const authToken = APIUtil.customHeaders.token || StorageUtils.getAuthTokenFromLocalStorage();
        const firmId = APIUtil.customHeaders.firmId || StorageUtils.getFirmId();
        const headers = new Headers();
        if (authToken || this.isUnauthenticatedRoute(url)) {
            headers.append('authorization', `Bearer ${authToken}`);
            !formData && headers.append('Content-Type', 'application/json');
            headers.append('X-Authorization', `Token ${authToken}`);
            if (firmId) {
                headers.append('firmid', firmId);
            }
            const config = {
                method: type,
                headers: headers,
            };

            if (data) {
                config['body'] = JSON.stringify(data);
            } else if (!GenUtils.isEmpty(formData)) {
                let _formData = new FormData();
                Object.keys(formData).forEach(key => _formData.append(key, formData[key]));
                config['body'] = _formData;
            }

            try {
                return fetch(url, config)
                    .then(response => {
                        return response.json();
                    })
                    .then(response => {
                        if (response.statusCode >=300 && !response.data) {
                            return {error: response.message, errorCode: response.statusCode};
                        }

                        return { data: response.data };
                    })
                    .catch(response => {
                        return { error: response.message, errorCode: response.statusCode };
                    });
            } catch (error) {
                return { error: error };
            }
        } else {
            return { error: 'User not logged In' };
        }
    }
}
