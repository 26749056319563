// @ts-nocheck
import React, { useState } from 'react';

import { Button, Col, Divider, Form, Input, Modal, Popconfirm, Row, Select, Table } from 'antd';
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import OptionService from '../../services/OptionService';
import Colors from '../../constants/Colors';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import { EOptionType } from '../../redux/reducers/OptionReducer';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import formStyles from '../form.scss';
import { Industries } from '../../constants/Options';

const Projects = () => {
    const [updatingType, setUpdatingType] = useState(null);
    const [showCreateModal, setCreateModal] = useState(false);
    const [isUpdating, setUpdating] = useState(false);
    const [name, setName] = useState('');
    const [abbreviation, setAbbreviation] = useState('');
    const [feeType, setFeeType] = useState('');

    const {project, consultant, phase, task} = useSelector((state: IStore) => state.options);

    const columnClass = 'hp-p1-body hp-text-color-black-100 hp-text-color-dark-0 ';
    const dividerClass = 'hp-border-color-black-40 hp-border-color-dark-80';

    const projectCategoryColumns = [
        {
            title: 'Category Name',
            dataIndex: 'name',
            width: 200,
            render: (text) => <span className={columnClass}>{GenUtils.capitalizeFirstLetter(text)}</span>
        },
        {
            title: '',
            dataIndex: 'edit',
            align: 'right',
            render: (text, data) => (<div>
                <a onClick={() => openEditDialog(data)}>Edit</a>
                <Popconfirm
                    title='Are you sure to delete this category?'
                    onConfirm={() => deleteOption(data.key)}
                    okText='Yes'
                    cancelText='No'
                >
                    <CloseOutlined style={{ color: 'IndianRed', marginLeft: 20 }} />
                </Popconfirm>
            </div>)
        }
    ];

    const phaseTypeColumns = [
        {
            title: 'Phase Name',
            dataIndex: 'name',
            width: 200,
            render: (text) => <span className={columnClass}>{GenUtils.capitalizeFirstLetter(text)}</span>
        },
        {
            title: 'Abbreviation',
            dataIndex: 'abbreviation',
            width: 200,
            render: (text) => <span className={columnClass}>{text}</span>
        },
        {
            title: 'Fee Type',
            dataIndex: 'feeType',
            width: 200,
            render: (text) => <span className={columnClass}>{GenUtils.capitalizeFirstLetter(text)}</span>
        },
        {
            title: '',
            dataIndex: 'edit',
            align: 'right',
            width: 100,
            render: (text, data) => (<div>
                <a onClick={() => openEditDialog(data)}>Edit</a>
                <Popconfirm
                    title='Are you sure to delete this Phase type?'
                    onConfirm={() => deleteOption(data.uuid)}
                    okText='Yes'
                    cancelText='No'
                >
                    <CloseOutlined style={{ color: 'IndianRed', marginLeft: 20 }} />
                </Popconfirm>
            </div>)
        }
    ];

    const consultantTypeColumns = [
        {
            title: 'Consultant Type',
            dataIndex: 'name',
            width: 200,
            render: (text) => <span className={columnClass}>{GenUtils.capitalizeFirstLetter(text)}</span>
        },
        {
            title: '',
            dataIndex: 'edit',
            align: 'right',
            render: (text, data) => (<div>
                <a onClick={() => openEditDialog(data)}>Edit</a>
                <Popconfirm
                    title='Are you sure to delete this Consultant Type?'
                    onConfirm={() => deleteOption(data.uuid)}
                    okText='Yes'
                    cancelText='No'
                >
                    <CloseOutlined style={{ color: 'IndianRed', marginLeft: 20 }} />
                </Popconfirm>
            </div>)
        }
    ];
    const taskTypeColumns = [
        {
            title: 'task Type',
            dataIndex: 'name',
            width: 200,
            render: (text) => <span className={columnClass}>{GenUtils.capitalizeFirstLetter(text)}</span>
        },
        {
            title: '',
            dataIndex: 'edit',
            align: 'right',
            render: (text, data) => (<div>
                <a onClick={() => openEditDialog(data)}>Edit</a>
                <Popconfirm
                    title='Are you sure to delete this task Type?'
                    onConfirm={() => deleteOption(data.uuid)}
                    okText='Yes'
                    cancelText='No'
                >
                    <CloseOutlined style={{ color: 'IndianRed', marginLeft: 20 }} />
                </Popconfirm>
            </div>)
        }
    ];

    const deleteOption = async (id) => {
        const { data, error } = await OptionService.deleteOption(id);
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Delete', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Successfully deleted', <notificationIcon.icon
            style={notificationIcon.style} />);
    };

    const updateOption = async () => {
        const { data, error } = await OptionService.updateOption(isUpdating,{
            name,
            ...(updatingType === EOptionType.phase ? {abbreviation, feeType} : {})
        });
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Update', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Successfully updated', <notificationIcon.icon
            style={notificationIcon.style} />);
        setCreateModal(false);
        setUpdating(false);
    };

    const createOption = async () => {
        const { data, error } = await OptionService.createOption({
            type: updatingType,
            name,
            ...(updatingType === EOptionType.phase ? {abbreviation, feeType} : {})
        });
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Create', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Successfully created', <notificationIcon.icon
            style={notificationIcon.style} />);
        setCreateModal(false);
        setUpdating(false);
    };

    const openEditDialog = (item) => {
        setUpdating(item.uuid);
        setUpdatingType(item.type);
        setName(item.name);
        item.type === EOptionType.phase && setAbbreviation(item.abbreviation);
        item.type === EOptionType.phase && setFeeType(item.feeType);
    };

    return (
        <div className='hp-profile-activity'>
            <Modal
                title={isUpdating ? 'Update Item' : 'Create New'}
                width={500}
                centered
                visible={showCreateModal || isUpdating}
                onCancel={() => {
                    setCreateModal(false);
                    setUpdating(false);
                }}
                footer={null}
                closeIcon={
                    <CloseOutlined
                        style={{ color: Colors.red }}
                        className='remix-icon text-color-black-100'
                        size={24} />
                }
            >
                <Form layout='vertical' name='basic' initialValues={{ remember: true }} style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
                    <Col
                        span={24}
                        className='hp-profile-content-list hp-mt-8 hp-pb-sm-0'
                        style={{ justifyContent: 'center', padding: '20px'}}
                    >
                        <ul style={{ display: 'flex',gap: '20px', flexDirection: 'column'}}>
                            <li>
                                <span className='hp-p1-body'>Name</span>
                                <Input
                                    value={name}
                                    style={{ width: 300 }}
                                    onChange={e => setName(e.target.value)}
                                />
                            </li>
                            {
                                updatingType === 'phase' && (
                                    <li>
                                        <span className='hp-p1-body'>Abbreviation</span>
                                        <Input
                                            value={abbreviation}
                                            style={{ width: 300 }}
                                            onChange={e => setAbbreviation(e.target.value)}
                                        />
                                    </li>
                                )
                            }
                            {
                                updatingType === 'phase' && (
                                    <li>
                                        <span className='hp-p1-body'>Fee Type</span>
                                        <Select
                                            placeholder='Select Fee Type'
                                            // className={formStyles.input}
                                            value={GenUtils.capitalizeFirstLetter(feeType)}
                                            onSelect={(e) => setFeeType(e)}
                                            // className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                                            style={{ width: '100%', border: 'none'}}
                                        >
                                            <option key='fixed' value='fixed'>Fixed</option>
                                            <option key='hourly' value='hourly'>Hourly</option>
                                        </Select>
                                    </li>
                                )
                            }
                        </ul>
                    </Col>
                    <Button type='primary' onClick={() => isUpdating ? updateOption() : createOption()}>Save</Button>
                </Form>
            </Modal>
            <h2 className='hp-mb-4'>Projects</h2>
            <Divider className={dividerClass} />
            <Row align='middle' justify='space-between'>
                <Col md={12} span={24}>
                    <h3>Project Categories</h3>
                    <p className='hp-p1-body hp-mb-0'>
                        Categories are used to group often by typology, region or office. These are available as filters
                        in various projects.
                    </p>
                </Col>
            </Row>
            <Button
                type='primary'
                style={{ margin: '20px 0' }}
                onClick={() => {
                    setCreateModal(true);
                    setUpdatingType('project')
                }}
                icon={<PlusCircleOutlined style={{ marginRight: 10 }} />}
            >
                Add Project Category
            </Button>
            <Table
                className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                columns={projectCategoryColumns}
                dataSource={project.map((p) => {return {...p, key: p.uuid}})}
                size='middle'
                pagination={false}
            />
            <Divider className={dividerClass} />

            <Row align='middle' justify='space-between'>
                <Col md={12} span={24}>
                    <h3>Phase Type</h3>
                    <p className='hp-p1-body hp-mb-0'>
                        Define your firm’s default phases. These will be available for project planning.
                    </p>
                </Col>
            </Row>
            <Button
                type='primary'
                style={{ margin: '20px 0' }}
                onClick={() => {
                    setCreateModal(true);
                    setUpdatingType('phase')
                }}
                icon={<PlusCircleOutlined style={{ marginRight: 10 }} />}
            >
                Add Phase Type
            </Button>
            <Table
                className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                columns={phaseTypeColumns}
                dataSource={phase.map((p) => {return {...p, key: p.uuid}})}
                size='middle'
                pagination={false}
            />
            <Divider className={dividerClass} />

            <Row align='middle' justify='space-between'>
                <Col md={12} span={24}>
                    <h3>Consultant Type</h3>
                    <p className='hp-p1-body hp-mb-0'>
                        Define consultant types for budget allocation in project planner.
                    </p>
                </Col>
            </Row>
            <Button
                type='primary'
                style={{ margin: '20px 0' }}
                onClick={() => {
                    setCreateModal(true);
                    setUpdatingType('consultant')
                }}
                icon={<PlusCircleOutlined style={{ marginRight: 10 }} />}
            >
                Add Consultant Type
            </Button>
            <Table
                className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                columns={consultantTypeColumns}
                dataSource={consultant.map((c) => {return {...c, key: c.uuid}})}
                size='middle'
                pagination={false}
            />
            <Divider className={dividerClass} />

            <Row align='middle' justify='space-between'>
                <Col md={12} span={24}>
                    <h3>Task Type</h3>
                    <p className='hp-p1-body hp-mb-0'>
                        Define task types for budget allocation in project planner.
                    </p>
                </Col>
            </Row>
            <Button
                type='primary'
                style={{ margin: '20px 0' }}
                onClick={() => {
                    setCreateModal(true);
                    setUpdatingType('task')
                }}
                icon={<PlusCircleOutlined style={{ marginRight: 10 }} />}
            >
                Add Task Type
            </Button>
            <Table
                className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                columns={taskTypeColumns}
                dataSource={task.map((c) => {return {...c, key: c.uuid}})}
                size='middle'
                pagination={false}
            />
        </div>
    );
};
export default Projects;
