import GenUtils from './GenUtils';

export enum StorageConstants {
    FIRST_TIME_LAUNCH = 'FIRST_TIME_LAUNCH',
    AUTH_TOKEN = 'AUTH_TOKEN',
    LANGUAGE = 'LANGUAGE',
    USER_UUID = 'USER_UUID',        // this is storing the current firm Id in our case
    USER_TOKEN = 'USER_TOKEN',
    USER_DETAILS = 'USER_DETAILS',
    COUNTRY_DETAILS = 'COUNTRY_DETAILS',
    NOTES = 'NOTES',
    CONFIGS="CONFIGS"
}

export default class StorageUtils {
    static clearStorage() {
        localStorage.clear();
    }

    static set(key: StorageConstants, value: object | string | number | boolean) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    static get(key: StorageConstants): any {
        try {
            return !GenUtils.isEmpty(localStorage) && key && !GenUtils.isEmpty(localStorage.getItem(key)) ? JSON.parse(localStorage.getItem(key)) : null;
        } catch (e) {
            return null;
        }
    }

    static delete(key: StorageConstants) {
        localStorage.removeItem(key);
    }

    static setObject(key: StorageConstants, value: any) {
        const str = JSON.stringify(value);
        StorageUtils.set(key, str);
    }

    static getObject(key: StorageConstants): object {
        const str = StorageUtils.get(key);
        // TODO: str could come as 'undefined' 'null' as well. Not getting used but fix it when you are using it.
        return !str ? {} : JSON.parse(str);
    }

    static getArray(key: StorageConstants): any[] {
        const str = localStorage.get(key);
        return !str || Object.keys(str).length === 0 ? [] : JSON.parse(str);
    }

    static saveUserTokenToStorage(authToken: string) {
        return StorageUtils.set(StorageConstants.USER_TOKEN, authToken);
    }

    static saveUserDetailsToStorage(user: Object) {
        return StorageUtils.set(StorageConstants.USER_DETAILS, user);
    }

    static saveCountryDetailsToStorage(countryDetails: object) {
        return StorageUtils.set(StorageConstants.COUNTRY_DETAILS, countryDetails);
    }

    static getUserTokenFromStorage() {
        return StorageUtils.get(StorageConstants.USER_TOKEN);
    }

    static getUserDetailsFromStorage(): any {
        return StorageUtils.get(StorageConstants.USER_DETAILS);
    }

    static getNumber(key: StorageConstants): number {
        const value = StorageUtils.get(key);
        return parseInt(value) || 0;
    }

    static setNumber(key: StorageConstants, val: number) {
        return StorageUtils.set(key, val);
    }

    static getBoolean(key: StorageConstants): boolean {
        const value = StorageUtils.get(key);
        return !!value;
    }

    static setBoolean(key: StorageConstants, val: boolean) {
        return StorageUtils.set(key, val);
    }

    static getAuthTokenFromLocalStorage() {
        return StorageUtils.get(StorageConstants.AUTH_TOKEN);
    }

    static getFirmId() {
        return StorageUtils.get(StorageConstants.USER_UUID);
    }

    static getCountryDetails() {
        return StorageUtils.get(StorageConstants.COUNTRY_DETAILS);
    }

    static saveLanguageToLocalStorage(language: string) {
        return StorageUtils.set(StorageConstants.LANGUAGE, language);
    }

    static saveAuthTokenToLocalStorage(authToken: string) {
        StorageUtils.set(StorageConstants.AUTH_TOKEN, authToken);
    }

    static saveFirmIdToStorage(uuid: string) {
        return StorageUtils.set(StorageConstants.USER_UUID, uuid);
    }

    static isLoggedIn(): boolean {
        return !!StorageUtils.getAuthTokenFromLocalStorage();
    }

    static isFirstTimeLaunch() {
        return StorageUtils.get(StorageConstants.FIRST_TIME_LAUNCH);
    }

    static updateFirstTimeLaunch() {
        return StorageUtils.set(StorageConstants.FIRST_TIME_LAUNCH, false);
    }
}
