import { useSelector } from 'react-redux';

import { Card, Divider, Form, Progress, Select } from 'antd';

import { IStore } from '../../redux/store';

import styles from './PhaseProgressCard.scss';
import React, { useState } from 'react';
import { IPhase } from '../../interfaces/IPhase';
import { getUserDetails } from '../../redux/selectors/UserSelector';
import FormatUtils from '../../utils/FormatUtils';
import PermissionUtils from '../../utils/PermissionUtils';
import GenUtils from '../../utils/GenUtils';

const ProjectProgressCard = ({ project }) => {
    const phasesArray = useSelector((state: IStore) => state.phases.phasesArrayForProject[project.uuid] || []);
    const phases = useSelector((state: IStore) => state.phases.projectPhases[project.uuid] || {});
    const user = useSelector((state: IStore) => getUserDetails(state));
    const [option, setOption] = useState('all');

    const renderItem = (data, showDate = false, showTime = false) => {
        // const hoursRemaining = showTime ? '_ of _ Hours' : '';
        const amountRemaining = `${FormatUtils.getAmountInFormat(data.progress?.amount || 0, true, 0, 0)} of ${FormatUtils.getAmountInFormat(data.amount, true)}`;

        return (
            <div className={styles.itemContainer} key={data?.uuid}>
                <div className={styles.nameRow}>
                    <div>{GenUtils.capitalizeInitials(data?.name || data.type?.name)}</div>
                    {/* <div>{hoursRemaining}</div> */}
                    {PermissionUtils.canViewFinancesInProjects() ? <div>{amountRemaining}</div> : null}
                </div>
                {/* @ts-ignore */}
                <Progress percent={FormatUtils.getAmountInFormat(data.progress?.amount/data.amount*100, false, 2, 0)} />
                {showDate && (
                    <div className={styles.nameRow}>
                        <span>{FormatUtils.getFormattedDateFromTimestamp(data?.startAt)}</span>
                        <span>{FormatUtils.getFormattedDateFromTimestamp(data?.endAt)}</span>
                    </div>
                )}
            </div>
        );
    };

    // todo put consultant array at line 75

    return (
        <Card title="Project Progress" style={{ paddingTop: 0 }}>
            {/* todo phase selector */}
            <Form.Item label="Phase" className={styles.margin0}></Form.Item>
            <Select
                style={{ width: 300, marginTop: '0px', marginBottom: '20px' }}
                placeholder="Select Phase"
                value={option}
                onChange={(value) => {
                    setOption(value);
                }}>
                {[{ uuid: 'all', name: 'All Phases' }, ...phasesArray].map((phase) => (
                    <Select.Option key={phase.uuid} value={phase.uuid}>
                        {GenUtils.capitalizeInitials(phase.type?.name || phase.name)}
                    </Select.Option>
                ))}
            </Select>
            {renderItem(
                {
                    name: option === 'all' ? 'All Phases' : phases[option]?.type?.name,
                    startAt: option === 'all' ? project?.startAt : phases[option]?.startAt,
                    endAt: option === 'all' ? project?.endAt : phases[option]?.endAt,
                    amount: option === 'all' ? project?.projectFee : phases[option]?.amount,
                    progress: option === 'all' ? project?.progress : phases[option]?.progress,
                },
                true
            )}
            <Divider className="hp-mb-4 hp-bg-color-black-40" />
            <div className={styles.itemContainer}>{(option === 'all' ? [...phasesArray] : []).map((o) => renderItem(o))}</div>
        </Card>
    );
};

export default ProjectProgressCard;
