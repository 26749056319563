import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import { useEffect, useState } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import HeaderSecondary from '../../components/header/HeaderSecondary';
import Button from '../../components/button/Button';
import NavigationUtils from '../../utils/NavigationUtils';
import PhaseService from '../../services/PhaseService';
import GenUtils from '../../utils/GenUtils';
import Style from '../expenses/ExpensesPage.scss';
import Tabs from '../../components/tabs/Tabs';
import { BarsOutlined, ScheduleOutlined, LineChartOutlined, DollarOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import PermissionUtils from '../../utils/PermissionUtils';
import Tag from '../../components/tag/Tag';
import ProjectMoreButton from '../../components/button/ProjectMoreButton';

export default function ProjectHead(){

    const [selectedProjectId, setSelectedProjectId] = useState('');
    const [activeRoute, setActiveRoute] = useState('overview');
    const firmId = useSelector((state: IStore) => state?.firm?.uuid);
    const project = useSelector((state: IStore) => state.project.projects[selectedProjectId] || {});

    const { pathname } = useLocation();

    useEffect(() => {
        const pathSplit = pathname.split('/');
        const selectedId = pathSplit[pathSplit.indexOf('projects') + 1];
        selectedId && setSelectedProjectId(selectedId);
        const _activeRoute = pathSplit[pathSplit .indexOf('projects') + 2] || 'overview';
        if (activeRoute !== _activeRoute) setActiveRoute(_activeRoute);
    }, [pathname]);

    useEffect(() => {
        fetchPhases();
    }, [selectedProjectId]);

    const fetchPhases = async () => {
        if (selectedProjectId) {
            const { data, error } = await PhaseService.fetchPhasesForProject(selectedProjectId);
        }
    };

    const editProject = () => {
        NavigationUtils.navigateTo(`${firmId}/projects/${selectedProjectId}/edit`);
    };


    return (
        <>
        <HeaderSecondary
                text={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Tag color={project.color} text={project.serial} style={{padding: '5px', marginRight: 10, fontSize: 24 }}/>
                        <Title level={4} style={{ margin: 0 }}>
                            {GenUtils.capitalizeInitials(project.name)}
                        </Title>
                        <Tag color={GenUtils.getTagColorByStatus(project.status)} text={project.status} style={{marginLeft: 10, fontSize: 14 }}/>
                    </div>
                }
                renderSubAction={() => (PermissionUtils.canEditProjectFor() !== 'none' ? <ProjectMoreButton project={project} style={{marginRight: 10}}/> : null)}
                renderAction={() => (PermissionUtils.canEditProjectFor() !== 'none' ? <Button onClick={editProject} text={'Edit Project'} /> : null)}
            />
<div className={Style.container}>
                <Tabs
                    items={[
                        {
                            key: 'overview',
                            name: 'Overview',
                            icon: <BarsOutlined style={{fontSize:"16px"}}/>
                        },
                        {
                            key: 'task',
                            name: 'Task',
                            icon: <ScheduleOutlined style={{fontSize:"16px"}}/>
                        },
                        {
                            key: 'milestone',
                            name: 'Milestone',
                            icon: <LineChartOutlined style={{fontSize:"16px"}}/>
                        },
                        {
                            key: 'finance',
                            name: 'Financials',
                            icon: <DollarOutlined style={{fontSize:"16px"}}/>
                        }
                    ]}
                    onTabClick={(key) => {
                        setActiveRoute(key)
                        NavigationUtils.navigateTo(`/${firmId}/projects/${selectedProjectId}/${key}`);
                    }}
                    activeTab={activeRoute}
                />
                <Outlet />
            </div>
        </>
    )
}