import IProject from './IProject';
import { IPhase } from './IPhase';
import { IMember } from '../redux/reducers/TeamReducer';
import { IOption } from '../redux/reducers/OptionReducer';

export default interface IExpense {
    uuid?: string;
    projectId?: string;
    phaseId?: string;
    teamMemberId?: string;
    categoryId?: string;
    vendorId?: string;
    invoiceId?: string;
    paymentModeId?: string;
    date?: string;
    units?: number;
    amount?: number;
    isBillable?: boolean;
    note?: string;
    status?: EExpenseStatus;
    type?: 'project' | 'firm';
    perUnitAmount?: number;
    totalAmount?: number;
    userUuid?: string;
    invoice?: string;
    project?: IProject;
    phase?: IPhase;
    teamMember?: IMember;
    category?: IOption;
    vendor?: IOption;
    paymentMode?: IOption;
    fileId?: string;
    fileUrl?: string;
}

export enum EExpenseStatus {
    submitted = 'submitted',
    approved = 'approved',
    rejected = 'rejected',
    invoiced = 'invoiced',
}