export const setUser = (user) => {
    return {
        type: 'SET_USER',
        payload: user
    };
};

export const updateUser = (userDetails) => {
    return {
        type: 'UPDATE_USER',
        payload: userDetails
    };
};
