import { Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import Button from '../../components/button/Button';

import styles from './AddClient.scss';
import formStyles from '../../pages/form.scss';
import classnames from 'classnames';
import { Countries, Currencies, ReferralSources } from '../../constants/Options';
import ClientService from '../../services/ClientService';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import IClient from '../../interfaces/IClient';

const { Option } = Select;

const EditClient = ({
                        client = {},
                        hideModal = () => {
                        },
                        onSuccess = () => {
                        }
                    }: { client: IClient, hideModal: () => void, onSuccess: () => void }) => {
    const [name, setName] = useState('');
    const [referralSource, setReferralSource] = useState('');
    const [currency, setCurrency] = useState('INR');
    const [taxRegId, setTaxRegId] = useState('');
    // address
    const [building, setBuilding] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [zipCode, setZipCode] = useState('');

    useEffect(() => {
        if (client.name !== name) setName(client.name);
        if (client.referralSource !== referralSource) setReferralSource(client.referralSource);
        if (client.taxRegId !== taxRegId) setTaxRegId(client.taxRegId);
        if (client.currency !== currency) setCurrency(client.currency);
        if (client.building && client.building !== building) setBuilding(client.building);
        if (client.street && client.street !== street) setStreet(client.street);
        if (client.city && client.city !== city) setCity(client.city);
        if (client.state && client.state !== state) setState(client.state);
        if (client.zipCode && client.zipCode !== zipCode) setZipCode(client.zipCode);
        if (client.country && client.country !== country) setCountry(client.country);
    }, [client.uuid]);

    const submitForm = async () => {
        const {
            data,
            error
        } = await ClientService.updateClient(client.uuid, {
            name,
            referralSource,
            currency,
            taxRegId,
            building,
            street,
            city,
            state,
            country,
            zipCode
        });
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('An error occurred', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        onSuccess();
    };

    const emptyContact = {
        name: '...',
        designation: '...',
        email: '...',
        mobile: '...'
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            editable: true
        },
        {
            title: 'Designation',
            dataIndex: 'designation',
            editable: true
        },
        {
            title: 'Email',
            dataIndex: 'email',
            editable: true
        },
        {
            title: 'Contact',
            dataIndex: 'mobile',
            editable: true
        }
    ];

    return (
        <div className={styles.container}>
            <form>
                <Form.Item
                    label='Company Name'
                    className={styles.margin0}>
                </Form.Item>
                <Input
                    placeholder='Name'
                    type={'name'}
                    className={formStyles.marginBottom20}
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                    }}
                />
                <div className={styles.row}>
                    <div className={styles.column}>
                        <Form.Item label='Referral Source' className={styles.margin0} />
                        {/* todo select default value here */}
                        <Select
                            showSearch
                            placeholder='Select'
                            value={referralSource}
                            onChange={(e) => {
                                setReferralSource(e);
                            }}
                        >
                            {
                                ReferralSources.map((source) => <Option key={source} value={source}>{source}</Option>)
                            }
                        </Select>
                    </div>
                    <div className={styles.column}>
                        <Form.Item label='Preferred Currency' className={styles.margin0} />
                        {/* todo select default value here */}
                        <Select
                            showSearch
                            placeholder='INR'
                            value={currency}
                            onChange={(value) => {
                                setCurrency(value);
                            }}
                        >
                            {
                                Currencies.map(currency => <Option key={currency.iso} value={currency.iso}>{currency.name} - {currency.symbol}</Option>)
                            }
                        </Select>
                    </div>
                    {/* show on ly if country is India */}
                    {
                        country.toLowerCase() === 'india' && (
                            <div className={styles.column}>
                                <Form.Item label='GST Number' className={styles.margin0} />
                                <Input
                                    value={taxRegId}
                                    placeholder='example'
                                    type={'number'}
                                    onChange={(e) => {
                                        setTaxRegId(e.target.value);
                                    }}
                                />
                            </div>
                        )
                    }
                </div>
                <div>
                    <Form.Item
                        className={classnames(styles.marginBottomN10)}
                        label='Address' />
                    <div className={styles.row}>
                        <Input
                            placeholder='Street, avenue, etc.'
                            type={'address'}
                            value={street}
                            onChange={(e) => {
                                setStreet(e.target.value);
                            }}
                        />
                        <Input
                            placeholder='Suite, Unit, Apt, etc'
                            type={'address'}
                            value={building}
                            onChange={(e) => {
                                setBuilding(e.target.value);
                            }}
                        />
                    </div>
                    <div className={styles.row}>
                        <Input
                            placeholder='City'
                            type={'address'}
                            value={city}
                            onChange={(e) => {
                                setCity(e.target.value);
                            }}
                        />
                        <Input
                            placeholder='State/Province/Region'
                            type={'address'}
                            value={state}
                            onChange={(e) => {
                                setState(e.target.value);
                            }}
                        />
                        <Input
                            placeholder='Zip/Postal Code'
                            type={'address'}
                            value={zipCode}
                            onChange={(e) => {
                                setZipCode(e.target.value);
                            }}
                        />
                    </div>
                    <div className={styles.row}>
                        <Form.Item
                            rules={[{ required: true }]}>
                            <Select
                                showSearch
                                placeholder='Country'
                                style={{ width: 300 }}
                                value={country}
                                onChange={(value) => {
                                    setCountry(value);
                                }}
                            >
                                {
                                    Countries.map((_country) => <Option key={_country}
                                                                        value={_country}>{_country}</Option>)
                                }
                            </Select>
                        </Form.Item>
                    </div>
                </div>
            </form>
            <div className={classnames(styles.row, styles.center)}>
                <Button
                    text='Add'
                    onClick={() => {
                        submitForm();
                    }}
                />
                <Button
                    type={'ghost'}
                    text='Cancel'
                    onClick={() => {
                        hideModal();
                    }}
                />
            </div>
        </div>
    );
};
export default EditClient;
