// @ts-nocheck
import React from 'react';

import { Button, Col, Divider, message, Popconfirm, Row, Table } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import OptionService from '../../services/OptionService';
import ProjectService from '../../services/ProjectService';

const ViewPermissions = () => {
    const columnClass = 'hp-p1-body hp-text-color-black-100 hp-text-color-dark-0 ';
    const dividerClass = 'hp-border-color-black-40 hp-border-color-dark-80';

    const columns = [
        {
            title: 'Category Name',
            dataIndex: 'name',
            width: 200,
            render: (text) => <span className={columnClass}>{text}</span>
        },
        {
            title: '',
            dataIndex: 'edit',
            align: 'right',
            render: (text, data) => (<div>
                <a>{text}</a>
                <Popconfirm
                    title='Are you sure to delete this role?'
                    onConfirm={() => deleteRole(data.key)}
                    okText='Yes'
                    cancelText='No'
                >
                    <CloseOutlined style={{ color: 'IndianRed', marginLeft: 20 }} />
                </Popconfirm>
            </div>)
        }
    ];

    const data = [
        {
            key: '1',
            name: 'Commercial',
            edit: 'Edit',

        },
        {
            key: '2',
            name: 'Cultural',
            edit: 'Edit',


        },
        {
            key: '3',
            name: 'Health & Science',
            edit: 'Edit',

        },
        {
            key: '4',
            name: 'Hospitality',
            edit: 'Edit',

        },
        {
            key: '5',
            name: 'Institutional',
            edit: 'Edit',

        }
    ];
    const column2 = [
        {
            title: 'Phase Name',
            dataIndex: 'name',
            width: 200,
            render: (text) => <span className={columnClass}>{text}</span>
        },
        {
            title: 'Abbreviation',
            dataIndex: 'abbr',
            width: 200,
            render: (text) => <span className={columnClass}>{text}</span>
        },
        {
            title: 'Fee Type',
            dataIndex: 'feeType',
            width: 200,
            render: (text) => <span className={columnClass}>{text}</span>
        },
        {
            title: '',
            dataIndex: 'edit',
            align: 'right',
            render: (text) => (<div>
                <a>{text}</a>
                <CloseOutlined style={{ color:'IndianRed', marginLeft:20}} />
            </div>)
        }
    ];

    const data2 = [
        {
            key: '1',
            name: 'Bidding & Negotiation',
            feeType: 'Fixed',
            edit: 'Edit',

        },
        {
            key: '2',
            name: 'Construction Administration',
            abbr: 'CA',
            feeType: 'Fixed',
            edit: 'Edit',


        },
        {
            key: '3',
            name: 'Construction Documentation',
            abbr: 'CD',
            feeType: 'Fixed',
            edit: 'Edit',

        },
        {
            key: '4',
            name: 'Design Development',
            abbr: 'DD',
            feeType: 'Fixed',
            edit: 'Edit',

        },
        {
            key: '5',
            name: 'Permitting',
            abbr: 'Perm',
            feeType: 'Fixed',
            edit: 'Edit',

        },
        {
            key: '6',
            name: 'Pre-Design',
            abbr: 'PreD',
            feeType: 'Fixed',
            edit: 'Edit',

        },
        {
            key: '7',
            name: 'Proposal',
            abbr: 'Prop',
            feeType: 'Fixed',
            edit: 'Edit',

        },
        {
            key: '8',
            name: 'Schematic Design',
            abbr: 'SD',
            feeType: 'Fixed',
            edit: 'Edit',

        }
    ];
    const column3 = [
        {
            title: 'Consultant Type',
            dataIndex: 'type',
            width: 200,
            render: (text) => <span className={columnClass}>{text}</span>
        },
        {
            title: '',
            dataIndex: 'edit',
            align: 'right',
            render: (text) => (<div>
                <a>{text}</a>
                <CloseOutlined style={{ color:'IndianRed', marginLeft:20}} />
            </div>)
        }
    ];

    const data3 = [
        {
            key: '1',
            type: 'Architect',
            edit: 'Edit',

        },
        {
            key: '2',
            type: 'Civil Engineer',
            edit: 'Edit',


        },
        {
            key: '3',
            type: 'Geotechnical Engineer',
            edit: 'Edit',

        },
        {
            key: '4',
            type: 'Interior Designer',
            edit: 'Edit',

        },
        {
            key: '5',
            type: 'Landscape Architect',
            edit: 'Edit',

        },
        {
            key: '6',
            type: 'Lighting Designer',
            edit: 'Edit',

        },
        {
            key: '7',
            type: 'MEP Engineer',
            edit: 'Edit',

        },
        {
            key: '8',
            type: 'Structural Engineer',
            edit: 'Edit',

        },
        {
            key: '9',
            type: 'Surveyor',
            edit: 'Edit',

        }
    ];

    const deleteRole = async (id) => {
        const { error } = await ProjectService.deleteRole(id);
        if (error) {
            message.error({
                content: error
            });
        }
    };


    return (
        <div className='hp-profile-activity'>
            <h2 className='hp-mb-4'> Projects</h2>

            <Divider className={dividerClass} />

            <Row align='middle' justify='space-between'>
                <Col md={12} span={24}>
                    <h3>Project Categories</h3>
                    <p className='hp-p1-body hp-mb-0'>
                        Categories are used to group often by typology, region or office. These are available as filters
                        in various projects.
                    </p>
                </Col>
            </Row>
            <Table
                className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                columns={columns}
                dataSource={data}
                size='middle'
                pagination={false}
            />
            <Divider className={dividerClass} />

            <Row align='middle' justify='space-between'>
                <Col md={12} span={24}>
                    <h3>Phase Type</h3>
                    <p className='hp-p1-body hp-mb-0'>
                        Define your firm’s default phases. These will be available for project planning.
                    </p>
                </Col>
            </Row>
            <Table
                className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                columns={column2}
                dataSource={data2}
                size='middle'
                pagination={false}
            />
            <Divider className={dividerClass} />

            <Row align='middle' justify='space-between'>
                <Col md={12} span={24}>
                    <h3>Consultant Type</h3>
                    <p className='hp-p1-body hp-mb-0'>
                        Define consultant types for budget allocation in project planner.
                    </p>
                </Col>
            </Row>
            <Table
                className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                columns={column3}
                dataSource={data3}
                size='middle'
                pagination={false}
            />
        </div>
    );
};
export default ViewPermissions;
