import React, { useEffect, useState } from 'react';
//import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Steps } from 'antd';
//import type {FormInstance} from 'antd/es/form';
import styles from './SignupStep1.css';
import { useDispatch } from 'react-redux';
import { proceedSignup } from '../../redux/actions/AuthActions';
import formStyles from '../form.scss';
import { Countries, Industries } from '../../constants/Options';

const { Option } = Select;

interface ISignupStep1 {
    email: string,
    setEmail: any,
    firmName: string,
    setFirmName: any,
    firmSize: string,
    setFirmSize: any,
    firmLocation: string,
    setFirmLocation: any,
    industry: string,
    setIndustry: any,
}


const SignupStep1 = ({
                         email,
                         setEmail,
                         firmName,
                         setFirmName,
                         firmSize,
                         setFirmSize,
                         firmLocation,
                         setFirmLocation,
                         industry,
                         setIndustry

                     }: ISignupStep1) => {

    const [isValid, setValidity] = useState(false);
    const dispatch = useDispatch();
    // const [current, setCurrent]=useState(0)

    const onNext = () => {
        dispatch(proceedSignup(2));
    };

    useEffect(() => {
        const validity = email && firmName && firmSize && firmLocation && industry;
        setValidity(!!validity);
    }, [email, firmName, firmSize, firmLocation, industry]);

    // const onChange = (value: number) => {
    //     isValid &&
    //     setCurrent(value);
    // };
    console.log(email, firmName, firmSize, firmLocation, industry);

    return (
        <div className={styles.signupStep1}>
            <div className={styles.stepContainer}>
                <Steps
                    size='small'
                    current={0}
                    responsive={true}
                    onChange={(e) =>
                        console.log(email, firmName, firmSize, firmLocation, industry)}
                    className={styles.steps}
                    items={[
                        {
                            title: ''
                        },
                        {
                            title: ''
                        },
                        {
                            title: ''
                        }
                    ]}
                />
                <Form
                    layout='horizontal'
                >
                    <Form.Item label='Work-email'
                               className={formStyles.label}
                               rules={[{ required: true, message: 'name@companyemail.com' }]}>
                    </Form.Item>
                    <Input
                        placeholder='name@companyemail.com'
                        type={'email'}
                        className={formStyles.input}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        value={email}
                    />
                    <Form.Item label='Firm-name'
                               className={formStyles.label}
                               rules={[{ required: true, message: 'e.g. Summit Builders' }]}>
                    </Form.Item>
                    <Input
                        placeholder='e.g. Summit Builders'
                        className={formStyles.input}
                        onChange={(e) => {
                            setFirmName(e.target.value);
                        }}
                        value={firmName}
                    />
                    <Form.Item label='Firm-size'
                               className={formStyles.label}
                               rules={[{ required: true, message: '0' }]}>
                    </Form.Item>
                    <Input
                        placeholder='0'
                        type='number'
                        min={0}
                        className={styles.input}
                        onChange={(e) => {
                            let val=Math.abs(Number(e.target.value)).toString();
                            setFirmSize(val);
                        }}
                        value={firmSize}
                    />
                    <Form.Item name='industry' className={formStyles.label} label='Industry'>
                    </Form.Item>
                    <Select
                        placeholder='Select'
                        className={formStyles.input}
                        onSelect={(e) => {
                            setIndustry(e);
                        }}
                        // className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                        style={{ width: '100%', border: 'none'}}
                        value={industry}
                    >
                        {
                            Industries.map(industry => <option key={industry} value={industry}>{industry}</option>)
                        }
                    </Select>
                    <Form.Item name='FirmLocation' className={formStyles.label} label='Firm Location'>
                    </Form.Item>
                    <Select
                        showSearch
                        placeholder='Select'
                        className={formStyles.input}
                        onSelect={(e) => {
                            setFirmLocation(e);
                        }}
                        // className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                        style={{ width: '100%', border: 'none'}}
                        value={firmLocation}
                        placement="bottomLeft"
                    >
                        {
                            Countries.map(country => <option key={country} value={country}>{country}</option>)
                        }
                    </Select>
                </Form>
                <p id={styles.terms} style={{ display: 'inline' }}>By clicking Next you agree to our <a href="https://resourceplan.io/terms-and-conditions" target='_blank' >Terms of Services</a> and acknowledge having read our <a href="https://resourceplan.io/privacy-policy" target='_blank' > Privacy Policy</a>
                </p>
                <Button
                    type='primary'
                    htmlType='submit'
                    className={styles.startFreeTrial}
                    onClick={onNext}
                    disabled={!isValid}
                >
                    Start your free trial
                </Button>
            </div>
        </div>
    );
};
export default SignupStep1;
