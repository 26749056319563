import { ECommunicationType, ICommunication, ICommunicationState } from '../reducers/CommunicationReducer';
import { IStore } from '../store';
import GenUtils from '../../utils/GenUtils';

export const communicationSelector = (state: IStore, type: ECommunicationType, typeId: string): { communications: {}; communicationsArray: any[] } => {
    let emptyData = {
        communications: {},
        communicationsArray: []
    };
    const _type = state.communication[type];

    if (GenUtils.isEmpty(_type)) {
        return emptyData;
    }
    const communicationsState = _type[typeId];
    if (GenUtils.isEmpty(communicationsState)) {
        return emptyData;
    }

    return _type[typeId];
};