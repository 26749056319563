import { CloseOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { IStore } from '../../../../redux/store';
import { useSelector } from 'react-redux';

const TotalCell = ({ data, index, phaseKey, deleteTimesheet, total }) => {
    const userTimesheet = useSelector((state: IStore) => state.timeLogs.timesheet);
    return (
        <div className={classnames(styles.weekColumn, styles.alignRight)}>
            {total}
            {/* {FormatUtils.getAmountInFormat(total / 3600, false, 2, 0)} */}
            <Popconfirm
                title="Are you sure? Deleting this row permanently remove logged hours for the activity."
                onConfirm={() => {
                    deleteTimesheet(phaseKey, data.uuid)
                }}
                okText="Yes"
                cancelText="No">
                {!userTimesheet.isLocked &&
                    <CloseOutlined style={{ color: 'IndianRed', marginLeft: 10 }} />}
            </Popconfirm>
        </div>
    );
};

export default TotalCell;
