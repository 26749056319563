import React from 'react';
import styles from './MemberPhaseAllottedHoursDonut.scss';
import DonutChart from 'react-donut-chart';
import FormatUtils from '../../utils/FormatUtils';
import classnames from 'classnames';

const MemberPhaseAllottedHoursDonut = ({ data, budgeted, showPercent = false }) => {
    let total = 0;
    let colors = [];

    data.map((item) => {
        total += item.hours;
        colors.push(item.color);
    });

    const d = data.map((item) => {
        const percent = (item.hours / budgeted) * 100;
        return {
            label: '',
            value: percent
        };
    });

    d.push({
        label: '',
        isEmpty: true,
        value: total > budgeted ? 0 : ((budgeted - total) / budgeted) * 100
    });

    const config = {
        height: 50,
        width: 50,
        legend: false,
        selectedOffset: 0,
        formatValues: (values, total) => '',
        interactive: false,
        colors
    };

    return (
        <div className={styles.container}>
            <DonutChart data={d} {...config} className={styles.donut} />
            {
                showPercent
                    ? (
                        <div
                            className={classnames(styles.label, total > budgeted && styles.overflow)}
                        >
                            {
                                FormatUtils.getAmountInFormat(
                                    budgeted*total === 0 ? (total===0 ? 0 : 100) : total / budgeted * 100,
                                    false,
                                    0,
                                    '0'
                                )
                            }%
                        </div>
                    ) : (
                        <div className={classnames(styles.label, total > budgeted && styles.overflow)}>
                            {total > budgeted ? '(' : null}
                            {
                                FormatUtils.getAmountInFormat(
                                    Math.abs(total - (0 || budgeted)) / 3600,
                                    false,
                                    2,
                                    0
                                )
                            }
                            {total > budgeted ? ')' : null}
                        </div>
                    )
            }
        </div>
    );
};
export default MemberPhaseAllottedHoursDonut;
