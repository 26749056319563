import { Checkbox, Col, Input, Row } from 'antd';
import React, { useState } from 'react';
import TimeSheetService from '../../services/TimeSheetService';
import styles from './AuditTimesheetRejectPopOver.scss';
import Button from '../../components/button/Button';
import moment from 'moment';
import GenUtils from '../../utils/GenUtils';
import MomentUtils from '../../utils/MomentUtils';

const AuditTimesheetRejectPopOver = ({ member, teamMemberId, status, date, phaseMemberId, activityId, weekStart, fetchAudits = () => {}, onSuccess = () => {} }) => {
    const [rejectionMessage, setRejectionMessage] = useState('');
    const [days, setDays] = useState(0);
    const [check, setCheck] = useState(false);

    return (
        <div className={styles.container}>
            <h3 className={styles.heading}>Reject Time Entry</h3>
            <p>Please provide a reason for rejecting this time entry.</p>
            <Input.TextArea className={styles.textArea} value={rejectionMessage} onChange={(e) => setRejectionMessage(e.target.value)} />
            <div className={styles.checkBoxParentContainer}>
                <Checkbox className={styles.checkBox} type="checkbox" checked={check} onChange={(e) => setCheck(Boolean(e.target.checked))}>
                    <p className={styles.checkBoxDescription}>
                        Unlock {member}'s Timesheet for {GenUtils.getDateAndMonth(weekStart)} -{' '}
                        {GenUtils.getDateAndMonth(moment(weekStart).add(7, 'd').toDate())}
                    </p>
                </Checkbox>
            </div>
            <Row className={styles.buttons} style={{ gap: 10 }}>
                <Button type="ghost" text={'cancel'} onClick={onSuccess} />
                <Button
                    type="primary"
                    text={'reject'}
                    danger
                    onClick={async () => {
                        const { data, error } = await TimeSheetService.updateMemberTimesheet({
                            teamMemberId: teamMemberId,
                            status: 'rejected',
                            date: MomentUtils.getGmtDayStart(weekStart),
                            phaseMemberId: phaseMemberId,
                            activityId: activityId,
                            rejectionNote: rejectionMessage,
                            enableLock: check,
                            lockingDays: days
                        });
                        onSuccess()
                        if (!error) fetchAudits();
                    }}
                />
            </Row>
        </div>
    );
};

export default AuditTimesheetRejectPopOver;
