import { Alert, Checkbox, Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import Button from '../../components/button/Button';

import styles from './AddConsultant.scss';
import EditableCellsTable from '../../components/table/EditableCellsTable';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import ConsultantService from '../../services/ConsultantService';
import { IStore } from '../../redux/store';
import { useSelector } from 'react-redux';
import { IPhase } from '../../interfaces/IPhase';
import FormatUtils from '../../utils/FormatUtils';
import CustomInput from '../../components/input/CustomInput';

const { Option } = Select;

const AddConsultant = ({ hideModal, onSuccess, project, initailConsultant = null }) => {
    const [involvement, setInvolvement] = useState('');
    const [consultant, setConsultant] = useState('');
    const [consultantPayout, setConsultantPayout] = useState(0);
    const [errorMessage, setErrorMessage] = useState(null);
    const [billableAmount, setBillableAmount] = useState(0);
    const [isPayoutEqualFee, setIsPayoutEqualFee] = useState(false);
    const [phaseTable, setPhaseTable] = useState([]);
    const projectPhases = useSelector((state: IStore) => project.uuid && state.phases.projectPhases[project.uuid]);
    const consultantTypes = useSelector((state: IStore) => state.options.consultant);

    const involvements = [
        { label: 'Project Consultant', value: 'project' },
        {
            label: 'Phase Consultant',
            value: 'phase'
        }
    ];
    const emptyPhase = {
        fee: 0,
        payout: 0,
        isFullPayout: false,
        phaseId: 'Select Phase'
    };

    useEffect(() => {
        setPhaseTable([emptyPhase]);
        if (initailConsultant) {
            setConsultant(initailConsultant.type.uuid);
            setInvolvement(initailConsultant.involvementType);
            setIsPayoutEqualFee(initailConsultant.isFullPayout);
            setBillableAmount(initailConsultant.fee);
            setConsultantPayout(initailConsultant.payout);
            initailConsultant.phases &&
                setPhaseTable(
                    initailConsultant.phases.map((phase, index) => ({
                        ...phase,
                        key: index,
                        fee: phase.fee
                    }))
                );
            initailConsultant.phases && calculatePayoutFee(initailConsultant.phases);
        }
    }, []);

    const submitForm = async () => {
        const updatedConsultant = {
            typeId: consultant,
            involvementType: involvement,
            fee: billableAmount,
            payout: consultantPayout,
            isFullPayout: isPayoutEqualFee,
            projectId: project['uuid'],
            phases: (phaseTable.length === 1 && phaseTable[0].phaseId === 'Select Phase' || involvement === 'project') ? [] : phaseTable.map(({ key, ...data }) => data)
        };

        if (!initailConsultant) {
            const response = await ConsultantService.createConsultant(updatedConsultant);
            if (response.error) {
                const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
                GenUtils.showNotification('Failed to Create Consultant', response.error, <notificationIcon.icon style={notificationIcon.style} />);
                return;
            }
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
            GenUtils.showNotification('Success', `Consultant Successfully Created`, <notificationIcon.icon style={notificationIcon.style} />);
        } else {
            const response = await ConsultantService.updateConsultant(updatedConsultant);
            if (response.error) {
                const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
                GenUtils.showNotification('Failed to Update Consultant', response.error, <notificationIcon.icon style={notificationIcon.style} />);
                return;
            }
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
            GenUtils.showNotification('Success', `Consultant Successfully Updated`, <notificationIcon.icon style={notificationIcon.style} />);
        }
        hideModal();
    };

    const togglePayoutEqualFee = (e) => {
        if (e) {
            setConsultantPayout(billableAmount);
        }
        setIsPayoutEqualFee(e);
    };

    const columns = [
        {
            title: 'Phase',
            dataIndex: 'phaseId',
            key: 'phaseId',
            render: (text, data, index) => (
                <Select
                    placeholder="Select Consultant"
                    value={GenUtils.capitalizeInitials(text)}
                    onChange={(e) => updatePhase(e, index)}
                    style={{ width: 150 }}
                >
                    {Object.values(projectPhases).map((phase: any) => (
                        <Option key={phase.uuid} value={phase.uuid}>
                            {GenUtils.capitalizeInitials(phase.type.name)}
                        </Option>
                    ))}
                </Select>
            )
        },
        {
            title: 'Consultant Fee',
            dataIndex: 'fee',
            key: 'fee',
            editable: true,
            render: (text, data) => <CustomInput
                value={FormatUtils.getAmountInFormat(text, false, 2, '0')}
                prefix={FormatUtils.getCurrency()}
                style={{ width: '100%' }}
                onChange={() => ''}
                updateOnBlur
            />
        },
        {
            title: 'Payout = Fee',
            dataIndex: 'isFullPayout',
            key: 'isFullPayout',
            render: (text, data, index) => <Checkbox
                checked={Boolean(data.isFullPayout)}
                onChange={() => onPayoutFeeChecked(data, index)}
            />
        },
        {
            title: 'Payout',
            dataIndex: 'payout',
            key: 'payout',
            editable: true,
            render: (text, data) => <CustomInput
                value={FormatUtils.getAmountInFormat(text, false, 2, '0')}
                prefix={FormatUtils.getCurrency()}
                style={{ width: '100%' }}
                onChange={() => ''}
                updateOnBlur
                disabled={data.isFullPayout}
            />
        },
        {
            title: '',
            key: 'delete-row',
            render: (text, data, index) => <CloseOutlined
                style={{ color: 'IndianRed' }}
                onClick={() => deleteRow(data, index)}
            />
        }
    ];

    const updateTable = (data) => {
        setPhaseTable(data);
        calculatePayoutFee(data);
    };

    const calculatePayoutFee = (data) => {
        let totalPayout = 0;
        let totalFee = 0;
        data.forEach((phase) => {
            totalFee += Number(phase.fee);
            totalPayout += Number(phase.payout);
        });
        setBillableAmount(totalFee);
        setConsultantPayout(totalPayout);
    };

    const onPayoutFeeChecked = (data, idx) => {
        let phase = phaseTable[idx];
        if (!phase.isFullPayout) phase.payout = phase.fee;
        phase.isFullPayout = !phase.isFullPayout;
        const phases = [...phaseTable.slice(0, idx), phase, ...phaseTable.slice(idx + 1)];
        setPhaseTable(phases);
        calculatePayoutFee(phases);
    };

    const deleteRow = (data, idx) => {
        const phases = [...phaseTable.slice(0, idx), ...phaseTable.slice(idx + 1)];
        setPhaseTable(phases);
        calculatePayoutFee(phases);
    };

    const updatePhase = (data, idx) => {
        if (!phaseTable.length) {
            setPhaseTable([emptyPhase]);
        }
        let phaseConsultant: IPhase[] = Object.values(projectPhases);
        let row = phaseTable[idx];
        let phaseIndex = phaseConsultant.findIndex((consultant) => consultant.uuid === data);
        row.phaseId = data;
        row.fee = phaseConsultant[phaseIndex].amount;
        const phases = [...phaseTable.slice(0, idx), row, ...phaseTable.slice(idx + 1)];
        setPhaseTable(phases);
        calculatePayoutFee(phases);
    };

    return (
        <div className={styles.container}>
            {errorMessage && <Alert message={errorMessage} type="error" closable onClose={() => { }} />}
            <form>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <Form.Item label="Consultant Type" className={styles.margin0} />
                    </div>
                    <div className={styles.column}>
                        <Select
                            value={consultant}
                            placeholder={'Select Consultant'}
                            onChange={(e) => {
                                setConsultant(e);
                            }}>
                            {consultantTypes.map((consultant) => (
                                <Option key={consultant.uuid} value={consultant.uuid}>
                                    {GenUtils.capitalizeInitials(consultant.name)}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <Form.Item label="Involvement" className={styles.margin0} />
                    </div>
                    <div className={styles.column}>
                        <Select
                            value={involvement}
                            placeholder={'Select Involvement'}
                            onChange={(e) => {
                                setInvolvement(e);
                                if (e === 'phase') {
                                    calculatePayoutFee(phaseTable);
                                }
                            }}>
                            {involvements.map((involvement) => (
                                <Option key={involvement.value} value={involvement.value}>
                                    {GenUtils.capitalizeInitials(involvement.label)}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
                {involvement !== 'phase' ? (
                    <div>
                        <div className={styles.row}>
                            <div className={styles.column}>
                                <Form.Item label="Total Consultant Fee" className={styles.margin0} />
                            </div>
                            <div className={styles.column}>
                                <Input
                                    placeholder="Billable Rate"
                                    type={'number'}
                                    prefix={FormatUtils.getCurrency()}
                                    value={billableAmount}
                                    onChange={(e) => {
                                        setBillableAmount(Number(e.target.value));
                                    }}
                                />
                            </div>
                        </div>
                        <Checkbox onChange={(e) => togglePayoutEqualFee(e.target.checked)} checked={isPayoutEqualFee}>
                            Payout = Fee
                        </Checkbox>
                        <div className={styles.row}>
                            <div className={styles.column}>
                                <Form.Item label="Consultant Payout" className={styles.margin0} />
                            </div>
                            <div className={styles.column}>
                                <Input
                                    placeholder="Consultant Payout"
                                    type={'number'}
                                    prefix={FormatUtils.getCurrency()}
                                    disabled={isPayoutEqualFee}
                                    value={consultantPayout}
                                    onChange={(e) => {
                                        setConsultantPayout(Number(e.target.value));
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <EditableCellsTable
                            dataSource={
                                phaseTable.length
                                    ? phaseTable.map((phase, index) => ({
                                        ...phase,
                                        key: index + ' ' + phaseTable.length
                                    }))
                                    : [emptyPhase]
                            }
                            newData={emptyPhase}
                            columns={columns}
                            setDataSource={updateTable}
                            addButtonText={'Add Phase'}
                        />
                        <Form.Item label="Total Consultant Fee" className={styles.margin0}>
                            {FormatUtils.getAmountInFormat(billableAmount, true, 0, 0)}
                        </Form.Item>
                        <Form.Item label="Total Payout" className={styles.margin0}>
                            {FormatUtils.getAmountInFormat(consultantPayout, true, 0, 0)}
                        </Form.Item>
                    </div>
                )}
            </form>
            <div className={classnames(styles.row, styles.center)}>
                <Button type={'ghost'} text="Cancel" onClick={hideModal} />
                <Button
                    text="Add"
                    onClick={() => {
                        submitForm();
                    }}
                />
            </div>
        </div>
    );
};
export default AddConsultant;
