import { useSearchParams } from 'react-router-dom';
import styles from './FiltersBar.scss';
import { Select } from 'antd';
import { useState } from 'react';
import GenUtils from '../../utils/GenUtils';

const {Option} = Select;

const GroupBar = ({groups = []}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [v, setV] = useState(searchParams.get('group'));
    
    const updateFilter = (group) => {
        if (searchParams.has('group')) {
            searchParams.delete('group');
        }
        if (group !== 'none') searchParams.append('group', group);
        setSearchParams(searchParams);
    }

    if (groups.length === 0) return;

    return (
        <div className={styles.groupContainer}>
            <Select
                showSearch={false}
                value={v}
                className={styles.dropdown}
                onChange={(value) => {
                    setV(value === 'none' ? null : value);
                    updateFilter(value)
                }}
                placeholder={'Group By'}
            >
                <Option key={'none'} value={'none'} style={{fontFamily: 'Inter' }}>None</Option>
                {
                    groups.map(
                        group => <Option 
                            key={group.dataIndex}
                            value={GenUtils.getSimpleStringKey(group.title)}
                            style={{fontFamily: 'Inter' }}
                        >
                            {group.title}
                        </Option>
                    )
                }
            </Select>
        </div>
    );
}

export default GroupBar;