import GenUtils from '../../utils/GenUtils';
import IProject from '../../interfaces/IProject';

export const updateProject = (project: IProject) => {
    return {
        type: 'UPDATE_PROJECT',
        payload: project,
    }
}

export const setProjects = (projects: IProject[], clientId?: null|string) => {
    return {
        type: 'SET_PROJECTS',
        payload: {
            projects: GenUtils.arrayToIdMap(projects),
            clientId,
            projectsArray: projects
        },
    }
}