import classnames from 'classnames';
import loginStyles from '../login/LoginPage.scss';
import CopyrightFooter from '../../components/footer/CopyrightFooter';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import AuthService from '../../services/AuthService';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import NavigationUtils from '../../utils/NavigationUtils';
import { useNavigate, useSearchParams } from 'react-router-dom';

const ForgotScreen = () => {
    const [verified, setVerified] = useState(false);
    const [error, setError] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [token, setToken] = React.useState<string>(null);

    const [searchParams] = useSearchParams();
    const navigateTo = useNavigate();
    
    useEffect(() => {
        const token = searchParams.get('token');
        const firmid = searchParams.get('firmid');

        setToken(token)

        if (token && firmid) {
            verify(token, firmid);
            //TODO why this call when no token in headers
            // AuthService.checkAuth().then(({ data, error }) => {
            //     if (error) {
            //         setError(true);
            //     } else {
            //         setVerified(true);
            //         verify(token, firmid);
            //     }
            // });
        }
    }, []);

    const verify = async (token, firmid) => {
        const { data, error } = await AuthService.verifyEmail(token, firmid);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('An error occurred', error, <notificationIcon.icon style={notificationIcon.style} />);
            setError(true);
            return;
        }
        if (data.isVerified) {
            setToken(token);
            setVerified(true);
        }

        !searchParams.get('isInvite') && NavigationUtils.navigateTo('/login');
    };

    const getTitle = () => {
        if (error) {
            return 'Invalid link';
        }
        return 'Just one last step!';
    };

    const getSubTitle = () => {
        if (error) {
            return 'The link you used is either incorrect or expired, please check and try again';
        }
        if (verified) {
            return 'Please create your ResourcePlan password. Make sure you create a strong one.';
        }
        if (!verified) {
            return 'We have sent a password reset link to your registered email.';
        }
    };

    const getButtonText = () => {
        if (error) {
            return 'Resend link';
        }
        if (verified) {
            return 'Resend';
        }
        if (!verified) {
            return 'Resend';
        }
    };

    const resetPassword = async () => {
        const { data, error } = await AuthService.resetPassword(newPassword, token);
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Reset Password Failed', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Reset Password Successful', '', <notificationIcon.icon style={notificationIcon.style} />);
        setNewPassword('');
        setConfirmPassword('');
        navigateTo('/login');
    };

    return (
        <div className={classnames(loginStyles.section, loginStyles.form)}>
            <h1 className={loginStyles.title}>{getTitle()}</h1>
            <p className={loginStyles.subtitle}>{getSubTitle()}</p>
            {/* {verified && !error && ( */}
                <Form layout="vertical" name="basic" initialValues={{ remember: true }}>
                    <Form.Item
                        label="New Password"
                        name="newPassword"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter a new password'
                            },
                            {
                                message: 'Password too short',
                                validator: (_, value) => {
                                    if (newPassword.length > 7) {
                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject();
                                    }
                                }
                            }
                        ]}>
                        <Input value={newPassword} type="password" onChange={(e) => setNewPassword(e.target.value)} />
                    </Form.Item>

                    <Form.Item
                        label="Confirm Password"
                        name="confirmPassword"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the new password again'
                            },
                            {
                                message: 'Passwords do not match',
                                validator: (_, value) => {
                                    if (newPassword === confirmPassword) {
                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject('Passwords do not match.');
                                    }
                                }
                            }
                        ]}>
                        <Input value={confirmPassword} type="password" onChange={(e) => setConfirmPassword(e.target.value)} />
                    </Form.Item>
                    <Row style={{ gap: '20px', display: 'flex' }}>
                        <Col md={11} span={24} className="hp-pr-sm-0 hp-pr-12">
                            <Button block type="primary" htmlType="submit" onClick={resetPassword}>
                                Reset Password
                            </Button>
                        </Col>
                    </Row>
                </Form>
            {/* )} */}
            <Row className={loginStyles.ResendButtonParent}>
                <p className={loginStyles.text}>{error ? 'OR' : "Didn't received verification link?"}</p>
                <Button className={loginStyles.button} type={'link'} href={'/recover-password'}>
                    {getButtonText()}
                </Button>
            </Row>
            <CopyrightFooter />
        </div>
    );
};
export default ForgotScreen;

