import { APIUtil } from '../utils/ApiUtils';
import ApiURLs from '../constants/ApiURLs';
import GenUtils from '../utils/GenUtils';
import { setTeam, setTeamTimesheetsSummary } from '../redux/actions/TeamActions';
import { IMember } from '../redux/reducers/TeamReducer';

export default class TeamService {
    static async fetchTeamDetails() {
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.GET_TEAM}`);

        if (error) {
            GenUtils.log('fetchTeamDetails', { error });
            return { error };
        }

        GenUtils.dispatch(setTeam(data));
        return { data };
    }

    static async addTeamMember(
        firstName,
        lastName,
        email,
        contact,
        roleUuid,
        permission,
        office,
        hoursPerWeek,
        hoursPerDay,
        startAt,
        type,
        amount,
        teamMemberId,
        directReportees = [],
        inviteCheck = false
    ): Promise<any> {
        const { data, error } = await APIUtil.makePostRequest(`${ApiURLs.CREATE_TEAM}`, {
            firstName,
            lastName,
            email,
            contact,
            roleUuid,
            permissionId: permission,
            office,
            compensation: {
                hoursPerWeek,
                hoursPerDay,
                startAt,
                type,
                amount
            },
            directReportees,
            invite: inviteCheck
        });

        if (error) {
            GenUtils.log('addTeamMember', error);
            return { error };
        }
        return { data };
    }

    static async updateUserDetails(userId, member: IMember) {
        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.UPDATE_MEMBER}/${userId}`, GenUtils.removeEmptyKeysFromObject(member, 1));

        if (error) {
            GenUtils.log('updateUserDetails', { error });
            return { error };
        }

        await TeamService.fetchTeamMemberDetails(userId);
        return { data };
    }

    static async fetchTeamMemberDetails(memberId) {
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.GET_TEAM}/${memberId}`);

        if (error) {
            GenUtils.log('fetchTeamMemberDetails', { error });
            return { error };
        }

        await TeamService.fetchTeamDetails();
        return { data };
    }

    static async deleteMember(memberId) {
        const { data, error } = await APIUtil.makeDeleteRequest(`${ApiURLs.DELETE_MEMBER}/${memberId}`);

        if (error) {
            GenUtils.log('deleteMember', { error });
            return { error };
        }

        await TeamService.fetchTeamDetails();
        return { data };
    }

    ///////////////////////// COMPENSATION

    static async addCompensation(startAt, hoursPerWeek, hoursPerDay, type, amount, teamMemberId) {
        const { data, error } = await APIUtil.makePostRequest(`${ApiURLs.ADD_COMPENSATION}`, {
            teamMemberId,
            hoursPerWeek,
            hoursPerDay,
            startAt,
            type,
            amount
        });

        if (error) {
            GenUtils.log('addCompensation', { error });
            return { error };
        }

        await TeamService.fetchTeamMemberDetails(teamMemberId);
        return { data };
    }

    static async stopCompensation(endAt, userId) {
        // return await TeamService.addCompensation(endAt, 0, 'salary', 0, userId);
        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.STOP_COMPENSATION}/${userId}`, { endAt });

        if (error) {
            GenUtils.log('stopCompensation', { error });
            return { error };
        }

        await TeamService.fetchTeamMemberDetails(userId);
        return { data };
    }

    static async deleteCompensation(compensationId, userId) {
        const { data, error } = await APIUtil.makeDeleteRequest(`${ApiURLs.DELETE_COMPENSATION}/${compensationId}`);

        if (error) {
            GenUtils.log('deleteCompensation', { error });
            return { error };
        }

        await TeamService.fetchTeamMemberDetails(userId);
        return { data };
    }

    static async updateCompensation(startAt, hoursPerWeek, hoursPerDay, type, amount, teamMemberId) {
        const { data, error } = await APIUtil.makePostRequest(`${ApiURLs.UPDATE_COMPENSATION}`, {
            teamMemberId,
            hoursPerWeek,
            hoursPerDay,
            startAt,
            type,
            amount
        });

        if (error) {
            GenUtils.log('updateCompensation', { error });
            return { error };
        }

        await TeamService.fetchTeamMemberDetails(teamMemberId);
        return { data };
    }

    static async sendInvitation(uuid: string) {
        const { data, error } = await APIUtil.makePostRequest(`${ApiURLs.INVITE}`, { uuid });

        if (error) {
            GenUtils.log('sendInvitation', { error });
            return { error };
        }

        await TeamService.fetchTeamMemberDetails(uuid);
        return { data };
    }

    static async fetchTeamTimesheetsSummary() {
        const isLoading = true;
        GenUtils.dispatch(setTeamTimesheetsSummary(null, isLoading));
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.GET_TEAM_TIMESHEETS}`);

        if (error) {
            GenUtils.log('fetchTeamTimesheetsSummary', { error });
            return { error };
        }

        GenUtils.dispatch(setTeamTimesheetsSummary(data, !isLoading));
        return { data };
    }
}
