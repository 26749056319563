import NavigationUtils from '../../utils/NavigationUtils';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import styles from './AddInvoice.scss';
import Button from '../../components/button/Button';
import React, { useState } from 'react';
// import SwitchTabs from '../../components/tabs/SwitchTabs';
import { Input, Select, DatePicker } from 'antd';
import FormatUtils from '../../utils/FormatUtils';
import classnames from 'classnames';
import { InvoiceService } from '../../services/InvoiceService';

const Option = Select.Option;
const { RangePicker } = DatePicker;

const AddInvoice = ({ onHide }) => {
    const [isStandard, setStandard] = useState(true);
    const [selectedProject, setSelectedProject] = useState(null);
    const [serial, setSerial] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const firmId = useSelector((state: IStore) => state?.firm?.uuid);
    const { projects, projectsArray } = useSelector((state: IStore) => state.project);
    const lastInvoice = useSelector((state: IStore) => state.invoices?.lastInvoice[selectedProject]);

    const createInvoice = async () => {
        const { data, error } = await InvoiceService.createInvoice({
            type: isStandard ? 'standard' : 'retainer',
            projectId: projects[selectedProject].uuid,
            serial,
            fromDate,
            toDate
        });

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Submit', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }

        NavigationUtils.navigateTo(`/${firmId}/invoices/build/${data.uuid}?invoiceId=${data.uuid}`);
    };

    return (
        <div className={styles.container}>
            {/*Info: Will add this later*/}
            {/*<div className={styles.field}>*/}
            {/*    <div className={styles.label}>Invoice Type</div>*/}
            {/*    <div className={styles.input}>*/}
            {/*        <SwitchTabs a='Standard' b='Retainer' onChange={(isStandard) => setStandard(isStandard)} />*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className={styles.field}>
                <div className={styles.label}>Project</div>
                <div className={styles.input}>
                    <Select
                        value={selectedProject}
                        placeholder={'Project Name'}
                        onChange={(e) => {
                            setSelectedProject(e);
                        }}>
                        {projectsArray.map((project) => (
                            <Option key={project?.uuid} value={project?.uuid}>
                                {GenUtils.capitalizeFirstLetter(project?.name)}
                            </Option>
                        ))}
                    </Select>
                    <span className={classnames(styles.info, !selectedProject && styles.hidden)}>
                        {
                            selectedProject && projects[selectedProject]?.startAt
                            && <>
                                Started Date : {
                                FormatUtils.getFormattedDateFromTimestamp(projects[selectedProject]?.startAt)}
                            </>
                        }
                    </span>
                </div>
            </div>
            <div className={styles.field}>
                <div className={styles.label}>Invoice Number</div>
                <div className={styles.input}>
                    <Input value={serial} onChange={e => setSerial(e.target.value)} />
                    <span className={classnames(styles.info, !selectedProject && !lastInvoice && styles.hidden)}>
                        {
                            selectedProject && lastInvoice
                            && <>
                                Last invoice : {lastInvoice.serial}
                            </>
                        }
                    </span>
                </div>
            </div>
            <div className={styles.field}>
                <div className={styles.label}>Service Through</div>
                <div className={styles.input}>
                    <RangePicker onChange={([_fromDate, _toDate]) => {
                        setFromDate(_fromDate)
                        setToDate(_toDate)
                    }}/>
                    <span className={classnames(styles.info, !selectedProject && !lastInvoice && styles.hidden)}>
                        {
                            selectedProject && lastInvoice
                            && <>
                                Last Invoice
                                : {FormatUtils.getFormattedDateFromTimestamp(lastInvoice?.fromDate)} - {FormatUtils.getFormattedDateFromTimestamp(lastInvoice?.toDate)}
                            </>
                        }
                    </span>
                </div>
            </div>
            <div className={styles.buttonContainer}>
                <Button text='Cancel' type='ghost' onClick={onHide} />
                <Button text='Create' onClick={createInvoice} />
            </div>
        </div>
    );
};

export default AddInvoice;
