import GenUtils from '../../utils/GenUtils';
import IExpense from '../../interfaces/IExpense';

export interface IExpenseState {
    expenses: any;
    expensesArray: IExpense[];
}

export const initialExpenseState: IExpenseState = {
    expenses: {},
    expensesArray: []
};

const expenseReducer = (state: IExpenseState, action: { type: string, payload }) => {
    switch (action.type) {
        case 'SET_EXPENSES':
            return {
                expenses: GenUtils.arrayToIdMap(action.payload),
                expensesArray: action.payload
            };
        case 'UPDATE_EXPENSE':
            const expense = state.expenses[action.payload?.uuid];
            const map = {
                ...state.expenses,
                ...(expense ? {
                    [expense.uuid]: {
                        ...expense,
                        ...action.payload
                    }
                } : {
                    [action.payload.uuid]: action.payload
                })
            };

            return {
                expenses: map,
                expensesArray: Object.values(map)
            };
        default:
            return state || initialExpenseState;
    }
};

export default expenseReducer;