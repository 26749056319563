import { APIUtil } from '../utils/ApiUtils';
import ApiURLs from '../constants/ApiURLs';
import GenUtils from '../utils/GenUtils';
import { setAllOptions, setRoles, updateOption } from '../redux/actions/OptionActions';
import { EOptionType, IOption } from '../redux/reducers/OptionReducer';

export default class OptionService {
    static async fetchAllOptions() {
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.GET_OPTIONS}`);

        if (error) {
            GenUtils.log('fetchAllOptions', error);
            return { error };
        }

        // data.roles && GenUtils.dispatch(setRoles(data.roles));
        GenUtils.dispatch(setAllOptions(data));
        return { data };
    }

    static async fetchRoles() {
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.GET_ROLE}`);

        if (error) {
            GenUtils.log('fetchRoles', error);
            return { error };
        }

        GenUtils.dispatch(setRoles(data));
        return { data };
    }

    static async createRole(name, hourRate) {
        return await OptionService.createOption({name, hourRate, type: EOptionType.role});
    }

    static async createOption(option: IOption) {
        const { data, error } = await APIUtil.makePostRequest(`${ApiURLs.CREATE_ROLE}`, option);

        if (error) {
            GenUtils.log('createOption: '+option.type, error);
            return { error };
        }

        await OptionService.fetchAllOptions();
        return { data };
    }

    static async deleteOption(roleId) {
        const { data, error } = await APIUtil.makeDeleteRequest(`${ApiURLs.DELETE_ROLE}/${roleId}`);

        if (error) {
            GenUtils.log('deleteRole', error);
            return { error };
        }

        await OptionService.fetchAllOptions();
        return { data };
    }

    static async updateOption(optionId, option, type: EOptionType | null = null) {
        if (type) GenUtils.dispatch(updateOption(optionId, option, type));
        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.UPDATE_ROLE}/${optionId}`, option );

        if (error) {
            GenUtils.log('updateOption', error);
            return { error };
        }

        await OptionService.fetchAllOptions();
        return { data };
    }
}
