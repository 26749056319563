import ProjectProfitability from "./ProjectProfitability";
import TeamCapacityForecast from "./TeamCapacityForecast";
import TeamEffectiveRates from "./TeamEffectiveRates";
import RevenueForecast from "./RevenueForecast";
import TeamUtilization from "./TeamUtilization";
import RevenueVsCost from "./RevenueVsCost";
import HiringForecast from "./HiringForecast";

export const charts = {
    team_capacity_forecast: TeamCapacityForecast,
    project_profitability: ProjectProfitability,
    team_utilization: TeamUtilization,
    team_effective_rates: TeamEffectiveRates,
    revenue_forecast: RevenueForecast,
    revenue_vs_cost: RevenueVsCost,
    hiring_forecast: HiringForecast
}
