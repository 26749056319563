// @ts-ignore
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import classnames from 'classnames';

import { Card, Modal, Popover, Row } from 'antd';
import TeamAvatar from '../avatar/TeamAvatar';
import MemberAllocationDetail from '../popovers/MemberAllocationDetail';
import MemberAllocationSplit from '../popovers/MemberAllocationSplit';
import PhaseTimeline from '../popovers/PhaseTimeline';
import ProjectAvatar from '../avatar/ProjectAvatar';

import { CloseOutlined, LeftOutlined, LoadingOutlined, PlusCircleOutlined, RightOutlined } from '@ant-design/icons';
import MomentUtils from '../../utils/MomentUtils';
import FormatUtils, { EDateTimeFormat } from '../../utils/FormatUtils';

import styles from './AllocatePhaseCard.scss';
import Colors from '../../constants/Colors';
import AddPhaseRow from '../../pages/timesheet/AddPhaseRow';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import PhaseService from '../../services/PhaseService';
import AssignTeamMember from '../../pages/project/AssignTeamMember';
import AllocationService from '../../services/AllocationService';
import MemberAllottedHoursProgress from '../progress/MemberAllotedHoursProgress';
import MemberPhaseAllottedHoursDonut from '../progress/MemberPhaseAllottedHoursDonut';
import { ICompensation } from '../../redux/reducers/TeamReducer';
import CallLimiter from '../../utils/CallLimiter';
import CustomInput from '../input/CustomInput';
import Button from '../button/Button';
import PermissionUtils from '../../utils/PermissionUtils';

const limitedOnSave = new CallLimiter(() => {}, 0);

const AllocatePhaseCard = ({
    dateHeader = false,
    timeOffCard = false,
    datesStart,
    setDatesStart = (d) => null,
    weeksShifted = 0,
    shiftWeek = (n) => null,
    isTeamMember = false,
    project = null,
    phase = null,
    teamMember = null,
    key = '',
    activeCard = '',
    setActiveCard = (n) => null
}) => {
    const [showProjectPhaseModal, setCreateProjectPhase] = useState(false);
    const [showAddMemberModal, setShowAddMemberModal] = useState(false);
    const [selectedPhase, setSelectedPhase] = useState(null);
    const phaseOptions = useSelector((state: IStore) => state.options.phase);
    const { timesheet, phaseMember, phase: phases } = useSelector((state: IStore) => state.timeLogs.allocation);
    const projects = useSelector((state: IStore) => state.project.projects);
    const teamMembers = useSelector((state: IStore) => state.team.members);

    const getCapacity = (compensations: ICompensation[], weekIndex = 0): number => {
        // todo we have picked hours from current day, add logic here, to pick for the week we are looking at
        if (!compensations) return 0;
        for (let i = 0; i < compensations.length; i++) {
            if (
                (MomentUtils.isDayBeforeToday(compensations[i].startAt) || MomentUtils.isToday(compensations[i].startAt)) &&
                (i + 1 < compensations.length ? MomentUtils.isDayAfterToday(compensations[i + 1].startAt) : true)
            ) {
                return compensations[i].hoursPerWeek;
            }
        }

        return 0;
    };

    const updateTimeSheet = async (memberId, hours, week, timesheetId = null) => {
        const date = datesStart.clone();
        const data = {
            timesheets: [
                {
                    phaseMemberId: memberId,
                    date: MomentUtils.getGmtDayStart(date.add(week * 7, 'd')),
                    hours
                }
            ]
        };
        const updateData = {
            timesheets: [{ timesheetId, hours }]
        };
        let error;
        if (timesheetId) {
            error = (await AllocationService.updateAllocation(updateData)).error;
        } else {
            error = (await AllocationService.createAllocation(data)).error;
        }

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('An error occurred', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }
    };

    limitedOnSave.setAction(updateTimeSheet);
    const weekCount = 5;

    const showNextWeek = () => {
        shiftWeek(1);
        setDatesStart(datesStart.add(7, 'd'));
    };

    const showPreviousWek = () => {
        shiftWeek(-1);
        setDatesStart(datesStart.subtract(7, 'd'));
    };

    const addPhaseAllocationTrue = async (data) => {
        const resp = await PhaseService.setPhaseAllocating(data.phase);
    };

    const renderCellByType = (type = 'other', data = null) => {
        switch (type) {
            case 'phase_name':
                return renderPhaseNameCell(data);
            case 'hours':
                let sum = 0;
                let memberTimesheetId;
                if (isTeamMember) {
                    let timesheetData =
                        timesheet[
                            `phase_${data.member.uuid}_${MomentUtils.getGmtDayStart(
                                moment(datesStart)
                                    .clone()
                                    .add(data.weekIndex * 7, 'd')
                            )}`
                        ];
                    sum = timesheetData?.seconds || 0;
                    memberTimesheetId = timesheetData?.uuid;
                } else {
                    data.phase.memberIds.forEach((id) => {
                        sum =
                            sum +
                            (timesheet[
                                `phase_${id}_${MomentUtils.getGmtDayStart(
                                    moment(datesStart)
                                        .clone()
                                        .add((data.weekIndex - weeksShifted) * 7, 'd')
                                )}`
                            ]?.seconds || 0);
                    });
                }
                return renderHoursCell(
                    sum / 3600 || 0,
                    data.weekIndex,
                    data.phaseStart,
                    data.phaseEnd,
                    data.abbreviation,
                    data.phase.type.name,
                    data.phase.projectId,
                    data?.member?.uuid,
                    memberTimesheetId
                );
            case 'team_member_detail':
                return renderTeamMemberDetailCell(data);
            case 'hour_detail':
                let hourSum = 0;
                let timeSheetId;
                if (isTeamMember) {
                    data.member.members.forEach((member) => {
                        hourSum +=
                            timesheet[
                                `phase_${member.uuid}_${MomentUtils.getGmtDayStart(
                                    moment(datesStart)
                                        .clone()
                                        .add(data.weekIndex * 7, 'd')
                                )}`
                            ]?.seconds / 3600 || 0;
                    });
                } else {
                    let timesheetData =
                        timesheet[
                            `phase_${data.member.uuid}_${MomentUtils.getGmtDayStart(
                                moment(datesStart)
                                    .clone()
                                    .add(data.weekIndex * 7, 'd')
                            )}`
                        ];
                    hourSum = timesheetData?.seconds / 3600 || 0;
                    timeSheetId = timesheetData?.uuid;
                }
                return renderHourDetailCell(data, hourSum, timeSheetId);
            case 'week_dates':
                return renderWeekDatesCell(data.weekIndex);
            case 'left_element':
                return renderLeftTextCell(data);
            case 'last_cell':
                return renderLastCell(data);
            case 'element':
            default:
                return renderTextCell(data);
        }
    };
    const renderPhaseNameCell = (data) => {
        const budgetHours = data?.phase?.members ? data?.phase?.members.map((member) => member?.budgetHours).reduce((acc, cur) => acc + cur, 0) : 0;
        const budgetAmount = data?.phase?.members ? data?.phase?.members.map((member) => member?.budgetAmount).reduce((acc, cur) => acc + cur, 0) : 0;
        let progressData = {
            allotted: isTeamMember ? phaseMember[data.member.uuid].allottedAmount || 0 : data?.phase?.allottedAmount || 0,
            logged: isTeamMember ? phaseMember[data.member.uuid].loggedAmount || 0 : data?.phase?.loggedAmount || 0,
            budget: isTeamMember ? data.budgetAmount : budgetAmount
        };
        return (

            <div className={classnames(styles.leftColumn, styles.row, { [styles.subMenu]: isTeamMember })}>
                <ProjectAvatar
                    serial={data.project?.serial}
                    color={data.project?.color}
                    abbreviation={
                        phaseOptions.findIndex((phse) => phse.uuid === data.phase.type.uuid) > -1
                            ? phaseOptions[phaseOptions.findIndex((phse) => phse.uuid === data.phase.type.uuid)]?.abbreviation
                            : 'SD'
                    }
                    name={GenUtils.capitalizeFirstLetter(data.project?.name)}
                    role={GenUtils.capitalizeFirstLetter(isTeamMember ? data.role?.name : null)}
                />
                <Popover
                    trigger={['hover', 'click']}
                    content={
                        <MemberAllocationDetail
                            data={data}
                            teamMember={data.teamMember || 'Member'}
                            budgetHours={isTeamMember ? data.budgetHours : budgetHours}
                            budgetAmount={isTeamMember ? data.budgetAmount : budgetAmount}
                            loggedHours={isTeamMember ? phaseMember[data.member.uuid].loggedSecs || 0 : data.phase.loggedSecs / 3600 || 0}
                            loggedAmount={isTeamMember ? phaseMember[data.member.uuid].loggedAmount || 0 : data.phase.loggedAmount || 0}
                            allocatedHours={isTeamMember ? phaseMember[data.member.uuid].allottedSecs / 3600 || 0 : data.phase.allottedSecs / 3600 || 0}
                            allocatedAmount={isTeamMember ? phaseMember[data.member.uuid].allottedAmount || 0 : data.phase.allottedAmount || 0}
                        />
                    }>
                    <div>
                        <MemberAllottedHoursProgress progressData={progressData} />
                    </div>
                </Popover>
            </div>
        );
    };

    const renderHoursCell = (hours = 20, weekIndex = 0, phaseStart, phaseEnd, abbreviation = 'SD', phaseName, projectId, phaseMemberId = '', timesheetId) => {
        const isPhaseStart = MomentUtils.isInThisWeek(phaseStart, weekIndex);
        const isPhaseEnd = MomentUtils.isInThisWeek(phaseEnd, weekIndex);
        const isPhaseInThisWeek =
            isPhaseStart || isPhaseEnd || (MomentUtils.isBeforeThisWeek(phaseStart, weekIndex) && MomentUtils.isAfterThisWeek(phaseEnd, weekIndex));
        const startFlex = isPhaseStart ? MomentUtils.getDiffFomWeekStart(phaseStart, weekIndex) : 0;
        const endFlex = isPhaseEnd ? 6 - MomentUtils.getDiffFomWeekStart(phaseEnd, weekIndex) : 0;
        const lineFlex = 7 - (startFlex + endFlex);

        const isValidDate = moment(phaseStart).isBefore(moment(phaseEnd));
        const isWeekDisabled = weekIndex < 0;

        const project = projects[projectId] || {};

        return (
            <div className={classnames(styles.weekColumn, styles.hourCell, { [styles.subMenu]: isTeamMember }, isWeekDisabled && styles.deactivatedCell)}>
                <Popover
                    trigger={isPhaseInThisWeek ? ['hover'] : []}
                    content={
                        <PhaseTimeline
                            projectName={project?.name}
                            serial={project?.serial}
                            color={project?.color}
                            phaseName={phaseName}
                            projectStatus={project?.status}
                            abbreviation={abbreviation}
                            startDate={phaseStart}
                            endDate={phaseEnd}
                        />
                    }>
                    <div className={styles.lineContainer}>
                        {isPhaseStart && isValidDate ? (
                            <div className={styles.lineHeight} style={{ flex: startFlex }}>
                                &nbsp;
                            </div>
                        ) : null}
                        {isPhaseInThisWeek && isValidDate ? (
                            <div
                                className={classnames(styles.line, styles.lineHeight, isPhaseStart && styles.lineStart, isPhaseEnd && styles.lineEnd)}
                                style={{ flex: lineFlex, background: project?.color }}>
                                {isPhaseStart ? abbreviation : ''}
                            </div>
                        ) : null}
                        {isPhaseEnd && isValidDate ? (
                            <div className={styles.lineHeight} style={{ flex: endFlex }}>
                                &nbsp;
                            </div>
                        ) : null}
                    </div>
                </Popover>
                {isTeamMember ? (
                    <CustomInput
                        style={{ flex: 1, borderRadius: 3, borderColor: 'lightGray', width: 100, backgroundColor: isWeekDisabled ? '' : 'white' }}
                        type={'number'}
                        value={hours}
                        suffix="Hrs"
                        updateOnBlur
                        disabled={!PermissionUtils.canEditAllocation() || isWeekDisabled}
                        onChange={(value) => limitedOnSave.call(phaseMemberId, Number(value), weekIndex, timesheetId)}
                    />
                ) : (
                    <div className={styles.hoursContainer} onChange={(value) => limitedOnSave.call(phaseMemberId, Number(value), weekIndex, timesheetId)}>{hours || 0} hrs</div>
                )}
            </div>
        );
    };

    const renderTeamMemberDetailCell = (data) => {
        const { member } = data;
        const name = isTeamMember ? member.firstName + ' ' + member.lastName : member.teamMember.firstName + ' ' + member.teamMember.lastName;
        let progressData = {
            allotted: member?.allottedAmount || 0,
            logged: member?.loggedAmount || 0,
            budget: member?.budgetAmount || 0
        };

        return (
            <Row className={classnames(styles.leftColumn, !isTeamMember && styles.teamMemberCellLeft)}>
                <TeamAvatar
                    image={member?.profilePicUrl}
                    name={GenUtils.capitalizeFirstLetter(name)}
                    role={GenUtils.capitalizeFirstLetter(member.role?.name)}
                />
                {!isTeamMember && (
                    <Popover
                        trigger={['hover', 'click']}
                        content={
                            <MemberAllocationDetail
                                data={member}
                                teamMember={member.teamMember || 'Liam'}
                                budgetHours={member.budgetHours || 0}
                                budgetAmount={member.budgetAmount || 0}
                                loggedHours={member?.loggedSecs / 3600 || 0}
                                loggedAmount={member?.loggedAmount || 0}
                                allocatedHours={member?.allottedSecs / 3600 || 0}
                                allocatedAmount={member?.allottedAmount || 0}
                            />
                        }>
                        <div>
                            <MemberAllottedHoursProgress progressData={progressData} />
                        </div>
                    </Popover>
                )}
            </Row>
        );
    };

    const renderHourDetailCell = (data, hours = 0, timeSheetId) => {
        let projectTimes =
            timesheet[
                `team_${isTeamMember ? data.member.members[0].teamMemberId : data.member.teamMemberId}_${MomentUtils.getGmtDayStart(
                    moment(datesStart)
                        .clone()
                        .add(data.weekIndex * 7, 'd')
                )}`
            ];
        let donutData = [];
        projectTimes &&
            projectTimes.forEach((projectTime) => {
                let index = donutData.findIndex((time) => time.type === projectTime.projectId);
                if (index > -1) {
                    donutData[index].hours += projectTime.seconds;
                } else {
                    donutData.push({
                        type: projectTime.projectId,
                        projectName: projects[projectTime.projectId]?.name,
                        serialNumber: projects[projectTime.projectId]?.serial,
                        color: projects[projectTime.projectId]?.color,
                        hours: projectTime.seconds,
                        abbreviation:
                            phaseOptions.findIndex((phse) => phse.uuid === phases[projectTime.phaseId].type.uuid) > -1
                                ? phaseOptions[phaseOptions.findIndex((phse) => phse.uuid === phases[projectTime.phaseId].type.uuid)]?.abbreviation
                                : 'SD'
                    });
                }
            });
        donutData = donutData.map((time) => ({ ...time, hours: time.hours / 3600, type: 'project' }));
        //todo uncomment when timeoff implemented
        // donutData.push({ type: 'timeOff', hours: 0, color: '#B2BEC3' });
        const isWeekDisabled = data.weekIndex + weeksShifted < 0;
        const name = isTeamMember
            ? `${data.member.firstName} ${data.member.lastName}`
            : `${data.member.teamMember.firstName} ${data.member.teamMember.lastName}`;
        const expectedHours = getCapacity(teamMembers[isTeamMember ? data.member.members[0].teamMemberId : data.member?.teamMemberId]?.compensations);

        return (
            <Row className={classnames(isTeamMember ? styles.teamHourDetailCells : styles.hourDetailCells, { [styles.deactivatedCell]: isWeekDisabled })}>
                <Popover
                    trigger={['hover']}
                    content={
                        <MemberAllocationSplit
                            data={data}
                            memberName={name}
                            date={`${FormatUtils.getDateTimeInFormat(
                                moment(datesStart).add(data.weekIndex * 7, 'd'),
                                EDateTimeFormat.DD_MMM
                            )} - ${FormatUtils.getDateTimeInFormat(moment(datesStart).add(data.weekIndex * 7 + 6, 'd'), EDateTimeFormat.DD_MMM)}`}
                            expectedHours={expectedHours}
                            tableData={donutData}
                        />
                    }>
                    <div>
                        <MemberPhaseAllottedHoursDonut data={donutData} budgeted={expectedHours} showPercent />
                    </div>
                </Popover>
                {isTeamMember ? (
                    <div style={{ padding: 10 }}>{hours} hrs</div>
                ) : (
                    <CustomInput
                        style={{ flex: 1, backgroundColor: isWeekDisabled ? '' : 'white' }}
                        type={'number'}
                        value={hours}
                        disabled={!PermissionUtils.canEditAllocation() || isWeekDisabled || data.project?.status !== 'active'}
                        onChange={(e) => limitedOnSave.call(data.member.uuid, Number(e), data.weekIndex, timeSheetId)}
                        suffix="Hrs"
                        updateOnBlur
                    />
                )}
            </Row>
        );
    };

    const renderWeekDatesCell = (i) => {
        const start = moment(datesStart).add(i * 7, 'd');
        const end = moment(datesStart).add(i * 7 + 6, 'd');

        return (
            <div
                className={classnames(styles.weekColumn, MomentUtils.isBeforeThisWeek(end) && styles.deactivatedCell)}
                style={{ justifyContent: 'space-between' }}>
                {i === 0 ? (
                    <div
                        style={{
                            padding: '5px',
                            border: '1px solid gray',
                            borderRadius: '10px',
                            cursor: 'pointer'
                        }}
                        onClick={showPreviousWek}>
                        <LeftOutlined />
                    </div>
                ) : (
                    <div />
                )}
                {FormatUtils.getDateTimeInFormat(start, EDateTimeFormat.DD_MMM)} - {FormatUtils.getDateTimeInFormat(end, EDateTimeFormat.DD_MMM)}
            </div>
        );
    };

    const renderLastCell = (empty) => {
        return (
            <div
                className={classnames(styles.weekColumn, styles.lastCell)}
                style={empty ? {} : { cursor: 'pointer' }}
                onClick={() => {
                    if (!empty) {
                        showNextWeek();
                    }
                }}>
                {empty ? (
                    ''
                ) : (
                    <div style={{ padding: '5px', border: '1px solid gray', borderRadius: '10px' }}>
                        <RightOutlined />
                    </div>
                )}
            </div>
        );
    };

    const renderTextCell = (data) => {
        return <div className={styles.weekColumn}>{data}</div>;
    };

    const renderLeftTextCell = (data) => {
        return <div className={styles.leftColumn}>{data}</div>;
    };
    // const renderAssignMember = (data) => {
    //     return (
    //         <div className={styles.leftColumn}>
    //             {data}
    //         </div>
    //     );
    // };

    const renderRowByType = (type = 'other', data = null) => {
        switch (type) {
            case 'date_row':
                return renderDateRow(data);
            case 'team_member_header':
                return renderPhaseHeaderRow(data);
            case 'phase_header':
                return renderPhaseHeader(data);
            case 'phase_header_detail':
                return renderPhaseHeaderDetail(data);
            case 'team_member_detail':
                return renderTeamMemberDetail(data);
            case 'assign':
                return renderAssignRow(data);
            case 'time_off':
                return renderTimeOffRow(data);
            case 'element':
            default:
                return renderTextCell(data);
        }
    };

    const renderPhaseHeader = (data = null) => {
        return (
            <div className={styles.rowContainer} onClick={() => (activeCard === phase.uuid ? setActiveCard(null) : setActiveCard(phase.uuid))}>
                {renderCellByType('phase_name', data)}
                {Array(weekCount)
                    .fill(' ')
                    .map((d, i) =>
                        renderCellByType('hours', {
                            phase: data.phase,
                            hours: 20,
                            weekIndex: i + weeksShifted,
                            phaseStart: data.phase.startAt,
                            phaseEnd: data.phase.endAt,
                            abbreviation:
                                phaseOptions.findIndex((phse) => phse.uuid === data.phase.type.uuid) > -1
                                    ? phaseOptions[phaseOptions.findIndex((phse) => phse.uuid === data.phase.type.uuid)]?.abbreviation
                                    : 'SD'
                        })
                    )}
                {renderCellByType('last_cell', true)}
            </div>
        );
    };

    const renderPhaseHeaderDetail = (data = {}) => {
        return (
            <div className={classnames(styles.rowContainer, styles.teamMember)}>
                {renderCellByType('phase_name', data)}
                {Array(weekCount)
                    .fill(' ')
                    .map((d, i) =>
                        renderCellByType('hours', {
                            hours: 20,
                            weekIndex: i + weeksShifted,
                            ...data
                        })
                    )}

                {renderCellByType('last_cell', true)}
            </div>
        );
    };

    const renderTeamMemberDetail = (data) => {
        return (
            <div className={styles.rowContainer} key={data.member.uuid}>
                {renderCellByType('team_member_detail', data)}
                {Array(weekCount)
                    .fill(' ')
                    .map((d, i) => renderCellByType('hour_detail', { weekIndex: i, ...data }))}
                {renderCellByType('last_cell', true)}
            </div>
        );
    };

    const renderPhaseHeaderRow = (data = {}) => {
        return (
            <div className={styles.rowContainer} onClick={() => (activeCard === teamMember.uuid ? setActiveCard(null) : setActiveCard(teamMember.uuid))}>
                {renderCellByType('team_member_detail', data)}
                {Array(weekCount)
                    .fill(' ')
                    .map((d, i) => renderCellByType('hour_detail', { weekIndex: i, ...data }))}
                {renderCellByType('last_cell', true)}
            </div>
        );
    };

    const renderDateRow = (data = {}) => {
        return (
            <div className={classnames(styles.rowContainer, styles.noBorder)}>
                <Modal
                    title={'Create Project Phase'}
                    width={500}
                    centered
                    open={showProjectPhaseModal}
                    onCancel={() => {
                        setCreateProjectPhase(false);
                    }}
                    footer={null}
                    destroyOnClose={true}
                    closeIcon={<CloseOutlined style={{ color: Colors.red }} className="remix-icon text-color-black-100" size={24} />}>
                    <AddPhaseRow
                        hideRole
                        hideModal={() => setCreateProjectPhase(false)}
                        onSuccess={(data) => {
                            addPhaseAllocationTrue(data);
                            setCreateProjectPhase(false);
                        }}
                    />
                </Modal>
                {renderCellByType(
                    'left_element',
                    <div>
                        <Row
                            style={{
                                justifyContent: 'space-between',
                                margin: '0',
                                padding: '0',
                                alignItems: 'center'
                            }}>
                            <p
                                style={{
                                    margin: '0',
                                    fontSize: '18px',
                                    fontWeight: 'bold'
                                }}>
                                {isTeamMember ? 'Team Member' : 'Project - Phase'}
                            </p>
                            {PermissionUtils.canEditAllocation()
                                ? !isTeamMember && (
                                      <Button
                                          onClick={() => setCreateProjectPhase(true)}
                                          type={'primary'}
                                          style={{ display: 'flex', margin: '0' }}
                                          text={'Add'}
                                          icon={<PlusCircleOutlined style={{ marginRight: 10 }} />}
                                      />
                                  )
                                : null}
                        </Row>
                    </div>
                )}
                {Array(weekCount)
                    .fill(' ')
                    .map((d, i) => {
                        return renderCellByType('week_dates', { weekIndex: i });
                    })}
                {renderCellByType('last_cell')}
            </div>
        );
    };

    const renderAssignRow = (data = null) => {
        return (
            <div className={classnames(styles.rowContainer, styles.addMember)}>
                <Modal
                    title={'Add Team Member'}
                    width={700}
                    centered
                    open={showAddMemberModal}
                    onCancel={() => {
                        setShowAddMemberModal(false);
                    }}
                    footer={null}
                    destroyOnClose={true}
                    closeIcon={<CloseOutlined style={{ color: Colors.red }} className="remix-icon text-color-black-100" size={24} />}>
                    <AssignTeamMember
                        hideModal={() => {
                            setSelectedPhase(null);
                            setShowAddMemberModal(false);
                        }}
                        onSuccess={() => {
                            setShowAddMemberModal(false);
                            setSelectedPhase(null);
                        }}
                        phase={selectedPhase}
                        projectId={(selectedPhase && selectedPhase?.projectId) || null}
                    />
                </Modal>
                {renderCellByType(
                    'left_element',
                    <div style={{ flex: 1, justifyContent: 'center', display: 'flex' }}>
                        {PermissionUtils.canEditAllocation() ? (
                            isTeamMember ? (
                                <Button
                                    onClick={() => {
                                        setSelectedPhase(data?.phase);
                                        setCreateProjectPhase(true);
                                    }}
                                    icon={<PlusCircleOutlined style={{ marginRight: 10 }} />}
                                    text={'Add Project Phase'}
                                    className={styles.addBtn}
                                />
                            ) : (
                                <Button
                                    onClick={() => {
                                        setSelectedPhase(data.phase);
                                        setShowAddMemberModal(true);
                                    }}
                                    icon={<PlusCircleOutlined style={{ marginRight: 10 }} />}
                                    text={'Add Member'}
                                    className={styles.addBtn}
                                />
                            )
                        ) : null}
                    </div>
                )}
                {Array(weekCount)
                    .fill(' ')
                    .map(() => renderCellByType('element', <div style={{ padding: '15px' }} />))}
                {renderCellByType('last_cell', true)}
            </div>
        );
    };

    const renderTimeOffRow = (data) => {
        return (
            <div className={classnames(styles.rowContainer, styles.addMember, styles.noBorder)}>
                {renderCellByType(
                    'left_element',
                    <div>
                        <Row
                            style={{
                                margin: '0',
                                padding: '0',
                                alignItems: 'center'
                            }}>
                            <img src={require('../../assets/TimeOff.png')} alt="time-off" />
                            <p style={{ margin: '0 0 0 10px', fontSize: '18px', fontWeight: 'bold' }}>Time Off</p>
                        </Row>
                    </div>
                )}
                {Array(weekCount)
                    .fill(' ')
                    .map(() => renderCellByType('hours', {}))}
                {renderCellByType('last_cell', true)}
            </div>
        );
    };

    const getCardContent = () => {
        if (dateHeader) {
            return renderRowByType('date_row');
        } else if (timeOffCard) {
            return renderRowByType('time_off');
        } else if (isTeamMember) {
            return (
                <div style={{ margin: -5 }} className={styles.cardWrapper}>
                    {renderRowByType('team_member_header', { member: teamMember })}
                    {activeCard === teamMember.uuid && (
                        <>
                            {/* {renderRowByType('time_off')} */}
                            {teamMember.members.map((member) => renderRowByType('phase_header_detail', member))}
                            {renderRowByType('assign', { phase })}
                        </>
                    )}
                </div>
            );
        } else {
            return (
                <div style={{ margin: -5 }} className={styles.cardWrapper}>
                    {renderRowByType('phase_header', { project, phase })}
                    {activeCard === phase.uuid && (
                        <>
                            {Object.values(phase.members).map((member) =>
                                renderRowByType('team_member_detail', {
                                    member,
                                    project,
                                    phase
                                })
                            )}
                            {renderRowByType('assign', { phase })}
                        </>
                    )}
                </div>
            );
        }
    };

    return dateHeader || project || teamMember ? <Card className={styles.container}>{getCardContent()}</Card> : <LoadingOutlined />;
};

export default AllocatePhaseCard;
