import { Select } from 'antd';
import styles from './styles.module.scss';
import GenUtils from '../../../../utils/GenUtils';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../redux/store';
import { useObservable } from '../../../../hooks/Observable';
import { teamMemberId$ } from '../../shared/TimesheetTab';
import TimeSheetService from '../../../../services/TimeSheetService';
import MomentUtils from '../../../../utils/MomentUtils';

const ActivityCell = ({ phaseMember, activitiesInPhase, activity, project, weekStart }) => {
    const userTimesheet = useSelector((state: IStore) => state.timeLogs.timesheet);
    const { isLocked } = userTimesheet;
    const state = useSelector((state: IStore) => state);
    const act_phase_ids = activitiesInPhase.map((a) => a.uuid);
    const mapped = state.options.activity.filter((a) => !act_phase_ids.includes(a.uuid));
    const find = state.options.activity.find((a) => a.uuid === activity.uuid);
    const teamMemberId = useObservable(teamMemberId$);

    if (find) mapped.push(find);

    return (
        <div className={styles.activityCell}>
            <Select
                style={{ width: '100%' }}
                value={activity.uuid}
                onChange={(e) => {
                    TimeSheetService.updateTimesheetActivityWeek({
                        date: MomentUtils.getGmtDayStart(weekStart),
                        activityId: activity.uuid,
                        // projectId: project.uuid,
                        phaseMemberId: phaseMember,
                        teamMemberId,
                        newActivityId: e
                    });
                }}
                disabled={isLocked}>
                {mapped.map((activity) => (
                    <Select.Option value={activity.uuid} key={activity.uuid}>
                        {GenUtils.capitalizeInitials(activity.name)}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
};

export default ActivityCell;
