import { useEffect, useState } from 'react';
import { Select, DatePicker, Input } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import TeamAvatar from '../avatar/TeamAvatar';
import { IStore } from '../../redux/store';
import Button from '../button/Button';
import style from './AddCommunication.scss';
import CommunicationService from '../../services/CommunicationService';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import { ECommunicationType } from '../../redux/reducers/CommunicationReducer';
import { getUserDetails } from '../../redux/selectors/UserSelector';

const { TextArea } = Input;

export default function CommunicationModal({ onSubmit, onClose, currType = ECommunicationType.other, typeId = null, client_Id = null, currData = null }) {
    const [date, setDate] = useState(moment());
    const [nextFollowup, setNextFollowup] = useState(null);
    const [category, setCategory] = useState(currData?.category);
    const [clientId, setClientId] = useState(client_Id || currData?.clientId || typeId);
    const [hours, setHours] = useState(currData?.recordedTime);
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [client, setClient] = useState(false);
    const [communicationFrom, setCommunicationFrom] = useState(currData?.communicationFrom || 'hello');
    const [project, setProject] = useState(currData?.projectId || (client_Id ? typeId : null));
    const [subject, setSubject] = useState(currData?.subject);
    const [body, setBody] = useState(currData?.note);
    const type = ECommunicationType.communication;
    const [teamMemberId, setTeamMemberId] = useState(currData?.teamMemberId);
    const [contactId, setContactId] = useState(currData?.contactId);

    const clients = useSelector((state: IStore) => state?.client.clients);
    const memberArray = useSelector((state: IStore) => state?.team?.membersArray);
    const selectedClient = useSelector((state: IStore) => state.client.clients[clientId]);
    const projectsArray = useSelector((state: IStore) => state.project.projectsArray);
    const user = useSelector((state: IStore) => getUserDetails(state));
    let clientOption = Object.values(clients);
    let typeOption = [
        {
            value: 'call',
            label: 'Phone'
        },
        {
            value: 'email',
            label: 'Email'
        },
        {
            value: 'site visit',
            label: 'Site Visit'
        }
    ];

    useEffect(() => {
        let obj;
        obj = memberArray.find((o) => o.uuid === from);
        if (obj) {
            setCommunicationFrom('team_member');
            setTeamMemberId(from);
            setClient(false);
        } else {
            setCommunicationFrom('client');
            setContactId(from);
            setClient(true);
        }
    }, [from, memberArray]);

    useEffect(() => {
        if (client) {
            setTeamMemberId(to);
        } else {
            setContactId(to);
        }
    }, [client, to]);

    function changeTime(time) {
        if (time) {
            if (time.includes('h') && time.includes('m')) {
                let nextMin = time.split('h');
                let hour = nextMin[0];
                let min = nextMin[1];
                let minute = min.split('m', 1);
                let recordTime = hour * 3600 + minute * 60;
                setHours(Number(recordTime));
            } else if (time.includes('h')) {
                let nextMin = time.split('h');
                let hour = nextMin[0];
                let recordTime = hour * 3600;
                setHours(Number(recordTime));
            } else if (time.includes('m')) {
                let nextMin = time.split('m');
                let hour = nextMin[0];
                let recordTime = hour * 60;
                setHours(Number(recordTime));
            } else {
                const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
                GenUtils.showNotification('RecordTime must be in Format of (ex:3h2m)', <notificationIcon.icon style={notificationIcon.style} />);
                return;
            }
        }
    }
    async function handleSubmit() {
        if (clientId === '' || communicationFrom === '' || category === '' || teamMemberId === '' || subject === '' || contactId === '') {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Please fill all the Required fields', <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }

        if (currData) {
            const updatedData = {
                uuid: currData.uuid,
                category,
                type: client_Id ? ECommunicationType.project : ECommunicationType.client,
                note: body,
                communicationAt: currData.communicationAt,
                followUpAt: nextFollowup,
                teamMemberId,
                clientId,
                projectId: project,
                taskId: currData.taskId
            };

            const { error } = await CommunicationService.updateCommunications(updatedData, currData.uuid);
            if (error) {
                const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
                GenUtils.showNotification('Failed to Add Communication', error, <notificationIcon.icon style={notificationIcon.style} />);
                return;
            }
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
            GenUtils.showNotification('Success', 'Added Communication successfully', <notificationIcon.icon style={notificationIcon.style} />);
            onSubmit();
            return;
        }
        const { error } = await CommunicationService.newCommunication(
            category,
            type,
            body,
            subject,
            communicationFrom,
            date.toISOString(),
            project,
            clientId,
            teamMemberId,
            contactId,
            hours,
            nextFollowup,
            user.uuid
        );
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Add Communication', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Added Communication successfully', <notificationIcon.icon style={notificationIcon.style} />);
        onSubmit();
    }

    return (
        <div className={style.container}>
            <div className={style.inputContainer}>
                <div className={style.inputBox}>
                    <p>Client</p>
                    <Select
                        style={{ width: 230 }}
                        onSelect={(e) => setClientId(e)}
                        onClear={()=>setClientId(null)}
                        allowClear
                        filterOption={(inputValue, option) => option.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())}
                        showSearch
                        defaultValue={client_Id || (currType === ECommunicationType.client && typeId) || currData?.clientId}>
                        {clientOption.map((option) => (
                            <Select.Option value={option['uuid']}>{option['name']}</Select.Option>
                        ))}
                    </Select>
                </div>
                <div className={style.inputBox}>
                    <p>Type</p>
                    <Select
                        showSearch
                        optionFilterProp="children"
                        style={{ width: 230 }}
                        onSelect={(e) => setCategory(e)}
                        defaultValue={currData?.category}
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        options={typeOption}
                    />
                </div>
            </div>
            <div className={style.inputContainer}>
                <div className={style.inputBox}>
                    <p>Date</p>
                    <DatePicker value={date} style={{ width: 230 }} onChange={setDate} showTime={{ format: 'HH:mm' }} />
                </div>
                <div className={style.inputBox}>
                    <p>Project (Optional)</p>
                    <Select
                        style={{ width: 230 }}
                        onSelect={(e) => setProject(e)}
                        allowClear
                        filterOption={(inputValue, option) => option.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())}
                        showSearch
                        defaultValue={currData?.projectId || (currType === ECommunicationType.project ? typeId : '')}>
                        {projectsArray.map((option) => (
                            <Select.Option value={option.uuid}>{option.name}</Select.Option>
                        ))}
                    </Select>
                </div>
            </div>
            <div className={style.inputContainer}>
                <div className={style.inputBox}>
                    <p>From</p>
                    <Select
                        style={{ width: 230 }}
                        onSelect={(e) => setFrom(e)}
                        onClear={()=>setFrom(null)}
                        allowClear
                        filterOption={(inputValue, option) => option.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())}
                        showSearch>
                        {selectedClient &&
                            selectedClient.contacts.map((option) => (
                                <Select.Option key={option.uuid} value={option.uuid}>
                                    <TeamAvatar name={option.name} role="Client Contact" image={null} disableClick />
                                </Select.Option>
                            ))}
                        {memberArray.map((option) => (
                            <Select.Option key={option.uuid} value={option.uuid}>
                                <TeamAvatar
                                    name={`${option.firstName} ${option.lastName}`}
                                    image={option.profilePicUrl}
                                    disableClick
                                    role={option.role?.name}
                                />
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <div className={style.inputBox}>
                    <p>To</p>
                    <Select
                        style={{ width: 230 }}
                        onSelect={(e) => setTo(e)}
                        onClear={()=>setTo(null)}
                        allowClear
                        filterOption={(inputValue, option) => option.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())}
                        showSearch>
                        {!client &&
                            selectedClient &&
                            selectedClient.contacts.map((option) => (
                                <Select.Option key={option.userUuid} value={option.uuid}>
                                    <TeamAvatar name={option.name} role="Client Contact" image={null} disableClick />
                                </Select.Option>
                            ))}
                        {from && client &&
                            memberArray.map((option) => (
                                <Select.Option key={option.userUuid} value={option.uuid}>
                                    <TeamAvatar
                                        name={`${option.firstName} ${option.lastName}`}
                                        image={option.profilePicUrl}
                                        disableClick
                                        role={option.role?.name}
                                    />
                                </Select.Option>
                            ))}
                    </Select>
                </div>
            </div>
            <div className={style.inputBox}>
                <p className={style.title}>Subject</p>
                <Input value={subject} onChange={(e) => setSubject(e.target.value)} />
            </div>
            <div className={style.inputBox}>
                <p className={style.title}>Body</p>
                <TextArea value={body} onChange={(e) => setBody(e.target.value)} autoSize={{ minRows: 3, maxRows: 5 }} />
            </div>
            <div className={style.inputContainer}>
                <div className={style.inputBox}>
                    <p className={style.title}>Record Time</p>
                    <Input
                        value={hours}
                        onChange={(e) => setHours(e.target.value)}
                        onBlur={() => changeTime(hours)}
                        style={{ width: 230 }}
                        placeholder="Enter Time in 0h0m format"
                    />
                </div>
                <div className={style.inputBox}>
                    <p className={style.title}>Next follow-up</p>
                    <DatePicker value={nextFollowup} onChange={setNextFollowup} showTime={{ format: 'HH:mm' }} style={{ width: 230 }} />
                </div>
            </div>
            <div className={style.buttonBox}>
                <Button block type="ghost" onClick={onClose} text="Cancel" style={{ width: 230 }} />
                <Button block type="primary" onClick={handleSubmit} text="Submit" style={{ width: 230 }} />
            </div>
        </div>
    );
}
