import { useState } from 'react';
import _ from 'lodash';
import React from 'react';
import { IStore } from '../../../redux/store';
import { useSelector } from 'react-redux';
import { TimesheetData, TimesheetEntry } from '../../../redux/reducers/TimeSheetReducer';
import moment from 'moment';

const useTimeLogData = (weekCount) => {
    const [timeLogData, setTimeLogData] = useState({});
    const [totals, setTotals] = useState({});
    const [totalsV2, setTotalsV2] = useState({ day1: 0, day2: 0, day3: 0, day4: 0, day5: 0, day6: 0, day7: 0, total: 0 });
    const userTimesheet = useSelector((state: IStore) => state.timeLogs.timesheet);
    
    const resetTotals = React.useCallback(() => {
        setTotalsV2({ day1: 0, day2: 0, day3: 0, day4: 0, day5: 0, day6: 0, day7: 0, total: 0 })
    }, []);
    
    React.useMemo(() => {
        const valid = moment(userTimesheet.startDate).isValid() && moment(userTimesheet.endDate).isValid()
        if (valid && userTimesheet.startDate.length > 0 && userTimesheet.endDate.length > 0) {
            resetTotals()
            const dateKeys = getDateKeys(userTimesheet);
            const projects = userTimesheet.projects;
            Object.keys(projects).forEach((k) => {
                const phases = projects[k];
                Object.keys(phases).forEach((k1) => {
                    const activities = phases[k1];
                    Object.keys(activities).forEach((k2) => {
                        const dates = activities[k2];
                        const calculated = calculate(dates, dateKeys);
                        setTotalsV2(p => {
                            Object.keys(p).forEach(k => {
                                p[k] += calculated[k]
                            })
                            return p
                        })
                    });
                });
            });
        }
    }, [resetTotals, userTimesheet]);


    const calculateTotals = React.useCallback(() => {
        const dayTotals = {};
        for (const id in timeLogData) {
            timeLogData[id].forEach((timeLog) => {
                [...Array(weekCount)].map((e, i) => {
                    let day = `day${i + 1}`;
                    dayTotals[day] = (dayTotals[day] || 0) + timeLog[day].time;
                });
                dayTotals['totals'] = (dayTotals['totals'] || 0) + timeLog['total'] / 3600;
            });
        }
        setTotals(dayTotals);
    }, [timeLogData, weekCount]);

    const updateTimeLogData = (phaseMemberId, day, data, index) => {
        let row = timeLogData[phaseMemberId][index];
        _.set(row, day, data);
        setTimeLogData({
            ...timeLogData,
            [phaseMemberId]: [...timeLogData[phaseMemberId].slice(0, index), row, ...timeLogData[phaseMemberId].slice(index + 1)]
        });
        calculateTotals();
    };
    const emptyRow = (phaseMemberId) => {
        let emptyRow = {};
        [...Array(weekCount)].map((e, i) => {
            let day = 'day' + (i + 1);
            emptyRow = { ...emptyRow, [day]: { time: 0, note: '' } };
        });
        emptyRow = { ...emptyRow, activity: '', total: 0, phaseMemberId };
        return emptyRow;
    };
    const addActivityRow = (phaseMemberId) => {
        let row = emptyRow(phaseMemberId);
        setTimeLogData({ ...timeLogData, [phaseMemberId]: [...timeLogData[phaseMemberId], row] });
    };

    return { timeLogData, setTimeLogData, totalsV2, setTotalsV2, resetTotals, calculateTotals, updateTimeLogData, emptyRow, addActivityRow };
};

function getDateKeys(timesheetData: TimesheetData) {
    const start = timesheetData.startDate;
    const end = timesheetData.endDate;
    const rest: string[] = new Array(5);
    for (let i = 1; i <= 5; i++) {
        const d = moment(start).add(i, 'd').toDate().toISOString();
        rest[i - 1] = isoToTimesheetKey(d);
    }
    return [isoToTimesheetKey(start), ...rest, isoToTimesheetKey(end)];
}

function isoToTimesheetKey(str: string) {
    const arr = str.split('T')[0].split('-');
    return `${arr[1]}/${arr[2]}/${arr[0]}`;
}

function calculate(dates: { [date: string]: TimesheetEntry[] }, dateKeys: string[]) {
    const init = { day1: 0, day2: 0, day3: 0, day4: 0, day5: 0, day6: 0, day7: 0, total: 0 };
    for (let i = 0; i < 7; i++) {
        const entries = dates[dateKeys[i]];
        if (entries) {
            const amount = entries.reduce((a, b) => a + b.seconds / 3600, 0);
            init[`day${i + 1}`] = amount;
            init.total += amount;
        }
    }
    return init;
}

export default useTimeLogData;
