import React from 'react';
import { TAudits } from '../../services/TimeSheetService';
import MomentUtils from '../../utils/MomentUtils';
import FormatUtils from '../../utils/FormatUtils';
import moment from 'moment';

export function useAuditItems(audits: TAudits, weekCount: 7, weekStart: Date) {
    const [items, setItems] = React.useState<{ projectId: string; data: ReturnType<typeof prepareItem> }[]>([]);
    React.useMemo(() => {
        if (audits) {
            const r = Object.keys(audits.projectsObj).map((s) => {
                const row = prepareItem(s, audits, weekCount, weekStart);
                return { projectId: s, data: row };
            });
            setItems(r);
        }
    }, [audits, weekCount, weekStart]);
    return { items, setItems };
}

const prepareItem = (projectId: string, audits: TAudits, weekCount: 7, weekStart: Date) => {
    return Object.keys(audits.phasesMembersObj)
        .filter((member) => audits.phasesMembersObj[member].projectId === projectId)
        .map((key) => {
            const member = audits.phasesMembersObj[key];
            const mapped = Object.keys(audits.activitiesObj)
                .map((activityKey) => {
                    const activity = audits.activitiesObj[activityKey];
                    let time = audits.timesheetsObj[`member_${member.uuid}_${activity.uuid}`] as number;
                    let status = audits.timesheetsObj[`status_member_${member.uuid}_${activity.uuid}`];
                    if (time) {
                        const arr = [...Array(weekCount)].map((e, i) => {
                            let day = `day${i + 1}`;
                            let timelog = audits.timesheetsObj[
                                `member_${member.uuid}_${activity.uuid}_${MomentUtils.getGmtDayStart(moment(weekStart).clone().add(i, 'd'))}`
                            ] as number;
                            return { [day]: timelog ? FormatUtils.getAmountInFormat(timelog / 3600, false, 1) : 0 };
                        });
                        const row = {
                            ...arr,
                            member: `${member?.teamMember?.firstName} ${member?.teamMember?.lastName}`,
                            role: member?.role?.name,
                            phase: audits?.phasesObj[member?.phaseId]?.type.name,
                            total: FormatUtils.getAmountInFormat(time / 3600, false, 1),
                            activity: activity?.name,
                            phaseMemberId: member?.uuid,
                            phaseId: member?.phaseId,
                            activityId: activity?.uuid,
                            teamMemberId: member?.teamMember?.uuid,
                            roleId: member?.role?.uuid,
                            status: status ? status : 'approved'
                        };
                        return row;
                    }
                    return null;
                })
                .filter((f) => f !== null);
            return mapped;
        })
        .map((s) => s[0]);
};
