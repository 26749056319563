import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import styles from './CollapseButton.scss';

const CollapseButton = ({collapsed, setCollapsed}) => {
    return (
        <div className={styles.buttonContainer} onClick={() => setCollapsed(!collapsed)}>
            {
                collapsed ? <RightOutlined className={styles.icon}/> : <LeftOutlined className={styles.icon}/>
            }
        </div>
    );
}

export default CollapseButton;