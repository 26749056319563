import IPermission from '../../interfaces/IPermission';
import PermissionService from '../../services/PermissionService';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import { Button, Col, Divider, Popconfirm, Select, Switch, Table } from 'antd';
import React from 'react';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';

const columnClass = 'hp-p1-body hp-text-color-black-100 hp-text-color-dark-0 ';

const managerRights = [
    { permission: 'They do not manage any team member', key: 'none' },
    { permission: 'They manage people that are assigned', key: 'assigned' },
    { permission: 'They manage all firm members', key: 'all' }
];
const projectToManagerRights = [
    { permission: 'They do not manage any project', key: 'none' },
    { permission: 'They manage assigned projects', key: 'assigned' },
    { permission: 'They manage all projects', key: 'all' }
];
const timeTracking = [
    { permission: 'View Timesheet of assigned team members', key: 'viewTimeSheet' },
    { permission: 'Edit Timesheets of assigned team members', key: 'editTimeSheet' },
    { permission: 'Approve Timesheets of assigned team members', key: 'approveTimeSheet' }
];
const scheduleWork = [
    { permission: 'View scheduled time of all team members', key: 'ViewTimeAllocation' },
    { permission: 'Edit scheduled time of all team members', key: 'editTimeAllocation' },
    { permission: 'View financial information about project budgets', key: 'viewAllocationFinance' }
];
const projectPlanning = [
    { permission: 'Projects to manage', key: 'projectsToManage' },
    { permission: 'Edit projects', key: 'editProjects' },
    { permission: 'Archive projects', key: 'archiveProjects' },
    { permission: 'View the Financial Information for projects', key: 'viewAllProjectFinance' },
    { permission: 'Create new projects', key: 'createProjects' }, 
];
const expenses = [
    { permission: 'Manage Consultant Expenses (create, view, delete)', key: 'manageConsultantExpenses' },
    { permission: 'Manage Firm Expenses (create, edit, delete)', key: 'manageFirmExpenses' },
    { permission: 'Manage Project Expenses (create, view) of assigned team members', key: 'manageMembersProjectExpenses' },
    { permission: 'Manage Project Expenses (create, view)  for the projects they manage', key: 'manageProjectExpenses' },
    { permission: 'Allow approval of expenses', key: 'approveExpenses' },
    { permission: 'View & manage all Project Expenses', key: 'viewExpenses' },
];
const communications = [
    { permission: 'Manage Communications (create, edit)', key: 'manageFinancialCommunication' },
    { permission: 'View communications by assigned team members', key: 'manageProjectCommunication' },
    { permission: 'View all communications', key: 'allCommunication' }
];
const invoicing = [
    { permission: 'View invoices', key: 'viewInvoices' },
    { permission: 'Edit invoices', key: 'editInvoices' },
    { permission: 'Create invoices', key: 'createInvoices' },
    { permission: 'Delete invoices', key: 'deleteInvoices' },
    { permission: 'Approve invoices for the projects they manage', key: 'approveAssignedProjectInvoices' },
    { permission: 'Approve all invoices', key: 'approveInvoices' }
];
const clients = [
    { permission: 'View client details', key: 'viewClientDetails' },
    { permission: 'View client financials', key: 'viewClientFinances' },
    { permission: 'Create clients', key: 'createClient' },
    { permission: 'Manage clients (edit, delete)', key: 'manageClient' }
];
const reports = [
    { permission: 'View Financial Reports', key: 'viewFinanceReports' },
    { permission: 'View Reports of all projects', key: 'viewAllProjectReports' },
    { permission: 'View Reports of assigned projects', key: 'viewProjectReports' },
    { permission: 'View Reports of all team members', key: 'viewAllMemberReports' },
    { permission: 'View Reports of assigned team members', key: 'viewMemberReports' }
];
const team = [
    { permission: 'Manage team members (create, invite, edit, delete)', key: 'manageMembers' },
    {
        permission: 'Manage compensation & permission (create, edit, delete) of assigned team members',
        key: 'manageCompensations'
    }
];
const accountAdministration = [{ permission: 'Manage firm settings', key: 'manageFirmSettings' }];
const AllPermissions = [
    // { key: 'Manager Rights', data: managerRights },
    { key: 'Time Tracking', data: timeTracking, about: " All users can manage their own timesheets. Adjust permissions to allow management of timesheets for assigned members." },
    { key: 'Schedule Work', data: scheduleWork, about: "" },
    { key: 'Project Planning', data: projectPlanning, about: " All users can see the basic details of assigned projects. Adjust permissions to allow project management and visibility of financial information." },
    { key: 'Expenses', data: expenses, about: " All users can add their project expenses. Adjust permissions to allow expense management and approval." },
    { key: 'Communications', data: communications, about: " Adjust permissions for further communication management. All users can view communications they add. " },
    { key: 'Invoicing', data: invoicing, about: " Adjust permissions for Invoice management." },
    { key: 'Clients', data: clients, about: " Adjust permissions for Client management." },
    { key: 'Reports', data: reports, about: " Adjust permissions for viewing reports." },
    { key: 'Team', data: team, about: " Adjust permissions for managing team members." },
    { key: 'Account Administration', data: accountAdministration, about: " " }
];

const PermissionDetails = ({ permissionId, onHide = () => {}, assignedUserLength }) => {
    const dividerClass = 'hp-border-color-black-40 hp-border-color-dark-80';

    const permission: IPermission = useSelector((state: IStore) => state.permission.permissions[permissionId]);

    const columns = [
        {
            title: 'Permission',
            dataIndex: 'permission',
            width: 400,
            render: (text) => <span className={columnClass}>{text}</span>
        },
        {
            title: 'Status',
            dataIndex: 'key',
            width: 80,
            render: (key) => (
                <span className={columnClass}>
                    {key == 'projectsToManage' ? (
                        <Select
                            placeholder="Select Project to Manager Rights"
                            // style={{ width: '50%' }}
                            value={permission['projectsToManage']}
                            onChange={(value) => updatePermission('projectsToManage', value)}>
                            {projectToManagerRights.map((item) => (
                                <Select.Option key={item.key} value={item.key}>
                                    {item.permission}
                                </Select.Option>
                            ))}
                        </Select>
                    ) : (
                        <Switch checked={permission[key]} disabled={false} onChange={(value) => updatePermission(key, value)} />
                    )}
                </span>
            )
        }
    ];

    const updatePermission = async (fieldToBeUpdated, value) => {
        const { data, error } = await PermissionService.updatePermission({
            ...permission,
            [fieldToBeUpdated]: value
        });

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Update', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }

        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Successfully Updated permission', '', <notificationIcon.icon style={notificationIcon.style} />);
    };

    const deletePermission = async (permissionId) => {
        const { data, error } = await PermissionService.deletePermission(permissionId);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to delete', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Successfully Deleted permission', '', <notificationIcon.icon style={notificationIcon.style} />);
        onHide();
    };

    return (
        <div style={{ padding: '20px' }}>
            <div style={{ marginBottom: '20px', display: 'flex' }}>
                <span
                    style={{
                        display: 'flex',
                        flex: 1,
                        fontSize: '20px',
                        fontWeight: 'bold'
                    }}>
                    {GenUtils.capitalizeFirstLetter(permission.name)}
                </span>
                <Popconfirm title="Are you sure to delete this Permission?" onConfirm={() => deletePermission(permission.uuid)} okText="Yes" cancelText="No">
                    {assignedUserLength == 0 && <Button danger>Delete this Permission</Button>}
                </Popconfirm>
            </div>
            <div key={'Manager Rights'}>
                <div>
                    <Col md={12} span={24}>
                        <h5>Manager Rights</h5>
                    </Col>
                </div>
                <Select
                    placeholder="Select Manager's Rights"
                    style={{ width: '50%' }}
                    value={permission['membersToManage']}
                    onChange={(value) => updatePermission('membersToManage', value)}>
                    {managerRights.map((item) => (
                        <Select.Option key={item.key} value={item.key}>
                            {item.permission}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            {
                AllPermissions.map((perm, index) =>(
                <div>
                    <div key={perm.key}>
                        <Divider className={dividerClass} />
                        <div>
                            <Col md={12} span={24}>
                                <h5>{perm.key}</h5>
                            </Col>
                            <Col>
                                <p style={{fontWeight:"400"}}>{perm.about}</p>
                            </Col>
                        </div>
                        <Table
                            className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                            columns={columns}
                            dataSource={perm.data}
                            size='middle'
                            pagination={false}
                        />
                    </div>
                    <Table
                        className="hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius"
                        columns={columns}
                        dataSource={perm.data}
                        size="middle"
                        pagination={false}
                    />
                </div>
            ))}
        </div>
    );
};
export default PermissionDetails;
