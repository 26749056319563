import { APIUtil } from '../utils/ApiUtils';
import ApiURLs from '../constants/ApiURLs';
import AppUtils from '../utils/AppUtils';
import GenUtils from '../utils/GenUtils';
import { saveAuthData } from '../redux/actions/AuthActions';
import StorageUtils from '../utils/StorageUtils';
import { setActivityLogs } from '../redux/actions/ActivityActions';
import FirmService from './FirmService';
import PermissionUtils from '../utils/PermissionUtils';

export default class AuthService {
    static async checkAuth() {
        const { data, error } = await APIUtil.makeGetRequest(ApiURLs.CHECK_AUTH);

        if (error) {
            return { error };
        }

        if (data.userUuid) {
            StorageUtils.saveFirmIdToStorage(data.userUuid);
        }
        StorageUtils.saveUserDetailsToStorage(data);
        GenUtils.dispatch(saveAuthData(data));
        PermissionUtils.permission = data.permission;
        
        FirmService.fetchFirms();

        return { data };
    }

    static async login(email: string, password: string, dispatch) {
        const { data, error } = await APIUtil.makePostRequest(ApiURLs.LOGIN, { email, password });

        if (error || GenUtils.isEmpty(data)) {
            return { error };
        }
        AppUtils.onLogin(data, dispatch);
        return { data };
    }

    static async signup({ email, firmName, firmSize, firmLocation, industry, firstName, lastName, password, currency, timeZone, dateFormat }, dispatch) {
        const { data, error } = await APIUtil.makePostRequest(ApiURLs.SIGNUP, {
            email,
            firmName,
            firmSize,
            firmLocation,
            firmType: industry,
            firstName,
            lastName,
            password,
            currency,
            timeZone,
            dateFormat
        });

        if (error) {
            return { error };
        }

        AppUtils.onLogin(data, dispatch);
        return { data };
    }

    static async changePassword(email, oldPassword, newPassword) {
        const { data, error } = await APIUtil.makePutRequest(ApiURLs.RESET_PASSWORD, {
            email,
            oldPassword,
            newPassword
        });

        if (error || GenUtils.isEmpty(data)) {
            return { error };
        }

        return { data };
    }

    static async fetchActivityLog() {
        const { data, error } = await APIUtil.makeGetRequest(ApiURLs.ACTIVITY_LOG);

        if (error || GenUtils.isEmpty(data)) {
            return { error };
        }

        GenUtils.dispatch(setActivityLogs(data));
        return { data };
    }

    static async verifyEmail(token, firmId) {
        APIUtil.customHeaders = { token, firmId };

        this.checkAuth();

        const { data, error } = await APIUtil.makePutRequest(ApiURLs.VERIFY, {});

        if (error) {
            return { error };
        }

        return { data };
    }

    static async sendVerificationLink(email) {
        const { data, error } = await APIUtil.makePutRequest(ApiURLs.VERIFICATION_LINK, { email });

        if (error) {
            return { error };
        }

        return { data };
    }

    static async sendPasswordResetLink(email) {
        const { data, error } = await APIUtil.makePutRequest(ApiURLs.FORGOT_PASSWORD_LINK, { email });

        if (error) {
            return { error };
        }

        return { data };
    }

    static async resetPassword(password, token) {
        APIUtil.customHeaders = { token };
        const { data, error } = await APIUtil.makePutRequest(ApiURLs.RESET_PASSWORD, { newPassword: password });

        if (error || GenUtils.isEmpty(data)) {
            return { error };
        }

        return { data };
    }
}
