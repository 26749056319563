import { Alert, Form, Input, Select } from 'antd';
import { useState } from 'react';

import Button from '../../components/button/Button';

import styles from './AddClient.scss';
import formStyles from '../../pages/form.scss';
import classnames from 'classnames';
import { Countries, Currencies, ReferralSources } from '../../constants/Options';
import ClientService from '../../services/ClientService';
import IContact from '../../interfaces/IContact';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import EditableCellsTable from '../../components/table/EditableCellsTable';
import { CloseOutlined } from '@ant-design/icons';

const { Option } = Select;

const AddClient = ({
                       hideModal = () => {
                       }, onSuccess = () => {
    }
                   }) => {
    const [name, setName] = useState('');
    const [referralSource, setReferralSource] = useState('');
    // todo take default from firm's currency setting in redux
    const [currency, setCurrency] = useState('INR');
    const [taxRegId, setTaxRegId] = useState('');
    const [building, setBuilding] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [contacts, setContacts] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);

    const submitForm = async () => {
        const { data, error } = await ClientService.createClient(
            name,
            referralSource,
            currency,
            taxRegId,
            building,
            street,
            city,
            state,
            country,
            zipCode,
            contacts
                .filter(contact => {
                    return Object
                        .keys(contact)
                        .reduce((didEnterSomeValue, k) => k !== 'key' ? didEnterSomeValue || (contact[k] !== '...' && contact[k] !== '') : 
didEnterSomeValue, false);
                })
                .map((contact) => {
                    let c = contact;
                    Object
                        .keys(c)
                        .forEach((k) => c[k] === '...' ? c[k] = '-' : c[k]);
                    delete c.key;
                    return c;
                })
        );
        onSuccess();
        setName('');
        setReferralSource('');
        setCurrency('INR');
        setTaxRegId('');
        setBuilding('');
        setStreet('');
        setCity('');
        setState('');
        setCountry('');
        setZipCode('');
        setContacts([]);
        setErrorMessage(null);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('failed to add Client', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
    };

    const emptyContact = {
        name: '...',
        designation: '...',
        email: '...',
        mobile: '...',
        key: Date.now()
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            editable: true
        },
        {
            title: 'Designation',
            dataIndex: 'designation',
            editable: true
        },
        {
            title: 'Email',
            dataIndex: 'email',
            editable: true
        },
        {
            title: 'Contact',
            dataIndex: 'mobile',
            editable: true
        },
        {
            title: '',
            dataIndex: 'delete-action',
            editable: true,
            render: (text, data, index) => <CloseOutlined style={{ color: 'IndianRed' }} />
        }
    ];

    return (
        <div className={styles.container}>
            {errorMessage && <Alert message={errorMessage} type='error' closable onClose={() => {
            }} />}
            <form>
                <Form.Item label='Company Name' className={styles.margin0}></Form.Item>
                <Input
                    placeholder='Name'
                    type={'name'}
                    className={formStyles.marginBottom20}
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                    }}
                />
                <div className={styles.row}>
                    <div className={styles.column}>
                        <Form.Item label='Referral Source' className={styles.margin0} />
                        {/* todo select default value here */}
                        <Select
                            showSearch
                            placeholder='Select'
                            value={referralSource}
                            onChange={(e) => {
                                setReferralSource(e);
                            }}>
                            {ReferralSources.map((source) => (
                                <Option key={source} value={source}>
                                    {source}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    {/* <div className={styles.column}>
                        <Form.Item label='Preferred Currency' className={styles.margin0} />
                        todo select default value here
                        <Select
                            showSearch
                            placeholder='INR'
                            value={currency}
                            onChange={(value) => {
                                setCurrency(value);
                            }}>
                            {Currencies.map((currency) => (
                                <Option key={currency.iso} value={currency.iso}>
                                    {currency.name} - {currency.symbol}
                                </Option>
                            ))}
                        </Select>
                    </div> */}
                    {/* show on ly if country is India */}
                    {country.toLowerCase() === 'india' && (
                        <div className={styles.column}>
                            <Form.Item label='GST Number' className={styles.margin0} />
                            <Input
                                value={taxRegId}
                                placeholder='example'
                                onChange={(e) => {
                                    setTaxRegId(e.target.value);
                                }}
                            />
                        </div>
                    )}
                </div>
                <div>
                    <Form.Item className={classnames(styles.marginBottomN10)} label='Address' />
                    <div className={styles.row}>
                        <Input
                            placeholder='Street, avenue, etc.'
                            type={'text'}
                            onChange={(e) => {
                                setStreet(e.target.value);
                            }}
                        />
                        <Input
                            placeholder='Suite, Unit, Apt, etc'
                            type={'text'}
                            onChange={(e) => {
                                setBuilding(e.target.value);
                            }}
                        />
                    </div>
                    <div className={styles.row}>
                        <Input
                            placeholder='City'
                            type={'text'}
                            onChange={(e) => {
                                setCity(e.target.value);
                            }}
                        />
                        <Input
                            placeholder='State/Province/Region'
                            type={'text'}
                            onChange={(e) => {
                                setState(e.target.value);
                            }}
                        />
                        <Input
                            placeholder='Zip/Postal Code'
                            type={'text'}
                            onChange={(e) => {
                                setZipCode(e.target.value);
                            }}
                        />
                    </div>
                    <div className={styles.row}>
                        <Form.Item rules={[{ required: true }]}>
                            <Select
                                showSearch
                                placeholder="Country"
                                style={{ width: 300 }}
                                onChange={(value) => {
                                    setCountry(value);
                                }}>
                                {Countries.map((_country) => (
                                    <Option key={_country} value={_country}>
                                        {_country}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                </div>
                <div>
                    <Form.Item className={styles.contactLabel} label='Contact' />
                    <EditableCellsTable
                        columns={columns}
                        dataSource={
                            contacts.length
                                ? contacts.map((contact: IContact, index) => {
                                    return { ...contact, key: contact.uuid || index };
                                })
                                : [emptyContact]
                        }
                        newData={emptyContact}
                        setDataSource={setContacts}
                        addButtonText={'Add New Contact'}
                    />
                </div>
            </form>
            <div className={classnames(styles.row, styles.center, styles.right)}>
                <Button
                    type={'ghost'}
                    text="Cancel"
                    onClick={() => {
                        hideModal();
                    }}
                />
                <Button
                    text="Add"
                    onClick={() => {
                        submitForm();
                    }}
                />
            </div>
        </div>
    );
};

export default AddClient;
