import Tag from '../tag/Tag';

import styles from './PhaseDetailPopover.scss';
import FormatUtils, { EDateTimeFormat } from '../../utils/FormatUtils';

const PhaseDetailPopover = ({ phase, project }) => {
    const getProgress = (p) => {
        return !p.amount ? 100 :  (p.progress?.amount || 0)* 100 / p.amount;
    }

    console.log(phase, project)

    return (<div className={styles.container}>
            <div className={styles.header}
            >
                <div style={{ display: 'flex', gap: 5, fontWeight: 500, fontSize:12 }}>
                    <Tag color={project.color} text={phase?.type.abbreviation} style={{fontSize:10}}/>{phase.type.name}
                </div>

                <div style={{ fontSize:12}}>
                    {FormatUtils.getDateTimeInFormat(phase.startAt, EDateTimeFormat.DD_MMM)} - {FormatUtils.getDateTimeInFormat(phase.endAt, EDateTimeFormat.DD_MMM)}
                </div>
            </div>
            <div className={styles.table}>
                <div style={{ flex: 1 }}>
                    <div className={styles.tableHeader}>Phase Fee</div>
                    <div className={styles.tableContent}>Logged</div>
                    <div className={styles.tableContent}>Remaining</div>
                </div>
                <div style={{ flex: 1 }}>
                    <div className={styles.tableHeader}>{FormatUtils.getAmountInFormat(phase.amount, true, 2, 0)}</div>
                    <div
                        className={styles.tableContent}>{FormatUtils.getAmountInFormat(phase.progress?.amount, true, 2, 0)}</div>
                    <div
                        className={styles.tableContent}>{FormatUtils.getAmountInFormat(phase.amount - (phase.progress?.amount || 0), true, 2, 0)}</div>
                </div>
                <div style={{ flex: 1 }}>
                    <div className={styles.tableHeader}>100%</div>
                    <div
                        className={styles.tableContent}>
                        {
                            FormatUtils.getAmountInFormat(getProgress(phase), false, 2, 0)
                        }%
                    </div>
                    <div className={styles.tableContent}>
                        {
                            FormatUtils.getAmountInFormat(100 - getProgress(phase), false, 2, 0)
                        }%
                    </div>
                </div>
            </div>
        </div>
    );
};
export default PhaseDetailPopover;
