import ApiURLs from '../constants/ApiURLs';
import { APIUtil } from '../utils/ApiUtils';
import IUser from '../interfaces/IUser';
import { setUser } from '../redux/actions/UserActions';
import GenUtils from '../utils/GenUtils';
import StorageUtils, { StorageConstants } from '../utils/StorageUtils';
import FormatUtils from '../utils/FormatUtils';
import MomentUtils from '../utils/MomentUtils';
import { setCurrentFirm } from '../redux/actions/FirmActions';

export default class UserService {
    static async fetchFirmDetails(firmId): Promise<any> {
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.GET_USER}/${firmId}`);

        if (error) {
            GenUtils.log('getUser', { error });
            return { error };
        }

        if (StorageUtils.getFirmId() === firmId) {
            GenUtils.dispatch(setCurrentFirm(firmId, data));

            if (!GenUtils.isEmpty(data.timeFormat)) {
                FormatUtils.timeFormat = data.timeFormat;
            }

            if (!GenUtils.isEmpty(data.dateFormat)) {
                FormatUtils.dateFormat = data.dateFormat;
            }

            if (!GenUtils.isEmpty(data.amountFormat)) {
                FormatUtils.amountFormat = data.amountFormat;
            }

            if (!GenUtils.isEmpty(data.currency)) {
                FormatUtils.currencyIso = data.currency;
            }

            if (!GenUtils.isEmpty(data.weekStart)) {
                MomentUtils.weekStart = data.weekStart;
            }

            if (!GenUtils.isEmpty(data.yearStart)) {
                MomentUtils.yearStart = data.yearStart;
            }
        }
        return { data };
    }

    static async updateUser(user: IUser) {
        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.UPDATE_USER}`, GenUtils.removeEmptyKeysFromObject(user, 0, true, false));

        if (error) {
            GenUtils.log('updateUser', { error });
            return { error };
        }

        await UserService.fetchFirmDetails(StorageUtils.getFirmId());
        return { data };
    }

    static async updateFirmLogo(fileId) {
        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.UPDATE_USER}`, {profilePicFileId: fileId});

        if (error) {
            GenUtils.log('updateFirmLogo', { error });
            return { error };
        }

        await UserService.fetchFirmDetails(StorageUtils.getFirmId());
        return { data };
    }
}
