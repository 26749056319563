
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import Gantt from '../../components/timeline/Gantt';
import { useState } from 'react';
import { EFilterCategory, EFilterTypes } from '../../components/filterBars/FiltersBarMultiselect';
import GroupedTable from '../../components/table/groupedTable/GroupedTable';

const ProjectOverview = () => {
    const projectsArray = useSelector((state: IStore) => state.project.projectsArray);

    const filters = [
        {
            name: 'Status',
            dataIndex: 'status',
            type: EFilterTypes.dropdown,
            category: EFilterCategory.simple,
        },
        {
            name: 'Project',
            dataIndex: 'projectId',
            type: EFilterTypes.dropdown,
            category: EFilterCategory.simple,
        },
        {
            name: 'Client',
            dataIndex: 'clientId',
            type: EFilterTypes.dropdown,
            category: EFilterCategory.advance,
        }
    ]

    return (
        <div>
            {
                projectsArray.length > 0
                    ?
                    <GroupedTable
                        columns={[]}
                        data={projectsArray.map((value) => ({ ...value, key: value.uuid, projectId: value.uuid }))}
                        filters={filters}
                        renderTable={(columns, data) => (
                            <Gantt data={data} />
                        )}
                    />
                    : 'No projects'
            }
        </div>
    );
};
export default ProjectOverview;
