import { Row, Col } from 'antd';
import styles from './styles.scss';

const HeaderRow = () => {
    return (
        <Row className={styles.rowHeader}>
            <Col span={4}>Project</Col>
            <Col span={4}>Phase</Col>
            <Col span={4}>Activity</Col>
            <Col span={2}>Note</Col>
            <Col span={3}>Start</Col>
            <Col span={3}>End</Col>
            <Col span={2}>Time</Col>
            <Col span={2}></Col>
        </Row>
    );
};

export default HeaderRow;
