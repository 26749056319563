import { useEffect, useState } from 'react';
import FormatUtils from '../../utils/FormatUtils';
import style from './AddCommunication.scss';
import Tag from '../tag/Tag';

export default function ViewModal({ data }) {
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [type, setType] = useState('');

    useEffect(() => {
        if (data.category === 'email') {
            setType('Email');
        }
        if (data.category === 'call') {
            setType('Call');
        }
        if (data.category === 'site visit') {
            setType('Site Visit');
        }
        if (data.communicationFrom === 'team_member') {
           { data.teamMember? setFrom(`${data.teamMember?.firstName} ${data.teamMember?.lastName}`) : setFrom(' ') }
           { data.contact? setTo(`${data.contact?.name}`) : setTo(' ') }
        } else if (data.communicationFrom === 'client') {
            { data.contact? setFrom(`${data.contact?.name}`) : setFrom(' ') }
            { data.teamMember? setTo(`${data.teamMember?.firstName} ${data.teamMember?.lastName}`) : setTo(' ') }
        }
    }, [data]);

    return (
        <div className={style.modal}>
            <div className={style.modalTag}>
                <p>Client Name:</p>
                <span>{data.client.name}</span>
            </div>
            <div className={style.modalTag}>
                <p>Project:</p>
                <div className={style.projectTag}>
                    <Tag text={data.project?.serial} color={data.project?.color} />
                    <span>{data.project?.name}</span>
                </div>
            </div>
            <div className={style.modalTag}>
                <p>Category:</p>
                <span>{type}</span>
            </div>
            <div className={style.modalTag}>
                <p>From:</p>
                <span>{from}</span>
            </div>
            <div className={style.modalTag}>
                <p>To:</p>
                <span>{to}</span>
            </div>
            <div className={style.modalTag}>
                <p>Date:</p>
                <span>{FormatUtils.getFormattedDateFromTimestamp(`${data.communicationAt}`)}</span>
            </div>
            <div className={style.modalTag}>
                <p>Next follow-up:</p>
                <span>{FormatUtils.getFormattedDateFromTimestamp(`${data.followUpAt}`)}</span>
            </div>
            <div className={style.modalTag}>
                <p>Recorded Time:</p>
                <span>{FormatUtils.formatTime(`${data.recordedTime}`)}</span>
            </div>
            <div className={style.modalTag}>
                <p>Subject:</p>
                <span>{data.subject}</span>
            </div>
            <div className={style.modalTag}>
                <p>Note:</p>
                <span>{data.note}</span>
            </div>
        </div>
    );
}
