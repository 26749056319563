import styles from './CopyrightFooter.css';

const CopyrightFooter = () => {
    return (
        <footer className={styles.footer}>
            {/*<p id={styles.text}>Copyright © 2023 TBD Tech</p>*/}
            {/*<div className={styles.links}>*/}
            {/*    <p style={{ margin: 0, padding: 10 }}>Privacy Policy </p>*/}
            {/*    <p style={{ margin: 0, padding: 10 }}>Terms of Use</p>*/}
            {/*</div>*/}
        </footer>
    );
}

export default CopyrightFooter;
