import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// components
import { Dropdown, Modal, Popover, Switch, Tag } from 'antd';
import { RiCloseFill } from 'react-icons/ri';
import Button from '../../components/button/Button';
import FeatureCard from '../../components/card/FeatureCard';
import { CalendarOutlined, DollarOutlined, MoreOutlined, SisternodeOutlined } from '@ant-design/icons';
import ExpandableRowTable from '../../components/table/ExpandableRowTable';
import EditableCellsTable from '../../components/table/EditableCellsTable';
import TeamAvatar from '../../components/avatar/TeamAvatar';
import InfoCard from '../../components/card/InfoCard';
import CommunicationCard from '../../components/card/CommunicationCard';
import HeaderSecondary from '../../components/header/HeaderSecondary';
import IProject from '../../interfaces/IProject';
import AddContact from '../../components/modals/AddContact';
import EditClient from './EditClient';
import EditProjectStatus from '../../components/modals/EditProjectStatus';

// utils
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import ProjectService from '../../services/ProjectService';
import ClientService from '../../services/ClientService';
import FormatUtils from '../../utils/FormatUtils';

// redux
import { ECommunicationType } from '../../redux/reducers/CommunicationReducer';
import { IStore } from '../../redux/store';

// styles
import styles from './ClientPage.scss';
import PermissionUtils from '../../utils/PermissionUtils';
import NavigationUtils from '../../utils/NavigationUtils';

const ClientPage = () => {
    const [showModal, setModal] = useState(false);
    const [selectedClientId, setSelectedClientId] = useState('');
    const [addContact, showAddContact] = useState(false);
    const [showProjectStatusEditor, setShowProjectStatusEditor] = useState(false);

    const firmId = useSelector((state: IStore) => state.firm?.uuid);
    const projects = useSelector((state: IStore) => state.project.projectsByClient[selectedClientId] || {});
    const client = useSelector((state: IStore) => state.client.clients[selectedClientId] || {});
    const phasesArray = useSelector((state: IStore) => state.phases.phasesArrayForProject || {});

    const { pathname } = useLocation();

    useEffect(() => {
        const pathSplit = pathname.split('/');
        const selectedId = pathSplit[pathSplit.indexOf('clients') + 1];
        if (selectedId) {
            setSelectedClientId(selectedId);
            fetchProjectsForClient(selectedId);
        }
    }, [pathname]);

    const fetchProjectsForClient = async (clientId: string) => {
        const { error, data } = await ProjectService.fetchProjectsForClient(clientId);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('An error Occurred', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }
    };

    const toggleClientStatus = async (client) => {
        const { data, error } = await ClientService.updateClientStatus(client.uuid, client.status === 'active' ? 'inactive' : 'active');

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Status Update Failed', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Status Updated Successfully', <notificationIcon.icon style={notificationIcon.style} />);
    };

    const openProjectReport = () => {
        // todo
    };

    const editProjectStatus = async (projectUuid, status) => {
        const { data, error } = await ProjectService.updateProjectStatus({
            uuid: projectUuid,
            status,
            clientId: selectedClientId
        });
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Update', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }
    };

    const renderEditProjectStatus = (project) => {
        return (
            <EditProjectStatus
                project={project}
                onClick={(status) => {
                    editProjectStatus(project?.uuid, status);
                }}
            />
        );
    };

    const getMenuItems = (project) => [
        // todo complete with reports module
        // {
        //     key: 0,
        //     label: <span onClick={openProjectReport}>Project Accounting Report</span>
        // },
        {
            key: 1,
            label: (
                <Popover
                    content={renderEditProjectStatus(project)}
                    title="Edit Status"
                    trigger="click"
                >
                    Edit Project Status
                </Popover>
            )
        }
    ];


    const goToProject = (id) => {
        NavigationUtils.navigateTo(`/${firmId}/projects/${id}`);
    }

    const columns = [
        {
            title: 'Project Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, data) => <div onClick={() => goToProject(data.uuid)}>{GenUtils.capitalizeInitials(text)}</div>
        },
        {
            title: 'Project Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => <Tag color={GenUtils.getTagColorByStatus(text)}>{GenUtils.capitalizeInitials(text) || '-'}</Tag>
        },
        ...(PermissionUtils.canViewFinancesInProjects()
            ? [
                {
                    title: 'Project fees',
                    dataIndex: 'projectFee',
                    key: 'projectFee',
                    render: (text) => FormatUtils.getAmountInFormat(text, true)
                },
                {
                    title: 'Collected Amount',
                    dataIndex: 'collectedAmount',
                    key: 'collectedAmount',
                    render: (text) => FormatUtils.getAmountInFormat(text, true)
                },
                {
                    title: 'Pending Amount',
                    dataIndex: 'pendingAmount',
                    key: 'pendingAmount',
                    render: (text, data) => FormatUtils.getAmountInFormat(data.projectFee, true)
                }
            ]
            : []),
        {
            title: 'Assigned Team',
            dataIndex: 'team',
            key: 'team',
            render: (text, data) => data.teamMembers ? <TeamAvatar image={data.teamMembers[0].profilePicUrl} members={data.teamMembers} /> : '-'
        },
        {
            title: '',
            dataIndex: 'menu',
            key: 'menu',
            render: (text, project) => (
                <Dropdown menu={{ items: getMenuItems(project) }} trigger={['click']}>
                    <MoreOutlined />
                </Dropdown>
            )
        }
    ];

    const phaseColumns = [
        {
            title: 'Phase',
            dataIndex: 'name',
            render: (_, phase) => {
                return (
                    <>
                        <Tag color={phase.color}>{phase?.type?.abbreviation}</Tag>&nbsp;&nbsp;{phase.type?.name}
                    </>
                );
            }
        },
        {
            title: 'Phase Fees',
            dataIndex: 'amount',
            render: (_) => FormatUtils.getAmountInFormat(_, true, 2, '0')
        },
        {
            title: 'Collected Amount',
            dataIndex: 'collectedAmount',
            render: (_) => FormatUtils.getAmountInFormat(_, true, 2, '0')
        },
        {
            title: 'Collected %',
            dataIndex: 'collectedPercent',
            render: (_, phase) => `${FormatUtils.getAmountInFormat((phase.collectedAmount / phase.amount) * 100, true, 2, '0')}%`
        },
        {
            title: 'Pending Amount',
            dataIndex: 'pendingAmount',
            render: (_, phase) => `${FormatUtils.getAmountInFormat(phase.amount - phase.collectedAmount, true, 2, '0')}%`
        }
    ];

    const renderPhases = ({ uuid, color }) => {
        const phases = phasesArray[uuid] || [];

        if (!phases?.length) {
            return null;
        }
        return (
            <EditableCellsTable
                columns={phaseColumns}
                dataSource={phases.map((p) => {
                    return { ...p, color };
                })}
            />
        );
    };

    return (
        <div>
            <Modal
                title="Edit Client"
                width={800}
                centered
                visible={showModal}
                onCancel={() => setModal(false)}
                footer={null}
                closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}>
                <EditClient
                    client={client}
                    hideModal={() => setModal(false)}
                    onSuccess={() => {
                        setModal(false);
                    }}
                />
            </Modal>
            <Modal
                title={`Edit Contacts for ${GenUtils.capitalizeInitials(client.name)}`}
                width={800}
                centered
                open={addContact}
                onCancel={() => showAddContact(false)}
                footer={null}
                closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}>
                <AddContact clientId={client.uuid} onHide={() => showAddContact(false)} />
            </Modal>
            <div className={styles.headerContainer}>
                <HeaderSecondary
                    text={(client && client.name) || 'Client Details'}
                    renderSubAction={() => (
                        <Switch
                            checkedChildren="Active"
                            unCheckedChildren="Inactive"
                            checked={client.status?.toLowerCase() === 'active'}
                            className="hp-mb-16"
                            loading={false}
                            style={{ margin: '16px 20px' }}
                            onChange={() => toggleClientStatus(client)}
                        />
                    )}
                />
            </div>
            <div className={styles.cardContainer}>
                <FeatureCard
                    title={FormatUtils.getAmountInFormat(Object.keys(projects).length, false, 0, 0)}
                    subtitle="Total Projects"
                    Icon={SisternodeOutlined}
                />
                <FeatureCard
                    title={FormatUtils.getDaysDiffText(client.lastProjectEndAt)}
                    subtitle={
                        <div>
                            <Tag color={'yellow'}>{client.project?.serial}</Tag>
                            {GenUtils.capitalizeFirstLetter(client.project?.name)}
                        </div>
                    }
                    Icon={CalendarOutlined}
                />
                {PermissionUtils.canViewFinancesInProjects() ? (
                    <>
                        <FeatureCard
                            title={FormatUtils.getAmountInFormat(client.totalPendingAmount, true, 0, 0)}
                            subtitle="Total pending amount"
                            Icon={DollarOutlined}
                        />
                        <FeatureCard
                            title={FormatUtils.getAmountInFormat(client.totalRetentionAmount, true, 0, 0)}
                            subtitle="Retainer Balance"
                            Icon={DollarOutlined}
                        />
                    </>
                ) : null}
            </div>
            <h5>Projects</h5>
            <ExpandableRowTable
                columns={columns}
                data={projects && Object.values(projects).map((project: IProject) => {
                    return { ...project, key: project.uuid };
                })}
                renderExpandedRow={renderPhases}
                expandableKey="phases"
            />
            <div className={styles.bottomCardContainer}>
                <div className={styles.communicationCardContainer}>
                    <CommunicationCard type={ECommunicationType.client} typeId={client.uuid} />
                </div>
                <div className={styles.infoCardContainer}>
                    <InfoCard
                        title="Client info"
                        data={{
                            'Company Name': client.name,
                            Address: FormatUtils.getAddressString({
                                ...client
                            }),
                            'GST Number': client.taxRegId,
                            'Referral Source': client.referralSource,
                            'Preferred Currency': client.currency,
                            'Client Since': FormatUtils.getFormattedDateFromTimestamp(client.createdAt)
                        }}
                        action={<Button onClick={() => setModal(true)} text="Edit" />}
                    />
                </div>
                <div className={styles.infoCardContainer}>
                    <InfoCard
                        title='Contact Details'
                        data={client.contacts?.reduce((acc, contact) => {
                            acc[contact.name] =
                                <>
                                    {contact.designation || ''}
                                    {contact.designation && (contact.mobile || contact.email) && <br />}
                                    {contact.mobile || ''}
                                    {contact.mobile && contact.email && <br />}
                                    {contact.email?.toLowerCase() || ''}
                                </>;
                            return acc;
                        }, {}) || { 'Start Adding Contacts': '' }
                        }
                        alignVertically
                        action={<Button onClick={() => {
                            showAddContact(true);
                        }} text='Edit Contact' />}
                    />
                </div>
            </div>
        </div>
    );
};

export default ClientPage;
