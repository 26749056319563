import { dummyDataProjectData, dummyTimesheetData } from '../../services/dummyData';

export const updateTimesheet = (...timesheet) => {
    return {
        type: 'UPDATE_TIMESHEETS',
        payload: [
            {
                ...timesheet
            }
        ]
    };
};

export const setTimesheet = (timesheets) => {
    return {
        type: 'SET_TIMESHEETS',
        payload: {
            timesheets
        }
    };
};

export const setTimeAllocation = (data) => {
    let payload = {
        phase: data.phasesObj,
        project: data.projectsObj,
        phaseMember: data.phasesMembersObj,
        timesheet: data.timesheetsObj,
        teamMember: data.teamMembersObj
    };
    return {
        type: 'SET_ALLOCATION',
        payload
    };
};

export const setTimesheetLog = (data) => {
    return {
        type: 'SET_TIMESHEET',
        payload: data
    };
};

export const setTimesheetEntries = (data) => {
    let payload = {
        phase: data.phasesObj,
        phaseMember: data.phasesMembersObj,
        timesheet: data.timesheets,
        activities: data.activitiesObj,
        stats: data.stats
    };
    return {
        type: 'SET_TIMESHEET_ENTRIES',
        payload
    };
};
export const setTimesheetSubmissions = (data) => {
    return {
        type: 'SET_TIMESHEET_SUBMISSIONS',
        payload: data
    };
};

export const setTimesheetAudits = (data) => {
    let payload = {
        phase: data.phasesObj,
        project: data.projectsObj,
        phaseMember: data.phasesMembersObj,
        timesheet: data.timesheetsObj,
        activities: data.activitiesObj
    };
    return {
        type: 'SET_TIMESHEET_AUDITS',
        payload
    };
};

export const setProjects = (data) => {
    return {
        type: 'SET_PROJECTS_DATA',
        payload: data
    };
};
