import moment from 'moment';

export const getTimeRowsForDate = (timeLogs, date, projectsObj, phasesObj, phaseActivitiesObj) => {
    let newTimeRows = [];
    const { timesheet: userTimesheet } = timeLogs;
    const { projects } = userTimesheet;
    const projectKeys = Object.keys(projects ?? {});

    projectKeys?.forEach((projectKey) => {
        let phases = projects[projectKey];
        let phaseKeys = Object.keys(phases);
        phaseKeys?.forEach((phaseKey) => {
            let activities = phases[phaseKey];
            let activityKeys = Object.keys(activities);
            activityKeys?.forEach((activityKey) => {
                let day = activities[activityKey];
                let dayKeys = Object.keys(day);
                dayKeys?.forEach((dayKey) => {
                    let timeLog = day[dayKey];
                    timeLog?.forEach((log) => {
                        let logData = {
                            project: projectsObj[projectKey],
                            phase: phasesObj[phaseKey],
                            activity: phaseActivitiesObj[phaseKey]?.find((activity) => activity.uuid === activityKey),
                            day: dayKey,
                            note: log.note,
                            ...log
                        };
                        if (moment(date).format('MM/DD/YYYY') === dayKey) newTimeRows.push(logData);
                    });
                });
            });
        });
    });
    return newTimeRows;
};