import React from 'react';
import { Footer } from 'antd/es/layout/layout';

const FooterSmall = () => {
    return (
        <Footer style={{ textAlign: 'left' }}></Footer>
    )
};

export default FooterSmall;
