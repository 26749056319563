import { useEffect, useState } from 'react';
import { Card, Col, Divider, Popover, Row, Select, Tag } from 'antd';

import GenUtils from '../../utils/GenUtils';

import TeamAvatar from '../../components/avatar/TeamAvatar';
import Button from '../../components/button/Button';

import styles from './AuditTimeSheetProjectItem.scss';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import TimeSheetService, { TAudits } from '../../services/TimeSheetService';
import AuditTimesheetRejectPopOver from './AuditTimesheetRejectPopOver';
import classNames from 'classnames';
import MembersService from '../../services/MembersService';
import MomentUtils from '../../utils/MomentUtils';
import { useAuditItems } from '../../hooks/timesheet/AuditTimeSheet';

type TRows = ReturnType<typeof useAuditItems>['items'][number]['data'];

const AuditTimeSheetProjectItem = ({
    color,
    serial,
    name,
    weekCount,
    weekStart = new Date(),
    fetchAudits = () => {},
    roles,
    rows
}: {
    color: string;
    serial: number;
    name: string;
    weekCount: 7;
    weekStart: Date;
    fetchAudits: () => void;
    roles: any;
    rows: TRows;
}) => {
    const [isExpanded, setExpanded] = useState(true);
    const [showPop, setShowPop] = useState(null);

    const renderRow = (row: TRows[number] & { key: string }) => {
        return (
            <div className={classNames(styles.body, row.status === 'rejected' ? styles.rejectCell : '')} key={row.key}>
                <Row style={{ alignItems: 'center' }}>
                    <Col span={3}>
                    {GenUtils.capitalizeFirstLetter(row.member)}
                    </Col>
                    <Col span={2}>{GenUtils.capitalizeFirstLetter(row.phase)}</Col>
                    <Col span={3}>
                        {row.role ? (
                            GenUtils.capitalizeFirstLetter(row.role)
                        ) : (
                            <Select
                                placeholder={'Select Role'}
                                onChange={async (e) => {
                                    const { data, error } = await MembersService.updateMember({ roleId: e }, row.phaseMemberId, row.phaseId);
                                    if (!error) fetchAudits();
                                }}>
                                {roles &&
                                    roles.map((role) => (
                                        <Select.Option key={role.uuid} value={role.uuid}>
                                            {GenUtils.capitalizeInitials(role.name)}
                                        </Select.Option>
                                    ))}
                            </Select>
                        )}
                    </Col>
                    <Col span={2}>{GenUtils.capitalizeFirstLetter(row.activity)}</Col>
                    <Col span={10}>
                        <Row justify="space-between">
                            {[...Array(weekCount)].map((e, i) => (
                                <Col className={styles.weekDays} span={Math.floor(18 / weekCount)} key={i}>
                                    {row[i][`day${i + 1}`]}
                                </Col>
                            ))}
                        </Row>
                    </Col>
                    <Col
                        span={4}
                        style={{
                            display: 'flex',
                            gap: 10,
                            alignItems: 'center',
                            fontWeight: 'bold',
                            justifyContent: 'space-between'
                        }}>
                        {row.total}
                        <div style={{ display: 'flex', gap: 10 }}>
                            <Button
                                text={'Approve'}
                                disable={row.status === 'approved' || !row.roleId}
                                small
                                onClick={async () => {
                                    const { data, error } = await TimeSheetService.updateMemberTimesheet({
                                        teamMemberId: row.teamMemberId,
                                        status: 'approved',
                                        date: MomentUtils.getGmtDayStart(weekStart),
                                        phaseMemberId: row.phaseMemberId,
                                        activityId: row.activityId
                                    });
                                    if (!error) fetchAudits();
                                }}
                            />
                            <Popover
                                trigger={['click']}
                                style={{ width: 200 }}
                                open={showPop === row.key}
                                content={
                                    <AuditTimesheetRejectPopOver
                                        member={row.member}
                                        weekStart={weekStart}
                                        teamMemberId={row.teamMemberId}
                                        status={'rejected'}
                                        date={weekStart}
                                        phaseMemberId={row.phaseMemberId}
                                        activityId={row.activityId}
                                        fetchAudits={fetchAudits}
                                        onSuccess={() => setShowPop(false)}
                                    />
                                }>
                                <Button disable={row.status === 'rejected'} text={'reject'} small danger onClick={() => setShowPop(row.key)} />
                            </Popover>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };

    return (
        <Card className={styles.projectTimesheetContainer}>
            <div
                className={styles.header}
                onClick={() => {
                    setExpanded(!isExpanded);
                }}>
                {isExpanded ? <CaretUpOutlined /> : <CaretDownOutlined />}
                <Tag color={color}>{serial}</Tag>
                {GenUtils.capitalizeFirstLetter(name)}{' '}
            </div>
            <Divider className={styles.marginPadding0} />
            {isExpanded && rows.map((row) => renderRow({ ...row, key: row.phaseMemberId + row.activityId + row.roleId }))}
        </Card>
    );
};

export default AuditTimeSheetProjectItem;
