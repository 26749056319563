import GenUtils from '../../utils/GenUtils';
import IClient from '../../interfaces/IClient';

export const updateClient = (...clientDetails) => {
    return {
        type: 'UPDATE_CLIENT',
        payload: [{
            ...clientDetails,
        }],
    }
}

export const setClients = (clients: IClient[]) => {
    return {
        type: 'SET_CLIENTS',
        payload: GenUtils.arrayToIdMap(clients),
    }
}