import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import * as docx from 'docx';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import ConsultantService from '../../services/ConsultantService';
import Button from '../button/Button';
import { DownloadOutlined } from '@ant-design/icons';

interface ProjectData {
    projectName: string;
    roles: { name: string; hourRate: String }[];
    phases: { phase: string; feeType: string; percentageProjectFee: string; phaseFee: string }[];
    consultantFee: string;
    totalProjectFee: string;
    consult: any;
}

const generateDoc = (
    projectName: string,
    roles: { name: string; hourRate: string }[],
    phases: { phase: string; feeType: string; percentageProjectFee: string; phaseFee: string }[],
    consultantFee: string,
    totalProjectFee: string,
    consult: any
) => {
    // Create a new Document
    const { Document, Packer, Paragraph, Table, TableCell, TableRow, HeadingLevel } = docx;

    const doc = new Document({
        sections: [
            {
                children: [
                    new Paragraph({
                        text: projectName,
                        heading: HeadingLevel.HEADING_1,
                        spacing: {
                            before: 200,
                            after: 200
                        }
                    }),
                    new Paragraph({
                        text: `Consultant Fee: ${consultantFee}`,
                        heading: HeadingLevel.HEADING_3,
                        spacing: {
                            before: 200,
                            after: 200
                        }
                    }),
                    new Paragraph({
                        text: `Total Project Fee: ${totalProjectFee}`,
                        heading: HeadingLevel.HEADING_3,
                        spacing: {
                            before: 200,
                            after: 200
                        }
                    }),

                    new Paragraph({
                        text: 'Project Roles',
                        heading: HeadingLevel.HEADING_1,
                        spacing: {
                            before: 200,
                            after: 200
                        }
                    }),
                    new Table({
                        rows: [
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [new Paragraph('Role')]
                                    }),
                                    new TableCell({
                                        children: [new Paragraph('Billable Rate')]
                                    })
                                ]
                            }),
                            ...roles.map(
                                (role) =>
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [new Paragraph(role.name)]
                                            }),
                                            new TableCell({
                                                children: [new Paragraph(role.hourRate)]
                                            })
                                        ]
                                    })
                            )
                        ]
                    }),
                    new Paragraph({
                        text: 'Fee Structure',
                        heading: HeadingLevel.HEADING_1,
                        spacing: {
                            before: 200,
                            after: 200
                        }
                    }),
                    new Table({
                        rows: [
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [new Paragraph('Phase')]
                                    }),
                                    new TableCell({
                                        children: [new Paragraph('Fee Type')]
                                    }),
                                    new TableCell({
                                        children: [new Paragraph('% of Project Fee')]
                                    }),
                                    new TableCell({
                                        children: [new Paragraph('Phase Fee')]
                                    })
                                ]
                            }),
                            ...phases.map(
                                (phase) =>
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [new Paragraph(phase.phase)]
                                            }),
                                            new TableCell({
                                                children: [new Paragraph(phase.feeType)]
                                            }),
                                            new TableCell({
                                                children: [new Paragraph(phase.percentageProjectFee)]
                                            }),
                                            new TableCell({
                                                children: [new Paragraph(phase.phaseFee)]
                                            })
                                        ]
                                    })
                            )
                        ]
                    }),
                    new Paragraph({
                        text: 'Consultant Fee',
                        heading: HeadingLevel.HEADING_1,
                        spacing: {
                            before: 200,
                            after: 200
                        }
                    }),
                    new Table({
                        rows: [
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [new Paragraph('Consultant')]
                                    }),
                                    new TableCell({
                                        children: [new Paragraph('Consultant Fee')]
                                    })
                                ]
                            }),
                            ...consult.map(
                                (con) =>
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [new Paragraph(con.type.name)]
                                            }),
                                            new TableCell({
                                                children: [new Paragraph(`${con.fee || con.phases?.reduce((acc, curr) => acc + curr.fee, 0)}`)]
                                            })
                                        ]
                                    })
                            )
                        ]
                    })
                ]
            }
        ]
    });
    // Generate the document
    Packer.toBlob(doc).then((blob) => {
        // Save the document using FileSaver.js
        saveAs(blob, `${projectName}-Proposal`);
    });
};

interface dockProps {
    data: any;
}

const DocMaker: React.FC<dockProps> = (props) => {
    const [consultants, setConsultants] = useState([]);
    const projectConsultants = useSelector((state: IStore) => props.data.uuid && state.consultants.consultantsByProject[props.data.uuid]);

    useEffect(() => {
        ConsultantService.fetchConsultantForProject(props.data?.uuid);
    }, [props.data?.uuid]);

    useEffect(() => {
        if (projectConsultants !== consultants) {
            projectConsultants && setConsultants(projectConsultants);
        }
    }, [projectConsultants]);
    console.log(Object.values(consultants));
    // const projectData: ProjectData = {
    //     projectName: 'My Project',
    //     roles: [
    //         { name: 'Developer', hourRate: '$50/hour' },
    //         { name: 'Designer', hourRate: '$60/hour' },
    //         { name: 'Project Manager', hourRate: '$70/hour' }
    //     ],
    //     phases: [
    //         { phase: 'Construction Design', feeType: 'Fixed', phaseFee: '$3999' },
    //         { phase: 'Architecture Design', feeType: 'Hourly', phaseFee: '$30' },
    //         { phase: 'Software Design', feeType: 'Hourly', phaseFee: '$40' }
    //     ]
    // };
    // consultant.phases?.reduce((acc,curr)=>acc+curr.fee,0)
    const roles = props.data.roles.map(({ name, hourRate }) => ({ name, hourRate: `$${hourRate}/hour` }));
    const phases = props.data.phases
        .sort((a, b) => a.startAt - b.startAt)
        .map((p) => ({
            phase: p.type.name,
            feeType: p.feeType,
            phaseFee: `$${p.amount}`,
            percentageProjectFee: `${Math.floor((p.amount / props.data.projectFee) * 100)}%`
        }));
    const projectName = props.data.name;
    const consultantFee = props.data.consultantFee;
    const totalProjectFee = props.data.projectFee;
    const consult = Object.values(consultants);

    return (
        <Button
        text={'Proposal'}
        type='ghost'
        icon={<DownloadOutlined style={{ marginRight: 10 }}/>}
        onClick={() => generateDoc(projectName, roles, phases, consultantFee, totalProjectFee, consult)}
        />
    );
};

export default DocMaker;
