import { Alert, Modal, Popconfirm } from 'antd';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUserDetails } from '../../redux/selectors/UserSelector';
import { IStore } from '../../redux/store';
import MomentUtils from '../../utils/MomentUtils';
import ProjectWeekTimeSheet from './ProjectWeekTimesheet';
import { IMember } from '../../redux/reducers/TeamReducer';
import WeekIterator from '../../components/time/WeekIterator';
import { useSearchParams } from 'react-router-dom';
import { RiCloseFill } from 'react-icons/ri';
import ApplyPTO from './ApplyPTO';
import ProjectDayTimesheet from './projectDayTimesheet/ProjectDayTimesheet';
import TimeSheetService from '../../services/TimeSheetService';
import Button from '../../components/button/Button';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import { PlusCircleOutlined } from '@ant-design/icons';
import styles from "./TimeEntryTab.scss"
import React from 'react';
import { teamMemberId$ } from './shared/TimesheetTab';
import { useObservable } from '../../hooks/Observable';

const TimeSheetTab = () => {
    const [weekRange, setWeekRange] = useState(MomentUtils.getWeekDates(new Date()));
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [calendarType, setCalendarType] = useState('week');
    const [selectedUser, setSelectedUser] = useState({} as IMember);
    const [showAddTimeOff, setShowAddTimeOff] = useState(false);
    const user = useSelector((state: IStore) => getUserDetails(state));
    const teamMembers = useSelector((state: IStore) => state.team.membersArray);
    const [lockSubmit, setLockSubmit] = useState(false);
    const [timesheetStatus, setTimesheetStatus] = useState('approved');
    const [showSubmissionPopup, setShowSubmissionPopup] = useState(false);
    useObservable(teamMemberId$)

    const [rejectionNotes, setRejectionNotes] = useState<string[]>([]);
    const [searchParams] = useSearchParams();
    const projectsArray = useSelector((state: IStore) => state.project.projectsArray);

    const userTimesheet = useSelector((state: IStore) => state.timeLogs.timesheet);
    const weekCount = 7;
    React.useMemo(() => {
        const rejNotes = [] as string[]
        setLockSubmit(userTimesheet.isLocked)
        setTimesheetStatus(userTimesheet.status)
        Object.keys(userTimesheet.projects).forEach(k => {
            const phases = userTimesheet.projects[k]
            const project = projectsArray.find(p => p.uuid === k)
            Object.keys(phases).forEach(pk => {
                const activities = phases[pk]
                Object.keys(activities).forEach(ak => {
                    const dates = activities[ak]
                    Object.keys(dates).forEach(dk => {
                        dates[dk].forEach(s => {
                            if (s.rejectionNote !== null) rejNotes.push(`[${project.name}] ${dk}: ${s.rejectionNote}`)
                        })
                    })
                })
            })
        })
        setRejectionNotes(rejNotes)
    }, [userTimesheet])

    useEffect(() => {
        if (teamMembers.length) {
            let currentUser =
                teamMembers[
                    teamMembers.findIndex((member) =>
                        searchParams.get('teamMemberId') ? member.uuid === searchParams.get('teamMemberId') : member.email === user.email
                    )
                ];
            setSelectedUser(currentUser);
            teamMemberId$.next(currentUser.uuid)
        }

        if (searchParams.get('weeksRange')) {
            let wr = JSON.parse(searchParams.get('weeksRange'));
            wr[0] = new Date(wr[0]);
            wr[1] = new Date(wr[1]);
            setWeekRange(wr);
        }
    }, [teamMembers, searchParams, selectedUser, user.email]);

    const fetchTimeSheetData = () => {
        setSelectedUser({ ...selectedUser });
    };

    const handleSubmit = async () => {
        if (lockSubmit) {
            return;
        }
        if (timesheetStatus === 'pending' || timesheetStatus === 'rejected' || timesheetStatus === undefined) {
            const { error } = await TimeSheetService.submitTimesheet({
                date: MomentUtils.getGmtDayStart(weekRange[0]),
                teamMemberId: selectedUser.uuid
            });

            if (error) {
                const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
                GenUtils.showNotification('Failed to Submit', error, <notificationIcon.icon style={notificationIcon.style} />);
                return;
            }
            fetchTimeSheetData();
        } else if (timesheetStatus === 'submitted') {
            // todo withdraw => set status to pending
        }
    };

    const RenderSubmitBtn = (
        <>
            <Button text={'Submit week'} onClick={() => setShowSubmissionPopup(true)} disable={lockSubmit} />
            {showSubmissionPopup && (
                <Popconfirm
                    title={'Confirm submission? Timesheet will be locked.'}
                    open={showSubmissionPopup}
                    onCancel={() => setShowSubmissionPopup(false)}
                    okText={'Yes'}
                    onConfirm={handleSubmit}
                />
            )}
        </>
    );

    const RenderTimeOff = (
        <Button
            type="default"
            text="Time off"
            icon={<PlusCircleOutlined />}
            onClick={() => setShowAddTimeOff(true)}
            style={{ color: '#B2BEC3' }}
            className={styles.btn}
        />
    );

    return (
        <div>
            <WeekIterator
                weekRange={weekRange}
                setWeekRange={setWeekRange}
                calendarType={calendarType}
                setCalendarType={setCalendarType}
                showCalenderType={true}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                timesheetStatus={userTimesheet.status}
                prefixComp={RenderTimeOff}
                suffixComp={RenderSubmitBtn}
            />
            {rejectionNotes.length > 0 &&
                <Alert style={{ marginBottom: 10 }} message={rejectionNotes[0]} type="error" />}
            {calendarType === 'week' ? (
                <ProjectWeekTimeSheet user={selectedUser} weekCount={weekCount} weekRange={weekRange} />
            ) : (
                <ProjectDayTimesheet user={selectedUser} date={selectedDate} weekRange={weekRange} />
            )}
            <Modal
                title={`Add Time Off Allowance`}
                width={600}
                centered
                style={{ margin: '20px' }}
                open={showAddTimeOff}
                onCancel={() => setShowAddTimeOff(false)}
                footer={null}
                closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}>
                <ApplyPTO teamMember={selectedUser?.uuid} onClose={() => setShowAddTimeOff(false)} />
            </Modal>
        </div>
    );
};

export default TimeSheetTab;
