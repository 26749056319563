import { useEffect, useState } from "react";
import { getNestedData as getData } from "../../../utils/GenUtils";
import { DatePicker } from 'antd';

import { useSearchParams } from "react-router-dom";

import styles from '../FiltersBar.scss';
import FormatUtils from "../../../utils/FormatUtils";
import moment from "moment";
const { RangePicker } = DatePicker;

const DateRangeFilter = ({ filter, data, updateFilter, startAt = null, endAt = null }) => {
    const [searchParams] = useSearchParams();
    const [start, setStart] = useState(searchParams.getAll(filter.dataIndex || 'date')?.[0]);
    const [end, setEnd] = useState(searchParams.getAll(filter.dataIndex || 'date')?.[1]);

    const [min, setMin] = useState(startAt ? moment(startAt) : moment());
    const [max, setMax] = useState(endAt ? moment(endAt) : moment());

    useEffect(() => {
        filter?.dataIndex && getMinAndmax(filter.dataIndex)
    }, [data, searchParams])

    const getMinAndmax = (dataIndex) => {
        if (data?.length === 0) return;
        let _min = moment(getData(dataIndex, data[0])), _max = moment(getData(dataIndex, data[0]));
        data.forEach(d => {
            if (_max?.isBefore(getData(dataIndex, d))) _max = moment(getData(dataIndex, d));
            if (_min?.isAfter(getData(dataIndex, d))) _min = moment(getData(dataIndex, d));
        });

        setMin(_min);
        setMax(_max);
    }

    const disabledDate = (current) => {
        // Can not select days before min and after max
        return filter.dataIndex && (max.isBefore(current) || min.isAfter(current));
    };

    return (
        <RangePicker
            className={styles.dropdown}
            // disabledDate={disabledDate}
            format={FormatUtils.dateFormat.toUpperCase()}
            value={[moment(start || min), moment(end || max)]}
            onChange={(values) => {
                if (values && values.length === 2) {
                    setStart(values[0].toISOString())
                    setEnd(values[1].toISOString())
                    updateFilter(filter.dataIndex || 'date', values.map(m => m.toISOString()))
                } else {
                    setStart(min.toISOString())
                    setEnd(max.toISOString())
                    updateFilter(filter.dataIndex || 'date', [])
                }
            }}
            renderExtraFooter={() => <h5>{filter.name}</h5>}
            style={{ minWidth: filter.category === 'simple' ? '250px' : undefined, maxWidth: filter.category === 'advance' ? '200px' : undefined }}
        />
    );
}

export default DateRangeFilter;
