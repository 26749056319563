import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// redux
import { IStore } from '../../redux/store';

// Utils
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import FormatUtils from '../../utils/FormatUtils';
import NavigationUtils from '../../utils/NavigationUtils';

// Components
import Tag from '../../components/tag/Tag';
// import Table from '../../components/table/custom/Table';
import TeamAvatar from '../../components/avatar/TeamAvatar';

// styles
import styles from './Task.scss';
import { Dropdown, Popconfirm, Table } from 'antd';
import Button from '../../components/button/Button';
import TaskService from '../../services/TaskService';
import ITask from '../../interfaces/ITask';
import { MoreOutlined } from '@ant-design/icons';
import { EFilterCategory, EFilterTypes } from '../../components/filterBars/FiltersBarMultiselect';
import GroupedTable from '../../components/table/groupedTable/GroupedTable';

const ProjectTask = () => {

    const { phase } = useSelector((state: IStore) => state.options);
    const firmId = useSelector((state: IStore) => state?.firm?.uuid);
    const tasksArray = useSelector((state: IStore) => state.tasks.tasksArray || []);

    const [selectedProjectId, setSelectedProjectId] = useState('');
    
    const { pathname } = useLocation();

    useEffect(() => {
        const pathSplit = pathname.split('/');
        const selectedId = pathSplit[pathSplit.indexOf('projects') + 1];
        selectedId && setSelectedProjectId(selectedId);
    }, []);

    const task = tasksArray.filter((option)=>option.projectId===selectedProjectId)

    const columns = [
        {
            title: 'Task',
            dataIndex: 'name',
            span: 4,
            render: (name, task) => <a onClick={() => NavigationUtils.navigateTo(`/${firmId}/task/${task.uuid}`)}>{name}</a>
        },
        {
            title: 'Project',
            dataIndex: 'project',
            span: 4,
            render: (project) => <div style={{ display: 'flex', gap: 10 }}>
                <Tag text={project?.serial} color={project?.color} />
                {GenUtils.capitalizeInitials(project?.name)}
            </div>
        },
        {
            title: 'Phase',
            dataIndex: 'phase',
            span: 3,
            render: (_phase) => <div style={{ display: 'flex' }}>
                <Tag color={_phase?.project?.color} text={phase?.find(p => p.uuid === _phase?.typeId)?.abbreviation} />
            </div>
        },
        {
            title: 'Task Type',
            dataIndex: 'type',
            span: 3,
            render: (type) => type?.name || '-'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            span: 3,
            render: (status) => <div style={{ display: 'flex' }}>
                <Tag text={status} color={GenUtils.getTagColorByStatus(status)} />
            </div>
        },
        {
            title: 'Assignee',
            dataIndex: 'assignees',
            span: 3,
            render: (members) => <div>
                <TeamAvatar image={members[0]?.profilePicUrl} members={members} name={members[0]?.firstName}/>
            </div>
        },
        {
            title: 'Due Date',
            dataIndex: 'date',
            span: 2,
            render: (task) => FormatUtils.getFormattedDateFromTimestamp(task?.date)
        },
        {
            title: '',
            dataIndex: 'menu',
            span: 2,
            render: (t, task) => (
                <Dropdown menu={{ items: getMenuItems(task) }} trigger={['click']}>
                    <MoreOutlined />
                </Dropdown>
            )
        }
    ];

    const deleteTask = async (taskId) => {
        const { data, error } = await TaskService.deleteTask(taskId);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification(
                'Failed to delete',
                error,
                <notificationIcon.icon style={notificationIcon.style} />
            );
            return;
        }

        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification(
            'Task deleted successfully',
            '',
            <notificationIcon.icon style={notificationIcon.style} />
        );
    };

    const getMenuItems = (task: ITask) => {

        return [
            {
                key: 1,
                label: (
                    <Popconfirm
                        title='Are you sure to delete this task?'
                        onConfirm={() => deleteTask(task.uuid)}
                        okText='Yes'
                        cancelText='No'
                    >
                        <Button
                            danger
                            onClick={() => null}
                            text='Delete Task'
                            />
                    </Popconfirm>
                )
            }];
    };
    const filters = [
        {
            name: 'Due-Date',
            dataIndex: 'date',
            type: EFilterTypes.dateRange,
            category: EFilterCategory.simple
        },
        {
            name: 'Search Task',
            dataIndex: 'name',
            type: EFilterTypes.dropdown,
            category:EFilterCategory.simple,
        },
        {
            name: 'Project',
            dataIndex: 'projectId',
            type: EFilterTypes.dropdown,
            category:EFilterCategory.advance,
        },
        {
            name: 'Assignee',
            dataIndex: 'assignees',
            type: EFilterTypes.dropdown,
            category:EFilterCategory.advance,
        },
        {
            name: 'Task Type',
            dataIndex: 'type.name',
            type: EFilterTypes.dropdown,
            category:EFilterCategory.advance,
        },
    ]
    
    return (
        <div className={styles.container}>
                <GroupedTable columns={columns} data={task} filters={filters}/>
        </div>
    );
};
export default ProjectTask;
