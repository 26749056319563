import { Button as AntButton, Card, Col, Empty, Modal, Row, Typography, Timeline, Divider, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import FeeAllocationCard from '../../components/card/FeeAllocationCard';
import NoPhaseImage from '../../assets/images/components/nophase.svg';
import { DollarOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { RiCloseFill } from 'react-icons/ri';
import Button from '../../components/button/Button';

import styles from './ConsultantFeeTab.scss';
import FeatureCard from '../../components/card/FeatureCard';
import AddConsultant from './AddConsultant';
import Consultant from './Consultant';
import ConsultantService from '../../services/ConsultantService';
import { IStore } from '../../redux/store';
import { useSelector } from 'react-redux';
import ProjectService from '../../services/ProjectService';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import FormatUtils from '../../utils/FormatUtils';

const { Title, Text } = Typography;

const ConsultantFeeTab = ({ project }) => {
    const [consultants, setConsultants] = useState([]);
    const [showAddConsultantModal, setShowAddConsultantModal] = useState(false);
    const [showUpdateFeeDialog, viewUpdateFeeDialog] = useState(false);
    const [consultantFee, setConsultantFee] = useState(null);
    const [totalAllocatedFee, setAllocatedFee] = useState(0);
    const [totalAllocatedPayout, setAllocatedPayout] = useState(0);
    const projectConsultants = useSelector((state: IStore) => project.uuid && state.consultants.consultantsByProject[project.uuid]);

    useEffect(() => {
        ConsultantService.fetchConsultantForProject(project?.uuid);
        if (project.consultantFee && consultantFee === null) setConsultantFee(project?.consultantFee);
    }, [project?.uuid]);

    useEffect(() => {
        if (projectConsultants !== consultants) {
            projectConsultants && setConsultants(Object.values(projectConsultants));
            projectConsultants &&
                setAllocatedFee(
                    Number(
                        Object.values(projectConsultants).reduce((acc: number, current: any) => {
                            if (current.phases && current.phases.length) {
                                current.phases.forEach((phse: { fee: number }) => {
                                    acc = acc + phse.fee;
                                });
                                return acc;
                            }
                            return acc + current.fee;
                        }, 0)
                    )
                );
            projectConsultants &&
                setAllocatedPayout(
                    Number(
                        Object.values(projectConsultants).reduce((acc: number, current: any) => {
                            if (current.phases && current.phases.length) {
                                current.phases.forEach((phse: { payout: number }) => {
                                    acc = acc + phse.payout;
                                });
                                return acc;
                            }
                            return acc + current.payout;
                        }, 0)
                    )
                );
        }
    }, [projectConsultants]);

    const editProject = async (projectUuid, fieldsToSave) => {
        const { data, error } = await ProjectService.updateProject(projectUuid, {
            ...fieldsToSave
        });
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Update', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Updated Project Details', <notificationIcon.icon style={notificationIcon.style} />);
        viewUpdateFeeDialog(false);
    };

    return (
        <div>
            {projectConsultants && (
                <Row gutter={[10, 10]}>
                    <Col span={6}>
                        <FeatureCard title={`${FormatUtils.getAmountInFormat(project.consultantFee + project.projectFee, true, 0, 0)}`} subtitle="Contract Fee" Icon={DollarOutlined} />
                    </Col>
                    <Col span={18}>
                        <FeeAllocationCard
                            title="Consultant Fee"
                            fee={project.consultantFee}
                            allocatedFee={Number(totalAllocatedFee || 0)}
                            updateFee={() => viewUpdateFeeDialog(true)}
                        />
                    </Col>
                </Row>
            )}
            {!consultants.length ? (
                <Empty
                    image={NoPhaseImage}
                    description={
                        <div>
                            <h2>Project Consultants</h2>
                            <h5>Start by adding Consultants to the project</h5>
                        </div>
                    }>
                    <AntButton type="primary" icon={<PlusCircleOutlined style={{ marginRight: 10 }} />} onClick={() => setShowAddConsultantModal(true)}>
                        Add Consultant
                    </AntButton>
                </Empty>
            ) : (
                <>
                    <Row style={{ marginTop: 30 }}>
                        <Col span={6}>
                            <Title level={4}>Summary</Title>
                        </Col>
                        <Col span={14}>
                            <Title level={4}>Project Consultants</Title>
                        </Col>
                        <Col span={4}>
                            <Title level={4}>
                                <Button
                                    icon={<PlusCircleOutlined style={{ marginRight: 10 }} />}
                                    text={'Add Consultant'}
                                    type="primary"
                                    block
                                    style={{ whiteSpace: 'nowrap' }}
                                    onClick={() => setShowAddConsultantModal(true)}
                                />
                            </Title>
                        </Col>
                    </Row>
                    <Row gutter={[10, 10]}>
                        <Col span={6}>
                            <Card className="hp-border-color-black-40">
                                <Text className={styles.fontSize18} strong>
                                    Consultants({consultants.length})
                                </Text>
                                <Timeline style={{ marginTop: 30, marginBottom: -45 }}>
                                    {Boolean(consultants.length) &&
                                        consultants
                                            .sort(GenUtils.sortByCreatedAt)
                                            .map((consultant, index) => (
                                                <Timeline.Item key={consultant.userUuid + index}>{GenUtils.capitalizeInitials(consultant.type.name)}</Timeline.Item>
                                            ))}
                                </Timeline>
                                <Divider />
                                <Row style={{ fontSize: 16 }}>
                                    <Col span={12}>Consultant Fee</Col>
                                    <Col span={12} style={{ textAlign: 'right' }}>
                                        {FormatUtils.getAmountInFormat(totalAllocatedFee, true, 0, 0)}
                                    </Col>
                                </Row>
                                <Row style={{ fontSize: 16 }}>
                                    <Col span={12}>Allocated Payout</Col>
                                    <Col span={12} style={{ textAlign: 'right' }}>
                                        {FormatUtils.getAmountInFormat(totalAllocatedPayout, true, 0, 0)}
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={18}>
                            <div className={styles.phasesCol}>
                                {Boolean(consultants.length) &&
                                    consultants
                                        .sort(GenUtils.sortByCreatedAt)
                                        .map((consultant, index) => (
                                            <Consultant key={consultant.userUuid + index} consultant={consultant} project={project} />
                                        ))}
                            </div>
                        </Col>
                    </Row>
                </>
            )}
            <Modal
                title="Add Consultant"
                width={800}
                centered
                open={showAddConsultantModal}
                onCancel={() => setShowAddConsultantModal(false)}
                footer={null}
                closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}>
                <AddConsultant
                    hideModal={() => setShowAddConsultantModal(false)}
                    onSuccess={() => {
                        setShowAddConsultantModal(false);
                    }}
                    project={project}
                />
            </Modal>
            <Modal
                title="Update Consultant Fee"
                width={500}
                centered
                open={showUpdateFeeDialog}
                onCancel={() => viewUpdateFeeDialog(false)}
                footer={null}
                closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}>
                <Form
                    layout="vertical"
                    name="basic"
                    initialValues={{ remember: true }}
                    style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                    <Col span={24} className="hp-profile-content-list hp-mt-8 hp-pb-sm-0" style={{ justifyContent: 'center', padding: '20px' }}>
                        <ul style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
                            <li>
                                <Input value={consultantFee} style={{ width: 300 }} type="number" onChange={(e) => setConsultantFee(e.target.value)} />
                            </li>
                        </ul>
                    </Col>
                    <Button type="primary" onClick={() => editProject(project.uuid, { consultantFee })} text="Save" />
                </Form>
            </Modal>
        </div>
    );
};

export default ConsultantFeeTab;
