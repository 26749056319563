import IClient from '../../interfaces/IClient';

export interface IClientsState {
    clients: {},
}

export const initialClientState: IClientsState = {
    clients: {}
};

const clientReducer = (state: IClientsState, action: { type: string, payload: IClient[] }) => {
    switch (action.type) {
        case 'SET_CLIENTS':
            return {
                ...state,
                clients: action.payload,
            };
        case 'UPDATE_CLIENT':
            return {
                ...state,
                clients: {
                    ...state.clients,
                    [action.payload[0].uuid]: action.payload[0],
                }
            };
        default:
            return state || initialClientState;
    }
};

export default clientReducer;