import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { Button, Col, Drawer, Dropdown, Menu, Row } from 'antd';
import { RiCloseFill, RiMenuFill, RiMore2Line } from 'react-icons/ri';
import MenuProfile from './MenuProfile';
import ImageUploadPopup from '../../components/files/ImageUploadPopup';
import { EFileCategory } from '../../services/FileService';
import { getUserDetails } from '../../redux/selectors/UserSelector';
import { IStore } from '../../redux/store';
import { useSelector } from 'react-redux';
import TeamService from '../../services/TeamService';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';

export default function Profile() {
    const [visible, setVisible] = useState(false);
    const [uploadImage, showUploadImage] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState('');
    const user = useSelector((state: IStore) => getUserDetails(state, selectedUserId));
    const loggedInUser = useSelector((state: IStore) => getUserDetails(state));

    const location = useLocation();
    const { pathname } = location;

    useEffect(() => {
        const pathSplit = pathname.split('/');
        const selectedId = pathSplit[pathSplit.indexOf('team') + 1];
        selectedId && setSelectedUserId(selectedId);
    }, [pathname]);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    const onUpload = async (profilePicFileId) => {
        const { data, error } = await TeamService.updateUserDetails(user.memberUuid || user.uuid, { profilePicFileId });
    
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to save', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
    }

    const items = [
        {
            key: '1',
            label: (
            <div onClick={() => showUploadImage(true)}>
                Change Avatar
            </div>
            )
        }
    ];

    function moreBtn() {
        return (
            <Dropdown menu={{ items }} placement='bottomLeft'>
                <Button
                    type='text'
                    icon={<RiMore2Line className='hp-text-color-black-100 hp-text-color-dark-0' size={24} />}
                />
            </Dropdown>
        );
    }

    return (
        <Row gutter={[32, 32]} className='hp-mb-32'>
            <ImageUploadPopup 
                show={uploadImage}
                onHide={() => showUploadImage(false)} 
                category={EFileCategory.ProfilePic}
                onUpload={onUpload}
            />
            <Drawer
                title={moreBtn()}
                className='hp-profile-mobile-menu'
                placement='left'
                closable={true}
                onClose={onClose}
                open={visible}
                closeIcon={
                    <RiCloseFill
                        className='remix-icon hp-text-color-black-80'
                        size={24}
                    />
                }
            >
                <MenuProfile
                    onCloseDrawer={onClose}
                    moreBtnCheck='none'
                    footer='none'
                />
            </Drawer>

            {/*<Col span={24}>*/}
            {/*    <Row gutter={[32, 32]} justify='space-between'>*/}
            {/*        /!*<Breadcrumbs breadCrumbParent='Pages' breadCrumbActive='Profile' />*!/*/}

            {/*        <ActionButton />*/}
            {/*    </Row>*/}
            {/*</Col>*/}

            <Col span={24}>
                <Row
                    className='hp-profile-mobile-menu-btn hp-bg-color-black-0 hp-bg-color-dark-100 hp-border-radius hp-py-12 hp-px-sm-8 hp-px-24 hp-mb-16'>
                    <Button
                        className='hp-p-0'
                        type='text'
                        icon={
                            <RiMenuFill
                                size={24}
                                className='remix-icon hp-text-color-black-80 hp-text-color-dark-30'
                            />
                        }
                        onClick={showDrawer}
                    />
                </Row>

                <Row className='hp-bg-color-black-0 hp-bg-color-dark-100 hp-border-radius hp-pr-sm-16 hp-pr-32'>
                    <MenuProfile moreBtn={moreBtn} />

                    <Col
                        flex='1 1'
                        className='hp-pl-sm-16 hp-pl-32 hp-py-sm-24 hp-py-32 hp-pb-24 hp-overflow-hidden'
                    >
                        <div style={{ padding: 30 }}>
                            <Outlet />
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
