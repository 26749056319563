import React from 'react';

import { Card, Col, Row } from 'antd';

const FeatureCard = ({ title, subtitle, subtext = '', Icon = null }) => {
    return (
        <Card className='hp-border-color-black-40' style={{height:'100%',display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
            <Row style={{alignItems:"center",gap:"16px",justifyContent:"center"}}>
                {
                    Icon
                    && <Col style={{ flex: 0 }}
                            className='hp-statistic-icon-bg hp-mr-16 hp-mb-xs-16 hp-bg-color-primary-4 hp-bg-color-dark-primary'>
                        <Icon className='hp-text-color-primary-1 remix-icon' style={{ fontSize: 25 }} />
                    </Col>
                }

                <Col className='hp-mt-8'>
                    <h3 className='hp-mb-4' style={{fontSize:"24px",fontWeight:"400"}}>
                        {title}
                        {
                            subtext
                            && <span className='hp-badge-text hp-ml-8 hp-text-color-warning-1'>{subtext}</span>
                        }
                    </h3>
                    <p className='hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30' style={{fontSize:"14px",color:"#636E72",fontWeight:"400"}}>
                        {subtitle}
                    </p>
                </Col>
            </Row>
        </Card>
    );
};

export default FeatureCard;