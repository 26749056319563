import { Typography } from 'antd';
import React from 'react';
import FormatUtils from '../../utils/FormatUtils';

const HourPopOver = ({ logged = 30, estimated = 20 }) => {
    const isOverDue = estimated < logged;
    return (
        <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', color: isOverDue ? 'red' : 'black' }}>
                <Typography.Title style={{ margin: 0, color: isOverDue ? 'red' : 'black', lineHeight: 1, paddingRight: 5 }}>
                    {isOverDue
                        ? `(${FormatUtils.getAmountInFormat(Math.abs(estimated - logged), false, 2, 0)})`
                        : `${FormatUtils.getAmountInFormat(estimated - logged, false, 2, 0)}`}
                </Typography.Title>
                <Typography.Text style={{ color: isOverDue ? 'red' : 'black', paddingRight: 5 }}>
                    Hours
                    <br />
                    {isOverDue ? <Typography.Text style={{ color: isOverDue ? 'red' : 'black' }}>Over</Typography.Text> : 'to Work'}
                </Typography.Text>
            </div>
            <div>
                <Typography.Title style={{ margin: 0, lineHeight: 1, paddingRight: 5 }}>=</Typography.Title>
            </div>
            <div style={{ display: 'flex' }}>
                <Typography.Title style={{ margin: 0, lineHeight: 1, paddingRight: 5 }}>
                    {FormatUtils.getAmountInFormat(estimated, false, 2, 0)}
                </Typography.Title>
                <Typography.Text style={{ paddingRight: 5 }}>
                    Hours
                    <br />
                    Allocated
                </Typography.Text>
            </div>
            <div>
                <Typography.Title style={{ margin: 0, lineHeight: 1, paddingRight: 5 }}>-</Typography.Title>
            </div>
            <div style={{ display: 'flex' }}>
                <Typography.Title style={{ margin: 0, lineHeight: 1, paddingRight: 5 }}>{FormatUtils.getAmountInFormat(logged, false, 2, 0)}</Typography.Title>
                <Typography.Text>
                    Hours
                    <br />
                    Logged
                </Typography.Text>
            </div>
        </div>
    );
};

export default HourPopOver;
