import { Alert, Form, Select, Tag } from 'antd';
import { useEffect, useState } from 'react';
import classnames from 'classnames';
import Button from '../../components/button/Button';

import styles from './AddPhaseRow.scss';
import { IStore } from '../../redux/store';
import { useSelector } from 'react-redux';
import GenUtils from '../../utils/GenUtils';
import React from 'react';

const { Option } = Select;

const AddPhaseRow = ({ hideModal, onSuccess, hideRole = false, teamMemberId = '', includeActivity = false }) => {
    const [selectedPhase, setSelectedPhase] = useState(null);
    const [selectedProject, setSelectedProject] = useState<string>(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);
    const [selectedActivity, setSelectedActivity] = useState(null);

    const [memberInvolvement, setMemberInvolvement] = useState({
        projects: [],
        phases: [],
        roles: []
    });
    const projects = useSelector((state: IStore) => state.project.projectsArray);
    const projectsMap = useSelector((state: IStore) => state.project.projects);
    const projectPhases = useSelector((state: IStore) => (selectedProject ? state.phases.phasesArrayForProject[selectedProject] : []));
    const members = useSelector((state: IStore) => state.members.members);
    const activities = useSelector((state: IStore) => state.options.activity);

    const isFormValid = () => {
        return Boolean(selectedPhase && selectedProject && (!getRoles().length || selectedRole) && (!includeActivity || selectedActivity));
    };

    const resetData = () => {
        setSelectedPhase(null);
        setSelectedProject(null);
        setSelectedRole(null);
        setSelectedActivity(null);
        setErrorMessage(null);
    };

    useEffect(() => {
        if (members) {
            let projects = [];
            let phases = [];
            let roles = [];
            Object.values(members).forEach((member: any) => {
                if (teamMemberId === member.teamMemberId) {
                    let project = member.projectId;
                    let phase = member.phaseId;
                    let role = member.roleId;
                    projects.push(project);
                    phases.push(phase);
                    roles.push(role);
                }
            });
            setMemberInvolvement({
                projects: Array.from(new Set(projects)),
                phases: Array.from(new Set(phases)),
                roles: Array.from(new Set(roles))
            });
        }
    }, [teamMemberId, members]);

    const submitForm = async () => {
        // const findProject = projects.find((p) => p.uuid === selectedProject);
        // if (findProject) {
        //     const findPhase = findProject.phases.find((p) => p.uuid === selectedPhase);
        //     if (findPhase) {
        //         let length = findPhase.members.filter((f) => f.teamMemberId === teamMemberId).length;
        //         if (length === 0) {
        //             setErrorMessage('');
        //             setErrorMessage(`You are not part of this phase`);
        //             return;
        //         }
        //     } else {
        //         setErrorMessage('');
        //         setErrorMessage(`This phase doesn't belong to the selected project.`);
        //         return;
        //     }
        // }
        if (!isFormValid()) {
            setErrorMessage(
                `Please select a ${
                    (!selectedProject && 'project') ||
                    (!selectedPhase && 'phase') ||
                    (getRoles().length && !selectedRole && 'role') ||
                    (!selectedActivity && 'activity')
                }`
            );
            return;
        }

        onSuccess({
            phaseId: selectedPhase,
            project: selectedProject,
            role: selectedRole,
            activity: selectedActivity
        });
        hideModal();
        resetData();
    };

    const getRoles = () => (projectsMap[selectedProject]?.roles || []).filter((role) => memberInvolvement.roles.includes(role.uuid));

    return (
        <div className={styles.container} style={{ paddingTop: 20, display: 'flex', flexDirection: 'column', gap: 20 }}>
            {errorMessage && <Alert message={errorMessage} type="error" closable onClose={() => {}} />}
            <form>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <Form.Item label="Project" className={styles.margin0} />
                    </div>
                    <div className={styles.column}>
                        <Select
                            value={selectedProject}
                            placeholder={'Select Project'}
                            onChange={(e) => {
                                setSelectedProject(e);
                            }}>
                            {projects
                                // .filter((project) => memberInvolvement.projects.includes(project.uuid))
                                .filter((p) => p.status === 'active')
                                .map((project) => (
                                    <Option key={project?.uuid} value={project?.uuid}>
                                        <Tag color={project?.color}>{project?.serial}</Tag>
                                        {GenUtils.capitalizeInitials(project?.name)}
                                    </Option>
                                ))}
                        </Select>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <Form.Item label="Phase" className={styles.margin0} />
                    </div>
                    <div className={styles.column}>
                        <Select
                            value={selectedPhase}
                            placeholder={'Select Phase'}
                            onChange={(e) => {
                                setSelectedPhase(e);
                            }}
                            disabled={Boolean(!selectedProject)}>
                            {projectPhases &&
                                projectPhases
                                    .filter((phase) => memberInvolvement.phases.includes(phase.uuid))
                                    .map((phase) => (
                                        <Option key={phase.uuid} value={phase.uuid}>
                                            {GenUtils.capitalizeInitials(phase.type.name)}
                                        </Option>
                                    ))}
                        </Select>
                    </div>
                </div>
                {!hideRole && !!getRoles().length && (
                    <div className={styles.row}>
                        <div className={styles.column}>
                            <Form.Item label="Role" className={styles.margin0} />
                        </div>
                        <div className={styles.column}>
                            <Select
                                value={selectedRole}
                                placeholder={'Select Role'}
                                onChange={(e) => {
                                    setSelectedRole(e);
                                }}>
                                {getRoles().map((role) => (
                                    <Option key={role.uuid} value={role.uuid}>
                                        {role.name}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                )}
                {includeActivity && (
                    <div className={styles.row}>
                        <div className={styles.column}>
                            <Form.Item label="Activity" className={styles.margin0} />
                        </div>
                        <div className={styles.column}>
                            <Select
                                value={selectedActivity}
                                placeholder={'Select Activity'}
                                onChange={(e) => {
                                    setSelectedActivity(e);
                                }}>
                                {activities.map((activity) => (
                                    <Option key={activity.uuid} value={activity.uuid}>
                                        {activity.name}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                )}
            </form>
            <div className={classnames(styles.row, styles.center)}>
                <Button
                    type={'ghost'}
                    text="Cancel"
                    onClick={() => {
                        resetData();
                        hideModal();
                    }}
                />
                <Button text="Add" onClick={submitForm} />
            </div>
        </div>
    );
};
export default AddPhaseRow;
