import { PlusCircleOutlined } from '@ant-design/icons';
import { Row, Col, Button } from 'antd';
import FormatUtils, { EDateTimeFormat } from '../../../utils/FormatUtils';
import styles from './AddRow.scss';
import { useSelector } from 'react-redux';
import { IStore } from '../../../redux/store';

const AddRow = ({ weekCount, totals, selectedTeamMemberId, setShowAddRowModal }) => {
    const userTimesheet = useSelector((state: IStore) => state.timeLogs.timesheet);

    return (
        <Row>
            <Col span={9} className={styles.addSaveButtonContainer}>
                <Button disabled={userTimesheet.isLocked} type="primary" onClick={() => setShowAddRowModal(true)} icon={<PlusCircleOutlined style={{ marginRight: '8px' }} />}>
                    New Row
                </Button>
                {/*<Button type="ghost" text="Save" onClick={onSave} />*/}
                last updated at&nbsp;
                {userTimesheet[selectedTeamMemberId]?.teamMember.updatedAt &&
                    FormatUtils.getDateTimeInFormat(
                        new Date(userTimesheet[selectedTeamMemberId]?.teamMember.updatedAt).toISOString(),
                        EDateTimeFormat.HH_MM_a_day
                    )}
            </Col>
            <Col span={15} style={{ alignSelf: 'center' }}>
                <Row justify="space-between" style={{ paddingRight: 20 }}>
                    <Col span={2}>Totals</Col>
                    {Object.keys(totals).map((k) => {
                        return (
                            <>
                                {k !== 'total' && (
                                    <Col span={Math.floor(18 / weekCount)} key={k}>
                                        {FormatUtils.getAmountInFormat(totals[k], false, 2, 0)}
                                    </Col>
                                )}
                            </>
                        );
                    })}
                    <Col span={1}>{FormatUtils.getAmountInFormat(totals['total'], false, 2, 0)}</Col>
                </Row>
            </Col>
        </Row>
    );
};

export default AddRow;
