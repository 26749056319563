import GenUtils from '../../utils/GenUtils';

export const updateConsultant = (...consultantsDetails) => {
  return {
    type: 'UPDATE_CONSULTANT',
    payload: [{
      ...consultantsDetails,
    }],
  }
}

export const setConsultants = (consultants: [], projectId?: null | string) => {
  return {
    type: 'SET_CONSULTANTS',
    payload: {
      consultants: GenUtils.arrayToIdMap(consultants),
      projectId
    },
  }
}