import { Dropdown } from 'antd';
import React from 'react';
import styles from './TeamAvatar.scss';
import NavigationUtils from '../../utils/NavigationUtils';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import Avvvatars from 'avvvatars-react';


const TeamAvatar = ({ name = null, role = null, members = [], image = null, userId = null, disableClick = false }) => {

    const firmId = useSelector((state: IStore) => state?.firm?.uuid);
    
    const goToUser = (id) => {
        !disableClick && NavigationUtils.navigateTo(`/${firmId}/team/${id}`);
    }

    const renderTeamListItem = (user, index) => {
        return {
            key: index,
            label: (
                <TeamAvatar
                    image={user.profilePicUrl}
                    userId={user.uuid}
                    name={user.firstName + ' ' + user.lastName}
                    role={user.role}
                />
            )
        };
    };

    const getTeamMenuItems = (): any[] => {
        return members?.map(renderTeamListItem) || [];
    };

    return members.length > 0 ? (
        <Dropdown menu={{ items: getTeamMenuItems() }} placement='bottomRight'>
            <div className={styles.row} onClick={() => goToUser(userId)} style={{alignItems: 'center'}}>
                {
                    image
                    &&<div style={{ width: 42, minWidth: 42 ,borderRadius:"50%"}}
                    className='hp-border-radius-xl hp-cursor-pointer hp-border-1 hp-border-color-dark-80'>
                <div className='hp-border-radius-lg hp-overflow-hidden hp-bg-info-4 hp-m-4 hp-d-flex'
                     style={{ width: 32, height: 32 }}>
                    <img src={image || require('../../assets/user.png')} alt='User'
                         height='100%' />                          
                </div>
            </div>                   
                }{!image && name && <Avvvatars value={name} size={40}/>}
                {members.length > 1 ? '+'+(members.length - 1) : ''}
                {/* <div className={styles.column}>
                    {name && <div className={styles.name}>{name}</div>}
                    {role && <div className={styles.role}>{role}</div>}
                </div> */}
            </div>
        </Dropdown>
    ) : (
        <div className={styles.row} onClick={() => goToUser(userId)}>
            {
                image
                && <div style={{ width: 42, minWidth: 42 ,borderRadius:"50%"}}
                        className='hp-border-radius-xl hp-cursor-pointer hp-border-1 hp-border-color-dark-80'>
                    <div className='hp-border-radius-lg hp-overflow-hidden hp-bg-info-4 hp-m-4 hp-d-flex'
                         style={{ width: 32, height: 32 }}>
                        <img src={image || require('../../assets/user.png')} alt='User'
                             height='100%' />                          
                    </div>
                </div>
            }{!image && name && <Avvvatars value={name} size={40} />}
            <div className={styles.column}>
                {name && <div className={styles.name}>{name}</div>}
                {role && <div className={styles.role}>{role}</div>}
            </div>
        </div>
    );
};

export default TeamAvatar;