import { Card, Col, Divider, Empty, Form, Input, Modal, Popconfirm, Row, Table, Timeline, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// compoennts
import FeeAllocationCard from '../../components/card/FeeAllocationCard';
import AddPhase from './AddPhase';
import Phase from './Phase';
import FeatureCard from '../../components/card/FeatureCard';
import Button from '../../components/button/Button';

// redux
import { IStore } from '../../redux/store';

// services
import ProjectService from '../../services/ProjectService';
import PhaseService from '../../services/PhaseService';

// utils
import { IPhase } from '../../interfaces/IPhase';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import FormatUtils from '../../utils/FormatUtils';
import Colors from '../../constants/Colors';

// assets
import { CloseOutlined, DollarOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { RiCloseFill } from 'react-icons/ri';
import NoPhaseImage from '../../assets/images/components/nophase.svg';

// styles
import styles from './ServiceFeeTab.scss';

const { Title, Text } = Typography;

const ServiceFeeTab = ({ project }) => {
    const [phases, setPhases] = useState([]);
    const [showCreateModal, setCreateModal] = useState(false);

    const [showAddPhaseModal, setShowAddPhaseModal] = useState(false);
    const [showUpdateFeeDialog, viewUpdateFeeDialog] = useState(false);
    const [name, setName] = useState('');
    const [rate, setRate] = useState(0);
    const [isUpdating, setUpdating] = useState(false);


    const columnClass = 'hp-p1-body hp-text-color-black-100 hp-text-color-dark-0 ';

    const [projectFee, setProjectFee] = useState(null);
    const projectPhases = useSelector((state: IStore) => (project.uuid && state.phases.projectPhases[project.uuid]) || {});

    useEffect(() => {
        PhaseService.fetchPhasesForProject(project.uuid);

        if (project.projectFee && projectFee === null) setProjectFee(project.projectFee);
    }, [project.uuid]);

    useEffect(() => {
        if (projectPhases !== phases) {
            projectPhases && setPhases(Object.values(projectPhases));
        }
    }, [projectPhases]);

    const editProject = async (projectUuid, fieldsToSave) => {
        const { data, error } = await ProjectService.updateProject(projectUuid, {
            ...fieldsToSave
        });
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Update', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Updated Project Details', <notificationIcon.icon
            style={notificationIcon.style} />);
        viewUpdateFeeDialog(false);
    };

    const columns = [
        {
            title: 'Default Role',
            dataIndex: 'role',
            width: 200,
            render: (text) => <span style={{ fontWeight: 500 }} className={columnClass}>{GenUtils.capitalizeFirstLetter(text)}</span>
        },
        {
            title: 'Billable Rate',
            dataIndex: 'rate',
            width: 200,
            render: (text) => <span className={columnClass}>{FormatUtils.getAmountInFormat(text, true, 0, 0)}</span>
        },
        {
            title: '',
            dataIndex: 'edit',
            render: (text, data) => {
                return (<div style={{ display: 'flex', alignItems: 'center' }}>
                    <a onClick={() => openEditDialog(data)}>Edit</a>
                    <Popconfirm
                        title='Are you sure to delete this role?'
                        onConfirm={() => deleteRole(data.key)}
                        okText='Yes'
                        cancelText='No'
                    >
                        <CloseOutlined style={{ color: 'IndianRed', marginLeft: 20 }} />
                    </Popconfirm>
                </div>);
            }
        }
    ];
    const openEditDialog = (role) => {
        setUpdating(role.key);
        setName(role.role);
        setRate(role.rate);
        setCreateModal(true);
    };

    const getRoles = () => {
        return project?.roles?.sort((a, b) => a.name.localeCompare(b.name)).map(role => {
            return {
                key: role.uuid,
                role: role.name,
                rate: role.hourRate,
                edit: 'Edit'
            };
        });
    };

    const deleteRole = async (id) => {
        const { error } = await ProjectService.deleteRole(project.uuid, id);
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Delete', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Successfully deleted the Role', <notificationIcon.icon
            style={notificationIcon.style} />);
        setName('');
        setRate(0);
        setCreateModal(false);
    };

    const updateRole = async (id) => {
        const { data, error } = await ProjectService.updateRole(project.uuid, id, { name, hourRate: rate });
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Update', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Successfully updated the Role', <notificationIcon.icon
            style={notificationIcon.style} />);
        setName('');
        setRate(0);
        setCreateModal(false);
    };

    const createRole = async () => {
        const { data, error } = await ProjectService.createRole({ name, hourRate: rate, projectId: project.uuid });
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Create', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Successfully created the Role', <notificationIcon.icon
            style={notificationIcon.style} />);
        setName('');
        setRate(0);
        setCreateModal(false);
    };


    // @ts-ignore
    return (
        <div>
            <Modal
                title={isUpdating ? 'Update Role' : 'Create Role'}
                width={500}
                centered
                open={showCreateModal}
                onCancel={() => {
                    setName('');
                    setUpdating(false);
                    setRate(0);
                    setCreateModal(false);
                }}
                footer={null}
                closeIcon={
                    <CloseOutlined
                        style={{ color: Colors.red }}
                        className='remix-icon text-color-black-100'
                        size={24} />
                }
            >
                <Form layout='vertical' name='basic' initialValues={{ remember: true }}>
                    <Col
                        span={24}
                        className='hp-profile-content-list hp-mt-8 hp-pb-sm-0 hp-pb-120'
                    >
                        <ul>
                            <li>
                                <span className='hp-p1-body'>Default Role</span>
                                <Input value={name} style={{ width: 300 }}
                                    onChange={e => setName(e.target.value)} />
                            </li>
                            <li className='hp-mt-18'>
                                <span className='hp-p1-body'>Billable Rate (in {
                                    FormatUtils.currencyIso
                                })</span>
                                <Input
                                    style={{ width: 300 }}
                                    type={'number'}
                                    value={rate}
                                    onChange={e => setRate(Number(e.target.value))}
                                />
                            </li>
                        </ul>
                    </Col>
                    <Button text='Save' onClick={() => isUpdating ? updateRole(isUpdating) : createRole()} />
                </Form>
            </Modal>
            {projectPhases && (
                <Row gutter={[10, 10]}>
                    <Col span={6}>
                        <FeatureCard
                            title={FormatUtils.getAmountInFormat(project.projectFee + project.consultantFee, true, 0, 0)}
                            subtitle='Contract Fee'
                            Icon={DollarOutlined}
                        />
                    </Col>
                    <Col span={18}>
                        <FeeAllocationCard
                            title='Service Fee'
                            fee={project.projectFee}
                            allocatedFee={Number(
                                Object
                                    .values(projectPhases)
                                    .reduce((acc: number, curr: IPhase) => curr.feeType === 'fixed' ? acc + curr.amount : acc, 0)
                            )}
                            updateFee={() => viewUpdateFeeDialog(true)}
                        />
                    </Col>
                </Row>
            )}

            <Row style={{ marginTop: 20,display: phases?.length? "flex":"none" }}>
                <Col span={6}>
                    <Title level={4}>Summary</Title>
                </Col>
                <Col span={15}>
                    <Title level={4}>Project Phases</Title>
                </Col>
                <Col span={3}>
                    <Title level={4}>
                        <Button
                            text={'Add Phase'}
                            type='primary'
                            style={{ whiteSpace: 'nowrap' }}
                            icon={<PlusCircleOutlined style={{ marginRight: 10 }} />}
                            onClick={() => setShowAddPhaseModal(true)}
                        />
                    </Title>
                </Col>
            </Row>
            <Row gutter={[10, 10]}>
                <Col span={6} style={{ gap: 10, display: 'flex', flexDirection: 'column' }}>
                    <Card className='hp-border-color-black-40' style={{display: phases?.length? "block":"none"}}>
                        <Text className={styles.fontSize18} strong>
                            Phases({Object.values(projectPhases).length})
                        </Text>
                        <Timeline style={{ marginTop: 30, marginBottom: -45 }}>
                            {!!Object.values(projectPhases)?.length &&
                                Object.values(projectPhases)
                                    .sort(GenUtils.sortByCreatedAt)
                                    .map((phase: any) => <Timeline.Item
                                        key={phase.uuid}>{GenUtils.capitalizeInitials(phase.type?.name)}</Timeline.Item>)}
                        </Timeline>
                        <Divider />
                        <Row style={{ fontSize: 16 }}>
                            <Col span={12}>Planned Fee</Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                {FormatUtils.getAmountInFormat(Number(Object.values(projectPhases).reduce((acc: number, curr: IPhase) => acc + curr.amount, 0) || 0), true, 0, 0)}
                            </Col>
                        </Row>
                        {/* <Row style={{ fontSize: 16 }}>
                                    <Col span={12}>Job Cost</Col>
                                    <Col span={12} style={{ textAlign: 'right' }}>
                                        {FormatUtils.getAmountInFormat(Number(Object.values(projectPhases).reduce((acc: number, curr: IPhase) => acc + curr.amount, 0)||0), true, 0, 0)}
                                    </Col>
                                </Row> */}
                    </Card>
                    <Col className='hp-mt-10'>
                        <Title level={4}>Project Roles</Title>
                    </Col>
                    <Card className='hp-border-color-black-40'>
                        <Button
                            text='Add Role'
                            icon={<PlusCircleOutlined style={{ marginRight: 10 }} />}
                            onClick={() => {
                                setCreateModal(true);
                            }} />
                        <Table
                            className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                            columns={columns}
                            dataSource={project?.roles ? getRoles() : []}
                            style={{ marginTop: '20px' }}
                            size='middle'
                            pagination={false}
                        />
                    </Card>
                </Col>
                <Col span={18}>
                    {
                        !Object.values(projectPhases).length
                            ? (
                                <Empty
                                style={{marginTop:"32px"}}
                                    image={NoPhaseImage}
                                    description={
                                        <div>
                                            <h2>Project Phases</h2>
                                            <h5>Start by adding phases to the project</h5>
                                        </div>
                                    }>
                                    <div style={{ display: 'flex', justifyContent: 'center', margin: '50px' }}>
                                        <Button
                                            text={'Add Phase'}
                                            type='primary'
                                            onClick={() => setShowAddPhaseModal(true)}
                                            icon={<PlusCircleOutlined style={{ marginRight: 10 }} />}
                                        />
                                    </div>
                                </Empty>
                            ) : (
                                <div className={styles.phasesCol}>
                                    {Object.values(projectPhases)
                                        .sort(GenUtils.sortByCreatedAt)
                                        .map((phase: any) => (
                                            <Phase key={phase.uuid} phase={phase} project={project} />
                                        ))}
                                </div>
                            )
                    }
                </Col>
            </Row>
            <Modal
                title='Add Phase'
                width={1000}
                centered
                open={showAddPhaseModal}
                onCancel={() => setShowAddPhaseModal(false)}
                footer={null}
                closeIcon={<RiCloseFill className='remix-icon text-color-black-100' size={24} />}>
                <AddPhase
                    hideModal={() => setShowAddPhaseModal(false)}
                    onSuccess={() => {
                        setShowAddPhaseModal(false);
                    }}
                    totalFee={project.projectFee}
                    project={project}
                    initialPhase={null}
                />
            </Modal>
            <Modal
                title='Update Project Fee'
                width={500}
                centered
                open={showUpdateFeeDialog}
                onCancel={() => viewUpdateFeeDialog(false)}
                footer={null}
                closeIcon={<RiCloseFill className='remix-icon text-color-black-100' size={24} />}>
                <Form
                    layout='vertical'
                    name='basic'
                    initialValues={{ remember: true }}
                    style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                    <Col span={24} className='hp-profile-content-list hp-mt-8 hp-pb-sm-0'
                        style={{ justifyContent: 'center', padding: '20px' }}>
                        <ul style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
                            <li>
                                <Input value={projectFee} style={{ width: 300 }} type='number'
                                    onChange={(e) => setProjectFee(e.target.value)} />
                            </li>
                        </ul>
                    </Col>
                    <Button type='primary' onClick={() => editProject(project.uuid, { projectFee })} text='Save' />
                </Form>
            </Modal>
        </div>
    );
};

export default ServiceFeeTab;
