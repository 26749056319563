import Button from '../../../components/button/Button';
import { Row, Col } from 'antd';
import FormatUtils, { EDateTimeFormat } from '../../../utils/FormatUtils';
import styles from './styles.scss';

const AddRow = ({ setShowAddRowModal, userTimesheet, selectedTeamMemberId, timeRows }) => {
    return (
        <Row>
            <Col span={10} className={styles.addSaveButtonContainer}>
                <Button disable={userTimesheet.isLocked} text="Add Row" onClick={() => setShowAddRowModal(true)} />
                last updated at{' '}
                {userTimesheet[selectedTeamMemberId]?.teamMember.updatedAt &&
                    FormatUtils.getDateTimeInFormat(new Date(userTimesheet[selectedTeamMemberId]?.teamMember.updatedAt).toISOString(), EDateTimeFormat.HH_MM_a)}
            </Col>
            <Col span={14} style={{ alignSelf: 'center' }}>
                <Row justify="space-between" style={{ paddingRight: 20 }}>
                    <Col span={16}></Col>
                    <Col span={2}>Totals</Col>
                    <Col span={1}>{FormatUtils.formatTime(timeRows.reduce((acc, cur) => acc + cur.seconds, 0))}</Col>
                    <Col span={4}></Col>
                </Row>
            </Col>
        </Row>
    );
};

export default AddRow;
