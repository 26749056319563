import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Button, Col, Drawer, Dropdown, Menu, Popconfirm, Row } from 'antd';
import { RiCloseFill, RiMenuFill, RiMore2Line } from 'react-icons/ri';

import Breadcrumbs from '../../layout/components/content/breadcrumbs';
import ActionButton from '../../layout/components/content/action-button';
import MenuFirm from './MenuFirm';
import { EFileCategory } from '../../services/FileService';
import ImageUploadPopup from '../../components/files/ImageUploadPopup';
import UserService from '../../services/UserService';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';

export default function FirmSettingsPage() {
    const [visible, setVisible] = useState(false);
    const [uploadImage, showUploadImage] = useState(false);
    const firmId = useSelector((state: IStore) => state?.firm?.uuid);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    const changeLogo = () => {
        showUploadImage(true);
    }

    const onUpload = async (fileId) => {
        const { data, error } = await UserService.updateFirmLogo(fileId);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to save', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
    }

    const removeLogo = () => {
        onUpload(null);
    }

    const items = [
        {
            key: '0',
            label: (
                <div onClick={changeLogo}>
                    Update Company Logo
                </div>
            )
        },
        {
            key: '1',
            label: (
                <Popconfirm
                    title='Are you sure to remove company logo?'
                    onConfirm={removeLogo}
                    okText='Yes'
                    cancelText='No'
                >
                    <div style={{color: 'red'}}>Remove Logo</div>
                </Popconfirm>
            )
        }
    ];

    function moreBtn() {
        return (
            <Dropdown menu={{ items }} placement='bottomLeft'>
                <Button
                    type='text'
                    icon={<RiMore2Line className='hp-text-color-black-100 hp-text-color-dark-0' size={24} />}
                />
            </Dropdown>
        );
    }

    return (
        <Row gutter={[32, 32]} className='hp-mb-32'>
            <ImageUploadPopup
                show={uploadImage}
                onHide={() => showUploadImage(false)}
                category={EFileCategory.ProfilePic}
                onUpload={onUpload}
            />
            <Drawer
                title={moreBtn()}
                className='hp-profile-mobile-menu'
                placement='left'
                closable={true}
                onClose={onClose}
                open={visible}
                closeIcon={
                    <RiCloseFill
                        className='remix-icon hp-text-color-black-80'
                        size={24}
                    />
                }
            >
                <MenuFirm
                    onCloseDrawer={onClose}
                    moreBtnCheck='none'
                    footer='none'
                />
            </Drawer>

            {/*<Col span={24}>*/}
            {/*    <Row gutter={[32, 32]} justify='space-between'>*/}
            {/*        <Breadcrumbs breadCrumbParent='Pages' breadCrumbActive='Profile' />*/}

            {/*        <ActionButton />*/}
            {/*    </Row>*/}
            {/*</Col>*/}

            <Col span={24}>
                <Row
                    className='hp-profile-mobile-menu-btn hp-bg-color-black-0 hp-bg-color-dark-100 hp-border-radius hp-py-12 hp-px-sm-8 hp-px-24 hp-mb-16'>
                    <Button
                        className='hp-p-0'
                        type='text'
                        icon={
                            <RiMenuFill
                                size={24}
                                className='remix-icon hp-text-color-black-80 hp-text-color-dark-30'
                            />
                        }
                        onClick={showDrawer}
                    />
                </Row>

                <Row className='hp-bg-color-black-0 hp-bg-color-dark-100 hp-border-radius hp-pr-sm-16 hp-pr-32'>
                    <MenuFirm moreBtn={moreBtn} onCloseDrawer={()=>{}}/>

                    <Col
                        flex='1 1'
                        className='hp-pl-sm-16 hp-pl-32 hp-py-sm-24 hp-py-32 hp-pb-24 hp-overflow-hidden'
                        style={{ padding: 30 }}
                    >
                        <Outlet />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
