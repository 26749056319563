import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducers/index';
import { IAuthState } from './reducers/AuthReducer';
import { ILoaderState } from './reducers/LoaderReducer';
import { IFirmState } from './reducers/FirmReducer';
import { IClientsState } from './reducers/ClientReducer';
import { ITeamState } from './reducers/TeamReducer';
import IUser from '../interfaces/IUser';
import { IProjectState } from './reducers/ProjectReducer';
import { IPermissionState } from './reducers/PermissionReducer';
import { IConsultantState } from './reducers/ConsultantReducer';
import { IPhaseState } from './reducers/PhaseReducer';
import { IMembersState } from './reducers/MemberReducer';
import { ICommunicationState } from './reducers/CommunicationReducer';
import { IOptionState } from './reducers/OptionReducer';
import { IActivityState } from './reducers/ActivityReducer';
import { IConfigState } from './reducers/ConfigReducer';
import { IExpenseState } from './reducers/ExpenseReducer';
import { ITimeOffTypeState } from './reducers/TimeOffTypeReducer';
import { ILeavesState } from './reducers/LeavesReducer';
import { IInvoiceState } from './reducers/InvoiceReducer';
import { ITaxState } from './reducers/TaxReducer';
import { IPaymentState } from './reducers/PaymentReducer';
import { ITaskState } from './reducers/TaskReducer';
import { IMilestoneState } from './reducers/MilestoneReducer';
import { ITimesheetState } from './reducers/TimeSheetReducer';

export interface IStore {
    auth?: IAuthState;
    loader?: ILoaderState;
    firm?: IFirmState;
    user?: IUser;
    client?: IClientsState;
    team?: ITeamState;
    project?: IProjectState;
    permission?: IPermissionState;
    consultants?: IConsultantState;
    phases?: IPhaseState;
    members?: IMembersState;
    communication?: ICommunicationState;
    options?: IOptionState;
    activity: IActivityState;
    config?: IConfigState;
    timeLogs?: ITimesheetState;
    expenses?: IExpenseState;
    timeOff?: ITimeOffTypeState;
    leaves?: ILeavesState;
    taxes?: ITaxState;
    invoices?: IInvoiceState;
    payments?: IPaymentState;
    tasks?: ITaskState;
    milestones?: IMilestoneState;
}

const store = configureStore({
    reducer,
    preloadedState: {}
});

export default store;
