import React, { useEffect, useState } from 'react';

import { Button, Col, Divider, Form, Input, Modal, Row, Select, Table } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import {
    Countries,
    Currencies,
    CurrencyFormat,
    DateFormatOptions,
    DaysOfWeek,
    Industries,
    Languages,
    MeasurementUnit,
    NumberFormat,
    TimeFormat,
    TimeZones,
    YearStart
} from '../../constants/Options';

import styles from './FirmSettings.scss';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import { getUserDetails } from '../../redux/selectors/UserSelector';
import UserService from '../../services/UserService';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import { useLocation } from 'react-router-dom';
import formStyles from '../form.scss';

const { Column } = Table;
const { Option } = Select;

const FirmInfo = () => {
    const [contactModalVisible, setContactModalVisible] = useState(false);
    const [preferenceModalVisible, setPreferenceModalVisible] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState('');

    const [firmName, setFirmName] = useState(null);
    const [firmType, setFirmType] = useState(null);

    const [building, setBuilding] = useState(null);
    const [street, setStreet] = useState(null);
    const [city, setCity] = useState(null);
    const [state, setState] = useState(null);
    const [country, setCountry] = useState(null);
    const [zipCode, setZipCode] = useState(null);

    const [language, setLanguage] = useState(null);
    const [weekStart, setWeekStart] = useState(null);
    const [timeZone, setTimeZone] = useState(null);
    const [dateFormat, setDateFormat] = useState(null);
    const [timeFormat, setTimeFormat] = useState(null);
    const [yearStart, setYearStart] = useState(null);
    const [measurementUnit, setMeasurementUnit] = useState(null);
    const [currency, setCurrency] = useState(null);
    const [currencyFormat, setCurrencyFormat] = useState(null);
    const [amountFormat, setAmountFormat] = useState(null);

    const listTitle = 'hp-p1-body';
    const listResult = 'hp-mt-sm-4 hp-p1-body hp-text-color-black-100 hp-text-color-dark-0';
    const dividerClass = 'hp-border-color-black-40 hp-border-color-dark-80';

    const { pathname } = useLocation();
    const firm = useSelector((state: IStore) => state.firm.firm);

    useEffect(() => {
        const pathSplit = pathname.split('/');
        const selectedId = pathSplit[pathSplit.indexOf('team') + 1];
        selectedId && setSelectedUserId(selectedId);
    }, [pathname]);

    useEffect(() => {
        if (!firmName && firm.firmName !== firmName) setFirmName(firm.firmName);
        if (!firmType && firm.firmType !== firmType) setFirmType(firm.firmType);

        if (!language && firm.language !== language) setLanguage(firm.language);
        if (!weekStart && firm.weekStart !== weekStart) setWeekStart(firm.weekStart);
        if (!timeZone && firm.timeZone !== timeZone) setTimeZone(firm.timeZone);
        if (!dateFormat && firm.dateFormat !== dateFormat) setDateFormat(firm.dateFormat);
        if (!timeFormat && firm.timeFormat !== timeFormat) setTimeFormat(firm.timeFormat);
        if (!yearStart && firm.yearStart !== yearStart) setYearStart(firm.yearStart);
        if (!measurementUnit && firm.measurementUnit !== measurementUnit) setMeasurementUnit(firm.measurementUnit);
        if (!currency && firm.currency !== currency) setCurrency(firm.currency);
        if (!currencyFormat && firm.currencyFormat !== currencyFormat) setCurrencyFormat(firm.currencyFormat);
        if (!amountFormat && firm.amountFormat !== amountFormat) setAmountFormat(firm.amountFormat);

        if (!building && firm.address?.building !== building) setBuilding(firm.address?.building);
        if (!street && firm.address?.street !== street) setStreet(firm.address?.street);
        if (!city && firm.address?.city !== city) setCity(firm.address?.city);
        if (!state && firm.address?.state !== state) setState(firm.address?.state);
        if (!zipCode && firm.address?.zipCode !== zipCode) setZipCode(firm.address?.zipCode);
        if (!country && firm.address?.country !== country) setCountry(firm.address?.country);
    }, [firm]);

    const updateUser = async (detailsToUpdate) => {
        const { data, error } = await UserService.updateUser(detailsToUpdate);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Update', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Successfully updated information', <notificationIcon.icon style={notificationIcon.style} />);
        contactModalCancel();
        preferenceModalCancel();
    };

    const contactModalShow = () => {
        setContactModalVisible(true);
    };

    const contactModalCancel = () => {
        setContactModalVisible(false);
    };

    const preferenceModalShow = () => {
        setPreferenceModalVisible(true);
    };

    const preferenceModalCancel = () => {
        setPreferenceModalVisible(false);
    };

    return (
        <div>
            <Modal
                title="Edit Basic Information"
                width={600}
                centered
                open={contactModalVisible}
                onCancel={contactModalCancel}
                footer={null}
                closeIcon={<CloseOutlined style={{ color: 'IndianRed' }} className="remix-icon text-color-black-100" size={24} />}>
                <Form layout="vertical" name="basic" initialValues={{ remember: true }}>
                    <Col span={24} className="hp-profile-content-list hp-mt-8 hp-pb-sm-0 hp-pb-120">
                        <div className={styles.container}>
                            <div className={styles.box}>
                                <span className={styles.title}>Firm Name</span>
                                <Input style={{ width: 300 }} onChange={(e) => setFirmName(e.target.value)} value={firmName} className={styles.dropdown} />
                            </div>
                            <div className={styles.box}>
                                <span className={styles.title}>Industry</span>
                                <Select placeholder="Select" className={styles.dropdown} value={firmType} onChange={setFirmType}>
                                    {Industries.map((industry) => (
                                        <Option key={industry}>{industry}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div>
                                <span className={styles.title}>Address</span>
                                <div className={styles.row}>
                                    <Input
                                        placeholder="Street, avenue, etc."
                                        type={'street'}
                                        value={street}
                                        onChange={(e) => {
                                            setStreet(e.target.value);
                                        }}
                                    />
                                    <Input
                                        placeholder="Suite, Unit, Apt, etc"
                                        type={'building'}
                                        value={building}
                                        onChange={(e) => {
                                            setBuilding(e.target.value);
                                        }}
                                    />
                                </div>
                                <div className={styles.row}>
                                    <Input
                                        placeholder="City"
                                        type={'city'}
                                        value={city}
                                        onChange={(e) => {
                                            setCity(e.target.value);
                                        }}
                                    />
                                    <Input
                                        placeholder="State/Province/Region"
                                        type={'state'}
                                        value={state}
                                        onChange={(e) => {
                                            setState(e.target.value);
                                        }}
                                    />
                                    <Input
                                        placeholder="Zip/Postal Code"
                                        type={'zipCode'}
                                        value={zipCode}
                                        onChange={(e) => {
                                            setZipCode(e.target.value);
                                        }}
                                    />
                                </div>
                                <div className={styles.row}>
                                    <Form.Item rules={[{ required: true }]}>
                                        <Select
                                            showSearch
                                            placeholder="Country"
                                            style={{ width: 300 }}
                                            value={country}
                                            onChange={(value) => {
                                                setCountry(value);
                                            }}>
                                            {Countries.map((_country) => (
                                                <Option key={_country} value={_country}>
                                                    {_country}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Row style={{ marginTop: '-100px', justifyContent: 'space-between' }}>
                        <Col md={11} span={24} className="hp-pr-sm-0 hp-pr-12">
                            <Button
                                block
                                type="primary"
                                htmlType="submit"
                                onClick={() =>
                                    updateUser({
                                        firmName,
                                        firmType,
                                        address: {
                                            building,
                                            city,
                                            country,
                                            state,
                                            street,
                                            zipCode
                                        }
                                    })
                                }>
                                Save
                            </Button>
                        </Col>

                        <Col md={11} span={24} className="hp-mt-sm-12 hp-pl-sm-0 hp-pl-12">
                            <Button block onClick={contactModalCancel}>
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal
                title="Edit Preferences"
                width={500}
                centered
                visible={preferenceModalVisible}
                onCancel={preferenceModalCancel}
                footer={null}
                closeIcon={<CloseOutlined style={{ color: 'IndianRed' }} className="remix-icon text-color-black-100" size={24} />}>
                <Form layout="vertical" name="basic" initialValues={{ remember: true }}>
                    <Col span={24} className="hp-profile-content-list hp-mt-sm-8 hp-mt-24">
                        <div className={styles.container}>
                            <div className={styles.box}>
                                <span className={styles.title}>Language</span>
                                <Select
                                    placeholder="English"
                                    className={styles.dropdown}
                                    style={{ width: 300 }}
                                    value={firm.language}
                                    onChange={(value) => {
                                        setLanguage(value);
                                    }}>
                                    {Languages.map((language) => (
                                        <Option key={language.code} value={language.code}>
                                            {language.name}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div className={styles.box}>
                                <span className={styles.title}>Start Week On</span>
                                <Select
                                    placeholder="Thursday"
                                    className={styles.dropdown}
                                    style={{ width: 300 }}
                                    value={weekStart}
                                    onChange={(value) => {
                                        setWeekStart(value);
                                    }}>
                                {DaysOfWeek.map((day) => (
                                    <Option key={day} value={day}>
                                        {GenUtils.capitalizeFirstLetter(day)}
                                    </Option>
                                    ))}
                                </Select>
                            </div>

                            <div className={styles.box}>
                                <span className={styles.title}>Timezone</span>
                                <Select
                                    placeholder="(GMT-09:00) Alask"
                                    className={styles.dropdown}
                                    style={{ width: 300 }}
                                    value={timeZone}
                                    onChange={(value) => {
                                        setTimeZone(value);
                                    }}>
                                    {TimeZones.map((timeZone) => (
                                        <option key={timeZone.value} value={timeZone.value}>
                                            {timeZone.name}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                            <div className={styles.box}>
                                <span className={styles.title}>Date Format</span>
                                <Select
                                    placeholder="MM/DD/YYYY (e.g. 04/25/2022)"
                                    className={styles.dropdown}
                                    style={{ width: 300 }}
                                    value={dateFormat}
                                    onChange={(value) => {
                                        setDateFormat(value);
                                    }}>
                                {DateFormatOptions.map((dateFormat) => (
                                    <option key={dateFormat.key} value={dateFormat.key}>
                                        {dateFormat.value}
                                    </option>
                                    ))}
                                </Select>
                            </div>
                            <div className={styles.box}>
                                <span className={styles.title}>Time Format</span>
                                <Select
                                    placeholder="Select"
                                    className={styles.dropdown}
                                    style={{ width: 300, border: 'none' }}
                                    value={timeFormat}
                                    onChange={(value) => {
                                        setTimeFormat(value);
                                    }}>
                                {TimeFormat.map((timeFormat) => (
                                    <option key={timeFormat.key} value={timeFormat.key}>
                                        {timeFormat.value}
                                    </option>
                                    ))}
                                </Select>
                            </div>
                            <div className={styles.vbox}>
                                <span className={styles.text}>Fiscal Year Starts In</span>
                                <Select
                                    placeholder="Select"
                                    className={styles.dropdown}
                                    value={GenUtils.capitalizeFirstLetter(yearStart)}
                                    onChange={(value) => {
                                        setYearStart(value);
                                    }}
                                    // className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                                    style={{ width: '100%', border: 'none' }}>
                                {YearStart.map((yearStart) => (
                                    <option key={yearStart} value={yearStart}>
                                        {GenUtils.capitalizeFirstLetter(yearStart)}
                                    </option>
                                    ))}
                                </Select>
                            </div>
                            <div className={styles.vbox}>
                                <span className={styles.text}>Unit of Measurement</span>
                                <Select
                                    placeholder="Select"
                                    className={styles.dropdown}
                                    onChange={(value) => {
                                        setMeasurementUnit(value);
                                    }}
                                    value={GenUtils.capitalizeFirstLetter(measurementUnit)}
                                    style={{ width: '100%', border: 'none' }}>
                                {MeasurementUnit.map((measurementUnit) => (
                                    <option key={measurementUnit} value={measurementUnit}>
                                        {GenUtils.capitalizeFirstLetter(measurementUnit)}
                                    </option>
                                    ))}
                                </Select>
                            </div>
                            <div className={styles.vbox}>
                                <span className={styles.title}>Currency</span>
                                <Select
                                    placeholder="Select"
                                    className={styles.dropdown}
                                    onChange={(value) => {
                                        setCurrency(value);
                                    }}
                                    value={currency}
                                    style={{ width: '100%', border: 'none' }}>
                                {Currencies.map((currency) => (
                                    <Option key={currency.iso} value={currency.iso}>
                                        {currency.name} - {currency.symbol}
                                    </Option>
                                    ))}
                                </Select>
                            </div>
                            <div className={styles.vbox}>
                                <span className={styles.text}>Currency Format</span>
                                <Select
                                    placeholder="Select"
                                    className={styles.dropdown}
                                    value={currencyFormat}
                                    onChange={(value) => {
                                        setCurrencyFormat(value);
                                    }}
                                    style={{ width: '100%', border: 'none' }}>
                                {CurrencyFormat.map((currencyFormat) => (
                                    <option key={currencyFormat.value} value={currencyFormat.value}>
                                        {currencyFormat.name}
                                    </option>
                                    ))}
                                </Select>
                            </div>
                            <div className={styles.vbox}>
                                <span className={styles.title}>Number Format</span>
                                <Select
                                    placeholder="Select"
                                    className={styles.dropdown}
                                    onChange={(value) => {
                                        setAmountFormat(value);
                                    }}
                                    value={amountFormat || 'indian'}
                                    style={{ width: '100%', border: 'none' }}>
                                {NumberFormat.map((numberFormat) => (
                                    <option key={numberFormat.value} value={numberFormat.value}>
                                        {numberFormat.name}
                                    </option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </Col>
                    <Row style={{ marginTop: 30, justifyContent: 'space-between' }}>
                        <Col md={11} span={24} className="hp-pr-sm-0 hp-pr-12">
                            <Button
                                block
                                type="primary"
                                htmlType="submit"
                                onClick={() =>
                                    updateUser({
                                        language: language || firm.language,
                                        weekStart: weekStart?.toLowerCase() || 'monday',
                                        yearStart: yearStart?.toLowerCase() || 'january',
                                        timeFormat: timeFormat || '12',
                                        timeZone: timeZone || firm.timeZone,
                                        dateFormat: dateFormat || 'dd/mm/yyyy',
                                        measurementUnit: measurementUnit?.toLowerCase() || 'meter',
                                        currency: currency || 'INR',
                                        currencyFormat: currencyFormat || 'symbol_after',
                                        amountFormat: amountFormat || 'indian'
                                    })
                                }>
                                Save
                            </Button>
                        </Col>

                        <Col md={11} span={24} className="hp-mt-sm-12 hp-pl-sm-0 hp-pl-12">
                            <Button block onClick={preferenceModalCancel}>
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Col md={15} span={24}>
                <h2>Firm Information</h2>
                <p className="hp-p1-body hp-mb-0">Define basic information and preferences for the firm.</p>
            </Col>

            <Divider className={dividerClass} />

            <Row align="middle" justify="space-between">
                <Col md={12} span={24}>
                    <h3>Basic Information</h3>
                </Col>

                <Col md={12} span={24} className="hp-profile-action-btn hp-text-right">
                    <Button type="primary" ghost onClick={contactModalShow}>
                        Edit
                    </Button>
                </Col>

                <Col span={24} className="hp-profile-content-list hp-mt-8 hp-pb-sm-0 hp-pb-120">
                    <ul>
                        <li>
                            <span className={listTitle}>Firm Name</span>
                            <span className={listResult}>{firm.firmName}</span>
                        </li>

                        <li className="hp-mt-18">
                            <span className={listTitle}>Industry</span>
                            <span className={listResult}>{firm.firmType || '-'}</span>
                        </li>
                        <li className="hp-mt-18">
                            <span className={listTitle}>Address</span>
                            <span className={listResult}>
                                {firm.address ? (
                                    <>
                                        {firm.address.building}, {firm.address.street}, {firm.address.city}, <br />
                                        {firm.address.state} - ({firm.address.zipCode})<br />
                                        {firm.address.country}
                                    </>
                                ) : (
                                    '-'
                                )}
                            </span>
                        </li>
                    </ul>
                </Col>
            </Row>

            <Divider className={dividerClass} style={{ marginTop: '-80px' }} />

            <Row align="middle" justify="space-between">
                <Col md={12} span={24}>
                    <h3>Preference</h3>
                </Col>

                <Col md={12} span={24} className="hp-profile-action-btn hp-text-right">
                    <Button type="primary" ghost onClick={preferenceModalShow}>
                        Edit
                    </Button>
                </Col>

                <Col span={24} className="hp-profile-content-list hp-mt-sm-8 hp-mt-24">
                    <ul>
                        <li>
                            <span className={listTitle}>Language</span>
                            <span className={listResult}>{Languages.find((l) => l.code === firm.language)?.name || 'English'}</span>
                        </li>

                        <li className="hp-mt-18">
                            <span className={listTitle}>Start Week On</span>
                            <span className={listResult}>{GenUtils.capitalizeFirstLetter(firm.weekStart) || 'Monday'}</span>
                        </li>

                        <li className="hp-mt-18">
                            <span className={listTitle}>Timezone</span>
                            <span className={listResult}>{firm.timeZone || '-'}</span>
                        </li>
                        <li className="hp-mt-18">
                            <span className={listTitle}>Date Format</span>
                            <span className={listResult}>{firm.dateFormat?.toUpperCase() || '-'}</span>
                        </li>
                        <li className="hp-mt-18">
                            <span className={listTitle}>Time Format</span>
                            <span className={listResult}>{TimeFormat[firm.timeFormat === '24' ? 1 : 0].value}</span>
                        </li>
                        <li className="hp-mt-18">
                            <span className={listTitle}>Fiscal Year Starts In</span>
                            <span className={listResult}>{GenUtils.capitalizeFirstLetter(firm.yearStart) || '-'}</span>
                        </li>
                        <li className="hp-mt-18">
                            <span className={listTitle}>Unit of Measurement</span>
                            <span className={listResult}>{GenUtils.capitalizeFirstLetter(firm.measurementUnit) || '-'}</span>
                        </li>
                        <li className="hp-mt-18">
                            <span className={listTitle}>Currency</span>
                            <span className={listResult}>{Currencies.find((format) => format.iso === firm.currency)?.name || '-'}</span>
                        </li>
                        <li className="hp-mt-18">
                            <span className={listTitle}>Currency Format</span>
                            <span className={listResult}>
                                {' '}
                                {CurrencyFormat.find((format) => format.value === firm.currencyFormat)?.name.replace(
                                    '$',
                                    Currencies.find((format) => format.iso === firm.currency)?.symbol
                                ) || '-'}
                            </span>
                        </li>
                        <li className="hp-mt-18">
                            <span className={listTitle}>Number Format</span>
                            <span className={listResult}>{NumberFormat.find((format) => format.value === firm.amountFormat)?.name || '-'}</span>
                        </li>
                    </ul>
                </Col>
            </Row>
        </div>
    );
};
export default FirmInfo;
