import TabItem, { ITabItem } from './TabItem';
import styles from './Tabs.scss';

const Tabs = ({
                  items,
                  onTabClick = () => null,
                  activeTab
              }: { items: ITabItem[], onTabClick: (k) => any, activeTab: string }) => {
    return (
        <div className={styles.container}>
            {
                items.map(
                    item =>
                        <TabItem
                            key={item.key}
                            {...item}
                            onClick={() => onTabClick(item.key)}
                            isActive={activeTab === item.key}
                        />
                )
            }
        </div>
    );
};

export default Tabs;