import { ColumnConfig, Column } from '@ant-design/plots';
import Colors from '../../../constants/Colors';
import React from 'react';
import { DatePicker } from 'antd';
import ReportService from '../../../services/ReportService';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { IStore } from '../../../redux/store';
import { Currencies } from '../../../constants/Options';
import moment from 'moment';
import GroupedTable from '../../../components/table/groupedTable/GroupedTable';
import styles from './Charts.scss';
import classnames from 'classnames';

type TGroup = 'profitloss' | 'revenue';
type TD1 = { month: string; value: number; groupType: TGroup };
type TD2 = TD1 & { seriesType: string | null; projectId: string | null; projectName: string | null; color: string | null; actualValue: number };

export interface TData {
    success: boolean;
    data: Data;
    message: string;
    httpCode: number;
    errorCode: number;
}

export interface Data {
    [key: string]: TRecord;
}

export interface TRecord {
    cost: number;
    revenue: {
        total: number;
        project:
            | {}
            | {
                  [key: string]: {
                      name: string;
                      color: string;
                      status: string;
                      number: number;
                      revenue: number;
                  };
              };
    };
    profitOrLoss: number;
}

const chartStyle = (height?: number) => ({
    width: '100%',
    padding: 25,
    height,
    background: 'white',
    boxShadow: `0px 0px 3px ${Colors.grayLight2}`
});

let trackMap = new Map<string, number>();

export default function RevenueVsCost() {
    const [processed, setProcessed] = React.useState({
        profitLoss: [] as TD2[],
        revenue: [] as TD2[]
    });
    const [plotData, setPlotData] = React.useState<TD2[]>([]);
    const [from, setFrom] = React.useState(moment().subtract(6, 'M').toDate());
    const [to, setTo] = React.useState(new Date());
    const [data, setData] = React.useState<TData['data']>(null);

    React.useEffect(() => {
        async function fetcher() {
            const res = await ReportService.fetchRevenueForecast(from, to);
            if (res.data) {
                trackMap = new Map<string, number>();
                setData(res.data);
                const processed = processData(res.data);
                setProcessed(processed);
                setPlotData([...processed.profitLoss, ...processed.revenue]);
            }
        }
        fetcher();
    }, [from, to]);

    return (
        <div style={{ padding: 10 }}>
            {data !== null ? (
                <>
                    <DatePicker.RangePicker
                        onChange={(values) => {
                            if (values !== null) {
                                setFrom(values[0].toDate());
                                setTo(values[1].toDate());
                            }
                        }}
                        value={[moment(from), moment(to)]}
                        style={{ marginBottom: 50 }}
                    />
                    <Legends data={processed.revenue} />
                    <Chart data={plotData} length={processed.profitLoss.length} rawData={data} />
                    <TableData revenue={processed.revenue} profitLoss={processed.profitLoss} data={data} />
                </>
            ) : null}
        </div>
    );
}

function Chart(props: { data: TD2[]; length: number; rawData: Data }) {
    const state = useSelector((state: IStore) => state.user) as unknown as any;
    const currency = state.user.currency;
    const findCurrency = Currencies.find((c) => c.iso === currency);

    const config: ColumnConfig = {
        data: props.data,
        isGroup: true,
        isStack: true,
        xField: 'month',
        yField: 'value',
        seriesField: 'seriesType',
        legend: false,
        minColumnWidth: columnWidth(props.length),
        yAxis: {
            label: {
                formatter(text, item, index) {
                    return Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: findCurrency === undefined ? 'INR' : findCurrency.iso
                    }).format(parseFloat(text));
                }
            }
        },
        color(datum, defaultColor) {
            if ('seriesType' in datum && typeof datum.seriesType === 'string') {
                if (datum.seriesType.includes('Revenue')) {
                    const s = datum.seriesType.split(' ');
                    const month = s[0] + ' ' + s[1];
                    const f = props.data.filter((p) => p.month === month && p.groupType === 'revenue');
                    const g = trackMap.get(month);
                    trackMap.set(month, g + 1);
                    if (g + 1 === f.length) {
                        trackMap.set(month, 0);
                    }
                    return f[g] === undefined || f[g].color === null ? defaultColor : f[g].color;
                } else return defaultColor;
            } else return defaultColor;
        },
        tooltip: {
            customItems(originalItems) {
                return originalItems.map((o) => {
                    if (o.data.seriesType.includes('Profit Loss')) {
                        return { ...o, name: `Profit Loss` };
                    } else {
                        return { ...o, name: `${o.data.projectName === null ? 'N/A' : o.data.projectName}` };
                    }
                });
            },
            customContent(title, data) {
                return (
                    <Popover
                        key={title}
                        title={title}
                        data={data.map((d) => d.data).filter((d) => d.groupType !== 'profitloss')}
                        currentCurrency={findCurrency}
                        rawData={props.rawData}
                    />
                );
            }
        },
        columnStyle: {
            lineDash: [5, 5],
            lineWidth: 2,
            strokeOpacity: 100,
            cursor: 'pointer'
        },
        pattern(datum, color) {
            if ('seriesType' in datum && typeof datum.seriesType === 'string' && datum.seriesType.includes('Profit Loss')) {
                return {
                    type: 'line'
                };
            } else return null;
        }
    };

    return <Column style={{ ...chartStyle(300), borderBottomLeftRadius: 20, borderBottomRightRadius: 20, marginBottom: 20 }} {...config} />;
}

function Legends(props: { data: TD2[] }) {
    const [legends, setLegends] = React.useState<{ projectId: string; projectName: string; color: string }[]>([]);

    React.useMemo(() => {
        const r: { [key: string]: (typeof legends)[number] } = {};
        const list = [];
        props.data.forEach((p) => {
            r[p.projectId] = {
                projectId: p.projectId,
                projectName: p.projectName,
                color: p.color
            };
        });
        Object.keys(r).forEach((k) => {
            list.push(r[k]);
        });
        setLegends(list);
    }, [props.data]);

    return (
        <div style={{ ...chartStyle(), borderTopLeftRadius: 20, borderTopRightRadius: 20, display: 'flex', flexWrap: 'wrap', gap: 10 }}>
            {legends.map((l) => (
                <Legend key={l.projectId} name={l.projectName} color={l.color} />
            ))}
        </div>
    );
}

const Legend = (props: { name: string; color: string }) => {
    return (
        <div style={{ display: 'flex', gap: 5, justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ background: props.color, height: 10, width: 10, borderRadius: '50%' }}></div>
            <p style={{ margin: 0 }}>{props.name}</p>
        </div>
    );
};

const TableData = (props: { revenue: TD2[]; profitLoss: TD2[]; data: TData['data'] }) => {
    const state = useSelector((state: IStore) => state.user) as unknown as any;
    const currency = state.user.currency;
    const findCurrency = Currencies.find((c) => c.iso === currency);
    const columns: ColumnsType = [
        {
            title: 'MONTH',
            dataIndex: 'month',
            key: 'month'
        },
        {
            title: 'COST',
            dataIndex: 'cost',
            key: 'cost',
            align: 'right',
            render(value) {
                return (
                    <span>
                        {Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: findCurrency === undefined ? 'INR' : findCurrency.iso
                        }).format(parseFloat(value))}
                    </span>
                );
            }
        },
        {
            title: 'REVENUE',
            dataIndex: 'revenue',
            key: 'revenue',
            align: 'right',
            render(value) {
                return (
                    <span>
                        {Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: findCurrency === undefined ? 'INR' : findCurrency.iso
                        }).format(parseFloat(value))}
                    </span>
                );
            }
        },
        {
            title: 'PROFIT / LOSS',
            dataIndex: 'profitLoss',
            key: 'profitLoss',
            align: 'right',
            render(value) {
                return (
                    <span>
                        {Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: findCurrency === undefined ? 'INR' : findCurrency.iso
                        }).format(parseFloat(value))}
                    </span>
                );
            }
        },
        {
            title: '(%)',
            dataIndex: 'percent',
            key: 'percent',
            align: 'right',
            render: (t: string) => {
                if (t.includes('-')) {
                    if (t.includes('Infinity')) return <span>-</span>;
                    return <span style={{ color: Colors.red }}>{t}</span>;
                } else return <span>{t}</span>;
            }
        }
    ];
    const [tableData, setTableData] = React.useState<{ month: string; cost: number; revenue: number; profitLoss: number; percent: string }[]>([]);

    React.useMemo(() => {
        const d: typeof tableData = [];
        props.profitLoss.forEach((p) => {
            const month = p.month;
            const profitLoss = p.actualValue;
            const cost = props.data[p.month].cost;
            const revenue = props.data[p.month].revenue.total;
            const percent = calculatePercentage(revenue, p.actualValue);
            d.push({
                month,
                cost,
                revenue,
                profitLoss,
                percent
            });
        });
        setTableData(d);
    }, [props]);

    return (
        <div style={{ borderRadius: 20, width: '100%', overflow: 'hidden' }}>
            <GroupedTable columns={columns} data={tableData} />
        </div>
    );
};

const Popover = (props: {
    title: string;
    data: TD2[];
    rawData: Data;
    currentCurrency: {
        name: string;
        symbol: string;
        iso: string;
    };
}) => {
    const [infos, setInfos] = React.useState<{ title: string; value: string; color?: string }[]>([]);

    React.useMemo(() => {
        const profitLoss = props.rawData[props.title].profitOrLoss;
        const cost = props.rawData[props.title].cost;
        const revenue = props.rawData[props.title].revenue.total;
        const percent = calculatePercentage(revenue, profitLoss);
        const i = [
            {
                title: 'Cost',
                value: Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: props.currentCurrency === undefined ? 'INR' : props.currentCurrency.iso
                }).format(cost),
                color: Colors.primary
            },
            {
                title: 'Revenue',
                value: Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: props.currentCurrency === undefined ? 'INR' : props.currentCurrency.iso
                }).format(revenue)
            },
            {
                title: 'Profit / Loss',
                value: Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: props.currentCurrency === undefined ? 'INR' : props.currentCurrency.iso
                }).format(profitLoss)
            },
            {
                title: '(%)',
                value: percent.includes('-') ? (percent.includes('Infinity') ? '-' : percent) : percent,
                color: percent.includes('-') ? (percent.includes('Infinity') ? Colors.black : Colors.red) : Colors.black
            }
        ];
        setInfos(i);
    }, [props.rawData, props.title, props.currentCurrency]);

    return (
        <div className={styles.popoverContainer} style={{ minWidth: 200 }}>
            <div className={styles.header}>{props.title}</div>
            <div className={styles.body}>
                <div className={styles.infos}>
                    {infos.map((info) => (
                        <div className={styles.info} key={info.title}>
                            <div className={styles.title}>{info.title}</div>
                            <div className={styles.title} style={info.color ? { color: info.color } : {}}>
                                {info.value}
                            </div>
                        </div>
                    ))}
                </div>
                <div className={styles.table}>
                    <div className={styles.header}>
                        <div className={styles.title}>Project</div>
                        <div className={styles.title}>{props.currentCurrency === undefined ? 'Rs' : props.currentCurrency.symbol}</div>
                    </div>
                    <div className={styles.infos}>
                        {props.data.map((d) => (
                            <div className={styles.info} key={d.projectName}>
                                <div className={styles.color} style={{ background: d.color === null ? Colors.blue : d.color }} />
                                <div className={classnames(styles.title, styles.project)}>{d.projectName === null ? 'N/A' : d.projectName}</div>
                                <div className={styles.title}>{d.actualValue}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

function processData(data: TData['data']) {
    const d = data;
    const d1 = new Array<TD2>();
    const d2 = new Array<TD2>();

    Object.keys(d).forEach((s) => {
        const r = d[s];
        trackMap.set(s, 0);
        d1.push({
            month: s,
            value: Math.abs(r.profitOrLoss),
            groupType: 'profitloss',
            seriesType: s + ' Profit Loss',
            projectId: null,
            projectName: null,
            color: null,
            actualValue: r.profitOrLoss
        });

        const revenue = r.revenue;
        if (Object.keys(revenue.project).length > 0) {
            Object.keys(revenue.project).forEach((t) => {
                const v = revenue.project[t];
                d2.push({
                    month: s,
                    value: v.revenue as number,
                    groupType: 'revenue',
                    seriesType: s + ' Revenue',
                    projectId: t,
                    projectName: v.name as string,
                    color: v.color as string,
                    actualValue: v.revenue as number
                });
            });
        } else {
            d2.push({ month: s, value: 0, groupType: 'revenue', seriesType: s + ' Revenue', projectId: null, projectName: null, color: null, actualValue: 0 });
        }
    });
    return {
        profitLoss: d1,
        revenue: d2
    };
}

function calculatePercentage(revenue: number, actualValue: number) {
    if (actualValue > 0) {
        const profitLoss = Math.abs(actualValue);
        const val = (profitLoss * 100) / revenue;
        return `${val.toFixed(2)}%`;
    } else {
        const profitLoss = Math.abs(actualValue);
        const val = (profitLoss * 100) / revenue;
        return `-${val.toFixed(2)}%`;
    }
}

function columnWidth(num: number) {
    if (num <= 8) return 30;
    else if (num <= 16) return 15;
    else return undefined;
}
