import React, { useEffect, useState } from 'react';

import { Button, Col, Divider, Row } from 'antd';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import { getUserDetails } from '../../redux/selectors/UserSelector';
import { useLocation } from 'react-router-dom';

const Reports = () => {
    const dividerClass = 'hp-border-color-black-40 hp-border-color-dark-80';

    const [selectedUserId, setSelectedUserId] = useState('');
    const user = useSelector((state: IStore) => getUserDetails(state, selectedUserId));

    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split('/');

    useEffect(() => {
        const pathSplit = pathname.split('/');
        const selectedId = pathSplit[pathSplit.indexOf('team') + 1];
        selectedId && setSelectedUserId(selectedId);
    }, [pathname])

    return (
        <div className='hp-profile-security'>
            <h2>Quick Links & Reports</h2>
            <p className='hp-p1-body hp-mb-0'>
                Track activities and performance with quick links and reports.
            </p>

            <Divider className={dividerClass} />

            <Row align='middle' justify='space-between'>
                <Col md={12}>
                    <h3>Quick Links</h3>
                    <p className='hp-p1-body hp-mb-0'>
                        Stay on top of your game with work activities.
                    </p>
                </Col>
            </Row>
                <Divider className={dividerClass} />

                <Row align='middle' justify='space-between'>
                    <Col md={12}>
                        <h3>Reports</h3>
                        <p className='hp-p1-body hp-mb-0'>
                            Drive productivity with instant performance reports.
                        </p>
                    </Col>
                </Row>
        </div>
);
}

export default Reports;
