import { Button, Col, Divider, Form, Input, Modal, Row, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import { getUserDetails } from '../../redux/selectors/UserSelector';
import { CloseOutlined } from '@ant-design/icons';
import styles from '../firmSettings/FirmSettings.scss';
import UserService from '../../services/UserService';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import { Countries, projectFeeTypes } from '../../constants/Options';
import Colors from '../../constants/Colors';
import Tag from '../../components/tag/Tag';
import TeamMemberSelector from '../../components/selector/TeamMemberSelector';
import ProjectService from '../../services/ProjectService';
import IClient from '../../interfaces/IClient';
import ColorPickerButton from '../../components/colorPicker/ColorPickerButton';

const { Option } = Select;

const GeneralTab = ({ project }) => {
    const listTitle = 'hp-p1-body';
    const listResult = 'hp-mt-sm-4 hp-p1-body hp-text-color-black-100 hp-text-color-dark-0';
    const dividerClass = 'hp-border-color-black-40 hp-border-color-dark-80';

    const [name, setName] = useState(project?.name || '');
    const [serial, setSerial] = useState(project?.serial || '');
    const [color, setColor] = useState(project?.color || '');
    const [projectType, setProjectType] = useState(project?.projectType || '');
    const [categories, setCategories] = useState(project?.categories.map(c => c.uuid) || []);
    const [note, setNote] = useState(project?.note || null);
    const [invoiceApproval, setInvoiceApproval] = useState(project?.invoiceApproval || false);

    const [building, setBuilding] = useState(project?.building || '');
    const [street, setStreet] = useState(project?.street || null);
    const [city, setCity] = useState(project?.city || null);
    const [state, setState] = useState(project?.state || null);
    const [country, setCountry] = useState(project?.country || null);
    const [zipCode, setZipCode] = useState(project?.zipCode || null);

    const [infoModalVisible, setInfoModalVisible] = useState(false);
    const [selectedMembers, setSelectedMembers] = useState([]);

    const _categories = useSelector((state: IStore) => state.options.project);
    const categoryMap = GenUtils.arrayToIdMap(_categories);
    const clients = useSelector((state: IStore) => state.client.clients);

    const contactModalShow = () => {
        setInfoModalVisible(true);
    };

    const contactModalCancel = () => {
        setInfoModalVisible(false);
    };

    useEffect(() => {
        if (!building && project.building !== building) setBuilding(project.building);
        if (!street && project.street !== street) setStreet(project.street);
        if (!city && project.city !== city) setCity(project.city);
        if (!state && project.state !== state) setState(project.state);
        if (!zipCode && project.zipCode !== zipCode) setZipCode(project.zipCode);
        if (!country && project.country !== country) setCountry(project.country);

        if (!name && project.name !== name) setName(project.name);
        if (!serial && project.serial !== serial) setSerial(project.serial);
        if (!color && project.color !== color) setColor(project.color);
        if (!projectType && project.type !== projectType) setProjectType(project.type);
        if (!categories && project.categories !== categories) setCategories(project.categorie.map(c => c.uuid));
        if (!note && project.note !== note) setNote(project.note);
        if (project.invoiceApproval !== invoiceApproval) setInvoiceApproval(project.invoiceApproval);

        if (project.managers?.length) {
            setSelectedMembers(project.managers);
        }
    }, [project]);

    const updateProject = async (detailsToUpdate) => {
        const { data, error } = (await ProjectService.updateProject(project.uuid, GenUtils.removeEmptyKeysFromObject(detailsToUpdate)));

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Update', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Successfully updated information', <notificationIcon.icon
            style={notificationIcon.style} />);
        contactModalCancel();
    };

    return (
        <div style={{ background: Colors.white, borderRadius: 10, padding: '20px 30px' }}>
            <Modal
                title='Edit Basic Information'
                width={600}
                centered
                open={infoModalVisible}
                onCancel={contactModalCancel}
                footer={null}
                closeIcon={
                    <CloseOutlined
                        style={{ color: 'IndianRed' }}
                        className='remix-icon text-color-black-100'
                        size={24} />
                }
            >
                <Form layout='vertical' name='basic' initialValues={{ remember: true }}>
                    <Col
                        span={24}
                        className='hp-profile-content-list hp-mt-8 hp-pb-sm-0 hp-pb-120'
                    >
                        <ul>
                            <li className='hp-mt-18'>
                                <span className={listTitle}>Name</span>
                                <Input value={name} color={name} onChange={e => setName(e.target.value)} />
                            </li>
                            <li className='hp-mt-18'>
                                <span className={listTitle}>Serial</span>
                                <Input value={serial} color={serial} onChange={e => setSerial(e.target.value)} />
                            </li>
                            <li className='hp-mt-18'>
                                <span className={listTitle}>Color</span>
                                <ColorPickerButton color={color} setColor={setColor} />
                            </li>
                            <li className='hp-mt-18'>
                                <span className={listTitle}>Project Type</span>
                                <Select
                                    placeholder='Select'
                                    className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                                    value={projectType}
                                    style={{ width: 300, border: 'none', justifyContent: 'space-between' }}
                                    onChange={setProjectType}
                                >
                                    {projectFeeTypes.map((type) => (
                                        <Option key={type.id} value={type.type}>
                                            {type.label}
                                        </Option>
                                    ))}
                                </Select>
                            </li>
                            <li className='hp-mt-18'>
                                <span className={listTitle}>Categories</span>
                                <Select
                                    placeholder='Select'
                                    mode='multiple'
                                    className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                                    value={categories}
                                    style={{ width: 300, border: 'none', justifyContent: 'space-between' }}
                                    onChange={setCategories}
                                >
                                    {
                                        _categories.map(c => <Option key={c.uuid} value={c.uuid}>{GenUtils.capitalizeInitials(c.name)}</Option>)
                                    }
                                </Select>
                            </li>
                            {/* <li className='hp-mt-18'>
                                <span className={listTitle}>Client</span>
                                <Select
                                    placeholder='Select'
                                    className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                                    value={client}
                                    style={{ width: 300, border: 'none', justifyContent: 'space-between' }}
                                    onChange={setClient}
                                >
                                    {
                                        clients && Object.values(clients).map((c: IClient) => <Option key={c.uuid} value={c.uuid}>{c.name}</Option>)
                                    }
                                </Select>
                            </li> */}
                            <li className='hp-mt-18'>
                                <div>
                                    <span className={listTitle}>Project Address</span>
                                    <div className={styles.row}>
                                        <Input
                                            placeholder='Street, avenue, etc.'
                                            type={'street'}
                                            value={street}
                                            onChange={(e) => {
                                                setStreet(e.target.value);
                                            }}
                                        />
                                        <Input
                                            placeholder='Suite, Unit, Apt, etc'
                                            type={'building'}
                                            value={building}
                                            onChange={(e) => {
                                                setBuilding(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className={styles.row}>
                                        <Input
                                            placeholder='City'
                                            type={'city'}
                                            value={city}
                                            onChange={(e) => {
                                                setCity(e.target.value);
                                            }}
                                        />
                                        <Input
                                            placeholder='State/Province/Region'
                                            type={'state'}
                                            value={state}
                                            onChange={(e) => {
                                                setState(e.target.value);
                                            }}
                                        />
                                        <Input
                                            placeholder='Zip/Postal Code'
                                            type={'zipCode'}
                                            value={zipCode}
                                            onChange={(e) => {
                                                setZipCode(e.target.value);
                                            }}
                                        />

                                    </div>
                                    <div className={styles.row}>
                                        <Form.Item
                                            rules={[{ required: true }]}>
                                            <Select
                                                showSearch
                                                placeholder='Country'
                                                style={{ width: 300 }}
                                                value={country}
                                                onChange={(value) => {
                                                    setCountry(value);
                                                }}
                                            >
                                                {
                                                    Countries.map(country => <option key={country} value={country}>{country}</option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                            </li>
                            <li className='hp-mt-18'>
                                <span className={listTitle}>Notes</span>
                                <Input.TextArea
                                    placeholder='leave a note..'
                                    className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                                    style={{ width: 300, justifyContent: 'space-between' }}
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                />
                            </li>
                        </ul>
                    </Col>
                    <Row style={{ marginTop: '-100px', justifyContent: 'space-between' }}>
                        <Col md={11} span={24} className='hp-mt-sm-12 hp-pl-sm-0 hp-pl-12'>
                            <Button block onClick={() => { }}>
                                Cancel
                            </Button>
                        </Col>
                        <Col md={11} span={24} className='hp-pr-sm-0 hp-pr-12'>
                            <Button
                                block
                                type='primary'
                                htmlType='submit'
                                onClick={() => updateProject({
                                    name,
                                    serial,
                                    color,
                                    type: projectType,
                                    categories,
                                    note,
                                        building,
                                        city,
                                        country,
                                        state,
                                        street,
                                        zipCode
                                })}
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Row align='middle' justify='space-between'>
                <Col md={12} span={24}>
                    <h3>Basic Information</h3>
                </Col>

                <Col md={12} span={24} className='hp-profile-action-btn hp-text-right'>
                    <Button type='primary' ghost onClick={contactModalShow}>
                        Edit
                    </Button>
                </Col>

                <Col
                    span={24}
                    className='hp-profile-content-list hp-mt-8 hp-pb-sm-0 hp-pb-120'
                >
                    <ul>
                        <li>
                            <span className={listTitle}>Name</span>
                            <span className={listResult}>{GenUtils.capitalizeInitials(project.name) || '-'}</span>
                        </li>
                        <li className='hp-mt-18'>
                            <span className={listTitle}>Number & Color</span>
                            <span className={listResult}><Tag color={project.color} text={project.serial} /></span>
                        </li>
                        <li className='hp-mt-18'>
                            <span className={listTitle}>Project Type</span>
                            <span className={listResult}>{GenUtils.capitalizeInitials(project.type) || '-'}</span>
                        </li>
                        <li className='hp-mt-18'>
                            <span className={listTitle}>Categories</span>
                            <span className={listResult}>{project.categories?.map(c => GenUtils.capitalizeInitials(categoryMap[c.uuid]?.name)).join(', ') || '-'}</span>
                        </li>
                        <li className='hp-mt-18'>
                            <span className={listTitle}>Client</span>
                            <span className={listResult}>{GenUtils.capitalizeInitials(clients[project.clientId]?.name) || '-'}</span>
                        </li>
                        <li className='hp-mt-18'>
                            <span className={listTitle}>Address</span>
                            <span className={listResult}>{
                                project.address ?
                                    <>{project.address.building}, {project.address.street}, {project.address.city}, <br />{project.address.state} -
                                        ({project.address.zipCode})<br />{project.address.country}</>
                                    : '-'
                            }
                            </span>
                        </li>
                        <li className='hp-mt-18'>
                            <span className={listTitle}>Notes</span>
                            <span className={listResult}>{note || '-'}</span>
                        </li>
                        <li className='hp-mt-18'>
                            <span className={listTitle}>Require Invoice Approval</span>
                            <Switch
                                checked={invoiceApproval}
                                onChange={v => {
                                    updateProject({ invoiceApproval: v });
                                    setInvoiceApproval(v);
                                }}
                            />
                        </li>
                    </ul>
                </Col>
            </Row>

            <Divider className={dividerClass} style={{ marginTop: '-80px' }} />
            <div>
                <Col md={12} span={24}>
                    <h3>Assigned Project Managers</h3>
                </Col>
                <p className='hp-p1-body' style={{ padding: '20' }}>
                    Assigned members will get Edit access to the project
                </p>
                <TeamMemberSelector
                    selectedMembers={selectedMembers}
                    setSelectedMembers={(m) => {
                        setSelectedMembers(m);
                        updateProject({ managers: m.map(member => member.uuid) });
                    }}
                    isMultiple
                    showSelected
                />
            </div>
        </div>
    );
};

export default GeneralTab;
