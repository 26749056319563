import { SketchPicker } from 'react-color';
import React, { useState } from 'react';
import Button from '../button/Button';


const ColorPicker = ({ color, setColor, onCancel }) => {

    const [_color, _setColor] = useState(color);

    const handleChange = c => {
        _setColor(c.hex);
    }

    return (
        <div style={{ marginTop: '20px', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
            <SketchPicker color={_color} onChangeComplete={handleChange} />
            <div style={{ gap: '20px', marginTop: '20px', display: 'flex' }}>
                <Button
                    block
                    type='primary'
                    onClick={() => setColor(_color)}
                    text='Save'
                />
                <Button block type='ghost' onClick={onCancel} text='Cancel' />
            </div>
        </div>
    );
};
export default ColorPicker;
