import { Menu } from 'antd';

import styles from './EditProjectStatus.scss';
import {
    CheckCircleFilled,
    ClockCircleFilled,
    PauseCircleFilled,
    PlayCircleFilled,
    StopOutlined
} from '@ant-design/icons';
import classnames from 'classnames';
import GenUtils from '../../utils/GenUtils';

const EditProjectStatus = ({ project, onClick }) => {

    const Item = ({ text, subText }) => {
        const isActive = project?.status === text;
        return (
            <div className={classnames(styles.item, isActive && styles.activeItem)}>
                <div className={styles.title}>{GenUtils.capitalizeFirstLetter(text)}</div>
                <div className={styles.subTitle}>{subText}</div>
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <span className={styles.projectName}>{project?.name}</span>
            <Menu style={{ width: 256 }} mode='vertical'>
                <Menu.Item key='proposed' icon={<ClockCircleFilled />} style={{ borderBottom: '1px solid lightgray' }}
                           onClick={() => onClick('proposed')}>
                    <Item text='proposed' subText='Visible to only users with Admin level Permissions' />
                </Menu.Item>
                <Menu.Item key='active' icon={<PlayCircleFilled />} style={{ borderBottom: '1px solid lightgray' }}
                           onClick={() => onClick('active')}>
                    <Item text='active' subText='Your team can track time against the Project' />
                </Menu.Item>
                <Menu.Item key='paused' icon={<PauseCircleFilled />} style={{ borderBottom: '1px solid lightgray' }}
                           onClick={() => onClick('paused')}>
                    <Item text='paused' subText='Freeze the schedule and prevent entering time in the future' />
                </Menu.Item>
                <Menu.Item key='canceled' icon={<StopOutlined />} style={{ borderBottom: '1px solid lightgray' }}
                           onClick={() => onClick('canceled')}>
                    <Item text='canceled' subText='Disable time tracking in the future and remove from gantt' />
                </Menu.Item>
                <Menu.Item key='completed' icon={<CheckCircleFilled />} onClick={() => onClick('completed')}>
                    <Item text='completed' subText='Disable any further time tracking in the future' />
                </Menu.Item>
            </Menu>
        </div>
    );
};

export default EditProjectStatus;
