import { useEffect, useState } from 'react'
import Table from '../../../components/table/custom/Table';
import FormatUtils from '../../../utils/FormatUtils';
import { IStore } from '../../../redux/store';
import { useSelector } from 'react-redux';
import Tag from '../../../components/tag/Tag';
import styles from './InvoiceStyle.scss';
import { IPhase } from '../../../interfaces/IPhase';
import GenUtils, { ENotificationType } from '../../../utils/GenUtils';
import CustomInput from '../../../components/input/CustomInput';
import { InvoiceService } from '../../../services/InvoiceService';
import { Popover } from 'antd';
import UnbilledDetails from '../UnbilledDetails';

const FixedFeeTab = ({ data, projectId }: any) => {
    const [_data, setData] = useState(data);
    const project = useSelector((state: IStore) => state.project.projects[projectId]);
    const phases = useSelector((state: IStore) => projectId && state.phases?.projectPhases && state.phases?.projectPhases[projectId] || {})

    useEffect(() => {
        setData(data);
    }, [data]);

    const PhaseDetail = ({ phaseId }) => {
        const phase: IPhase = phases && phases[phaseId] || {}

        return (
            <div className={styles.phaseDetail}>
                <div className={styles.titleContainer}>
                    <Tag text={phase.type?.abbreviation} color={project?.color} />
                    <div className={styles.title}>{GenUtils.capitalizeInitials(phase.type?.name)}</div>
                </div>
                {FormatUtils.getFormattedDateFromTimestamp(phase.startAt)} - {FormatUtils.getFormattedDateFromTimestamp(phase.endAt)}
            </div>
        )
    }

    const updateDetail = (detailId, detail) => {
        setData(
            _data
                .map(d => d.uuid === detailId
                    ? { ...d, ...detail }
                    : d
                )
        )
    }

    const updateInvoiceDetail = async (detailId, detail) => {
        updateDetail(detailId, detail);
        const { data, error } = await InvoiceService.updateInvoiceDetails(detailId, detail);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to draft', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }

        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Saved as draft', <notificationIcon.icon
            style={notificationIcon.style} />);
    }

    const columns = [
        {
            name: 'Phase',
            key: 'phase',
            span: 5,
            render: (d) => d.phase === 'Fixed Fee Services Total' ? d.phase : <PhaseDetail phaseId={d.phaseId} />
        },
        {
            name: 'Status',
            key: 'status',
            span: 3,
            render: (d) => d.phase === 'Fixed Fee Services Total' ? null : <div style={{ display: 'flex', height: '100%', alignItems: 'flex-start' }}>
                <Tag text={project?.status} color={GenUtils.getTagColorByStatus(project?.status)} />
            </div>
        },
        {
            name: 'Phase Fee',
            key: 'fee',
            span: 3,
            render: (d) => FormatUtils.getAmountInFormat(d.fee, true, 2, 0)
        },
        {
            name: 'Previously invoiced',
            key: 'invoiced',
            span: 4,
            render: (d) => `${FormatUtils.getAmountInFormat(d.invoiced, true, 2, '0')} / ${d.fee ? (d.invoiced * 100 / d.fee).toFixed(2) : 0}%`
        },
        {
            name: 'Unbilled',
            key: 'unbilled',
            span: 3,
            render: (d) => d.phase === 'Fixed Fee Services Total'
                ? FormatUtils.getAmountInFormat(d.unbilled, true, 2, '0')
                : <Popover content={<UnbilledDetails detail={d} prefix={FormatUtils.getCurrency()} />}>
                    {FormatUtils.getAmountInFormat(d.unbilled, true, 2, '0')}
                </Popover>
        },
        {
            name: 'Complete %',
            key: 'complete',
            span: 3,
            render: (d) => d.phase === 'Fixed Fee Services Total' ? null : <CustomInput
                value={d.complete}
                suffix='%'
                style={{ width: '90%' }}
                updateOnBlur
                onChange={complete => updateInvoiceDetail(d.uuid, { complete, amount: ((d.fee * complete) / 100) - d.invoiced })}
            />
        },
        {
            name: 'Current Due',
            key: 'due',
            span: 3,
            render: (d) => d.phase === 'Fixed Fee Services Total' ? FormatUtils.getAmountInFormat(d.due, true, 0, '0') : <CustomInput
                value={d.due}
                prefix={FormatUtils.getCurrency()}
                updateOnBlur
                onChange={amount => updateInvoiceDetail(d.uuid, { complete: (d.invoiced + amount) * 100 / d.fee, amount })}
            />
        }
    ];

    return (
        <Table
            data={[..._data, ...(_data.length > 0 ? [{
                phase: 'Fixed Fee Services Total',
                status: null,
                fee: _data?.reduce((acc, d) => acc + d.fee, 0),
                invoiced: _data?.reduce((acc, d) => acc + d.invoiced, 0),
                unbilled: _data?.reduce((acc, d) => acc + d.unbilled, 0),
                due: _data?.reduce((acc, d) => acc + d.due, 0),
            }] : [])] || []}
            columns={columns}
            lastRowBold
        />
    )
}

export default FixedFeeTab;