import { useEffect } from 'react';
import styles from './App.css';
import SideBarLayout from './components/sidebar/SideBarLayout';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import SignupPage from './pages/signup/SignupPage';
import { useDispatch } from 'react-redux';
import GenUtils from './utils/GenUtils';
import RecoverPassword from './pages/recover/RecoverPassword';
import UnauthenticatedPage from './pages/UnauthenticatedPage';
import './assets/less/yoda-theme.less';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';
import { direction, enLocaleConfig } from './constants/Config';
import LoginPage from './pages/login/LoginPage';
import VerifyScreen from './pages/auth/VerifyScreen';
import ForgotScreen from './pages/auth/ForgotScreen';

const App = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        GenUtils.init(dispatch);
    }, []);

    return (
        <ConfigProvider locale={enLocaleConfig.antd} direction={direction}>
            <IntlProvider locale={enLocaleConfig.locale} messages={enLocaleConfig.messages}>
                <div className={styles.App}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<UnauthenticatedPage />}>
                                <Route index element={<LoginPage />} /> {/* todo where to go when wrong route and unauthenticated */}
                                <Route path="/login" element={<LoginPage />} />
                                <Route path="/signup" element={<SignupPage />} />
                                <Route path="/verify" element={<VerifyScreen />} />
                                <Route path="/password" element={<ForgotScreen />} />
                                <Route path="/recover-password" element={<RecoverPassword />} />
                            </Route>
                            {/* shared side bar for all the pages */}
                            <Route path='/*' element={<SideBarLayout />}>
                                <Route index element={<Home />} />
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </div>
            </IntlProvider>
        </ConfigProvider>
    );
};

export default App;
