import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';

import { DatePicker, Input, Select, Steps } from 'antd';

import Button from '../../components/button/Button';
import HeaderSecondary from '../../components/header/HeaderSecondary';
import FormatUtils from '../../utils/FormatUtils';
import StepTabs from '../../components/tabs/StepTabs';
import NavigationUtils from '../../utils/NavigationUtils';
import HourlyTab from './InvoiceBuilderSteps/HourlyTab';
import ConsultantServicesTab from './InvoiceBuilderSteps/ConsultantServicesTab';
import ExpensesTab from './InvoiceBuilderSteps/ExpensesTab';
import OtherItemsTab from './InvoiceBuilderSteps/OtherItemsTab';
import FixedFeeTab from './InvoiceBuilderSteps/FixedFeeTab';
import { IStore } from '../../redux/store';

import styles from './InvoiceBuilder.scss';
import moment from 'moment';
import CustomInput from '../../components/input/CustomInput';
import { IOption } from '../../redux/reducers/OptionReducer';
import ITax from '../../interfaces/ITax';
import { InvoiceService } from '../../services/InvoiceService';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import { IInvoiceDetail } from '../../interfaces/IInvoice';
import Tag from '../../components/tag/Tag';
import MomentUtils from '../../utils/MomentUtils';
import InvoiceUtils, { EInvoiceDetailTypes } from '../../utils/InvoiceUtils';

const { RangePicker } = DatePicker;
const { Option } = Select;

const InvoiceBuilder = () => {
    const [hasHourlyData, setHasHourlyData] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);
    const [invoiceId, setInvoiceId] = useState(null);

    // const [clientName, setClientName] = useState('');
    // const [billingAddress, setBillingAddress] = useState('');
    // const [from, setFrom] = useState(moment());
    // const [to, setTo] = useState(moment());

    const [address, setAddress] = useState(null);
    const [note, setNote] = useState('');
    const [date, setDate] = useState(moment());
    const [term, setTerm] = useState(null);
    const [tax, setTax] = useState(null);
    const [dueDate, setDueDate] = useState(moment());
    const [footer, setFooter] = useState('');
    const [totals, setTotals] = useState({});

    const invoice = useSelector((state: IStore) => state.invoices?.invoices[invoiceId]);
    const terms = useSelector((state: IStore) => state?.options?.invoice_terms || []);
    const taxes = useSelector((state: IStore) => state?.taxes?.taxes || {});
    const projectsMap = useSelector((state: IStore) => state.project.projects);

    // Location
    const { pathname, search } = useLocation();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        setInvoiceId(searchParams.get('invoiceId'));

        if (invoice) {
            setAddress(invoice.address);
        }
    }, [searchParams, pathname])

    useEffect(() => {
        if (invoice) {
            setTotals(InvoiceUtils.calculateTotals(invoice));
        }
    }, [invoice])

    const tabs = [
        {
            title: 'Fixed Fee Services',
            subTitle: `Current Due: ${FormatUtils.getAmountInFormat(totals['fixed'], true, 2, 0)}`
        },
        ...(hasHourlyData
            ? [
                {
                    title: 'Hourly Services',
                    subTitle: `Current Due: ${FormatUtils.getAmountInFormat(totals['hourly'], true, 2, 0)}`
                }
            ]
            : []),
        {
            title: 'Consultant Services',
            subTitle: `Current Due: ${FormatUtils.getAmountInFormat(totals['consultant'], true, 2, 0)}`
        },
        {
            title: 'Expenses',
            subTitle: `Current Due: ${FormatUtils.getAmountInFormat(totals['expense'], true, 2, 0)}`
        },
        {
            title: 'Other Items',
            subTitle: `Current Due: ${FormatUtils.getAmountInFormat(totals['other'], true, 2, 0)}`
        }
    ];

    const next = () => {
        updateInvoice();
        NavigationUtils.navigateTo(pathname.replace('build', 'design') + search);
    };

    const updateInvoice = async () => {
        const { error } = await InvoiceService.updateInvoice(invoice.uuid, GenUtils.removeEmptyKeysFromObject({
            address: address || invoice.address || '',
            note: note || invoice.note || '',
            date: MomentUtils.getGmtDayStart(date) || invoice.date || '',
            termId: term || invoice.termId || '',
            dueDate: MomentUtils.getGmtDayStart(dueDate) || invoice.dueDate || '',
            taxId: tax || invoice.taxId || '',
            footer: footer || invoice.footer || '',
        }));

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to draft', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
    }

    const renderTable = () => {
        switch (activeIndex) {
            case 0:
                return <FixedFeeTab
                    data={InvoiceUtils.getInvoiceDetailsByType(invoice, EInvoiceDetailTypes.FIXED)}
                    {...invoice}
                />;
            case 1:
                return <HourlyTab
                    data={InvoiceUtils.getInvoiceDetailsByType(invoice, EInvoiceDetailTypes.HOURLY)}
                    {...invoice}
                />;
            case 2:
                return <ConsultantServicesTab
                    data={InvoiceUtils.getInvoiceDetailsByType(invoice, EInvoiceDetailTypes.CONSULTANT)}
                    {...invoice}
                />;
            case 3:
                return <ExpensesTab
                    data={InvoiceUtils.getInvoiceDetailsByType(invoice, EInvoiceDetailTypes.EXPENSE)}
                    {...invoice}
                />;
            case 4:
                return <OtherItemsTab
                    data={[...InvoiceUtils.getInvoiceDetailsByType(invoice, EInvoiceDetailTypes.OTHER), ...InvoiceUtils.getInvoiceDetailsByType(invoice, EInvoiceDetailTypes.DISCOUNT)]}
                    {...invoice}
                />;
            default:
                return <FixedFeeTab
                    data={InvoiceUtils.getInvoiceDetailsByType(invoice, EInvoiceDetailTypes.FIXED)}
                    {...invoice}
                />;
        }
    };

    return (
        <div className={styles.container}>
            <HeaderSecondary
                text={
                    <div style={{ display: 'flex', gap: 10 }}>
                        Invoice #{invoice?.serial} for <div style={{ flex: 0, fontSize: 14 }}>
                            <Tag
                                text={projectsMap[invoice?.projectId]?.serial}
                                color={projectsMap[invoice?.projectId]?.color}
                            />
                        </div> {projectsMap[invoice?.projectId]?.name} :
                        <Tag
                            style={{ fontSize: 14 }}
                            text={invoice?.status}
                            color={GenUtils.getTagColorByStatus(invoice?.status)}
                        />
                    </div>
                }
                renderAction={() => <Button text={'Next'} onClick={next} />}
            />
            <Steps size='small' current={0} className={styles.steps}
                items={[{ title: 'Builder' }, { title: 'Design' }, { title: 'Preview' }]} />
            <div className={styles.form}>
                <div className={styles.section}>
                    <div className={styles.field}>
                        <div className={styles.label}>Client Name</div>
                        <div className={styles.input}>
                            <Input type='text' value={GenUtils.capitalizeInitials(invoice?.client?.name)} disabled />
                        </div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.label}>Service Through</div>
                        <div className={styles.input}>
                            <RangePicker value={[moment(invoice?.fromDate), moment(invoice?.toDate)]} disabled />
                        </div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.label}>Billing Address</div>
                        <div className={styles.input}>
                            <Input.TextArea value={address || invoice?.address} onChange={(e) => setAddress(e.target.value)} />
                        </div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.label}>Note</div>
                        <div className={styles.input}>
                            <Input.TextArea value={note} onChange={(e) => setNote(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className={styles.section}>
                    <div className={styles.field}>
                        <div className={styles.label}>Billing Date</div>
                        <div className={styles.input}>
                            <DatePicker value={moment(date)} onChange={(e) => setDate(e)} />
                        </div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.label}>Terms</div>
                        <div className={styles.input}>
                            <Select
                                placeholder="Select Term"
                                value={term || invoice?.termId}
                                onChange={(e) => {
                                    setTerm(e);
                                }}>
                                {terms.map((term: IOption) => (
                                    <Option key={term.uuid} value={term.uuid}>
                                        {term.name}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.label}>Due Date</div>
                        <div className={styles.input}>
                            <DatePicker value={moment(dueDate)} onChange={(e) => setDueDate(e)} />
                        </div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.label}>Tax</div>
                        <div className={styles.input}>
                            <Select
                                placeholder="Select Tax"
                                value={tax || invoice?.taxId}
                                onChange={(e) => {
                                    setTax(e);
                                }}>
                                {Object.values(taxes).map((tax: ITax) => (
                                    <Option key={tax.uuid} value={tax.uuid}>
                                        {tax.name}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.label}>Footer</div>
                        <div className={styles.input}>
                            <Input.TextArea value={footer} onChange={(e) => setFooter(e.target.value)} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.totalContainer}>
                Total Due:&nbsp;&nbsp;
                <span>{FormatUtils.getAmountInFormat(InvoiceUtils.calculateTotals(invoice, EInvoiceDetailTypes.ALL), true, 2, 0)}</span>
            </div>
            <StepTabs
                tabs={tabs}
                activeIndex={activeIndex}
                onClick={(i) => {
                    setActiveIndex(i);
                    updateInvoice();
                }}
            />
            {renderTable()}
            <div style={{ marginTop: '15px', display: 'flex', justifyContent: 'end' }}>
                <Button
                    type='primary'
                    text={activeIndex >= 4 ? 'Next: Designer' : `Next: ${tabs[activeIndex + 1].title}`}
                    onClick={() => {
                        updateInvoice();
                        (activeIndex >= 4 ? NavigationUtils.navigateTo(pathname.replace('build', 'design') + search) : setActiveIndex(activeIndex + 1))
                    }}
                />
            </div>
        </div>
    );
};

export default InvoiceBuilder;
