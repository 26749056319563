import IPermission from '../../interfaces/IPermission';

export interface IPermissionState {
    permissions?: {},
    permissionsArray?: IPermission[];
}

export const initialPermissionState: IPermissionState = {
    permissions: {},
    permissionsArray: []
};

const permissionReducer = (state: IPermissionState, action: { type: string, payload  }) => {
    switch (action.type) {
        case 'SET_PERMISSIONS':
            return {
                ...state,
                ...action.payload
            };
        default:
            return state || initialPermissionState;
    }
};

export default permissionReducer;