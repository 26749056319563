export const setRoles = (roles) => {
    return {
        type: 'SET_OPTIONS',
        payload: {
            options: roles,
            type: 'role'
        }
    }
}

export const setAllOptions = (options) => {
    return {
        type: 'SET_ALL_OPTIONS',
        payload: options,
    }
}

export const setOptions = (options, type) => {
    return {
        type: 'SET_OPTIONS',
        payload: {
            options,
            type
        }
    }
}

export const updateOption = (uuid, option, type) => {
    return {
        type: 'UPDATE_OPTION',
        payload: {
            uuid,
            type,
            ...option
        },
    }
}