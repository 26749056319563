// @ts-nocheck
import React, { useState } from 'react';

import { Button, Checkbox, Col, Divider, Form, Input, Modal, Popconfirm, Row, Table } from 'antd';
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Colors from '../../constants/Colors';
import TimeOff from './TimeOff';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import OptionService from '../../services/OptionService';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';

const Expenses = () => {
    const columnClass = 'hp-p1-body hp-text-color-black-100 hp-text-color-dark-0';
    const dividerClass = 'hp-border-color-black-40 hp-border-color-dark-80';

    const [showExpenseCategoryModal, setExpenseCategoryModal] = useState(false);
    const [categoryName, setCategoryName] = useState('');
    const [isCategoryUpdating, setCategoryUpdating] = useState(false);

    const [showVendorModal, setVendorModal] = useState(false);
    const [vendorName, setVendorName] = useState('');
    const [isVendorUpdating, setVendorUpdating] = useState(false);

    const [showPaymentMethodModal, setPaymentMethodModal] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [isPaymentMethodUpdating, setPaymentMethodUpdating] = useState(false);
    const [clicked, setClicked] = useState(false);

    const { expense, vendor, payment_mode } = useSelector((state: IStore) => state.options);

    const createExpenseCategory = async () => {
        const { error, data } = await OptionService.createOption({
            name: categoryName,
            type: 'expense'
        });

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to create', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Created Successfully', <notificationIcon.icon
            style={notificationIcon.style} />);
    };

    const createVendor = async () => {
        const { error, data } = await OptionService.createOption({
            name: vendorName,
            type: 'vendor'
        });

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to create', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Created Successfully', <notificationIcon.icon
            style={notificationIcon.style} />);
    };

    const createPaymentMode = async () => {
        const { error, data } = await OptionService.createOption({
            name: paymentMethod,
            type: 'payment_mode'
        });

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to create', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Created Successfully', <notificationIcon.icon
            style={notificationIcon.style} />);
    };

    const updateExpenseCategory = async (uuid) => {
        const { error, data } = await OptionService.updateOption(uuid, { name: categoryName });

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to update', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Updated Successfully', <notificationIcon.icon
            style={notificationIcon.style} />);

        setCategoryUpdating(false);
        setCategoryName(null);
        setExpenseCategoryModal(false);
    };

    const updateVendor = async (uuid) => {
        const { error, data } = await OptionService.updateOption(uuid, { name: vendorName });

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to update', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Updated Successfully', <notificationIcon.icon
            style={notificationIcon.style} />);

        setVendorUpdating(false);
        setVendorName(null);
        setVendorModal(false);
    };

    const updatePaymentMode = async (uuid) => {
        const { error, data } = await OptionService.updateOption(uuid, { name: paymentMethod });

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to update', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Updated Successfully', <notificationIcon.icon
            style={notificationIcon.style} />);

        setPaymentMethodUpdating(false);
        setPaymentMethod(null);
        setPaymentMethodModal(false);
    };

    const deleteOption = async (uuid) => {
        const { error, data } = await OptionService.deleteOption(uuid);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to delete', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Deleted Successfully', <notificationIcon.icon
            style={notificationIcon.style} />);
    };

    const ExpenseCategoryColumns = [
        {
            title: 'Category Name',
            dataIndex: 'name',
            width: 200,
            render: (text) => <span className={columnClass}>{GenUtils.capitalizeFirstLetter(text)}</span>
        },
        {
            title: '',
            dataIndex: 'edit',
            align: 'right',
            render: (text, data) => {
                return (<div>
                    <a
                        onClick={() => {
                            setCategoryUpdating(data.uuid);
                            setCategoryName(data.name);
                            setExpenseCategoryModal(true);
                        }}
                    >Edit</a>
                    <Popconfirm
                        title='Are you sure to delete this expense category?'
                        onConfirm={() => deleteOption(data.uuid)}
                        okText='Yes'
                        cancelText='No'
                    >
                        <CloseOutlined style={{ color: 'IndianRed', marginLeft: 20 }} />
                    </Popconfirm>
                </div>);
            }
        }
    ];
    const VendorColumns = [
        {
            title: 'Vendor Name',
            dataIndex: 'name',
            width: 200,
            render: (text) => <span className={columnClass}>{GenUtils.capitalizeFirstLetter(text)}</span>
        },
        {
            title: '',
            dataIndex: 'edit',
            align: 'right',
            render: (text, data) => {
                return (<div>
                    <a
                        onClick={() => {
                            setVendorUpdating(data.uuid);
                            setVendorName(data.name);
                            setVendorModal(true);
                        }}
                    >Edit</a>
                    <Popconfirm
                        title='Are you sure to delete this expense category?'
                        onConfirm={() => deleteOption(data.uuid)}
                        okText='Yes'
                        cancelText='No'
                    >
                        <CloseOutlined style={{ color: 'IndianRed', marginLeft: 20 }} />
                    </Popconfirm>
                </div>);
            }
        }
    ];
    const PaymentMethodColumns = [
        {
            title: 'Payment Method',
            dataIndex: 'name',
            width: 200,
            render: (text) => <span className={columnClass}>{GenUtils.capitalizeFirstLetter(text)}</span>
        },
        {
            title: '',
            dataIndex: 'edit',
            align: 'right',
            render: (text, data) => {
                return (<div>
                    <a
                        onClick={() => {
                            setPaymentMethodUpdating(data.uuid);
                            setPaymentMethod(data.name);
                            setPaymentMethodModal(true);
                        }}
                    >Edit</a>
                    <Popconfirm
                        title='Are you sure to delete this expense category?'
                        onConfirm={() => deleteOption(data.uuid)}
                        okText='Yes'
                        cancelText='No'
                    >
                        <CloseOutlined style={{ color: 'IndianRed', marginLeft: 20 }} />
                    </Popconfirm>
                </div>);
            }
        }
    ];


    return (
        <div className='hp-profile-activity'>
            <Modal
                title={isCategoryUpdating ? 'Update Expense Category' : 'Create Expense Category'}
                width={1000}
                centered
                visible={showExpenseCategoryModal}
                onCancel={() => {
                    setCategoryName('');
                    setCategoryUpdating(false);
                    setExpenseCategoryModal(false);
                }}
                footer={null}
                closeIcon={
                    <CloseOutlined
                        style={{ color: Colors.red }}
                        className='remix-icon text-color-black-100'
                        size={24} />
                }
            >
                <Form layout='vertical' name='basic' initialValues={{ remember: true }}>
                    <Form.Item label='Category Name'>
                        <Input value={categoryName}
                               onChange={e => setCategoryName(e.target.value)} />
                    </Form.Item>
                    <Form.Item label='' name='checkbox'>
                        <Checkbox onChange={(e) => setClicked(e.target.checked)}
                        >This expense has a unit
                            price</Checkbox>
                    </Form.Item>
                    {clicked ? <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: 300,
                            marginBottom: 20
                        }}>
                        <span>$</span> <Input placeholder='0.585' type={'number'} /> <span>per</span> <Input
                        placeholder={'mile'} />
                    </div> : null}


                    <Button
                        text='Save'
                        type={'primary'}
                        onClick={() => {
                            setExpenseCategoryModal(false);
                            isCategoryUpdating ? updateExpenseCategory(isUpdating) : createExpenseCategory();
                        }
                        }
                    > Save</Button>
                </Form>
            </Modal>
            <Modal
                title={isVendorUpdating ? 'Update Vendor' : 'Create Vendor'}
                width={500}
                centered
                visible={showVendorModal}
                onCancel={() => {
                    setVendorName('');
                    setVendorUpdating(false);
                    setVendorModal(false);
                }}
                footer={null}
                closeIcon={
                    <CloseOutlined
                        style={{ color: Colors.red }}
                        className='remix-icon text-color-black-100'
                        size={24} />
                }
            >
                <Form layout='vertical' name='basic' initialValues={{ remember: true }}>
                    <Form.Item style={{ display: 'flex', paddingTop:20}}>
                        <span className='hp-p1-body' style={{ paddingRight:20 }}>Vendor Name</span>
                        <Input value={vendorName}
                               style={{ width: 330 }}
                               onChange={e => setVendorName(e.target.value)}
                        />
                    </Form.Item>
                    <Button
                        text='Save'
                        type={'primary'}
                        onClick={() => {
                            setVendorModal(false);
                            isVendorUpdating ? updateVendor(isVendorUpdating) : createVendor();
                        }
                        }
                    > Save</Button>
                </Form>
            </Modal>
            <Modal
                title={isPaymentMethodUpdating ? 'Update Payment Method' : 'Create Payment Method'}
                width={500}
                centered
                visible={showPaymentMethodModal}
                onCancel={() => {
                    setPaymentMethod('');
                    setPaymentMethodUpdating(false);
                    setPaymentMethodModal(false);
                }}
                footer={null}
                closeIcon={
                    <CloseOutlined
                        style={{ color: Colors.red }}
                        className='remix-icon text-color-black-100'
                        size={24} />
                }
            >
                <Form layout='vertical' name='basic' initialValues={{ remember: true }}>
                    <Form.Item style={{ display: 'flex', paddingTop:20}}>
                        <span className='hp-p1-body' style={{ paddingRight:20 }}>Payment Method</span>
                        <Input value={paymentMethod}
                               style={{ width: 310 }}
                               onChange={e => setPaymentMethod(e.target.value)} />
                    </Form.Item>
                    <Button
                        text='Save'
                        type={'primary'}
                        onClick={() => {
                            setPaymentMethodModal(false);
                            isPaymentMethodUpdating ? updatePaymentMode(isPaymentMethodUpdating) : createPaymentMode();
                        }}
                    > Save</Button>
                </Form>
            </Modal>
            <Col md={15} span={24}>
                <h2>Expenses</h2>
            </Col>

            <Row align='middle' justify='space-between'>
                <Col md={12} span={24}>
                    <h4>Expense Categories</h4>
                    <Button
                        onClick={() => setExpenseCategoryModal(true)}
                        icon={<PlusCircleOutlined style={{ marginRight: 10 }} />}
                        type="primary"
                    > Add Expense Category</Button>
                </Col>
            </Row>
            <Table
                className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                columns={ExpenseCategoryColumns}
                dataSource={expense}
                style={{ marginTop: '20px' }}
                size='middle'
                pagination={false}
            />
            <Divider className={dividerClass} />
            <Row align='middle' justify='space-between'>
                <Col md={12} span={24}>
                    <h4>Vendor</h4>
                    <Button
                        onClick={() => setVendorModal(true)}
                        icon={<PlusCircleOutlined style={{ marginRight: 10 }} />}
                        type="primary"
                    > Add Vendor</Button>
                </Col>
            </Row>
            <Table
                className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                columns={VendorColumns}
                dataSource={vendor}
                style={{ marginTop: '20px' }}
                size='middle'
                pagination={false} ds
            />
            <Divider className={dividerClass} />
            <Row align='middle' justify='space-between'>
                <Col md={12} span={24}>
                    <h4>Payment Method</h4>
                    <Button
                        onClick={() => setPaymentMethodModal(true)}
                        icon={<PlusCircleOutlined style={{ marginRight: 10 }} />}
                        type="primary"
                    >Add Payment Method</Button>
                </Col>
            </Row>
            <Table
                className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                columns={PaymentMethodColumns}
                dataSource={payment_mode}
                style={{ marginTop: '20px' }}
                size='middle'
                pagination={false} ds
            />
            <Divider className={dividerClass} />

            {/*<Row align='middle' justify='space-between'>*/}
            {/*    <Col md={12} span={24}>*/}
            {/*        <h4>Overhead Costs</h4>*/}
            {/*    </Col>*/}
            {/*</Row>*/}

            {/*<Divider className={dividerClass} />*/}

            {/*<Row align='middle' justify='space-between'>*/}
            {/*    <Col md={12} span={24}>*/}
            {/*        <h4>leave List</h4>*/}
            {/*    </Col>*/}
            {/*</Row>*/}

            {/*<Divider className={dividerClass} />*/}

            <Row align='middle' justify='space-between'>
                <Col md={24} span={24}>
                    <h4>Paid TimeOff </h4>
                    <TimeOff />
                </Col>
            </Row>

        </div>
    );
};
export default Expenses;
