import GenUtils from '../../utils/GenUtils';
import { IPhase } from '../../interfaces/IPhase';

export const updatePhase = (...phaseDetails) => {
  return {
    type: 'UPDATE_PHASE',
    payload: [{
      ...phaseDetails,
    }],
  }
}

export const setPhases = (phases: IPhase[], projectId: string) => {
  return {
    type: 'SET_PHASES',
    payload: {
      phases: GenUtils.arrayToIdMap(phases),
      phasesArrayForProject: phases,
      projectId,
    },
  }
}