import { Card, Col, Modal, Row } from 'antd';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TaskService from '../../services/TaskService';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import Tag from '../../components/tag/Tag';
import FormatUtils, { EDateTimeFormat } from '../../utils/FormatUtils';
import CreateTask from './CreateTask';
import Button from '../../components/button/Button';
import Buttons from '../../components/button/Button';
import { RiCloseFill } from 'react-icons/ri';
import HeaderSecondary from '../../components/header/HeaderSecondary';
import styles from './TaskDetail.scss';
import TeamMemberSelector from '../../components/selector/TeamMemberSelector';
import { ECommunicationType } from '../../redux/reducers/CommunicationReducer';
import CommunicationCardMessage from '../../components/card/CommunicationCardMessage';
import Colors from '../../constants/Colors';
import { PlusCircleOutlined } from '@ant-design/icons';
import { communicationSelector } from '../../redux/selectors/CommunicationSelector';
import TaskDetailCard from './TaskDetailCard';

const TaskDetail = () => {
    const listTitle = 'hp-p1-body';
    const listResult = 'hp-mt-sm-4 hp-p1-body hp-text-color-black-100 hp-text-color-dark-0';

    const [addTaskModal, setAddTaskModal] = useState(false);
    const [editTaskModal, setEditTaskModal] = useState(false);
    const [selectedTaskId, setSelectedTaskId] = useState(null);

    const [projectName, setProjectName] = useState('');
    const [phase, setPhase] = useState('');
    const [taskType, setTaskType] = useState('');
    const [teamMembers, setTeamMembers] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [description, setDescription] = useState('');

    const task = useSelector((store: IStore) => selectedTaskId && store.tasks.tasks[selectedTaskId] || {});
    const phaseTypes = useSelector((state: IStore) => state.options.phase);
    const tasksArray = useSelector((state: IStore) => state.tasks.tasksArray || []);
    const { pathname } = useLocation();

    useEffect(() => {
        fetchTasks();
    }, []);

    const fetchTasks = () => {
        TaskService.fetchTasks();
    };

    useEffect(() => {
        const pathSplit = pathname.split('/');
        const selectedId = pathSplit[pathSplit.indexOf('task') + 1];
        if (selectedId) {
            setSelectedTaskId(selectedId);
        }
    }, [pathname]);

    const onComplete = async () => {
        const error = (await TaskService.updateTask(task.uuid, {status: 'completed'})).error;

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }
    }

    const renderTask = () => {
        return tasksArray.map(taask => <TaskDetailCard task={taask} selectTask={setSelectedTaskId} id={task.uuid}/>);
    };
    const deleteTask = async (taskId) => {
        const { data, error } = await TaskService.deleteTask(taskId);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification(
                'Failed to delete',
                error,
                <notificationIcon.icon style={notificationIcon.style} />
            );
            return;
        }

        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification(
            'Task deleted successfully',
            '',
            <notificationIcon.icon style={notificationIcon.style} />
        );
    };
    return (
        <div className={styles.container}>
            <Modal
                title='Add New Task'
                width={500}
                centered
                open={addTaskModal}
                onCancel={() => setAddTaskModal(false)}
                footer={null}
                closeIcon={<RiCloseFill className='remix-icon text-color-black-100' size={24} />}
                destroyOnClose={true}
                >
                <CreateTask
                    hideModal={() => setAddTaskModal(false)}
                    onSuccess={() => {
                        setAddTaskModal(false);
                    }}
                />
            </Modal>
            <Modal
                title='Edit Task Details'
                width={500}
                centered
                open={editTaskModal}
                onCancel={() => setEditTaskModal(false)}
                destroyOnClose={true}
                footer={null}
                closeIcon={<RiCloseFill className='remix-icon text-color-black-100' size={24} />}
                >
                <CreateTask
                    hideModal={() => setEditTaskModal(false)}
                    onSuccess={() => setEditTaskModal(false)}
                    task={task}
                />
            </Modal>
          
            <div className={styles.tasksContainer} style={{marginLeft:"-16px",maxWidth:"350px",overflowY:"scroll",height:"100%"}}>
                <Card style={{ borderRadius:"0px",height:"110vh"}} className={styles.antbody}>
                    <div className={styles.AllTasksHeader}>
                        <Col>
                            <h3>Tasks</h3>
                        </Col>
                        <Col className='hp-profile-action-btn hp-text-right'>
                            <Buttons onClick={() => setAddTaskModal(true)} text={'New'}
                                     icon={<PlusCircleOutlined style={{ marginRight: 10 }} />} />
                        </Col>
                    </div>
                    {
                       renderTask()
                    }
                </Card>
            </div>
            <div className={styles.details}>
                <HeaderSecondary
                    text={task?.name}
                    renderAction={() => (
                        <Row style={{gap:10}}>
                            <Button
                                onClick={() => deleteTask(selectedTaskId)}
                                text='Delete'
                                type={'default'}
                            />
                            {task.status === 'completed' ?  <Tag text={'Completed'} color='lightgreen' />
                                :<Button
                                onClick={onComplete}
                                style={{ whiteSpace: 'nowrap' }}
                                text={'Mark as Complete'}
                            />}
                        </Row>
                    )}

                />
                <Card
                    className={styles.detailCard}
                >
                    <div
                        style={{flex:1}}
                        className='hp-profile-content-list hp-mt-8 hp-pb-sm-0'
                    >
                        <ul style={{display: 'flex', flexDirection: 'column'}} >
                            <li>
                                <span className={listTitle}>Project</span>
                                <span className={listResult}
                                      style={{ display: 'flex', gap: 10 }}>{task?.project?.name}
                                    <Tag text={task?.project?.serial} color={task?.project?.color} /></span>
                            </li>
                            <li className='hp-mt-18'>
                                <span className={listTitle}>Phase</span>
                                <span className={listResult}>
                                        <Tag color={task?.project?.color}
                                             text={phaseTypes.find(type => type.uuid === task.phase?.typeId)?.abbreviation} />
                                    </span>
                            </li>
                            <li className='hp-mt-18'>
                                <span className={listTitle}>Due Date</span>
                                <span
                                    className={listResult}>{FormatUtils.getDateTimeInFormat(task?.date, EDateTimeFormat.HH_MM_a_day)}</span>
                            </li>
                            <li className='hp-mt-18'>
                                <span className={listTitle}>Task Type</span>
                                <span className={listResult}>{task?.type?.name}</span>
                            </li>
                            <li className='hp-mt-18'>
                                <span className={listTitle}>Description</span>
                                <span
                                    className={listResult}>{GenUtils.capitalizeFirstLetter(task?.description)}</span>
                            </li>
                        </ul>
                    </div>
                    <Button onClick={() => setEditTaskModal(true)} text='Edit'  type={'default'} />
                </Card>
                <Card>
                    <div className={styles.column}>
                        <div style={{fontSize: 18, paddingBottom: 10, borderBottom: '1px solid', borderColor: Colors.grayLighter}}>Assignees</div>
                        <TeamMemberSelector
                            selectedMembers={task?.assignees || []}
                            setSelectedMembers={(m) => {
                            }}
                            isMultiple
                            showSelected
                            hideInput
                            style={{ width: '100%' }}
                        />
                    </div>
                </Card>
                <CommunicationCardMessage type={ECommunicationType.task} uuid={task.uuid} />
            </div>
        </div>
    );
};
export default TaskDetail;
