import ITask from '../../interfaces/ITask';

export interface ITaskState {
    tasks: { [key: string]: ITask };
    tasksArray: ITask[];
    loading: boolean;
}

export const initialTaskState: ITaskState = {
    tasks: {},
    tasksArray: [],
    loading: false
};

const taskReducer = (state: ITaskState, action: { type: string; payload: ITask; loading: boolean }) => {
    switch (action.type) {
        case 'SET_TASKS':
            return {
                ...state,
                ...action.payload,
                loading: action.loading
            };
        case 'UPDATE_TASK':
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    [action.payload.uuid]: {
                        ...state.tasks[action.payload.uuid],
                        ...action.payload,
                    },
                },
            };
        default:
            return state || initialTaskState;
    }
};

export default taskReducer;
