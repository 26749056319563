import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'antd';
import { IStore } from '../../redux/store';
import { Outlet, useLocation } from 'react-router-dom';
import { RiCloseFill } from 'react-icons/ri';
import { FlagOutlined, ProfileOutlined, TransactionOutlined } from '@ant-design/icons';

import styles from './InvoicesPage.scss';
import Style from '../expenses/ExpensesPage.scss';

import Button from '../../components/button/Button';
import HeaderSecondary from '../../components/header/HeaderSecondary';
import AddInvoice from './AddInvoice';
import Tabs from '../../components/tabs/Tabs';
import NavigationUtils from '../../utils/NavigationUtils';
import PermissionUtils from '../../utils/PermissionUtils';

import Invoices from '../../assets/images/invoices.svg';

const includeArr = ['/retainers', '/build', '/design', '/preview'];

const doesInclude = (s: string) => {
    for (let i = 0; i < includeArr.length; i++) {
        if (s.includes(includeArr[i])) {
            return true;
        }
    }
    return false;
};

const InvoicesPage = () => {
    const { pathname, state: locationState } = useLocation();
    const includeHeader = doesInclude(pathname);
    const [showCreateInvoice, setShowCreateInvoice] = useState(false);
    const [activeRoute, setActiveRoute] = useState('All Invoices');
    const firmId = useSelector((state: IStore) => state?.firm?.uuid);

    useEffect(() => {
        setShowCreateInvoice(locationState?.openAddInvoice || false);
    }, [locationState]);

    useEffect(() => {
        const splitLocation = pathname.split('/');
        const _activeRoute = splitLocation[splitLocation.indexOf('invoices') + 1] || 'all';
        if (activeRoute !== _activeRoute) setActiveRoute(_activeRoute);
    }, [pathname]);

    return (
        <>
            {!includeHeader ? (
                <div className={styles.container}>
                    <HeaderSecondary
                        renderImage={() => <img src={Invoices} alt="invoice" width={70} />}
                        text={'Invoices'}
                        subtext={'Streamline billing with customizable, professional invoices.'}
                        renderAction={() =>
                            PermissionUtils.canCreateInvoices() ? <Button text={'Create Invoice'} onClick={() => setShowCreateInvoice(true)} /> : null
                        }
                    />
                    <div className={Style.container}>
                        <Tabs
                            items={[
                                {
                                    key: 'all',
                                    name: 'All Invoices',
                                    icon: <ProfileOutlined style={{ fontSize: '16px' }} />
                                },
                                {
                                    key: 'un-billed',
                                    name: 'Unbilled',
                                    icon: <FlagOutlined />
                                },
                                // {
                                //     key: 'retainers',
                                //     name: 'Retainers',
                                //     icon: <FlagOutlined />
                                // },
                                {
                                    key: 'payments',
                                    name: 'All Payments',
                                    icon: <TransactionOutlined style={{ fontSize: '16px' }} />
                                }
                            ]}
                            onTabClick={(key) => {
                                NavigationUtils.navigateTo(`/${firmId}/invoices/${key}`);
                            }}
                            activeTab={activeRoute}
                        />
                        <Outlet />
                    </div>
                    <Modal
                        title="Create Invoice"
                        width={600}
                        centered
                        visible={showCreateInvoice}
                        onCancel={() => setShowCreateInvoice(false)}
                        footer={null}
                        closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}>
                        <AddInvoice onHide={() => setShowCreateInvoice(false)} />
                    </Modal>
                </div>
            ) : (
                <div className={styles.container}>
                    <Outlet />
                </div>
            )}
        </>
    );
};

export default InvoicesPage;
