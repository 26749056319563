import ITask from '../interfaces/ITask';
import { APIUtil } from '../utils/ApiUtils';
import ApiURLs from '../constants/ApiURLs';
import GenUtils from '../utils/GenUtils';
import { setTasks, updateTask } from '../redux/actions/TaskActions';

export default class TaskService {
    static async createTask(task: ITask | any) {
        const { data, error } = await APIUtil.makePostRequest(`${ApiURLs.CREATE_TASK}`, task);

        if (error) {
            GenUtils.log('createTask', error);
            return { error };
        }

        await TaskService.fetchTasks();
        return { data };
    }

    static async fetchTasks() {
        const isLoading = true;
        GenUtils.dispatch(setTasks([], isLoading));
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.FETCH_TASKS}`);

        if (error) {
            GenUtils.log('fetchTasks', error);
            return { error };
        }

        GenUtils.dispatch(setTasks(data, !isLoading));
        return { data };
    }

    static async updateTask(taskId, task) {
        GenUtils.dispatch(updateTask(taskId, task));

        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.UPDATE_TASK}/${taskId}`, task);

        if (error) {
            GenUtils.log('updateTask', error);
            return { error };
        }

        await TaskService.fetchTasks();
        return { data };
    }

    static async deleteTask(taskId) {
        const { data, error } = await APIUtil.makeDeleteRequest(`${ApiURLs.DELETE_TASK}/${taskId}`);

        if (error) {
            GenUtils.log('deleteTask', error);
            return { error };
        }

        await TaskService.fetchTasks();
        return { data };
    }
}
