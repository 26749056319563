import React from 'react';
import styles from './ProfileMenuItem.scss';

const ProfileMenuItem = ({text, icon, link, onClick}: {text: string, icon?: any, link?: string, onClick?: () => void}) => {
    return (
        <div
            className={styles.container}
            onClick={onClick || (() => {
                window.open(link, '_blank');
            })}
        >
            {
                icon ? (
                    <div className={styles.icon}>
                        {icon}
                    </div>
                ): null
            }
            <div className={styles.text}>{text}</div>
        </div>
    );
}

export default ProfileMenuItem;