import { logout } from "../redux/actions/AuthActions";
import AuthService from "../services/AuthService";
import GenUtils from "./GenUtils";
import NavigationUtils from "./NavigationUtils";
import StorageUtils from "./StorageUtils";

export default class AppUtils {
    static setTitle({ pathname }) {
        const split = pathname.split('/');
        const title = GenUtils.isUuid(split[1]) ? split[2] : split[1];
        document.title = title ? `${GenUtils.capitalizeFirstLetter(title)} - ResourcePlan` : 'ResourcePlan';
    }

    static logOut() {
        StorageUtils.clearStorage();
        GenUtils.dispatch(logout())
        NavigationUtils.navigateTo('/login')
    }

    static async onLogin(info, dispatch) {
        if (!info) {
            return;
        } else if (!info.accessToken) {
            NavigationUtils.navigateTo('/verify', {state: {email: info.email}});
        }
        StorageUtils.saveAuthTokenToLocalStorage(info.accessToken);
        StorageUtils.saveUserDetailsToStorage(info.owner);
        info.firms && StorageUtils.saveFirmIdToStorage(info.firms[0].uuid);
        await AuthService.checkAuth();
        GenUtils.init(dispatch);
    }
}
