import { Divider } from 'antd';
import GenUtils from '../../../../utils/GenUtils';
import styles from './ProjectTimeSheetHeader.module.scss';
import Tag from '../../../../components/tag/Tag';
const ProjectTimeSheetHeader = ({ project }) => {
    return (
        <div className={styles.headerRow}>
            <div className={styles.header}>
                <Tag color={project.color} text={project.serial} />
                {GenUtils.capitalizeInitials(project.name)}
                <Tag color={GenUtils.getTagColorByStatus(project.status)} text={GenUtils.capitalizeInitials(project.status)} />
            </div>
            <Divider className={styles.marginPadding0} />
        </div>
    );
};

export default ProjectTimeSheetHeader;
