import { ILeave } from '../../interfaces/ILeaves';

export const setLeaves = (leaves: ILeave[]) => {
    let payload = { balance: {}, record: {} };
    leaves.forEach((leave) => {
        if (leave.category === 'balance') {
            payload = {
                ...payload,
                balance: {
                    ...payload.balance,
                    [leave.teamMemberId]: payload.balance[leave.teamMemberId] ? [...payload.balance[leave.teamMemberId], leave] : [leave]
                }
            };
        }
        if (leave.category === 'record') {
            payload = {
                ...payload,
                record: {
                    ...payload.record,
                    [leave.teamMemberId]: payload.record[leave.teamMemberId] ? [...payload.record[leave.teamMemberId], leave] : [leave]
                }
            };
        }
    });

    return {
        type: 'SET_LEAVES',
        payload
    };
};
