import { AuditOutlined, CaretRightOutlined, PauseCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Row, Col, Select, Popover, Popconfirm, TimePicker } from 'antd';
import classNames from 'classnames';
import FocusFormattedInput from '../../../components/input/FocusedFormattedInput';
import TimeDifference from '../../../components/time/TimeDefference';
import FormatUtils from '../../../utils/FormatUtils';
import NotePopOver from '../NotePopOver';
import EmptyComponent from './EmptyComponent';
import styles from './styles.scss';
import { useSelector } from 'react-redux';
import { IStore } from '../../../redux/store';
import GenUtils from '../../../utils/GenUtils';
import Tag from '../../../components/tag/Tag';
import Colors from '../../../constants/Colors';
import _ from 'lodash';
import moment from 'moment';

export const ProjectCell = ({ project }) => {
    return (
        <div className={styles.projectCell} style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', gap: 10 }}>
                <Tag color={project?.color} text={project?.serial} />
                <Tag color={GenUtils.getTagColorByStatus(project?.status)} text={GenUtils.capitalizeFirstLetter(project?.status)} />
            </div>
            {GenUtils.capitalizeFirstLetter(project?.name)}
        </div>
    );
};

export const PhaseCell = ({ phase, project, roles, roleId }) => {
    return (
        <div className={styles.phaseCell} style={{ display: 'flex', flexDirection: 'column' }}>
            <Tag color={project?.color} text={phase?.type?.abbreviation} />
            {roles && roles[roles?.findIndex((role) => role.uuid === roleId)] ? (
                GenUtils.capitalizeFirstLetter(roles[roles.findIndex((role) => role.uuid === roleId)].name)
            ) : (
                <div style={{ color: Colors.orangeRed }}>No Role Assigned</div>
            )}
        </div>
    );
};

const ProjectDayTimesheetGrid = ({ timeRows, updateTimeSheet, addRow, deleteRow, runningTimerIndex }) => {
    const phaseActivitiesObj = useSelector((state: IStore) => state.timeLogs.projectsData.phaseActivitiesObj);
    const projectsMap = useSelector((state: IStore) => state.project.projects);
    const { isLocked } = useSelector((state: IStore) => state.timeLogs.timesheet);
    const userTimesheet = useSelector((state: IStore) => state.timeLogs.timesheet);
    const state = useSelector((state: IStore) => state);
    const mapped = state.options.activity

    return (
        <>
            {timeRows?.map((row, index) => {
                const activities = phaseActivitiesObj[row.phase?.uuid];
                const { project, roleId } = row;
                const roles = projectsMap[project.uuid]?.roles;
                return (
                    <Row
                        className={
                            index === timeRows.length - 1
                                ? classNames(styles.dataRow, styles.lastRow, row.status === 'rejected' && styles.rejectCell)
                                : classNames(styles.dataRow, row.status === 'rejected' && styles.rejectCell)
                        }
                        key={index + 'key'}>
                        <Col span={4} className={styles.dataColumns}>
                            <ProjectCell project={project} />
                        </Col>
                        <Col span={4} className={styles.dataColumns}>
                            <PhaseCell phase={row.phase} project={project} roles={roles} roleId={roleId} />
                        </Col>
                        <Col span={4} className={styles.dataColumns}>
                            <Select
                                style={{ width: '100%' }}
                                disabled={isLocked && row.status !== 'rejected'}
                                value={row.activityId}
                                onChange={(e) => updateTimeSheet('activityId', index, e)}>
                                {mapped.map((activity) => (
                                    <Select.Option value={activity.uuid} key={activity.uuid}>
                                        {activity.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={2} className={styles.dataColumns}>
                            <Popover
                                trigger={['click']}
                                content={
                                    <NotePopOver
                                        disabled={isLocked && row.status !== 'rejected'}
                                        value={row.note}
                                        onChange={(value) => _.throttle(() => updateTimeSheet(`note`, index, value), 3000)}
                                    />
                                }>
                                <AuditOutlined />
                            </Popover>
                        </Col>
                        <Col span={3} className={styles.timeColumn}>
                            {row.seconds && !row.startAt && !row.endAt ? null : (
                                <TimePicker
                                    value={row.startAt ? moment(row.startAt) : null}
                                    onChange={(j) => updateTimeSheet('startAt', index, moment(j).toISOString())}
                                    className={styles.timePicker}
                                    disabled={isLocked && row.status !== 'rejected'}
                                />
                            )}
                        </Col>
                        <Col span={3} className={styles.timeColumn}>
                            {row.seconds && !row.startAt && !row.endAt ? null : (
                                <TimePicker
                                    value={row.endAt ? moment(row.endAt) : null}
                                    onChange={(j) => updateTimeSheet('endAt', index, moment(j).toISOString())}
                                    className={styles.timePicker}
                                    disabled={isLocked && row.status !== 'rejected'}
                                />
                            )}
                        </Col>
                        <Col span={2} className={styles.dataColumns} style={{ paddingRight: 10, paddingLeft: 10 }}>
                            {row.startAt && row.startAt ? (
                                <TimeDifference startTime={row.startAt} endTime={row.endAt} />
                            ) : (
                                <FocusFormattedInput
                                    originalData={row.seconds / 3600}
                                    // onChange={(value) => updateTimeSheet('seconds', index, value * 3600)}
                                    formattedData={FormatUtils.formatTime(row.seconds)}
                                    disabled={isLocked && row.status !== 'rejected'}
                                    onChange={() => {}}
                                />
                            )}
                        </Col>
                        <Col span={2} className={styles.lastColumn}>
                            {(!row.startAt || (row.startAt && row.endAt)) && (
                                <CaretRightOutlined
                                    className={styles.playButton}
                                    onClick={() => {
                                        if (isLocked && row.status !== 'rejected') return;
                                        if ((row.startAt && row.endAt && row.seconds) || (row.seconds && !row.startAt && !row.endAt)) {
                                            addRow({ activity: row.activityId, phaseId: row.phaseId }, new Date());
                                        } else {
                                            if (runningTimerIndex) updateTimeSheet('endAt', runningTimerIndex, new Date());
                                            updateTimeSheet('startAt', index, new Date());
                                        }
                                    }}
                                />
                            )}
                            {row.startAt && !row.endAt && (
                                <PauseCircleOutlined
                                    className={classNames(styles.playButton, styles.pauseButton)}
                                    onClick={() => updateTimeSheet('endAt', index, new Date())}
                                />
                            )}
                            <Popconfirm
                                title="Are you sure? Deleting this row permanently remove logged hours for the activity."
                                onConfirm={() => deleteRow(row.uuid)}
                                okText="Yes"
                                cancelText="No">
                                <CloseOutlined style={{ color: 'IndianRed', marginLeft: 10 }} />
                            </Popconfirm>
                        </Col>
                    </Row>
                );
            })}
        </>
    );
};

export default ProjectDayTimesheetGrid;
