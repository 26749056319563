import GenUtils from '../../utils/GenUtils';
import StorageUtils from '../../utils/StorageUtils';

export const setCurrentFirm = (firmId, firmData = null) => {
    StorageUtils.saveFirmIdToStorage(firmId);
    return {
        type: 'SET_FIRM',
        payload: firmData ? {firmData} : firmId,
    }
}

export const setFirms = (firms) => {
    return {
        type: 'SET_FIRMS',
        payload: {
            firms: GenUtils.arrayToIdMap(firms),
            firmsArray: firms,
        },
    }
}