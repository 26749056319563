import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import { Dropdown, Modal, Popconfirm, Tag } from 'antd';
import ExpandableRowTable from '../../components/table/ExpandableRowTable';
import Buttons from '../../components/button/Button';
import { RiCloseFill } from 'react-icons/ri';
import AddTeam from './AddTeam';
import EditableCellsTable from '../../components/table/EditableCellsTable';
import { CheckCircleOutlined, EditOutlined, MoreOutlined, SyncOutlined, PlusCircleOutlined } from '@ant-design/icons';
import HeaderSecondary from '../../components/header/HeaderSecondary';
import TeamService from '../../services/TeamService';

import GenUtils, { ENotificationType } from '../../utils/GenUtils';

import { getUserDetails } from '../../redux/selectors/UserSelector';
import { IStore } from '../../redux/store';

import styles from './AllTeamsPage.scss';
import { IMember } from '../../redux/reducers/TeamReducer';
import Team from '../../assets/images/team.svg';
import NavigationUtils from '../../utils/NavigationUtils';
import Colors from '../../constants/Colors';
import Avvvatars from 'avvvatars-react';
import { useLocation } from 'react-router-dom';
import { EFilterTypes, EFilterCategory } from '../../components/filterBars/FiltersBarMultiselect';
import GroupedTable from '../../components/table/groupedTable/GroupedTable';
import TeamAvatar from '../../components/avatar/TeamAvatar';


const AllTeamsPage = () => {
    const { state: locationState } = useLocation();
    const firmId = useSelector((state: IStore) => state?.firm?.uuid);
    const memberArrays = useSelector((state: IStore) => Object.values(state.team.members));
    const user = useSelector((state: IStore) => getUserDetails(state));
    const [showModal, setModal] = useState(false);

    const memberArray = memberArrays.map((value) => ({ ...value, teamMemberId: value.uuid }));


    useEffect(() => {
        fetchClients();
    }, []);

    useEffect(() => {
        setModal(locationState?.openAddMember || false);
    }, [locationState]);
    const fetchClients = () => {
        TeamService.fetchTeamDetails();
    };

    const deleteMember = async (memberId) => {
        const { data, error } = await TeamService.deleteMember(memberId);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Delete', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Successfully deleted member', <notificationIcon.icon
            style={notificationIcon.style} />);
    };

    const updateMemberStatus = async (memberId, status) => {
        const { data, error } = await TeamService.updateUserDetails(memberId, { status });

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification(`Failed to update`, error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Successfully updated', <notificationIcon.icon
            style={notificationIcon.style} />);

    };

    const getMenuItems = (member: IMember) => [
        {
            key: 0,
            label: (<div>
                <Popconfirm
                    title='Are you sure to delete this compensation?'
                    onConfirm={() => deleteMember(member.uuid)}
                    okText='Yes'
                    cancelText='No'
                >
                    Delete
                </Popconfirm>
            </div>)
        }, {
            key: 1,
            label:
                <span
                    onClick={
                        () => updateMemberStatus(
                            member.uuid,
                            member.status === 'active' ? 'inactive' : 'active'
                        )
                    }
                >
                    {member.status === 'active' ? 'Deactivate' : 'Activate'}
                </span>
        }
    ];

    const goToUser = (id) => {
        NavigationUtils.navigateTo(`/${firmId}/team/${id}`);
    }

    const goToUserAccess = (id) => {
        NavigationUtils.navigateTo(`/${firmId}/team/${id}/access`);
    }

    const columns = [
        {
            title:'Member',
            dataIndex:'uuid',
            type:'member'
        },
        {
            title: 'Default Role',
            dataIndex: 'role',
            key: 'role',
            render: (text, member) => member.email !== user.email
                ? <>{
                    user.permission?.name?.toLowerCase() === 'admin' && !GenUtils.isEmpty(member.role?.hourRate)
                        ? `${member.role?.name} - ${member.role?.hourRate}/hour`
                        : `${member.role ? member.role?.name : 'admin'}`} {
                        user.permission?.name?.toLowerCase() === 'admin' &&
                        <div onClick={() => goToUserAccess(member.uuid)}><EditOutlined /></div>
                    }</>
                : 'You'
        },
        {
            title: 'Mobile Number',
            dataIndex: 'contact',
            key: 'contact',
            render: (text) => text || '-'
        },
        { title: 'Email', dataIndex: 'email', key: 'email' }
    ];

    const adminColumns = [
        {
            title: 'Permissions',
            dataIndex: 'permission',
            key: 'permission',
            render: (permission) => <>{GenUtils.capitalizeFirstLetter(permission?.name)}</>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => getStatusTag(text),
            sorter: (a, b) => a.status.localeCompare(b.status)
        },
        {
            title: 'Onboarding',
            dataIndex: 'onboarding',
            key: 'onboarding',
            render: (text, data) => getOnboardingButton(data)
        },
        {
            title: '',
            dataIndex: 'menu',
            key: 'menu',
            render: (text, member) => member.email !== user.email && <Dropdown menu={{ items: getMenuItems(member) }}
                trigger={['click']}><MoreOutlined /></Dropdown>
        }
    ];

    if (user.permission?.name?.toLowerCase() === 'admin') {
        columns.push(...adminColumns);
    }

    const contactColumns = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Designation',
            dataIndex: 'designation'
        },
        {
            title: 'Email',
            dataIndex: 'email'
        },
        {
            title: 'Contact',
            dataIndex: 'contact'
        }
    ];

    const filters = [
        {
            name: 'Member',
            dataIndex: 'teamMemberId',
            type: EFilterTypes.dropdown,
            category: EFilterCategory.simple,
        },
        {
            name: 'Status',
            dataIndex: 'status',
            type: EFilterTypes.dropdown,
            category: EFilterCategory.simple,
        },
        {
            name: 'Default Role',
            dataIndex: 'role.name',
            type: EFilterTypes.dropdown,
            category: EFilterCategory.advance,
        },
        {
            name: 'Permission',
            dataIndex: 'permission.name',
            type: EFilterTypes.dropdown,
            category: EFilterCategory.advance,
        }
    ]

    const addTeam = () => {
        setModal(true);
    };

    const getOnboardingButton = ({ uuid, status, firstName }) => {
        switch (status.toLowerCase()) {
            case 'active':
                return <a style={{ cursor: 'default' }}><CheckCircleOutlined /> Complete</a>;
            case 'inactive':
                return <a className='ant-dropdown-link' onClick={() => updateMemberStatus(uuid, 'active')}><SyncOutlined spin /> Reactivate</a>;
            case 'invited':
                return <a style={{ cursor: 'default' }} onClick={() => sendInvite(uuid, firstName)} >Resend</a>;
            case 'not_invited':
            default:
                return <a className='ant-dropdown-link' onClick={() => sendInvite(uuid, firstName)}>Invite</a>;
        }
    };

    const getStatusTag = (status) => {
        const text = status === 'not_invited' ? 'Not Invited' : status;

        return (
            <Tag color={GenUtils.getTagColorByStatus(status)}>{GenUtils.capitalizeFirstLetter(text)}</Tag>);
    };

    const sendInvite = async (uuid, firstName) => {
        const { data, error } = await TeamService.sendInvitation(uuid);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification(`Failed to invite`, error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }

        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('User Invited', `Invitation sent to ${user}`, <notificationIcon.icon
            style={notificationIcon.style} />);
    };

    const renderContacts = ({ contacts }) => {
        if (!contacts?.length) {
            return null;
        }

        return (
            <EditableCellsTable
                columns={contactColumns}
                dataSource={contacts}
            />
        );
    };

    return (
        <div>
            <Modal
                title='Add Member'
                width={800}
                centered
                style={{ margin: '20px' }}
                open={showModal}
                onCancel={() => setModal(false)}
                footer={null}
                destroyOnClose={true}
                closeIcon={
                    <RiCloseFill className='remix-icon text-color-black-100' size={24} />
                }
            >
                <AddTeam hideModal={() => setModal(false)} onSuccess={() => {
                    setModal(false);
                    fetchClients();
                }} />
            </Modal>
            <div className={styles.headerContainer}>
                <HeaderSecondary
                    text='Team members'
                    subtext={'Manage your team for effective collaboration.'}
                    renderImage={() => <img src={Team} alt='expence' width={70} />}
                />
                <Buttons onClick={addTeam} text={'New Member'} icon={<PlusCircleOutlined style={{ marginRight: "8px" }} />} />
            </div>
            <GroupedTable
                columns={columns}
                data={memberArray}
                filters={filters}
                renderTable={(columns, data) => (
                    <ExpandableRowTable
                        columns={columns}
                        data={data}
                        renderExpandedRow={renderContacts}
                        expandable={false}
                    />
                )}
            />
        </div>
    );
};

export default AllTeamsPage;
