import React, { useEffect, useState } from 'react';

import { Layout, Row } from 'antd';
import { motion } from 'framer-motion';
import AccountSelector from './AccountSelector';
import SidebarMenu from './SidebarMenu';
import CollapseButton from './CollapseButton';
import styles from './Sidebar.scss';
import { useSelector } from 'react-redux';
import { getUserDetails } from '../../redux/selectors/UserSelector';
import Colors from '../../constants/Colors';
import classnames from 'classnames';
import MenuItem from './MenuItem';

const { Sider } = Layout;

const Sidebar = () => {
    const [collapsed, setCollapsed] = useState(false);
    const loggedInUser = useSelector(getUserDetails);

    const handleResize = () => {
        if (window.innerWidth < 1200) {
            setCollapsed(true);
        } else {
            setCollapsed(false);
        }
    }

    useEffect(() => {
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, [])
    

    const data = SidebarMenu(collapsed);
    return (
        <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            width={220}
            style={{ background: Colors.navBackground }}
            className='hp-sidebar hp-border-right-1 hp-border-color-black-40 hp-border-color-dark-80'
        >
            <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ type: 'spring', duration: 0.5, delay: 0.1 }}
                className={styles.menuContainer}
            >
                <Row align='bottom' justify='space-between' className={styles.accountSelectorContainer}>
                    <AccountSelector collapsed={collapsed} />
                </Row>
                <div className={
                    classnames(
                        styles.menu,
                        collapsed && styles.menuCollapsed
                    )}>
                    {data.map((item, i) => <MenuItem
                        key={i}
                        onClose={() => null}
                        item={item}
                        collapsed={collapsed}
                    />)}
                </div>
                <CollapseButton collapsed={collapsed} setCollapsed={setCollapsed} />
            </motion.div>
        </Sider>
    );
};

export default Sidebar;
