import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { IStore } from "../../../redux/store";
import ReportService from "../../../services/ReportService"
import styles from './Charts.scss';
import Colors from '../../../constants/Colors';
import FormatUtils from '../../../utils/FormatUtils';
import classnames from 'classnames';
import { Mix } from '@ant-design/plots';
import style from './Forecast.scss'
import { Select, DatePicker } from "antd";
import GenUtils from "../../../utils/GenUtils";
import moment from "moment";

const { RangePicker } = DatePicker;


export default function HiringForecast() {
    const [data, setData] = useState("")
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const { role } = useSelector((state: IStore) => state.options);
    const firm = useSelector((state: IStore) => state.firm.firm);
    const dateFormat = firm.dateFormat?.toUpperCase()
    const [roleId, setRoleId] = useState("")
    async function GetHiringForecast(roleId, from, to) {
        const data = await ReportService.hiringForecast(roleId, from, to)
        setData(data.data?.finalObj)
    }

    useEffect(() => {
        setRoleId(role[0]?.uuid)
        GetHiringForecast(role[0]?.uuid, from, to)
    }, [role])

    useEffect(() => {
        if (roleId) {
            GetHiringForecast(roleId, from, to)
        }
        else {
            GetHiringForecast(role[0]?.uuid, from, to)
        }
    }, [from,to,roleId])


    const lineData = Object.keys(data).flatMap((item) =>
    ({
        month: item.replace("_", " "),
        capacity: data[item].capacity || 0
    })
    )
    const projectInfo: any[] = [];
    Object.keys(data).flatMap((key) =>
        Object.values(data[key])?.map((item) => {
            if (typeof (item) !== "number") {
                projectInfo.push(
                    {
                        month: key.replace("_", " "),
                        projectData: item,
                    }
                )
            }
        }
        )
    )

    const columnData = projectInfo.map((item) => ({
        month: item.month,
        budgetedHours: item.projectData.budgetedHours,
        color: item.projectData.projectColor,
        projectName: item.projectData.projectName
    }))

    // var max = columnData.reduce((max, entry) => {
    //     return Math.max(max, entry.budgetedHours);
    // }, 0);
      {/* @ts-ignore */}
    const max = Math.ceil([...lineData, ...Object.values(columnData.reduce((obj, d) => ({
        ...obj,
        [d.month]: {
            capacity: obj[d.month] ? (obj[d.month].capacity || 0) + d?.budgetedHours : d?.budgetedHours,
        }
    }), {})
)].reduce((m, d) =>
      Object(d).capacity > m ? Object(d).capacity : m, 20) * 1.1
   );
    
    const colors = columnData.reduce((obj, d) => ({ ...obj, [d.color]: d.projectName }), {});

    const config = {
        plots: [
            {
                type: 'column',
                options: {
                    data: columnData,
                    xField: 'month',
                    yField: 'budgetedHours',
                    seriesField: 'projectName',
                    isStack: true,
                    color: Object.keys(colors),
                    yAxis: {
                        max,
                    },
                    style: {
                        borderRadius: '10px',
                        overflow: 'hidden',
                    }
                }
            },
            {
                type: 'line',
                options: {
                    data: lineData,
                    color: Colors.primary,
                    // smooth: true,
                    xField: 'month',
                    yField: 'capacity',
                    stepType: 'hvh',
                    yAxis: {
                        max,
                    },
                    // legend: true,
                }
            },
        ],
        tooltip: {
            shared: true,
            domStyles: {
                'g2-tooltip': {
                    width: '200px',
                    padding: 0,
                    borderRadius: '10px',
                    overflow: 'hidden',
                    opacity: 1,
                },
            },
            customContent: (title, items) => <Popover datas={items} title={title} />,
        },
    };

    return (
        <>
           {
            role[0]?.name && 
            <div className={style.container}>
                    <RangePicker 
                        defaultValue={[moment().subtract(30, 'days'),moment()]}
                        onChange={(values) => {
                            if (values && values.length === 2) {
                            setFrom(values[0].toISOString());
                            setTo(values[1].toISOString());
                        }
                    }} 
                    format={dateFormat}
                     />
                <Select
                    style={{ width: 230 }}
                    onSelect={(e) => setRoleId(e)}
                    defaultValue={GenUtils.capitalizeInitials(role[0]?.name)}
                    allowClear
                    filterOption={(inputValue, option) => option.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())}
                    showSearch>
                    {role.map((option) => (
                        <Select.Option value={option['uuid']}>{GenUtils.capitalizeInitials(option['name'])}</Select.Option>
                    ))}
                </Select>
        </div>
           }
            {/* @ts-ignore */}
            <Mix {...config} />
        </>
    )
}
const Popover = ({ datas, title }) => {
    const capacity = datas.find(d => d.name === 'capacity')?.data?.capacity || 0;
    const planned = datas.filter(d => d.name !== 'capacity').reduce((acc, d) => acc + d.data.budgetedHours, 0);

    const infos = [
        {
            title: 'Capacity',
            value: FormatUtils.getAmountInFormat(capacity, false, 2, 0) + ' Hrs',
            color: Colors.primary,
        }, {
            title: 'Planned',
            value: FormatUtils.getAmountInFormat(planned, false, 2, 0) + ' Hrs',
        }, {
            title: 'Planned %',
            value: FormatUtils.getAmountInFormat(capacity ? planned * 100 / capacity : 100, false, 2, 0) + ' %',
        }, {
            title: 'Availability',
            value: FormatUtils.getAmountInFormat((capacity - planned), false, 2, 0) + ' Hrs',
            color: planned > capacity ? Colors.red : Colors.black,
        }
    ];

    return (
        <div className={styles.popoverContainer}>
            <div className={styles.header}>
                {title}
            </div>
            <div className={styles.body}>
                <div className={styles.infos}>
                    {
                        infos.map(info => (
                            <div className={styles.info} key={info.title}>
                                <div className={styles.title}>
                                    {info.title}
                                </div>
                                <div className={styles.title} style={info.color ? { color: info.color } : {}}>
                                    {info.value}
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className={styles.table}>
                    <div className={styles.header}>
                        <div className={styles.title}>
                            Project
                        </div>
                        <div className={styles.title}>
                            Hours
                        </div>
                    </div>
                    <div className={styles.infos}>
                        {
                            datas.filter(d => d.name !== 'capacity').map(({ data }) => (
                                <div className={styles.info} key={data.projectName}>
                                    <div className={styles.color} style={{ background: data.color }} />
                                    <div className={classnames(styles.title, styles.project)}>
                                        {data.projectName}
                                    </div>
                                    <div className={styles.title}>
                                        {FormatUtils.getAmountInFormat(data.budgetedHours, false, 2, 0)}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}