import { Divider, Form, Input, Modal, Select, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import Button from '../../components/button/Button';

import styles from './CreateProject.scss';
import ProjectService from '../../services/ProjectService';
import { IStore } from '../../redux/store';
import { useSelector } from 'react-redux';
import IClient from '../../interfaces/IClient';
import NavigationUtils from '../../utils/NavigationUtils';
import ColorPicker from '../../components/colorPicker/ColorPicker';
import { CloseOutlined } from '@ant-design/icons';
import ColorPickerButton from '../../components/colorPicker/ColorPickerButton';
import FormatUtils from '../../utils/FormatUtils';
import { projectFeeTypes } from '../../constants/Options';

const { Option } = Select;
const { Title } = Typography;

const CreateProject = ({ hideModal, onSuccess }) => {
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [color, setColor] = useState('');
    const [template, setTemplate] = useState('');
    const [feeType, setFeeType] = useState(projectFeeTypes[0].type);
    const [projectFee, setProjectFee] = useState(0);
    const [consultantFee, setConsultantFee] = useState(0);
    const [selectedClientId, setSelectedClientId] = useState('');

    const clients = useSelector((state: IStore) => state?.client.clients);
    const firmId = useSelector((state: IStore) => state?.firm?.uuid);

    const dividerClass = 'hp-border-color-black-40 hp-border-color-dark-80';

    const templates = [
        {
            id: 1,
            type: 'template1'
        },
        {
            id: 2,
            type: 'template2'
        }
    ];

    useEffect(() => {}, []);

    const submitForm = async () => {
        const { data, error } = await ProjectService.createProject({
            name,
            type: feeType,
            serial: number,
            color,
            clientId: selectedClientId,
            consultantFee,
            projectFee
        });
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }

        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Project Created Successfully', <notificationIcon.icon style={notificationIcon.style} />);
        NavigationUtils.navigateTo(`${firmId}/projects/${data.uuid}/edit`);
        hideModal();
    };
    const disableSubmitBtn = () => {
        return !selectedClientId || !name;
    };
    return (
        <div className={styles.container}>
            <form>
                <div className={styles.column}>
                    <Form.Item label="Client" className={styles.margin0} colon={false}>
                        {' '}
                    </Form.Item>
                    <Select
                        placeholder="Select Client"
                        value={selectedClientId}
                        onChange={(e) => {
                            setSelectedClientId(e);
                        }}>
                        {Object.values(clients).map((client: IClient) => (
                            <Option key={client.uuid} value={client.uuid}>
                                {client.name}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <Form.Item label="Name" className={styles.margin0} colon={false}>
                            {' '}
                        </Form.Item>
                        <Input
                            placeholder="Name"
                            type={'name'}
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <Form.Item label="Number" className={styles.margin0} colon={false}>
                            {' '}
                        </Form.Item>
                        <Input
                            placeholder="0"
                            value={number}
                            onChange={(e) => {
                                setNumber(e.target.value);
                            }}
                        />
                    </div>
                    <div className={styles.column}>
                        <Form.Item label="Color" className={styles.margin0} colon={false}>
                            {' '}
                        </Form.Item>
                        <ColorPickerButton color={color} setColor={setColor} />
                    </div>
                </div>
                {/* todo implement project templates */}

                {/*<div className={styles.row}>*/}
                {/*    <div className={styles.column}>*/}
                {/*        <Form.Item label="Template" className={styles.margin0}></Form.Item>*/}
                {/*        <Select*/}
                {/*            placeholder="Select Template"*/}
                {/*            value={template}*/}
                {/*            onChange={(e) => {*/}
                {/*                setTemplate(e);*/}
                {/*            }}>*/}
                {/*            {templates.map((template) => (*/}
                {/*                <Option key={template.id} value={template.id}>*/}
                {/*                    {template.type}*/}
                {/*                </Option>*/}
                {/*            ))}*/}
                {/*        </Select>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className={styles.row}>
                    <div className={styles.column}>
                        <Form.Item label="Fee Type" className={styles.margin0} colon={false}>
                            {' '}
                        </Form.Item>
                        <Select
                            placeholder="Select Type"
                            value={feeType}
                            onChange={(e) => {
                                setFeeType(e);
                            }}>
                            {projectFeeTypes.map((type) => (
                                <Option key={type.id} value={type.type}>
                                    {type.label}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div className={styles.column}> </div>
                </div>
                <Divider className={dividerClass} />
                <div className={styles.row}>
                    <div className={styles.column}>
                        <Form.Item label="Service Fee" className={styles.margin0} colon={false}>
                            {' '}
                        </Form.Item>
                    </div>
                    <div className={classnames(styles.column, styles.marginBottom20)}>
                        <Input
                            placeholder="Service Fee"
                            type={'number'}
                            prefix={FormatUtils.currencyIso}
                            value={projectFee}
                            onChange={(e) => {
                                setProjectFee(Number(e.target.value));
                            }}
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <Form.Item label="Consultant Fee" className={styles.margin0} colon={false}>
                            {' '}
                        </Form.Item>
                    </div>
                    <div className={classnames(styles.column, styles.marginBottom20)}>
                        <Input
                            placeholder="Consultant Fee"
                            type={'number'}
                            prefix={FormatUtils.currencyIso}
                            value={consultantFee}
                            onChange={(e) => {
                                setConsultantFee(Number(e.target.value));
                            }}
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <Title level={4}>Total Contract Budget</Title>
                    </div>
                    <div className={styles.column}>
                        <Title level={4} style={{ textAlign: 'right' }}>
                            {FormatUtils.getAmountInFormat(projectFee + consultantFee, true, undefined, 0)}
                        </Title>
                    </div>
                </div>
            </form>
            <div className={classnames(styles.row, styles.center)}>
                <Button type={'ghost'} text="Cancel" onClick={hideModal} />
                <Button
                    text="Add"
                    disable={disableSubmitBtn()}
                    onClick={() => {
                        submitForm();
                    }}
                />
            </div>
            {/*<div>{JSON.stringify(clients)}</div>*/}
        </div>
    );
};
export default CreateProject;
