import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Popconfirm, Select, Table } from 'antd';
import { IStore } from '../../redux/store';

import styles from './TeamMemberSelector.scss';
import { ColumnsType } from 'antd/es/table';
import TeamAvatar from '../avatar/TeamAvatar';
import { IMember } from '../../redux/reducers/TeamReducer';
import { CloseOutlined } from '@ant-design/icons';

const TeamMemberSelector = ({
    selectedMembers,
    setSelectedMembers,
    showSelected = false,
    isMultiple = false,
    width = 300,
    disabled = false,
    hideInput = false,
    style = {},
}) => {
    const { members, membersArray } = useSelector((state: IStore) => state?.team);
    const [_selectedMembers, _setSelectedMembers] = useState(selectedMembers);

    useEffect(() => {
        _setSelectedMembers(selectedMembers);
    }, [selectedMembers]);

    const removeAssignedMember = (memberId) => {
        const s = _selectedMembers.filter(member => member.uuid !== memberId);
        setSelectedMembers && setSelectedMembers(s,false);
        _setSelectedMembers(s);
    };

    const columns: ColumnsType = [
        {
            title: 'Member',
            dataIndex: 'label',
            render: (text, member: IMember) => <TeamAvatar image={member.profilePicUrl}
                name={`${member.firstName} ${member.lastName}`} />
        },
        {
            title: 'Default Role',
            dataIndex: 'role',
            render: (text, member: IMember) => members[member.uuid]?.role?.name
        },
        ...(
            hideInput ? [{}] : [
                {
                    title: '',
                    key: 'action',
                    render: (_, member: IMember, index) => (
                        <Popconfirm title='Sure to remove?' onConfirm={() => removeAssignedMember(member.uuid)}>
                            <CloseOutlined style={{ color: 'IndianRed', marginLeft: 20 }} />
                        </Popconfirm>
                    )
                }]
        )
    ];

    const selectMember = useCallback(
        (selectedMember) => {
            const s = selectedMember;
            setSelectedMembers && setSelectedMembers(s);
            _setSelectedMembers(s);
        },
        [setSelectedMembers, _setSelectedMembers]
    );

    return (
        <div className={styles.container} style={style}>
            {!hideInput && (
                <Select
                    showSearch
                    mode={isMultiple ? 'multiple' : null}
                    placeholder='Select Team Member'
                    style={{ width: 300 }}
                    onChange={(selectedId) => {
                        selectMember(isMultiple ? selectedId.map((id) => members[id]) : members[selectedId]);
                    }}
                    optionLabelProp='label'
                    value={isMultiple ? selectedMembers.uuid : selectedMembers}
                    disabled={disabled}
                >
                    {membersArray
                        .filter((member) => {
                            if (member.status !== 'active') {
                                return false;
                            }
                            if (selectedMembers) {
                                return isMultiple
                                    ? !_selectedMembers.find(m => m.uuid === member.uuid)
                                    : selectedMembers.uuid !== member?.uuid;
                            }
                            return true;
                        })
                        .map((member) => {
                            return (
                                <Select.Option
                                    showSearch
                                    key={member.uuid}
                                    value={member.uuid}
                                    label={`${member.firstName} ${member.lastName}`}>
                                    <div className={styles.optionContainer}>
                                        <div style={{ display: 'flex', flexDirection: 'row', width: '400px' }}>
                                            {/*// className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'>*/}
                                            <TeamAvatar
                                                image={member.profilePicUrl}
                                                name={`${member.firstName} ${member.lastName}`}
                                                role={member.permission?.name}
                                                disableClick
                                            />
                                            {/*<Checkbox> </Checkbox>*/}
                                        </div>
                                    </div>
                                </Select.Option>
                            );
                        })}
                </Select>
            )}
            {
                showSelected && _selectedMembers &&
                <Table
                    className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                    columns={columns}
                    dataSource={(isMultiple ? _selectedMembers : [_selectedMembers]).map(m => {
                        return { ...m, key: m.uuid };
                    })}
                    pagination={false}
                />
            }
        </div>
    );
};

export default TeamMemberSelector;
