import { useSelector } from 'react-redux';
import { IStore } from '../../../redux/store';
import ProjectTimeSheet from '../ProjectTimeSheet';

const TimesheetEntries = ({ weekCount, updateTimeLogData, addActivityRow, weekRange, onSave }) => {
    const timeLogs = useSelector((state: IStore) => state.timeLogs);
    const { timesheet: userTimesheet, projectsData } = timeLogs;
    const { projects } = userTimesheet;
    const projectKeys = Object.keys(projects ?? {});

    return (
        <>
            {projectKeys?.map((projectKey) => {
                const project = projectsData.projectsObj[projectKey];
                return (
                    <ProjectTimeSheet
                        key={project?.uuid}
                        project={project}
                        weekCount={weekCount}
                        updateTimeLogData={updateTimeLogData}
                        addActivityRow={addActivityRow}
                        weekStart={weekRange[0]}
                        onSave={onSave}
                    />
                );
            })}
        </>
    );
};

export default TimesheetEntries;
