import IPayment from '../../interfaces/IPayment';
import GenUtils from '../../utils/GenUtils';

export const setPayments = (payments: IPayment[]) => {
    return {
        type: 'SET_PAYMENTS',
        payload: GenUtils.arrayToIdMap(payments),
    };
};

export const updatePayment = (paymentId, payment: IPayment) => {
    return {
        type: 'UPDATE_PAYMENT',
        payload: { uuid: paymentId, ...payment }
    };
};