import IReport from '../../interfaces/IReport';
import GenUtils from '../../utils/GenUtils';

export const setReports = (reports: IReport[]) => {
    return {
        type: 'SET_REPORTS',
        payload: {
            reports: GenUtils.arrayToIdMap(reports),
            reportsArray: reports,
        },
    };
};

export const updateReport = (reportId: string, report: IReport) => {
    return {
        type: 'UPDATE_REPORT',
        payload: { uuid: reportId, ...report }
    };
};
