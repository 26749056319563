import ApiURLs from '../constants/ApiURLs';
import { APIUtil } from '../utils/ApiUtils';
import GenUtils from '../utils/GenUtils';
import IInvoice, { EInvoiceStatus } from '../interfaces/IInvoice';
import { setInvoices, setLastInvoice, updateInvoiceAction } from '../redux/actions/InvoiceActions';
import { setPayments } from '../redux/actions/PaymentActions';

export class InvoiceService {
    static async createInvoice(invoice: IInvoice) {
        const { data, error } = await APIUtil.makePostRequest(`${ApiURLs.CREATE_INVOICE}`, invoice);

        if (error) {
            GenUtils.log('createInvoice', error);
            return { error };
        }

        await InvoiceService.fetchInvoices();
        return { data };
    }

    static async fetchInvoices() {
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.FETCH_INVOICE}`);

        if (error) {
            GenUtils.log('fetchInvoices', error);
            return { error };
        }

        GenUtils.dispatch(setInvoices(data.invoices));
        GenUtils.dispatch(setLastInvoice(data.lastInvoicesObj));
        GenUtils.dispatch(setPayments(data.payments));
        return { data };
    }

    static async fetchUnbilledInvoices() {
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.GET_UNBILLED_INVOICES}`);

        if (error) {
            GenUtils.log('fetchInvoices', error);
            return { error };
        }

        // GenUtils.dispatch(setInvoices(data.invoices));
        // GenUtils.dispatch(setLastInvoice(data.lastInvoicesObj));
        // GenUtils.dispatch(setPayments(data.payments));
        return { data };
    }

    static async updateInvoice(invoiceId, invoice) {
        GenUtils.dispatch(updateInvoiceAction(invoiceId, invoice));

        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.UPDATE_INVOICE}/${invoiceId}`, invoice);

        if (error) {
            GenUtils.log('updateInvoice', error);
            return { error };
        }

        await InvoiceService.fetchInvoices();
        return { data };
    }

    static async updateInvoiceDetails(invoiceId, invoice) {
        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.UPDATE_INVOICE_DETAILS}/${invoiceId}`, invoice);

        if (error) {
            GenUtils.log('updateInvoiceDetails', error);
            return { error };
        }

        await InvoiceService.fetchInvoices();
        return { data };
    }

    static async updateInvoiceStatus(invoiceId, status: EInvoiceStatus = EInvoiceStatus.Draft, note = null, amount = null, email = null) {
        const { data, error } = await APIUtil.makePutRequest(
            `${ApiURLs.UPDATE_INVOICE_STATUS}/${invoiceId}`,
            GenUtils.removeEmptyKeysFromObject({ status, note, amount, email })
        );

        if (error) {
            GenUtils.log('updateInvoiceStatus', error);
            return { error };
        }

        await InvoiceService.fetchInvoices();
        return { data };
    }

    static async recordPayment(invoiceId, details) {
        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.UPDATE_INVOICE_STATUS}/${invoiceId}`, GenUtils.removeEmptyKeysFromObject(details));

        if (error) {
            GenUtils.log('recordPayment', error);
            return { error };
        }

        await InvoiceService.fetchInvoices();
        return { data };
    }

    static async deleteInvoice(invoiceId) {
        const { data, error } = await APIUtil.makeDeleteRequest(`${ApiURLs.DELETE_INVOICE}/${invoiceId}`);

        if (error) {
            GenUtils.log('updateInvoice', error);
            return { error };
        }

        await InvoiceService.fetchInvoices();
        return { data };
    }

    static async deleteInvoiceDetail(invoiceId) {
        const { data, error } = await APIUtil.makeDeleteRequest(`${ApiURLs.DELETE_INVOICE_DETAILS}/${invoiceId}`);

        if (error) {
            GenUtils.log('deleteInvoiceDetail', error);
            return { error };
        }

        await InvoiceService.fetchInvoices();
        return { data };
    }
}
