import { Checkbox, Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import Button from '../../components/button/Button';
import TimeOffService from '../../services/TimeOffService';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import _ from 'lodash';

const AddTimeAllowance = ({ onClose = () => {}, ptoType }) => {
    const [pto, setPto] = useState({
        name: '',
        type: 'monthly',
        carryOverLimit: 0,
        minCount: 0,
        unit: 'day',
        count: 0,
        rate: 0,
        isCarryOverUnlimited: false,
        applyToNewEmployee: false,
        canExceed: false
    });

    const handleChange = (name, value) => {
        setPto({ ...pto, [name]: value });
    };

    useEffect(() => {
        if (ptoType) {
            setPto({
                name: ptoType.name,
                type: ptoType.type,
                carryOverLimit: ptoType.carryOverLimit,
                minCount: ptoType.minCount || 0,
                unit: ptoType.unit,
                count: ptoType.count || 0,
                rate: ptoType.rate || 0,
                isCarryOverUnlimited: ptoType.isCarryOverUnlimited,
                applyToNewEmployee: ptoType.applyToNewEmployee,
                canExceed: ptoType.canExceed
            });
        }
    }, [ptoType]);

    const resetData = () => {
        setPto({
            name: '',
            type: 'monthly',
            carryOverLimit: 0,
            minCount: 0,
            unit: 'day',
            count: 0,
            rate: 0,
            isCarryOverUnlimited: false,
            applyToNewEmployee: false,
            canExceed: false
        });
    };

    const onSubmit = async () => {
        if (ptoType) {
            const { data, error } = await TimeOffService.updatePTOType(ptoType.uuid, pto);
            if (error) {
                const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
                GenUtils.showNotification('Failed to Update PTO Type', error, <notificationIcon.icon style={notificationIcon.style} />);
                return;
            }
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
            GenUtils.showNotification('Successfully Updated PTO Type', null, <notificationIcon.icon style={notificationIcon.style} />);
            onClose();
            resetData();
            return;
        }
        const { data, error } = await TimeOffService.createPTOType(pto.type === 'fixed' ? _.omit(pto, 'minCount') : pto);
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Create PTO Type', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Successfully Created PTO Type', null, <notificationIcon.icon style={notificationIcon.style} />);
        onClose();
        resetData();
    };

    return (
        <div style={{ paddingTop: 10 }}>
            <Form>
                <Form.Item label="Name" labelCol={{ span: 8 }} labelAlign="left">
                    <Input value={pto.name} onChange={(e) => handleChange('name', e.target.value)} />
                </Form.Item>
                <Form.Item label="Type" labelCol={{ span: 8 }} labelAlign="left">
                    <Select value={pto.type} onChange={(e) => handleChange('type', e)}>
                        <Select.Option value="fixed">Fixed</Select.Option>
                        <Select.Option value="weekly">Weekly</Select.Option>
                        {/* <Select.Option value="biweekly">BiWeekly</Select.Option> */}
                        <Select.Option value="monthly">Monthly</Select.Option>
                    </Select>
                </Form.Item>
                {pto.type !== 'fixed' ? (
                    <Form.Item label="Minimum Hours to Accrue" labelCol={{ span: 8 }} labelAlign="left">
                        <Input value={pto.minCount} onChange={(e) => handleChange('minCount', e.target.value)} />
                    </Form.Item>
                ) : null}
                <Form.Item label="Earning Unit" labelCol={{ span: 8 }} labelAlign="left">
                    <Select value={pto.unit} onChange={(e) => handleChange('unit', e)}>
                        <Select.Option value="hour">Hour</Select.Option>
                        <Select.Option value="day">Day</Select.Option>
                    </Select>
                </Form.Item>
                {pto.type !== 'fixed' ? (
                    <Form.Item label="Accruel Rate" labelCol={{ span: 8 }} labelAlign="left">
                        <Input value={pto.rate} onChange={(e) => handleChange('rate', e.target.value)} />
                    </Form.Item>
                ) : (
                    <Form.Item label="Yearly leave count" labelCol={{ span: 8 }} labelAlign="left">
                        <Input value={pto.count} onChange={(e) => handleChange('count', e.target.value)} />
                    </Form.Item>
                )}
                <Form.Item label="Carryover Limit" labelCol={{ span: 8 }} labelAlign="left">
                    <Input value={pto.carryOverLimit} disabled={pto.isCarryOverUnlimited} onChange={(e) => handleChange('carryOverLimit', e.target.value)} />
                </Form.Item>
                <Form.Item labelCol={{ span: 8 }} labelAlign="left">
                    <Checkbox checked={pto.isCarryOverUnlimited} onChange={(e) => handleChange('isCarryOverUnlimited', e.target.checked)}>
                        Unlimited
                    </Checkbox>
                </Form.Item>
                <Form.Item labelCol={{ span: 8 }} labelAlign="left">
                    <Checkbox checked={pto.applyToNewEmployee} onChange={(e) => handleChange('applyToNewEmployee', e.target.checked)}>
                        <p>Apply this allowance for all new employees</p>
                    </Checkbox>
                    <p>Note: You can modify the allowance settings for each employee using “Set Employee Allowance” section.</p>
                </Form.Item>
                <div style={{ display: 'flex', gap: 10, flexDirection: 'row', justifyContent: 'center' }}>
                    <Button
                        text={'Cancel'}
                        onClick={() => {
                            resetData();
                            onClose();
                        }}
                        type="ghost"
                    />
                    <Button text={'Create'} onClick={onSubmit} type="primary" />
                </div>
            </Form>
        </div>
    );
};

export default AddTimeAllowance;
