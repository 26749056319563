import { useEffect, useState } from 'react';
import styles from './TimeSheet.scss';
import Clock from '../../assets/images/pages/timesheet/timer.svg';
import HeaderSecondary from '../../components/header/HeaderSecondary';
import Tabs from '../../components/tabs/Tabs';
import { AuditOutlined, CalendarOutlined, FieldTimeOutlined, FileDoneOutlined } from '@ant-design/icons';
import NavigationUtils from '../../utils/NavigationUtils';
import { Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import PermissionUtils from '../../utils/PermissionUtils';
import { IMember } from '../../redux/reducers/TeamReducer';
import { useSearchParams } from 'react-router-dom';
import { getUserDetails } from '../../redux/selectors/UserSelector';
import { MenuProps, Space, Dropdown, Modal } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { RiCloseFill } from 'react-icons/ri';
import MomentUtils from '../../utils/MomentUtils';
import ApplyPTO from './ApplyPTO';
const TimeSheet = (): JSX.Element => {
    const { pathname } = useLocation();
    const teamMembers = useSelector((state: IStore) => state.team.membersArray);
    const [weekRange, setWeekRange] = useState(MomentUtils.getWeekDates(new Date()));
    const [selectedUser, setSelectedUser] = useState({} as IMember);
    const [members, setMembers] = useState([]);
    const user = useSelector((state: IStore) => getUserDetails(state));
    const [activeRoute, setActiveRoute] = useState('timesheets');
    const [searchParams, setSearchParams] = useSearchParams();
    const [timesheetStatus, setTimesheetStatus] = useState('approved');
    const [showAddTimeOff, setShowAddTimeOff] = useState(false);
    const firmId = useSelector((state: IStore) => state?.firm?.uuid);
    const activeMembers = members.filter((data)=>data.status==='active')

    useEffect(() => {
        const splitLocation = pathname.split('/');
        const _activeRoute = splitLocation[splitLocation.indexOf('timesheet') + 1] || 'timesheets';
        if (activeRoute !== _activeRoute) setActiveRoute(_activeRoute);
    }, [pathname]);

    useEffect(() => {
        if (teamMembers.length) {
            let currentUser =
                teamMembers[
                    teamMembers.findIndex((member) =>
                        searchParams.get('teamMemberId') ? member.uuid === searchParams.get('teamMemberId') : member.email === user.email
                    )
                ];
            setSelectedUser(currentUser);
            if (PermissionUtils.viewTimesheetFor() === 'all') {
                setMembers(teamMembers);
            }
            if (PermissionUtils.viewTimesheetFor() === 'assigned') {
                setMembers(currentUser.directReportees);
            }
        }
    }, [teamMembers]);

    useEffect(()=>{if(user?.uuid){searchParams.set('teamMemberId',user.uuid);setSearchParams(searchParams)}},[])

    const nameItems: MenuProps['items'] = activeMembers.filter(m => m.status === 'active').map((member) => ({
        label: <Space onClick={() => {
            setSelectedUser(member)
            searchParams.set('teamMemberId', member.uuid)            
            setSearchParams(searchParams);
        }}
        >{member.firstName + ' ' + member.lastName + "'s"}</Space>,
        key: member.uuid
    }));

    const getButtonText = () => {
      switch(timesheetStatus) {
          case 'rejected':
          case 'pending': return 'Submit';
          case 'submitted': return 'Withdraw';
          default: return false;
      }
  }
    const renderAltTab = () => {
        return (
            <div>
                {selectedUser && PermissionUtils.viewTimesheetFor() !== 'own' ? (
                    <Dropdown menu={{ items: nameItems }}>
                        <Space>
                            {selectedUser.firstName + ' ' + selectedUser.lastName + "'s"}
                            <DownOutlined style={{ fontSize: 16, paddingLeft: 5, color: 'blue' }} />
                        </Space>
                    </Dropdown>
                ) : (
                    <>{selectedUser.firstName + ' ' + selectedUser.lastName + "'s"}</>
                )}{' '}
                TimeSheet
            </div>
        );
    };
    return (
        <div>
            <div className={styles.header} style={{alignItems:'center'}}>
                <HeaderSecondary
                    text={activeRoute === 'timesheets' ? renderAltTab() : 'Time Tracking'}
                    subtext={'Keep track of time spent daily or weekly on project activities.'}
                    renderImage={() => <img src={Clock} alt="timesheet-clock" width={70} />}
                />
            </div>
            <div className={styles.tsMain}>
                <Tabs
                    items={[
                        {
                            key: 'timesheets',
                            name: 'Timesheets',
                            icon: <CalendarOutlined />
                        },
                        {
                          key: 'submissions',
                          name: 'Submissions',
                          icon: <FileDoneOutlined />
                      },
                      {
                          key: 'entries',
                          name: 'Time Entries',
                          icon: <FieldTimeOutlined />
                      },
                        ...(PermissionUtils.approveTimeSheet() !== 'none'
                            ? [
                                  {
                                      key: 'audits',
                                      name: 'Audit Timesheets',
                                      icon: <AuditOutlined />
                                  }
                              ]
                            : [])
                    ]}
                    onTabClick={(key) => {
                      let url = `${firmId}/timesheet/${key}`
                      if(key==='timesheets'){
                        url += `?teamMemberId=${selectedUser?.uuid}`
                      }
                        NavigationUtils.navigateTo(url);
                    }}
                    activeTab={activeRoute}
                />
                <Outlet />
                <Modal
                    title={`Add Time Off Allowance`}
                    width={600}
                    centered
                    style={{ margin: '20px' }}
                    open={showAddTimeOff}
                    onCancel={() => setShowAddTimeOff(false)}
                    footer={null}
                    closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
                    destroyOnClose={true}
                    >
                    <ApplyPTO teamMember={selectedUser?.uuid} onClose={() => setShowAddTimeOff(false)} />
                </Modal>
            </div>
        </div>
    );
};

export default TimeSheet;
