import { Pie, PieConfig } from '@ant-design/plots';
import { memo } from 'react';

interface IPieChartProps {
    pieChartData: { project: { name: string; color: string }; seconds: number }[];
}

const PieChart = memo(({ pieChartData }: IPieChartProps) => {
    const data = pieChartData.map((item) => {
        let hours = Number((item.seconds / 3600).toFixed(2));
        return {
            type: item.project.name,
            value: hours
        };
    });
    const colors = pieChartData.map((item) => item.project.color);
    const config: PieConfig = {
        data,
        angleField: 'value',
        colorField: 'type',
        color: colors,
        radius: 0.9,
        innerRadius: 0.6,
        label: {
            content: '{value} Hrs'
        },
        interactions: [
            {
                type: 'element-selected'
            },
            {
                type: 'element-active'
            }
        ],
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: '16px'
                },
                formatter: (datum) => {
                    let totalHours = 0;
                    data.forEach((item) => {
                        totalHours += item.value;
                    });
                    return `${totalHours} Hrs`;
                }
            }
        },
        tooltip: {
            formatter: (datum) => {
                return { name: datum.type, value: `${datum.value} Hrs` };
            }
        },
        
    };
    return <Pie {...config} />;
});

export default PieChart;
