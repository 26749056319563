import { DatePicker, Form, Input, Radio, Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Button from '../../components/button/Button';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import MomentUtils from '../../utils/MomentUtils';
import { LeavesService } from '../../services/LeavesService';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import TimeOffService from '../../services/TimeOffService';

const ApplyPTO = ({ teamMember = '', onClose = () => {} }) => {
    const [selectedPTOUnit, setSelectedPTOUnit] = useState('day');
    const ptoTypes = useSelector((state: IStore) => state.timeOff.timeOffTypesArray);
    const leaves = useSelector((state: IStore) => state.leaves.leaves);

    useEffect(() => {
        if (!ptoTypes.length) {
            TimeOffService.fetchPTOTypes();
        }
    }, []);

    useEffect(() => {
        LeavesService.fetchLeaves(teamMember);
    }, [teamMember]);

    const [leave, setLeave] = useState({
        typeId: '',
        teamMemberId: teamMember,
        note: '',
        unit: 'day',
        count: 0,
        fromDate: moment().toDate(),
        toDate: moment().toDate()
    });

    const handleChange = (name, data) => {
        const temp = { ...leave, [name]: data };
        console.log(name, data, temp);
        setLeave((prev) => ({ ...prev, [name]: data }));
    };
    const resetData = () => {
        setLeave({
            typeId: '',
            teamMemberId: teamMember,
            note: '',
            unit: 'day',
            count: 0,
            fromDate: moment().toDate(),
            toDate: moment().toDate()
        });
    };

    const onSubmit = async () => {
        // after resolving units issue
        const { error } = await LeavesService.applyLeave({ ...leave, unit: selectedPTOUnit });
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Apply Leave', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Leave Applied successfully', {}, <notificationIcon.icon style={notificationIcon.style} />);
        resetData();
        onClose();
    };

    return (
        <div style={{ paddingTop: 10 }}>
            <Form>
                <Form.Item label="PTO Type" labelCol={{ span: 8 }} labelAlign="left">
                    <Select
                        value={leave.typeId}
                        onChange={(e) => {
                            handleChange('typeId', e);
                            let ind = ptoTypes.findIndex((type) => type.uuid === e);
                            console.log(ptoTypes[ind].unit);
                            setSelectedPTOUnit(ind > -1 ? ptoTypes[ind].unit : 'day');
                        }}>
                        {ptoTypes &&
                            ptoTypes.map((ptoType) => (
                                <Select.Option value={ptoType.uuid} key={ptoType.uuid}>
                                    {ptoType.name}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>
                <Form.Item label="Note" labelCol={{ span: 8 }} labelAlign="left">
                    <Input.TextArea value={leave.note} onChange={(e) => handleChange('note', e.target.value)} />
                </Form.Item>
                {selectedPTOUnit === 'day' ? (
                    <Form.Item style={{ marginLeft: '33%' }}>
                        <Radio.Group
                            value={leave.unit === 'hour' && leave.count === 4 ? 'half_day' : leave.unit}
                            style={{ width: '100%' }}
                            onChange={(e) => {
                                if (e.target.value === 'half_day') {
                                    handleChange('unit', 'day');
                                    handleChange('count', 0.5);
                                } else {
                                    handleChange('unit', e.target.value);
                                    handleChange('count', 0);
                                }
                            }}>
                            {/* <Radio.Button value={'hour'}>Hour</Radio.Button> */}
                            <Radio.Button value={'half_day'}>Half Day</Radio.Button>
                            <Radio.Button value={'day'}>Day</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                ) : null}
                <Form.Item label={`${selectedPTOUnit}s`} labelCol={{ span: 8 }} labelAlign="left">
                    <Input value={leave.count} onChange={(e) => handleChange('count', e.target.value)} />
                </Form.Item>
                <Form.Item label="Date" labelCol={{ span: 8 }} labelAlign="left">
                    {selectedPTOUnit === 'day' ? (
                        <DatePicker.RangePicker
                            value={[moment(leave.fromDate), moment(leave.toDate)]}
                            style={{ width: '100%' }}
                            onChange={(e) => {
                                handleChange('fromDate', MomentUtils.getGmtDayStart(e[0]));
                                handleChange('toDate', MomentUtils.getGmtDayStart(e[1]));
                            }}
                        />
                    ) : (
                        <DatePicker
                            value={moment(leave.fromDate)}
                            style={{ width: '100%' }}
                            onChange={(e) => {
                                handleChange('fromDate', MomentUtils.getGmtDayStart(e));
                                handleChange('toDate', MomentUtils.getGmtDayStart(e));
                            }}
                        />
                    )}
                </Form.Item>
                {ptoTypes.length &&
                    ptoTypes.map((type) => (
                        <Form.Item label={type.name} key={type.uuid} labelCol={{ span: 8 }} labelAlign="left">
                            {leaves.balance
                                ? leaves.balance[teamMember][leaves.balance[teamMember]?.findIndex((leave) => leave.typeId === type.uuid)]?.count
                                : 0}
                            {'  '}
                            Leaves Available
                        </Form.Item>
                    ))}
                <div style={{ display: 'flex', gap: 10, flexDirection: 'row', justifyContent: 'center' }}>
                    <Button
                        text={'Cancel'}
                        onClick={() => {
                            resetData();
                            onClose();
                        }}
                        type="ghost"
                    />
                    <Button
                        text={'Create'}
                        onClick={() => {
                            onSubmit();
                        }}
                        type="primary"
                    />
                </div>
            </Form>
        </div>
    );
};

export default ApplyPTO;
