import { APIUtil } from '../utils/ApiUtils';
import ApiURLs from '../constants/ApiURLs';
import IPermission from '../interfaces/IPermission';
import { setPermissions } from '../redux/actions/PermissionActions';
import GenUtils from '../utils/GenUtils';

export default class PermissionService {
    static async fetchPermissions() {
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.GET_PERMISSION}`);

        if (error) {
            GenUtils.log('createPermission', error);
            return { error };
        }

        GenUtils.dispatch(setPermissions(data));
        return { data };
    }

    static async createPermission(permission: IPermission) {
        const { data, error } = await APIUtil.makePostRequest(`${ApiURLs.CREATE_PERMISSION}`, permission);

        if (error) {
            GenUtils.log('createPermission', error);
            return { error };
        }

        await PermissionService.fetchPermissions();
        return { data };
    }

    static async deletePermission(permissionId) {
        const { data, error } = await APIUtil.makeDeleteRequest(`${ApiURLs.DELETE_PERMISSION}/${permissionId}`);

        if (error) {
            GenUtils.log('deletePermission', error);
            return { error };
        }

        await PermissionService.fetchPermissions();
        return { data };
    }

    static async updatePermission(permission: IPermission) {
        const p = { ...permission };
        delete p.userUuid;
        delete p.uuid;
        delete p.createdAt;
        delete p.updatedAt;

        Object.keys(p).forEach(key => {
            if (p[key] === null) {
                p[key] = (key !== 'membersToManage' && key !== 'projectsToManage') ? false : 'none';
            }
        })

        GenUtils.log('updatePermission',p)

        const {data, error} = await APIUtil.makePutRequest(`${ApiURLs.UPDATE_PERMISSION}/${permission.uuid}`, p);

        if (error) {
            GenUtils.log('updatePermission', error);
            return {error};
        }

        await PermissionService.fetchPermissions();
        return { data };
    }
}
