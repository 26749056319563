import GenUtils from '../../utils/GenUtils';
import ITax from '../../interfaces/ITax';

export const setTaxes = (taxes: ITax[]) => {
    return {
        type: 'SET_TAXES',
        payload: GenUtils.arrayToIdMap(taxes)
    };
};

export const updateTax = (taxId, tax: ITax) => {
    return {
        type: 'UPDATE_TAX',
        payload: { uuid: taxId, ...tax }
    };
};