import React, { useState } from 'react';
import GenUtils, { getNestedData as getData } from '../../../utils/GenUtils';
import { Select } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IStore } from '../../../redux/store';

import styles from '../FiltersBar.scss';
import Tag from '../../tag/Tag';
import TeamAvatar from '../../avatar/TeamAvatar';

const DropdownFilter = ({ filter, data, updateFilter }) => {
    const [searchParams] = useSearchParams();
    const [v, setV] = useState(searchParams.getAll(filter.dataIndex));

    const projects = useSelector((state: IStore) => state.project.projects);
    const phases = useSelector((state: IStore) => state.phases.projectPhases);
    const members = useSelector((state: IStore) => state.team.members);
    const clients = useSelector((state: IStore) => state.client.clients);
    const invoices = useSelector((state: IStore) => state.invoices.invoices);
    const options_s = useSelector((state: IStore) => state.options);

    const getUniquesFromData = (dataIndex) => {
        const obj = {};
        if (data) {
            data.forEach((d) => (obj[getData(dataIndex, d)] = d));
        }
        const list = Object.keys(obj);
        if (dataIndex === 'projectId') {
            return [
                {
                    uuid: 'all',
                    name: 'All Projects'
                },
                ...list.map((l) => ({
                    uuid: l,
                    name: (
                        <div style={{ display: 'flex', gap: 10 }}>
                            <Tag text={projects[l]?.serial} color={projects[l]?.color} />
                            {GenUtils.capitalizeInitials(projects[l]?.name || '-')}
                        </div>
                    )
                }))
            ];
        } else if (dataIndex === 'phaseId') {
            const r =  [
                {
                    uuid: 'all',
                    name: 'All Phases'
                },
                ...list.map((l) => ({
                    uuid: l,
                    name: GenUtils.capitalizeInitials((phases[obj[l]?.projectId] && phases[obj[l].projectId][l]?.type?.name) || '-')
                }))
            ];
            return r
        } else if (dataIndex === 'clientId' || dataIndex === 'project.clientId') {
            return [
                {
                    uuid: 'all',
                    name: 'All Clients'
                },
                ...list.map((l) => ({
                    uuid: l,
                    name: GenUtils.capitalizeInitials(clients[l]?.name || '-')
                }))
            ];
        } else if (dataIndex === 'teamMemberId' || dataIndex === 'members') {
            return [
                {
                    uuid: 'all',
                    name: 'All Members'
                },
                ...list.map((l) => ({
                    uuid: l,
                    name: (
                        <TeamAvatar
                            image={members[l]?.profilePicUrl}
                            userId={l}
                            name={GenUtils.capitalizeInitials(members[l]?.name || members[l]?.firstName + ' ' + members[l]?.lastName)}
                            role={options_s.role.find((r) => r.uuid === members[l]?.roleId)?.name}
                        />
                    )
                }))
            ];
        } else if (dataIndex === 'invoiceId') {
            return [
                {
                    uuid: 'all',
                    name: 'All Invoices'
                },
                ...list.map((l) => ({
                    uuid: l,
                    name: GenUtils.capitalizeInitials(invoices[l]?.serial || '-')
                }))
            ];
        } else if (dataIndex === 'activityId') {
            const r: string[] = (dataIndex ? list : filter.options || []).map((l) => l);
            return [
                {
                    uuid: 'all',
                    name: 'All Activities'
                },
                ...r.map((l) => ({
                    uuid: l,
                    name: GenUtils.capitalizeInitials(options_s.activity.find((a) => a.uuid === l).name)
                }))
            ];
        }

        return (dataIndex ? list : filter.options || []).map((l) => ({ uuid: l, name: GenUtils.capitalizeInitials(l) }));
    };

    const options = getUniquesFromData(filter.dataIndex);

    return (
        <Select
            showSearch={true}
            mode="multiple"
            value={v}
            className={styles.dropdown}
            onChange={(values) => {
                let _values = [];
                if (filter.name === 'Invoice Status') {
                    if (values.length === 0) {
                        setV([]);
                        updateFilter('odStatus', []);
                        updateFilter(filter.dataIndex, []);
                        return;
                    } else if (values.indexOf('Overdue') > -1) {
                        setV(['Overdue']);
                        updateFilter(filter.dataIndex, []);
                        updateFilter('odStatus', ['Overdue']);
                        return;
                    }
                }
                if ((values.indexOf('all') === 0 && values.length === 1) || values.indexOf('all') === values.length - 1) {
                    _values = [];
                } else {
                    _values = values.filter((val) => val !== 'all');
                }
                setV(_values);
                updateFilter(filter.dataIndex, _values);
            }}
            filterOption={(input, option) => {
                const optionText = (option.children as React.ReactNode) || '';
                if (typeof optionText === 'string') {
                    return optionText.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }

                if (typeof optionText === 'object') {
                    const optionProps = (optionText as any).props || {};
                    if (optionProps.name) {
                        return optionProps.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    } else if (optionProps.children) {
                        const childrenText = optionProps.children[1] || '';
                        return childrenText.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }
                }

                return false;
            }}
            key={filter.name}
            placeholder={filter.name}
            maxTagCount={1}
            style={{ width: '250px', fontFamily: 'Inter' }}>
            {filter.name === 'Invoice Status' && (
                <Select.Option key={'Overdue'} value={'Overdue'} style={{ fontFamily: 'Inter' }}>
                    Overdue
                </Select.Option>
            )}
            {options.map((option) => (
                <Select.Option key={option.uuid} value={option.uuid} style={{ fontFamily: 'Inter' }}>
                    {option.name}
                </Select.Option>
            ))}
        </Select>
    );
};

export default DropdownFilter;
