export interface IMembersState {
  members: {},
  phaseMembers: {}
}

export const initialPhaseState: IMembersState = {
  members: {},
  phaseMembers: {}
};

const phaseMemberReducer = (state: IMembersState, action: { type: string, payload }) => {
  switch (action.type) {
    case 'SET_MEMBERS':
      return {
        ...state,
        members: {...state.members,...action.payload.members},
        phaseMembers: {
          ...state.phaseMembers,
          ...(action.payload.phaseId ? {[action.payload.phaseId]:action.payload.members}: {})
        }
      };
    case 'UPDATE_MEMBER':
      return {
        ...state
      };
    default:
      return state || initialPhaseState;
  }
};

export default phaseMemberReducer;