import { Mix } from '@ant-design/plots';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import styles from './Charts.scss';
import Colors from '../../../constants/Colors';
import FormatUtils from '../../../utils/FormatUtils';
import classnames from 'classnames';
import ReportService from '../../../services/ReportService';
import { ReactNode } from 'react';
import { useSelector } from "react-redux";
import { IStore } from "../../../redux/store";
import moment from "moment";
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

const TeamCapacityForecast = () => {

    const { pathname } = useLocation();
    const firm = useSelector((state: IStore) => state.firm.firm);
    const dateFormat = firm.dateFormat?.toUpperCase()
    const [reportId, setReportId] = useState('');
    const [data, setData] = useState("");
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);

    useEffect(() => {
        const pathSplit = pathname.split('/');
        const selectedId = pathSplit[pathSplit.indexOf('reports') + 1];
        selectedId && setReportId(selectedId);
    }, [pathname]);

    useEffect(() => {
        reportId && fetchReport(reportId);
    }, [from, to, reportId]);

    const fetchReport = async (selectedId) => {
        setData((await ReportService.fetchReport(selectedId, from, to)).data);
    }


    const columnData = Object.keys(data).flatMap((key) =>
      data[key]?.map((item) => ({
        member: key.replace("_", " "),
        capacity: item.capacity,
        budgetedHours: item.budgetedHours,
        projectName: item.projectName,
        color: item.color,
      })) || []
    );

    const lineData = Object.keys(data).reduce((arr, key) => [
        ...arr,
        data[key]?.reduce((o, a) => ({
                member: key.replace('_', ' '),
                capacity: a.capacity || 0
            }
        ), {}),
    ], [])


    const max = Math.ceil([...lineData, ...Object.values(columnData.reduce((obj, d) => ({
            ...obj,
            [d.member]: {
                capacity: obj[d.member] ? (obj[d.member].capacity || 0) + d.capacity : d.capacity,
            }
        }), {})
    )].reduce((m, d) => d.capacity > m ? d.capacity : m, 20) * 1.1);

    const colors = columnData.reduce((obj, d) => ({...obj, [d.color]: d.projectName}), {});

    const config = {
        tooltip: {
            shared: true,
            domStyles: {
                'g2-tooltip': {
                    width: '200px',
                    padding: 0,
                    borderRadius: '10px',
                    overflow: 'hidden',
                    opacity: 1,
                },
            },
            customContent: (title, items) => <Popover datas={items} title={title} />,
        },
        plots: [
            {
                type: 'column',
                options: {
                    data: columnData,
                    xField: 'member',
                    yField: 'budgetedHours',
                    seriesField: 'projectName',
                    isStack: true,
                    color: Object.keys(colors),
                    yAxis: {
                        max,
                    },
                    style: {
                        borderRadius: '10px',
                        overflow: 'hidden',
                    }
                }
            },
            {
                type: 'line',
                options: {
                    data: lineData,
                    color: Colors.primary,
                    // smooth: true,
                    xField: 'member',
                    yField: 'capacity',
                    stepType: 'hvh',
                    yAxis: {
                        max,
                    },
                    // legend: true,
                }
            },
        ],
    };

    return (
        <div className={styles.container}>
                <RangePicker 
                        defaultValue={[moment().subtract(30, 'days'),moment()]}
                        onChange={(values) => {
                            if (values && values.length === 2) {
                            setFrom(values[0].toISOString());
                            setTo(values[1].toISOString());
                        }
                    }} 
                    format={dateFormat}
                    style={{width:"300px"}}
                 />
            <div className={styles.legend}>
                {
                    Object.entries({line: 'Member Capacity', ...colors}).map(legend => (
                        <div className={styles.info} key={legend[0]}>
                            <div className={styles.color} style={legend[0] === 'line' ? {background: Colors.primary, height: 2} : {background: legend[0]}} /> 
                            <div className={classnames(styles.title, styles.project)}>
                                {legend[1] as ReactNode}
                            </div>
                        </div>
                    ))
                }
            </div>
            {/* @ts-ignore */}
            <Mix {...config} />
        </div>
    )
}

export default TeamCapacityForecast;

const Popover = ({datas, title}) => {
    const capacity = datas.find(d => d.name==='capacity')?.data?.capacity || 0;
    const planned = datas.filter(d => d.name!=='capacity').reduce((acc, d) => acc+d.data.budgetedHours, 0);

    const infos = [
        {
            title: 'Capacity',
            value: FormatUtils.getAmountInFormat(capacity, false, 2, 0) + ' Hrs',
            color: Colors.primary,
        },{
            title: 'Planned',
            value: FormatUtils.getAmountInFormat(planned, false, 2, 0) + ' Hrs',
        },{
            title: 'Planned %',
            value: FormatUtils.getAmountInFormat(capacity ? planned*100/capacity: 100, false, 2, 0) + ' %',
        },{
            title: 'Availability',
            value: FormatUtils.getAmountInFormat((capacity - planned), false, 2, 0) + ' Hrs',
            color: planned > capacity ? Colors.red : Colors.black,
        }
    ];

    return (
        <div className={styles.popoverContainer}>
            <div className={styles.header}>
                {title}
            </div>
            <div className={styles.body}>
                <div className={styles.infos}>
                {
                    infos.map(info => (
                        <div className={styles.info} key={info.title}>
                            <div className={styles.title}>
                                {info.title}
                            </div>
                            <div className={styles.title} style={info.color ? {color: info.color} : {}}>
                                {info.value}
                            </div>
                        </div>
                    ))
                }
                </div>
                <div className={styles.table}>
                    <div className={styles.header}>
                        <div className={styles.title}>
                            Project
                        </div>
                        <div className={styles.title}>
                            Hours
                        </div>
                    </div>
                    <div className={styles.infos}>
                    {
                        datas.filter(d => d.name !== 'capacity').map(({data}) => (
                            <div className={styles.info} key={data.projectName}>
                                <div className={styles.color} style={{background: data.color}} /> 
                                <div className={classnames(styles.title, styles.project)}>
                                    {data.projectName}
                                </div>
                                <div className={styles.title}>
                                    {FormatUtils.getAmountInFormat(data.budgetedHours, false, 2, 0)}
                                </div>
                            </div>
                        ))
                    }
                    </div>
                </div>
            </div>
        </div>
    )
}