import { Card, Col, Divider, Row } from 'antd';

import styles from './Table.scss';
import { IInvoiceDetail } from '../../../interfaces/IInvoice';

interface IColumn {
    key: string;
    name: string;
    span: number;
    render?: (d, i) => any;
}

const Table = ({
    data,
    columns,
    applyFilters = () => true,
    lastRowBold = false,
    isExpandableRow = () => false,
    expandableRow = () => null,
}: {
    data: any[],
    columns: IColumn[],
    applyFilters?: () => boolean,
    lastRowBold?: boolean,
    isExpandableRow?: (d: IInvoiceDetail) => IInvoiceDetail[] | false,
    expandableRow?: (d: IInvoiceDetail[]) => any,
}) => {
    return (
        <Card className={styles.container}>
            <div className={styles.header}>
                <Row style={{ alignItems: 'center', flex: 1, padding: '0 20px' }}>
                    {
                        columns.map(column => <Col span={column.span || (24 / columns.length)}>{column.name}</Col>)
                    }
                </Row>
            </div>
            <Divider className={styles.divider} />
            {
                data?.filter(applyFilters)?.length
                    ? data.filter(applyFilters)
                        .map((d, i) => (
                            <>
                            <div className={styles.body} key={d.uuid} style={i === data.filter(applyFilters).length - 1 && lastRowBold ? { fontWeight: 'bold' } : {}}>
                                {
                                    columns.map(c => <Col span={c.span}>{c.render ? c.render(d, i) : d[c.key]}</Col>)
                                }
                            </div>
                            {
                                isExpandableRow(d) ? expandableRow(d?.details) : <div className={styles.divider}/>
                            }
                            </>
                        ))
                    : (
                        <div style={{
                            display: 'flex',
                            padding: '30px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            No relevant data
                        </div>
                    )
            }
        </Card>
    );
};

export default Table;