import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import Button from '../../components/button/Button';
import { RiCloseFill } from 'react-icons/ri';
import { Modal } from 'antd';
import HeaderSecondary from '../../components/header/HeaderSecondary';
import CreateProject from './CreateProject';
import ProjectService from '../../services/ProjectService';
import '../../Gantt.scss';
import NavigationUtils from '../../utils/NavigationUtils';
import PermissionUtils from '../../utils/PermissionUtils';
import Tabs from '../../components/tabs/Tabs';
import { BarsOutlined, ScheduleOutlined, LineChartOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Outlet, useLocation } from 'react-router-dom';
import Style from '../expenses/ExpensesPage.scss';
import CreateTask from './CreateTask';
import CreateMilestone from './CreateMilestone';
import Project from '../../assets/images/project.svg';

const AllProjectPage = () => {
    const { pathname, state: locationState } = useLocation();
    const [selectedTaskId, setSelectedTaskId] = useState(null);
    const firmId = useSelector((state: IStore) => state?.firm?.uuid);
    const [showProjectModal, setProjectModal] = useState(false);
    const [showTaskModal, setTaskModal] = useState(false);
    const [showMilestoneModal, setMilestoneModal] = useState(false);
    const [activeRoute, setActiveRoute] = useState('Overview');

    useEffect(() => {
        setProjectModal(locationState?.openAddProject || false);
    }, [locationState]);
    useEffect(() => {
        const pathSplit = pathname.split('/');
        const selectedId = pathSplit[pathSplit.indexOf('tasks') + 1];
        if (selectedId) {
            setSelectedTaskId(selectedId);
        }
    }, [pathname]);

    useEffect(() => {
        fetchProjects();
    }, []);
    const fetchProjects = () => {
        ProjectService.fetchAllProjects();
    };
    useEffect(() => {
        const splitLocation = pathname.split('/');
        const _activeRoute = splitLocation[splitLocation.indexOf('projects') + 1] || 'overview';
        if (activeRoute !== _activeRoute) setActiveRoute(_activeRoute);
    }, [pathname]);

    const getHeader = () => {
        let text = '';
        let subtext='';
        let onClick=() => {};
        let buttonText='';

        switch (activeRoute) {
            case 'overview':
                text = 'Project';
                subtext = 'Track and manage your projects here';
                onClick = () => setProjectModal(true);
                buttonText = 'New Project';
                break;

            case 'task':
                text = 'Tasks';
                subtext = 'Track and manage your projects here';
                onClick = () => setTaskModal(true);
                buttonText = 'New Task';
                break;

            case 'milestone':
                text = 'Milestone';
                subtext = 'Track and manage your projects here';
                onClick = () => setMilestoneModal(true);
                buttonText = 'New Milestone';
                break;

            default:
                text = 'Project';
                subtext = 'Efficiently drive projects from conception to completion.';
                onClick = () => setProjectModal(true);
                buttonText = 'New Project';
        }

        return <HeaderSecondary
            text={text}
            subtext={subtext}
            renderAction={() => (PermissionUtils.canCreateProjects() ? <Button onClick={onClick} text={buttonText} icon={<PlusCircleOutlined style={{marginRight:"8px"}} />}/> : null)}
            renderImage={() => <img src={Project} alt="project" width={70} />}
        />;
    };

    return (
        <div style={{display: 'flex', gap: '10px', flexDirection: 'column'}}>
            <Modal
                title='Create a new Project'
                width={500}
                centered
                open={showProjectModal}
                onCancel={() => setProjectModal(false)}
                footer={null}
                destroyOnClose={true}
                closeIcon={<RiCloseFill className='remix-icon text-color-black-100' size={24} />}>
                <CreateProject
                    hideModal={() => setProjectModal(false)}
                    onSuccess={() => {
                        setProjectModal(false);
                        fetchProjects();
                    }}
                />
            </Modal>
            <Modal
                title='Create a new task'
                width={500}
                centered
                open={showTaskModal}
                onCancel={() => setTaskModal(false)}
                footer={null}
                destroyOnClose={true}
                closeIcon={<RiCloseFill className='remix-icon text-color-black-100' size={24} />}>
                <CreateTask
                    hideModal={() => setTaskModal(false)}
                    onSuccess={() => {
                        setTaskModal(false);
                        fetchProjects();
                    }}
                />
            </Modal>
            <Modal
                title='Create a new milestone'
                width={500}
                centered
                open={showMilestoneModal}
                onCancel={() => setMilestoneModal(false)}
                footer={null}
                destroyOnClose={true}
                closeIcon={<RiCloseFill className='remix-icon text-color-black-100' size={24} />}>
                <CreateMilestone
                    hideModal={() => setMilestoneModal(false)}
                    onSuccess={() => {
                        setMilestoneModal(false);
                        fetchProjects();
                    }}
                />
            </Modal>
            {
                getHeader()
            }
            <div className={Style.container}>
                <Tabs
                    items={[
                        {
                            key: 'overview',
                            name: 'Overview',
                            icon: <BarsOutlined style={{fontSize:"16px"}}/>
                        },
                        {
                            key: 'task',
                            name: 'Task',
                            icon: <ScheduleOutlined style={{fontSize:"16px"}}/>
                        },
                        {
                            key: 'milestone',
                            name: 'Milestone',
                            icon: <LineChartOutlined style={{fontSize:"16px"}}/>
                        }
                    ]}
                    onTabClick={(key) => {
                        NavigationUtils.navigateTo(`/${firmId}/projects/${key}`);
                    }}
                    activeTab={activeRoute}
                />
                <Outlet />
            </div>
        </div>
    );
};

export default AllProjectPage;
