export const logout = () => {
    return {
        type: 'LOGOUT',
        payload: null,
    }
}

export const proceedSignup = (step: 1 | 2 | 3) => {
    return {
        type: 'SIGNUP',
        payload: step,
    }
}

export const saveAuthData = (info) => {
    return {
        type: 'AUTH_DATA',
        payload: info,
    }
}