import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

//components
import { Layout } from 'antd';
import Footer from '../footer/Footer';
import Sidebar from './Sidebar';
import Header from '../header/Header';

// utils
import NavigationUtils from '../../utils/NavigationUtils';

//styles
import styles from './SideBarLayout.scss';
import AuthService from '../../services/AuthService';
import AppUtils from '../../utils/AppUtils';
import AppRoutes from '../approutes/AppRoutes';
import { Spin } from 'antd';

const { Content } = Layout;

const SidebarLayout = () => {
    const navigateTo = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        AppUtils.setTitle(location);
    }, [location]);

    useEffect(() => {
        NavigationUtils.init(navigateTo);
        AuthService.checkAuth().then(({ error, data }) => {
            setIsLoading(false);
            if (error) {
                NavigationUtils.navigateTo('/login');
            }
            if (data.status === 'pending') {
                NavigationUtils.navigateTo('/verify');
            }
        });
    }, []);

    return (
        <>
            {isLoading ? (
                <div className={styles.loader}>
                    <Spin size="large" spinning={true} />
                </div>
            ) : (
                <Layout style={{ minHeight: '100vh' }}>
                    <Sidebar />
                    <Layout className={styles.siteLayout}>
                        <Header />
                        <Content style={{ margin: '0 16px' }}>
                            <Routes>
                                <Route path="/*" element={<AppRoutes />} />
                            </Routes>
                        </Content>
                        <Footer />
                    </Layout>
                </Layout>
            )}
        </>
    );
};

export default SidebarLayout;
