import { APIUtil } from '../utils/ApiUtils';
import ApiURLs from '../constants/ApiURLs';
import GenUtils from '../utils/GenUtils';
import { setExpenses, updateExpense } from '../redux/actions/ExpenseActions';
import IExpense from '../interfaces/IExpense';

export default class ExpenseService {
    static async fetchExpenses() {
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.GET_EXPENSES}`);

        if (error) {
            GenUtils.log('fetchExpenses', error);
            return { error };
        }

        GenUtils.dispatch(setExpenses(data));
        return { data };
    }

    static async deleteExpense(uuid) {
        const { data, error } = await APIUtil.makeDeleteRequest(`${ApiURLs.DELETE_EXPENSES}/${uuid}`);

        if (error) {
            GenUtils.log('deleteExpense', error);
            return { error };
        }

        await ExpenseService.fetchExpenses();
        return { data };
    }

    static async createExpense(expense: IExpense) {
        const {
            data,
            error
        } = await APIUtil.makePostRequest(ApiURLs.CREATE_EXPENSE, GenUtils.removeEmptyKeysFromObject(expense));

        if (error) {
            GenUtils.log('createExpense', error);
            return { error };
        }

        await ExpenseService.fetchExpenses();
        return { data };
    }

    static async updateExpense(uuid, expense) {
        GenUtils.dispatch(updateExpense(uuid, expense));

        const {
            data,
            error
        } = await APIUtil.makePutRequest(`${ApiURLs.UPDATE_EXPENSES}/${uuid}`, GenUtils.removeEmptyKeysFromObject(expense));

        if (error) {
            GenUtils.log('updateExpense', error);
            return { error };
        }

        await ExpenseService.fetchExpenses();
        return { data };
    }

    static async updateExpenseStatus(uuid, status) {
        GenUtils.dispatch(updateExpense(uuid, {status}));

        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.UPDATE_EXPENSE_STATUS}/${uuid}`, { status });

        if (error) {
            GenUtils.log('updateExpenseStatus', error);
            return { error };
        }

        await ExpenseService.fetchExpenses();
        return { data };
    }
}