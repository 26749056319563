import { APIUtil } from '../utils/ApiUtils';
import ApiURLs from '../constants/ApiURLs';
import GenUtils from '../utils/GenUtils';

export enum EFileCategory {
    Other = 'other',
    ProfilePic = 'profile_pic',
    Expense = 'expense',
}

export default class FileService {
    static recentlyUploadedFiles = {};

    static async uploadFile(file, category = EFileCategory.Other) {
        FileService.recentlyUploadedFiles = {};

        const { error, data } = await APIUtil.uploadFile(ApiURLs.FILE, {
            file,
            category: category || EFileCategory.Other
        });

        if (error) {
            GenUtils.log('uploadFile', error);
            return { error };
        }

        return { data };
    }
}