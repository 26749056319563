import React, { useContext, useEffect, useRef, useState } from 'react';

import { Button, Card, Col, Form, Row, Table } from 'antd';
import CustomInput from '../input/CustomInput';

const EditableContext = React.createContext(null);

const EditableCellsTable = ({
    title = '',
    subtitle = '',
    columns,
    dataSource,
    setDataSource = (props) => {
    },
    addButtonText = null,
    rightAction = null,
    location = '',
    summary = (d) => null,
    total = 10000,
    onRowChange = (d) => {
    },
    newData = null
}) => {
    const [checkedCode, setCheckedCode] = useState(false);
    const [codeClass, setCodeClass] = useState(false);

    function toggleChecked() {
        setTimeout(() => setCodeClass(!codeClass), 100);
        setCheckedCode(!checkedCode);
    }

    const [count, setCount] = useState((dataSource && dataSource.length) || 1);

    const EditableRow = ({ index, ...props }) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const EditableCell = ({
        title,
        editable,
        children,
        dataIndex,
        record,
        handleSave,
        disableEditing,
        hourCell,
        ...restProps
    }) => {
        const [editing, setEditing] = useState(true);
        const inputRef = useRef(null);
        const form = useContext(EditableContext);

        useEffect(() => {
            // if (editing) {
            //     inputRef.current.focus();
            // }
        }, [editing]);

        const toggleEdit = () => {
            setEditing(!editing);
            form.setFieldsValue({
                [dataIndex]: record[dataIndex]
            });
        };

        const save = async (v) => {
            try {
                if (location == 'phase') {
                    if (hourCell) {
                        record.hourPercent = Number((Number(v) * record.role.hourRate * 100) / total);
                        record.budgetHours = Number(v);
                    } else {
                        record.budgetHours = Number((total * Number(v)) / (100 * record.role.hourRate));
                        record.hourPercent = Number(v);
                        console.log(record);
                    }
                    handleSave(record);
                    onRowChange(record);
                } else {
                    handleSave && handleSave({ ...record, [dataIndex]: v });
                }
            } catch (errInfo) {
                console.log('Save failed:', errInfo);
            }
        };

        let childNodes = children?.reduce((nodes, child) => child && nodes.push(child) && nodes || [], []);

        if (editable && (!childNodes.length || typeof childNodes[0] !== 'object')) {
            childNodes = [(
                <CustomInput
                    type={typeof record[dataIndex]}
                    value={record[dataIndex]}
                    style={{ width: '100%' }}
                    updateOnBlur
                />
            )];
        }

        return (
            <td {...restProps}>{
                childNodes.length > 0 ?
                    childNodes.map(child => (typeof child === 'object' ? {
                        ...child, props: {
                            ...child.props,
                            onChange: (v) => {
                                save(v);
                                child?.props?.onChange && child?.props?.onChange(v);
                            },
                            ...(dataIndex === 'delete-action' ? {onClick: (e) => handleDelete(record.key)} : {})
                        }
                    } : child)) : childNodes
            }</td>
        );
    };

    const handleDelete = (key) => {
        setDataSource(dataSource.filter((item) => item.key !== key));
    };

    const handleAdd = () => {
        setDataSource([...dataSource, { ...newData, key: dataSource.length + 1 }]);
        setCount(count + 1);
    };

    const handleSave = (row) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });

        setDataSource(newData);
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell
        }
    };

    const columnsTable = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                hourCell: col.hourCell,
                handleSave: handleSave,
            })
        };
    });

    return (
        <Card className='hp-border-color-black-40' style={{backgroundColor:'transparent', border:'none'}}>
            <Row>
                {(title || subtitle) && (
                    <Col className='hp-mb-16' lg={15} span={20}>
                        {title && <h4>{title}</h4>}
                        {subtitle && <p className='hp-p1-body'>{subtitle}</p>}
                    </Col>
                )}

                {rightAction && (
                    <Col lg={9} span={4} className='hp-text-right'>
                        {/*<Button*/}
                        {/*    onClick={toggleChecked}*/}
                        {/*    type='text'*/}
                        {/*    icon={<RiCodeSSlashLine className='hp-text-color-black-80' />}*/}
                        {/*/>*/}
                        {rightAction}
                    </Col>
                )}

                <Col span={24} style={{margin:-20, borderRadius:40}}>
                    <Table
                        components={components}
                        summary={summary}
                        rowClassName={() => 'editable-row'}
                        bordered
                        dataSource={dataSource}
                        columns={columnsTable}
                        scroll={{ x: 500 }}
                        pagination={false}
                    />

                    {addButtonText && (
                        <Button
                            onClick={handleAdd}
                            style={{
                                marginTop: 20
                            }}
                            ghost
                        >
                            {addButtonText}
                        </Button>
                    )}
                </Col>
            </Row>
        </Card>
    );
};

export default EditableCellsTable;
