import React, { useState } from 'react';
import GroupedTable, { EColumnType } from '../../../components/table/groupedTable/GroupedTable';
import { EFilterTypes } from '../../../components/filterBars/FiltersBarMultiselect';

import styles from './Charts.scss';
import Avvvatars from 'avvvatars-react';

interface TData {
    startDate: string;
    endDate: string;
    report: Report[];
}

interface Report {
    teamMemberId: TeamMemberId;
    role: TRole;
    scheduledUtilization: number;
    billable: number;
    nonbillable: number;
    totalUtilization: number;
}

interface TeamMemberId {
    email: string;
    firstName: string;
    lastName: string;
    dob: string;
    profilePicUrl: any;
    profilePicFileId?: string;
    contact: string;
    permissionId: string;
    status: string;
    office?: string;
    userUuid: string;
    userId: number;
    uuid: string;
    createdAt: string;
    updatedAt: string;
}

interface TRole {
    name: string;
    hourRate: number;
    userUuid: string;
    projectId: string;
    optionId: string;
    uuid: string;
    createdAt: string;
    updatedAt: string;
}

const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const Scheduled_Utilization = 'Scheduled Utilization(%)';
const Billed_Utilization = 'Billable Utilization(%)';
const Non_Billable_Utilization = 'Non-Billable Utilization(%)';
const Total_Utilization = 'Total Utilization(%)';
const Team_Member = 'teamMember';
const Role = 'role';
const lightRed = '#edc1bf';
const lightGreen = '#a2e0b5';
const B_U_Red = 65;
const B_U_Green = 85;
const T_U_Red = 50;
const T_U_Green = 85;

const TeamUtilization = ({ data = [] as TData[] }) => {
    const rawData = data === null ? [] : data;
    const [_data, setData] = useState<ReturnType<typeof groupByUser>>([]);

    React.useMemo(() => {
        if (data !== null) {
            setData(groupByUser(data));
        }
    }, [data]);

    const filters = [
        {
            name: 'Date',
            dataIndex: null,
            type: EFilterTypes.dateRange
        },
        // {
        //     name: 'Range - Monthly',
        //     dataIndex: null,
        //     type: EFilterTypes.dropdown,
        //     options: ['Weeks', 'Months', 'Quarter']
        // },
        {
            name: 'Team Member',
            dataIndex: 'teamMemberId',
            type: EFilterTypes.dropdown
        },
        {
            name: 'Role',
            dataIndex: Role,
            type: EFilterTypes.dropdown
        }
    ];

    const columns =
        rawData.length > 0
            ? [
                  {
                      title: '',
                      bordered: true,
                      children: [
                          {
                              title: 'Team Member',
                              dataIndex: Team_Member,
                              type: EColumnType.member,
                              fixed: 'left',
                              width: 200,
                              render: (t: TeamMemberId) => (
                                  <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                                      {t.profilePicUrl ? (
                                          <img src={t.profilePicUrl} alt="user-pfp" height={30} width={30} style={{ objectFit: 'cover' }} />
                                      ) : (
                                          <Avvvatars size={30} value={t.email} />
                                      )}
                                      <span style={{ fontWeight: 600 }}>{composeFullName(t)}</span>
                                  </div>
                              )
                          }
                          //   {
                          //       title: 'Role',
                          //       dataIndex: Role,
                          //       type: EColumnType.text,
                          //       width: 150,
                          //       render: (t: string) => <span style={{ fontWeight: 400 }}>{capFirstLetter(t)}</span>
                          //   }
                      ]
                  },
                  {
                      title: '',
                      bordered: true,
                      children: [
                          {
                              title: 'Role',
                              dataIndex: Role,
                              type: EColumnType.text,
                              width: 150,
                              render: (t: string) => <span style={{ fontWeight: 400 }}>{capFirstLetter(t)}</span>
                          }
                      ]
                  },

                  ...rawData.map((d) => {
                      const key = getHeaderWeekRange(d);
                      return {
                          title: key,
                          dataIndex: key,
                          key,
                          children: [
                              {
                                  title: Scheduled_Utilization,
                                  dataIndex: `${key}-${Scheduled_Utilization}`,
                                  key: `${key}-${Scheduled_Utilization}`,
                                  width: 120,
                                  render: (t: string) => <CellAbsolute modifyStyle={false} t={t} red={0} green={0} />
                              },
                              {
                                  title: Billed_Utilization,
                                  dataIndex: `${key}-${Billed_Utilization}`,
                                  key: `${key}-${Billed_Utilization}`,
                                  width: 120,
                                  render: (t: string) => <CellAbsolute modifyStyle={true} t={t} red={B_U_Red} green={B_U_Green} />
                              },
                              {
                                  title: Non_Billable_Utilization,
                                  dataIndex: `${key}-${Non_Billable_Utilization}`,
                                  key: `${key}-${Total_Utilization}`,
                                  width: 120,
                                  render: (t: string) => <CellAbsolute modifyStyle={false} t={t} red={0} green={0} />
                              },
                              {
                                  title: Total_Utilization,
                                  dataIndex: `${key}-${Total_Utilization}`,
                                  key: `${key}-${Total_Utilization}`,
                                  width: 120,
                                  render: (t: string) => <CellAbsolute modifyStyle={true} t={t} red={T_U_Red} green={T_U_Green} />
                              }
                          ]
                      };
                  })
              ]
            : [];

    return (
        <div className={styles.container}>
            <GroupedTable bordered scrollX={2000} columns={columns} data={_data} filters={filters} />
        </div>
    );
};

function groupByUser(data: TData[]) {
    const users = data.reduce((a, b) => a.concat(b.report), [] as Report[]);
    const unique_users: Array<Report> = Object.values(users.reduce((a, b) => ({ ...a, [b.teamMemberId.uuid]: b }), {}));

    const arr = new Array<{ [key: string]: unknown }>(unique_users.length);

    for (let j = 0; j < unique_users.length; j++) {
        const user = unique_users[j];
        const obj: (typeof arr)[number] = {};
        for (let i = 0; i < data.length; i++) {
            const item = data[i];
            const key = getHeaderWeekRange(item);
            const filtered = item.report.filter((i) => i.teamMemberId.uuid === user.teamMemberId.uuid);
            if (filtered.length > 0) {
                const u = filtered[filtered.length - 1];
                obj['teamMemberId'] = u.teamMemberId.uuid;
                obj[Team_Member] = u.teamMemberId;
                obj[Role] = u.role.name;
                obj[`${key}-${Scheduled_Utilization}`] = roundTwoDecimal(u.scheduledUtilization);
                obj[`${key}-${Billed_Utilization}`] = roundTwoDecimal(u.billable);
                obj[`${key}-${Non_Billable_Utilization}`] = roundTwoDecimal(u.nonbillable);
                obj[`${key}-${Total_Utilization}`] = roundTwoDecimal(u.totalUtilization);
            } else {
                const u = user;
                obj['teamMemberId'] = u.teamMemberId.uuid;
                obj[Team_Member] = u.teamMemberId;
                obj[Role] = u.role.name;
                obj[`${key}-${Scheduled_Utilization}`] = roundTwoDecimal(0);
                obj[`${key}-${Billed_Utilization}`] = roundTwoDecimal(0);
                obj[`${key}-${Non_Billable_Utilization}`] = roundTwoDecimal(0);
                obj[`${key}-${Total_Utilization}`] = roundTwoDecimal(0);
            }
        }
        arr[j] = obj;
    }
    return arr;
}

function getHeaderWeekRange(item: TData) {
    const start = new Date(item.startDate);
    const end = new Date(item.endDate);
    const key = `${month[start.getMonth()]} ${start.getDate()} - ${month[end.getMonth()]} ${end.getDate()}`;
    return key;
}

function roundTwoDecimal(num: number) {
    return (Math.round(num * 100) / 100).toFixed(2) + '%';
}

function composeFullName(t: TeamMemberId) {
    return `${t.firstName} ${t.lastName}`;
}

function capFirstLetter(s: string) {
    if (s.length === 0) return s;
    else {
        return s[0].toUpperCase() + s.slice(1);
    }
}

function CellAbsolute(props: { t: string; red: number; green: number; modifyStyle: boolean; style?: React.CSSProperties }) {
    const num = parseFloat(props.t.split('%')[0]);
    let bg = 'white';
    let fontWeight = 'normal';
    if (!isNaN(num)) {
        if (num < props.red) {
            bg = lightRed;
            fontWeight = 'bold';
        } else if (num > props.green) {
            bg = lightGreen;
            fontWeight = 'bold';
        }
    }

    const rest = props.modifyStyle
        ? { background: bg, fontWeight }
        : {
              background: 'white'
          };

    return (
        <div
            style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...rest,
                ...props.style
            }}>
            {props.t}
        </div>
    );
}
export default TeamUtilization;
