import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';

import { Button, Checkbox, Form, Input } from 'antd';
import CopyrightFooter from '../../components/footer/CopyrightFooter';

import { IStore } from '../../redux/store';

import loginStyles from './LoginPage.scss';
import styles from '../form.scss';
import AuthService from '../../services/AuthService';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import { BOOK_A_DEMO_URL } from '../../constants/Constants';

const LoginPage = () => {
    const [password, setPassword] = useState('');
    const [userName, setUserName] = useState('');

    const firmId = useSelector((state: IStore) => state?.firm?.uuid);
    const navigateTo = useNavigate();
    const dispatch = useDispatch();

    const onFinish = async (values: any) => {

        if (userName === '') {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('An error Occurred', 'Please enter your email', <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }
        if (password === '') {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('An error Occurred', 'Please enter your password', <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }
        const { data, error } = await AuthService.login(userName, password, dispatch);
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Login Failed', '', <notificationIcon.icon style={notificationIcon.style} />);
            return;
        } else if (!data?.accessToken) {
            navigateTo(`/verify`, { state: { email: userName } });
            return;
        }
        navigateTo(`/${data.firms[0].uuid}/dashboard`);
    };


    return (
        <div className={classnames(loginStyles.section, loginStyles.form)}>
            <h1 className={loginStyles.title}>Login</h1>
            <p className={loginStyles.subtitle}>Welcome back, please login to your account.</p>
            <Form
                name='normal_login'
                initialValues={{ remember: true }}
                onFinish={onFinish}
            >
                <Form.Item
                    label='Email'
                    className={styles.label}
                >
                </Form.Item>
                <Input className={styles.input}
                       onChange={(e) => {
                           setUserName((e.target.value).trim());
                       }}
                />
                <Form.Item
                    label='Password'
                    className={styles.label}
                >
                </Form.Item>
                <Input.Password className={styles.input}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                                visibilityToggle
                                iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
                />
                <Form.Item className={classnames(styles.label)}>
                    <div className={loginStyles.rememberContainer}>
                        <Form.Item name='remember' valuePropName='checked' noStyle>
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                        <div
                            className={loginStyles.forgotPassword}
                        >
                            <p
                                onClick={() => {
                                    navigateTo('/recover-password');
                                }}
                            >Forgot password
                            </p>
                        </div>
                    </div>
                </Form.Item>
                <Form.Item>
                    <Button
                        type='primary'
                        htmlType='submit'
                        className={loginStyles.loginButton}
                        block
                    >
                        Log In
                    </Button>
                </Form.Item>
            </Form>
            <div className={loginStyles.createText}>
                Don't you have an account?
                <span
                    className={loginStyles.create}
                    onClick={() => {
                        window.location.href = BOOK_A_DEMO_URL
                    }}
                >Book a Demo</span>
            </div>
            <CopyrightFooter />
        </div>
    );
};
export default LoginPage;
