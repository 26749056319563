import { ITimeOffType } from '../../interfaces/ITimeOffType';

export const setTimeOffTypes = (timeOffTypes: ITimeOffType[]) => {
    return {
        type: 'SET_TIME_OFF_TYPES',
        payload: timeOffTypes
    };
};

export const updateTimeOffType = (uuid, timeOffType: ITimeOffType) => {
    return {
        type: 'UPDATE_TIME_OFF_TYPE',
        payload: { uuid, ...timeOffType }
    };
};
