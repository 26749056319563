import IInvoice from '../../interfaces/IInvoice';

export interface IInvoiceState {
    invoices: {
        [key: string]: IInvoice;
    },
    lastInvoice: IInvoice,
}

export const initialInvoiceState: IInvoiceState = {
    invoices: {},
    lastInvoice: null,
};

const invoiceReducer = (state: IInvoiceState, action: { type: string, payload: IInvoice }) => {
    switch (action.type) {
        case 'SET_INVOICES':
            return {
                ...state,
                invoices: action.payload,
            };
        case 'SET_LAST_INVOICE':
            return {
                ...state,
                lastInvoice: action.payload,
            };
        case 'UPDATE_INVOICE':
            return {
                ...state,
                invoices: {
                    ...state.invoices,
                    [action.payload.uuid]: {
                        ...state.invoices[action.payload.uuid],
                        ...action.payload,
                    },
                }
            };
        default:
            return state || initialInvoiceState;
    }
};

export default invoiceReducer;