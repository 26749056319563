import FormatUtils from '../../../utils/FormatUtils';
import styles from './TotalRow.scss';

const TotalRow = ({ label, total, isNegative=false }) => {
    return (
        <div className={styles.container}>
            <div className={styles.empty} />
            <div className={styles.data}>
                <div className={styles.label}>
                    {label || 'Total'}
                </div>
                <div className={styles.total}>
                    {isNegative ? '- (' : ''}
                    {FormatUtils.getAmountInFormat(total, true, 2, 0)}
                    {isNegative ? ')' : ''}
                </div>
            </div>
        </div>
    )
}

export default TotalRow;