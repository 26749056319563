import React, { useState } from 'react';

import { Button, Col, Divider, Form, Input, Modal, Row } from 'antd';
import { RiCloseFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import AuthService from '../../services/AuthService';

const SecuritySettings = () => {
    const [passwordModalVisible, setPasswordModalVisible] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const { email } = useSelector((state: IStore) => state.user);

    const dividerClass = 'hp-border-color-black-40 hp-border-color-dark-80';

    const passwordModalShow = () => {
        setPasswordModalVisible(true);
    };

    const passwordModalCancel = () => {
        setPasswordModalVisible(false);
    };

    const resetPassword = async () => {
        const { data, error } = await AuthService.changePassword(email, oldPassword, newPassword);
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Reset Password Failed', error, <notificationIcon.icon
                style={notificationIcon.style} />);
        } else {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
            GenUtils.showNotification('Reset Password Successful', '', <notificationIcon.icon
                style={notificationIcon.style} />);
            passwordModalCancel();
        }
    };

    return (
        <div className='hp-profile-security'>
            <Modal
                title='Change Password'
                width={416}
                centered
                visible={passwordModalVisible}
                onCancel={passwordModalCancel}
                footer={null}
                closeIcon={
                    <RiCloseFill className='remix-icon text-color-black-100' size={24} />
                }
            >
                <Form layout='vertical' name='basic' initialValues={{ remember: true }}>
                    <Form.Item
                        label='Old Password'
                        name='oldPassword'
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your old password'
                            }
                        ]}
                    >
                        <Input value={oldPassword} type='password' onChange={(e) => setOldPassword(e.target.value)} />
                    </Form.Item>

                    <Form.Item
                        label='New Password'
                        name='newPassword'
                        rules={[
                            {
                                required: true,
                                message: 'Please enter a new password'
                            },
                            {
                                message: 'Password too short',
                                validator: (_, value) => {
                                    if (newPassword.length > 7) {
                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject();
                                    }
                                }
                            }
                        ]}
                    >
                        <Input value={newPassword} type='password' onChange={(e) => setNewPassword(e.target.value)} />
                    </Form.Item>

                    <Form.Item
                        label='Confirm Password'
                        name='confirmPassword'
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the new password again'
                            },
                            {
                                message: 'Passwords do not match',
                                validator: (_, value) => {
                                    if (newPassword === confirmPassword) {
                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject('Passwords do not match.');
                                    }
                                }
                            }
                        ]}
                    >
                        <Input value={confirmPassword} type='password'
                               onChange={(e) => setConfirmPassword(e.target.value)} />
                    </Form.Item>
                    <Row style={{ gap: '20px', display: 'flex' }}>
                        <Col md={11} span={24} className='hp-mt-sm-12 hp-pl-sm-0 hp-pl-12'>
                            <Button block onClick={passwordModalCancel}>
                                Cancel
                            </Button>
                        </Col>
                        <Col md={11} span={24} className='hp-pr-sm-0 hp-pr-12'>
                            <Button
                                block
                                type='primary'
                                htmlType='submit'
                                onClick={resetPassword}
                            >
                                Update
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <h1>Security Settings</h1>
            <p className='hp-p1-body hp-mb-0'>
                These settings are helps you keep your account secure.
            </p>

            <Divider className={dividerClass} />

            <Row align='middle' justify='space-between'>
                <Col md={12}>
                    <h3>Change Password</h3>
                    <p className='hp-p1-body hp-mb-0'>
                        Set a unique password to protect your account.
                    </p>
                </Col>
                <Col md={12} span={24} className='hp-profile-action-btn hp-text-right'>
                    <Button type='primary' ghost onClick={passwordModalShow}>
                        Change Password
                    </Button>
                </Col>
            </Row>

            <Divider className={dividerClass} />

            <Row align='middle' justify='space-between'>
                <Col md={12}>
                    <h3>2 Factor Auth</h3>
                    <p className='hp-p1-body hp-mb-0'>
                        Secure your account with 2FA security. When it is activated you will
                        need to enter not only your password, but also a special code using
                        app. You can receive this code by in mobile app.
                    </p>
                </Col>

                <Col md={12} span={24} className='hp-profile-action-btn hp-text-right'>
                    <Button type='primary' ghost>
                        Coming Soon!
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default SecuritySettings;
