import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import AllocatePhaseCard from '../../components/card/AllocatePhaseCard';
import HeaderSecondary from '../../components/header/HeaderSecondary';
import { Button, Row, Switch } from 'antd';

import Style from '../../components/tabs/SwitchTabs.scss';
import styles from './AllocateWorkPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import { getUserDetails } from '../../redux/selectors/UserSelector';
import MomentUtils from '../../utils/MomentUtils';
import moment from 'moment';
import FiltersBar from '../../components/filterBars/FiltersBar';
import { toggleFinancials } from '../../redux/actions/ConfigActions';
import PermissionUtils from '../../utils/PermissionUtils';

const AllocateWorkPage = () => {
    const [projectFilter, setProject] = useState(null);
    const [roleFilter, setRole] = useState(null);
    const [categoryFilter, setCategory] = useState(null);
    const [teamMemberFilter, setTeamMember] = useState(null);
    const [isProjectSelected, setProjectSelected] = useState(true);
    const [datesStart, setDatesStart] = useState(moment().toISOString());
    const [weeksShifted, setWeeksShiftedBy] = useState(0);
    const [projectsData, setProjectsData] = useState([]);
    const [teamMemberData, setTeamMemberData] = useState([]);
    const [activeCard, setActiveCard] = useState('');

    const { phase, project, teamMember, phaseMember, timesheet: allocatedTimesheet } = useSelector((state: IStore) => state.timeLogs.allocation);
    const loggedInUser = useSelector((state: IStore) => getUserDetails(state));
    const phaseMembers = useSelector((state: IStore) => state.members.phaseMembers);
    const memberArray = useSelector((state: IStore) => state?.team?.membersArray);
    const { showFinancials } = useSelector((state: IStore) => state.config);

    const dispatch = useDispatch();

    useEffect(() => {
        setDatesStart(MomentUtils.getCurrentWeekStart().toISOString());
        dispatch(toggleFinancials(PermissionUtils.canViewFinancesInAllocation()));
    }, [loggedInUser.weekStart]);

    const filters = [
        {
            name: 'Project',
            options: [
                {
                    name: 'All Projects',
                    uuid: 'any'
                },
                ...Object.values(project || {})
            ],
            onSelect: (v) => setProject(v)
        },
        // {
        //     name: 'Role',
        //     options: [
        //         {
        //             name: 'Any Role',
        //             uuid: 'any'
        //         },
        //         ...roles
        //     ],
        //     onSelect: (v) => setRole(v)
        // },
        {
            name: 'Category',
            options: [
                {
                    name: 'Proj1',
                    uuid: '12345'
                },
                {
                    name: 'Proj2',
                    uuid: '12344'
                },
                {
                    name: 'Proj3',
                    uuid: '1235'
                }
            ],
            onSelect: (v) => setCategory(v)
        },
        {
            name: 'Team Member',
            options: [
                {
                    name: 'All Members',
                    uuid: 'any'
                },
                ...memberArray.map((m) => {
                    return { name: m.firstName + ' ' + m.lastName, uuid: m.uuid };
                })
            ],
            onSelect: (v) => setTeamMember(v)
        }
    ];

    useEffect(() => {
        let data = Object.values(project || {}).map((pr: any) => {
            let phases = Object.values(phase).filter((phse: any) => phse.projectId === pr.uuid);
            phases = phases.map((phse: any) => {
                let members = [];
                members = phse.memberIds.map((id) => phaseMember[id]);
                return { ...phse, members };
            });
            return { ...pr, phases };
        });
        setProjectsData(data);
    }, [project, phase, phaseMember]);

    const applyFilters = (dataItem) => {
        if (projectFilter && dataItem.project.toLowerCase() !== projectFilter.toLowerCase() && projectFilter !== 'any') {
            return false;
        }
        if (roleFilter && dataItem.role?.uuid !== roleFilter && roleFilter !== 'any') {
            return false;
        }

        if (categoryFilter && dataItem.category !== categoryFilter && categoryFilter !== 'any') {
            return false;
        }
        if (teamMemberFilter && !(dataItem.firstName + ' ' + dataItem.lastName).toLowerCase().match(teamMemberFilter.toLowerCase())) {
            return false;
        }

        return true;
    };

    useEffect(() => {
        let data = Object.values(teamMember || {});
        data = data.map((member: any) => {
            let membersObj = member.members.map((member) => {
                let memberPhase = phase[member.phaseId];
                let memberProject = project[member.projectId];
                let phaseMembers = phaseMember[member.uuid];
                return { ...member, phase: memberPhase, project: memberProject, member: phaseMembers };
            });
            return { ...member, members: membersObj };
        });
        setTeamMemberData(data);
    }, [teamMember, phase, project, phaseMember]);

    return (
        <>
            <HeaderSecondary
                renderImage={() => <img src={require('../../assets/allocate.png')} />}
                text={'Schedule Work to Team'}
                subtext={'Schedule weekly work hours to your team members for project phases'}
                renderAction={() => (
                    <Row className={Style.switchTabs}>
                        <div
                            className={classnames(Style.left, isProjectSelected && Style.leftActive)}
                            onClick={() => {
                                setProjectSelected(true);
                            }}>
                            {' '}
                            Project
                        </div>
                        <div
                            className={isProjectSelected ? Style.right : Style.rightActive}
                            onClick={() => {
                                setProjectSelected(false);
                            }}
                            style={{ whiteSpace: 'nowrap' }}>
                            {' '}
                            Team Member
                        </div>
                    </Row>
                )}
            />
            <FiltersBar
                filters={[]}
                renderRightOptions={() => (
                    <div className={styles.container}>
                        {PermissionUtils.canViewFinancesInAllocation() ? (
                            <Row className={styles.switch}>
                                <div className={styles.text}>Financials</div>
                                <Switch
                                    className={styles.switchButton}
                                    checked={showFinancials}
                                    onChange={() => {
                                        dispatch(toggleFinancials(!showFinancials));
                                    }}
                                />
                            </Row>
                        ) : null}
                        {/*<Button className={styles.addTimeOff} style={{ display: 'flex', flex: 1 }}>*/}
                        {/*    + Add Time Off{' '}*/}
                        {/*</Button>*/}
                    </div>
                )}
                selectedFilters={{}}
            />

            <AllocatePhaseCard
                dateHeader
                datesStart={moment(datesStart)}
                setDatesStart={(d) => setDatesStart(d.toISOString())}
                shiftWeek={(n) => setWeeksShiftedBy((w) => w + n)}
                isTeamMember={!isProjectSelected}
            />

            {isProjectSelected ? (
                <>
                    {projectsData.map((project) => (
                        <div key={project?.uuid}>
                            {project?.phases.map((phase) => (
                                <AllocatePhaseCard
                                    key={phase.uuid}
                                    phase={phase}
                                    project={project}
                                    datesStart={moment(datesStart)}
                                    weeksShifted={weeksShifted}
                                    isTeamMember={!isProjectSelected}
                                    activeCard={activeCard}
                                    setActiveCard={setActiveCard}
                                />
                            ))}
                        </div>
                    ))}
                </>
            ) : (
                <>
                    {teamMemberData.map((member: any) => {
                        return (
                            <AllocatePhaseCard
                                key={member.uuid}
                                // data={memberArray.filter(applyFilters)}
                                datesStart={moment(datesStart)}
                                weeksShifted={weeksShifted}
                                isTeamMember={!isProjectSelected}
                                teamMember={member}
                                activeCard={activeCard}
                                setActiveCard={setActiveCard}
                            />
                        );
                    })}
                </>
            )}
        </>
    );
};

export default AllocateWorkPage;