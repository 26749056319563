import Colors from '../../constants/Colors';
import GenUtils from '../../utils/GenUtils';

const Tag = ({ color = null, style = {}, className = '', text = '' as any }) => {
    const backgroundColor = color || GenUtils.getTagColorByStatus(text || '')

    return (
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{
                backgroundColor,
                padding: '0px 5px',
                borderRadius: '5px',
                height: 'fit-content',
                fontWeight: 'bold',
                color: GenUtils.getTextColorBasedOnBgColorAdvanced(backgroundColor),
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                boxShadow: `0 0 3px ${GenUtils.getTextColorBasedOnBgColorAdvanced(backgroundColor, true, Colors.white, Colors.grayLight2)}`,
                ...style,
            }}>
                {GenUtils.capitalizeFirstLetter(text)}
            </div>
        </div>
    );
};

export default Tag;
