import { Col, Row, Timeline } from 'antd';

import FormatUtils, { EDateTimeFormat } from '../../utils/FormatUtils';

import styles from './Timeline.scss';
import GenUtils from '../../utils/GenUtils';
import { ICommunication } from '../../redux/reducers/CommunicationReducer';

const ActivityTimeline = ({ data }: { data: ICommunication[] }) => {

    const TimeLabel = ({ time }) => {
        return (
            <div className={styles.timeContainer}>
                {FormatUtils.getDateTimeInFormat(time, EDateTimeFormat.HH_MM_a_day)}<br />
                {FormatUtils.getDateTimeInFormat(time, EDateTimeFormat.MMM_DD_YYYY)}
            </div>
        );
    };

    const getLabel = (action: string) => {
        switch (action.toLowerCase()) {
            case 'created':
                return 'Created Invoice';
            case 'updated':
                return 'Updated Invoice Details';
            default:
                return action;
        }
    }

    return (
        <div style={{ border: '0px', padding: 0, margin: 0 }}>
            <Row>
                <Col span={24}>
                    <Timeline mode={'left'}>
                        {
                            data.map((item: any) => {
                                return <Timeline.Item
                                    key={item.uuid}
                                    label={<TimeLabel time={item.createdAt} />}
                                >
                                    <div className={styles.timelineLabelContainer}>
                                        <div className={styles.row}>
                                            <div className={styles.title}>
                                                {GenUtils.capitalizeFirstLetter(getLabel(item.action))}
                                            </div>
                                        </div>
                                        <div className={styles.description}>
                                            {`- by ${item.actionBy} `}
                                        </div>
                                    </div>
                                </Timeline.Item>;
                            }
                            )
                        }
                    </Timeline>
                </Col>
            </Row>
        </div>
    );
};

export default ActivityTimeline;
