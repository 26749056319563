import ITask from '../../interfaces/ITask';
import GenUtils from '../../utils/GenUtils';

export const setTasks = (tasks: ITask[], loading: boolean) => {
    return {
        type: 'SET_TASKS',
        payload: {
            tasks: GenUtils.arrayToIdMap(tasks),
            tasksArray: tasks
        },
        loading
    };
};

export const updateTask = (taskId, task: ITask) => {
    return {
        type: 'UPDATE_TASK',
        payload: { uuid: taskId, ...task }
    };
};
