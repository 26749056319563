import { Modal } from 'antd';
import { RiCloseFill } from 'react-icons/ri';
import AddPhaseRow from '../AddPhaseRow';

const AddRowModal = ({ showAddRowModal, setShowAddRowModal, selectedTeamMemberId, addRow }) => {
    return (
        <Modal
            title="Add Row"
            width={800}
            centered
            open={showAddRowModal}
            onCancel={() => setShowAddRowModal(false)}
            footer={null}
            closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}>
            <AddPhaseRow
                hideModal={() => setShowAddRowModal(false)}
                onSuccess={(data) => {
                    addRow(data, new Date());
                    setShowAddRowModal(false);
                }}
                teamMemberId={selectedTeamMemberId}
                includeActivity
            />
        </Modal>
    );
};

export default AddRowModal;
