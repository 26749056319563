import { Row, Col } from 'antd';
import moment from 'moment';
import GenUtils from '../../../utils/GenUtils';
import styles from './HeaderRow.scss';

const HeaderRow = ({ weekCount, weekRange }) => {
    return (
        <Row className={styles.rowHeader}>
            <Col span={4}>Project - Phase</Col>
            <Col span={2} style={{ textAlign: 'right', paddingRight: 15 }}>
                Hours to Work
            </Col>
            <Col span={18}>
                <Row justify="space-between">
                    <Col span={5} style={{ paddingLeft: 10 }}>
                        Activity
                    </Col>
                    {[...Array(weekCount)].map((e, i) => (
                        <Col className={styles.weekDays} span={Math.floor(18 / weekCount)} key={i}>
                            {GenUtils.getWeekDaySmallString(moment(GenUtils.getDifferenceDate(weekRange[0], i - 1)).day())}
                            <br />
                            <span
                                style={{
                                    fontWeight: '400',
                                    fontSize: 12
                                }}>
                                {GenUtils.getDateAndMonth(GenUtils.getDifferenceDate(weekRange[0], i))}
                            </span>
                        </Col>
                    ))}
                    <Col span={2}>Total Hours</Col>
                </Row>
            </Col>
        </Row>
    );
};

export default HeaderRow;
