import React from 'react';
import { LeftOutlined, RightOutlined, RollbackOutlined } from '@ant-design/icons';
import FormatUtils from '../../utils/FormatUtils';
import GenUtils from '../../utils/GenUtils';
import { DatePicker, Popover, Radio } from 'antd';
import MomentUtils from '../../utils/MomentUtils';
import styles from './WeekIterator.scss';
import Tag from '../tag/Tag';

const WeekIterator = ({
    weekRange,
    setWeekRange,
    selectedDate = new Date(),
    setSelectedDate = (d) => {},
    showCalenderType = true,
    calendarType = 'week',
    setCalendarType = (d) => {},
    timesheetStatus = null,
    prefixComp = null,
    suffixComp = null
}) => {
    const changeDate = (type) => {
        if (calendarType === 'week') {
            let change = type === 'back' ? -7 : 7;
            setWeekRange(MomentUtils.getWeekDates(GenUtils.getDifferenceDate(weekRange[0], change)));
        } else {
            const date = type === 'back' ? MomentUtils.getPreviousDate(selectedDate).toDate() : MomentUtils.getNextDate(selectedDate).toDate()
            setSelectedDate(date)
            if (date.getTime() < weekRange[0].getTime()) {
                setWeekRange(MomentUtils.getWeekDates(GenUtils.getDifferenceDate(weekRange[0], -7)));
            } else if (date.getTime() > weekRange[1].getTime()) {
                setWeekRange(MomentUtils.getWeekDates(GenUtils.getDifferenceDate(weekRange[0], 7)));
            }
        }
    };
    return (
        <div className={styles.container}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <LeftOutlined
                    style={{
                        background: 'white',
                        padding: 10,
                        borderRadius: 8,
                        marginRight: 10,
                        borderWidth: 0.5,
                        borderStyle: 'solid',
                        borderColor: '#DFE6E9',
                        fontSize: 18
                    }}
                    onClick={() => changeDate('back')}
                />
                <RightOutlined
                    style={{
                        background: 'white',
                        padding: 10,
                        borderRadius: 8,
                        marginRight: 10,
                        borderWidth: 0.5,
                        borderStyle: 'solid',
                        borderColor: '#DFE6E9',
                        fontSize: 18
                    }}
                    onClick={() => changeDate('forward')}
                />
                {calendarType === 'week' ? (
                    <>
                        <strong>{GenUtils.isDateInRange(new Date(), weekRange[0], weekRange[1]) ? 'This Week:' : null}</strong>&nbsp;&nbsp;
                        {FormatUtils.getFormattedDateFromTimestamp(weekRange[0])}-{FormatUtils.getFormattedDateFromTimestamp(weekRange[1])}
                        {GenUtils.isDateInRange(new Date(), weekRange[0], weekRange[1]) ? null : (
                            <span
                                onClick={() => setWeekRange(MomentUtils.getWeekDates(new Date()))}
                                style={{ paddingLeft: 10, fontSize: 15, color: 'blue', textDecorationLine: 'underline', cursor: 'pointer' }}>
                                <Popover content={'Return to current week'} placement={'bottom'} trigger={'hover'}>
                                    <RollbackOutlined />
                                </Popover>
                            </span>
                        )}
                    </>
                ) : (
                    <>
                        <strong>{MomentUtils.isToday(selectedDate) ? 'Today:  ' : null}</strong>&nbsp;&nbsp;
                        {FormatUtils.getFormattedDateFromTimestamp(selectedDate)}
                        {MomentUtils.isToday(selectedDate) ? null : (
                            <span
                                onClick={() => setSelectedDate(new Date())}
                                style={{ paddingLeft: 10, fontSize: 15, color: 'blue', textDecorationLine: 'underline' }}>
                                Return to Today
                            </span>
                        )}
                    </>
                )}
                {timesheetStatus ? <Tag style={{ marginLeft: 10, fontSize: 14 }} text={GenUtils.capitalizeFirstLetter(timesheetStatus)} /> : null}
            </div>
            <div className={styles.weekSegment}>
                {showCalenderType && (
                    <>
                        <DatePicker
                            className={styles.datePicker}
                            inputReadOnly
                            allowClear={false}
                            picker={calendarType === 'week' ? 'week' : null}
                            onChange={(e) => {
                                if (!e) return;
                                setWeekRange(MomentUtils.getWeekDates(e?.toDate()));
                                setSelectedDate(e?.toDate());
                            }}
                        />
                        <Radio.Group value={calendarType} onChange={(e) => setCalendarType(e.target.value)} className={styles.radioGroup}>
                            <Radio.Button value={'week'}>Week</Radio.Button>
                            <Radio.Button value={'day'}>Day</Radio.Button>
                        </Radio.Group>
                    </>
                )}
                {prefixComp}
                {suffixComp}
            </div>
        </div>
    );
};

export default WeekIterator;
