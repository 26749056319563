import moment from 'moment';

export default class MomentUtils {
    static weekStart = 'monday';
    static yearStart = 'january';

    /**
     * First day of the week
     */
    static getCurrentWeekStart(): moment.Moment {
        const weekStartDate = moment().day(MomentUtils.weekStart).endOf('d');
        return MomentUtils.isDayAfterToday(weekStartDate) ? weekStartDate.subtract(7, 'd') : weekStartDate;
    }

    /**
     * Last day of the week
     */
    static getCurrentWeekEnd(): moment.Moment {
        return MomentUtils.getCurrentWeekStart().add(6, 'd').endOf('d');
    }

    static isToday(date): boolean {
        return moment(date).isSame(moment(), 'day');
    }

    static isDayBeforeToday(date): boolean {
        return moment(date).isBefore(moment(), 'day');
    }

    static isDayAfterToday(date): boolean {
        return moment(date).isAfter(moment(), 'day');
    }

    static isBeforeThisWeek(date, weekCount = 0): boolean {
        return moment(date).isBefore(
            MomentUtils.getCurrentWeekStart()
                .add(weekCount * 7, 'd')
                .format('YYYY-MM-DD'),
            'day'
        );
    }

    static isAfterThisWeek(date, weekCount = 0): boolean {
        return moment(date).isAfter(
            MomentUtils.getCurrentWeekEnd()
                .add(weekCount * 7, 'd')
                .format('YYYY-MM-DD'),
            'day'
        );
    }

    static isInThisWeek(date, weekCount = 0): boolean {
        return moment(date).isBetween(
            MomentUtils.getCurrentWeekStart()
                .add(weekCount * 7, 'd')
                .format('YYYY-MM-DD'),
            MomentUtils.getCurrentWeekEnd()
                .add(weekCount * 7, 'd')
                .format('YYYY-MM-DD'),
            'day',
            '[]'
        );
    }

    static getDiffFomWeekStart(date, weekCount = 0): number {
        return MomentUtils.isInThisWeek(date, weekCount)
            ? moment(date).diff(
                MomentUtils.getCurrentWeekStart()
                    .add(weekCount * 7, 'd')
                    .format('YYYY-MM-DD'),
                'd'
            )
            : -1;
    }

    static getNextDate(date) {
        return moment(date).add(1, 'day');
    }

    static getPreviousDate(date) {
        return moment(date).subtract(1, 'day');
    }

    static getWeekDates = (date: Date) => {
        let days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        const dayOfWeek = date.getDay();
        const daysUntilWeekStart = (7 + (days.findIndex((d) => d === MomentUtils.weekStart) - dayOfWeek)) % 7;
        const weekStartDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + (daysUntilWeekStart ? daysUntilWeekStart - 7 : 0));
        const weekEndDate = new Date(weekStartDate.getFullYear(), weekStartDate.getMonth(), weekStartDate.getDate() + 6);
        return [weekStartDate, weekEndDate];
    };

    static getGmtDayStart = (_date) => {
        const date = moment(_date);
        return moment()
            .utc()
            .year(date.year())
            .month(date.month())
            .date(date.date())
            .startOf('date')
            .toISOString();
    };
}
