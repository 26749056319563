/**
 * A custom class like Debounce/Throttle for our use case
 * it executes the given function only once in a given interval
 * calls with the last used params only
 *
 * USAGE:
 * const limiter = new CallLimiter(functionToBeExecuted, 3000); // create this once;
 * limiter.call("props", "required", forTheFunction)   // call like this whenever required
 */
export default class CallLimiter {
    private readonly milliseconds = 0;    // milliseconds
    private props = [];
    private isRunning = false;      // to check if timer is running or not
    private isAgentWaiting = false;     // to know that a function is waiting to be executed
    private timeout;

    constructor(func, milliseconds) {
        this.func = func;
        this.milliseconds = milliseconds;
    }

    public call(...props) {
        this.props = props;
        if (this.isRunning) {
            // already started a delay cycle, just store the props and wait for it to complete
            this.isAgentWaiting = true;
        } else {
            // first trigger of limiter, just run the function and start the timeout
            // this.func(...props);
            this.isAgentWaiting = true;
            this.startTimeout();
        }
    }

    public setAction(func) {
        this.func = func;
    }

    private func = (...p) => {
    };    // function

    private startTimeout() {
        this.isRunning = true;
        this.timeout = setTimeout(() => {
            this.isRunning = false;

            // execute the function with the latest props only if there is an agent waiting
            // Note: otherwise it can result in an infinite loop of timeouts
            if (this.isAgentWaiting) {
                this.isAgentWaiting = false;
                this.func(...this.props);
                this.startTimeout();
            }
        }, this.milliseconds);
    }
}