import { Row } from 'antd';
import styles from './PhaseTimeline.scss';
import React from 'react';
import Tag from '../tag/Tag';
import FormatUtils from '../../utils/FormatUtils';
import GenUtils from '../../utils/GenUtils';

const PhaseTimeline = ({ projectName, serial, color, phaseName, projectStatus, abbreviation, startDate, endDate }) => {
    return (
        <div className={styles.container}>
            <Row style={{ alignItems: 'center', marginBottom: 5 }}>
                <Tag text={serial} color={color} />
                <div className={styles.heading}>{GenUtils.capitalizeFirstLetter(projectName)}</div>
                <Tag text={GenUtils.capitalizeFirstLetter(projectStatus)}
                     color={GenUtils.getTagColorByStatus(projectStatus)} />
            </Row>
            <Row>
                <Tag text={abbreviation} color={color} />
                <div className={styles.subHeading}>{GenUtils.capitalizeFirstLetter(phaseName)}</div>
            </Row>
            <Row style={{ marginTop: 10 }}>
                <div className={styles.dates}>Start Date:</div>
                <div className={styles.dates}>{FormatUtils.getFormattedDateFromTimestamp(startDate)}</div>
            </Row>
            <Row>
                <div className={styles.dates}>End Date:</div>
                <div className={styles.dates}>{FormatUtils.getFormattedDateFromTimestamp(endDate)}</div>
            </Row>
        </div>
    );

};
export default PhaseTimeline;
