import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { Avatar, Badge, Col, Menu } from 'antd';
import menuImg from '../../assets/images/pages/profile/menu-img.svg';
import avatar from '../../assets/images/memoji/memoji-1.png';
import { theme } from '../../constants/Config';
import { IStore } from '../../redux/store';
import { getUserDetails } from '../../redux/selectors/UserSelector';
import GenUtils from '../../utils/GenUtils';
import NavigationUtils from '../../utils/NavigationUtils';

const MenuProfile = (props) => {
    const firmId = useSelector((state: IStore) => state?.firm?.uuid);
    const firm = useSelector((state: IStore) => state?.firm?.firm);
    const [selectedUserId, setSelectedUserId] = useState('');
    const user = useSelector((state: IStore) => getUserDetails(state, selectedUserId));
    const loggedInUser = useSelector((state: IStore) => getUserDetails(state));

    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split('/');

    useEffect(() => {
        const pathSplit = pathname.split('/');
        const selectedId = pathSplit[pathSplit.indexOf('team') + 1];
        selectedId && setSelectedUserId(selectedId);
    }, [pathname]);

    // @ts-ignore
    const menuItems = [
        {
            label: 'Personal Information',
            route: 'info'
        },
        ...(
            (loggedInUser.permission?.name?.toLowerCase() === 'admin' && loggedInUser.email !== user.email) || (loggedInUser.email === firm.email)
                ? [{
                    label: 'Role, Permission & Team',
                    route: 'access'
                }, {
                    label: 'Compensation',
                    route: 'compensation'
                }]
                : []
        ),
        {
            label: 'Quick Links and Reports',
            route: 'reports'
        },
        ...(
            (loggedInUser.email === user.email)
                ? [{
                    label: 'Activity Monitor',
                    route: 'activity'
                }]
                : []
        ), {
            label: 'Security Settings',
            route: 'security'
        }

    ];

    function menuFooterItem() {
        if (props.footer !== 'none') {
            return (
                <div className='hp-profile-menu-footer'>
                    <img src={menuImg} alt='Profile Image' />
                </div>
            );
        }
    }

    function moreBtn() {
        if (props.moreBtnCheck !== 'none') {
            return (
                <Col className='hp-menu-header-btn hp-pr-16 hp-mb-12 hp-text-right'>
                    {props.moreBtn()}
                </Col>
            );
        }
    }


    const goToUserRoute = (route) => {
        NavigationUtils.navigateTo(`/${firmId}/team/${user.uuid}/${route}`);
    }

    const renderMenuItem = (item) => {
        const route = splitLocation[splitLocation.length - 1];
        const isSelected = (route === item.route) || (item.route === 'info' && GenUtils.isUuid(route));

        return (
            <Menu.Item
                key={item.label}
                // icon={<User set="curved" className={menuIconClass} />}
                className={`hp-mb-16 hp-pl-24 hp-pr-32 ${
                    isSelected ? 'ant-menu-item-selected' : 'ant-menu-item-selected-in-active'
                }`}
                onClick={() => {
                    props?.onCloseDrawer && props?.onCloseDrawer();
                    goToUserRoute(item.route);
                }}
            >
                <div>
                    {item.label}
                </div>
            </Menu.Item>
        );
    };

    return (
        <Col flex='240px' className='hp-profile-menu hp-py-24'>
            <div className='hp-w-100'>
                <div className='hp-profile-menu-header hp-mt-md-16 hp-text-center'>
                    {moreBtn()}

                    <Badge>
                        <Avatar size={90} src={user.profilePicUrl || avatar} style={{overflow: 'hidden'}}/>
                    </Badge>

                    <h3 className='hp-mt-24 hp-mb-4'>{`${user.firstName ? user.firstName : ' '} ${user.lastName ? user.lastName : ' '}`}</h3>
                    <a href='mailto:dolores@yoda.com' className='hp-p1-body'>
                        {user.email}
                    </a>
                </div>

                <Menu
                    mode='inline'
                    className='hp-w-100 hp-profile-menu-body'
                    theme={theme}
                >
                    {
                        menuItems.map(renderMenuItem)
                    }
                </Menu>
            </div>

            {menuFooterItem()}
        </Col>
    );
};

export default MenuProfile;
