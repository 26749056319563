import TimesheetPopOverInput from '../../../../components/input/TimesheetPopOverInput';
import styles from './styles.module.scss';

const HoursInputCell = ({ name, data, index, phaseMember, onSave, updateTimeLogData }) => {
    return (
        <div className={styles.hoursInput}>
            <TimesheetPopOverInput onSave={(value, note) => onSave(value, note)} value={data} name={name} onChange={(data, name) => updateTimeLogData(phaseMember.uuid, data, name, index)} />
        </div>
    );
};

export default HoursInputCell;
