import IProject from './IProject';
import IClient from './IClient';

export default interface IInvoice {
    uuid?: string;
    type?: 'retainer' | 'standard';
    projectId?: string;
    serial?: string;
    fromDate?: string;
    toDate?: string;
    address?: string;
    status?: EInvoiceStatus;
    note?: string;
    footer?: string;
    files?: any[];
    date?: string;
    dueDate?: string;
    termId?: string;
    term?: any;
    taxId?: string;
    clientId?: string;
    total?: number;
    paid?: number;
    remaining?: number;
    discount?: number;
    taxed?: number;
    showPlanned?: boolean;
    showPrevious?: boolean;
    showComplete?: boolean;
    showRole?: boolean;
    showHourAndRate?: boolean;
    showExpenseBy?: boolean;
    showExpenseNote?: boolean;
    showExpenseDate?: boolean;
    showExpensePhase?: boolean;
    showQuantityAndRate?: boolean;
    showDesc?: boolean;
    applyInterest?: boolean;
    addLineItems?: boolean;
    consultantDisplay?: boolean;
    userUuid?: string;
    project?: IProject;
    client?: IClient;
    details?: IInvoiceDetail[];
    history?: any[];
}

export interface IInvoiceDetail {
    type?: string;
    name?: string;
    desc?: string;
    complete?: number;
    createdAt?: string;
    amount?: number;
    count?: number;
    rate?: number;
    projectId?: string;
    phaseId?: string;
    phaseMemberId?: string;
    expenseId?: string;
    roleId?: string;
    consultantId?: string;
    consultantTypeId?: string;
    invoiceId?: string;
    fee?: number;
    invoiced?: number;
    unbilled?: number;
    margin?: number;
    due?: number;
    total?: number;
    unbilledSinceLastInvoice?: number;
    unbilledRemaining?: number;
    userUuid?: string;
    uuid?: string;
    details?: IInvoiceDetail[];
}

export enum EInvoiceStatus {
    Draft = "draft",
    Pending = "pending",
    Submitted = "submitted",
    Approved = "approved",
    Paid = "paid",
    PartiallyPaid = "partially_paid",
    Void = "void",
    WriteOff = "write_off",
    OverDue = "over_due"
}
