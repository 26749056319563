import { useState } from 'react';
import classnames from 'classnames';
import { CaretDownOutlined } from '@ant-design/icons';

import styles from './Group.scss';

const Group = ({ key, header, table }) => {
    const [isOpen, setOpen] = useState(true);

    return (
        <div className={styles.groupContainer} key={key}>
            <div className={styles.header} onClick={() => setOpen(o => !o)}>
                <div className={styles.title}>{header}</div>
                <div className={classnames(styles.icon, isOpen && styles.open)}>
                    <CaretDownOutlined />
                </div>
            </div>
            {
                isOpen && (
                    <div className={styles.body}>{table}</div>
                )
            }
        </div>
    )
}

export default Group;