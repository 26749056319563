import { APIUtil } from '../utils/ApiUtils';
import ApiURLs from '../constants/ApiURLs';
import { ECommunicationCategory, ECommunicationType, ICommunication } from '../redux/reducers/CommunicationReducer';
import GenUtils from '../utils/GenUtils';
import _ from 'lodash';
import { setCommunications, setNewCommunications } from '../redux/actions/CommunicationActions';

export default class CommunicationService {
    static async createCommunication(
        category: ECommunicationCategory,
        type: ECommunicationType,
        note: string,
        communicationAt: string,
        followUpAt: string,
        teamMemberId: string,
        clientOrProjectId: string
    ): Promise<any> {
        const d = {
            category,
            type,
            communicationAt,
            teamMemberId,
            note
        };

        if (!GenUtils.isEmpty(followUpAt)) {
            d['followUpAt'] = followUpAt;
        }

        if (type === ECommunicationType.client) {
            d['clientId'] = clientOrProjectId;
        } else if (type === ECommunicationType.project) {
            d['projectId'] = clientOrProjectId;
        } else if (type === ECommunicationType.task) {
            d['taskId'] = clientOrProjectId;
            d['category'] = ECommunicationCategory.message;
        }

        const { data, error } = await APIUtil.makePostRequest(`${ApiURLs.CREATE_COMMUNICATION}`, { ...d, note: d.note || '' });

        if (error) {
            GenUtils.log('createCommunication', error);
            return { error };
        }

        await CommunicationService.fetchCommunications(type, clientOrProjectId);
        return { data };
    }

    static async fetchCommunications(type: ECommunicationType, id: string) {
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.GET_COMMUNICATION}?type=communication&${type}Id=${id}`);

        if (error) {
            GenUtils.log('fetchCommunications', { error });
            return { error };
        }

        GenUtils.dispatch(setCommunications(type, id, data));
        return { data };
    }

    static async updateCommunications(communication: ICommunication, id: string) {
        let _communication = { ...communication };
        _communication = _.omit(_communication, ['clientId', 'projectId', 'uuid', 'type', 'taskId']);
        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.UPDATE_COMMUNICATION}/${id}`, { ..._communication, note: communication.note || '' });

        if (error) {
            GenUtils.log('updateCommunications', { error });
            return { error };
        }

        // await CommunicationService.fetchCommunications(communication.type, communication.clientId || communication.projectId || communication.taskId);
        return { data };
    }

    static async deleteCommunications(communicationId: string, type: ECommunicationType, clientOrProjectId: string) {
        const { data, error } = await APIUtil.makeDeleteRequest(`${ApiURLs.DELETE_COMMUNICATION}/${communicationId}`);

        if (error) {
            GenUtils.log('deleteCommunications', { error });
            return { error };
        }

        // await CommunicationService.fetchCommunications(type, clientOrProjectId);
        return { data };
    }

    //new function
    static async fetchNewCommunications(type: ECommunicationType) {
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.GET_COMMUNICATION}?type=${type}`);

        if (error) {
            GenUtils.log('fetchCommunications', { error });
            return { error };
        }

        GenUtils.dispatch(setNewCommunications(type, data));
        return { data };
    }

    static async newCommunication(
        category: string,
        type: ECommunicationType,
        note: string,
        subject: string,
        communicationFrom: string,
        communicationAt: string,
        projectId: string,
        clientId: string,
        teamMemberId: string,
        contactId: string,
        recordedTime: number,
        followUpAt: string,
        createdById: string
    ): Promise<any> {
        const d = {
            category,
            type,
            note,
            subject,
            communicationFrom,
            communicationAt,
            projectId,
            clientId,
            teamMemberId,
            contactId,
            recordedTime,
            followUpAt,
            createdById
        };
        const { data, error } = await APIUtil.makePostRequest(`${ApiURLs.CREATE_COMMUNICATION}`, { ...d, note: d.note || '' });
        
        if (error) {
            GenUtils.log('newCommunication', error);
            return { error };
        }

        // await CommunicationService.fetchNewCommunications(type);
        return { data };
    }

    static async deleteNewCommunications(communicationId: string, type: ECommunicationType) {
        const { data, error } = await APIUtil.makeDeleteRequest(`${ApiURLs.DELETE_COMMUNICATION}/${communicationId}`);

        if (error) {
            GenUtils.log('deleteCommunications', { error });
            return { error };
        }
        await CommunicationService.fetchNewCommunications(type);
        return { data };
    }
}
