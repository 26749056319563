import { APIUtil } from '../utils/ApiUtils';
import ApiURLs from '../constants/ApiURLs';
import IProject from '../interfaces/IProject';
import { setProjects, updateProject } from '../redux/actions/ProjectActions';
import GenUtils from '../utils/GenUtils';
import MembersService from './MembersService';
import { setPhases } from '../redux/actions/PhaseActions';

export default class ProjectService {
    static async createProject(project: IProject) {
        const { data, error } = await APIUtil.makePostRequest(`${ApiURLs.CREATE_PROJECT}`, project);

        if (error) {
            GenUtils.log('createProject', error);
            return { error };
        }

        await this.fetchProjectsForClient(project.clientId, true);
        return { data };
    }

    static async fetchAllProjects(dontFetchPhases = false) {
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.GET_PROJECT}`);

        if (error) {
            GenUtils.log('fetchAllProjects', { error });
            return { error };
        }

        GenUtils.dispatch(setProjects(data));
        if (!dontFetchPhases) {
            for (const project of data) {
                // await PhaseService.fetchPhasesForProject(project.uuid);
                GenUtils.dispatch(setPhases(project.phases, project.uuid));

                for (const phase of project.phases) {
                    MembersService.fetchMemberForPhase(phase.uuid);
                }
            }
        }
        return { data };
    }

    static async fetchProjectsForClient(clientId: string, dontFetchPhases = false) {
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.GET_PROJECT_FOR_CLIENT}/${clientId}`);

        if (error) {
            GenUtils.log('fetchProjectsForClient', { error });
            return { error };
        }

        GenUtils.dispatch(setProjects(data, clientId));
        if (!dontFetchPhases) {
            for (const project of data) {
                // await PhaseService.fetchPhasesForProject(project.uuid);
                GenUtils.dispatch(setPhases(project.phases, project.uuid));

                for (const phase of project.phases) {
                    MembersService.fetchMemberForPhase(phase.uuid);
                }
            }
        }
        return { data };
    }

    static async fetchProjectFinance(projectID){
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.PROJECT_FINANCE}?projectId=${projectID}`)
        if (error) {
            GenUtils.log('fetchProjectsFinance', { error });
            return { error };
        }
        return {data}
    }

    static async fetchProject(projectId: string) {
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.GET_PROJECT}/${projectId}`);

        if (error) {
            GenUtils.log('fetchProject', { error });
            return { error };
        }

        GenUtils.dispatch(updateProject(data));
        return { data };
    }

    static async updateProjectStatus(project: IProject) {
        return await ProjectService.updateProject(project.uuid, { status: project.status }, project.clientId);
    }

    static async updateProject(projectId, project: IProject, clientId = null) {
        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.UPDATE_PROJECT}/${projectId}`, project);

        if (error) {
            GenUtils.log('updateProject', error);
            return { error };
        }

        if (clientId) {
            this.fetchProjectsForClient(clientId, true);
        } else {
            this.fetchAllProjects(true);
        }
        return { data };
    }

    static async deleteProject(projectId) {
        const { data, error } = await APIUtil.makeDeleteRequest(`${ApiURLs.DELETE_PROJECT}/${projectId}`);

        if (error) {
            GenUtils.log('deleteProject', error);
            return { error };
        }

        this.fetchAllProjects(true);
        return { data };
    }

    ////////////////////////////////
    static async createRole(role) {
        const { data, error } = await APIUtil.makePostRequest(`${ApiURLs.CREATE_PROJECT_ROLE}`, role);

        if (error) {
            GenUtils.log('createProjectRole ', error);
            return { error };
        }

        this.fetchProject(role.projectId);
        return { data };
    }

    static async deleteRole(projectId, roleId) {
        const { data, error } = await APIUtil.makeDeleteRequest(`${ApiURLs.DELETE_PROJECT_ROLE}/${roleId}`);

        if (error) {
            GenUtils.log('deleteRole', error);
            return { error };
        }

        this.fetchProject(projectId);
        return { data };
    }

    static async updateRole(projectId, roleId, role) {
        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.UPDATE_PROJECT_ROLE}/${roleId}`, role);

        if (error) {
            GenUtils.log('updateRole', error);
            return { error };
        }

        this.fetchProject(projectId);
        return { data };
    }
}
