import React, { useState } from 'react';
import classnames from 'classnames';

import { Row } from 'antd';

import styles from './SwitchTabs.scss';

const SwitchTabs = ({ a, b, onChange, initialValue = null }) => {
    const [optionA, setOptionA] = useState(initialValue !== b);

    const select = (option) => {
        setOptionA(option === a);
        onChange(option === a, option);
    }

    return (
        <Row className={styles.switchTabs} style={{margin: '20px 0'}}>
            <div
                className={classnames(styles.left, optionA && styles.leftActive)}
                onClick={() => select(a)}>
                {a}
            </div>
            <div
                className={optionA ? styles.right : styles.rightActive}
                onClick={() => select(b)}>
                {b}
            </div>
        </Row>
    )
}

export default SwitchTabs;
