import moment from "moment";
import IInvoice, { IInvoiceDetail } from "../interfaces/IInvoice";
import GenUtils from "./GenUtils";

export enum EInvoiceDetailTypes {
    FIXED = 'fixed',
    HOURLY = 'hourly',
    CONSULTANT = 'consultant',
    EXPENSE = 'expense',
    OTHER = 'other',
    DISCOUNT = 'discount',
    ALL = 'all',
}


export default class InvoiceUtils {
    private static types = Object.values(EInvoiceDetailTypes);

    static calculateTotals = (invoice: IInvoice, _type?: EInvoiceDetailTypes) => {
        if (_type === EInvoiceDetailTypes.ALL) {
            return (invoice?.total || 0) - (invoice?.discount || 0) + (invoice?.taxed || 0);
        }

        if (!invoice) return {};

        const _totals = {};

        InvoiceUtils.types.forEach((type: EInvoiceDetailTypes) => {
            if (type === EInvoiceDetailTypes.HOURLY) {
                _totals[type] = (invoice?.details || [])
                    .filter((d: IInvoiceDetail) => type === EInvoiceDetailTypes.HOURLY ? d.type === type && !d.phaseMemberId : d.type === type)
                    .reduce((acc, d) => acc + d.due, 0);
            } else {
                _totals[type] = (invoice?.details || [])
                    .filter((d: IInvoiceDetail) => d.type === type)
                    .reduce((acc, d) => acc + d.due, 0);
            }
        })

        if (_type) {
            return _totals[_type];
        }

        return _totals;
    }

    static getInvoiceDetailsByType = (invoice: IInvoice, type: EInvoiceDetailTypes) => {
        if (!invoice?.details) {
            return [];
        }

        switch (type) {
            case EInvoiceDetailTypes.FIXED:
            case EInvoiceDetailTypes.EXPENSE:
            case EInvoiceDetailTypes.OTHER:
            case EInvoiceDetailTypes.DISCOUNT:
                return invoice.details.filter((d: IInvoiceDetail) => d.type === type);
            case EInvoiceDetailTypes.HOURLY:
                return InvoiceUtils.processHourlyData(invoice.details);
            case EInvoiceDetailTypes.CONSULTANT:
                return InvoiceUtils.processConsultantData(invoice.details);
            default:
                return [];
        }
    }

    static processHourlyData = (details: IInvoiceDetail[]) => {
        const _details: any = {};

        details.filter(d => d.type === EInvoiceDetailTypes.HOURLY && !d.phaseMemberId).forEach(detail => _details[detail.phaseId] = detail);
        details.filter(d => d.type === EInvoiceDetailTypes.HOURLY && d.phaseMemberId).forEach(detail => {
            if (_details[detail.phaseId]['details']) {
                _details[detail.phaseId]['details'][detail.uuid] = detail
            } else {
                _details[detail.phaseId].details = { [detail.uuid]: detail }
            }
        });

        return Object.values(_details).map((detail: IInvoiceDetail) => detail.details ? { ...detail, details: Object.values(detail.details || {}) } : detail);
    }

    static processConsultantData = (details: IInvoiceDetail[]) => {
        const _details: any = {};
        let d: IInvoiceDetail[] = []
        if (details.length === 1 && details[0].details) {
            d = details[0].details
        } else {
            d = details
        }

        d.filter(d => d.type === EInvoiceDetailTypes.CONSULTANT).forEach(detail => {
            if (GenUtils.isUuid(detail.phaseId)) {
                if (_details[detail.consultantTypeId]) {
                    // existing
                    const _detail = _details[detail.consultantTypeId];
                    const len = Object.values(_detail.details).length;
                    _details[detail.consultantTypeId] = {
                        ..._details[detail.consultantTypeId],
                        createdAt: moment(_detail.createdAt).isBefore(detail.createdAt) ? _detail.createdAt : detail.createdAt,
                        fee: _detail.fee + detail.fee,
                        invoiced: _detail.invoiced + detail.invoiced,
                        complete: (_detail.complete * len + detail.complete) / (len + 1),
                        due: _detail.due + detail.due,
                        details: {
                            ..._detail.details,
                            [detail.uuid]: detail,
                        }
                    }
                } else {
                    // new
                    _details[detail.consultantTypeId] = {
                        uuid: detail.consultantTypeId,
                        createdAt: detail.createdAt,
                        consultantTypeId: detail.consultantTypeId,
                        fee: detail.fee,
                        invoiced: detail.invoiced,
                        complete: detail.complete,
                        due: detail.due,
                        details: {
                            [detail.uuid]: detail,
                        }
                    }
                }
            } else {
                _details[detail.consultantTypeId] = detail;
            }
        });

        return Object
            .values(_details)
            .map((detail: IInvoiceDetail) =>
                detail.details
                    ? { ...detail, details: Object.values(detail.details || {}) }
                    : detail
            );
    }
}