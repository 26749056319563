import FiltersBar from '../../../components/filterBars/FiltersBar';
import { Dropdown, Popconfirm, Table } from 'antd';
import NavigationUtils from '../../../utils/NavigationUtils';
import GenUtils, { ENotificationType } from '../../../utils/GenUtils';
import Tag from '../../../components/tag/Tag';
import FormatUtils from '../../../utils/FormatUtils';
import PermissionUtils from '../../../utils/PermissionUtils';
import Button from '../../../components/button/Button';
import IInvoice, { EInvoiceStatus } from '../../../interfaces/IInvoice';
import { MoreOutlined, PaperClipOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { IStore } from '../../../redux/store';
import { InvoiceService } from '../../../services/InvoiceService';
import { useState } from 'react';
import Colors from '../../../constants/Colors';
import GroupedTable, { EColumnType } from '../../../components/table/groupedTable/GroupedTable';
import { EFilterCategory, EFilterTypes } from '../../../components/filterBars/FiltersBarMultiselect';
import GroupBar from '../../../components/filterBars/GroupBar';

const Payments = () => {
    const firmId = useSelector((state: IStore) => state?.firm?.uuid);
    const invoices = useSelector((state: IStore) => state.invoices.invoices);
    const { payments } = useSelector((state: IStore) => state.payments);
    const clients = useSelector((state: IStore) => Object.values(state.client.clients));
    const paymentModes = useSelector((state: IStore) => Object.values(state.options.payment_mode));

    const paymentsArray = Object.values(payments);
    const filters1 = [
        {
            name: 'Invoice #',
            dataIndex: 'invoiceId',
            type: EFilterTypes.dropdown,
            category: EFilterCategory.simple,
        },
        {
            name: 'Project',
            dataIndex: 'projectId',
            type: EFilterTypes.dropdown,
            category: EFilterCategory.advance,
        },
        {
            name: 'Client',
            dataIndex: 'project.clientId',
            type: EFilterTypes.dropdown,
            category: EFilterCategory.advance,
        },
        {
            name: 'Payment Mode',
            dataIndex: 'paymentModeId',
            type: EFilterTypes.dropdown,
            category: EFilterCategory.advance,
        },
        {
            name: 'Due-Date',
            dataIndex: 'date',
            type: EFilterTypes.dateRange,
            category: EFilterCategory.simple
        }
    ]

    const columns = [
        {
            title: 'Invoice Number',
            dataIndex: 'invoiceId',
            render: (d, p) => <a onClick={() => NavigationUtils.navigateTo(`/${firmId}/invoices/build/${d}?invoiceId=${d}`)}>{GenUtils.capitalizeInitials(invoices[d]?.serial)}</a>,
            // type: EColumnType.number
        },
        {
            title: 'Client',
            dataIndex: 'project.clientId',
            render: (d, p) => { GenUtils.capitalizeInitials(clients[invoices[p.invoiceId]?.clientId]?.name) },
            type: EColumnType.client
        },
        {
            title: 'Payment number',
            dataIndex: 'payment_number',
            type: EColumnType.number,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            type: EColumnType.amount
        },
        {
            title: 'Bank charges',
            dataIndex: 'charges',
            type: EColumnType.amount
        },
        {
            title: 'Date',
            dataIndex: 'date',
            type: EColumnType.date
        },
        {
            title: 'Mode',
            dataIndex: 'paymentModeId',
            render: (x) => {
                console.log(x)
                return paymentModes[x]?.name || '-'
            },
        },
        {
            title: 'Reference number',
            dataIndex: 'reference',
            type: EColumnType.text
        },
        {
            title: 'Note',
            dataIndex: 'note',
            type: EColumnType.text
        },
        {
            title: 'Files',
            dataIndex: 'files',
            render: (x) => <div style={{ color: x?.length ? Colors.primary : Colors.grayLight }}>
                <PaperClipOutlined />
            </div>
        },
    ];

    const groups = [
        { title: 'Project', dataIndex: 'projectId', type: EColumnType.project },
        { title: 'Client', dataIndex: 'project.clientId', type: EColumnType.client },
        { title: 'Mode', dataIndex: 'paymentModeId', type: EColumnType.text },
    ]

    return (
        <div>
            <GroupedTable columns={columns} data={paymentsArray} filters={filters1} groups={groups} />
        </div>
    )
}
export default Payments;
