import React from 'react';

import { Button, Col, Row } from 'antd';
import { ButtonType } from 'antd/es/button';
import GenUtils from '../../utils/GenUtils';

const _Button = ({
                     text,
                     onClick,
                     disable = false,
                     type = 'primary' as ButtonType,
                     icon = null,
                     block = false,
                     danger = false,
                     small = false,
                     style = {},
                     className='',
                   }) => {
    return (
        <Col>
            <Row align='middle'>
                {
                    <Button
                        type={type}
                        block={block}
                        disabled={disable}
                        icon={icon}
                        danger={danger}
                        onClick={onClick}
                        ghost={type === 'ghost'}
                        size={small ? 'small' : 'large'}
                        style={style}
                        className={className}>
                        {GenUtils.capitalizeFirstLetter(text)}
                    </Button>
                }
            </Row>
        </Col>
    );
};

export default _Button;
