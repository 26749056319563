// @ts-nocheck
import React, { useState } from 'react';
import { CloseOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { Col, Divider, Form, Input, Modal, Popconfirm, Table } from 'antd';
import Button from '../../components/button/Button';
import OptionService from '../../services/OptionService';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import Colors from '../../constants/Colors';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import FormatUtils from '../../utils/FormatUtils';

const Roles = () => {
    const roles = useSelector((state: IStore) => state.options.role);

    const [showCreateModal, setCreateModal] = useState(false);
    const [name, setName] = useState('');
    const [rate, setRate] = useState(0);
    const [isUpdating, setUpdating] = useState(false);

    const columnClass = 'hp-p1-body hp-text-color-black-100 hp-text-color-dark-0 ';
    const dividerClass = 'hp-border-color-black-40 hp-border-color-dark-80';

    const columns = [
        {
            title: 'Default Role',
            dataIndex: 'role',
            width: 200,
            render: (text) => <span style={{ fontWeight: 500 }}
                                    className={columnClass}>{GenUtils.capitalizeFirstLetter(text)}</span>
        },
        {
            title: 'Billable Rate',
            dataIndex: 'rate',
            width: 200,
            render: (text) => <span className={columnClass}>{FormatUtils.getAmountInFormat(text, true, 0, 0)}</span>
        },
        {
            title: '',
            dataIndex: 'edit',
            align: 'right',
            render: (text, data) => {
                return (<div>
                    <a onClick={() => openEditDialog(data)}>Edit</a>
                    <Popconfirm
                        title='Are you sure to delete this role?'
                        onConfirm={() => deleteRole(data.key)}
                        okText='Yes'
                        cancelText='No'
                    >
                        <CloseOutlined style={{ color: 'IndianRed', marginLeft: 20 }} />
                    </Popconfirm>
                </div>);
            }
        }
    ];

    const getRoles = () => {
        return roles.map(role => {
            return {
                key: role.uuid,
                role: role.name,
                rate: role.hourRate,
                edit: 'Edit'
            };
        });
    };

    const createRole = async () => {
        const { data, error } = await OptionService.createRole(name, rate);
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Delete', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Successfully created the Role', <notificationIcon.icon
            style={notificationIcon.style} />);
        setName('');
        setRate(0);
        setCreateModal(false);
    };

    const deleteRole = async (id) => {
        const { error } = await OptionService.deleteOption(id);
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Delete', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Successfully deleted the Role', <notificationIcon.icon
            style={notificationIcon.style} />);
        setName('');
        setRate(0);
        setCreateModal(false);
    };

    const updateRole = async (id) => {
        const { data, error } = await OptionService.updateOption(id, { name, hourRate: rate });
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Update', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Successfully updated the Role', <notificationIcon.icon
            style={notificationIcon.style} />);
        setName('');
        setRate(0);
        setCreateModal(false);
    };

    const openEditDialog = (role) => {
        setUpdating(role.key);
        setName(role.role);
        setRate(role.rate);
        setCreateModal(true);
    };

    return (
        <div className='hp-profile-activity'>
            <Modal
                title={isUpdating ? 'Update Role' : 'Create Role'}
                width={500}
                centered
                visible={showCreateModal}
                onCancel={() => {
                    setName('');
                    setUpdating(false);
                    setRate(0);
                    setCreateModal(false);
                }}
                footer={null}
                closeIcon={
                    <CloseOutlined
                        style={{ color: Colors.red }}
                        className='remix-icon text-color-black-100'
                        size={24} />
                }
            >
                <Form layout='vertical' name='basic' initialValues={{ remember: true }}>
                    <Col
                        span={24}
                        className='hp-profile-content-list hp-mt-8 hp-pb-sm-0 hp-pb-120'
                    >
                        <ul>
                            <li>
                                <span className='hp-p1-body'>Default Role</span>
                                <Input value={name} style={{ width: 300 }}
                                       onChange={e => setName(e.target.value)} />
                            </li>
                            <li className='hp-mt-18'>
                                <span className='hp-p1-body'>Billable Rate (in {
                                    FormatUtils.currencyIso
                                })</span>
                                <Input
                                    style={{ width: 300 }}
                                    type={'number'}
                                    value={rate}
                                    onChange={e => setRate(Number(e.target.value))}
                                />
                            </li>
                        </ul>
                    </Col>
                    <Button text='Save' onClick={() => isUpdating ? updateRole(isUpdating) : createRole()} />
                </Form>
            </Modal>
            <h2 className='hp-mb-4'> Default Roles & Billable Rates</h2>
            <p className='hp-p1-body hp-mb-0'>
                Define default roles and billable rates for your firm. These can be assigned to team members in their
                profiles.
            </p>

            <Divider className={dividerClass} />
            <Button text='Add Role'
                    onClick={() => setCreateModal(true)}
                    icon={<PlusCircleOutlined style={{ marginRight: 10 }} />}
            />
            <Table
                className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                columns={columns}
                dataSource={getRoles()}
                style={{ marginTop: '20px' }}
                size='middle'
                pagination={false} ds
            />
        </div>
    );
};
export default Roles;
