import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FlagOutlined, TagOutlined, DollarOutlined, BookOutlined, ReconciliationOutlined } from '@ant-design/icons';
import { Tabs, TabsProps, Typography } from 'antd';

// utils & services & Redux
import ProjectService from '../../services/ProjectService';
import GenUtils from '../../utils/GenUtils';
import { IStore } from '../../redux/store';
import IProject from '../../interfaces/IProject';

// components
import Tag from '../../components/tag/Tag';
import HeaderSecondary from '../../components/header/HeaderSecondary';
import ProjectMoreButton from '../../components/button/ProjectMoreButton';
import ServiceFeeTab from './ServiceFeeTab';
import ConsultantFeeTab from './ConsultantFeeTab';
import GeneralTab from './GeneralTab';
import DocMaker from '../../components/createDocs/docMaker';

// styles
import styles from './EditProjectPage.scss';

const { Title } = Typography;
const { TabPane } = Tabs;

const EditProjectPage = () => {
    const [selectedProjectId, setSelectedProjectId] = useState('');

    const project: IProject = useSelector((state: IStore) => selectedProjectId && state.project.projects[selectedProjectId]);
    const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => <DefaultTabBar {...props} className={styles.tabBar} />;

    const { pathname } = useLocation();
    useEffect(() => {
        const pathSplit = pathname.split('/');
        const selectedId = pathSplit[pathSplit.indexOf('projects') + 1];
        selectedId && setSelectedProjectId(selectedId);

        ProjectService.fetchProject(selectedId);
    }, [pathname]);

    return (
        <div className={styles.container}>
            {project && (
                <>
                    <div style={{ display: 'flex',alignItems:"center",gap:"8px" }}>
                        <HeaderSecondary
                            text={
                                <div style={{ display: 'flex' }}>
                                    <Tag color={project?.color} text={project?.serial} style={{ padding: '5px', marginRight: 10, fontSize: 24 }} />
                                    <Title level={4} style={{ margin: 0 }}>
                                        {GenUtils.capitalizeInitials(project?.name)}
                                    </Title>
                                    <Tag
                                        color={GenUtils.getTagColorByStatus(project?.status)}
                                        text={project?.status}
                                        style={{ marginLeft: 10, fontSize: 14 }}
                                    />
                                </div>
                            }
                            renderAction={() => <ProjectMoreButton project={project} />}
                        />
                        <DocMaker data={project} />
                    </div>
                    <Tabs renderTabBar={renderTabBar}>
                        <TabPane
                            tab={
                                <span style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '16px' }}>
                                    {/* <FlagOutlined style={{ marginRight: 10 }} /> */}
                                    <ReconciliationOutlined style={{ fontSize: '16px' }} />
                                    Planner
                                </span>
                            }
                            key="1">
                            <ServiceFeeTab project={project} />
                        </TabPane>
                        <TabPane
                            tab={
                                <span style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '16px' }}>
                                    {/* <TagOutlined style={{ marginRight: 10 }} /> */}
                                    <DollarOutlined style={{ fontSize: '16px' }} />
                                    Consultant Fee
                                </span>
                            }
                            key="2">
                            <ConsultantFeeTab project={project} />
                        </TabPane>
                        {/*<TabPane*/}
                        {/*    tab={*/}
                        {/*        <span>*/}
                        {/*            <DollarCircleOutlined style={{ marginRight: 10 }} />*/}
                        {/*            Expenses*/}
                        {/*        </span>*/}
                        {/*    }*/}
                        {/*    disabled*/}
                        {/*    key="3">*/}
                        {/*    Tab 3*/}
                        {/*</TabPane>*/}
                        <TabPane
                            tab={
                                <span style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '16px' }}>
                                    {/* <FlagOutlined style={{ marginRight: 10 }} /> */}
                                    <BookOutlined style={{ fontSize: '16px' }} />
                                    General
                                </span>
                            }
                            key="4">
                            <GeneralTab project={project} />
                        </TabPane>
                    </Tabs>
                </>
            )}
        </div>
    );
};

export default EditProjectPage;
