import { useState } from 'react';
import Button from '../../components/button/Button';
import { EInvoiceStatus } from '../../interfaces/IInvoice';
import { InvoiceService } from '../../services/InvoiceService';
import styles from './RecordPayment.scss';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import CustomInput from '../../components/input/CustomInput';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import { Select } from 'antd';
import FormatUtils from '../../utils/FormatUtils';
import FileDragger from '../../components/files/FileDragger';

const RecordPayment = ({ onHide, invoice }) => {
    const [amount, setAmount] = useState(0);
    const [bankCharges, setBankCharges] = useState(0);
    const [date, setDate] = useState(moment());
    const [note, setNote] = useState(0);
    const [reference, setReference] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [fileIds, setFileIds] = useState([]);

    const { payment_mode } = useSelector((state: IStore) => state.options);

    const recordPayment = async () => {
        const { data, error } = await InvoiceService.recordPayment(invoice.uuid, {
            status: EInvoiceStatus.PartiallyPaid,
            note,
            amount,
            charges: bankCharges,
            reference,
            paymentModeId: paymentMethod,
            date,
            fileIds,
        });

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Save', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }

        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Payment Recorded successfully', <notificationIcon.icon
            style={notificationIcon.style} />);

        onHide();
    }

    return (
        <div className={styles.container}>
            <div className={styles.field}>
                <div className={styles.label}>
                    Client Name
                </div>
                <div className={styles.input}>
                    <CustomInput type="text" value={invoice?.client?.name} disabled onChange={() => { }} />
                </div>
            </div>
            <div className={styles.field}>
                <div className={styles.label}>
                    Payment #
                </div>
                <div className={styles.input}>
                    <CustomInput value={1} disabled onChange={() => { }} />
                </div>
            </div>
            <div className={styles.field}>
                <div className={styles.label}>
                    Amount Received
                </div>
                <div className={styles.input}>
                    <CustomInput value={amount} onChange={setAmount} prefix={FormatUtils.getCurrency()} />
                </div>
            </div>
            <div className={styles.field}>
                <div className={styles.label}>
                    Bank Charges (If Any)
                </div>
                <div className={styles.input}>
                    <CustomInput value={bankCharges} onChange={setBankCharges} prefix={FormatUtils.getCurrency()} />
                </div>
            </div>
            <div className={styles.field}>
                <div className={styles.label}>
                    Payment Date
                </div>
                <div className={styles.input}>
                    <CustomInput type='date' value={date} onChange={setDate} />
                </div>
            </div>
            <div className={styles.field}>
                <div className={styles.label}>
                    Mode of Payment
                </div>
                <div className={styles.input}>
                    <Select
                        value={paymentMethod}
                        onChange={setPaymentMethod}
                        style={{ width: '100%' }}
                    >
                        {
                            payment_mode.map(
                                option => <Select.Option key={option.uuid} value={option.uuid}>
                                    {option.name}
                                </Select.Option>
                            )
                        }
                    </Select>
                </div>
            </div>
            <div className={styles.field}>
                <div className={styles.label}>
                    Note
                </div>
                <div className={styles.input}>
                    <CustomInput type='text' value={note} onChange={setNote} />
                </div>
            </div>
            <div className={styles.field}>
                <div className={styles.label}>
                    Reference #
                </div>
                <div className={styles.input}>
                    <CustomInput type='text' value={reference} onChange={setReference} />
                </div>
            </div>
            <div className={styles.field}>
                <FileDragger setfileIds={setFileIds}/>
            </div>
            <div className={styles.field}>
                <Button text='Cancel' onClick={onHide} type='ghost' />
                <Button text='Save' onClick={() => recordPayment()} />
            </div>
        </div>
    );
}

export default RecordPayment;