import classnames from 'classnames';
import loginStyles from '../login/LoginPage.scss';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import AuthService from '../../services/AuthService';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import { Button, Row } from 'antd';
import CopyrightFooter from '../../components/footer/CopyrightFooter';
import OptionService from '../../services/OptionService';
import { async } from '../../view/components/feedback/popconfirm/code';

const VerifyScreen = () => {
    const [verifying, setVerifying] = useState(false);
    const [error, setError] = useState(false);

    const [searchParams] = useSearchParams();
    const navigateTo = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const token = searchParams.get('token');
        const firmid = searchParams.get('firmid');
        const isInvite = searchParams.get('invited');

        if (isInvite) {
            navigateTo(`/password?token=${token}&firmid=${firmid}&isInvite=${isInvite}`);
            return;
        }

        if (token && firmid) {
            setVerifying(true);
            verify(token, firmid);
        }
    }, []);

    const verify = async (token, firmid) => {
        const { error } = await AuthService.verifyEmail(token, firmid);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('An error occurred', error, <notificationIcon.icon style={notificationIcon.style} />);
            setError(true);
            // return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Verified Successfully', error, <notificationIcon.icon style={notificationIcon.style} />);
        navigateTo('/login');
    };

    const getTitle = () => {
        if (error) {
            return 'Invalid link';
        }
        if (verifying) {
            return 'Please wait, verifying your email';
        }
        if (!verifying) {
            return 'Please verify your e-mail';
        }
    };

    const getSubTitle = () => {
        if (error) {
            return 'The link you used is either incorrect or expired, please check and try again';
        }
        if (verifying) {
            return 'We have sent a verification link to your registered email.';
        }
        if (!verifying) {
            return 'We have sent a verification link to your registered email.';
        }
    };

    const getButtonText = () => {
        if (error) {
            return 'Resend verification link';
        }
        if (verifying) {
            return 'Resend';
        }
        if (!verifying) {
            return 'Resend';
        }
    };

    const SendVerificationLink = async () => {
        const { data, error } = await AuthService.sendVerificationLink(location.state?.email);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to send', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Verification link sent', <notificationIcon.icon style={notificationIcon.style} />);
    };

    return (
        <div className={classnames(loginStyles.section, loginStyles.form)}>
            <h1 className={loginStyles.title}>{getTitle()}</h1>
            <p className={loginStyles.subtitle}>{getSubTitle()}</p>
            <Row className={loginStyles.ResendButtonParent}>
                <p className={loginStyles.text}>{error ? 'OR' : "Didn't received verification link?"}</p>
                <Button
                    className={loginStyles.button}
                    type={'link'}
                    onClick={() => {
                        SendVerificationLink();
                    }}>
                    {getButtonText()}
                </Button>
            </Row>
            <CopyrightFooter />
        </div>
    );
};
export default VerifyScreen;
