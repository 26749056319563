import { Alert, Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import Button from '../../components/button/Button';

import styles from './AssignTeamMember.scss';
import formStyles from '../../pages/form.scss';
import OptionService from '../../services/OptionService';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import TeamMemberSelector from '../../components/selector/TeamMemberSelector';
import MembersService from '../../services/MembersService';

const { Option } = Select;

const AssignTeamMember = ({ hideModal, onSuccess, phase, projectId, memberId = null }) => {
    const [role, setRole] = useState('');
    const [member, setMember] = useState(null);
    const roles = useSelector((state: IStore) => state.project.projects[projectId]?.roles);

    const members = useSelector((state: IStore) => state.members.phaseMembers[phase.uuid]);

    useEffect(() => {
        OptionService.fetchRoles();
        return () => {
            setRole('');
            setMember(null);
        };
    }, []);

    useEffect(() => {
        if (GenUtils.isEmpty(memberId) && GenUtils.isEmpty(members)) {
            setMember(members[memberId]?.teamMemberId);
            setRole(members[memberId]?.roleId);
        }
    }, [memberId, members]);

    const submitForm = async () => {

        const reqMember = {
            teamMemberId: member,
            roleId: role,
            budgetHours: 0
        };
        if (memberId) {
            const { data, error } = await MembersService.updateMember(reqMember, memberId, phase.uuid);
            if (error) {
                const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
                GenUtils.showNotification('Failed to Change Member', error, <notificationIcon.icon
                    style={notificationIcon.style} />);
                return;
            }
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
            GenUtils.showNotification('Successfully Changed Member', '', <notificationIcon.icon
                style={notificationIcon.style} />);
            hideModal();
        } else {
            const response = await MembersService.addMember({ ...reqMember, phaseId: phase.uuid });
            if (response.error) {
                const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
                GenUtils.showNotification('Failed to Assign Member', response.error, <notificationIcon.icon
                    style={notificationIcon.style} />);
                return;
            }
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
            GenUtils.showNotification('Successfully assigned Member', null, <notificationIcon.icon
                style={notificationIcon.style} />);
            hideModal();
        }
    };

    return (
        <div className={styles.container}>
            <form>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <Form.Item label='Team Member' className={styles.margin0}> </Form.Item>
                    </div>
                    <div className={styles.column}>
                        <TeamMemberSelector
                            selectedMembers={member}
                            setSelectedMembers={(e) => {
                                setMember(e.uuid);
                            }}
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <Form.Item label='Role' className={styles.margin0}> </Form.Item>
                    </div>
                    <div className={styles.column}>
                        <Select
                            value={role}
                            placeholder={'Select Role'}
                            onChange={setRole}>
                            {roles.map((role) => (
                                <Option key={role.uuid} value={role.uuid}>
                                    {role.name}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
                {/* <div className={styles.row}>
                    <div className={styles.column}>
                        <Form.Item label='Billable Rate' className={styles.margin0}> </Form.Item>
                    </div>
                    <div className={styles.column}>
                        <Input
                            placeholder='Billable Rate'
                            type={'number'}
                            className={formStyles.marginBottom20}
                            value={billableRate}
                            onChange={(e) => {
                                setBillableRate(Number(e.target.value));
                            }}
                        />
                    </div>
                </div> */}
            </form>
            <div className={classnames(styles.row, styles.center)}>
                <Button type={'ghost'} text='Cancel' onClick={hideModal} />
                <Button
                    text='Add'
                    onClick={() => {
                        submitForm();
                    }}
                />
            </div>
        </div>
    );
};
export default AssignTeamMember;
