import styles from './InvoiceDesigner.scss';
import Invoice from './Invoice';
import HeaderSecondary from '../../components/header/HeaderSecondary';
// import Button from '../../components/button/Button';
import { Button, Col, Row, Steps, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import NavigationUtils from '../../utils/NavigationUtils';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { InvoiceService } from '../../services/InvoiceService';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import IInvoice from '../../interfaces/IInvoice';
import Tag from '../../components/tag/Tag';

const InvoiceDesigner = () => {
    const [addLineItems, setAddLineItems] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const [showPlanned, setShowPlanned] = useState(false);
    const [showPrevious, setShowPrevious] = useState(false);
    const [showComplete, setShowComplete] = useState(false);
    const [showRole, setShowRole] = useState(false);
    const [showHourAndRate, setShowHourAndRate] = useState(false);
    const [showExpenseBy, setShowExpenseBy] = useState(false);
    const [showExpenseNote, setShowExpenseNote] = useState(false);
    const [showExpenseDate, setShowExpenseDate] = useState(false);
    const [showExpensePhase, setShowExpensePhase] = useState(false);
    const [showDesc, setShowDesc] = useState(false);
    const [showQuantityAndRate, setShowQuantityAndRate] = useState(false);


    const [invoiceId, setInvoiceId] = useState(null);

    const invoice = useSelector((state: IStore) => state.invoices?.invoices[invoiceId]);
    const projectsMap = useSelector((state: IStore) => state.project.projects);

    // Location
    const { pathname, search } = useLocation();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        setInvoiceId(searchParams.get('invoiceId'));

        if (invoice) {
            setShowPlanned(invoice.showPlanned);
            setShowPrevious(invoice.showPrevious);
            setShowComplete(invoice.showComplete);
            setShowRole(invoice.showRole);
            setShowHourAndRate(invoice.showHourAndRate);
            setShowExpenseBy(invoice.showExpenseBy);
            setShowExpenseNote(invoice.showExpenseNote);
            setShowExpenseDate(invoice.showExpenseDate);
            setShowExpensePhase(invoice.showExpensePhase);
            setShowDesc(invoice.showDesc);
            setShowQuantityAndRate(invoice.showQuantityAndRate);
        }
    }, [searchParams, pathname])

    useEffect(() => {
        if (invoice) {
            setShowPlanned(invoice.showPlanned);
            setShowPrevious(invoice.showPrevious);
            setShowComplete(invoice.showComplete);
            setShowRole(invoice.showRole);
            setShowHourAndRate(invoice.showHourAndRate);
            setShowExpenseBy(invoice.showExpenseBy);
            setShowExpenseNote(invoice.showExpenseNote);
            setShowExpenseDate(invoice.showExpenseDate);
            setShowExpensePhase(invoice.showExpensePhase);
            setShowDesc(invoice.showDesc);
            setShowQuantityAndRate(invoice.showQuantityAndRate);
        }
    }, [invoice])

    const next = () => {
        NavigationUtils.navigateTo(pathname.replace('design', 'preview') + search);
    };

    const updateInvoice = async (details: IInvoice) => {
        const { error } = await InvoiceService.updateInvoice(invoiceId, details);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to draft', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
    }

    const renderOption = ({ name, key, value, setValue }: any) => {
        return (
            <div className={styles.option} key={key}>
                <Switch
                    checked={value}
                    onChange={v => {
                        updateInvoice({ [key]: v });
                        setValue(v);
                    }}
                />
                {name}
            </div>
        );
    };

    const options = [
        { head: 'Line item display options', key: 'lineItemOptions' },
        {
            name: 'Add all fixed fee line items',
            key: 'addLineItems',
            value: addLineItems,
            setValue: setAddLineItems
        },
        ...(showAll
            ? [
                { head: 'Services display options', key: 'heading1' },
                { name: 'Show planned amounts', key: 'showPlanned', value: showPlanned, setValue: setShowPlanned },
                { name: 'Show previously billed', key: 'showPrevious', value: showPrevious, setValue: setShowPrevious },
                { name: 'Show % complete', key: 'showComplete', value: showComplete, setValue: setShowComplete },
                { head: 'Hourly services display options', key: 'heading2' },
                { name: 'Show roles', key: 'showRole', value: showRole, setValue: setShowRole },
                { name: 'Show hours and hourly rate', key: 'showHourAndRate', value: showHourAndRate, setValue: setShowHourAndRate },
                { head: 'Expenses display options', key: 'heading3' },
                {
                    name: 'Show team members ',
                    key: 'showExpenseBy',
                    value: showExpenseBy,
                    setValue: setShowExpenseBy
                },
                { name: 'Show notes', key: 'showExpenseNote', value: showExpenseNote, setValue: setShowExpenseNote },
                { name: 'Show dates', key: 'showExpenseDate', value: showExpenseDate, setValue: setShowExpenseDate },
                { name: 'Show phases', key: 'showExpensePhase', value: showExpensePhase, setValue: setShowExpensePhase },
                { head: 'Other items display options', key: 'heading4' },
                {
                    name: 'Show description in other items',
                    key: 'showDesc',
                    value: showDesc,
                    setValue: setShowDesc
                },
                {
                    name: 'Show quantity and rate in other items',
                    key: 'showQuantityAndRate',
                    value: showQuantityAndRate,
                    setValue: setShowQuantityAndRate
                }
            ]
            : []
        )
    ];

    const renderOptions = () => {
        return options.map(option => {
            return option.head
                ? <b className={styles.head} key={option.key}>{option.head}:</b>
                : renderOption(option);
        });
    };

    return (
        <div className={styles.container}>
            <HeaderSecondary
                text={
                    <div style={{ display: 'flex', gap: 10 }}>
                        Invoice #{invoice?.serial} for <div style={{ flex: 0, fontSize: 14 }}>
                            <Tag
                                text={projectsMap[invoice?.projectId]?.serial}
                                color={projectsMap[invoice?.projectId]?.color}
                            />
                        </div> {projectsMap[invoice?.projectId]?.name} :
                        <Tag
                            style={{fontSize: 14}}
                            text={invoice?.status}
                            color={GenUtils.getTagColorByStatus(invoice?.status)}
                        />
                    </div>
                }
                renderAction={() => <Button type='primary' onClick={next}>Next</Button>}
            />
            <Steps
                size='small'
                current={1}
                className={styles.steps}
                items={[{ title: 'Builder' }, { title: 'Design' }, { title: 'Preview' }]}
                onChange={(n) => {
                    if (n === 0) {
                        NavigationUtils.navigateTo(pathname.replace('design', 'build') + search);
                    }
                }}
            />
            <div className={styles.content}>
                <div className={styles.left}>
                    <div className={styles.optionsContainer}>
                        {renderOptions()}
                        <Row align='middle' justify='space-between' style={{ marginLeft: -20 }}>
                            {!showAll ? <Col md={12} span={24}>
                                <Button
                                    type='link'
                                    className='hp-text-color-primary-1 hp-hover-text-color-primary-3'
                                    icon={<DownOutlined />}
                                    onClick={() => {
                                        setShowAll(true);
                                    }}>&nbsp;&nbsp;Show All
                                </Button>
                            </Col> :
                                <Col md={12} span={24}>
                                    <Button
                                        type='link'
                                        className='hp-text-color-primary-1 hp-hover-text-color-primary-3'
                                        icon={<UpOutlined />}
                                        onClick={() => {
                                            setShowAll(false);
                                        }}>&nbsp;&nbsp;Hide
                                    </Button>
                                </Col>}
                        </Row>
                    </div>
                </div>
                <div className={styles.right}>
                    <Invoice
                        invoiceData={{}}
                        options={
                            options.reduce(
                                (acc, v) => {
                                    if (v.value) acc[v.key] = v.value;
                                    return acc;
                                }, {}
                            )
                        }
                    />
                </div>
            </div>
        </div>
    );
};
export default InvoiceDesigner;
