import { useSelector } from 'react-redux';
import HeaderSecondary from '../../components/header/HeaderSecondary';
import styles from './ReportPage.scss';
import { IStore } from '../../redux/store';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ReportService from '../../services/ReportService';
import GroupedTable from '../../components/table/groupedTable/GroupedTable';
import GenUtils from '../../utils/GenUtils';
import { config } from './config';

const ReportPage = (props) => {
    const [reportId, setReportId] = useState('');
    const report = useSelector((state: IStore) => state.options.report.find(r => GenUtils.getSimpleStringKey(r.name) === reportId));
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);

    const [searchParams] = useSearchParams();
    const { pathname } = useLocation();

    const [data, setData] = useState(null);
    
    useEffect(() => {
        const pathSplit = pathname.split('/');
        const selectedId = pathSplit[pathSplit.indexOf('reports') + 1];
        selectedId && setReportId(selectedId);
    }, [pathname]);

    useEffect(() => {
        setFrom(searchParams.getAll('date')?.[0])
        setTo(searchParams.getAll('date')?.[1])
    }, [searchParams])

    useEffect(() => {
        const fetchReport = async (selectedId) => {
            const d = await ReportService.fetchReport(selectedId, from, to)
            setData(d.data);
        }
        console.log(reportId)
        if (reportId) {
            fetchReport(reportId);
        }
    }, [from, to, reportId]);


    const { Chart, table, rows = [], columns = [], filters = [], groups = [] } = config[reportId] || {};

    return (
        <div className={styles.container}>
            <HeaderSecondary 
                text={report?.name || 'Report not found'}
                subtext={report?.description}
            />
            {
                Chart && <Chart data={data} />
            }
            { 
                table && columns?.length && data?.length && <GroupedTable columns={columns} data={rows} groups={groups} filters={filters}/>
            }
        </div>
    )
}

export default ReportPage;
