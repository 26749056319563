import Table from '../../../components/table/custom/Table';
import styles from '../../../components/table/custom/Table.scss';
import FormatUtils from '../../../utils/FormatUtils';
import { useState, useEffect } from 'react';
import { InvoiceService } from '../../../services/InvoiceService';
import GenUtils, { ENotificationType } from '../../../utils/GenUtils';
import CustomInput from '../../../components/input/CustomInput';
import { IInvoiceDetail } from '../../../interfaces/IInvoice';
import Button from '../../../components/button/Button';
import { CloseOutlined } from '@ant-design/icons';
import Colors from '../../../constants/Colors';

// const retainer = [
//     {
//         name: 'Retainer Invoice #',
//         key: 'retainer_invoice',
//         span: 4,
//         render: (data) => data.retainer_invoice
//     },
//     {
//         name: 'Date',
//         key: 'date',
//         span: 6,
//         render: (data) => data.date
//     },
//     {
//         name: 'Retainer Amount',
//         key: 'retainer_amount',
//         span: 6,
//         render: (data) => FormatUtils.getAmountInFormat(data.retainer_amount, true, 2, 0)
//     },
//     {
//         name: 'Available Retainer',
//         key: 'available_retainer',
//         span: 6,
//         render: (data) => FormatUtils.getAmountInFormat(data.available_retainer, true, 2, 0)
//     },
//     {
//         name: 'Amount',
//         key: 'amount',
//         span: 2,
//         render: (data) => <Input value={FormatUtils.getAmountInFormat(data.available_retainer, true, 2, 0)} prefix='$' style={{ width: '120px' }} />
//     }
// ];

const OtherItemsTab = ({ data, total, discount }: any) => {
    const [_data, setData] = useState(data);

    useEffect(() => {
        setData(data);
    }, [data]);

    const updateDetail = (detailId, detail) => {
        setData(
            _data
                .map(d => d.uuid === detailId
                    ? { ...d, ...detail }
                    : d
                )
        )
    }

    const updateInvoiceDetail = async (detailId, detail) => {
        updateDetail(detailId, detail);
        const { data, error } = await InvoiceService.updateInvoiceDetails(detailId, detail);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to draft', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }

        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Saved as draft', <notificationIcon.icon
            style={notificationIcon.style} />);
    }

    const addOther = async () => {
        const { error } = await InvoiceService.updateInvoice(data[0]?.invoiceId, {addOther: true});

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Add', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
    }

    const removeOther = async (id) => {
        const { error } = await InvoiceService.deleteInvoiceDetail(id);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to delete', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
    }

    const discountColumns = [
        {
            name: 'Name',
            key: 'name',
            span: 15,
            render: (d) => {
                return (<CustomInput
                    value={d.name}
                    style={{ width: '85%' }}
                    updateOnBlur
                    type='text'
                    onChange={(name) => updateInvoiceDetail(d.uuid, { name })}
                />
            )}
        },
        {
            name: 'Discount',
            key: 'rate',
            span: 9,
            render: (d) => <div style={{display: 'flex', marginLeft: 20}}>
                <CustomInput
                    value={discount}
                    style={{ width: '85%' }}
                    updateOnBlur
                    prefix={FormatUtils.getCurrency()}
                    onChange={(amount) => updateInvoiceDetail(d.uuid, { rate: amount*100/total })}
                />&nbsp;/&nbsp;
                <CustomInput
                    value={d.rate}
                    style={{ width: '85%' }}
                    updateOnBlur
                    suffix='%'
                    onChange={(rate) => updateInvoiceDetail(d.uuid, { rate })}
                />
            </div>
        }
    ];

    const otherItem = [
        {
            name: 'Name',
            key: 'name',
            span: 5,
            render: (d) => <CustomInput
            value={d.name}
            style={{ width: '85%' }}
            updateOnBlur
            type='text'
            onChange={(name) => updateInvoiceDetail(d.uuid, { name })}
        />
        },
        {
            name: 'Description',
            key: 'desc',
            span: 9,
            render: (d) => <CustomInput
            value={d.desc}
            style={{ width: '95%' }}
            updateOnBlur
            type='text'
            onChange={(desc) => updateInvoiceDetail(d.uuid, { desc })}
        />
        },
        {
            name: 'Quantity',
            key: 'count',
            span: 3,
            render: (d) => <CustomInput
            value={d.count}
            style={{ width: '85%' }}
            updateOnBlur
            onChange={(count) => updateInvoiceDetail(d.uuid, { count })}
        />
        },
        {
            name: 'Rate',
            key: 'rate',
            span: 3,
            render: (d) => <CustomInput
            value={d.fee}
            style={{ width: '85%' }}
            prefix={FormatUtils.getCurrency()}
            updateOnBlur
            onChange={(amount) => updateInvoiceDetail(d.uuid, { amount })}
        />
        },
        {
            name: 'Current Due',
            key: 'due',
            span: 3,
            render: (d) => FormatUtils.getAmountInFormat(d.due, true, 2, 0)
        },
        {
            name: '',
            key: 'remove',
            span: 1,
            render: (d) => <CloseOutlined
                onClick={() => removeOther(d.uuid)}
                style={{
                    color: Colors.red,
                    fontSize: 20,
                    display: 'flex',
                    justifyContent: 'center'
                }}
            />
        }
    ];

    return (
        <div style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
            <div>
                <div className={styles.title}>Other Items</div>
                <Table data={data.filter((d: IInvoiceDetail) => d.type === 'other') || []} columns={otherItem} />
                <Button text={'Add Other'} type='ghost' onClick={() => addOther()} style={{marginTop: 20}}/>
            </div>
            <div>
                <div className={styles.title}>Discount</div>
                <Table 
                data={
                    data
                    .filter((d: IInvoiceDetail) => d.type === 'discount')
                    .sort(GenUtils.sortByCreatedAt)|| []
                } 
                columns={discountColumns} 
                />
            </div>
            {/* <div>
                <div className={styles.title}>Use Retainer</div>
                <Table data={data || []} columns={retainer} />
            </div> */}
        </div>
    )
}

export default OtherItemsTab