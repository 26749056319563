import React from 'react';

const ErrorPage = (): JSX.Element => {
    return (
        <div>
            <h1>404 Page Not Found</h1>
        </div>
    )
}

export default ErrorPage;