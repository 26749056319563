import IExpense, { EExpenseStatus } from '../../interfaces/IExpense';
import styles from './ProjectExpense.scss';
import { Card, Col, Divider, Row } from 'antd';
import FormatUtils from '../../utils/FormatUtils';
import GenUtils from '../../utils/GenUtils';
import Tag from '../../components/tag/Tag';
import { PaperClipOutlined } from '@ant-design/icons';
import Colors from '../../constants/Colors';
import Button from '../../components/button/Button';
import React, { useState } from 'react';
import FiltersBar from '../../components/filterBars/FiltersBar';

const FirmExpense = () => {

    const [dateFilter, setDateFilter] = useState(null);
    const [projectFilter, setProjectFilter] = useState(null);
    const [clientFilter, setClientFilter] = useState(null);

    // const data: IExpense[] = [
    //     {
    //         member: { firstName: 'Some Name' },
    //         uuid: 'someUuid',
    //         date: Date.now().toString(),
    //         category: { uuid: 'cat1', name: 'faaltu kharch' },
    //         note: 'bina matlab uda diye',
    //         amount: 124423,
    //         link: '',
    //         status: EExpenseStatus.submitted,
    //         invoiceNumber: 'INV123'
    //     },
    //     {
    //         member: { firstName: 'Some Name' },
    //         uuid: 'someUuid1',
    //         date: Date.now().toString(),
    //         category: { uuid: 'cat1', name: 'faaltu kharch' },
    //         note: 'bina matlab uda diye dobara',
    //         amount: 3546576,
    //         link: '',
    //         status: EExpenseStatus.approved,
    //         invoiceNumber: 'INV123'
    //     },
    //     {
    //         member: { firstName: 'Some Name' },
    //         uuid: 'someUui2d',
    //         date: Date.now().toString(),
    //         category: { uuid: 'cat1', name: 'faaltu kharch' },
    //         note: 'bina matlab uda diye fir se',
    //         amount: 878743,
    //         link: '',
    //         status: EExpenseStatus.rejected,
    //         invoiceNumber: 'INV123'
    //     },
    //     {
    //         member: { firstName: 'Some Name' },
    //         uuid: 'someUui23d',
    //         date: Date.now().toString(),
    //         category: { uuid: 'cat1', name: 'zyada faaltu kharch' },
    //         note: 'ab to hadd ho gyi',
    //         amount: 878743,
    //         link: '',
    //         status: EExpenseStatus.invoiced,
    //         invoiceNumber: 'INV123'
    //     }
    // ];

    const filters = [
        {
            name: 'Date',
            type: 'text',
            onChange: (v) => setDateFilter(v)
        },
        {
            name: 'Project',
            type: 'text',
            onChange: (v) => setProjectFilter(v)
        },
        {
            name: 'Client',
            type: 'text',
            onChange: (v) => setClientFilter(v)
        }

    ];


    const renderRow = ({
                           uuid,
                           date,
                           teamMember,
                           category,
                           note,
                           amount,
                           status,
                           invoice
                       }: IExpense) => {
        return (
            <div className={styles.body}>
                <Row style={{ alignItems: 'center', flex: 1, padding: '0 20px' }}>
                    <Col span={2}>{FormatUtils.getFormattedDateFromTimestamp(date)}</Col>
                    <Col span={3}>{GenUtils.capitalizeFirstLetter(teamMember?.firstName)}</Col>
                    <Col span={3}>{GenUtils.capitalizeFirstLetter(category?.name)}</Col>
                    <Col span={6}>{note || '-'}</Col>
                    <Col span={3}>{FormatUtils.getAmountInFormat(amount, true, 2, '0')}</Col>
                    <Col span={1}>{invoice ? <PaperClipOutlined /> : ''}</Col>
                    <Col span={2} style={{ display: 'flex' }}>
                        <Tag
                            style={{ fontSize: 12 }}
                            color={status === 'submitted' ? Colors.orange : GenUtils.getTagColorByStatus(status)}
                            text={GenUtils.capitalizeFirstLetter(status)}
                        />
                    </Col>
                    {/*<Col span={2}>Invoice</Col>*/}
                    <Col span={4} className={styles.buttonCell}>
                        <div style={{ display: 'flex', gap: 10 }}>
                            <Button text={'Details'} type='link' small onClick={() => {
                            }} />
                            <Button text={'Approve'} small onClick={() => {
                            }} />
                            <Button text={'reject'} small danger onClick={() => {
                            }} />
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };

    return (
        <div>
            <FiltersBar filters={filters} selectedFilters={{}} />
            <Card className={styles.projectTimesheetContainer}>
                <div className={styles.header}>
                    <Row style={{ alignItems: 'center', flex: 1, padding: '0 20px' }}>
                        <Col span={2}>Date</Col>
                        <Col span={3}>Member</Col>
                        <Col span={3}>Category</Col>
                        <Col span={6}>Note</Col>
                        <Col span={3}>Amount</Col>
                        <Col span={1}>Link</Col>
                        <Col span={2}>Status</Col>
                        {/*<Col span={2}>Invoice</Col>*/}
                        <Col span={4} className={styles.buttonCell}></Col>
                    </Row>
                </div>
                <Divider className={styles.divider} />
                {
                    [].map((expense) => renderRow(expense))
                }
            </Card>
        </div>
    );
};

export default FirmExpense;
