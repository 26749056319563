import React from 'react';
import classnames from 'classnames';

// utils
import GenUtils from '../../utils/GenUtils';

// styles
import styles from './TabItem.scss';

export interface ITabItem {
    key: string;
    name: string;
    icon?: React.ReactNode;
    isActive?: boolean;
    onClick?: () => any;
}

const TabItem = ({name, icon, isActive, onClick}: ITabItem) => {
    return(
        <div onClick={onClick} className={classnames(styles.container, isActive && styles.active)}>
            {icon}
            {GenUtils.capitalizeFirstLetter(name)}
        </div>
    );
}

export default TabItem;