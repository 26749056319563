import { Alert, Form, Input, Select, DatePicker, Segmented } from 'antd';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import Button from '../../components/button/Button';

import styles from './AddPhase.scss';
import moment, { Moment } from 'moment';
import PhaseService from '../../services/PhaseService';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import FormatUtils from '../../utils/FormatUtils';

const { Option } = Select;
const { RangePicker } = DatePicker;

const AddPhase = ({ hideModal, onSuccess, totalFee, project, initialPhase }) => {
    const [phase, setPhase] = useState('');
    const [linkedPhase, setLinkedPhase] = useState('');
    const [dateRange, setDateRange] = useState<[Moment, Moment]>([moment(), moment().add(1, 'M')]);
    const [feeType, setFeeType] = useState('fixed');
    const [budget, setBudget] = useState(0);
    const [percent, setPercent] = useState(0);
    const phases = useSelector((state: IStore) => state.options.phase);
    const projectPhases = useSelector((state: IStore) => state.phases.phasesArrayForProject[project.uuid] || []);
    const user = useSelector((state: IStore) => state?.user);

    useEffect(() => {
        if (initialPhase) {
            setPhase(initialPhase.name);
            setLinkedPhase(initialPhase.linkedPhaseId);
            setDateRange([moment(initialPhase.startAt), moment(initialPhase.endAt)]);
            setFeeType(initialPhase.feeType);
            setBudget(initialPhase.amount);
            setPercent((initialPhase.amount * 100) / totalFee);
        }
    }, []);

    const submitForm = async () => {
        const reqPhase = {
            typeUuid: phase,
            linkedPhaseId: linkedPhase,
            startAt: dateRange[0].toDate(),
            endAt: dateRange[1].toDate(),
            feeType: feeType,
            projectId: project?.uuid,
            amount: budget
        };

        if (!initialPhase) {
            const { data, error } = await PhaseService.createPhase(reqPhase);
            if (error) {
                const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
                GenUtils.showNotification('Failed to Create', error, <notificationIcon.icon style={notificationIcon.style} />);
                return;
            }
        } else {
            const { data, error } = await PhaseService.updatePhase(reqPhase, initialPhase.uuid);

            if (error) {
                const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
                GenUtils.showNotification('Failed to Update', error, <notificationIcon.icon style={notificationIcon.style} />);
                return;
            }
        }

        setPhase('');
        setLinkedPhase('');
        setDateRange([moment('2022-09-12 13:00'), moment('2022-09-12 16:00')]);
        setFeeType('fixed');
        setBudget(0);
        setPercent(0);

        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification(
            'Success',
            `${initialPhase ? 'Updated' : 'created'} Phase Successfully`,
            <notificationIcon.icon style={notificationIcon.style} />
        );

        hideModal();
    };

    return (
        <div className={styles.container}>
            <form>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <Form.Item label="Phase" colon={false} className={styles.margin0}></Form.Item>
                        <Select
                            placeholder="Select Phase"
                            value={phase}
                            onChange={(e) => {
                                setPhase(e);
                            }}>
                            {phases &&
                                phases.length &&
                                phases.map((phase) => (
                                    <Option key={phase.uuid} value={phase.uuid}>
                                        {phase.name}
                                    </Option>
                                ))}
                        </Select>
                    </div>
                    <div className={styles.column}>
                        <Form.Item label="Link a Phase" colon={false} className={styles.margin0}></Form.Item>
                        <Select
                            placeholder="Select Phase"
                            value={linkedPhase}
                            onChange={(e) => {
                                setLinkedPhase(e);
                            }}>
                            {projectPhases &&
                                projectPhases.length &&
                                projectPhases.map((phase) => (
                                    <Option key={phase.uuid} value={phase.uuid}>
                                        {phase.type?.name}
                                    </Option>
                                ))}
                        </Select>
                    </div>
                    <div className={styles.column}>
                        <Form.Item label="Date Range" colon={false} className={styles.margin0}></Form.Item>
                        <RangePicker onCalendarChange={(e) => setDateRange(e)} value={dateRange} format={FormatUtils.dateFormat.toUpperCase()}/>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <Form.Item label="Fee Type" colon={false} className={styles.margin0}></Form.Item>
                        <Segmented
                            options={[
                                { label: 'Fixed Fee', value: 'fixed' },
                                { label: 'Hourly Fee', value: 'hourly' }
                            ]}
                            block={true}
                            value={feeType}
                            onChange={(e) => {
                                setBudget(0);
                                setPercent(0);
                                setFeeType(String(e));
                            }}
                        />
                    </div>
                    {feeType === 'fixed' ? (
                        <>
                            <div className={styles.column}>
                                <Form.Item label="Phase Fee" colon={false} className={styles.margin0}></Form.Item>
                                <Input
                                    placeholder="Phase Fee"
                                    type={'number'}
                                    prefix={FormatUtils.currencyIso}
                                    value={budget}
                                    onChange={(e) => {
                                        setBudget(Number(e.target.value));
                                        setPercent((Number(e.target.value) * 100) / totalFee);
                                    }}
                                />
                            </div>
                            <div className={styles.column}>
                                <Form.Item label="% of Service Fee" colon={false} className={styles.margin0}></Form.Item>
                                <Input
                                    placeholder="% of Service Fee"
                                    type={'number'}
                                    value={percent}
                                    onChange={(e) => {
                                        if (Number(e.target.value) >= 0 && Number(e.target.value) <= 100) {
                                            setPercent(Number(e.target.value));
                                            setBudget(0.01 * Number(e.target.value) * totalFee);
                                        }
                                    }}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={styles.column}>
                                <Form.Item label="Max Cap" colon={false} className={styles.margin0}></Form.Item>
                                <Input
                                    placeholder="Max Cap"
                                    type={'number'}
                                    prefix={FormatUtils.currencyIso}
                                    value={budget}
                                    onChange={(e) => {
                                        setBudget(Number(e.target.value));
                                    }}
                                />
                            </div>
                            <div className={styles.column}></div>
                        </>
                    )}
                </div>
            </form>
            <div className={classnames(styles.row, styles.center)}>
                <Button type={'ghost'} text="Cancel" onClick={hideModal} />
                <Button
                    text="Save"
                    onClick={() => {
                        submitForm();
                    }}
                />
            </div>
        </div>
    );
};
export default AddPhase;
