import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Button from '../button/Button';
import { Col, Form, Input, Row } from 'antd';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import ClientService from '../../services/ClientService';
import styles from '../../pages/client/AddClient.scss';
import EditableCellsTable from '../table/EditableCellsTable';
import IContact from '../../interfaces/IContact';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import { CloseOutlined } from '@ant-design/icons';

const AddContact = ({ clientId, onHide }) => {
    const [contacts, setContacts] = useState([]);
    const client = useSelector((state: IStore) => state.client.clients[clientId] || {});

    useEffect(() => {
        setContacts(client.contacts);
    }, [client.contacts])

    const addContact = async (contact: IContact) => {
        const { uuid, name, designation, email, mobile } = contact;

        const {
            data,
            error
        } = await ClientService.addContact({ clientId, name, designation, email, mobile });
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Add Contact', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Added Contact successfully', <notificationIcon.icon
            style={notificationIcon.style} />);
        onHide();
    };

    const updateContact = async (contact: IContact) => {
        const { uuid, name, designation, email, mobile } = contact;

        const {
            data,
            error
        } = await ClientService.updateContact(uuid, { name, designation, email, mobile });
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Update Contact', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Updated Contact successfully', <notificationIcon.icon
            style={notificationIcon.style} />);
        onHide();
    };


    const deleteContact = async (contact) => {
        if (!GenUtils.isUuid(contact.uuid)) {
            setContacts(contacts.filter((c) => c.key !== contact.key));
            return;
        }

        const {
            data,
            error
        } = await ClientService.deleteContact(clientId, contact.uuid);
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Delete Contact', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Deleted Contact successfully', <notificationIcon.icon
            style={notificationIcon.style} />);
        onHide();
    };

    const updateContacts = (_contacts: IContact[], save = false) => {
        setContacts(_contacts);
        const contactMap = GenUtils.arrayToIdMap(_contacts);
        _contacts.forEach(c => {
            const isSame = Object.keys(c).reduce((isSame, key) => isSame && c[key] === contactMap[c.uuid][key], true);
            if (!isSame && GenUtils.isUuid(c.uuid)) updateContact(c);
            if (save
                && !c.uuid
                && Object
                    .keys(c)
                    .reduce(
                        (didEnterSomeValue, k) => k !== 'key' ? didEnterSomeValue || (c[k] !== '...' && c[k] !== '') : didEnterSomeValue,
                        false)
            ) addContact(c);
        })
    }

    const emptyContact = {
        name: '...',
        designation: '...',
        email: '...',
        mobile: '...',
        key: Date.now(),
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            editable: true
        },
        {
            title: 'Designation',
            dataIndex: 'designation',
            editable: true
        },
        {
            title: 'Email',
            dataIndex: 'email',
            editable: true
        },
        {
            title: 'Contact',
            dataIndex: 'mobile',
            editable: true
        },
        {
            title: '',
            dataIndex: 'delete',
            render: (text, data, index) => <CloseOutlined style={{ color: 'IndianRed' }} onClick={() => deleteContact(data)} />
        }
    ];


    return (
        <Form layout='vertical' name='basic' initialValues={{ remember: true }} >
            <EditableCellsTable
                columns={columns}
                dataSource={
                    contacts?.length
                        ? contacts.map((contact: IContact) => {
                            return { ...contact, key: contact.uuid };
                        })
                        : [emptyContact]
                }
                newData={emptyContact}
                setDataSource={updateContacts}
                addButtonText={'Add New Contact'}
            />
            <Row style={{ gap: '20px', justifyContent: 'center' }}>
                <Col className='hp-pr-sm-0 hp-pr-12'>
                    <Button
                        block
                        type='primary'
                        onClick={() => updateContacts(contacts, true)}
                        text='Add'
                    />
                </Col>

                <Col className='hp-mt-sm-12 hp-pl-sm-0 hp-pl-12'>
                    <Button block type='ghost' onClick={onHide} text='Cancel' />
                </Col>
            </Row>
        </Form>
    );
};

export default AddContact;
