import IPermission from '../interfaces/IPermission';

export default class PermissionUtils {
    static permission: IPermission = {};

    // Info: Team & access
    static canSeeFirmSettingsTab() {
        //
        return this.permission.manageFirmSettings;
    }

    static canSeeTeamTab() {
        //
        return this.permission.manageMembers && this.permission.membersToManage && this.permission.membersToManage !== 'none';
    }

    static canUpdateOrCreateCompensations() {
        //
        return this.permission.manageCompensations;
    }

    // Info: Timesheet
    static viewTimesheetFor() {
        //
        if (this.permission.viewTimeSheet) {
            return (this.permission.membersToManage || 'own').replace('none', 'own');
        }
        return 'own';
    }

    static editTimesheetFor() {
        if (this.permission.editTimeSheet) {
            return (this.permission.membersToManage || 'own').replace('none', 'own');
        }
        return 'own';
    }

    static approveTimeSheet() {
        //
        //
        if (this.permission.approveTimeSheet) {
            return this.permission.membersToManage || 'none';
        }
        return 'none';
    }

    static canViewTimeSheetAuditTab(){
      return this.permission.approveTimeSheet
    }
    // Info: Allocation
    static canSeeAllocationTab() {
        //
        //
        return this.permission.ViewTimeAllocation;
    }

    static canEditAllocation() {
        //
        return this.permission.ViewTimeAllocation && this.permission.editTimeAllocation;
    }

    static canViewFinancesInAllocation() {
        //
        return this.permission.viewAllocationFinance;
    }

    // Info: Projects
    static canViewFinancesInProjects() {
        return this.permission.viewProjectFinance || this.permission.editProjects;
    }

    static canCreateProjects() {
        //
        return this.permission.createProjects;
    }

    static canDeleteProjectFor() {
        if (this.permission.deleteProjects) {
            return this.permission.projectsToManage || 'none';
        }
        return 'none';
    }

    static canEditProjectFor() {
        //
        if (this.permission.editProjects) {
            return this.permission.projectsToManage || 'none';
        }
        return 'none';
    }

    // Info: Expenses
    static canSeeExpensesTab() {
        //
        //
        return this.canSeeAllExpenses() || this.canSeeExpensesForAssignedProjects() || this.canSeeExpensesForAssignedMembers();
    }

    static canSeeAllExpenses() {
        //
        return this.permission.manageProjectExpenses;
    }

    static canSeeExpensesForAssignedProjects() {
        //
        return this.permission.manageAssignedProjectExpenses;
    }

    static canSeeExpensesForAssignedMembers() {
        return this.permission.manageAssignedMemberExpenses;
    }

    static canApproveExpensesFor() {
        if (this.permission.approveExpenses) {
            return this.permission.projectsToManage || 'none';
        }
        return 'none';
    }

    // Info: Communication
    static canManageFinancialCommunication() {
        return this.permission.manageFinancialCommunication;
    }

    static canManageProjectCommunication() {
        return this.permission.manageProjectCommunication;
    }

    // Info: Invoices
    static canSeeInvoicesTab() {
        //
        return this.permission.viewInvoices || this.canEditInvoices();
    }

    static canEditInvoices() {
        return this.permission.editInvoices;
    }

    static canCreateInvoices() {
        //
        return this.permission.createInvoices;
    }

    static canApproveInvoicesFor() {
        if (this.permission.approveInvoices) {
            return 'all';
        } else if (this.permission.approveAssignedProjectInvoices) {
            return 'assigned';
        }
        return 'none';
    }

    // Info: Clients
    static canSeeClientTab() {
        //
        return this.permission.viewClientDetails;
    }

    static canCreateClient() {
        //
        return this.permission.createClient;
    }

    static canViewFinancesOfClient() {
        //
        return this.permission.viewClientFinances;
    }

    static canUpdateClientStatus() {
        //
        return this.permission.manageClient;
    }

    // Info: Reports
    static viewProjectReportsFor() {
        if (this.permission.viewAllProjectReports) return 'all';
        if (this.permission.viewProjectReports) return 'assigned';
        return 'none';
    }

    static viewMemberReportsFor() {
        if (this.permission.viewAllMemberReports) return 'all';
        if (this.permission.viewMemberReports) return 'assigned';
        return 'none';
    }

    static viewFinancialReports() {
        return this.permission.viewFinanceReports;
    }
}
