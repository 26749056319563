import React from 'react';
import GroupedTable, { EColumnType } from '../../../components/table/groupedTable/GroupedTable';
import { EFilterTypes } from '../../../components/filterBars/FiltersBarMultiselect';

import styles from './Charts.scss';

const ProjectProfitability = ({ data }) => {

    const filters = [
        {
            name: 'Date',
            dataIndex: null,
            type: EFilterTypes.dateRange
        },
        {
            name: 'Project',
            dataIndex: 'projectId',
            type: EFilterTypes.dropdown
        }
    ];

    const columns = [
        {
            title: 'Project Name',
            dataIndex: 'project.uuid',
            type: EColumnType.project,
        },
        {
            title: 'Status',
            dataIndex: 'project.status',
            type: 'tag'
        },
        {
            title: 'Contract Fee',
            dataIndex: 'project.projectFee',
            type: EColumnType.amount,
            // render: (projectFee) => <span> {projectFee}</span>
        },
        {
            title: 'Logged',
            dataIndex: 'logged',
            type: EColumnType.amount
        },
        {
            title: 'Logged %',
            dataIndex: 'loggedPercent',
            type: EColumnType.percent,
        },
        {
            title: 'Unbilled',
            dataIndex: 'unbilled',
            type:  EColumnType.amount
        },
        {
            title: 'Billed',
            dataIndex: 'billed',
            type:  EColumnType.amount
        },
        {
            title: 'Billed %',
            dataIndex: 'billedPercent',
            type: EColumnType.percent,
        },
        {
            title: 'Collected',
            dataIndex: 'collected',
            type: EColumnType.amount
        },
        {
            title: 'Pending',
            dataIndex: 'pending',
            type:  EColumnType.amount,
        }, {
            title: 'Cost',
            dataIndex: 'projectFee',
            type:  EColumnType.amount
        }, {
            title: 'Margin',
            dataIndex: 'margin',
            type: 'margin'
        }
    ];

    return (
        <div className={styles.container}>
            <GroupedTable columns={columns} data={data} filters={filters} scrollX={1500}/>
        </div>
    );
};

export default ProjectProfitability;
