import { CloseOutlined, CopyTwoTone, HeartOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import styles from './NotePopOver.scss';
import StorageUtils, { StorageConstants } from '../../utils/StorageUtils';
import CustomInput from '../../components/input/CustomInput';

const NotePopOver = ({ value = '', onChange, disabled = false }) => {
    const [notes, setNotes] = useState([]);
    const [noteInput, setNoteInput] = useState('');

    useEffect(() => {
        setNotes(StorageUtils.get(StorageConstants.NOTES) || []);
    }, []);

    useEffect(() => {
        setNoteInput(value);
    }, [value]);

    return (
        <>
            <div className={styles.noteInput}>
                <CustomInput
                    type='text'
                    disabled={disabled}
                    style={{border: 'none'}}
                    value={noteInput}
                    placeholder="Add A Note"
                    onChange={(v) => {
                        onChange(v)
                        setNoteInput(v);
                    }}
                    updateOnBlur
                />
                <HeartOutlined
                    onClick={() => {
                        StorageUtils.set(StorageConstants.NOTES, notes.length ? [...notes, noteInput] : [noteInput]);
                        setNotes(StorageUtils.get(StorageConstants.NOTES));
                    }}
                />
            </div>
            {notes.length ? (
                <div style={{ backgroundColor: '#F7FAFC', maxHeight: 200, overflowY: 'auto', padding: 10 }}>
                    <strong>Saved Notes:</strong>
                    {notes.map((note, i) => (
                        <div 
                            className={styles.noteItem} 
                            key={note + i} 
                            onClick={() => {
                                onChange(note)
                                setNoteInput(note)
                            }}
                        >
                            {" "+note}
                            <div style={{gap: 5, display: 'flex'}}>
                                <CopyTwoTone onClick={() => navigator.clipboard.writeText(note)}/>
                                <CloseOutlined
                                    onClick={() => {
                                        StorageUtils.set(StorageConstants.NOTES, [...notes.slice(0, i), ...notes.slice(i + 1)]);
                                        setNotes([...notes.slice(0, i), ...notes.slice(i + 1)]);
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            ) : null}
        </>
    );
};

export default NotePopOver;
