import React, { useEffect, useState } from 'react';
import styles from './Gantt.scss';
import moment from 'moment';
import GenUtils from '../../utils/GenUtils';
import Tag from '../tag/Tag';
import PermissionUtils from '../../utils/PermissionUtils';
import NavigationUtils from '../../utils/NavigationUtils';
import { IStore } from '../../redux/store';
import { useSelector } from 'react-redux';
import IProject from '../../interfaces/IProject';
import Colors from '../../constants/Colors';
import classnames from 'classnames';
import { CaretDownFilled, CheckCircleFilled, StarFilled } from '@ant-design/icons';
import { Popover } from 'antd';
import TaskPopover from '../popovers/TaskPopover';
import PhaseDetailPopover from '../popovers/PhaseDetailPopover';
import { useRef } from 'react';
const Gantt = ({ data = [], onClick = () => {}, project = {} as IProject }) => {
    const [months, setMonths] = useState(10);
    const [startAt, setStartAt] = useState(moment());
    const [endAt, setEndAt] = useState(moment());
    const [items, setItems] = useState([]);
    const todayRef = useRef<HTMLDivElement>(null);
    const ganttRef = useRef<HTMLDivElement>(null);

    const firmId = useSelector((state: IStore) => state?.firm?.uuid);
    const tasksArray = useSelector((state: IStore) => state.tasks.tasksArray || []);
    const milestonesArray = useSelector((state: IStore) => state.milestones?.milestonesArray || []);
    const viewProjectFinance = PermissionUtils.canViewFinancesInProjects();

    useEffect(() => {
        const _startAt = data.reduce((startDate, d) => (startDate.isAfter(d.startAt) ? moment(d.startAt) : startDate), startAt);
        const _endAt = data.reduce((endDate, d) => (endDate.isBefore(d.endAt) ? moment(d.endAt) : endDate), endAt);
        const _months = Math.ceil(_endAt.diff(_startAt, 'months', true)) + 2;

        if (!startAt.isSame(_startAt) || !endAt.isSame(_endAt)) {
            setStartAt(moment(_startAt).subtract(1, 'months'));
            setEndAt(_endAt);
            setMonths((m) => (_months > m ? _months : m));
        }
    }, [data]);

    useEffect(() => {
        const coords = todayRef.current.getBoundingClientRect();
        ganttRef.current.scrollTo(coords.x - 750, coords.y);
    }, [endAt]);

    useEffect(() => {
        if (tasksArray || milestonesArray) {
            const _items = [];

            if (tasksArray.length) {
                tasksArray.forEach((t) =>
                    _items.push({
                        type: 'task',
                        date: moment(t.date),
                        complete: t.status === 'completed',
                        data: t
                    })
                );
            }

            if (milestonesArray.length) {
                milestonesArray.forEach((m) =>
                    _items.push({
                        type: 'milestone',
                        date: moment(m.date),
                        data: m
                    })
                );
            }
            setItems(_items);
        }
    }, [tasksArray, milestonesArray]);

    const getLeft = (_startAt, _endAt, date) => {
        const length = moment(_endAt).diff(_startAt, 'days');
        const left = moment(date).diff(_startAt, 'days');

        if ((left * 100) / 30 < 40) {
            return 40;
        }

        if ((length * 100) / 30 - (left * 100) / 30 < 30) {
            return (length * 100) / 30 - 30;
        }

        return (left * 100) / 30;
    };

    return (
        <div className={styles.container}>
            <div className={styles.listContainer}>
                <span className={styles.header}>
                    {project?.uuid ? 'Phases' : 'Projects'} ({data.length})
                </span>
                {data.sort(GenUtils.sortByCreatedAt).map((d) => (
                    <div
                        className={styles.listItem}
                        onClick={() => {
                            PermissionUtils.canEditProjectFor() !== 'none' &&
                                NavigationUtils.navigateTo(`${firmId}/projects/${project?.uuid || d.uuid}${project.uuid ? '/edit' : ''}`);
                        }}>
                        <Tag color={d.color || project?.color} text={((d.serial || d.type?.abbreviation) + '').toUpperCase()} />
                        <span style={{ flex: 1 }}>{GenUtils.capitalizeFirstLetter(d?.name || d.type?.name)}</span>
                        {d?.status && <Tag text={d.status} color={GenUtils.getTagColorByStatus(d.status)} />}
                    </div>
                ))}
            </div>
            <div className={styles.gantt} ref={ganttRef}>
                <div className={styles.header}>
                    {Array(months)
                        .fill('')
                        .map((x, i) => (
                            <div className={styles.cell} key={i}>
                                {moment(startAt).add(i, 'months').format("MMM'YY")}
                            </div>
                        ))}
                    <div ref={todayRef} className={styles.today} style={{ left: getLeft(startAt.date(1), endAt, moment()) }}>
                        <CaretDownFilled />
                    </div>
                </div>
                <div className={styles.body}>
                    {data.sort(GenUtils.sortByCreatedAt).map((d) => (
                        <div className={styles.row} key={d.uuid}>
                            <div className={styles.bgRow}>
                                {Array(months)
                                    .fill('')
                                    .map((x, i) => (
                                        <div className={styles.cell} key={i} />
                                    ))}
                            </div>
                            <RenderPhases proj={d} project={project} startAt={startAt} items={items} canViewProjectFinance={viewProjectFinance} />
                            <div className={styles.today} style={{ left: getLeft(startAt.date(1), endAt, moment()) }} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

function hexToHSL(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    if (result === null) {
        return `hsl(${60}, 50%, 80%)`;
    }

    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);

    r /= 255;
    g /= 255;
    b /= 255;
    let max = Math.max(r, g, b),
        min = Math.min(r, g, b);
    let h: number = (max + min) / 2;

    if (max === min) {
        h = 0;
    } else {
        var d = max - min;
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
        }

        h /= 6;
    }

    h = Math.round(h * 360);

    return `hsl(${h}, 50%, 80%)`;
}

function RenderPhases({
    proj: d,
    project,
    startAt,
    items,
    canViewProjectFinance
}: {
    proj: any;
    project: any;
    startAt: moment.Moment;
    items: any[];
    canViewProjectFinance: boolean;
}) {
    const [curr, setCurr] = React.useState<string>(null);

    React.useMemo(() => {
        if (d.phases && d.phases.length >= 1) {
            setCurr(d.phases[d.phases.length - 1].uuid);
        }
    }, [d]);

    const getProps = (phase, proj = {} as IProject) => {
        const left = Math.abs((100 / 30) * moment(startAt).date(1).diff(phase.startAt, 'days')) - 10;
        const width = Math.abs((100 / 30) * moment(phase.endAt).diff(phase.startAt, 'days'));
        const backgroundColor = proj?.color || project?.color;

        return { left, width, backgroundColor };
    };

    const getLeft = (_startAt, _endAt, date) => {
        const length = moment(_endAt).diff(_startAt, 'days');
        const left = moment(date).diff(_startAt, 'days');

        if ((left * 100) / 30 < 40) {
            return 40;
        }

        if ((length * 100) / 30 - (left * 100) / 30 < 30) {
            return (length * 100) / 30 - 30;
        }

        return (left * 100) / 30;
    };

    const getProgress = (p) => {
        return !p.amount ? 100 : (p.progress?.amount * 100) / p.amount;
    };

    return (
        <>
            {d.phases ? (
                d.phases.map((p, zIndex) => (
                    <div
                        className={classnames(styles.stickContainer, styles.verticallyCenter)}
                        style={{ ...getProps(p, d), backgroundColor: Colors.transparent, zIndex: p.uuid === curr ? 1 : 0 }}
                        tabIndex={0}
                        onClick={() => {
                            setCurr(p.uuid);
                        }}>
                        {canViewProjectFinance ? (
                            <Popover placement="bottom" content={<PhaseDetailPopover phase={p} project={d} />}>
                                <div className={styles.stick}>
                                    <div className={styles.bar}>
                                        <div
                                            className={classnames(styles.progress)}
                                            style={{
                                                backgroundColor: `${getProps(p, d).backgroundColor}`,
                                                flex: getProgress(p)
                                            }}
                                        />
                                        <div
                                            className={classnames(styles.empty)}
                                            style={{
                                                backgroundColor: hexToHSL(`${getProps(p, d).backgroundColor}`),
                                                flex: 100 - getProgress(p)
                                            }}
                                        />
                                    </div>
                                    <div className={styles.bar} style={{ overflow: 'visible', position: 'absolute', top: 0, left: 0, width: '100%' }}>
                                        <div
                                            className={classnames(styles.progress)}
                                            style={{
                                                backgroundColor: Colors.transparent,
                                                flex: getProgress(p)
                                            }}
                                        />
                                        <div className={styles.dollarContainer}>
                                            <div className={styles.dollar} style={{ backgroundColor: `${getProps(p, d).backgroundColor}` }}>
                                                $
                                            </div>
                                        </div>
                                        <div
                                            className={classnames(styles.empty)}
                                            style={{
                                                backgroundColor: Colors.transparent,
                                                flex: 100 - getProgress(p),
                                                opacity: 0.3
                                            }}
                                        />
                                    </div>
                                    <div className={classnames(styles.abb, styles.verticallyCenter)}>{p.type.abbreviation}</div>
                                    {items
                                        .filter((i) => i.data.phaseId === p.uuid)
                                        .map((t, i) => (
                                            <div
                                                className={classnames(styles.abb, styles.verticallyCenter)}
                                                style={{
                                                    left: getLeft(p.startAt, p.endAt, t.date),
                                                    padding: 0
                                                }}
                                                key={i}>
                                                {t.type === 'milestone' ? (
                                                    <Popover placement="bottom" content={<TaskPopover task={t.data} />}>
                                                        <StarFilled color={Colors.white} />
                                                    </Popover>
                                                ) : (
                                                    <Popover placement="bottom" content={<TaskPopover task={t.data} isTask />}>
                                                        {t.complete ? <CheckCircleFilled color={Colors.white} /> : <div className={styles.task}>〇</div>}
                                                    </Popover>
                                                )}
                                            </div>
                                        ))}
                                </div>
                            </Popover>
                        ) : (
                            <div className={styles.stick}>
                                <div className={styles.bar}>
                                    <div
                                        className={classnames(styles.empty)}
                                        style={{
                                            backgroundColor: hexToHSL(getProps(p, d).backgroundColor),
                                            flex: 100
                                        }}
                                    />
                                </div>
                                <div className={classnames(styles.abb, styles.verticallyCenter)}>{p.type.abbreviation}</div>
                                {items
                                    .filter((i) => i.data.phaseId === p.uuid)
                                    .map((t, i) => (
                                        <div
                                            className={classnames(styles.abb, styles.verticallyCenter)}
                                            style={{
                                                left: getLeft(p.startAt, p.endAt, t.date),
                                                padding: 0
                                            }}
                                            key={i}>
                                            {t.type === 'milestone' ? (
                                                <Popover placement="bottom" content={<TaskPopover task={t.data} />}>
                                                    <StarFilled color={Colors.white} />
                                                </Popover>
                                            ) : (
                                                <Popover placement="bottom" content={<TaskPopover task={t.data} isTask />}>
                                                    {t.complete ? <CheckCircleFilled color={Colors.white} /> : <div className={styles.task}>〇</div>}
                                                </Popover>
                                            )}
                                        </div>
                                    ))}
                            </div>
                        )}
                    </div>
                ))
            ) : (
                <div className={classnames(styles.stickContainer, styles.verticallyCenter)} style={{ ...getProps(d), backgroundColor: Colors.transparent }}>
                    {canViewProjectFinance ? (
                        <Popover placement="bottom" content={<PhaseDetailPopover phase={d} project={project} />} style={{ padding: -10 }}>
                            <div className={styles.stick}>
                                <div className={styles.bar}>
                                    <div
                                        className={classnames(styles.progress)}
                                        style={{
                                            backgroundColor: `${getProps(d).backgroundColor}`,
                                            flex: getProgress(d)
                                        }}
                                    />
                                    <div
                                        className={classnames(styles.empty)}
                                        style={{
                                            backgroundColor: hexToHSL(`${getProps(d).backgroundColor}`),
                                            flex: 100 - getProgress(d)
                                        }}
                                    />
                                </div>
                                <div className={styles.bar} style={{ overflow: 'visible', position: 'absolute', top: 0, left: 0, width: '100%' }}>
                                    <div
                                        className={classnames(styles.progress)}
                                        style={{
                                            backgroundColor: Colors.transparent,
                                            flex: getProgress(d)
                                        }}
                                    />
                                    <div className={styles.dollarContainer}>
                                        <div className={styles.dollar} style={{ backgroundColor: getProps(d).backgroundColor }}>
                                            $
                                        </div>
                                    </div>
                                    <div
                                        className={classnames(styles.empty)}
                                        style={{
                                            backgroundColor: Colors.transparent,
                                            flex: 100 - getProgress(d),
                                            opacity: 0.3
                                        }}
                                    />
                                </div>
                                <div className={classnames(styles.abb, styles.verticallyCenter)}>{d.type.abbreviation}</div>
                                {items
                                    .filter((i) => i.data.phaseId === d.uuid)
                                    .map((t, i) => (
                                        <div
                                            className={classnames(styles.abb, styles.verticallyCenter)}
                                            style={{
                                                left: getLeft(d.startAt, d.endAt, t.date),
                                                padding: 0
                                            }}
                                            key={i}>
                                            {t.type === 'milestone' ? (
                                                <Popover placement="bottom" content={<TaskPopover task={t.data} />}>
                                                    <StarFilled color={Colors.white} />
                                                </Popover>
                                            ) : (
                                                <Popover placement="bottom" content={<TaskPopover task={t.data} isTask />}>
                                                    {t.complete ? <CheckCircleFilled color={Colors.white} /> : <div className={styles.task}>〇</div>}
                                                </Popover>
                                            )}
                                        </div>
                                    ))}
                            </div>
                        </Popover>
                    ) : (
                        <div className={styles.stick}>
                            <div className={styles.bar}>
                                <div
                                    className={classnames(styles.empty)}
                                    style={{
                                        backgroundColor: hexToHSL(getProps(d).backgroundColor),
                                        flex: 100
                                    }}
                                />
                            </div>
                            <div className={classnames(styles.abb, styles.verticallyCenter)}>{d.type.abbreviation}</div>
                            {items
                                .filter((i) => i.data.phaseId === d.uuid)
                                .map((t, i) => (
                                    <div
                                        className={classnames(styles.abb, styles.verticallyCenter)}
                                        style={{
                                            left: getLeft(d.startAt, d.endAt, t.date),
                                            padding: 0
                                        }}
                                        key={i}>
                                        {t.type === 'milestone' ? (
                                            <Popover placement="bottom" content={<TaskPopover task={t.data} />}>
                                                <StarFilled color={Colors.white} />
                                            </Popover>
                                        ) : (
                                            <Popover placement="bottom" content={<TaskPopover task={t.data} isTask />}>
                                                {t.complete ? <CheckCircleFilled color={Colors.white} /> : <div className={styles.task}>〇</div>}
                                            </Popover>
                                        )}
                                    </div>
                                ))}
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default Gantt;

