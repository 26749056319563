import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import { useEffect, useState } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import HeaderSecondary from '../../components/header/HeaderSecondary';
import Button from '../../components/button/Button';
import NavigationUtils from '../../utils/NavigationUtils';
import PhaseService from '../../services/PhaseService';
import GenUtils from '../../utils/GenUtils';
import styles from './ProjectDetailPage.scss';
import CommunicationCard from '../../components/card/CommunicationCard';
import InfoCard from '../../components/card/InfoCard';
import { ECommunicationType } from '../../redux/reducers/CommunicationReducer';
import FormatUtils from '../../utils/FormatUtils';
import ProjectProgressCard from '../../components/card/PhaseProgressCard';
import PermissionUtils from '../../utils/PermissionUtils';
import Gantt from '../../components/timeline/Gantt';



const ProjectDetailPage = () => {
    const [selectedProjectId, setSelectedProjectId] = useState('');
    const firmId = useSelector((state: IStore) => state?.firm?.uuid);
    const phasesArray = useSelector((state: IStore) => state.phases.phasesArrayForProject[selectedProjectId] || []);
    const project = useSelector((state: IStore) => state.project.projects[selectedProjectId] || {});
    
    const { pathname } = useLocation();

    useEffect(() => {
        const pathSplit = pathname.split('/');
        const selectedId = pathSplit[pathSplit.indexOf('projects') + 1];
        selectedId && setSelectedProjectId(selectedId);
    }, [pathname]);

    useEffect(() => {
        fetchPhases();
    }, [selectedProjectId]);

    const fetchPhases = async () => {
        if (selectedProjectId) {
            const { data, error } = await PhaseService.fetchPhasesForProject(selectedProjectId);
        }
    };

    const editProject = () => {
        NavigationUtils.navigateTo(`${firmId}/projects/${selectedProjectId}/edit`);
    };

    return (
        <div>
            {phasesArray.length > 0 ? (
                <Gantt data={phasesArray.sort(GenUtils.sortByCreatedAt)} project={project} />
            ) : (
                <>&nbsp;&nbsp;&nbsp;No phases created...</>
            )}
            <div className={styles.bottomCardContainer}>
                <div className={styles.cardColumn}>
                    <div className={styles.communicationCardContainer}>
                        {selectedProjectId && <CommunicationCard type={ECommunicationType.project} typeId={selectedProjectId} clientId={project?.clientId} />}
                    </div>
                    {PermissionUtils.canViewFinancesInProjects() ? (
                        <div className={styles.infoCardContainer}>
                            <InfoCard
                                title="Project info"
                                data={
                                    PermissionUtils.canViewFinancesInProjects()
                                        ? {
                                              DURATION: `${FormatUtils.getFormattedDateFromTimestamp(
                                                  project.startAt
                                              )} - ${FormatUtils.getFormattedDateFromTimestamp(project.endAt)}`,
                                              'Total Project Budget': FormatUtils.getAmountInFormat(project.projectFee + project.consultantFee, true),
                                              'Architectural Fees': FormatUtils.getAmountInFormat(project.projectFee, true),
                                              'Consultant Fees': FormatUtils.getAmountInFormat(project.consultantFee, true)
                                          }
                                        : {
                                              DURATION: `${FormatUtils.getFormattedDateFromTimestamp(
                                                  project.startAt
                                              )} - ${FormatUtils.getFormattedDateFromTimestamp(project.endAt)}`
                                          }
                                }
                                action={PermissionUtils.canViewFinancesInProjects() ? <Button onClick={() => {}} text="Edit" /> : null}
                            />
                        </div>
                    ) : null}
                </div>
                {PermissionUtils.canViewFinancesInProjects() ? (
                    <div className={styles.cardColumn}>
                        <div className={styles.infoCardContainer}>
                            <ProjectProgressCard project={project} />
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default ProjectDetailPage;
