import { APIUtil } from '../utils/ApiUtils';
import ApiURLs from '../constants/ApiURLs';
import GenUtils from '../utils/GenUtils';
import { setConsultants } from '../redux/actions/ConsultantActions';
import _ from 'lodash';

export default class ConsultantService {
  static async createConsultant(consultant) {
    const { data, error } = await APIUtil.makePostRequest(`${ApiURLs.CREATE_CONSULTANT}`, consultant);

    if (error) {
      GenUtils.log('createConsultant', error);
      return { error };
    }
    await this.fetchConsultantForProject(consultant.projectId);
    return { data };
  }

  static async fetchConsultantForProject(projectId: string) {
    const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.GET_CONSULTANT_FOR_PROJECT}/${projectId}`);

    if (error) {
      GenUtils.log('fetchConsultantForProject', { error });
      return;
    }

    GenUtils.dispatch(setConsultants(data, projectId));
    return { data };
  }

  static async fetchConsultant(projectId: string) {
    const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.GET_CONSULTANT}/${projectId}`);

    if (error) {
      GenUtils.log('fetchConsultant', { error });
      return;
    }

    return { data };
  }

  static async updateConsultant(consultant) {
    let _consultant = { ...consultant };
    _consultant = _.omit(_consultant, ['consultantType', 'uuid']);
    if (!consultant.phases.length && consultant.involvementType==='project') {
      _consultant = _.omit(_consultant, ["phases"]);
    } else {
      _consultant.phases = _consultant.phases.map(({ fee, payout, isFullPayout, phaseId }) => ({ fee, payout, isFullPayout, phaseId }))
    }
    
    const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.CREATE_CONSULTANT}`, _consultant);

    if (error) {
      GenUtils.log('createConsultant', error);
      return { error };
    }
    await this.fetchConsultantForProject(consultant.projectId);
    return { data };
  }

  static async deleteConsultant(consultantId, projectId) {
    const { data, error } = await APIUtil.makeDeleteRequest(`${ApiURLs.CREATE_CONSULTANT}/${consultantId}`);

    if (error) {
      GenUtils.log('createConsultant', error);
      return { error };
    }
    await this.fetchConsultantForProject(projectId);
    return { data };
  }
}