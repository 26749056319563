import { APIUtil } from '../utils/ApiUtils';
import ApiURLs from '../constants/ApiURLs';
import GenUtils from '../utils/GenUtils';
import { setClients, updateClient } from '../redux/actions/ClientActions';
import IContact from '../interfaces/IContact';
import IClient from '../interfaces/IClient';
import _ from 'lodash';

export default class ClientService {
    static async createClient(name, referralSource, currency, taxRegId, building, street, city, state, country, zipCode, contacts): Promise<any> {
        let client = { name };

        if (!GenUtils.isEmpty(referralSource)) client['referralSource'] = referralSource;
        if (!GenUtils.isEmpty(currency)) client['currency'] = currency;
        if (!GenUtils.isEmpty(taxRegId)) client['taxRegId'] = taxRegId;
        if (!GenUtils.isEmpty(building)) client['building'] = building;
        if (!GenUtils.isEmpty(street)) client['street'] = street;
        if (!GenUtils.isEmpty(city)) client['city'] = city;
        if (!GenUtils.isEmpty(state)) client['state'] = state;
        if (!GenUtils.isEmpty(country)) client['country'] = country;
        if (!GenUtils.isEmpty(zipCode)) client['zipCode'] = zipCode;
        if (!GenUtils.isEmpty(contacts)) client['contacts'] = contacts;

        const { data, error } = await APIUtil.makePostRequest(`${ApiURLs.CREATE_CLIENT}`, client);

        if (error) {
            GenUtils.log('createClient', error);
            return { error };
        }

        await this.fetchClients();
        return { data };
    }

    static async fetchClients() {
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.GET_CLIENT}`);

        if (error) {
            GenUtils.log('fetchClients', { error });
            return { error };
        }

        GenUtils.dispatch(setClients(data));
        return { data };
    }

    static async fetchClient(clientId) {
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.GET_CLIENT}/${clientId}`);

        if (error) {
            GenUtils.log('fetchClient', { error });
            return { error };
        }

        GenUtils.dispatch(updateClient(data));
        return { data };
    }

    static async updateClientStatus(clientId, status) {
        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.UPDATE_CLIENT}/${clientId}`, {status});

        if (error) {
            GenUtils.log('updateClientStatus', { error });
            return { error };
        }

        await ClientService.fetchClients();
        return { data };
    }

    static async updateClient(uuid, client: IClient) {
        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.UPDATE_CLIENT}/${uuid}`, GenUtils.removeEmptyKeysFromObject(client));

        if (error) {
            GenUtils.log('updateClient', { error });
            return { error };
        }

        await ClientService.fetchClients();
        return { data };
    }

    //////////////////////////////// CONTACTS
    static async addContact(contact: IContact) {
        let _contact = { ...contact };
        if (GenUtils.isEmpty(contact.email)) {
            // Remove the values you don't want
            _contact = _.omit(_contact, ['email']);
        }

        if (GenUtils.isEmpty(contact.mobile)) {
            // Remove the values you don't want
            _contact = _.omit(_contact, ['mobile']);
        }

        const { data, error } = await APIUtil.makePostRequest(`${ApiURLs.ADD_CONTACT}`, _contact);

        if (error) {
            GenUtils.log('addContact', { error });
            return { error };
        }

        // await ClientService.fetchClient(contact.clientId);
        await ClientService.fetchClients();
        return { data };
    }

    static async updateContact(contactId, contact: IContact) {
        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.UPDATE_CONTACT}/${contactId}`, contact);

        if (error) {
            GenUtils.log('updateContact', { error });
            return { error };
        }

        await ClientService.fetchClient(contact.clientId);
        return { data };
    }

    static async deleteContact(clientId, contactId) {
        const { data, error } = await APIUtil.makeDeleteRequest(`${ApiURLs.DELETE_CONTACT}/${contactId}`);

        if (error) {
            GenUtils.log('deleteContact', { error });
            return { error };
        }

        await ClientService.fetchClient(clientId);
        return { data };
    }
}