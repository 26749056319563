import { CaretDownFilled } from '@ant-design/icons';
import GenUtils from '../../utils/GenUtils';

import styles from './AccountItem.scss';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';

interface IAccountItem {
    uuid: string;
    name?: string,
    image?: any,
    memberCount?: number,
    expandable?: boolean,
    collapsed: boolean,
    onClick?: () => void,
}

const AccountItem = ({ uuid, name = null, image = null, memberCount = 0, expandable, collapsed, onClick }: IAccountItem) => {
    const firms = useSelector((state: IStore) => state.firm.firms);

    return (
        <div
            className={styles.container}
            onClick={(e) => {
                e.preventDefault();
                onClick && onClick();
            }}
        >
            <img className={styles.image}
                 src={image || firms[uuid]?.profilePic?.url || require('../../assets/user.png')}
                 alt='profile' />
            {
                !collapsed
                    ? (
                        <>
                            <div className={styles.nameContainer}>
                                <div className={styles.name}>
                                    {GenUtils.capitalizeInitials(name || firms[uuid]?.firmName)}
                                </div>
                                <div className={styles.count}>
                                    {memberCount || firms[uuid]?.membersCount} Members
                                </div>
                            </div>
                            <div className={styles.icon}>
                                {
                                    expandable
                                        ? (
                                            <CaretDownFilled />
                                        ) : null
                                }
                            </div>
                        </>
                    ) : null
            }
        </div>
    );
};

export default AccountItem;
