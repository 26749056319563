import React, { useEffect, useState } from 'react';
import EditableCellsTable from '../../components/table/EditableCellsTable';
import { Button, InputNumber, Modal, Popconfirm, Table } from 'antd';
import { RiCloseFill, RiPencilFill } from 'react-icons/ri';
import AllowanceHistory from './AllowanceHistory';
import { CloseOutlined, DeleteFilled, DownOutlined, PlusCircleOutlined, UpOutlined } from '@ant-design/icons';
import TimeOffService from '../../services/TimeOffService';
import AddTimeAllowance from './AddTimeAllowance';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import { getUserDetails } from '../../redux/selectors/UserSelector';
import { LeavesService } from '../../services/LeavesService';
import styles from './TimeOff.scss';
import FormatUtils from '../../utils/FormatUtils';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import CustomInput from '../../components/input/CustomInput';

const TimeOff = () => {
    const [showHistory, setShowHistory] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [showAddPTO, setShowAddPTO] = useState(false);
    const [currentTeamMember, setCurrentTeamMember] = useState<any>({});
    const [reportees, setReportees] = useState([]);
    const [historyMember, setHistoryMember] = useState('');
    const [historyMemberName, setHistoryMemberName] = useState('');
    const [selectedPTO, setSelectedPTO] = useState(null);
    // const [ptoTypes, setPtoTypes] = useState([]);
    const user = useSelector((state: IStore) => state.user);
    const teamMembers = useSelector((state: IStore) => state.team.membersArray);
    const ptoTypes = useSelector((state: IStore) => state.timeOff.timeOffTypesArray);
    const leaves = useSelector((state: IStore) => state.leaves.leaves);
    const [isToFetch, setIsToFetch] = useState(true);

    useEffect(() => {
        !ptoTypes.length && TimeOffService.fetchPTOTypes();
    }, []);

    useEffect(() => {
        if (user && isToFetch && teamMembers && teamMembers.length) {
            setIsToFetch(false);
            setCurrentTeamMember(teamMembers[teamMembers.findIndex((member) => member.email === user.email)]);
            if (teamMembers[teamMembers.findIndex((member) => member.email === user.email)]?.permission?.name === 'admin') {
                setReportees(teamMembers);
                teamMembers.forEach((member) => LeavesService.fetchLeaves(member.uuid));
            } else {
                //TODO: add reportees logic
            }
            LeavesService.fetchLeaves(teamMembers[teamMembers.findIndex((member) => member.email === user.email)]?.uuid);
        }
    }, [user, teamMembers]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: 150,
            render: (text, data) => (
                <p>
                    {data.firstName + ' ' + data.lastName}
                    <br />
                    <span
                        style={{ color: 'blue', cursor: 'pointer' }}
                        onClick={() => {
                            setHistoryMember(data.uuid);
                            setShowHistory(true);
                            setHistoryMemberName(data.firstName + ' ' + data.lastName);
                        }}>
                        (Allowance history)
                    </span>
                </p>
            )
        },
        {
            title: 'Date of Joining',
            dataIndex: 'createdAt',
            key: 'createdAt',
            fixed: 'left',
            width: 120,
            render: (text) => FormatUtils.getFormattedDateFromTimestamp(text)
        },
        ...(ptoTypes.length
            ? ptoTypes.map((type) => ({
                  title: (
                      <p>
                          {GenUtils.capitalizeInitials(type.name)}
                          <br /> {type.type !== 'fixed' ? <p style={{ fontWeight: 400, color: 'gray' }}>Accrues {type.type}</p> : null}
                      </p>
                  ),
                  dataIndex: type.name,
                  key: type.name,
                  className: styles.column,
                  align: 'center',
                  width: 160,
                  render: (text, data) =>
                      leaves.balance ? (
                          <div style={{ display: 'flex', padding: 5, justifyContent: 'center' }}>
                              <CustomInput
                                  value={
                                      leaves.balance[data?.uuid]
                                          ? leaves.balance[data?.uuid][leaves.balance[data?.uuid].findIndex((lv) => lv?.type?.name === type?.name)]?.count
                                          : null
                                  }
                                  onChange={async (e) => {
                                      if (leaves.balance[data?.uuid]) {
                                          const { data: res, error } = await LeavesService.updateLeave(
                                              leaves.balance[data?.uuid][leaves.balance[data?.uuid].findIndex((lv) => lv?.type?.name === type.name)]?.uuid,
                                              {
                                                  teamMemberId: data?.uuid,
                                                  count: Number(e)
                                              }
                                          );
                                          if (error) {
                                              const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
                                              GenUtils.showNotification(
                                                  'Failed to Update leave',
                                                  error,
                                                  <notificationIcon.icon style={notificationIcon.style} />
                                              );
                                              return;
                                          }
                                          const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
                                          GenUtils.showNotification(
                                              'Successfully Updated Leave',
                                              null,
                                              <notificationIcon.icon style={notificationIcon.style} />
                                          );
                                      } else {
                                          const { data: res, error } = await LeavesService.addLeave({
                                              typeId: type?.uuid,
                                              teamMemberId: data?.uuid,
                                              count: Number(e),
                                              rate: 0
                                          });
                                          if (error) {
                                              const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
                                              GenUtils.showNotification('Failed to Add Leave', error, <notificationIcon.icon style={notificationIcon.style} />);
                                              return;
                                          }
                                          const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
                                          GenUtils.showNotification('Successfully Added Leave', null, <notificationIcon.icon style={notificationIcon.style} />);
                                      }
                                  }}
                                  style={{ width: '40%', margin: 0 }}
                                  updateOnBlur
                              />
                              {type.type !== 'fixed' ? (
                                  <>
                                      +
                                      <CustomInput
                                          value={
                                              leaves.balance[data?.uuid]
                                                  ? leaves.balance[data?.uuid][leaves.balance[data?.uuid].findIndex((lv) => lv?.type?.name === type?.name)]
                                                        ?.rate
                                                  : null
                                          }
                                          onChange={async (e) => {
                                              if (leaves.balance[data?.uuid]) {
                                                  const { data: res, error } = await LeavesService.updateLeave(
                                                      leaves.balance[data?.uuid][leaves.balance[data?.uuid].findIndex((lv) => lv?.type?.name === type?.name)]
                                                          ?.uuid,
                                                      {
                                                          teamMemberId: data?.uuid,
                                                          rate: Number(e)
                                                      }
                                                  );
                                                  if (error) {
                                                      const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
                                                      GenUtils.showNotification(
                                                          'Failed to Update leave',
                                                          error,
                                                          <notificationIcon.icon style={notificationIcon.style} />
                                                      );
                                                      return;
                                                  }
                                                  const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
                                                  GenUtils.showNotification(
                                                      'Successfully Updated Leave',
                                                      null,
                                                      <notificationIcon.icon style={notificationIcon.style} />
                                                  );
                                              } else {
                                                  const { data: res, error } = await LeavesService.addLeave({
                                                      typeId: type?.uuid,
                                                      teamMemberId: data?.uuid,
                                                      rate: Number(e),
                                                      count: 0
                                                  });
                                                  if (error) {
                                                      const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
                                                      GenUtils.showNotification(
                                                          'Failed to Add Leave',
                                                          error,
                                                          <notificationIcon.icon style={notificationIcon.style} />
                                                      );
                                                      return;
                                                  }
                                                  const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
                                                  GenUtils.showNotification(
                                                      'Successfully Added Leave',
                                                      null,
                                                      <notificationIcon.icon style={notificationIcon.style} />
                                                  );
                                              }
                                          }}
                                          style={{ width: '40%', margin: 0 }}
                                          updateOnBlur
                                      />
                                  </>
                              ) : null}
                          </div>
                      ) : null
              }))
            : [])
    ];

    const ptoColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => GenUtils.capitalizeFirstLetter(text)
        },
        {
            title: 'Accrued',
            dataIndex: 'type',
            key: 'type',
            render: (text) => (text ? GenUtils.capitalizeFirstLetter(text) : '(not accrued)')
        },
        {
            title: 'Minimum Hours to accrue',
            dataIndex: 'minCount',
            key: 'minCount'
        },
        // {
        //     title: 'Can Exceed',
        //     dataIndex: 'canExceed',
        //     key: 'canExceed',
        //     render: (text) => (text ? 'Yes' : 'No')
        // },
        {
            title: '',
            render: (text, data) => (
                <>
                    <a
                        onClick={() => {
                            setSelectedPTO(
                                ptoTypes[
                                    ptoTypes.findIndex((pto) => {
                                        return pto.uuid === data.uuid;
                                    })
                                ]
                            );
                            setShowAddPTO(true);
                        }}>
                        Edit
                    </a>
                    <Popconfirm
                        title="Are you sure? You want to delete this PTO Type."
                        onConfirm={async () => {
                            const { data: res, error } = await TimeOffService.deletePTOType(data.uuid);
                            if (error) {
                                const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
                                GenUtils.showNotification('Failed to Delete PTO Type', error, <notificationIcon.icon style={notificationIcon.style} />);
                                return;
                            }
                            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
                            GenUtils.showNotification('Successfully Deleted PTO Type', null, <notificationIcon.icon style={notificationIcon.style} />);
                        }}
                        okText="Yes"
                        cancelText="No">
                        <CloseOutlined style={{ color: 'IndianRed', marginLeft: 20 }} />
                    </Popconfirm>
                </>
            ),
            key: 'delete'
        }
    ];
    return (
        <div>
            <Button type="primary" onClick={() => setShowAddPTO(true)}
                    icon={<PlusCircleOutlined style={{ marginRight: 10 }} />}>
                Add PTO Type{' '}
            </Button>
            <Table
                className="hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius"
                style={{ marginTop: 10, marginBottom: 20 }}
                columns={ptoColumns}
                dataSource={ptoTypes}
                pagination={false}
            />

            {!showTable ? (
                <Button
                    type="link"
                    className="hp-text-color-primary-1 hp-hover-text-color-primary-3"
                    icon={<DownOutlined />}
                    onClick={() => {
                        setShowTable(true);
                    }}>
                    &nbsp;&nbsp;Set Employee Allowance
                </Button>
            ) : (
                <>
                    <Button
                        type="link"
                        className="hp-text-color-primary-1 hp-hover-text-color-primary-3"
                        icon={<UpOutlined />}
                        onClick={() => {
                            setShowTable(false);
                        }}>
                        &nbsp;&nbsp;Hide Employee Allowance
                    </Button>
                    <EditableCellsTable columns={columns} dataSource={teamMembers} />
                </>
            )}
            <Modal
                title={`${historyMemberName}'s - Allowance History`}
                width={800}
                centered
                style={{ margin: '20px' }}
                visible={showHistory}
                onCancel={() => {
                    setHistoryMember('');
                    setShowHistory(false);
                    setHistoryMemberName('');
                }}
                footer={null}
                closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}>
                <AllowanceHistory
                    onClose={() => {
                        setHistoryMember('');
                        setShowHistory(false);
                        setHistoryMemberName('');
                    }}
                    data1={leaves && historyMember ? leaves?.record[historyMember] : []}
                />
            </Modal>
            <Modal
                title={`Add Time Off Allowance`}
                width={600}
                centered
                style={{ margin: '20px' }}
                visible={showAddPTO}
                onCancel={() => {
                    setSelectedPTO(null);
                    setShowAddPTO(false);
                }}
                footer={null}
                closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}>
                <AddTimeAllowance
                    onClose={() => {
                        setSelectedPTO(null);
                        setShowAddPTO(false);
                    }}
                    ptoType={selectedPTO}
                />
            </Modal>
        </div>
    );
};

export default TimeOff;
