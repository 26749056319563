import { combineReducers } from 'redux';
import authReducer from './AuthReducer';
import firmReducer from './FirmReducer';
import loaderReducer from './LoaderReducer';
import userReducer from './UserReducer';
import clientReducer from './ClientReducer';
import teamReducer from './TeamReducer';
import projectReducer from './ProjectReducer';
import phaseReducer from './PhaseReducer';
import permissionReducer from './PermissionReducer';
import consultantReducer from './ConsultantReducer';
import phaseMemberReducer from './MemberReducer';
import communicationReducer from './CommunicationReducer';
import optionReducer from './OptionReducer';
import activityReducer from './ActivityReducer';
import configReducer from './ConfigReducer';
import timesheetReducer from './TimeSheetReducer';
import expenseReducer from './ExpenseReducer';
import timeOffReducer from './TimeOffTypeReducer';
import leavesReducer from './LeavesReducer';
import invoiceReducer from './InvoiceReducer';
import taxReducer from './TaxReducer';
import paymentReducer from './PaymentReducer';
import taskReducer from './TaskReducer';
import milestoneReducer from './MilestoneReducer';

const Reducers = combineReducers({
    auth: authReducer,
    loader: loaderReducer,
    firm: firmReducer,
    user: userReducer,
    client: clientReducer,
    team: teamReducer,
    project: projectReducer,
    phases: phaseReducer,
    permission: permissionReducer,
    consultants: consultantReducer,
    members: phaseMemberReducer,
    communication: communicationReducer,
    options: optionReducer,
    activity: activityReducer,
    config: configReducer,
    timeLogs: timesheetReducer,
    expenses: expenseReducer,
    timeOff: timeOffReducer,
    leaves: leavesReducer,
    taxes: taxReducer,
    invoices: invoiceReducer,
    payments: paymentReducer,
    tasks: taskReducer,
    milestones: milestoneReducer,
});

export default Reducers;
