import enMessages from "../languages/locales/en_US.json";

import antdEN from 'antd/lib/locale-provider/en_US';

export const theme = 'light';

export const direction = 'ltr';

export const enLocaleConfig = {
    messages: {
        ...enMessages
    },
    antd: antdEN,
    locale: 'en-US',
};
