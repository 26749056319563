import { useSelector } from "react-redux";
import { ECommunicationCategory, ECommunicationType } from "../../redux/reducers/CommunicationReducer";
import { IStore } from "../../redux/store";
import { communicationSelector } from "../../redux/selectors/CommunicationSelector";
import { useEffect, useState } from "react";
import CommunicationService from "../../services/CommunicationService";
import GenUtils, { ENotificationType } from "../../utils/GenUtils";
import { getUserDetails } from "../../redux/selectors/UserSelector";
import moment from "moment";

import styles from './CommunicationCardMessage.scss';
import CustomInput from "../input/CustomInput";
import Buttons from "../../view/components/general/buttons";
import Button from "../button/Button";
import FormatUtils, { EDateTimeFormat } from "../../utils/FormatUtils";
import TeamAvatar from "../avatar/TeamAvatar";
import { Popconfirm, Popover } from "antd";

const CommunicationCardMessage = ({ type, uuid }: { type: ECommunicationType, uuid: string }) => {
    const { communicationsArray } = useSelector((state: IStore) => communicationSelector(state, type, uuid));
    const user = useSelector((state: IStore) => getUserDetails(state));

    const [message, setMessage] = useState('');
    const [editing, setEditing] = useState(null);

    useEffect(() => {
        fetchCommunications();
    }, [uuid]);

    const fetchCommunications = async () => {
        const { data, error } = await CommunicationService.fetchCommunications(type, uuid);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('An error occurred', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }
    };

    const deleteCommunications = async (messageId) => {
        const { error } = await CommunicationService.deleteCommunications(messageId, type, uuid);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('An error occurred', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }
    };

    const updateCommunication = async (messageId, message) => {
        setEditing(null);
        const { error } = await CommunicationService.updateCommunications({note: message, type, taskId: uuid}, messageId);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('An error occurred', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }
    };

    const sendMessage = async () => {
        setMessage('');
        const { error } = await CommunicationService.createCommunication(ECommunicationCategory.message, type, message, moment().toISOString(), '', user.uuid, uuid);
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Mesasge Failed', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.title}>Comments ({communicationsArray.length})</div>
            <div className={styles.inputs}>
                <CustomInput value={message} onChange={setMessage} type="text" style={{ height: '50px' }} />
                <Button text='Add comment' type='default' onClick={sendMessage} />
            </div>
            {   
                communicationsArray
                    .sort(GenUtils.sortByCreatedAt)
                    .reverse()
                    .map(m => (
                        <div className={styles.messageContainer}>
                            <div className={styles.sender}>
                                <div className={styles.image}>
                                    <TeamAvatar image={m.teamMember?.profilePicUrl} />
                                </div>
                                <div className={styles.name}>{GenUtils.capitalizeInitials(m.teamMember?.firstName + ' ' + m.teamMember?.lastName)}</div>
                                <div className={styles.time}>{FormatUtils.getFormattedDateFromTimestamp(m.createdAt) + ' ' + FormatUtils.getDateTimeInFormat(m.createdAt, EDateTimeFormat.HH_MM_a)}</div>
                                <div className={styles.actions} onClick={() => setEditing(m.uuid)}>Edit</div>
                                <div className={styles.actions}>
                                    <Popconfirm
                                        title='Are you sure to delete this message?'
                                        onConfirm={() => deleteCommunications(m.uuid)}
                                        okText='Yes'
                                        cancelText='No'
                                    >
                                        Delete
                                    </Popconfirm>    
                                </div>
                            </div>
                            <div className={styles.message}>
                                {
                                    m.uuid === editing 
                                        ? <CustomInput type='text' value={m.note} updateOnBlur onChange={(v => updateCommunication(m.uuid, v))}/>
                                        : `${m.note}`
                                }
                            </div>
                        </div>
                    ))
            }
        </div>
    );
}

export default CommunicationCardMessage;