export default {
    primary: '#0210ed',
    secondary: '#0210ed77',
    background: '#F0F3F5',
    black: '#000000',
    grayDark: '#555555',
    gray: '#999999',
    grayLight: '#A6AEB0',
    grayLight2: '#c9c9c9',
    grayLighter: '#e0e6e9',
    grayLightest: '#f7fafc',
    white: '#ffffff',
    violet: '#ee82ee',
    indigo: '#4B0082',
    blueDark: '#000088',
    blue: '#0000ff',
    blueLight: '#00BFFF',
    greenLime: '#3CB371',
    greenDark: '#006400',
    green: '#32CD32',
    greenYellow: '#ADFF2F',
    yellow: '#FFFF00',
    orange: '#FFA500',
    orangeRed: '#FF4500',
    red: '#ff0000',
    pinkDark: '#e7487f',
    transparent: '#00000000',
    navBackground: '#061B33',
    navIconsSelected: '#2B3D52',
}
