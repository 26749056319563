export interface ILoaderState {
    isVisible: boolean;
    calledFrom: string;
    text?: string;
    type?: string;
    isBlocking?: boolean;
}

export const initialLoaderState: ILoaderState = {
    isVisible: false,
    calledFrom: '',
    text: '',
    type: 'SPINNER',
    isBlocking: false,
}

const loaderReducer = (state: ILoaderState, action: {type: string, payload: ILoaderState}) => {
    switch (action.type) {
        case 'SHOW_LOADER':
            return {
                ...state,
                ...action.payload,
            };
        case 'HIDE_LOADER':
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state || initialLoaderState;
    }
}

export default loaderReducer;