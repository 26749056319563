export interface IActivityState {
    activityLog: IActivity[];
}

export const initialActivityState: IActivityState = {
    activityLog: []
};

export interface IActivity {
    uuid?: string;
}

const activityReducer = (state: IActivityState, action: { type: string, payload }) => {
    switch (action.type) {
        case 'SET_LOGS':
            return {
                ...state,
                activityLog: action.payload,
            };
        default:
            return state || initialActivityState;
    }
};

export default activityReducer;