import { APIUtil } from '../utils/ApiUtils';
import ApiURLs from '../constants/ApiURLs';
import GenUtils from '../utils/GenUtils';
import { setCurrentFirm, setFirms } from '../redux/actions/FirmActions';
import StorageUtils from '../utils/StorageUtils';

export default class FirmService {
    static async fetchFirms() {
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.FETCH_FIRMS}`);

        if (error) {
            GenUtils.log('fetchFirms', { error });
            return { error };
        }

        GenUtils.dispatch(setFirms(data));
        const firmId = StorageUtils.getFirmId() || data[0].uuid;

        // taking the default firm as the first one
        GenUtils.dispatch(setCurrentFirm(firmId));
        StorageUtils.saveFirmIdToStorage(firmId);

        return { data };
    }
}
