import React from 'react';
import style from './groupedTable/GroupedTable.scss';
import { Card, Col, Row, Table } from 'antd';
import Colors from '../../constants/Colors';

const ExpandableRowTable = ({
    title = '',
    subtitle = '',
    columns,
    data,
    renderExpandedRow = (props) => null,
    rightAction = null,
    expandable = true,
    expandableKey = null
}) => {
    return (
        <Card>
            <Row style={{ margin: -24, backgroundColor: Colors.background }}>
                {(title || subtitle) && (
                    <Col className="hp-mb-16" lg={15} span={20}>
                        {title && <h4>{title}</h4>}
                        {subtitle && <p className="hp-p1-body">{subtitle}</p>}
                    </Col>
                )}

                {rightAction && (
                    <Col lg={9} span={4} className="hp-text-right">
                        {/*<Button*/}
                        {/*    onClick={toggleChecked}*/}
                        {/*    type='text'*/}
                        {/*    icon={<RiCodeSSlashLine className='hp-text-color-black-80' />}*/}
                        {/*/>*/}
                        {rightAction}
                    </Col>
                )}
                <Col span={24}>
                    <Table
                        columns={columns}
                        className={style.table}
                        expandable={
                            expandable && {
                                expandedRowRender: renderExpandedRow,
                                rowExpandable: (record) => (expandableKey ? record[expandableKey]?.length > 0 : true)
                            }
                        }
                        dataSource={data}
                        scroll={{ x: 500 }}
                        pagination={(data.length > 10) ? { defaultPageSize: 10 } : false}

                    />
                </Col>
            </Row>
        </Card>
    );
};

export default ExpandableRowTable;
