import React, { useEffect, useState } from 'react';
import ExpandableRowTable from '../../components/table/ExpandableRowTable';
import Buttons from '../../components/button/Button';
import { RiCloseFill } from 'react-icons/ri';
import { Dropdown, Modal, Popconfirm, Tag } from 'antd';
import AddClient from './AddClient';

import styles from './AllClientsPage.scss';
import EditableCellsTable from '../../components/table/EditableCellsTable';
import FeatureCard from '../../components/card/FeatureCard';
import { CloseOutlined, DollarOutlined, MoreOutlined, PlusCircleOutlined, SisternodeOutlined, UserOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import Colors from '../../constants/Colors';
import HeaderSecondary from '../../components/header/HeaderSecondary';
import ClientService from '../../services/ClientService';
import AddCommunication from '../../components/modals/AddCommunication';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import { ECommunicationType } from '../../redux/reducers/CommunicationReducer';
import Button from '../../components/button/Button';
import IClient from '../../interfaces/IClient';
import FormatUtils from '../../utils/FormatUtils';
import moment from 'moment';
import Client from '../../assets/images/clients.svg';
import PermissionUtils from '../../utils/PermissionUtils';
import NavigationUtils from '../../utils/NavigationUtils';
import { useLocation } from 'react-router-dom';
import { EFilterCategory, EFilterTypes } from '../../components/filterBars/FiltersBarMultiselect';
import GroupedTable from '../../components/table/groupedTable/GroupedTable';

const AllClientsPage = () => {
    const { state: locationState } = useLocation();
    const [newFollowUpFilter, setNewFollowUpFilter] = useState(null);

    const firmId = useSelector((state: IStore) => state?.firm?.uuid);
    const clients = useSelector((state: IStore) => state?.client.clients);

    const [showAddCommunication, setShowAddCommunication] = useState('');

    const [showModal, setModal] = useState(false);

    useEffect(() => {
        fetchClients();
    }, []);

    useEffect(() => {
        setModal(locationState?.openAddClient || false);
    }, [locationState]);
    const fetchClients = async () => {
        const { data, error } = await ClientService.fetchClients();

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('An error occurred', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }
    };

    const toggleClientStatus = async (client) => {
        const { data, error } = await ClientService.updateClientStatus(client.uuid, client.status === 'active' ? 'inactive' : 'active');

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Status Update Failed', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }
    };

    const pendingAmountColumns = [
        {
            title: 'Invoice',
            dataIndex: 'invoice',
            // todo go to this particular invoice on click
            render: (text, data) => <a>Invoice</a>
        },
        {
            title: 'Project',
            dataIndex: 'project'
        },
        ...(PermissionUtils.canViewFinancesOfClient()
            ? [
                {
                    title: 'Invoice Amount',
                    dataIndex: 'invoiceAmount'
                },
                {
                    title: 'Pending Amount',
                    dataIndex: 'pendingAmount'
                }
            ]
            : []),
        {
            title: 'Issue Date',
            dataIndex: 'issueDate',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.issueDate - b.issueDate
        },
        {
            title: 'Due Date',
            dataIndex: 'dueDate'
        }
    ];

    const deleteRow = async (clientId, contactId) => {
        const { error } = await ClientService.deleteContact(clientId, contactId);
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Delete', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Successfully deleted contact', <notificationIcon.icon style={notificationIcon.style} />);
    };

    const getMenuItems = (client) => [
        {
            key: 0,
            label: (
                <span
                    onClick={() => {
                        setShowAddCommunication(client.uuid);
                    }}>
                    Add Client Communication
                </span>
            )
        },
        ...[
            PermissionUtils.canUpdateClientStatus()
                ? {
                    key: 1,
                    label: <span onClick={() => toggleClientStatus(client)}>{client.status === 'active' ? 'Deactivate' : 'Activate'}</span>
                }
                : null
        ]
    ];
    const filter = [
        {
            name: 'Client',
            dataIndex: 'clientId',
            type: EFilterTypes.dropdown,
            category: EFilterCategory.simple,
        },
        {
            name: 'Status',
            dataIndex: 'status',
            type: EFilterTypes.dropdown,
            category: EFilterCategory.simple,
        }
    ]
    const columns = [
        {
            title: 'Client Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, data) => <a onClick={() => NavigationUtils.navigateTo(`/${firmId}/clients/${data.uuid}`)}>{text}</a>
        },
        {
            title: 'Total Projects',
            dataIndex: 'projectsCount',
            key: 'projectsCount',
            render: (text, data) => FormatUtils.getAmountInFormat(text, false, 0, 0),
            sorter: (a, b) => a.projectsCount - b.projectsCount
        },
        // {
        //     title: 'Last Project',
        //     dataIndex: 'lastProjectEndAt',
        //     key: 'lastProjectEndAt',
        //     render: (text, data) => FormatUtils.getDaysDiffText(text),
        //     sorter: (a, b) => moment(a.lastProjectEndAt).isAfter(b.lastProjectEndAt)
        // },
        // {
        //     title: 'Last finance Call',
        //     dataIndex: 'lastFinanceCall',
        //     key: 'lastFinanceCall',
        //     render: (text, data) => FormatUtils.getDaysDiffText(text?.communicationAt),
        //     sorter: (a, b) => moment(a.lastFinanceCall).isAfter(b.lastFinanceCall)
        // },
        {
            title: 'next Follow-up',
            dataIndex: 'followUpAt',
            key: 'followUpAt',
            render: (text, data) => FormatUtils.getDaysDiffText(text?.followUpAt),
            sorter: (a, b) => moment(a.followUpAt).isAfter(b.followUpAt)
        },
        ...(PermissionUtils.canViewFinancesOfClient()
            ? [
                // {
                //     title: 'Retainer Balance',
                //     dataIndex: 'totalRetentionAmount',
                //     key: 'totalRetentionAmount',
                //     render: (text) => FormatUtils.getAmountInFormat(text, true, 0, 0),
                //     sorter: (a, b) => a.totalRetentionAmount - b.totalRetentionAmount
                // },
                {
                    title: 'Total pending Amount',
                    dataIndex: 'totalPendingAmount',
                    key: 'totalPendingAmount',
                    render: (text) => (
                        <span style={{ color: Number(text || 0) > 0 ? Colors.red : Colors.black }}>{FormatUtils.getAmountInFormat(text, true, 0, 0)}</span>
                    ),
                    sorter: (a, b) => a.totalPendingAmount - b.totalRetentionAmount
                }
            ]
            : []),
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => <Tag color={text === 'active' ? Colors.green : Colors.orange}>{GenUtils.capitalizeFirstLetter(text)}</Tag>
        },
        {
            title: '',
            dataIndex: 'menu',
            key: 'menu',
            render: (text, data) => (
                <Dropdown menu={{ items: getMenuItems(data) }} trigger={['click']}>
                    <MoreOutlined />
                </Dropdown>
            )
        }
    ];

    const contactColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text) => GenUtils.capitalizeFirstLetter(text || '-')
        },
        {
            title: 'Designation',
            dataIndex: 'designation',
            render: (text) => GenUtils.capitalizeFirstLetter(text || '-')
        },
        {
            title: 'Email',
            dataIndex: 'email'
        },
        {
            title: 'Contact',
            dataIndex: 'mobile'
        },
        ...(PermissionUtils.canCreateClient()
            ? [
                {
                    title: '',
                    dataIndex: 'X',
                    render: (text, data) => {
                        return (
                            <div>
                                <Popconfirm
                                    title="Are you sure to delete this role?"
                                    onConfirm={() => deleteRow(data.clientId, data.uuid)}
                                    okText="Yes"
                                    cancelText="No">
                                    <CloseOutlined style={{ color: 'IndianRed', marginLeft: 20 }} />
                                </Popconfirm>
                            </div>
                        );
                    }
                }
            ]
            : [])
    ];

    const addClient = () => {
        setModal(true);
    };

    const renderContacts = ({ contacts }) => {
        if (!contacts?.length) {
            return null;
        }

        return (
            <EditableCellsTable
                columns={contactColumns}
                dataSource={contacts.map((contact) => {
                    return { ...contact, key: contact.uuid };
                })}
            />
        );
    };
    const renderCards = (filteredData) => {
        return (
            <div className={styles.cardContainer}>
                <FeatureCard
                    title={filteredData.length}
                    subtitle="Total Clients"
                    Icon={UserOutlined}
                />
                <FeatureCard
                    title={filteredData?.reduce((count: number, p: IClient) => count + p.projectsCount || 0, 0)}
                    subtitle="Total Projects"
                    Icon={SisternodeOutlined}
                />

                {PermissionUtils.canViewFinancesOfClient() ? (
                    <FeatureCard
                        title={FormatUtils.getAmountInFormat(
                            Number(filteredData?.reduce((acc: number, p: IClient) => acc + p.totalPendingAmount || 0, 0)),
                            true
                        )}
                        subtitle="Total pending amount"
                        Icon={DollarOutlined}
                    />
                ) : null}
            </div>
        )
    }
    return (
        <div>
            <Modal
                title="Add Client"
                width={800}
                centered
                visible={showModal}
                onCancel={() => setModal(false)}
                footer={null}
                destroyOnClose={true}
                closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}>
                <AddClient
                    hideModal={() => setModal(false)}
                    onSuccess={() => {
                        setModal(false);
                    }}
                />
            </Modal>
            <Modal
                title="Add Communication"
                width={400}
                centered
                visible={!GenUtils.isStringEmpty(showAddCommunication)}
                onCancel={() => setShowAddCommunication('')}
                footer={null}
                destroyOnClose={true}
                closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}>
                <AddCommunication typeId={showAddCommunication?.toString()} onHide={() => setShowAddCommunication('')} type={ECommunicationType.client} />
            </Modal>
            <div>
                <HeaderSecondary
                    text={'Clients'}
                    subtext={'Manage and foster relationships for business growth.'}
                    renderImage={() => <img src={Client} alt="client" width={70} />}
                    renderAction={() => (PermissionUtils.canCreateProjects() ? <Button onClick={addClient} text={'New Client'} icon={<PlusCircleOutlined style={{ marginRight: "8px" }} />} /> : null)}
                />
            </div>
            <div style={{ paddingLeft: 5 }}>
                <GroupedTable
                    columns={columns}
                    data={clients && Object.values(clients)
                        .map((client: IClient) => {
                            return { ...client, key: client.uuid, clientId: client.uuid };
                        })}
                    filters={filter}
                    renderCards={renderCards}
                    renderTable={(columns, data) => (
                        <ExpandableRowTable
                            columns={columns}
                            data={data}
                            renderExpandedRow={renderContacts}
                            expandableKey="contacts"
                        />
                    )}
                />
            </div>
        </div>
    );
};

export default AllClientsPage;