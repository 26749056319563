import NavigationUtils from '../../utils/NavigationUtils';
import styles from './UnauthorizedPage.scss';
import Button from '../../components/button/Button';
import StorageUtils from '../../utils/StorageUtils';

export default function UnauthorizedPage() {
    const firmId = StorageUtils.getFirmId()

    return (
        <div className={styles.container}>
            <div className={styles.infoBox}>
                <h1>403</h1>
                <h2>Forbidden</h2>
                <p>You don't have access to this page</p>
                <Button
                    type="primary"
                    text="Back to home"
                    onClick={() => 
                        NavigationUtils.navigateTo(`/${firmId}/dashboard`)
                    }/>
            </div>
        </div>
    );
}
