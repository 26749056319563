// import "../.././constants/colors.scss";
import styles from './MemberAllocationSplit.scss';
import React from 'react';
import Tag from '../tag/Tag';
import { Table } from 'antd';
import FormatUtils from '../../utils/FormatUtils';

const MemberAllocationSplit = ({ memberName, date, expectedHours, tableData, data }) => {
    const total = tableData.reduce((val, item) => {
        return val + item.hours;
    }, 0);

    const columns = [
        {
            title: 'Projects',
            dataIndex: 'projectName',
            render: (projectName, project) => (
                <span style={{ display: 'flex', gap: 5, fontSize: 12 }}>
                    <Tag text={project.serialNumber} color={project.color} style={{ flex: 0 }} />
                    {project.type === 'timeOff' ? 'Time Off' : projectName}
                </span>
            )
        },
        {
            title: 'Phase',
            dataIndex: 'color',
            render: (color, project) => (
                <span style={{ display: 'flex', fontSize: 12 }}>
                    <Tag text={project.abbreviation} color={color} />
                </span>
            )
        },
        {
            title: 'Hours',
            dataIndex: 'hours',
            render: (hours) => <span style={{ display: 'flex', fontSize: 12 }}>{hours} Hrs</span>
        }
    ];

    return (
        <div className={styles.container}>
            <div style={{ display: 'flex' }}>
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        fontWeight: 'bold',
                        backgroundColor: '#f7fafc',
                        padding: 15,
                        margin: '-15px 0 0 -15px'
                    }}>
                    {memberName}
                </div>
                <div
                    style={{
                        display: 'flex',
                        backgroundColor: '#f7fafc',
                        padding: 15,
                        margin: '-15px -15px 0 -15px'
                    }}>
                    {date}
                </div>
            </div>
            <div style={{ display: 'flex' }}>
                <div className={styles.leftElement}>Utilization</div>
                <div className={styles.rightElement}>
                    {FormatUtils.getAmountInFormat(expectedHours * total === 0 ? (total === 0 ? 0 : 100) : (total / expectedHours) * 100, false, 0, '0')}%
                </div>
            </div>
            <div style={{ display: 'flex' }}>
                <div className={styles.leftElement}>Total Scheduled</div>
                <div className={styles.rightElement}>
                    {total}hrs/{expectedHours}hrs
                </div>
            </div>
            <Table
                className={styles.table}
                dataSource={tableData.map((value) => ({ ...value, key: value.uuid })) || []}
                columns={columns}
                pagination={false}
                size="small"
            />
        </div>
    );
};

export default MemberAllocationSplit;
