import { IAuthInfo } from '../../interfaces/IUser';

export interface IAuthState {
    isLoggedIn?: boolean;
    signupStep?: number;
    info: IAuthInfo;
}

export const initialAuthState: IAuthState = {
    isLoggedIn: false,
    signupStep: 0,
    info: {},
}

const authReducer = (state: IAuthState, action: {type: string, payload: IAuthState}) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                isLoggedIn: true,
            };
        case 'LOGOUT':
            return {
                ...state,
                isLoggedIn: false,
            };
        case 'SIGNUP':
            return {
                ...state,
                signupStep: action.payload,
            }
        case 'AUTH_DATA':
            return {
                ...state,
                info: action.payload,
            }
        default:
            return state || initialAuthState;
    }
}

export default authReducer;