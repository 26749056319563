import React, { useState } from 'react';
import TeamAvatar from '../../components/avatar/TeamAvatar';
import Tag from '../../components/tag/Tag';
import FormatUtils from '../../utils/FormatUtils';
import moment from 'moment';
import { DatePicker, Table } from 'antd';
import FeatureCard from '../../components/card/FeatureCard';
import styles from './TimeEntryTab.scss';
import { Column, ColumnConfig } from '@ant-design/plots';
import Colors from '../../constants/Colors';
import TimeSheetService from '../../services/TimeSheetService';
import MomentUtils from '../../utils/MomentUtils';
import classNames from 'classnames';
import PermissionUtils from '../../utils/PermissionUtils';
import SwitchTabs from '../../components/tabs/SwitchTabs';
import FiltersBarMultiselect, { EFilterCategory, EFilterTypes } from '../../components/filterBars/FiltersBarMultiselect';
import GroupBar from '../../components/filterBars/GroupBar';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

interface TTimeEntries {
    phasesObj: PhasesObj;
    phasesMembersObj: PhasesMembersObj;
    activitiesObj: ActivitiesObj;
    timesheets: Timesheet[];
    stats: Stats;
}

interface PhasesObj {
    [key: string]: Phase;
}

interface Phase {
    linkedPhaseId: any;
    startAt: string;
    endAt: string;
    days: any;
    feeType: string;
    amount: number;
    projectId: string;
    typeId: string;
    isAllocationActive: boolean;
    userUuid: string;
    uuid: string;
    createdAt: string;
    updatedAt: string;
    type: Type;
    project: Project;
}

interface Type {
    feeType: string;
    abbreviation: string;
    name: string;
    type: string;
    billType: any;
    userUuid: string;
    uuid: string;
    createdAt: string;
    updatedAt: string;
}

interface Project {
    name: string;
    serial: number;
    color: string;
    templateId: any;
    clientId: string;
    type: string;
    projectFee: number;
    consultantFee: number;
    status: string;
    startAt: string;
    endAt: string;
    addressId: any;
    note: any;
    invoiceApproval: boolean;
    userUuid: string;
    uuid: string;
    createdAt: string;
    updatedAt: string;
}

interface PhasesMembersObj {
    [key: string]: PhaseMember;
}

interface PhaseMember {
    teamMemberId: string;
    roleId: string;
    budgetHours: number;
    budgetAmount: number;
    phaseId: string;
    projectId: string;
    userUuid: string;
    uuid: string;
    createdAt: string;
    updatedAt: string;
    role: Role;
    teamMember: TeamMember;
}

interface Role {
    hourRate: number;
    name: string;
    userUuid: string;
    uuid: string;
    createdAt: string;
    updatedAt: string;
}

interface TeamMember {
    email: string;
    firstName: string;
    lastName: string;
    dob: string;
    profilePicUrl: any;
    profilePicFileId: string;
    contact: string;
    permissionId: string;
    status: string;
    office: string;
    userUuid: string;
    userId: number;
    uuid: string;
    createdAt: string;
    updatedAt: string;
}

interface ActivitiesObj {
    [key: string]: Activity;
}

interface Activity {
    billType: string;
    name: string;
    type: string;
    userUuid: string;
    uuid: string;
    createdAt: string;
    updatedAt: string;
}

interface Timesheet {
    projectId: string;
    phaseId: string;
    phaseMemberId: string;
    teamMemberId: string;
    roleId?: string;
    activityId: string;
    date: string;
    startAt?: string;
    endAt?: string;
    note?: string;
    type: string;
    status: string;
    seconds: number;
    amount: number;
    currency: any;
    lockEnable: boolean;
    lockedAt: string;
    rejectionNote?: string;
    lastSubmittedAt?: string;
    userUuid: string;
    uuid: string;
    createdAt: string;
    updatedAt: string;
}

interface Stats {
    billableSecs: number;
    billableAmount: number;
    nonBillableSecs: number;
    nonBillableAmount: number;
    totalSecs: number;
    totalAmount: number;
    months: Months;
}

interface Months {
    [key: string]: Month;
}

interface Month {
    billableSecs: number;
    billableAmount: number;
    nonBillableSecs: number;
    nonBillableAmount: number;
    totalSecs: number;
    totalAmount: number;
}

const getPlotValue = (data: Months, trueKey: string, falseKey: string, i: number, condition: boolean) => {
    if (data && data[i]) {
        if (condition) {
            return (data[i][trueKey] / 3600).toFixed(0);
        }
        return data[i][falseKey];
    }
    return 0;
};
const weekRange = MomentUtils.getWeekDates(new Date());

const TimeEntryTab = () => {
    const [range, setRange] = React.useState({
        start: new Date(),
        end: moment().add(7, 'd').toDate()
    });
    const [plotData, setPlotData] = useState([]);
    const [timeEntries, setTimeEntries] = useState<TTimeEntries>();
    const [isHourView, setIsHourView] = useState(true);
    const [filteredData, setFilteredData] = useState<TTimeEntries['timesheets']>();

    React.useMemo(() => {
        setRange({
            start: weekRange[0],
            end: weekRange[1]
        });
    }, []);

    React.useEffect(() => {
        (async () => {
            const r:
                | {
                      error: string;
                      data?: undefined;
                  }
                | {
                      data: TTimeEntries;
                      error?: undefined;
                  } = await TimeSheetService.fetchTimeEntries([range.start, range.end]);
            setTimeEntries(r.data);
            setFilteredData(r.data?.timesheets);
        })();
    }, [range]);

    React.useMemo(() => {
        if (timeEntries && timeEntries?.stats) {
            let monthsData = [];
            months.forEach((month, i) => {
                monthsData.push({ month, type: 'billable', value: getPlotValue(timeEntries?.stats?.months, 'billableSecs', 'billableAmount', i, isHourView) });
                monthsData.push({
                    month,
                    type: 'non_billable',
                    value: getPlotValue(timeEntries?.stats?.months, 'nonBillableSecs', 'nonBillable', i, isHourView)
                });
            });
            setPlotData(monthsData);
        }
    }, [isHourView, timeEntries]);

    const columns = [
        {
            title: 'Employee',
            dataIndex: 'phaseMemberId',
            width: 200,
            render: (text, data) => (
                <TeamAvatar
                    name={`${timeEntries?.phasesMembersObj[data.phaseMemberId]?.teamMember?.firstName} ${
                        timeEntries?.phasesMembersObj[data.phaseMemberId]?.teamMember?.lastName
                    }`}
                    image={timeEntries?.phasesMembersObj[data.phaseMemberId]?.teamMember?.profilePicUrl}
                />
            ),
            sorter: (a, b) =>
                (
                    timeEntries?.phasesMembersObj[a.phaseMemberId]?.teamMember?.firstName + timeEntries?.phasesMembersObj[a.phaseMemberId]?.teamMember?.lastName
                ).localeCompare(
                    timeEntries?.phasesMembersObj[b.phaseMemberId]?.teamMember?.firstName + timeEntries?.phasesMembersObj[b.phaseMemberId]?.teamMember?.lastName
                )
        },
        {
            title: 'Date',
            dataIndex: 'date',
            width: 150,
            render: (text) => FormatUtils.getFormattedDateFromTimestamp(text),
            sorter: (a, b) => (moment(a.date).isAfter(b.date) ? 1 : -1)
        },
        {
            title: 'Project #',
            dataIndex: 'number',
            width: 150,
            render: (text, data) => (
                <Tag text={timeEntries.phasesObj[data.phaseId]?.project.serial} color={timeEntries.phasesObj[data.phaseId]?.project.color} />
            ),
            sorter: (a, b) => timeEntries.phasesObj[a.phaseId]?.project.serial - timeEntries.phasesObj[b.phaseId]?.project.serial
        },
        {
            title: 'Project Names',
            dataIndex: 'projectName',
            width: 250,
            render: (text, data) => timeEntries.phasesObj[data.phaseId]?.project.name,
            sorter: (a, b) => timeEntries.phasesObj[a.phaseId]?.project.name.localeCompare(timeEntries.phasesObj[b.phaseId]?.project.name)
        },
        {
            title: 'Phase',
            dataIndex: 'phaseName',
            width: 100,
            render: (text, data) => (
                <Tag
                    text={timeEntries?.phasesObj[data.phaseId]?.type?.abbreviation.toUpperCase()}
                    color={timeEntries?.phasesObj[data.phaseId]?.project?.color}
                />
            ),
            sorter: (a, b) => timeEntries?.phasesObj[a.phaseId]?.type?.abbreviation.localeCompare(timeEntries?.phasesObj[b.phaseId]?.type?.abbreviation)
        },
        {
            title: 'Role',
            dataIndex: 'role',
            width: 150,
            render: (text, data) => timeEntries?.phasesMembersObj[data.phaseMemberId]?.role?.name,
            sorter: (a, b) =>
                timeEntries?.phasesMembersObj[a.phaseMemberId]?.role?.name.localeCompare(timeEntries?.phasesMembersObj[b.phaseMemberId]?.role?.name)
        },
        {
            title: 'Activity',
            dataIndex: 'activityId',
            width: 150,
            render: (text, data) => timeEntries?.activitiesObj[data.activityId]?.name,
            sorter: (a, b) => timeEntries?.activitiesObj[a.activityId]?.name.localeCompare(timeEntries?.activitiesObj[b.activityId]?.name)
        },
        {
            title: 'Note',
            dataIndex: 'note',
            width: 250,
            render: (text) => <div style={{ textOverflow: 'ellipsis' }}>{text}</div>,
            sorter: (a, b) => a.note.localeCompare(b.note)
        },
        {
            title: 'Rate',
            dataIndex: 'rate',
            width: 200,
            render: (text, data) => FormatUtils.getAmountInFormat(timeEntries?.phasesMembersObj[data.phaseMemberId]?.role?.hourRate, true, 2, '0'),
            sorter: (a, b) => timeEntries?.phasesMembersObj[a.phaseMemberId]?.role?.hourRate - timeEntries?.phasesMembersObj[b.phaseMemberId]?.role?.hourRate
        },
        {
            title: 'Hours',
            dataIndex: 'seconds',
            width: 100,
            render: (text) => FormatUtils.getAmountInFormat(text / 3600, false, 0, '0'),
            sorter: (a, b) => a.seconds - b.seconds
        },
        ...(PermissionUtils.canViewFinancesInProjects()
            ? [
                  {
                      title: 'Amount',
                      dataIndex: 'amount',
                      width: 200,
                      render: (text) => FormatUtils.getAmountInFormat(text, true, 2, '0'),
                      sorter: (a, b) => a.amount - b.amount
                  }
              ]
            : [])
    ];

    const filters = [
        {
            name: 'Status',
            dataIndex: 'status',
            type: EFilterTypes.dropdown,
            category: EFilterCategory.advance
        },
        {
            name: 'Project',
            dataIndex: 'projectId',
            type: EFilterTypes.dropdown,
            category: EFilterCategory.simple
        },
        {
            name: 'Phase',
            dataIndex: 'phaseId',
            type: EFilterTypes.dropdown,
            category: EFilterCategory.advance
        },
        {
            name: 'Member',
            dataIndex: 'teamMemberId',
            type: EFilterTypes.dropdown,
            category: EFilterCategory.advance
        },
        {
            name: 'Activity',
            dataIndex: 'activityId',
            type: EFilterTypes.dropdown,
            category: EFilterCategory.advance
        }
    ];

    const config = {
        data: plotData,
        isStack: true,
        xField: 'month',
        yField: 'value',
        seriesField: 'type',
        theme: {
            colors10: [Colors.primary, Colors.secondary],
            stylesheet: {
                background: 'white',
                color: Colors.black
            }
        },
        maxColumnWidth: 30,
        reversed: true,
        tooltip: {
            formatter: (datum) => {
                return { name: datum.type, value: isHourView ? `${datum.value} Hrs` : FormatUtils.getAmountInFormat(parseInt(datum.value), true) };
            }
        },
        label: {
            style: {
                fontSize: 26
            },
            layout: [
                {
                    type: 'interval-adjust-position'
                },
                {
                    type: 'interval-hide-overlap'
                },
                {
                    type: 'adjust-color'
                }
            ],
            formatter: (value) => value.name
        },
        legend: {
            position: 'bottom'
        },
        columnStyle: {
            lineDash: [5, 5],
            lineWidth: 2,
            strokeOpacity: 100,
            cursor: 'pointer'
        }
    } as ColumnConfig;

    const handleTabChange = (val) => {
        if (!PermissionUtils.canViewFinancesInProjects()) {
            return;
        }
        setIsHourView(val);
        let newData = [];
        months.forEach((month, i) => {
            newData.push({ month, type: 'billable', value: getPlotValue(timeEntries?.stats?.months, 'billableSecs', 'billableAmount', i, val) });
            newData.push({
                month,
                type: 'non_billable',
                value: getPlotValue(timeEntries?.stats?.months, 'nonBillableSecs', 'nonBillableAmount', i, val)
            });
        });
        setPlotData(newData);
    };

    return (
        <div className={styles.container}>
            <div style={{ display: 'flex', gap: 10 }}>
                <DatePicker.RangePicker
                    onChange={(values) => {
                        if (values && values.length === 2) {
                            setRange({
                                start: values[0].toDate(),
                                end: values[1].toDate()
                            });
                        }
                    }}
                    style={{marginBottom: 30}}
                />
                <FiltersBarMultiselect
                    filters={filters}
                    data={timeEntries?.timesheets}
                    colummns={columns}
                    setFilteredData={(d: TTimeEntries['timesheets']) => setFilteredData(d)}
                    renderRightOptions={() => <GroupBar groups={[]} />}
                />
            </div>
            <div className={styles.cardContainer}>
                {PermissionUtils.canViewFinancesInProjects() && (
                    <FeatureCard title={FormatUtils.getAmountInFormat(timeEntries?.stats?.billableAmount, true, 2)} subtitle="Billable Fee" />
                )}
                <FeatureCard title={FormatUtils.getAmountInFormat(timeEntries?.stats?.billableSecs / 3600, false, 1)} subtitle="Billable Hours" />
                <FeatureCard title={FormatUtils.getAmountInFormat(timeEntries?.stats?.nonBillableSecs / 3600, false, 1)} subtitle="Non-Billable Hours" />
                <FeatureCard title={FormatUtils.getAmountInFormat(timeEntries?.stats?.totalSecs / 3600, false, 1)} subtitle="Total Hours" />
                <FeatureCard
                    title={FormatUtils.getAmountInFormat((timeEntries?.stats?.billableSecs * 100) / timeEntries?.stats?.totalSecs, false, 2) + '%'}
                    subtitle="Billable %"
                />
            </div>
            {timeEntries?.timesheets && (
                <>
                    <div className={styles.tabContainer}>
                        {PermissionUtils.canViewFinancesInProjects() && <SwitchTabs a="Hours" b={'Amount'} onChange={handleTabChange} initialValue={'Hours'} />}
                    </div>
                    <Column {...config} />
                    <Table
                        className={classNames("hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius",styles.submissionTab)}
                        columns={columns}
                        dataSource={filteredData || []}
                        size="middle"
                        pagination={false}
                    />
                </>
            )}
        </div>
    );
};

export default TimeEntryTab;
