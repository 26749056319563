import IUser from '../../interfaces/IUser';

export const initialUserState: IUser = {
    language: 'English',
    weekStart: 'Monday',
    yearStart: 'January',
    timeFormat: '12',
    timeZone: '',
    dateFormat: 'dd/mm/yyyy',
    measurementUnit: 'meter',
    currency: 'INR',
    currencyFormat: 'symbol_after',
    amountFormat: 'indian',
}

const userReducer = (state: IUser, action: {type: string, payload: IUser}) => {
    switch (action.type) {
        case 'SET_USER':
            return {
                ...action.payload,
            };
        case 'UPDATE_USER':
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state || initialUserState;
    }
}

export default userReducer;
