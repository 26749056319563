import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import styles from './ProjectWeekTimeSheet.scss';
import GenUtils from '../../utils/GenUtils';
import AuditTimeSheetProjectItem from './AuditTimeSheetProjectItem';
import TimeSheetService, { TAudits } from '../../services/TimeSheetService';
import WeekIterator from '../../components/time/WeekIterator';
import MomentUtils from '../../utils/MomentUtils';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import { useAuditItems } from '../../hooks/timesheet/AuditTimeSheet';
import FiltersBarMultiselect, { EFilterCategory, EFilterTypes } from '../../components/filterBars/FiltersBarMultiselect';
import GroupBar from '../../components/filterBars/GroupBar';

const fetchAudits = async (setAudits: React.Dispatch<TAudits>, weekRange: Date[]) => {
    const { data: d } = await TimeSheetService.fetchTimesheetAudits(weekRange[0]);
    let data = d as TAudits;
    setAudits(data);
};
const weekCount = 7;

const filters = [
    {
        name: 'Status',
        dataIndex: 'status',
        type: EFilterTypes.dropdown,
        category: EFilterCategory.simple
    },
    {
        name: 'Project',
        dataIndex: 'projectId',
        type: EFilterTypes.dropdown,
        category: EFilterCategory.advance
    },
    {
        name: 'Phase',
        dataIndex: 'phase',
        type: EFilterTypes.dropdown,
        category: EFilterCategory.advance
    },
    {
        name: 'Member',
        dataIndex: 'teamMemberId',
        type: EFilterTypes.dropdown,
        category: EFilterCategory.advance
    },
    {
        name: 'Activity',
        dataIndex: 'activityId',
        type: EFilterTypes.dropdown,
        category: EFilterCategory.advance
    }
];

const AuditTimeSheetTab = () => {
    const projects = useSelector((state: IStore) => state.project.projects);
    const [audits, setAudits] = useState<TAudits>();
    const [weekRange, setWeekRange] = useState(MomentUtils.getWeekDates(new Date()));

    useEffect(() => {
        fetchAudits(setAudits, weekRange);
    }, [weekRange]);

    const { items } = useAuditItems(audits, weekCount, weekRange[0]);
    const [filtered, setFiltered] = React.useState([]);
    const [flattened, setFlattened] = React.useState([])

    React.useMemo(() => {
        setFlattened(flattenItems(items))
    }, [items])

    return (
        <>
            <WeekIterator weekRange={weekRange} setWeekRange={setWeekRange} showCalenderType={false} />
            <FiltersBarMultiselect
                filters={filters}
                data={flattened}
                colummns={[]}
                setFilteredData={(d: typeof flattened) => setFiltered(flatToItems(d))}
                renderRightOptions={() => <GroupBar groups={[]} />}
            />
            <Row className={styles.rowHeader}>
                <Col span={3}>Team Member</Col>
                <Col span={2}>Phase</Col>
                <Col span={3}>Role</Col>
                <Col span={2}>Activity</Col>
                <Col span={10}>
                    <Row justify="space-between">
                        {[...Array(weekCount)].map((e, i) => (
                            <Col className={styles.weekDays} span={Math.floor(18 / weekCount)} key={i}>
                                {GenUtils.getWeekDaySmallString(moment(GenUtils.getDifferenceDate(weekRange[0], i - 1)).day())}
                                <br />
                                <span
                                    style={{
                                        fontWeight: '400',
                                        fontSize: 12
                                    }}>
                                    {GenUtils.getDateAndMonth(GenUtils.getDifferenceDate(weekRange[0], i))}
                                </span>
                            </Col>
                        ))}
                    </Row>
                </Col>
                <Col span={4}>Total</Col>
            </Row>
            {filtered.length > 0 && audits ? (
                filtered.map((i) => (
                    <AuditTimeSheetProjectItem
                        color={audits.projectsObj[i.projectId]?.color}
                        serial={audits.projectsObj[i.projectId]?.serial}
                        name={audits.projectsObj[i.projectId]?.name}
                        weekCount={weekCount}
                        weekStart={weekRange[0]}
                        fetchAudits={() => fetchAudits(setAudits, weekRange)}
                        roles={projects[audits.projectsObj[i.projectId]?.uuid]?.roles || []}
                        rows={i.data}
                    />
                ))
            ) : (
                <div style={{ display: 'flex', padding: '20px', justifyContent: 'center' }}>Nothing pending for this week</div>
            )}
        </>
    );
};

const flattenItems = (items: ReturnType<typeof useAuditItems>['items']) => {
    const data = new Array<(typeof items)[number]['data'][number] & { projectId: string }>();
    items.forEach((i) => {
        i.data.forEach((t) => data.push({ ...t, projectId: i.projectId }));
    });
    return data;
};

const flatToItems = (flattened: ReturnType<typeof flattenItems>) => {
    const data: ReturnType<typeof useAuditItems>['items'] = [];
    flattened.forEach((f) => {
        const { projectId, ...rest } = f;
        const find = data.find((i) => i.projectId === projectId);
        if (find) {
            find.data.push({ ...rest });
        } else {
            data.push({ projectId, data: [rest] });
        }
    });
    return data;
};

export default AuditTimeSheetTab;
