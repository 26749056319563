import React from 'react';
import { DatePicker, Input, Select } from 'antd';
import styles from './FiltersBar.scss';
import { RiCalendarLine } from 'react-icons/ri';

const FiltersBar = ({ filters, renderRightOptions = () => null, selectedFilters }) => {
    return (
        <div className={styles.container}>
            <div className={styles.left}>
                {filters.map((filter) => {
                    switch (filter.type) {
                        case 'text':
                            return (
                                <Input
                                    className={styles.dropdown}
                                    onChange={(e) => filter.onChange(e.target.value)}
                                    placeholder={filter.name}
                                    key={filter.name}
                                />
                            );
                        case 'number':
                            return (
                                <Input
                                    type='number'
                                    className={styles.dropdown}
                                    onChange={(e) => filter.onChange(e.target.value)}
                                    placeholder={filter.name}
                                    key={filter.name}
                                />
                            );
                        case 'date':
                            return (
                                <DatePicker
                                    className={styles.dropdown}
                                    onChange={filter.onChange}
                                    suffixIcon={
                                        <RiCalendarLine className='remix-icon hp-text-color-black-60' />
                                    }
                                    key={filter.name}
                                />
                            );
                        default:
                            return (
                                <Select
                                    showSearch
                                    className={styles.dropdown}
                                    onChange={filter.onSelect}
                                    key={filter.name}
                                    placeholder={filter.name}
                                >
                                    {
                                        filter.options.map(
                                            option => <Select.Option key={option.uuid} value={option.uuid}>
                                                {option.name}
                                            </Select.Option>
                                        )
                                    }
                                </Select>
                            );
                    }

                })}
            </div>
            <div className={styles.right}>
                {renderRightOptions && renderRightOptions()}
            </div>
        </div>
    );
};

export default FiltersBar;