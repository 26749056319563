import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

// components
import Expense from '../../assets/images/expenses.svg';
import HeaderSecondary from '../../components/header/HeaderSecondary';
import Tabs from '../../components/tabs/Tabs';
import { CarryOutOutlined, CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import NewExpense from './NewExpense';

// utils
import NavigationUtils from '../../utils/NavigationUtils';
import Colors from '../../constants/Colors';

// redux
import { IStore } from '../../redux/store';

// styles
import styles from './ExpensesPage.scss';
import Button from '../../components/button/Button';
import PermissionUtils from '../../utils/PermissionUtils';

const ExpensesPage = () => {
    const { pathname, state: locationState } = useLocation();
    const [activeRoute, setActiveRoute] = useState('project');
    const [showCreateExpense, setShowCreateExpense] = useState(false);
    const firmId = useSelector((state: IStore) => state?.firm?.uuid);

    useEffect(() => {
        setShowCreateExpense(locationState?.openCreateModal || false);
    }, [locationState]);

    useEffect(() => {
        const splitLocation = pathname.split('/');
        const _activeRoute = splitLocation[splitLocation.indexOf('expenses') + 1] || 'project';
        if (activeRoute !== _activeRoute) setActiveRoute(_activeRoute);
    }, [pathname]);

    return (
        <div>
            <HeaderSecondary
                text={'Expenses'}
                subtext={'Keep track of time spent daily or weekly on project activities.'}
                renderImage={() => <img src={Expense} alt='expence' width={70} />}
                renderAction={
                    () =>
                        PermissionUtils.canSeeAllExpenses()
                        ? <Button
                            text={`New Expense`}
                            onClick={() => setShowCreateExpense(true)}
                            icon={<PlusCircleOutlined style={{marginRight:"8px"}}/>}
                        />
                        : null
                }
            />
            <div className={styles.container}>
                <Tabs
                    items={[
                        {
                            key: 'project',
                            name: 'Project Expense',
                            icon: <CarryOutOutlined />
                        }
                        // {
                        //     key: 'firm',
                        //     name: 'Firm Expense',
                        //     icon: <SnippetsOutlined />
                        // }
                    ]}
                    onTabClick={(key) => {
                        NavigationUtils.navigateTo(`/${firmId}/expenses/${key}`);
                    }}
                    activeTab={activeRoute}
                />
                <Outlet />
            </div>
            <Modal
                title={`Add ${activeRoute === 'project' ? 'Project' : 'Firm'} Expense`}
                width={700}
                centered
                visible={showCreateExpense}
                onCancel={() => setShowCreateExpense(false)}
                footer={null}
                closeIcon={<CloseOutlined style={{ color: Colors.red }} size={24} />}
                destroyOnClose={true}
            >
                <NewExpense onHide={() => setShowCreateExpense(false)} expenseType={activeRoute}/>
            </Modal>
        </div>
    );
};

export default ExpensesPage;
