import GenUtils from '../../utils/GenUtils';
import { ICommunication } from '../reducers/CommunicationReducer';

export const setCommunications = (type, typeId, communications: ICommunication[]) => {
    return {
        type: 'SET_COMMUNICATION',
        payload: {
            type,
            typeId,
            communications: GenUtils.arrayToIdMap(communications),
            communicationsArray: communications,
        }
    }
}
export const setNewCommunications = (type, communications: ICommunication[]) => {
    return {
        type: 'SET_COMMUNICATION',
        payload: {
            type,
            communications: GenUtils.arrayToIdMap(communications),
            communicationsArray: communications,
        }
    }
}