import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { Avatar, Badge, Col, Menu } from 'antd';
import menuImg from '../../assets/images/pages/profile/menu-img.svg';
import avatar from '../../assets/images/memoji/memoji-1.png';
import { theme } from '../../constants/Config';
import { IStore } from '../../redux/store';
import { getUserDetails } from '../../redux/selectors/UserSelector';
import GenUtils from '../../utils/GenUtils';
import NavigationUtils from '../../utils/NavigationUtils';

const menuItems = [
    {
        label: 'Firm Information',
        route: 'firm-info'
    }, {
        label: 'Roles & Billable Rates',
        route: 'roles'
    },
    {
        label: 'Permissions',
        route: 'permissions'
    },
    {
        label: 'Invoices',
        route: 'invoices'
    },
    {
        label: 'TimeSheets',
        route: 'time-sheets'
    },
    {
        label: 'Projects',
        route: 'projects'
    },
    {
        label: 'Expenses',
        route: 'expenses'
    },
    {
        label: 'Integrations',
        route: 'integrations'
    }
];

const MenuProfile = (props) => {
    const menuIconClass = 'remix-icon hp-mr-8';
    const firm = useSelector((state: IStore) => state?.firm?.firm);
    const user = useSelector((state: IStore) => getUserDetails(state));

    function menuFooterItem() {
        if (props.footer !== 'none') {
            return (
                <div className='hp-profile-menu-footer'>
                    <img src={menuImg} alt='Profile Image' />
                </div>
            );
        }
    }

    function moreBtn() {
        if (props.moreBtnCheck !== 'none') {
            return (
                <Col className='hp-menu-header-btn hp-pr-16 hp-mb-12 hp-text-right'>
                    {props.moreBtn()}
                </Col>
            );
        }
    }

    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split('/');

    const renderMenuItem = (item) => {
        const route = splitLocation[splitLocation.length - 1];
        const isSelected = route === item.route || (route === 'firm-settings' && item.route === 'firm-info');

        return (
            <Menu.Item
                key={item.label}
                // icon={<User set="curved" className={menuIconClass} />}
                className={`
              hp-mb-16 hp-pl-24 hp-pr-32
              ${isSelected
                        ? 'ant-menu-item-selected'
                        : 'ant-menu-item-selected-in-active'}
            `}
                onClick={() => {
                    NavigationUtils.navigateTo(`/${firm.uuid}/firm-settings/${item.route}`);
                    props.onCloseDrawer();
                }}
            >
                <div>
                    {item.label}
                </div>
            </Menu.Item>
        );
    };

    return (
        <Col flex='240px' className='hp-profile-menu hp-py-24'>
            <div className='hp-w-100'>
                <div className='hp-profile-menu-header hp-mt-md-16 hp-text-center'>
                    {moreBtn()}

                    <Badge>
                        <Avatar size={100} src={firm.profilePicUrl || avatar} style={{overflow: 'hidden'}} />
                    </Badge>

                    <h3 className='hp-mt-24 hp-mb-4'>{user.firmName}</h3>
                    <a href='mailto:dolores@yoda.com' className='hp-p1-body'>
                        {user.email}
                    </a>
                </div>

                <Menu

                    mode='inline'
                    className='hp-w-100 hp-profile-menu-body'
                    theme={theme}
                >
                    {
                        menuItems.map(renderMenuItem)
                    }
                </Menu>
            </div>

            {menuFooterItem()}
        </Col>
    );
};

export default MenuProfile;
