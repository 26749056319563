import { useEffect, useState } from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import Button from '../button/Button';
import { RiCalendarLine } from 'react-icons/ri';
import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import TeamMemberSelector from '../selector/TeamMemberSelector';

import { ECommunicationType } from '../../redux/reducers/CommunicationReducer';

import CommunicationService from '../../services/CommunicationService';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';

const AddCommunication = ({ typeId, onHide, type = ECommunicationType.other, by = null, selectedCategory = null, editing = null }) => {
    const [date, setDate] = useState(moment());
    const [notes, setNotes] = useState('');
    const [category, setCategory] = useState();
    const [nextFollowup, setNextFollowup] = useState(null);
    const [selectedMemberId, setSelectedMemberId] = useState(null);

    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split('/');
    const currentTab = splitLocation[2];

    const createCommunication = async ( ) => {
        const source = currentTab;
        // todo adjust this for other types of the communications
        const clientOrProjectId = typeId;

        const {
            data,
            error
        } = await CommunicationService.createCommunication(category, type, notes, date.toISOString(), nextFollowup?.toISOString() || '', by || selectedMemberId, clientOrProjectId);
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Add Communication', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Added Communication successfully', <notificationIcon.icon
            style={notificationIcon.style} />);
        onHide();
        setDate(moment());
        // setTime(moment());
        setNotes('');
        setCategory(null);
        setNextFollowup(null);
        setSelectedMemberId(null);
    };

    useEffect(()=>{
            if (editing){
                setDate(date);
                // setTime(moment());
                setNotes(notes);
                setCategory(category);
                setNextFollowup(editing.nextFollowup);
                setSelectedMemberId(editing.uuid)
            }
        }
        ,[editing])

    return (
        <Form layout='vertical' name='basic' initialValues={{ remember: true }} style={{ marginTop: '10px' }}>
            {
                !by && (
                <Form.Item label='Communication Done By' name='member'>
                    <TeamMemberSelector
                        selectedMembers={selectedMemberId}
                        setSelectedMembers={(member) => {
                            setSelectedMemberId(member.uuid);
                        }}
                    />
                </Form.Item>
                )
            }
            <Form.Item label='Date' name='date'>
                <DatePicker
                    className='hp-w-100'
                    value={date}
                    onChange={setDate}
                    showTime={{ format: 'HH:mm' }}
                    suffixIcon={
                        <RiCalendarLine className='remix-icon hp-text-color-black-60' />
                    }
                />
            </Form.Item>
            {/*<Form.Item label='Time' name='date'>*/}
            {/*    <TimePicker*/}
            {/*        className='hp-w-100'*/}
            {/*        value={time}*/}
            {/*        onChange={(value) => setTime(value)}*/}
            {/*        suffixIcon={*/}
            {/*            <RiTimeLine className='remix-icon hp-text-color-black-60' />*/}
            {/*        }*/}
            {/*    />*/}
            {/*</Form.Item>*/}
            {
                !selectedCategory && (
                    <Form.Item label='Category' name='category'>
                        <Select
                            placeholder='Select Category'
                            className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                            style={{ width: 300 }}
                            onChange={setCategory}
                            value={category}
                        >
                            <option value='email'>Email</option>
                            <option value='call'>Call</option>
                            <option value='message'>Message</option>
                        </Select>
                    </Form.Item>
                )
            }
            {
                type !== ECommunicationType.task && (
                    <Form.Item label='Next Follow-up' name='date'>
                        <DatePicker
                            className='hp-w-100'
                            value={nextFollowup}
                            onChange={setNextFollowup}
                            showTime
                            suffixIcon={
                                <RiCalendarLine className='remix-icon hp-text-color-black-60' />
                            }
                        />
                    </Form.Item>
                )
            }
            <Form.Item label='Notes' name='displayName'>
                <Input
                    value={notes}
                    onChange={(e) => {
                        setNotes(e.target.value);
                    }}
                />
            </Form.Item>
            <Row style={{ gap: '20px' }}>
                <Col md={11} span={24} className='hp-pr-sm-0 hp-pr-12'>
                    <Button
                        block
                        type='primary'
                        onClick={createCommunication}
                        text='Add'
                    />
                </Col>

                <Col md={11} span={24} className='hp-mt-sm-12 hp-pl-sm-0 hp-pl-12'>
                    <Button block type='ghost' onClick={onHide} text='Cancel' />
                </Col>
            </Row>
        </Form>
    );
};

export default AddCommunication;
