import {produce} from "immer"

interface initialTimesheetData {
    phase: {};
    project: {};
    phaseMember: {};
    timesheet: {};
    teamMember: {};
}
interface Project {
    name: string;
    serial: number;
    color: string;
    templateId: string | null;
    clientId: string;
    type: string;
    projectFee: number;
    consultantFee: number;
    status: string;
    startAt: string;
    endAt: string;
    addressId: string | null;
    note: string | null;
    invoiceApproval: boolean;
    userUuid: string;
    uuid: string;
    createdAt: string;
    updatedAt: string;
}

interface PhaseType {
    feeType: string;
    abbreviation: string;
    name: string;
    type: string;
    billType: string | null;
    userUuid: string;
    uuid: string;
    createdAt: string;
    updatedAt: string;
}

interface PhaseMember {
    teamMemberId: string;
    roleId: string;
    budgetHours: number;
    budgetAmount: number;
    phaseId: string;
    projectId: string;
    userUuid: string;
    uuid: string;
    createdAt: string;
    updatedAt: string;
    role: {
        hourRate: number;
        uuid: string;
        createdAt: string;
        updatedAt: string;
    };
}

interface Phase {
    linkedPhaseId: string | null;
    startAt: string;
    endAt: string;
    days: number | null;
    feeType: string;
    amount: number;
    projectId: string;
    typeId: string;
    isAllocationActive: boolean;
    userUuid: string;
    uuid: string;
    createdAt: string;
    updatedAt: string;
    type: PhaseType;
    members: string[];
    loggedSecs: number;
    loggedAmount: number;
    budgetedSecs: number;
    budgetedAmount: number;
}
interface PhaseActivity {
    name: string;
    type: string;
    billType: string;
    userUuid: string;
    uuid: string;
    createdAt: string;
    updatedAt: string;
}
interface ProjectsData {
    projectsObj: Record<string, Project>;
    phasesObj: Record<string, Phase>;
    phasesMembersObj: Record<string, PhaseMember>;
    phaseActivitiesObj: Record<string, PhaseActivity[]>;
}
export interface TimesheetData {
    startDate: string;
    endDate: string;
    isLocked: boolean;
    status: string;
    projects: {
        [key: string]: Phases;
    };
}

export interface TimesheetEntry {
    projectId: string;
    phaseId: string;
    phaseMemberId: string;
    teamMemberId: string;
    roleId: string;
    activityId: string;
    date: string;
    startAt: string | null;
    endAt: string | null;
    note: string | null;
    type: string;
    status: string;
    seconds: number;
    amount: number;
    currency: string | null;
    lockEnable: boolean;
    lockedAt: string | null;
    rejectionNote: string | null;
    lastSubmittedAt: string | null;
    userUuid: string;
    uuid: string;
    createdAt: string;
    updatedAt: string;
}

export interface ITimesheetState {
    timesheet: TimesheetData;
    allocation: initialTimesheetData;
    timeEntries: any;
    submissions: any;
    audits: any;
    projectsData: ProjectsData;
}

interface Phases {
    [phaseId: string]: {
        [activityId: string]: {
            [date: string]: TimesheetEntry[];
        };
    };
}

const initialTimesheetState: ITimesheetState = {
    timesheet: {
        startDate: '',
        endDate: '',
        isLocked: false,
        status: '',
        projects: {}
    },
    allocation: {
        phase: undefined,
        project: undefined,
        phaseMember: undefined,
        timesheet: undefined,
        teamMember: undefined
    },
    timeEntries: {},
    submissions: [],
    audits: {},
    projectsData: {
        projectsObj: {},
        phasesObj: {},
        phasesMembersObj: {},
        phaseActivitiesObj: {}
    }
};

const timesheetReducer = (state: ITimesheetState, action: { type: string; payload }): ITimesheetState => {
    switch (action.type) {
        case 'SET_ALLOCATION':
            return {
                ...state,
                allocation: {
                    ...state.allocation,
                    project: {
                        ...state.allocation.project,
                        ...action.payload.project
                    },
                    phaseMember: {
                        ...state.allocation.phaseMember,
                        ...action.payload.phaseMember
                    },
                    timesheet: {
                        ...state.allocation.timesheet,
                        ...action.payload.timesheet
                    },
                    teamMember: {
                        ...state.allocation.teamMember,
                        ...action.payload.teamMember
                    },
                    phase: {
                        ...state.allocation.phase,
                        ...action.payload.phase
                    }
                }
            };
        case 'SET_TIMESHEET':
           return  produce(state, draft => {
                draft.timesheet = action.payload
                return draft
            })  
        case 'SET_PROJECTS_DATA':
            return {
                ...state,
                projectsData: {
                    ...state.projectsData,
                    ...action.payload
                }
            };
        case 'UPDATE_PHASE':
            // todo
            return {
                ...state
            };
        case 'SET_TIMESHEET_ENTRIES':
            return { ...state, timeEntries: action.payload };
        case 'SET_TIMESHEET_SUBMISSIONS':
            return { ...state, submissions: action.payload };
        case 'SET_TIMESHEET_AUDITS':
            return { ...state, audits: action.payload };
        default:
            return state || initialTimesheetState;
    }
};

export default timesheetReducer;
