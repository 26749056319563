import React, { useEffect, useState } from 'react';

import { Button, Col, DatePicker, Divider, Form, Input, Modal, Row, Select } from 'antd';

import { RiCalendarLine, RiCloseFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import { getUserDetails } from '../../redux/selectors/UserSelector';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import UserService from '../../services/UserService';
import classnames from 'classnames';
import styles from '../client/AddClient.scss';
import { Countries, DateFormatOptions, Languages, TimeZones } from '../../constants/Options';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import TeamService from '../../services/TeamService';
import FormatUtils from '../../utils/FormatUtils';
import VerifiedEmailTag from '../../components/tag/VerifiedEmailTag';

const { Option } = Select;

const PersonalInfo = () => {
    const [contactModalVisible, setContactModalVisible] = useState(false);
    const [preferenceModalVisible, setPreferenceModalVisible] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [contact, setContact] = useState('');
    const [dob, setDob] = useState(null);

    const [building, setBuilding] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [zipCode, setZipCode] = useState('');

    const [language, setLanguage] = useState('');
    const [timeZone, setTimeZone] = useState('');
    const [dateFormat, setDateFormat] = useState('');

    const { pathname } = useLocation();
    const user = useSelector((state: IStore) => getUserDetails(state, selectedUserId));

    useEffect(() => {
        const pathSplit = pathname.split('/');
        const selectedId = pathSplit[pathSplit.indexOf('team') + 1];
        selectedId && setSelectedUserId(selectedId);
    }, [pathname]);

    useEffect(() => {
        if (user.firstName !== firstName) setFirstName(user.firstName);
        if (user.lastName !== lastName) setLastName(user.lastName);
        if (user.displayName !== displayName) setDisplayName(user.displayName);
        if (user.contact !== contact) setContact(user.contact);
        if (moment(user.dob).isValid() && user.dob !== dob) setDob(user.dob);
        if (user.language !== language) setLanguage(user.language);
        if (user.timeZone !== timeZone) setTimeZone(user.timeZone);
        if (user.dateFormat !== dateFormat) setDateFormat(user.dateFormat);
        if (user.address?.building && user.address?.building !== building) setBuilding(user.address?.building);
        if (user.address?.street && user.address?.street !== street) setStreet(user.address?.street);
        if (user.address?.city && user.address?.city !== city) setCity(user.address?.city);
        if (user.address?.state && user.address?.state !== state) setState(user.address?.state);
        if (user.address?.zipCode && user.address?.zipCode !== zipCode) setZipCode(user.address?.zipCode);
        if (user.address?.country && user.address?.country !== country) setCountry(user.address?.country);
    }, [user.uuid]);

    const updateUser = async () => {
        const { data, error } = await TeamService.updateUserDetails(user.memberUuid || user.uuid,
            {
                firstName,
                lastName,
                // displayName,
                contact: contact,
                dob,
                address: {
                    building,
                    city,
                    country,
                    state,
                    street,
                    zipCode
                }
            }
        );

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Update', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Information Updated Successfully', <notificationIcon.icon
            style={notificationIcon.style} />);
        contactModalCancel();
        preferenceModalCancel();
    };

    const listTitle = 'hp-p1-body';
    const listResult = 'hp-mt-sm-4 hp-p1-body hp-text-color-black-100 hp-text-color-dark-0';
    const dividerClass = 'hp-border-color-black-40 hp-border-color-dark-80';

    const contactModalShow = () => {
        setContactModalVisible(true);
    };

    const contactModalCancel = () => {
        setContactModalVisible(false);
    };

    const preferenceModalShow = () => {
        setPreferenceModalVisible(true);
    };

    const preferenceModalCancel = () => {
        setPreferenceModalVisible(false);
    };

    const sendInvitationEmail = (user) =>{
        // todo send invitation email here
    }

    return (
        <div>
            <Modal
                title='Edit Contact'
                width={600}
                centered
                visible={contactModalVisible}
                onCancel={contactModalCancel}
                footer={null}
                closeIcon={
                    <RiCloseFill className='remix-icon text-color-black-100' size={24} />
                }
            >
                <Form layout='vertical' name='basic' initialValues={{ remember: true }}>
                    <Form.Item label='First Name' name='firstName'>
                        <Input
                            value={firstName}
                            onChange={(e) => {
                                setFirstName(e.target.value);
                            }}
                        />
                    </Form.Item>
                    <Form.Item label='Last Name' name='LastName'>
                        <Input
                            value={lastName}
                            onChange={(e) => {
                                setLastName(e.target.value);
                            }}
                        />
                    </Form.Item>
                    {/*<Form.Item label='Display Name' name='displayName'>*/}
                    {/*    <Input*/}
                    {/*        value={displayName}*/}
                    {/*        onChange={(e) => {*/}
                    {/*            setDisplayName(e.target.value);*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*</Form.Item>*/}
                    <Form.Item label='Mobile Number' name='contact'>
                        <Input
                            value={'ascsdc'}
                            onChange={(e) => {
                                setContact(e.target.value);
                            }}
                        />
                    </Form.Item>
                    <Form.Item label='Date of Birth' name='dob'>
                        <DatePicker
                            className='hp-w-100'
                            value={moment(dob)}
                            onChange={value => {
                                setDob(value.toISOString());
                            }}
                            suffixIcon={
                                <RiCalendarLine className='remix-icon hp-text-color-black-60' />
                            }
                        />
                    </Form.Item>

                    <Form.Item
                        className={classnames(styles.marginBottomN10)}
                        label='Address'>
                        <div className={styles.row}>
                            <Input
                                placeholder='Street, avenue, etc.'
                                type={'street'}
                                onChange={(e) => {
                                    setStreet(e.target.value);
                                }}
                            />
                            <Input
                                placeholder='Suite, Unit, Apt, etc'
                                type={'building'}
                                onChange={(e) => {
                                    setBuilding(e.target.value);
                                }}
                            />
                        </div>
                        <div className={styles.row}>
                            <Input
                                placeholder='City'
                                type={'city'}
                                onChange={(e) => {
                                    setCity(e.target.value);
                                }}
                            />
                            <Input
                                placeholder='State/Province/Region'
                                type={'state'}
                                onChange={(e) => {
                                    setState(e.target.value);
                                }}
                            />
                            <Input
                                placeholder='Zip/Postal Code'
                                type={'zipCode'}
                                onChange={(e) => {
                                    setZipCode(e.target.value);
                                }}
                            />
                        </div>
                        <div className={styles.row}>
                            <Form.Item
                                rules={[{ required: true }]}>
                                <Select
                                    showSearch
                                    placeholder='Country'
                                    style={{ width: 300 }}
                                    onChange={(value) => {
                                        setCountry(value);
                                    }}
                                >
                                    {
                                        Countries.map((_country) => <Option key={_country}
                                                                            value={_country}>{_country}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                    </Form.Item>

                    <Row style={{ gap: '20px' }}>
                        <Col md={11} span={24} className='hp-pr-sm-0 hp-pr-12'>
                            <Button
                                block
                                type='primary'
                                htmlType='submit'
                                onClick={updateUser}
                            >
                                Edit
                            </Button>
                        </Col>

                        <Col md={11} span={24} className='hp-mt-sm-12 hp-pl-sm-0 hp-pl-12'>
                            <Button block onClick={contactModalCancel}>
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal
                title='Edit Preference'
                width={500}
                centered
                visible={preferenceModalVisible}
                onCancel={preferenceModalCancel}
                footer={null}
                closeIcon={
                    <RiCloseFill className='remix-icon text-color-black-100' size={24} />
                }
            >
                <Form layout='vertical' name='basic' initialValues={{ remember: true }}>
                    <Col span={24} className='hp-profile-content-list hp-mt-sm-8 hp-mt-24'>
                        <ul>
                            <li>
                                <span className={listTitle}>Language</span>
                                <Select
                                    placeholder='English'
                                    className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                                    style={{ width: 300 }}
                                    value={language}
                                    onChange={setLanguage}
                                >
                                    {
                                        Languages.map((language) =>
                                            <Option key={language.code} value={language.code}>{language.name}</Option>)
                                    }
                                </Select>
                            </li>
                            <li className='hp-mt-18'>
                                <span className={listTitle}>Date Format</span>
                                <Select
                                    placeholder='MM/DD/YYYY (e.g. 04/25/2022)'
                                    className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                                    style={{ width: 300 }}
                                    value={dateFormat}
                                    onChange={setDateFormat}
                                >
                                    {
                                        DateFormatOptions.map(dateFormat => <option key={dateFormat.key} value={dateFormat.key}>{dateFormat.value}</option>)
                                    }
                                </Select>
                            </li>
                            <li className='hp-mt-18'>
                                <span className={listTitle}>Timezone</span>
                                <Select
                                    placeholder='(GMT-09:00) Alask'
                                    className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                                    style={{ width: 300 }}
                                    value={timeZone}
                                    onChange={setTimeZone}
                                >
                                    {
                                        TimeZones.map((timeZone) =>
                                            <option key={timeZone.value} value={timeZone.value}>{timeZone.name}</option>
                                        )
                                    }
                                </Select>
                            </li>
                        </ul>
                    </Col>

                    <Row style={{marginTop: '20px', justifyContent: 'space-between'}}>
                        <Col md={11} span={24} className='hp-pr-sm-0 hp-pr-12'>
                            <Button
                                block
                                type='primary'
                                htmlType='submit'
                                onClick={updateUser}
                            >
                                Edit
                            </Button>
                        </Col>

                        <Col md={11} span={24} className='hp-mt-sm-12 hp-pl-sm-0 hp-pl-12'>
                            <Button block onClick={preferenceModalCancel}>
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Col md={15} span={24}>
                <h2>Personal Information</h2>
                <p className='hp-p1-body hp-mb-0'>
                    This information will be shared with all firm members.
                </p>
            </Col>

            <Divider className={dividerClass} />

            <Row align='middle' justify='space-between'>
                <Col md={12} span={24}>
                    <h3>Contact</h3>
                </Col>

                <Col md={12} span={24} className='hp-profile-action-btn hp-text-right'>
                    <Button
                        type='primary' ghost
                        onClick={() => {
                            contactModalShow();
                        }}>
                        Edit
                    </Button>
                </Col>

                <Col
                    span={24}
                    className='hp-profile-content-list hp-mt-8 hp-pb-sm-0 hp-pb-120'
                >
                    <ul>
                        <li>
                            <span className={listTitle}>Full Name</span>
                            <span className={listResult}>{`${user.firstName || '-'} ${user.lastName || '-'}`}</span>
                        </li>
                        {/*<li className='hp-mt-18'>*/}
                        {/*    <span className={listTitle}>Display Name</span>*/}
                        {/*    <span className={listResult}>{`${user.firstName || '-'} ${user.lastName || '-'}`}</span>*/}
                        {/*</li>*/}
                        <li className='hp-mt-18'>
                            <span className={listTitle}>Email</span>
                            <span className={listResult} style={{marginRight: '20px'}}>{user.email || '-'}</span>
                            <VerifiedEmailTag user={user} onClick={() => sendInvitationEmail(user)}/>
                        </li>
                        <li className='hp-mt-18'>
                            <span className={listTitle}>Phone</span>
                            <span className={listResult}>{user.contact || '-'}</span>
                        </li>
                        <li className='hp-mt-18'>
                            <span className={listTitle}>Date of Birth</span>
                            <span
                                className={listResult}>{FormatUtils.getFormattedDateFromTimestamp(user.dob) || '-'}</span>
                        </li>
                        <li className='hp-mt-18'>
                            <span className={listTitle}>Address</span>
                            <span className={listResult}>{
                                user.address ?
                                    <>{user.address.building} {user.address.street}, {user.address.city}, <br />{user.address.state} -
                                        ({user.address.zipCode})<br />{user.address.country}</>
                                    : '-'
                            }
                            </span>
                        </li>
                    </ul>
                </Col>
            </Row>
            <Divider className={dividerClass} />

            <Row align='middle' justify='space-between'>
                <Col md={12} span={24}>
                    <h3>Preference</h3>
                </Col>

                {/*<Col md={12} span={24} className='hp-profile-action-btn hp-text-right'>*/}
                {/*    <Button type='primary' ghost onClick={preferenceModalShow}>*/}
                {/*        Edit*/}
                {/*    </Button>*/}
                {/*</Col>*/}

                <Col span={24} className='hp-profile-content-list hp-mt-sm-8 hp-mt-24'>
                    <ul>
                        <li>
                            <span className={listTitle}>Language</span>
                            <span className={listResult}>{user.language || 'English'}</span>
                        </li>
                        <li className='hp-mt-18'>
                            <span className={listTitle}>Date Format</span>
                            <span className={listResult}>{user.dateFormat || '-'}</span>
                        </li>
                        <li className='hp-mt-18'>
                            <span className={listTitle}>Timezone</span>
                            <span className={listResult}>{user.timeZone || '-'}</span>
                        </li>
                    </ul>
                </Col>
            </Row>
        </div>
    );
};

export default PersonalInfo;
