import ApiURLs from '../constants/ApiURLs';
import { ICreateTimesheet } from '../interfaces/ITimesheet';
import { setTimeAllocation } from '../redux/actions/TimesheetActions';
import { APIUtil } from '../utils/ApiUtils';
import GenUtils from '../utils/GenUtils';

export default class AllocationService {
    static async fetchAllocationData() {
        // todo fetch the data and set in redux /timesheet/allocation
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.GET_ALLOCATION}`);
        if (error) {
            GenUtils.log('Get Allocation', error);
            return { error };
        }
        GenUtils.dispatch(setTimeAllocation(data));
        return { data };
    }

    static async createAllocation(timesheets) {
        const { data, error } = await APIUtil.makePostRequest(`${ApiURLs.ADD_ALLOCATION}`, timesheets);
        if (error) {
            GenUtils.log('Create Allocation', error);
            return { error };
        }
        this.fetchAllocationData();
        return { data };
    }
    static async updateAllocation(timesheets) {
        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.ADD_ALLOCATION}`, timesheets);
        if (error) {
            GenUtils.log('Create Allocation', error);
            return { error };
        }
        this.fetchAllocationData();
        return { data };
    }
}
