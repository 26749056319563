import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../../redux/store';
import TimeSheetService from '../../../services/TimeSheetService';
import AddRowModal from './AddRowModal';
import _ from 'lodash';
import HeaderRow from './HeaderRow';
import AddRow from './AddRow';
import ProjectDayTimesheetGrid from './ProjectDayTimesheetGrid';
import moment from 'moment';
import MembersService from '../../../services/MembersService';
import GenUtils, { ENotificationType } from '../../../utils/GenUtils';
import { getTimeRowsForDate } from './helper';

const ProjectDayTimesheet = ({ date, user, weekRange }) => {
    const [selectedTeamMemberId, setSelectedTeamMember] = useState('');
    const [showAddRowModal, setShowAddRowModal] = useState(false);
    const [timeRows, setTimeRows] = useState([]);
    const timeLogs = useSelector((state: IStore) => state.timeLogs);
    const { timesheet: userTimesheet, projectsData } = timeLogs;
    const [runningTimerIndex, setRunningTimerIndex] = useState();
    const { projectsObj, phasesObj, phaseActivitiesObj } = projectsData;

   useEffect(() => {
        user && setSelectedTeamMember(user.uuid);
        let utcDate = moment.utc(weekRange[0]).toISOString();
        user && TimeSheetService.fetchTimeSheetDataForMember(utcDate, user.uuid);
    }, [user, weekRange]); 

    useEffect(() => {
        let newTimeRows = getTimeRowsForDate(timeLogs, date, projectsObj, phasesObj, phaseActivitiesObj);
        setTimeRows(newTimeRows);
    }, [timeLogs, date]);

    const updateTimeSheet = (name, index, value) => {
        let timesheetId = timeRows[index].uuid;
        if (name === 'startAt') setRunningTimerIndex(timesheetId);
        TimeSheetService.updateTimesheet(selectedTeamMemberId, { timesheets: [{ timesheetId, [name]: value }] }, date);
    };
    const addRow = async (row, startTime) => {
        let phaseMemberId = phasesObj[row.phaseId].members[0]
        let data;
        data = { ...data, activityId: row.activity, phaseMemberId, date, seconds: 0, startAt: startTime };
        if (row.role === '') {
            const { data: addMemberResponse, error } = await MembersService.addMember({
                teamMemberId: selectedTeamMemberId,
                hourRate: 0,
                budgetHours: 0,
                phaseId: row.phase
            });
            if (error) {
                const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
                GenUtils.showNotification('Failed to Add Member', error, <notificationIcon.icon style={notificationIcon.style} />);
                return;
            }
            data = { ...data, phaseMemberId: addMemberResponse.uuid };
        }
        if (runningTimerIndex)
            await TimeSheetService.updateTimesheet( selectedTeamMemberId, { timesheets: [{ timesheetId: runningTimerIndex, endAt: new Date() }] }, date);
        const { data: resp } = await TimeSheetService.postTimelogData(date.toISOString(), selectedTeamMemberId, { timesheets: [data] });
        if (resp && resp.length) {
            setRunningTimerIndex(resp[0].uuid);
        }
    };
    const deleteRow = async (uuid) => {
        // setTimeRows([...timeRows.slice(0, index), ...timeRows.slice(index + 1)]);
        const { error } = await TimeSheetService.deleteTimesheet([uuid], selectedTeamMemberId);
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Delete Timesheet', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }
        let utcDate = moment.utc(date).toISOString();
        TimeSheetService.fetchTimeSheetDataForMember(utcDate, selectedTeamMemberId);
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Successfully Deleted Timesheet', null, <notificationIcon.icon style={notificationIcon.style} />);
    };

    return (
        <div>
            <HeaderRow />
            <ProjectDayTimesheetGrid
                timeRows={timeRows}
                updateTimeSheet={updateTimeSheet}
                addRow={addRow}
                deleteRow={deleteRow}
                runningTimerIndex={runningTimerIndex}
            />

            <AddRow setShowAddRowModal={setShowAddRowModal} userTimesheet={userTimesheet} selectedTeamMemberId={selectedTeamMemberId} timeRows={timeRows} />
            <AddRowModal
                showAddRowModal={showAddRowModal}
                setShowAddRowModal={setShowAddRowModal}
                selectedTeamMemberId={selectedTeamMemberId}
                addRow={addRow}
            />
        </div>
    );
};

export default ProjectDayTimesheet;
