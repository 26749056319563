import { DatePicker, Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import Button from '../../components/button/Button';

import styles from './CreateProject.scss';
import { IStore } from '../../redux/store';
import { useSelector } from 'react-redux';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import moment from 'moment';
import { RiCalendarLine } from 'react-icons/ri';
import TaskService from '../../services/TaskService';
import TeamMemberSelector from '../../components/selector/TeamMemberSelector';
import MomentUtils from '../../utils/MomentUtils';

const { Option } = Select;

const CreateTask = ({ hideModal, onSuccess, task = null }) => {
    const [taskTitle, setTaskTitle] = useState('');
    const [description, setDescription] = useState('');
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedPhase, setSelectedPhase] = useState(null);
    const [taskType, setTaskType] = useState('');
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [dueDate, setDueDate] = useState(moment());

    const project = useSelector((state: IStore) => state.project.projectsArray);
    const phase = useSelector((state: IStore) => state.phases.phasesArrayForProject[selectedProject] || []);
    const taskTypes = useSelector((state: IStore) => state.options.task);

    useEffect(() => {
        if (task) {
            setTaskTitle(task.name);
            setSelectedProject(task.projectId);
            setSelectedPhase(task.phaseId);
            setTaskType(task.typeId);
            task?.assignees && setSelectedMembers(task?.assignees);
            setDescription(task.description);
            setDueDate(moment(task.date));
        }
    }, [task]);

    const createTask = async () => {
        let error;
        if  (task) {
            error = (await TaskService.updateTask(task.uuid, {
                name: taskTitle,
                // projectId: selectedProject,
                // phaseId: selectedPhase,
                typeId: taskType,
                assignees: selectedMembers.map(m => m.uuid),
                description,
                date: MomentUtils.getGmtDayStart(dueDate),
            })).error;
        } else {
            error = (await TaskService.createTask({
                name: taskTitle,
                projectId: selectedProject,
                phaseId: selectedPhase,
                typeId: taskType,
                assignees: selectedMembers.map(m => m.uuid),
                description,
                date: MomentUtils.getGmtDayStart(dueDate),
            })).error;
        }

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }

        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Task saved Successfully', <notificationIcon.icon
            style={notificationIcon.style} />);
        hideModal();

        setTaskTitle('');
        setSelectedProject(null);
        setSelectedPhase(null);
        setTaskType(null);
        setSelectedMembers([]);
        setDescription('');
        setDueDate(moment());
    };

    return (
        <div className={styles.container}>
            <form>
                <div className={styles.column}>
                    <Form.Item label='Task Title' className={styles.margin0}>
                        {' '}
                    </Form.Item>
                    <Input
                        placeholder='Task Title'
                        type={'text'}
                        value={taskTitle}
                        onChange={(e) => {
                            setTaskTitle(e.target.value);
                        }} />
                </div>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <Form.Item label='Description' className={styles.margin0}>
                            {' '}
                        </Form.Item>
                        <Input
                            placeholder='Description'
                            type={'text'}
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <Form.Item label='Task Type' className={styles.margin0}>
                            {' '}
                        </Form.Item>
                        <Select
                            placeholder='Select Type'
                            value={taskType}
                            onChange={(e) => {
                                setTaskType(e);
                            }}
                        >
                            {taskTypes.map((phase) => (
                                <Option key={phase.uuid} value={phase.uuid}>
                                    {GenUtils.capitalizeFirstLetter(phase.name)}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <Form.Item label='Project' className={styles.margin0}>
                            {' '}
                        </Form.Item>
                        <Select
                            value={selectedProject}
                            placeholder={'Select project'}
                            onChange={(e) => {
                                setSelectedProject(e);
                            }}>
                            {project.map((project) => (
                                <Option key={project?.uuid} value={project?.uuid}>
                                    {GenUtils.capitalizeFirstLetter(project?.name)}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div className={styles.column}>
                        <Form.Item label='Phase' className={styles.margin0}>
                            {' '}
                        </Form.Item>
                        <Select
                            value={selectedPhase}
                            placeholder={'Select Phase'}
                            onChange={(e) => {
                                setSelectedPhase(e);
                            }}>
                            {phase.map((phase) => (
                                <Option key={phase.uuid} value={phase.uuid}>
                                    {GenUtils.capitalizeInitials(phase.type.name)}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className={styles.column}>
                    <Form.Item label='Due Date' className={styles.margin0}>
                        {' '}
                    </Form.Item>
                    <DatePicker
                        placeholder={''}
                        className='hp-w-100'
                        value={dueDate}
                        onChange={e => {
                            setDueDate(e);
                        }}
                        suffixIcon={
                            <RiCalendarLine className='remix-icon hp-text-color-black-60' />
                        }
                    />
                </div>
                <div className={styles.column}>
                    <Form.Item label='Assignees' className={styles.margin0}>
                        {' '}
                    </Form.Item>
                    <TeamMemberSelector
                        selectedMembers={selectedMembers}
                        setSelectedMembers={(m) => {
                            setSelectedMembers(m);
                        }}
                        isMultiple
                        showSelected
                    />
                </div>
            </form>
            <div className={classnames(styles.row, styles.center)}>
                <Button type={'ghost'} text='Cancel' onClick={hideModal} />
                <Button
                    text={task ? 'Edit' : 'Save'}
                    onClick={createTask}
                />
            </div>
        </div>
    );
};
export default CreateTask;
