import Tag from '../../components/tag/Tag';
import { AlignType } from 'rc-table/lib/interface';
import { EDateTimeFormat } from '../../utils/FormatUtils';
import { Modal, Table, Popconfirm, Dropdown } from 'antd';
import { BankFilled, MailFilled, PhoneFilled, MoreOutlined } from '@ant-design/icons';
import { IStore } from '../../redux/store';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import HeaderSecondary from '../../components/header/HeaderSecondary';
import Project from '../../assets/images/project.svg';
import Button from '../../components/button/Button';
import { useState } from 'react';
import CommunicationModal from '../../components/modals/NewCommunication';
import CommunicationService from '../../services/CommunicationService';
import { ECommunicationType } from '../../redux/reducers/CommunicationReducer';
import { ENotificationType } from '../../utils/GenUtils';
import { useEffect } from 'react';
import GenUtils from '../../utils/GenUtils'
import FormatUtils from '../../utils/FormatUtils';
import ViewModal from '../../components/modals/ViewModal';
import style from '../../components/modals/AddCommunication.scss'
import GroupedTable from '../../components/table/groupedTable/GroupedTable';
import { EFilterCategory, EFilterTypes } from '../../components/filterBars/FiltersBarMultiselect';

export default function CommunicationPage() {
    const clients = useSelector((state: IStore) => state?.client.clients);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [modalData, setModalData] = useState(' ');
    const type = ECommunicationType.communication;
    const [data, setData] = useState(null);

    async function fetchData() {
        const { data, error} = await CommunicationService.fetchNewCommunications(type);
        if(error){
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Fetch Communication', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }
        return data
    }

    useEffect(() => {
        fetchData().then((res) => {
            setData(res);
        });
    }, []);

    function addCommunication() {
        setIsModalOpen(true);
    }

    const menuItem = (id) => {
        return [
            {
                key: 1,
                label: (
                    <Popconfirm title="Are you sure to delete this Invoice?" onConfirm={() => deleteCommunication(id.uuid)} okText="Yes" cancelText="No">
                        <Button danger onClick={() => {}} text="Delete Invoice" />
                    </Popconfirm>
                )
            }
        ];
    };
    const filters = [
        {
            name: 'Type',
            dataIndex: 'category',
            type: EFilterTypes.dropdown,
            category:EFilterCategory.simple,
        },
        {
            name: 'Communication At',
            dataIndex: 'communicationAt',
            type: EFilterTypes.dateRange,
            category:EFilterCategory.simple,
        },
        {
            name: 'Project',
            dataIndex: 'project.uuid',
            type: EFilterTypes.dropdown,
            category:EFilterCategory.advance,
        },
        {
            name: 'Client',
            dataIndex: 'client.uuid',
            type: EFilterTypes.dropdown,
            category:EFilterCategory.advance,
        },
        {
            name: 'Team Member',
            dataIndex: 'teamMemberId',
            type: EFilterTypes.dropdown,
            category:EFilterCategory.advance,
        },
        {
            name: 'Client contact',
            dataIndex: 'contact.name',
            type: EFilterTypes.dropdown,
            category:EFilterCategory.advance,
        },
        {
            name: 'Next FollowUp',
            dataIndex: 'followUpAt',
            type: EFilterTypes.dateRange,
            category:EFilterCategory.advance,
        }
    ]
    console.log(data);
    
    const columns = [
        {
            title: 'Type',
            dataIndex: 'category',
            key: 'category',
            render: (x) => {
                if (x === 'email') {
                    return <MailFilled style={{ color: 'blue' }} />;
                }
                if (x === 'call') {
                    return <PhoneFilled style={{ color: 'blue' }} />;
                }
                if (x === 'site visit') {
                    return <BankFilled style={{ color: 'blue' }} />;
                }
            },
            align: 'center' as AlignType
        },
        {
            title: 'Date',
            dataIndex: 'communicationAt',
            render: (x) => <p className={style.date}>{FormatUtils.getFormattedDateFromTimestamp(x)}</p>,
            defaultSortOrder: 'descend',
            sorter: (a, b) => new Date(a.communicationAt).valueOf() - new Date(b.communicationAt).valueOf()
        },
        { 
            title: 'Time',
            dataIndex: 'communicationAt',
            render: (x) => FormatUtils.getDateTimeInFormat(x, EDateTimeFormat.HH_mm_ss) },
        {
            title: 'Client',
            dataIndex: 'client',
            key: 'client',
            render: (client) => {
                return <p className={style.tableTitle}>{client?.name}</p>;
            }
        },
        {
            title: 'Project',
            dataIndex: 'project',
            key: 'project',
            render: (project) => {
                return (
                    <div className={style.projectTag}>
                        <Tag color={project?.color} text={project?.serial} />
                        <p className={style.tableTitle}>{project?.name}</p>
                    </div>
                );
            }
        },
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
            render: (note, record) => {
                return (
                    <p
                    className={style.subject}
                    onClick={() => { handleViewModal(record) }}
                    >
                    {note}
                    </p>
                );
            }
        },
        {
            title: 'From',
            dataIndex: 'communicationFrom',
            key: 'from',
            render: (text, record) => {
                var data;
                if (text === 'team_member' && record.teamMember) {
                    data = `${record.teamMember?.firstName} ${record.teamMember?.lastName}`;
                } else if (text === 'client' && record.client) {
                    data = `${record.contact?.name}`;
                }
                return <p className={style.tableTitle}>{data}</p>;
            }
        },
        {
            title: 'To',
            dataIndex: 'communicationFrom',
            key: 'to',
            render: (text, record) => {
                var data;
                if (text === 'team_member' && record.client) {
                    data = `${record.contact?.name}`;
                } else if (text === 'client' && record.teamMember) {
                    data = `${record.teamMember?.firstName} ${record.teamMember?.lastName}`;
                }

                return <p className={style.tableTitle}>{data}</p>;
            }
        },
        {
            title: 'Next follow-up',
            dataIndex: 'followUpAt',
            render: (x) => <p className={style.tableTitle}>{FormatUtils.getFormattedDateFromTimestamp(x)}</p>,
            key: 'followUpAt'
        },
        {
            title: 'Record Time',
            dataIndex: 'recordedTime',
            key: 'recordTime',
            render: (time) => {
                return <p className={style.date}>{FormatUtils.formatTime(time)}</p>
            }
        },
        {
            title: ' ',
            dataIndex: '',
            render: (text, invoice) => (
                <Dropdown menu={{ items: menuItem(invoice) }} trigger={['click']}>
                    <MoreOutlined />
                </Dropdown>
            )
        }
    ]

    function handleViewModal(invoice) {
        setViewModal(true);
        setModalData(invoice);
    }
    async function deleteCommunication(msg) {
        const { data, error } = await CommunicationService.deleteNewCommunications(msg, ECommunicationType.communication);
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to delete Communication', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }

        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Deleted Communication successfully', <notificationIcon.icon style={notificationIcon.style} />);

        fetchData().then((res) => {
            setData(res);
        });
    }

    return (
        <div>
            <HeaderSecondary
                text="Communication"
                subtext="Add New communication"
                renderAction={() => (
                    <Button text="New communication" onClick={() => addCommunication()} icon={<PlusCircleOutlined style={{ marginRight: '8px' }} />} />
                )}
                renderImage={() => <img src={Project} alt="project" width={70} />}
            />
            <Modal title="Add Communication" open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer={null}>
                <CommunicationModal
                    onSubmit={() => {
                        setIsModalOpen(false);
                        fetchData().then((res) => {
                            setData(res);
                        });
                    }}
                    onClose={()=>{
                        setIsModalOpen(false);
                    }}
                />
            </Modal>
        {data && <GroupedTable filters={filters} columns={columns} pagination={true} data={data} scrollX={1000}/>}
            <Modal title="Communication Details" open={viewModal} onCancel={() => setViewModal(false)} footer={null}>
                <ViewModal data={modalData} />
            </Modal>
        </div>
    );
}
