import React from 'react';

import styles from './InvoiceTable.scss';
import classnames from 'classnames';

interface IColumn {
    key: string;
    name: string;
    render?: (d, i, _d) => any;
    renderTotal?: (d, _d) => any;
    minWidth?: number;
}

interface ICell {
    component: React.ReactNode;
    isHeader?: boolean;
    isTotal?: boolean;
    value?: any;
    minWidth?: number;
}

const Table = ({
    data,
    columns,
    applyFilters = () => true,
    tableText
}: {
    data: any[];
    columns: IColumn[];
    applyFilters?: () => boolean;
    tableText: string;
}) => {
    const formattedData = () => {
        const columnData: ICell[][] = [];
        const _data = data.filter(applyFilters);
        columns.forEach((column) => {
            const dataColumns: ICell[] = _data.map((d, i) => ({
                component: column.render ? column.render(d, i, _data) : d[column.key],
                value: d
            }));

            columnData.push([
                {
                    component: <>{column.name}</>,
                    isHeader: true,
                    minWidth: column.minWidth
                },
                ...dataColumns,
                ...(column.renderTotal
                    ? [
                          {
                              isTotal: true,
                              component: column.renderTotal(dataColumns, _data)
                          }
                      ]
                    : [])
            ] as ICell[]);
        });

        return columnData;
    };
    const length = data?.filter(applyFilters)?.length;

    if (length == 0) {
        return <></>;
    }

    return (
        <div className={styles.container}>
            <div>
                <h5>{tableText}</h5>
            </div>
            {data?.filter(applyFilters)?.length ? (
                <div className={styles.columnContainer}>
                    {formattedData().map((column, i) => (
                        <div className={styles.column}>
                            {column.map((cell) => (
                                <div
                                    className={classnames(
                                        styles.cell,
                                        cell.isHeader && styles.header,
                                        cell.isTotal && styles.total,
                                        i === 0 && styles.leftAlign
                                    )}
                                    style={cell.minWidth ? { minWidth: cell.minWidth } : {}}>
                                    {cell?.component}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            ) : (
                <div className={styles.empty}>No relevant data</div>
            )}
        </div>
    );
};
export default Table;
