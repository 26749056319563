import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import { Link, useLocation } from 'react-router-dom';
import styles from './MenuItem.scss';
import React from 'react';
import classnames from 'classnames';
import { Popover } from 'antd';

const MenuItem = ({ onClose, item, collapsed }) => {
    // Redux
    const firmId = useSelector((state: IStore) => state?.firm?.uuid);

    // Location
    const location = useLocation();
    const { pathname } = location;

    const getNavLinkForFirm = (route) => {
        return `${firmId}/${route}`;
    };

    const pathSplit = pathname.split('/');
    const isSelected = item.navLink && pathSplit[2] === item.navLink;

    if (item.header) {
        return (
            <div
                className={styles.header}
            >
                {item.header}
            </div>
        );
    }
    return (<div
            // className={isSelected && !collapsed && styles.containerSelected}
        >
            <Link
                to={getNavLinkForFirm(item.navLink)}
                className={
                    classnames(
                        styles.container,
                        isSelected && !collapsed && styles.containerSelected,
                        isSelected && collapsed && styles.containerCollapsed,
                        !isSelected && collapsed && styles.notSelected
                    )}
            >
                {collapsed ?
                    <Popover
                        placement='right'
                        content={item.title}
                        trigger='hover'
                    >
                        <item.icon className={styles.icon} />
                    </Popover> :
                    <item.icon className={styles.icon} />
                }

                <div className={classnames(styles.text, collapsed && styles.textCollapsed)}>{item.title}</div>
                {
                    isSelected && !collapsed && <div className={styles.line} />
                }
            </Link>
        </div>
    );
};
export default MenuItem;
