import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import TimeSheetService from '../../services/TimeSheetService';
import AddRowModal from './projectWeekTimeSheet/AddRowModal';
import AddRow from './projectWeekTimeSheet/AddRow';
import HeaderRow from './projectWeekTimeSheet/HeaderRow';
import TimesheetEntries from './projectWeekTimeSheet/TimeSheetEntries';
import useTimeLogData from './hooks/useTimeLogData';
import moment from 'moment';
import MembersService from '../../services/MembersService';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import MomentUtils from '../../utils/MomentUtils';
import { teamMemberId$ } from './shared/TimesheetTab';

const ProjectWeekTimeSheet = ({ user, weekCount, weekRange }) => {
    const [showAddRowModal, setShowAddRowModal] = useState(false);
    const [selectedTeamMemberId, setSelectedTeamMember] = useState('');
    const userTimesheet = useSelector((state: IStore) => state.timeLogs.timesheet);
    const { addActivityRow, totalsV2, resetTotals, updateTimeLogData } = useTimeLogData(weekCount);
    const { phasesObj } = useSelector((state: IStore) => state.timeLogs.projectsData)

    const onSave = (date: string, data?: { date: string; seconds: number; activityId: string; phaseMemberId: string; note?: string }) => {
        TimeSheetService.updateTimesheetWeek(data, date, selectedTeamMemberId);
    };

    console.log(userTimesheet)

    useEffect(() => {
        user && setSelectedTeamMember(user.uuid);
        let utcDate = moment.utc(weekRange[0]).toISOString();
        user && TimeSheetService.fetchTimeSheetDataForMember(utcDate, user.uuid);
        resetTotals()
    }, [resetTotals, user, weekRange]);

    const addRow = async (row) => {
        let phaseMemberId = phasesObj[row.phaseId].members[0]
        console.log(phasesObj[row.phaseId].members, teamMemberId$.get())
        let data;
        data = { ...data, activityId: row.activity, phaseMemberId, date: MomentUtils.getGmtDayStart(weekRange[0]), seconds: 0 };
        if (row.role === '') {
            const { data: addMemberResponse, error } = await MembersService.addMember({
                teamMemberId: selectedTeamMemberId,
                hourRate: 0,
                budgetHours: 0,
                phaseId: row.phaseId
            });
            if (error) {
                const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
                GenUtils.showNotification('Failed to Add Member', error, <notificationIcon.icon style={notificationIcon.style} />);
                return;
            }
            data = { ...data, phaseMemberId: addMemberResponse.uuid };
        }
        await TimeSheetService.postTimelogData(MomentUtils.getGmtDayStart(weekRange[0]), selectedTeamMemberId, { timesheets: [data] });
    };

    return (
        <>
            <HeaderRow weekCount={weekCount} weekRange={weekRange} />
            <TimesheetEntries
                weekCount={weekCount}
                updateTimeLogData={updateTimeLogData}
                addActivityRow={addActivityRow}
                weekRange={weekRange}
                onSave={onSave}
            />
            <AddRow weekCount={weekCount} totals={totalsV2} selectedTeamMemberId={selectedTeamMemberId} setShowAddRowModal={setShowAddRowModal} />
            {showAddRowModal && (

            <AddRowModal
                isVisible={true}
                hideModal={() => setShowAddRowModal(false)}
                onSuccess={(data) => {
                    addRow(data)
                    setShowAddRowModal(false);
                }}
                teamMemberId={selectedTeamMemberId}
            />
            )}
        </>
    );
};

export default ProjectWeekTimeSheet;
