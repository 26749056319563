import GenUtils from '../../utils/GenUtils';
import IPermission from '../../interfaces/IPermission';

export const setPermissions = (permissions: IPermission[]) => {
    return {
        type: 'SET_PERMISSIONS',
        payload: {
            permissions: GenUtils.arrayToIdMap(permissions),
            permissionsArray: permissions
        }
    };
};