import IReport from '../interfaces/IReport';
import { APIUtil } from '../utils/ApiUtils';
import ApiURLs from '../constants/ApiURLs';
import GenUtils from '../utils/GenUtils';
import { setReports, updateReport } from '../redux/actions/ReportActions';
import moment from 'moment';

export default class ReportService {
    // static async fetchReports() {
    //     const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.FETCH_REPORTS}`);

    //     if (error) {
    //         GenUtils.log('fetchReports', error);
    //         return { error };
    //     }

    //     GenUtils.dispatch(setReports(data));
    //     return { data };
    // }

    static async fetchReport(reportId: string, from?: string, to?: string) {
        const { data, error } = await APIUtil.makeGetRequest(
            `${ApiURLs.FETCH_REPORT}/${reportId}?fromDate=${from || moment().subtract(30, 'days').toISOString()}&toDate=${to || moment().toISOString()}`
        );

        if (error) {
            GenUtils.log('fetchReport', error);
            return { error };
        }

        GenUtils.dispatch(updateReport(reportId, data));
        return { data };
    }

    static async fetchRevenueForecast(from: Date, to: Date) {
        const res = await APIUtil.makeGetRequest(`${ApiURLs.FETCH_REPORT}/revenue-forecast?fromDate=${from.toISOString()}&toDate=${to.toISOString()}`);
        return res
    }
    
    static async hiringForecast(roleId: string, from?: string, to?: string){
        const {data,error}= await APIUtil.makeGetRequest(`${ApiURLs.HIRING_FORECAST}/?fromDate=${from || moment().subtract(30, 'days').toISOString()}&toDate=${to || moment().toISOString()}&roleId=${roleId}`)

        if (error) {
            GenUtils.log('fetchReport', error);
            return { error };
        }

        return {data}
    }
}
