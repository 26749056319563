import { Dropdown, Switch, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { IMember } from '../../redux/reducers/TeamReducer';
import Button from '../../components/button/Button';
import GenUtils from '../../utils/GenUtils';
import TeamAvatar from '../../components/avatar/TeamAvatar';
import moment from 'moment';
import FormatUtils from '../../utils/FormatUtils';
import Tag from '../../components/tag/Tag';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import TimeSheetService from '../../services/TimeSheetService';
import WeekIterator from '../../components/time/WeekIterator';
import MomentUtils from '../../utils/MomentUtils';
import NavigationUtils from '../../utils/NavigationUtils';
import style from './TimeEntryTab.scss';
import classNames from 'classnames';

// ...

const SubmissionsTab = ({ isDayMode = false }) => {
    const [weekRange, setWeekRange] = useState(MomentUtils.getWeekDates(new Date()));
    const [calendarType, setCalendarType] = useState('week');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [submissionData, setSubmissionData] = useState([]);
    const firmId = useSelector((state: IStore) => state?.firm?.uuid);

    useEffect(() => {
        fetchData();
    }, [weekRange, selectedDate, calendarType]);

    const fetchData = async () => {
        let data = [];
        if (calendarType === 'week') {
            const { data: apiData } = await TimeSheetService.fetchTimesheetSubmission(weekRange[0], true);
            data = await apiData;
        } else {
            const { data: apiData } = await TimeSheetService.fetchTimesheetSubmission(selectedDate, false);
            data = apiData;
        }
        setSubmissionData(data);
    };

    const columns = [
        {
            title: 'Employee',
            dataIndex: 'member',
            width: 200,
            render: (text, data) => <TeamAvatar name={`${data?.firstName} ${data?.lastName}`} image={data.profilePicUrl} />,
            sorter: (a, b) => (a?.firstName + a?.lastName).localeCompare(b?.firstName + b?.lastName)
        },
        ...(calendarType === 'day'
            ? []
            : [
                {
                    title: 'Status',
                    dataIndex: 'timesheetStatus',
                    width: 100,
                    render: (text) => <Tag text={text} color={GenUtils.getTagColorByStatus(text)} />,
                    sorter: (a, b) => a.status.localeCompare(b.status)
                }
            ]),
        {
            title: 'Total Hours',
            dataIndex: 'totalSecs',
            width: 150,
            render: (text) => FormatUtils.getAmountInFormat(Number(text / 3600), false, 2),
            sorter: (a, b) => a.totalSecs - b.totalSecs
        },
        {
            title: 'Overtime',
            dataIndex: 'overtimeSecs',
            width: 150,
            render: (text) => FormatUtils.getAmountInFormat(Number(text / 3600), false, 2),
            sorter: (a, b) => a.overtimeSecs - b.overtimeSecs
        },
        // {
        //     title: 'Double Overtime',
        //     dataIndex: 'doubleOvertimeSecs',
        //     width: 150,
        //     render: (text) => FormatUtils.getAmountInFormat(Number(text / 3600), false, 2),
        //     sorter: (a, b) => a.doubleOvertimeSecs - b.doubleOvertimeSecs
        // },
        {
            title: 'PTO Hours',
            dataIndex: 'paidLeavesSecs',
            width: 150,
            render: (text) => FormatUtils.getAmountInFormat(Number(text / 3600), false, 2),
            sorter: (a, b) => a.paidLeavesSecs - b.paidLeavesSecs
        },
        ...(calendarType === 'day'
            ? []
            : [
                {
                    title: 'Last Edited',
                    dataIndex: 'lastEdited',
                    width: 300,
                    render: (text) => (
                        <>
                            {text}
                            {FormatUtils.getFormattedDateFromTimestamp(text)}-{FormatUtils.getTimeInFormatFromTimeStamp(text).toLocaleUpperCase()}
                        </>
                    ),
                    sorter: (a, b) => (moment(a.lastEdited).isAfter(b.lastEdited) ? 1 : -1)
                }
            ]),
        {
            title: 'Lock',
            dataIndex: 'lockEnable',
            width: 100,
            render: (text, data) => (
                <Switch
                    checked={TimeSheetService.isLocked(data)}
                    // disabled={TimeSheetService.isLocked(data)}
                    onChange={async (value) => {
                        const { data: res, error } = await TimeSheetService.updateMemberTimesheet({
                            teamMemberId: data.uuid,
                            enableLock: !data.lockEnable,
                            date: MomentUtils.getGmtDayStart(weekRange[0])
                        });
                        if (res) fetchData();
                    }}
                />
            ),
            sorter: (a, b) => (a.lockEnable ? 1 : b.lockEnable ? -1 : 0)
        },
        {
            title: 'Action',
            dataIndex: 'edit',
            width: 100,
            render: (text, data) => (
                <Button
                    type="ghost"
                    text="View"
                    onClick={() => {
                        const queryParams = new URLSearchParams({
                          teamMemberId: data.uuid,
                          weeksRange: JSON.stringify(weekRange)
                        });
                        const url = `${firmId}/timesheet/timesheets?${queryParams.toString()}`;
                        NavigationUtils.navigateTo(url);
                      }}
                />
            )
        },
        {
            title: '',
            dataIndex: 'menu',
            key: 'menu',
            width: 100,
            render: (text, member) => (
                <Dropdown menu={{ items: getMenuItems(member) }} trigger={['click']}>
                    <MoreOutlined />
                </Dropdown>
            )
        }
    ];

    const getMenuItems = (member: IMember) => [
        {
            key: 0,
            label: <span onClick={() => {}}>Send Reminder</span>
        },
        { key: 1, type: 'divider' },
        {
            key: 2,
            label: <span onClick={() => {}}>Export to CSV</span>
        },
        { key: 3, type: 'divider' },
        {
            key: 4,
            label: <span onClick={() => {}}>Export to PDF</span>
        }
    ];

    return (
        <div>
            <WeekIterator
                weekRange={weekRange}
                setWeekRange={setWeekRange}
                calendarType={calendarType}
                setCalendarType={setCalendarType}
                showCalenderType={true}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
            />
            {submissionData.length ? (
                <Table
                    className={classNames('hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius', style.submissionTab)}
                    columns={columns}
                    dataSource={
                        submissionData.length
                            ? submissionData.map((p) => {
                                return { ...p };
                            })
                            : []
                    }
                    size="middle"
                    pagination={false}
                />
            ) : null}
        </div>
    );
};

export default SubmissionsTab;
