// @ts-nocheck

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styles from './Compensation.scss';

import { Col, DatePicker, Divider, Form, Input, Modal, Popconfirm, Row, Select, Table } from 'antd';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import { getUserDetails } from '../../redux/selectors/UserSelector';
import { useLocation } from 'react-router-dom';
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Button from '../../components/button/Button';
import { RiCalendarLine, RiCloseFill } from 'react-icons/ri';
import { CompensationType } from '../../constants/Options';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import TeamService from '../../services/TeamService';
import FormatUtils from '../../utils/FormatUtils';
import PermissionUtils from '../../utils/PermissionUtils';

import CustomInput from '../../components/input/CustomInput';

const Compensation = () => {
    const colTextClass = 'hp-caption hp-text-color-black-80 hp-text-color-dark-30';
    const switchClass = 'hp-mt-sm-8 hp-ml-sm-0 hp-ml-8';
    const dividerClass = 'hp-border-color-black-40 hp-border-color-dark-80';

    const compensations = useSelector((state: IStore) => []);
    const [selectedUserId, setSelectedUserId] = useState('');
    const [showCreateModal, setCreateModal] = useState(false);

    const [hoursPerWeek, setHoursPerWeek] = useState('');
    const [hoursPerDay, setHoursPerDay] = useState('');
    const [startAt, setStartAt] = useState(moment());
    const [type, setType] = useState('');
    const [amount, setAmount] = useState(0);
    const [endAt, setEndAt] = useState('');

    // const [compensationId, setCompensationId] = useState('');

    const [addNewCompensationVisible, setAddNewCompensationVisible] = useState(false);
    const [stopCompensationVisible, setStopCompensationVisible] = useState(false);
    const columnClass = 'hp-p1-body hp-text-color-black-100 hp-text-color-dark-0 ';
    const user = useSelector((state: IStore) => getUserDetails(state, selectedUserId));

    const location = useLocation();
    const { pathname } = location;

    useEffect(() => {
        const pathSplit = pathname.split('/');
        const selectedId = pathSplit[pathSplit.indexOf('team') + 1];
        selectedId && setSelectedUserId(selectedId);
    }, [pathname]);

    const openEditDialog = (role) => {
        setUpdating(role.key);
        setName(role.role);
        setRate(role.rate);
        setCreateModal(true);
    };

    const columns = [
        {
            title: 'Effective On',
            dataIndex: 'startAt',
            width: 200,
            render: (text) => <span className={columnClass}>{FormatUtils.getFormattedDateFromTimestamp(text)}</span>
        },
        {
            title: 'Compensation',
            dataIndex: 'amount',
            width: 200,
            render: (text) => <span className={columnClass}>{text}</span>
        },
        {
            title: 'Hours per Week',
            dataIndex: 'hoursPerWeek',
            width: 200,
            render: (text) => <span className={columnClass}>{text}</span>
        },
        {
            title: 'Hours per Day',
            dataIndex: 'hoursPerDay',
            width: 200,
            render: (text) => <span className={columnClass}>{text}</span>
        },
        {
            title: '',
            dataIndex: 'edit',
            align: 'right',
            render: (text, data) => {
                return PermissionUtils.canUpdateOrCreateCompensations() ? (
                    <div>
                        {/*<a onClick={() => addNewCompensationShow()}>Edit</a>*/}
                        <Popconfirm
                            title='Are you sure to delete this compensation?'
                            onConfirm={() => DeleteCompensation(data.uuid)}
                            okText='Yes'
                            cancelText='No'>
                            <CloseOutlined style={{ color: 'IndianRed', marginLeft: 20 }} />
                        </Popconfirm>
                    </div>
                ) : null;
            }
        }
    ];

    const AddCompensation = async () => {
        const {
            data,
            error
        } = await TeamService.addCompensation(startAt, hoursPerWeek, hoursPerDay, type, amount, selectedUserId);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('failed to add compensation', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }

        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Added compensation successfully', <notificationIcon.icon
            style={notificationIcon.style} />);
        return;
    };

    const StopCompensation = async () => {
        const { data, error } = await TeamService.stopCompensation(endAt, selectedUserId);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('failed to stop compensation', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }

        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Stopped compensation for the user', <notificationIcon.icon
            style={notificationIcon.style} />);
    };

    const DeleteCompensation = async (compensationId) => {
        const { data, error } = await TeamService.deleteCompensation(compensationId, selectedUserId);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('failed to delete compensation', error, <notificationIcon.icon
                style={notificationIcon.style} />);
        }

        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Deleted compensation successfully', <notificationIcon.icon
            style={notificationIcon.style} />);
    };

    const addNewCompensationShow = () => {
        setAddNewCompensationVisible(true);
    };

    const addNewCompensationCancel = () => {
        setAddNewCompensationVisible(false);
    };
    const stopCompensationShow = () => {
        setStopCompensationVisible(true);
    };

    const stopCompensationCancel = () => {
        setStopCompensationVisible(false);
    };

    return (
        <div>
            <Modal
                title='Add Compensation'
                width={500}
                centered
                visible={addNewCompensationVisible}
                onCancel={addNewCompensationCancel}
                footer={null}
                closeIcon={<RiCloseFill className='remix-icon text-color-black-100' size={24} />}>
                <Form layout='vertical' name='basic' initialValues={{ remember: true }}>
                    <Form.Item label='Effective from' className={styles.date}>
                        <DatePicker
                            className='hp-w-100'
                            onChange={(e) => setStartAt(e.toISOString())}
                            suffixIcon={<RiCalendarLine className='remix-icon hp-text-color-black-60' />}
                            defaultValue={moment()}
                        />
                    </Form.Item>
                    <Form.Item label='Hours per week' id={styles.hours} style={{}}>
                        <CustomInput type={'number'} placeholder={'0'} onChange={(value) => setHoursPerWeek(value)}
                                     style={{ display: 'flex', flex: 1 }} />
                    </Form.Item>
                    <Form.Item label='Hours per day' id={styles.hours} style={{}}>
                        <CustomInput type={'number'} placeholder={'0'} onChange={(value) => setHoursPerDay(value)}
                                     style={{ display: 'flex', flex: 1 }} />
                    </Form.Item>
                    <Form.Item label='Compensation type' className='hp-mt-18'>
                        <Select
                            placeholder='Salary'
                            onChange={(value) => setType(value)}
                            className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                            style={{ display: 'flex', flex: 1, border: 'none' }}>
                            {CompensationType.map((compensation) => (
                                <option key={compensation} value={compensation}>
                                    {GenUtils.capitalizeFirstLetter(compensation)}
                                </option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label={type === 'salary' ? 'Annual Salary' : 'Hourly Rate'}
                               style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                        <CustomInput prefix={FormatUtils.getCurrency()} suffix={type === 'salary' ? '/year' : '/hr'} type={'number'} placeholder={''}
                               onChange={(e) => setAmount(e.target.value)} style={{ display: 'flex', flex: 1 }} />
                    </Form.Item>
                    {PermissionUtils.canUpdateOrCreateCompensations() ? (
                        <Row style={{ display: 'flex' }}>
                            <Col
                                md={12}
                                span={24}
                                className='hp-pr-sm-0 hp-pr-12'
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    gap: '20px'
                                }}>
                                <Button
                                    style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
                                    text={'Save'}
                                    type='primary'
                                    htmlType='submit'
                                    onClick={() => {
                                        AddCompensation();
                                        addNewCompensationCancel();
                                    }}
                                />
                                <Button style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
                                        text={'Cancel'} type='ghost' />
                            </Col>
                        </Row>
                    ) : null}
                </Form>
            </Modal>
            <Modal
                title='Stop Compensation'
                width={500}
                centered
                visible={stopCompensationVisible}
                onCancel={stopCompensationCancel}
                footer={null}
                closeIcon={<RiCloseFill className='remix-icon text-color-black-100' size={24} />}>
                <Form layout='vertical' name='basic' initialValues={{ remember: true }}>
                    <p> Stop compensation on the date you stop paying the employee, e.g. their termination date or when
                        they leave for sabbatical. </p>

                    <Form.Item label='Effective from'>
                        <DatePicker
                            className='hp-w-100'
                            onChange={(e) => setEndAt(e.toISOString())}
                            suffixIcon={<RiCalendarLine className='remix-icon hp-text-color-black-60' />}
                        />
                    </Form.Item>

                    <Row style={{ display: 'flex' }}>
                        <Col
                            md={12}
                            span={24}
                            className='hp-pr-sm-0 hp-pr-12'
                            style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', gap: '20px' }}>
                            <Button
                                style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
                                text={'Save'}
                                type='primary'
                                htmlType='submit'
                                onClick={() => {
                                    StopCompensation();
                                    stopCompensationCancel();
                                }}
                            />
                            <Button
                                type='ghost'
                                style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
                                text={'Cancel'}
                                onClick={() => stopCompensationCancel}
                            />
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Row>
                <h2 className='hp-mb-4'>Compensation</h2>
            </Row>

            <Row>
                <Table
                    className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                    columns={columns}
                    dataSource={user.compensations}
                    style={{ marginTop: '20px' }}
                    size='middle'
                    pagination={false}
                    ds
                />
            </Row>
            {PermissionUtils.canUpdateOrCreateCompensations() ? (
                <Row style={{ marginTop: '20px', gap: '20px' }}>
                    <Button text='Add New Compensation' onClick={() => addNewCompensationShow()}
                            icon={<PlusCircleOutlined style={{ marginRight: 10 }} />} />
                    <Button text='Stop Compensation' className='hp-profile-action-btn hp-text-right'
                            onClick={() => stopCompensationShow()} />
                </Row>
            ) : null}
            <Divider className={dividerClass} />
        </div>
    );
};
export default Compensation;
