import React from 'react';

import { Card, Col, Divider, Progress, Row, Typography } from 'antd';
import Icon, { HomeOutlined } from '@ant-design/icons';
import { EditOutlined, MoneyCollectTwoTone} from '@ant-design/icons';
import FormatUtils from '../../utils/FormatUtils';
import GenUtils from '../../utils/GenUtils';
import FeeIcon from '../../assets/icons/Feeicon.svg'

const { Title } = Typography;

const FeeAllocationCard = ({ title = 'Consultant Fee', fee = 1, allocatedFee = 0, Icon = MoneyCollectTwoTone, updateFee = () => {} }) => {
    return (
        <Card className="hp-border-color-black-40">
            <Row>
                <Col span={7} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingRight:10 }}>
                    <Col style={{display:"flex",flexDirection:"row",gap:"16px", alignItems: 'flex-end',justifyContent:"center"}}>
                    {/* <Icon style={{ fontSize: 50 }} /> */}
                    <img src={FeeIcon}  style={{paddingTop:10 ,float:"left"}}/>
                    <div>   
                        <Title level={4} style={{ margin: 0,fontSize:"24px",fontWeight:"400" }}>
                            {FormatUtils.getAmountInFormat(fee, true, 0, 0)}
                        </Title>
                        <Title level={5} style={{ margin: 0,fontSize:"14px",color:"#636E72",fontWeight:"400"  }}>
                            {GenUtils.capitalizeFirstLetter(title)}
                        </Title>
                    </div>
                    </Col>
                    <EditOutlined style={{ fontSize: 20 }} onClick={updateFee} />
                </Col>
                <Divider type="vertical" style={{ height: '5rem', border: 0, borderLeft: 1, borderColor: '#b5b5b5', borderStyle: 'solid' }} />
                <Col span={16} style={{ paddingLeft: '2rem', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <Title level={5} style={{ margin: 0,fontSize:"14px",color:"#636E72",fontWeight:"400" }}>
                        Allocated Service Fee: {FormatUtils.getAmountInFormat(allocatedFee, true, 0, 0)}
                    </Title>
                    <Progress percent={Math.round((allocatedFee * 100) / fee)} status={allocatedFee > fee ? 'exception': 'active'} />
                </Col>
            </Row>
        </Card>
    );
};

export default FeeAllocationCard;
