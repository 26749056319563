import ITax from '../../interfaces/ITax';

export interface ITaxState {
    taxes: {
        [key: string]: ITax;
    },
}

export const initialTaxState: ITaxState = {
    taxes: {},
};

const taxReducer = (state: ITaxState, action: { type: string, payload: ITax }) => {
    switch (action.type) {
        case 'SET_TAXES':
            return {
                ...state,
                taxes: action.payload,
            };
        case 'UPDATE_TAX':
            return {
                ...state,
                taxes: {
                    ...state.taxes,
                    [action.payload.uuid]: {
                        ...state.taxes[action.payload.uuid],
                        ...action.payload,
                    },
                }
            };
        default:
            return state || initialTaxState;
    }
};

export default taxReducer;