export default class NavigationUtils {
    private static navigate;

    static init(navigate) {
        this.navigate = navigate;
    }

    static navigateTo(route, props = {}) {
        this.navigate && this.navigate(route, props);
    }
}
