import ITax from '../interfaces/ITax';
import { APIUtil } from '../utils/ApiUtils';
import ApiURLs from '../constants/ApiURLs';
import GenUtils from '../utils/GenUtils';
import { setTaxes, updateTax } from '../redux/actions/TaxActions';

export default class TaxService {
    static async createTax(tax: ITax) {
        const { data, error } = await APIUtil.makePostRequest(`${ApiURLs.CREATE_TAX}`, tax);

        if (error) {
            GenUtils.log('createTax', error);
            return { error };
        }

        await TaxService.fetchTaxes();
        return { data };
    }

    static async fetchTaxes() {
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.FETCH_TAX}`);

        if (error) {
            GenUtils.log('fetchTaxes', error);
            return { error };
        }

        GenUtils.dispatch(setTaxes(data));
        return { data };
    }

    static async updateTax(taxId, tax) {
        GenUtils.dispatch(updateTax(taxId, tax));

        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.UPDATE_TAX}/${taxId}`, tax);

        if (error) {
            GenUtils.log('updateTax', error);
            return { error };
        }

        await TaxService.fetchTaxes();
        return { data };
    }

    static async deleteTax(taxId) {
        const { data, error } = await APIUtil.makeDeleteRequest(`${ApiURLs.DELETE_TAX}/${taxId}`);

        if (error) {
            GenUtils.log('deleteTax', error);
            return { error };
        }

        await TaxService.fetchTaxes();
        return { data };
    }
}