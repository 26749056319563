import IMilestone from '../interfaces/IMilestone';
import { APIUtil } from '../utils/ApiUtils';
import ApiURLs from '../constants/ApiURLs';
import GenUtils from '../utils/GenUtils';
import { setMilestones, updateMilestone } from '../redux/actions/MilestoneAction';

export default class MilestoneService {
    static async createMilestone(milestone: IMilestone) {
        const { data, error } = await APIUtil.makePostRequest(`${ApiURLs.CREATE_MILESTONE}`, milestone);

        if (error) {
            GenUtils.log('createMilestone', error);
            return { error };
        }

        await MilestoneService.fetchMilestones();
        return { data };
    }

    static async fetchMilestones() {
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.FETCH_MILESTONES}`);

        if (error) {
            GenUtils.log('fetchMilestones', error);
            return { error };
        }

        GenUtils.dispatch(setMilestones(data));
        return { data };
    }

    static async updateMilestone(milestoneId, milestone) {
        GenUtils.dispatch(updateMilestone(milestoneId, milestone));

        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.UPDATE_MILESTONE}/${milestoneId}`, milestone);

        if (error) {
            GenUtils.log('updateMilestone', error);
            return { error };
        }

        await MilestoneService.fetchMilestones();
        return { data };
    }

    static async deleteMilestone(milestoneId) {
        const { data, error } = await APIUtil.makeDeleteRequest(`${ApiURLs.DELETE_MILESTONE}/${milestoneId}`);

        if (error) {
            GenUtils.log('deleteMilestone', error);
            return { error };
        }

        await MilestoneService.fetchMilestones();
        return { data };
    }
}
