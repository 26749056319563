import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import styles from './UnauthenticatedPage.scss';
import AppUtils from '../utils/AppUtils';
import GenUtils from '../utils/GenUtils';

const notVisibleRoutes = ['/signup'];

const UnauthenticatedPage = () => {
    const location = useLocation();

    useEffect(() => {
        AppUtils.setTitle(location);
    }, [location]);

    const visible = notVisibleRoutes.filter((n) => location.pathname.includes(n)).length > 0;

    return (
        <div className={styles.container}>
            {!visible && (
                <>
                    {!GenUtils.IsMobile() && (
                        <div className={styles.section}>
                            <img className={styles.logo} src={require('../assets/images/CompanyLogo.png')} />
                            <div className={styles.textContainer}>
                                <h1 className={styles.title}>Gain visibility and more time for biz dev</h1>
                                <h3 className={styles.subtitle}>
                                    ResourcePlan replaces all siloed tools and spreadsheets, providing a centralized source of truth for your entire team to
                                    mitigate risks related to project, client, financial, time, and staff management.{' '}
                                    <a href="https://meetings.hubspot.com/naman-kasliwal" target="_blank">
                                        Schedule a demo now
                                    </a>
                                </h3>
                            </div>
                        </div>
                    )}
                </>
            )}
            {/* this is the shared layout */}
            <Outlet />
        </div>
    );
};
export default UnauthenticatedPage;
