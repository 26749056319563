import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import moment from 'moment';
import type { UploadProps } from 'antd';

import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import FormatUtils from '../../utils/FormatUtils';
import FileService from '../../services/FileService';

import ExpenseService from '../../services/ExpenseService';
import TeamMemberSelector from '../../components/selector/TeamMemberSelector';
import { Checkbox, DatePicker, Divider, Form, Input, message, Row, Select, Upload } from 'antd';
import { RiCalendarLine } from 'react-icons/ri';
import Button from '../../components/button/Button';
import IExpense from '../../interfaces/IExpense';
import FormLabel from '../../components/Formlabel/FormLabel';
import styles from './NewExpense.scss';
import FileDragger from '../../components/files/FileDragger';

const NewExpense = ({ onHide, expenseType = 'project', editMode = null }) => {
    const dividerClass = 'hp-border-color-black-40 hp-border-color-dark-80';

    const [project, setProject] = useState(null);
    const [phase, setPhase] = useState(null);
    const [category, setCategory] = useState(null);
    const [date, setDate] = useState(moment().toISOString());
    const [employee, setEmployee] = useState(null);
    const [vendorId, setVendor] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [unit, setUnit] = useState(1.0);
    const [costPerUnit, setCostPerUnit] = useState(0);
    const [note, setNote] = useState('');
    const [isBillable, setBillable] = useState(true);
    const [isEditing, setEditing] = useState(false);
    const [fileIds, setFileIds] = useState([]);

    const projects = useSelector((state: IStore) => state.project.projectsArray);
    const projectsMap = useSelector((state: IStore) => state.project.projects);
    const { expense, vendor, payment_mode } = useSelector((state: IStore) => state.options);

    useEffect(() => {
        if (!GenUtils.isEmpty(editMode)) {
            setEditing(!GenUtils.isEmpty(editMode));
            const {
                date,
                projectId,
                phaseId,
                teamMemberId,
                vendorId,
                categoryId,
                isBillable,
                paymentModeId,
                perUnitAmount,
                units,
                note
            }: IExpense = editMode;

            setProject(projectId);
            setPhase(phaseId);
            setCategory(categoryId);
            setDate(moment(date).toISOString());
            setEmployee(teamMemberId);
            setVendor(vendorId);
            setPaymentMethod(paymentModeId);
            setUnit(units);
            setCostPerUnit(perUnitAmount);
            setNote(note);
            setBillable(isBillable);
        } else {
            setEditing(false);
            setProject(null);
            setPhase(null);
            setCategory(null);
            setDate(moment().toISOString());
            setEmployee(null);
            setVendor(null);
            setPaymentMethod(null);
            setUnit(1.0);
            setCostPerUnit(0);
            setNote(null);
            setBillable(false);
        }
    }, [editMode]);

    const updateExpense = async () => {
        const { error, data } = await ExpenseService.updateExpense(editMode.uuid, { isBillable, note, phase });

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to update', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Expense updated Successfully', <notificationIcon.icon
            style={notificationIcon.style} />);
        onHide();
    };

    const addExpense = async () => {
        const { error, data } = await ExpenseService.createExpense({
            projectId: project,
            phaseId: phase,
            teamMemberId: employee,
            categoryId: category,
            vendorId,
            paymentModeId: paymentMethod,
            date,
            units: unit,
            amount: costPerUnit,
            isBillable,
            note,
            type: expenseType === 'project' ? 'project' : 'firm',
            fileId: fileIds[0],
        });

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to create', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Expense added Successfully', <notificationIcon.icon
            style={notificationIcon.style} />);
        onHide();
    };
    const disableSubmitBtn = () => {
        return !project || !category || !employee;
    };
    return (
        <Form
            className={styles.container}
            layout='vertical'
            name='basic'
            >

            <Row style={{ justifyContent: 'space-between' }}>
                <div style={{ flexDirection: 'column', display: 'flex' }}>
                    <FormLabel className={styles.label} text={'Project'}/>
                    <Select
                        showSearch
                        className={styles.dropdown}
                        value={project}
                        onChange={setProject}
                        placeholder='select'
                        disabled={isEditing}
                    >
                        {
                            projects.map(
                                option => <Select.Option key={option.uuid} value={option.uuid}>
                                    {GenUtils.capitalizeInitials(option.name)}
                                </Select.Option>
                            )
                        }
                    </Select>
                </div>
                <div style={{ flexDirection: 'column', display: 'flex' }}>
                    <FormLabel text={'Phase'} optional={true} className={styles.label}/>
                    <Select
                        showSearch
                        className={styles.dropdown}
                        placeholder='select'
                        value={phase}
                        onChange={(value) => {
                            setPhase(value);
                        }}
                    >
                        {
                            projectsMap[project]?.phases?.map(
                                phase => {
                                    return <Select.Option key={phase.uuid} value={phase.uuid}>
                                        {GenUtils.capitalizeInitials(phase?.type?.name)}
                                    </Select.Option>;
                                }
                            )
                        }
                    </Select>
                </div>
            </Row>

            <Row style={{ justifyContent: 'space-between' }}>
                <div style={{ flexDirection: 'column', display: 'flex' }}>
                    <FormLabel text={'Category'} className={styles.label}/>
                    <Select
                        showSearch
                        className={styles.dropdown}
                        placeholder='select'
                        value={category}
                        onChange={(value) => {
                            setCategory(value);
                        }}
                        disabled={isEditing}
                    >
                        {
                            expense.map(
                                option => <Select.Option key={option.uuid} value={option.uuid}>
                                    {GenUtils.capitalizeInitials(option.name)}
                                </Select.Option>
                            )
                        }
                    </Select>
                </div>
                <div style={{ flexDirection: 'column', display: 'flex' }}>
                    <FormLabel className={styles.label} text={'Date'}/>
                    <DatePicker
                        className={styles.dropdown}
                        value={moment(date)}
                        onChange={value => {
                            setDate(value.toISOString());
                        }}
                        suffixIcon={
                            <RiCalendarLine className='remix-icon hp-text-color-black-60' />
                        }
                        disabled={isEditing}
                    />
                </div>
            </Row>
            <Row style={{ justifyContent: 'space-between' }}>
                <div style={{ flexDirection: 'column', display: 'flex' }}>
                    <FormLabel className={styles.label} text={'Employee'}/>
                    <TeamMemberSelector
                        selectedMembers={employee}
                        setSelectedMembers={(e) => {
                            setEmployee(e.uuid);
                        }}
                        width={250}
                        disabled={isEditing}
                        style={{ marginBottom: '20px' }}
                    />
                </div>
                <div style={{ flexDirection: 'column', display: 'flex' }}>
                    <FormLabel text={'Vendor'} optional={true} className={styles.label}/>
                    <Select
                        showSearch
                        className={styles.dropdown}
                        placeholder='select'
                        value={vendorId}
                        onChange={(value) => {
                            setVendor(value);
                        }}
                        disabled={isEditing}
                    >
                        {
                            vendor.map(
                                option => <Select.Option key={option.uuid} value={option.uuid}>
                                    {GenUtils.capitalizeInitials(option.name)}
                                </Select.Option>
                            )
                        }
                    </Select>
                </div>
            </Row>
            <div style={{ flexDirection: 'column', display: 'flex' }}>
                <FormLabel text={'Payment Method'} optional={true} className={styles.label}/>
                <Select
                    className={styles.dropdown}
                    placeholder='select'
                    value={paymentMethod}
                    onChange={(value) => {
                        setPaymentMethod(value);
                    }}
                    disabled={isEditing}>
                    {
                        payment_mode.map(
                            option => <Select.Option key={option.uuid} value={option.uuid}>
                                {GenUtils.capitalizeInitials(option.name)}
                            </Select.Option>
                        )
                    }
                </Select>
            </div>

            <Divider className={dividerClass} />

            <Row style={{ justifyContent: 'space-between' }}>
                <div style={{ flexDirection: 'column', display: 'flex' }}>
                    <FormLabel text={'Units'} className={styles.label}/>
                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                        <Input
                            className={styles.dropdown}
                            type={'number'}
                            min="0"
                            placeholder="1.00"
                            value={unit}
                            step="0.01"
                            onChange={(e) => {
                                setUnit(Number(e.target.value));
                            }}
                            disabled={isEditing}
                        />
                    </div>
                </div>
                <div style={{ flexDirection: 'column', display: 'flex' }}>
                    <FormLabel text={'Cost Per Unit'} className={styles.label}/>
                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                        <Input
                            className={styles.dropdown}
                            type={'number'}
                            placeholder='0'
                            value={costPerUnit}
                            onChange={(e) => {
                                setCostPerUnit(Number(e.target.value));
                            }}
                            disabled={isEditing}
                            prefix={FormatUtils.getCurrency()}
                        />
                    </div>
                </div>
            </Row>
            <Row>
                <span className={styles.label}>Total Amount: </span>
                <div>{FormatUtils.getAmountInFormat(costPerUnit * unit, true, 0, '0')}</div>
            </Row>
            <Row>
                <Checkbox checked={isBillable} onChange={(e) => setBillable(e.target.checked)}>
                    Is it Billable?
                </Checkbox>
            </Row>
            <Divider className={dividerClass} />
            <FormLabel text={'Note'} className={styles.label}/>
            <Input.TextArea
                placeholder='Note..'
                className={styles.textArea}
                value={note}
                onChange={(e) => {
                    setNote(e.target.value);
                }}
            />
            {
                !isEditing && <FileDragger setfileIds={setFileIds}/>
            }

            <Row className={styles.buttons} style={{ gap: 10 }}>
                <Button
                    type='ghost'
                    text={'cancel'}
                    onClick={onHide}
                />
                <Button
                    type='primary'
                    text={isEditing ? 'Update' : 'Add'}
                    onClick={isEditing ? updateExpense : addExpense}
                    disable={disableSubmitBtn()}
                />
            </Row>
        </Form>
    );
};

export default NewExpense;
