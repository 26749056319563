import React, { useEffect, useState } from 'react';
import style from './FirmSettings.scss'
import CustomInput from '../../components/input/CustomInput';
import {
    Button, Checkbox, Col, Divider, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import { getUserDetails } from '../../redux/selectors/UserSelector';
import UserService from '../../services/UserService';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import { ModesOfPayment, TimeRound } from '../../constants/Options';
import ColorPickerButton from '../../components/colorPicker/ColorPickerButton';
import TaxService from '../../services/TaxService';
import ITax from '../../interfaces/ITax';
import OptionService from '../../services/OptionService';
import { EOptionType, IOption } from '../../redux/reducers/OptionReducer';
import classNames from 'classnames';

const Invoices = () => {
    // const [dueAfterTermsVisible, setDueAfterTermsVisible] = useState(false);
    const [newTermVisible, setNewTermVisible] = useState(false);
    const [addTaxGroupVisible, setAddTaxGroupVisible] = useState(false);
    const [brandColorsVisible, setBrandColorsVisible] = useState(false);
    const [invoiceTermsVisible, setInvoiceTermsVisible] = useState(false);
    const [invoiceDefaultsVisible, setInvoiceDefaultsVisible] = useState(false);

    const [brandColor, setBrandColor] = useState('#000');
    const [accentColor, setAccentColor] = useState('#000');

    const [timeRound, setTimeRound] = useState('');
    const [modeOfPayment, setModeOfPayment] = useState('');

    const [addTaxVisible, setAddTaxVisible] = useState(false);
    const [name, setName] = useState(null);
    const [amount, setAmount] = useState(null);
    const [taxIds, setTaxIds] = useState(null);
    const [updateTax, setUpdatingTax] = useState(null);
    const [updatingTerm, setUpdatingTerm] = useState(null);

    const user = useSelector((state: IStore) => getUserDetails(state));
    const taxes = useSelector((state: IStore) => state.taxes.taxes);
    const terms = useSelector((state: IStore) => state.options.invoice_terms);

    const columnClass = 'hp-p1-body hp-text-color-black-100 hp-text-color-dark-0 ';
    const listTitle = 'hp-p1-body';
    const listResult = 'hp-mt-sm-4 hp-p1-body hp-text-color-black-100 hp-text-color-dark-0';
    const dividerClass = 'hp-border-color-black-40 hp-border-color-dark-80';

    // if (user.timeRound !== timeRound) setTimeRound(user.timeRound);
    // if (user.modeOfPayment !== modeOfPayment) setModeOfPayment(user.modeOfPayment);
    // if (user.firmType !== firmType) setFirmType(user.firmType);
    // if (user.firmType !== firmType) setFirmType(user.firmType); Todo

    useEffect(() => {
        if (!accentColor && user.accentColor !== accentColor) setAccentColor(user.accentColor);
        if (!brandColor && user.brandColor !== brandColor) setBrandColor(user.brandColor);
    }, [user.uuid]);

    const updateUser = async () => {
        const { data, error } = (await UserService.updateUser({
            brandColor,
            accentColor
        }));

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Update', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Successfully Updated Settings', <notificationIcon.icon
            style={notificationIcon.style} />);
        brandColorsCancel();
    };

    const createTax = async (isGroup = false) => {
        const { data, error } = await TaxService.createTax({
            name,
            rate: isGroup ? taxIds.reduce((rate, taxId) => rate + taxes[taxId]?.rate, 0) : amount,
            type: isGroup ? 'group' : 'individual',
            ...(isGroup ? { taxIds } : {})
        });

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Add Tax', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }

        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Added Tax successfully', <notificationIcon.icon
            style={notificationIcon.style} />);
        setAddTaxVisible(false);
        setUpdatingTax(null);
        setName('');
        setAmount(0);
        setTaxIds([]);
    };

    const updateTaxDetails = async () => {
        const { data, error } = await TaxService.updateTax(updateTax.uuid, {
            name,
            rate: updateTax.type === 'group' ? taxIds.reduce((rate, taxId) => rate + taxes[taxId]?.rate, 0) : amount,
            ...(updateTax.type === 'group' ? { taxIds } : {})
        });

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Update Tax', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }

        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Updated Tax successfully', <notificationIcon.icon
            style={notificationIcon.style} />);
        setUpdatingTax(null);
        setName('');
        setAmount(0);
        setTaxIds([]);
    };

    const openEditTaxDialog = (tax) => {
        setName(tax.name);
        setAmount(tax.rate);
        setTaxIds(tax.taxIds);
        setUpdatingTax(tax);
    };

    const deleteTax = async (taxId: string) => {
        const { data, error } = await TaxService.deleteTax(taxId);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Delete Tax', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }

        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Deleted Tax successfully', <notificationIcon.icon
            style={notificationIcon.style} />);
    };

    const createTerm = async () => {
        const { data, error } = await OptionService.createOption({ name, amount, type: EOptionType.invoice_terms });

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Add Term', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }

        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Added Term successfully', <notificationIcon.icon
            style={notificationIcon.style} />);
        setNewTermVisible(false);
        setUpdatingTerm(null);
        setName('');
        setAmount(0);
    };

    const updateTerm = async () => {
        const { data, error } = await OptionService.updateOption(updatingTerm.uuid, { name, amount });

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Update Term', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }

        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Updated Term successfully', <notificationIcon.icon
            style={notificationIcon.style} />);
        setNewTermVisible(false);
        setUpdatingTerm(null);
        setName('');
        setAmount(0);
    };

    const openEditTermDialog = (term) => {
        setName(term.name);
        setAmount(term.amount);
        setUpdatingTerm(term);
    };

    const deleteTerm = async (termId: string) => {
        const { data, error } = await OptionService.deleteOption(termId);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Delete Term', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }

        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Deleted Term successfully', <notificationIcon.icon
            style={notificationIcon.style} />);
    };


    const brandColorsShow = () => {
        setBrandColorsVisible(true);
    };

    const brandColorsCancel = () => {
        setBrandColorsVisible(false);
    };
    const invoiceTermsShow = () => {
        setInvoiceTermsVisible(true);
    };

    const invoiceTermsCancel = () => {
        setInvoiceTermsVisible(false);
    };
    const invoiceDefaultsShow = () => {
        setInvoiceDefaultsVisible(true);
    };

    const invoiceDefaultsCancel = () => {
        setInvoiceDefaultsVisible(false);
    };


    const columnTerms: ColumnsType = [
        {
            title: 'Term Name',
            dataIndex: 'name'
        },
        {
            title: 'Due After',
            dataIndex: 'amount'
        },
        {
            title: '',
            dataIndex: 'action3',
            width: 100,
            render: (text, data: IOption) => (
                <div>
                    <a onClick={() => openEditTermDialog(data)}>Edit</a>
                    <Popconfirm
                        title='Are you sure to delete this Term?'
                        onConfirm={() => deleteTerm(data.uuid)}
                        okText='Yes'
                        cancelText='No'
                    >
                        <CloseOutlined style={{ color: 'IndianRed', marginLeft: 20 }} />
                    </Popconfirm>
                </div>
            )
        }

    ];

    const columnTaxes = [
        {
            title: 'Tax Name',
            dataIndex: 'name',
            width: 200,
            render: (text) => <span className={columnClass}>{text}</span>
        },
        {
            title: 'Rate (%) ',
            dataIndex: 'rate',
            width: 200,
            render: (text) => <span className={columnClass}>{text}</span>
        },
        // {
        //     title: '',
        //     dataIndex: 'action',
        //     align: 'right',
        //     render: (text) => (
        //         <Button type='text'>Mark as Default</Button>
        //     )
        // },
        {
            title: '',
            dataIndex: 'action3',
            width: 50,
            render: (text, data: ITax) => (
                <div>
                    <a onClick={() => openEditTaxDialog(data)}>Edit</a>
                    <Popconfirm
                        title='Are you sure to delete this Tax?'
                        onConfirm={() => deleteTax(data.uuid)}
                        okText='Yes'
                        cancelText='No'
                    >
                        <CloseOutlined style={{ color: 'IndianRed', marginLeft: 20 }} />
                    </Popconfirm>
                </div>
            )
        }
    ];

    const columnGroup = [
        {
            title: '',
            dataIndex: 'checkbox',
            render: (_, tax) => (
                <Space>
                    <Checkbox
                        checked={taxIds?.indexOf(tax.uuid) >= 0}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setTaxIds(taxIds => {
                                    return Array.from(new Set([...(taxIds || []), tax.uuid]));
                                });
                            } else {
                                setTaxIds(taxIds => {
                                    return taxIds.filter((taxId) => tax.uuid !== taxId);
                                });
                            }
                        }} />
                </Space>
            )
        },
        {
            title: '',
            dataIndex: 'name'
        },
        {
            title: '',
            dataIndex: 'rate'
        }
    ];

    return (
        <div>
            <Modal
                title='Brand Colors'
                width={500}
                centered
                visible={brandColorsVisible}
                onCancel={brandColorsCancel}
                footer={null}
                closeIcon={
                    <CloseOutlined
                        style={{ color: 'IndianRed' }}
                        size={24} />
                }
            >
                <Form layout='vertical' name='basic' initialValues={{ remember: true }}>
                    <Col
                        span={24}
                        className='hp-profile-content-list hp-mt-8 hp-pb-sm-0'
                        style={{ gap: '20px', display: 'flex', flexDirection: 'column' }}
                    >
                        <li style={{ alignItems: 'center', gap: '20px', display: 'flex' }}>
                            <span style={{ width: 150, fontSize: 14, fontWeight: 'bold' }}>Brand Color</span>:
                            <ColorPickerButton color={brandColor} setColor={setBrandColor} />
                        </li>
                        <li style={{ alignItems: 'center', gap: '20px', display: 'flex' }}>
                            <span style={{ width: 150, fontSize: 14, fontWeight: 'bold' }}>Accent Color</span>:
                            <ColorPickerButton color={accentColor} setColor={setAccentColor} />
                        </li>
                    </Col>
                    <Row style={{ marginTop: '20px', justifyContent: 'space-between' }}>
                        <Col md={11} span={24} className='hp-mt-sm-12 hp-pl-sm-0 hp-pl-12'>
                            <Button block onClick={brandColorsCancel}>
                                Cancel
                            </Button>
                        </Col>
                        <Col md={11} span={24} className='hp-pr-sm-0 hp-pr-12'>
                            <Button
                                block
                                type='primary'
                                htmlType='submit'
                                onClick={() => {
                                    updateUser();
                                }}
                            >
                                Add
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Modal
                title='Invoice Terms'
                width={800}
                centered
                visible={invoiceTermsVisible}
                onCancel={invoiceTermsCancel}
                footer={null}
                closeIcon={
                    <CloseOutlined
                        style={{ color: 'IndianRed' }}
                        size={24} />
                }
            >
                <Form layout='vertical' name='basic' initialValues={{ remember: true }}>
                    <Col
                        span={24}
                        className='hp-profile-content-list hp-mt-8 hp-pb-sm-0 hp-pb-120'
                    >
                        <ul>
                            <li>
                                <span className={listTitle}>Time Round</span>
                                <Select
                                    placeholder='Country'
                                    style={{ width: 300 }}
                                    onChange={(value) => {
                                        setTimeRound(value);
                                    }}
                                >
                                    {
                                        TimeRound.map(timeRound => <option key={timeRound}
                                                                           value={timeRound}>{timeRound}</option>)
                                    }
                                </Select>

                                <InputNumber
                                    style={{ width: 100, marginRight: '10px' }}
                                    defaultValue={100}
                                />
                                <p className='hp-p1-body hp-mb-0'>Minutes</p>

                            </li>
                            {/* <li className='hp-mt-18'>
                                <span className={listTitle}>Due After Terms</span>
                                <Input style={{ width: 300 }} onClick={DueAfterTermsShow} />
                            </li> */}

                            <li className='hp-mt-18'>
                                <span className={listTitle}>Interest after due date</span>
                                <InputNumber style={{ width: 300 }} addonAfter='$' defaultValue={100} />
                            </li>

                            <li className='hp-mt-18'>
                                <span className={listTitle}>Modes of payment</span>
                                <Select
                                    placeholder='Country'
                                    style={{ width: 300 }}
                                    onChange={(value) => {
                                        setModeOfPayment(value);
                                    }}
                                >
                                    {
                                        ModesOfPayment.map(modesOfPayment => <option key={modesOfPayment}
                                                                                     value={modesOfPayment}>{modesOfPayment}</option>)
                                    }
                                </Select>
                                <Button
                                    style={{ width: 100 }}
                                    icon={<PlusCircleOutlined style={{ marginRight: 10 }} />}
                                >New Mode</Button>
                            </li>
                        </ul>
                    </Col>
                    <Row>
                        <Col md={12} span={24} className='hp-mt-sm-12 hp-pl-sm-0 hp-pl-12'>
                            <Button block onClick={invoiceTermsCancel}>
                                Cancel
                            </Button>
                        </Col>
                        <Col md={12} span={24} className='hp-pr-sm-0 hp-pr-12'>
                            <Button
                                block
                                type='primary'
                                htmlType='submit'
                                onClick={invoiceTermsCancel}
                            >
                                Add
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Modal
                title='Invoice Defaults'
                width={500}
                centered
                visible={invoiceDefaultsVisible}
                onCancel={invoiceDefaultsCancel}
                footer={null}
                closeIcon={
                    <CloseOutlined
                        style={{ color: 'IndianRed' }}
                        size={24} />
                }
            >
                <Form layout='vertical' name='basic' initialValues={{ remember: true }}>
                    <Col
                        span={24}
                        className='hp-profile-content-list hp-mt-8 hp-pb-sm-0 hp-pb-120'
                    >
                        <ul>
                            <li className='hp-mt-18'>
                                <span className={listTitle}>Default Subjects</span>
                                <Input/>

                            </li>

                            <li className='hp-mt-18'>
                                <span className={listTitle}>Default Notes</span>
                                <Input.TextArea rows={3} />
                            </li>
                        </ul>
                    </Col>
                    <Row>
                        <Col md={12} span={24} className='hp-mt-sm-12 hp-pl-sm-0 hp-pl-12'>
                            <Button block onClick={invoiceDefaultsCancel}>
                                Cancel
                            </Button>
                        </Col>
                        <Col md={12} span={24} className='hp-pr-sm-0 hp-pr-12'>
                            <Button
                                block
                                type='primary'
                                htmlType='submit'
                                onClick={invoiceDefaultsCancel}
                            >
                                Add
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            {/* <Modal
                title='Due After Terms'
                width={416}
                centered
                visible={dueAfterTermsVisible}
                onCancel={DueAfterTermsCancel}
                footer={null}
                closeIcon={
                    <CloseOutlined
                        style={{ color: 'IndianRed' }}
                        size={24} />
                }
            >
                <Form layout='vertical' name='basic' initialValues={{ remember: true }}>
                    <Table
                        className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                        columns={columns}
                        dataSource={data}
                        size='middle'
                        pagination={false}
                    />
                    <Button onClick={NewTermShow}>+ New Term</Button>
                </Form>
            </Modal> */}
            <Modal
                title={updatingTerm ? 'Update Term' : 'Create Invoice Term'}
                width={416}
                centered
                open={newTermVisible || updatingTerm}
                onCancel={() => {
                    setNewTermVisible(false);
                    setUpdatingTerm(null);
                }}
                footer={null}
                destroyOnClose={true}
                closeIcon={
                    <CloseOutlined
                        style={{ color: 'IndianRed' }}
                        size={24} />
                }
            >
                <Form layout='vertical' name='basic' initialValues={{ remember: true }}>
                    <Form.Item label='Term Name' name='language'>
                        <CustomInput type='text' value={name} onChange={setName} />
                    </Form.Item>
                    <Form.Item label='Due After' name='amount'>
                        <CustomInput value={amount} onChange={setAmount} suffix='days' />
                    </Form.Item>
                    <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Col md={11} span={24}>
                            <Button block onClick={() => {
                                setNewTermVisible(false);
                                setUpdatingTerm(null);
                            }}>
                                Cancel
                            </Button>
                        </Col>
                        <Col md={11} span={24}>
                            <Button
                                block
                                type='primary'
                                htmlType='submit'
                                onClick={() => updatingTerm ? updateTerm() : createTerm()}
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Modal
                title={updateTax && updateTax.type === 'individual' ? 'Update Tax' : 'Add Tax'}
                width={416}
                centered
                open={addTaxVisible || (updateTax && updateTax.type === 'individual')}
                onCancel={() => {
                    setAddTaxVisible(false);
                    setUpdatingTax(null);
                }}
                footer={null}
                closeIcon={
                    <CloseOutlined
                        style={{ color: 'IndianRed' }}
                        size={24} />
                }
            >
                <Form layout='vertical' name='basic' initialValues={{ remember: true }}>
                    <Form.Item label='Tax Name'>
                        <CustomInput value={name} onChange={setName} type={'text'} />
                    </Form.Item>

                    <Form.Item label='Rate (%)'>
                        <CustomInput suffix={'%'} value={amount} onChange={setAmount} />
                    </Form.Item>

                    <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Col md={11} span={24}>
                            <Button block onClick={() => {
                                setAddTaxVisible(false);
                                setUpdatingTax(null);
                            }}>
                                Cancel
                            </Button>
                        </Col>
                        <Col md={11} span={24}>
                            <Button
                                block
                                type='primary'
                                htmlType='submit'
                                onClick={() => updateTax ? updateTaxDetails() : createTax()}
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Modal
                title={updateTax && updateTax.type === 'group' ? 'Update Tax Group' : 'Add Tax Group'}
                width={416}
                centered
                open={addTaxGroupVisible || (updateTax && updateTax.type === 'group')}
                onCancel={() => {
                    setAddTaxGroupVisible(false);
                    setUpdatingTax(null);
                }}
                footer={null}
                closeIcon={
                    <CloseOutlined
                        style={{ color: 'IndianRed' }}
                        size={24} />
                }
            >
                <Form layout='vertical' name='basic' initialValues={{ remember: true }}>
                    <Form.Item label='Tax Group Name'>
                        <CustomInput value={name} onChange={setName} type={'text'} />
                    </Form.Item>

                    <Form.Item label='Associated Taxes'>
                        <Table
                            className={classNames('hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius',style.tax)}
                            columns={columnGroup}
                            dataSource={Object.values(taxes || {})?.filter((t: ITax) => t.type === 'individual')}
                            pagination={false}
                        />
                    </Form.Item>

                    <Row>
                        <Col md={12} span={24} className='hp-mt-sm-12 hp-pl-sm-0 hp-pl-12'>
                            <Button block onClick={() => {
                                setAddTaxGroupVisible(false);
                                setUpdatingTax(null);
                            }}>
                                Cancel
                            </Button>
                        </Col>
                        <Col md={12} span={24} className='hp-pr-sm-0 hp-pr-12'>
                            <Button
                                block
                                type='primary'
                                htmlType='submit'
                                onClick={() => updateTax ? updateTaxDetails() : createTax(true)}
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>


            <Col md={15} span={24}>
                <h2>Invoices</h2>
                <p className='hp-p1-body hp-mb-0'>
                    Make {user.firmName} stand out with customized invoices.
                </p>
            </Col>

            <Divider className={dividerClass} />

            <Row align='middle' justify='space-between'>
                <Col md={12} span={24} style={{ display: 'flex', flexDirection: 'column', marginBottom: '30px' }}>
                    <h3>Brand Colors</h3>
                    <p className='hp-p1-body hp-mb-0'>
                        Define brand colors to personalize your invoices.
                    </p>
                </Col>
                <Col md={12} span={24} className='hp-profile-action-btn hp-text-right'>
                    <Button type='primary' ghost onClick={brandColorsShow}>
                        Edit
                    </Button>
                </Col>
                <Col
                    span={24}
                    className='hp-profile-content-list hp-mt-8 hp-pb-sm-0 hp-pb-120'
                >
                    <ul>
                        <li>
                            <span className={listTitle}>Brand Color</span>
                            <span className={listResult}>{user.brandColor &&
                            <ColorPickerButton color={user.brandColor} disable />}{user.brandColor || '-'}</span>
                        </li>

                        <li className='hp-mt-18'>
                            <span className={listTitle}>Accent Color</span>
                            <span className={listResult}>{user.accentColor &&
                            <ColorPickerButton color={user.accentColor} disable />}{user.accentColor || '-'}</span>
                        </li>
                    </ul>
                </Col>


            </Row>

            <Divider className={dividerClass} />
            <Row align='middle' justify='space-between'>
                <Col md={12} span={24} style={{ display: 'flex', flexDirection: 'column', marginBottom: '30px' }}>
                    <h3>Invoice Terms</h3>
                    <p className='hp-p1-body hp-mb-0'>
                        Define the terms to be used in your invoices.
                    </p>
                </Col>
                <Col md={12} span={24} className='hp-profile-action-btn hp-text-right'>
                    <Button type='primary' ghost onClick={invoiceTermsShow}>
                        Edit
                    </Button>
                </Col>

                <Col
                    span={24}
                    className='hp-profile-content-list hp-mt-8 hp-pb-sm-0 hp-pb-120'
                >
                    <ul>
                        <li>
                            <span className={listTitle}>Time Round</span>
                            <span className={listResult}>{user.timeRounding || '-'}</span>
                        </li>

                        <li className='hp-mt-18'>
                            <span className={listTitle}>Interest after due date</span>
                            <span className={listResult}>{'-'}</span>
                        </li>

                        <li className='hp-mt-18'>
                            <span className={listTitle}>Modes of payment</span>
                            <span className={listResult}>{'-'}</span>

                        </li>

                        <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <h5>Due After Terms</h5>
                            <Table
                                className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                                columns={columnTerms}
                                dataSource={terms}
                                pagination={false}
                            />
                            <Button
                                type="primary"
                                onClick={() => {
                                    setName('');
                                    setAmount(0);
                                    setUpdatingTerm(null);
                                    setNewTermVisible(true);
                                }}
                                style={{ width: 200 }}
                                icon={<PlusCircleOutlined style={{ marginRight: 10 }} />}
                            >New Term</Button>
                        </div>
                    </ul>
                </Col>
            </Row>

            <Divider className={dividerClass} />

            <Row align='middle' justify='space-between'>
                <Col md={12} span={24} style={{ display: 'flex', flexDirection: 'column', marginBottom: '30px' }}>
                    <h3>Invoice Defaults</h3>
                    <p className='hp-p1-body hp-mb-0'>
                        Set a default subject and default notes for your invoices.
                    </p>
                </Col>
                <Col md={12} span={24} className='hp-profile-action-btn hp-text-right'>
                    <Button type='primary' ghost onClick={invoiceDefaultsShow}>
                        Edit
                    </Button>
                </Col>
                <Col
                    span={24}
                    className='hp-profile-content-list hp-mt-8 hp-pb-sm-0 hp-pb-120'
                >
                    <ul>
                        <li className='hp-mt-18'>
                            <span className={listTitle}>Default Subjects</span>
                            <span className={listResult}> English</span>

                        </li>

                        <li className='hp-mt-18'>
                            <span className={listTitle}>Default Notes</span>
                            <span className={listResult}> abcd</span>
                        </li>
                    </ul>
                </Col>
            </Row>

            <Divider className={dividerClass} />
            <Row align='middle' justify='space-between'>
                <Col md={12} span={24} style={{ display: 'flex', flexDirection: 'column', marginBottom: '30px' }}>
                    <h3>Tax</h3>
                    <p className='hp-p1-body hp-mb-0'>
                        Set default taxes that are to be added in your invoices.
                    </p>
                </Col>
                <Col md={12} span={24} style={{ display: 'flex', gap: '20px', justifyContent: 'flex-end' }}>
                    <Button
                        type='primary'
                        ghost
                        onClick={() => {
                            setUpdatingTax(null);
                            setName('');
                            setAmount(0);
                            setTaxIds([]);
                            setAddTaxGroupVisible(true);
                        }}
                    >
                        Add Tax Group
                    </Button>
                    <Button
                        type='primary'
                        onClick={() => {
                            setUpdatingTax(null);
                            setName('');
                            setAmount(0);
                            setTaxIds([]);
                            setAddTaxVisible(true);
                        }}
                    >
                        Add Tax
                    </Button>
                </Col>
                <Col
                    span={24}
                    className='hp-profile-content-list hp-mt-8 hp-pb-sm-0 hp-pb-120'
                >
                    <ul>
                        <Table
                            className={classNames('hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius',style.tax)}
                            columns={columnTaxes}
                            dataSource={Object.values(taxes)}
                            pagination={false}
                        />
                    </ul>
                </Col>
            </Row>
        </div>
    );
};
export default Invoices;
