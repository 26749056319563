import {
    BankOutlined,
    ClockCircleOutlined,
    ContainerOutlined,
    ControlOutlined,
    DollarCircleOutlined,
    FundOutlined,
    IdcardOutlined,
    MessageOutlined,
    ReconciliationOutlined,
    SnippetsOutlined,
    UserOutlined
} from '@ant-design/icons';
import PermissionUtils from '../../utils/PermissionUtils';

const SideBar = (collapsed: any) => {
    const options: any[] = [
        {
            header: collapsed ? 'T' : 'TRACK'
        },
        {
            id: 'Dashboard',
            title: 'Dashboard',
            navLink: 'dashboard',
            icon: FundOutlined
        },
        {
            id: 'Timesheet',
            title: 'Timesheet',
            navLink: 'timesheet',
            icon: ClockCircleOutlined
        }
        // {
        //     id: 'Tasks',
        //     title: 'Tasks',
        //     navLink: 'tasks',
        //     icon: CarryOutOutlined
        // }
    ];

    if (PermissionUtils.canSeeExpensesTab()) {
        options.push({
            id: 'Expenses',
            title: 'Expenses',
            navLink: 'expenses',
            icon: DollarCircleOutlined
        });
    }

    options.push({
        id: 'Communications',
        title: 'Communications',
        navLink: 'communications',
        icon: MessageOutlined
    });

    if (PermissionUtils.canSeeAllocationTab()) {
        options.push({
            id: 'Allocate Work',
            title: 'Allocate Work',
            navLink: 'allocate',
            icon: ContainerOutlined
        });
    }

    options.push({ header: collapsed ? 'M' : 'MANAGE' });

    options.push({
        id: 'Projects',
        title: 'Projects',
        navLink: 'projects',
        icon: BankOutlined
    });

    if (PermissionUtils.canSeeClientTab()) {
        options.push({
            id: 'Clients',
            title: 'Clients',
            navLink: 'clients',
            icon: UserOutlined
        });
    }

    if (PermissionUtils.canSeeInvoicesTab()) {
        options.push({
            id: 'Invoices',
            title: 'Invoices',
            navLink: 'invoices',
            icon: SnippetsOutlined
        });
    }

    if (PermissionUtils.canSeeTeamTab()) {
        options.push({
            id: 'Team',
            title: 'Team',
            navLink: 'team',
            icon: IdcardOutlined
        });
    }

    options.push({
        id: 'Reports',
        title: 'Reports',
        navLink: 'reports',
        icon: ReconciliationOutlined,
        children: [
            {
                id: 'X',
                title: 'X',
                navLink: 'something',
                icon: SnippetsOutlined
            },
            {
                id: 'y',
                title: 'y',
                navLink: 'everything',
                icon: IdcardOutlined
            }
        ]
    });

    if (PermissionUtils.canSeeFirmSettingsTab()) {
        options.push({
            id: 'Firm Settings',
            title: 'Firm Settings',
            navLink: 'firm-settings',
            icon: ControlOutlined
        });
    }

    return options;
};

export default SideBar;
