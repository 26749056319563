// @ts-nocheck
import React, { useState } from 'react';

import { Button, Col, Form, Input, Modal, Row, Select, Table } from 'antd';
import { RiCloseFill } from 'react-icons/ri';
import { Countries, Industries } from '../../constants/Options';
import styles from './FirmSettings.scss';
import PermissionService from '../../services/PermissionService';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import Permission from './Permission';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import IPermission from '../../interfaces/IPermission';
import { PlusCircleOutlined } from '@ant-design/icons';

const { Column } = Table;
const { Option } = Select;

const Expenses = () => {

    const [addNewPermissionsVisible, setAddNewPermissionsVisible] = useState(false);
    const [adminPermissionsVisible, setAdminPermissionsVisible] = useState(false);
    const [permissionName, setPermissionName] = useState('');
    const permissions = useSelector((state: IStore) => state.permission.permissionsArray);
    const team = useSelector((state: IStore) => state.team.membersArray);

    const listTitle = 'hp-p1-body';

    const addNewPermissionsShow = () => {
        setAddNewPermissionsVisible(true);
    };

    const addNewPermissionsCancel = () => {
        setPermissionName('')
        setAddNewPermissionsVisible(false);
    };

    const adminPermissionsShow = () => {
        setAddNewPermissionsVisible(true);
    };

    const adminPermissionsCancel = () => {
        setAdminPermissionsVisible(false);
    };

    const createPermission = async () => {
        const { data, error } = await PermissionService.createPermission({ name: permissionName });
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Create', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Successfully created Permission', <notificationIcon.icon
            style={notificationIcon.style} />);

        addNewPermissionsCancel();
    };

    return (
        <div className='hp-profile-activity'>
            <Modal
                title='Add new permissions'
                width={500}
                centered
                visible={addNewPermissionsVisible}
                onCancel={addNewPermissionsCancel}
                footer={null}
                closeIcon={
                    <RiCloseFill className='remix-icon text-color-black-100' size={24} />
                }
            >
                <Form layout='vertical' name='basic' initialValues={{ remember: false }}>
                    <Col
                        span={24}
                        style={{ paddingBottom: '20px', paddingTop: '10px' }}
                        className='hp-profile-content-list hp-mt-8 hp-pb-sm-0 hp-pb-20'
                    >
                        <ul>
                            <li>
                                <span className={listTitle}>Permission Name</span>
                                <Input
                                    value={permissionName}
                                    style={{ width: 300 }}
                                    onChange={(e) => setPermissionName(e.target.value)} />
                            </li>
                        </ul>
                    </Col>
                    <Row style={{ justifyContent: 'space-between' }}>
                        <Col md={11} span={24} className='hp-pr-sm-0 hp-pr-12'>
                            <Button
                                block
                                type='primary'
                                htmlType='submit'
                                onClick={createPermission}
                            >
                                Save
                            </Button>
                        </Col>

                        <Col md={11} span={24} className='hp-mt-sm-12 hp-pl-sm-0 hp-pl-12'>
                            <Button block onClick={addNewPermissionsCancel}>
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal
                title='Basic Information'
                width={500}
                centered
                visible={adminPermissionsVisible}
                onCancel={adminPermissionsCancel}
                footer={null}
                closeIcon={
                    <RiCloseFill className='remix-icon text-color-black-100' size={24} />
                }
            >
                <Form layout='vertical' name='basic' initialValues={{ remember: true }}>
                    <Col
                        span={24}
                        className='hp-profile-content-list hp-mt-8 hp-pb-sm-0 hp-pb-120'
                    >
                        <ul>
                            <li>
                                <span className={listTitle}>Firm Name</span>
                                <Input style={{ width: 300 }} />
                            </li>
                            <li className='hp-mt-18'>

                                <span className={listTitle}>Industry</span>
                                <Select
                                    placeholder='Select'
                                    className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                                    style={{ width: 300, border: 'none' }}
                                >
                                    {
                                        Industries.map(industry => <option>{industry}</option>)
                                    }
                                </Select>
                            </li>
                            <li className='hp-mt-18'>
                                <div>
                                    <span className={listTitle}>Address</span>
                                    <div className={styles.row}>
                                        <Input placeholder='Street, avenue, etc.' type={'address'} />
                                        <Input placeholder='Suite, Unit, Apt, etc' type={'address'} />
                                    </div>
                                    <div className={styles.row}>
                                        <Input placeholder='City' type={'address'} />
                                        <Input placeholder='State/Province/Region' type={'address'} />
                                        <Input placeholder='Zip/Postal Code' type={'address'} />
                                    </div>
                                    <div className={styles.row}>
                                        <Select showSearch placeholder='Country' style={{ width: 300 }}>
                                            {
                                                Countries.map((country) =>
                                                    <Option key={country} value={country}>{country}</Option>)
                                            }
                                        </Select>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </Col>
                    <Row>
                        <Col md={12} span={24} className='hp-pr-sm-0 hp-pr-12'>
                            <Button
                                block
                                type='primary'
                                htmlType='submit'
                                onClick={adminPermissionsCancel}
                            >
                                Edit
                            </Button>
                        </Col>

                        <Col md={12} span={24} className='hp-mt-sm-12 hp-pl-sm-0 hp-pl-12'>
                            <Button block onClick={adminPermissionsCancel}>
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Row align='middle'>
                <Row style={{ display: 'flex', width: '100%' }}>
                    <Col style={{ display: 'flex', flex: 1 }}>
                        <h2>Permissions </h2>
                    </Col>
                    <Col>
                        <Button type='primary' onClick={addNewPermissionsShow} icon={<PlusCircleOutlined style={{ marginRight: 10 }} />}>
                            Add new permissions
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <p style={{ margin: 0 }}> Manage permissions for your team members. These permissions will
                        decide which employees can edit, create and manage ResourcePlan features. Define basic
                        information
                        and preferences for the firm.</p>
                </Row>

            </Row>
            {
                permissions.map((permission: IPermission) => <div key={permission.uuid}>
                        <Permission
                            permission={permission}
                            assignedUsers={team.filter(user => user.permissionId === permission.uuid)}
                            hasEditAccess={permission.name !== 'admin'}
                        />
                    </div>
                )
            }
            {/*<Permission name={'Project Manager'} assignedUsers={[]} />*/}
            {/*<Permission name={'Admin'} assignedUsers={[]} />*/}
            {/*<Permission name={'Admin'} assignedUsers={[]} />*/}
        </div>
    );
};
export default Expenses;
