import { APIUtil } from '../utils/ApiUtils';
import ApiURLs from '../constants/ApiURLs';
import { IPhase } from '../interfaces/IPhase';
import GenUtils from '../utils/GenUtils';
import { setPhases } from '../redux/actions/PhaseActions';
import _ from 'lodash';
import AllocationService from './AllocationService';

export default class PhaseService {
    static async createPhase(phase: IPhase) {
        const { data, error } = await APIUtil.makePostRequest(`${ApiURLs.CREATE_PHASE}`, GenUtils.removeEmptyKeysFromObject(phase));

        if (error) {
            GenUtils.log('createPhase', error);
            return { error };
        }
        await this.fetchPhasesForProject(phase.projectId);
        return { data };
    }

    static async fetchPhasesForProject(projectId: string) {
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.GET_PHASES_FOR_PROJECT}/${projectId}`);

        if (error) {
            GenUtils.log('fetchPhasesForProject', { error });
            return { error };
        }

        GenUtils.dispatch(setPhases(data, projectId));
        return { data };
    }

    static async fetchPhase(projectId: string) {
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.GET_PHASE}/${projectId}`);

        if (error) {
            GenUtils.log('fetchPhase', { error });
            return { error };
        }

        return { data };
    }

    static async updatePhase(phase: IPhase,uuid:string) {
        let _phase = { ...phase };
        if (GenUtils.isEmpty(phase.linkedPhaseId)) {
            // Remove the values you don't want
            _phase = _.omit(_phase, ['linkedPhaseId']);
        }
        _phase = _.omit(_phase, ['projectId', 'uuid', 'days','userUuid']);
        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.CREATE_PHASE}/${uuid}`, _phase);

        if (error) {
            GenUtils.log('createPhase', error);
            return { error };
        }
        await this.fetchPhasesForProject(phase.projectId);
        return { data };
    }

    static async deletePhase(phaseId: string, projectId: string) {
        const { data, error } = await APIUtil.makeDeleteRequest(`${ApiURLs.CREATE_PHASE}/${phaseId}`);

        if (error) {
            GenUtils.log('createPhase', error);
            return { error };
        }
        await this.fetchPhasesForProject(projectId);
        return { data };
    }

    static async setPhaseAllocating(phaseId) {
        // todo need to update a key for the phase isAllocationActive: true
        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.CREATE_PHASE}/${phaseId}`, {isAllocationActive: true});

        if (error) {
            GenUtils.log('createPhase', error);
            return { error };
        }
        await AllocationService.fetchAllocationData();
        return { data };
    }
}
