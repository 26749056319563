import { Card, Modal, Pagination } from 'antd';
import Button from '../button/Button';
import CommunicationTimeline from '../timeline/CommunicationTimeline';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import { RiCloseFill } from 'react-icons/ri';
import AddCommunication from '../modals/AddCommunication';
import React, { useEffect, useState } from 'react';
import { ECommunicationType } from '../../redux/reducers/CommunicationReducer';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import { PlusCircleOutlined } from '@ant-design/icons';
import { communicationSelector } from '../../redux/selectors/CommunicationSelector';
import CommunicationService from '../../services/CommunicationService';
import PermissionUtils from '../../utils/PermissionUtils';
import CommunicationModal from '../modals/NewCommunication';

const CommunicationCard = ({ type = ECommunicationType.other, typeId = null,clientId=null, by = null, selectedCategory = null }) => {
    const [showAddCommunication, setShowAddCommunication] = useState('');
    const { communicationsArray } = useSelector((state: IStore) => communicationSelector(state, type, typeId));
    communicationsArray.sort((a, b) => new Date(b.communicationAt).getTime() - new Date(a.communicationAt).getTime());
    
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        fetchCommunications();
    }, [typeId]);

    const fetchCommunications = async () => {
        const { data, error } = await CommunicationService.fetchCommunications(type, typeId);
        
        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('An error occurred', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const itemsPerPage = 5;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = communicationsArray?.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <>
            <Modal
                title="Add Communication"
                open={!GenUtils.isStringEmpty(showAddCommunication)}
                onCancel={() => setShowAddCommunication('')}
                footer={null}>
                <CommunicationModal
                    onSubmit={() => {
                        setShowAddCommunication('')
                        fetchCommunications()
                    }}
                    onClose={()=>{
                        setShowAddCommunication('')
                    }}
                    currType={type}
                    typeId={typeId}
                    client_Id={clientId}
                />
            </Modal>
            <Card
                title="Communications"
                extra={
                    (type === ECommunicationType.project && PermissionUtils.canManageProjectCommunication()) ||
                    (type === ECommunicationType.finance && PermissionUtils.canManageFinancialCommunication()) ||
                    (type === ECommunicationType.client && PermissionUtils.canManageFinancialCommunication()) ||
                    (type === ECommunicationType.task && PermissionUtils.canManageProjectCommunication()) ? (
                        <Button onClick={() => setShowAddCommunication(typeId)} text="New Communication" icon={<PlusCircleOutlined style={{marginRight:"8px"}}/>} />
                    ) : null
                }>
                {communicationsArray?.length > 0 ? (
                    <>
                        <CommunicationTimeline data={currentItems} fetchCommunications={fetchCommunications}/>
                        <Pagination
                            pageSize={itemsPerPage}
                            total={communicationsArray?.length}
                            current={currentPage}
                            onChange={handlePageChange}
                            showSizeChanger={false}
                        />
                    </>
                ) : (
                    'Start adding communications'
                )}
            </Card>
        </>
    );
}

export default CommunicationCard;
