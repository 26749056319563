import React from 'react';
import styles from './MemberAllotedHoursProgress.scss';
import classnames from 'classnames';

const MemberAllottedHoursProgress = ({ progressData }) => {
    const { logged, allotted, budget } = progressData;
    let remaining = budget ? budget - logged - allotted : 0;
    remaining = remaining > -1 ? remaining : 0;

    return (
        <div className={classnames(styles.container, styles.progress)}>
            <div className={classnames(styles.progress, styles.logged)} style={{ flex: logged }} />
            <div className={classnames(styles.progress, styles.allocated)} style={{ flex: allotted }}>
                <div className={classnames(styles.img)} />
            </div>
            <div className={classnames(styles.progress)} style={{ flex: remaining > -1 ? remaining : 0 }} />
        </div>
    );
};
export default MemberAllottedHoursProgress;
