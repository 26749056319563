import { useEffect, useState } from 'react';

import styles from '../../../components/table/custom/Table.scss';
import Table from '../../../components/table/custom/Table';
import FormatUtils from '../../../utils/FormatUtils';
import { InvoiceService } from '../../../services/InvoiceService';
import GenUtils, { ENotificationType } from '../../../utils/GenUtils';
import { useSelector } from 'react-redux';
import { IStore } from '../../../redux/store';
import Button from '../../../components/button/Button';
import Tag from '../../../components/tag/Tag';
import CustomInput from '../../../components/input/CustomInput';
import moment from 'moment';
import { MinusSquareFilled, PlusSquareFilled } from '@ant-design/icons';
import Colors from '../../../constants/Colors';

const ExpensesTab = ({ data, uuid, projectId, fromDate, toDate }: any) => {
    const [_data, setData] = useState(data);
    const { expensesArray, expenses } = useSelector((state: IStore) => state.expenses);
    const project = useSelector((state: IStore) => state.project.projects[projectId]);

    const updateDetail = (detailId, detail) => {
        setData(
            _data.map(d =>
                d.uuid === detailId
                    ? { ...d, ...detail }
                    : d
            )
        )
    }

    useEffect(() => {
        setData(data);
    }, [data]);

    const updateInvoiceDetail = async (detailId, detail) => {
        updateDetail(detailId, detail);
        const { data, error } = await InvoiceService.updateInvoiceDetails(detailId, detail);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to draft', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }

        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Saved as draft', <notificationIcon.icon
            style={notificationIcon.style} />);
    }

    const addExpense = async (expenseId) => {
        const { error } = await InvoiceService.updateInvoice(uuid, { expenseId });

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to Add', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
    }

    const removeExpense = async (expenseId) => {
        const { error } = await InvoiceService.deleteInvoiceDetail(expenseId);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to remove', error, <notificationIcon.icon
                style={notificationIcon.style} />);
            return;
        }
    }

    const expensesIncluded = [
        {
            name: 'Category',
            key: 'category',
            span: 3,
            render: (d) => GenUtils.capitalizeFirstLetter(expenses[d.expenseId]?.category?.name)
        },
        {
            name: 'Note',
            key: 'note',
            span: 5,
            render: (d) => GenUtils.capitalizeFirstLetter(expenses[d.expenseId]?.note) || '-'
        },
        {
            name: 'Date',
            key: 'date',
            span: 3,
            render: (d) => FormatUtils.getFormattedDateFromTimestamp(expenses[d.expenseId]?.date)
        },
        {
            name: 'Phase',
            key: 'phase',
            span: 3,
            render: (d) => expenses[d.expenseId]?.phase ? GenUtils.capitalizeInitials(expenses[d.expenseId]?.phase?.type?.name) : '-',
        },
        {
            name: 'Person',
            key: 'person',
            span: 3,
            render: (d) => GenUtils.capitalizeFirstLetter(expenses[d.expenseId]?.teamMember?.firstName),
        },
        {
            name: 'Amount',
            key: 'amount',
            span: 2,
            render: (d) => FormatUtils.getAmountInFormat(expenses[d.expenseId]?.totalAmount, true, 2, 0)
        },
        {
            name: 'Markup %',
            key: 'margin',
            span: 2,
            render: (d) => <CustomInput
                value={d.margin}
                suffix="%"
                style={{ width: '90%' }}
                onChange={(rate) => updateInvoiceDetail(d.uuid, { rate })}
                updateOnBlur
            />
        },
        {
            name: 'Amount',
            key: 'due',
            span: 2,
            render: (d) => FormatUtils.getAmountInFormat(d.due, true, 2, 0)
        },
        {
            name: '',
            key: 'remove',
            span: 1,
            render: (d) => <MinusSquareFilled
                onClick={() => removeExpense(d.uuid)}
                style={{
                    color: Colors.primary,
                    fontSize: 25,
                    display: 'flex',
                    justifyContent: 'center'
                }}
            />
        }
    ];

    const reportedProjectExpenses = [
        {
            name: 'Category',
            key: 'category',
            span: 4,
            render: (d) => GenUtils.capitalizeFirstLetter(d.category?.name)
        },
        {
            name: 'Status',
            key: 'status',
            span: 3,
            render: (d) => <div style={{ display: 'flex', height: '100%', alignItems: 'flex-start' }}>
                <Tag text={d?.status} color={GenUtils.getTagColorByStatus(d?.status)} />
            </div>
        },
        {
            name: 'Note',
            key: 'note',
            span: 4,
            render: (d) => GenUtils.capitalizeFirstLetter(d.note) || '-'
        },
        {
            name: 'Date',
            key: 'date',
            span: 4,
            render: (d) => FormatUtils.getFormattedDateFromTimestamp(d.date)
        },
        {
            name: 'Phase',
            key: 'phase',
            span: 4,
            render: (d) => GenUtils.capitalizeInitials(d.phase?.type?.name)
        },
        {
            name: 'Amount',
            key: 'amount',
            span: 4,
            render: (d) => FormatUtils.getAmountInFormat(d.totalAmount, true, 2, '0'),
        },
        {
            name: '',
            key: 'Add',
            span: 1,
            render: (d) => <PlusSquareFilled
                onClick={() => addExpense(d.uuid)}
                style={{
                    color: Colors.primary,
                    fontSize: 25,
                    display: 'flex',
                    justifyContent: 'center'
                }}
            />
        }
    ];

    return (
        <div style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
            <div>
                <div className={styles.title}>Included</div>
                <Table data={_data || []} columns={expensesIncluded} />
            </div>
            <div>
                <div className={styles.title}>Reported Project Expenses</div>
                <Table
                    data={data &&
                        expensesArray
                            .filter(e =>
                                (moment(e.date).isBetween(fromDate, toDate, 'day', '[]'))
                                && (e.projectId === projectId)
                                && !GenUtils.isUuid(e.invoiceId)
                                && (_data.indexOf(_data.find(d =>
                                    d.expenseId === e.uuid
                                )) < 0))
                        || []
                    }
                    columns={reportedProjectExpenses}
                />
            </div>
        </div>
    );
}

export default ExpensesTab;
