import { useEffect, useState } from 'react';
import { Divider, Table } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import classnames from 'classnames';
import { useSearchParams } from 'react-router-dom';

// components
import Tag from '../../tag/Tag';
import TeamAvatar from '../../avatar/TeamAvatar';
import { IStore } from '../../../redux/store';
import Colors from '../../../constants/Colors';
import GroupBar from '../../filterBars/GroupBar';
import Group from './Group';
import FiltersBarMultiselect from '../../filterBars/FiltersBarMultiselect';

// utils
import GenUtils, { getNestedData as getData, groupData } from '../../../utils/GenUtils';
import FormatUtils from '../../../utils/FormatUtils';

// styles
import styles from './GroupedTable.scss';

export enum EColumnType {
    tag = 'tag',
    text = 'text',
    link = 'link',
    number = 'number',
    boolean = 'boolean',
    amount = 'amount',
    date = 'date',
    percent = 'percent',
    hours = 'hours',
    project = 'project',
    member = 'member',
    phase = 'phase',
    client = 'client',
    status = 'status',
    month = 'month',
    remDays = 'remDays'
}

const GroupedTable = ({
    columns = [],
    data = [],
    filters = [],
    groups = [],
    scrollX = null,
    renderTable = null,
    pagination = true,
    renderCards = null,
    bordered = false as boolean
}) => {
    const [_columns, setColumns] = useState([]);
    const [filteredData, setFilteredData] = useState(data);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [sorter, setSorter] = useState(undefined);
    const [searchParams] = useSearchParams();
    const projects = useSelector((state: IStore) => state.project.projects);
    const phases = useSelector((state: IStore) => state.phases.projectPhases);
    const members = useSelector((state: IStore) => state.team.members);
    const invoices = useSelector((state: IStore) => state.invoices.invoices);
    const clients = useSelector((state: IStore) => state.client.clients);
    const { role } = useSelector((state: IStore) => state.options);

    useEffect(() => {
        setColumns(formatColumns(columns));
    }, [columns]);

    useEffect(() => {
        const groupKey = searchParams.get('group');
        const group = groups.find((g) => groupKey === GenUtils.getSimpleStringKey(g.title));
        if (group && group.dataIndex && group.dataIndex !== selectedGroup?.dataIndex) {
            setSelectedGroup(group);
        } else if (!group && selectedGroup?.dataIndex) {
            setSelectedGroup(null);
        }
    }, [searchParams]);

    const formatColumns = (cols) => {
        return cols.map((c) => ({
            ...c,
            width: getColumnWidth(c),
            render: (x, row) => renderByType(c, row),
            ...(getSorter(c) ? { sorter: getSorter(c) } : {})
        }));
    };

    const getColumnWidth = ({ type, width }) => {
        switch (type) {
            case EColumnType.member:
            case EColumnType.status:
            case EColumnType.project:
                return 250;
            case EColumnType.boolean:
            case EColumnType.phase:
            case EColumnType.link:
                return 100;
            case EColumnType.amount:
            case EColumnType.date:
            case EColumnType.percent:
            case EColumnType.tag:
            case EColumnType.text:
            case EColumnType.number:
            case EColumnType.hours:
                return 150;
            default:
                return width || 150;
        }
    };

    const getSorter = (col) => {
        const { dataIndex, type, sorter } = col;
        switch (type) {
            case EColumnType.tag:
            case EColumnType.link:
            case EColumnType.text:
                return (a, b) => getData(dataIndex, a).localeCompare(getData(dataIndex, b));
            case EColumnType.number:
                return (a, b) => getData(dataIndex, a) - getData(dataIndex, b);
            case EColumnType.boolean:
                return (a, b) => (getData(dataIndex, a) ? 1 : -1);
            case EColumnType.amount:
                return (a, b) => getData(dataIndex, a) - getData(dataIndex, b);
            case EColumnType.date:
                return (a, b) => (moment(getData(dataIndex, a)).isAfter(getData(dataIndex, b)) ? 1 : -1);
            case EColumnType.percent:
                return (a, b) => getData(dataIndex, a) - getData(dataIndex, b);
            case EColumnType.hours:
                return (a, b) => getData(dataIndex, a) - getData(dataIndex, b);
            case EColumnType.project:
                return (a, b) => projects[getData(dataIndex, a)]?.name?.localeCompare(projects[getData(dataIndex, b)]?.name);
            case EColumnType.member:
                return (a, b) =>
                    (members[getData(dataIndex, a)]?.firstName + members[getData(dataIndex, a)]?.lastName)?.localeCompare(
                        members[getData(dataIndex, b)]?.firstName + members[getData(dataIndex, b)]?.firstName
                    );
            case EColumnType.phase:
                return (a, b) =>
                    phases[a['projectId']] &&
                    phases[b['projectId']] &&
                    phases[a['projectId']][getData(dataIndex, a)]?.type?.name?.localeCompare(phases[b['projectId']][getData(dataIndex, b)]?.type?.name);
            default:
                return sorter ? sorter : false;
        }
    };

    const renderByType = (col, data) => {
        const { dataIndex, type, render, onClick } = col;
        switch (type) {
            case EColumnType.text:
                return GenUtils.capitalizeFirstLetter(getData(dataIndex, data));
            case EColumnType.link:
                return <a onClick={() => onClick(data)}>{GenUtils.capitalizeFirstLetter(getData(dataIndex, data))}</a>;
            case EColumnType.number:
                return FormatUtils.getAmountInFormat(getData(dataIndex, data), false, 0, 0);
            case EColumnType.amount:
                return FormatUtils.getAmountInFormat(getData(dataIndex, data), true, 2, 0);
            case EColumnType.date:
                return FormatUtils.getFormattedDateFromTimestamp(getData(dataIndex, data));
            case EColumnType.month:
                return FormatUtils.getMonthAndYearFromDateTime(getData(dataIndex, data));
            case EColumnType.percent:
                return FormatUtils.getAmountInFormat(getData(dataIndex, data), false, 2, 0) + '%';
            case EColumnType.hours:
                return FormatUtils.getAmountInFormat(getData(dataIndex, data), true, 2, 0) + 'Hrs';
            case EColumnType.project:
                return (
                    <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                        <Tag text={projects[getData(dataIndex, data)]?.serial} color={projects[getData(dataIndex, data)]?.color} />
                        {GenUtils.capitalizeInitials(projects[getData(dataIndex, data)]?.name || '-')}
                    </div>
                );
            case EColumnType.member:
                return (
                    <div style={{ display: 'flex', cursor: 'pointer' }}>
                        <TeamAvatar
                            image={members[getData(dataIndex, data)]?.profilePicUrl}
                            userId={getData(dataIndex, data)}
                            name={GenUtils.capitalizeInitials(
                                members[getData(dataIndex, data)]?.name ||
                                    members[getData(dataIndex, data)]?.firstName + ' ' + members[getData(dataIndex, data)]?.lastName
                            )}
                            role={role.find((r) => r.uuid === members[getData(dataIndex, data)]?.roleId)?.name}
                        />
                    </div>
                );
            case EColumnType.phase:
                return (
                    <div style={{ display: 'flex' }}>
                        {phases[data['projectId']] && phases[data['projectId']][getData(dataIndex, data)] ? (
                            <Tag text={phases[data['projectId']][getData(dataIndex, data)]?.type?.abbreviation} color={projects[data['projectId']]?.color} />
                        ) : (
                            '-'
                        )}
                    </div>
                );
            case EColumnType.tag:
                return (
                    <div style={{ display: 'flex' }}>
                        <Tag text={GenUtils.capitalizeInitials(getData(dataIndex, data))} color={GenUtils.getTagColorByStatus(getData(dataIndex, data))} />
                    </div>
                );
            case EColumnType.client:
                return (
                    <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                        {GenUtils.capitalizeInitials(clients[getData(dataIndex, data)]?.name || '-')}
                    </div>
                );
            default:
                if (render) return render(getData(dataIndex, data), data);
                return getData(dataIndex, data);
        }
    };
    const sortContent = (rows): any[] => {
        if (sorter?.order) {
            const { sorter: sorterFunction } = sorter.column;
            const sortedData = rows.sort(sorterFunction);
            return sorter?.order === 'ascend' ? sortedData : sortedData.reverse();
        }
        return rows;
    };

    const renderContent = (rows, isHeader = false) => {
        return renderTable && !isHeader ? (
            renderTable(_columns, rows)
        ) : (
            <Table
                bordered={bordered}
                className={classnames(styles.table, isHeader && styles.header)}
                style={{ border: '1px solid', borderColor: Colors.grayLighter, borderRadius: 7, overflow: 'none' }}
                dataSource={sortContent(rows)?.map((d) => ({ key: d.uuid, ...d }))}
                columns={_columns}
                scroll={{ x: scrollX }}
                pagination={pagination && rows?.length > 10 ? { defaultPageSize: 10 } : false}
                onChange={(pagination, filters, sorter, extra) => {
                    if (extra?.action === 'sort') {
                        setSorter(sorter);
                    }
                }}
                // onScroll={() => {}}
            />
        );
    };

    const renderGroupedContent = () => {
        if (groups.length && selectedGroup?.dataIndex) {
            const { dataIndex, type } = selectedGroup;
            const groupedData = groupData(dataIndex, filteredData);
            return (
                <div className={styles.groups}>
                    {renderContent([], true)}
                    {Object.keys({ ...groupedData })?.map((key) => (
                        <Group
                            key={key}
                            header={renderByType({ dataIndex, type }, groupedData[key][0])}
                            table={groupedData[key]?.length ? renderContent(groupedData[key]) : <div style={{ margin: 40 }}>No Data yet...</div>}
                        />
                    ))}
                </div>
            );
        } else {
            return renderContent(filteredData);
        }
    };

    return (
        <div className={styles.container}>
            {filters.length || groups.length ? (
                <FiltersBarMultiselect
                    filters={filters}
                    data={data}
                    colummns={_columns}
                    setFilteredData={setFilteredData}
                    renderRightOptions={() => <GroupBar groups={groups} />}
                />
            ) : null}
            {renderCards && renderCards(filteredData)}
            {renderGroupedContent()}
        </div>
    );
};

export default GroupedTable;
