import ApiURLs from '../constants/ApiURLs';
import { setTimeOffTypes } from '../redux/actions/TimeOffActions';
import { APIUtil } from '../utils/ApiUtils';
import GenUtils from '../utils/GenUtils';

export default class TimeOffService {
    static async createPTOType(pto) {
        const { data, error } = await APIUtil.makePostRequest(`${ApiURLs.GET_PTO}`, pto);
        if (error) {
            GenUtils.log('create PTO error', error);
            return { error };
        }
        await this.fetchPTOTypes();
        return { data };
    }

    static async fetchPTOTypes() {
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.GET_PTO}`);
        if (error) {
            GenUtils.log('create PTO error', error);
            return { error };
        }
        GenUtils.dispatch(setTimeOffTypes(data));
        return { data };
    }

    static async updatePTOType(ptoId, pto) {
        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.GET_PTO}/${ptoId}`, pto);
        if (error) {
            GenUtils.log('update PTO error', error);
            return { error };
        }
        await this.fetchPTOTypes();
        return { data };
    }

    static async deletePTOType(ptoId) {
        const { data, error } = await APIUtil.makeDeleteRequest(`${ApiURLs.GET_PTO}/${ptoId}`);
        if (error) {
            GenUtils.log('delete PTO error', error);
            return { error };
        }
        await this.fetchPTOTypes();
        return { data };
    }
}
