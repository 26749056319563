import { useEffect, useState } from 'react';
import classnames from 'classnames';

import styles from './Input.scss';

const CustomInput = ({
                         value,
                         type = 'number',
                         onChange = (e) => {
                         },
                         placeholder = '',
                         prefix = null,
                         suffix = null,
                         disabled = false,
                         style = {},
                         allowNegative = false,
                         updateOnBlur = false,
                         className = null,
                         ref = null
                     }) => {
    const [_value, setValue] = useState(type === 'number' ? (Number(value) || 0) : value);
    const [isActive, setActive] = useState(false);

    useEffect(() => {
        setValue(value);
    }, [value])

    const _onChange = (e) => {
        let v = e.target.value;
        if (!allowNegative && v < 0) {
            v = 0;
        }
        setValue(v);
        !updateOnBlur && onChange(v);
    };

    const onBlur = () => {
        if (_value === '') {
            setValue('..');
        }
        setActive(false);
        updateOnBlur && onChange(_value);
    };

    const onFocus = () => {
        setValue(_value => _value === '...' || _value === '-' ? '' : _value);
        setActive(true);
    };

    return (
        <div 
            className={classnames(
                styles.container, 
                isActive && styles.inputActive,
                disabled && styles.disabled,
            )} 
            style={{ width: '100%', ...style }}
        >
            {
                prefix && <div className={styles.prefix}>{prefix}</div>
            }
            <input
                ref={ref}
                className={classnames(styles.input, disabled && styles.disabled, className)}
                placeholder={placeholder}
                value={_value}
                type={type}
                onChange={_onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                disabled={disabled}
                onKeyDown={({ key }) => key === 'Enter' && onBlur()}
            />
            {
                !isActive && suffix && <div className={styles.suffix}>{suffix}</div>
            }
        </div>
    );
};

export default CustomInput;