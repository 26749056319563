import { Button, Col, Divider, Modal, Row, Table } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { RiCloseFill } from 'react-icons/ri';
import PermissionDetails from './PermissionDetails';
import GenUtils from '../../utils/GenUtils';

const columnClass = 'hp-p1-body hp-text-color-black-100 hp-text-color-dark-0 ';

const Permission = ({ permission, assignedUsers = [], hasEditAccess = false }) => {
    const [showPermissions, setShowPermissions] = useState(false);
    const [showAssignedUsers, setShowAssignedUsers] = useState(false);
    const dividerClass = 'hp-border-color-black-40 hp-border-color-dark-80';

    const columns = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
            width: 200,
            render: (text) => <span className={columnClass}>{text}</span>
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            width: 200,
            render: (text) => <span className={columnClass}>{text}</span>
        },
        {
            title: 'Role',
            dataIndex: 'role',
            width: 200,
            render: (role) => <span className={columnClass}>{role?.name || 'You'}</span>
        },
        {
            title: '',
            dataIndex: 'edit',
            // align: 'right',
            render: () => (
                <div>
                    {/* todo check this */}
                    {/*<a onClick={}>Edit</a>*/}
                    {/*<CloseOutlined style={{ color: 'IndianRed', marginLeft: 20 }} />*/}
                </div>
            )
        }
    ];

    return (
        <div key={permission.uuid}>
            <Modal
                title={`${hasEditAccess ? 'Edit' : 'View'} Permissions`}
                width={800}
                centered
                style={{ margin: '20px' }}
                visible={showPermissions}
                onCancel={() => setShowPermissions(false)}
                footer={null}
                closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}>
                <PermissionDetails permissionId={permission.uuid} onHide={() => setShowPermissions(false)} assignedUserLength={assignedUsers.length} />
            </Modal>
            <Divider className={dividerClass} />

            <Row align="middle" justify="space-between">
                <Col md={12} span={24}>
                    <h3>{GenUtils.capitalizeFirstLetter(permission.name)}</h3>
                    <p style={{ margin: 0 }}>
                        Assigned users : <span className="hp-p1-body hp-mb-0">{assignedUsers.length}</span>
                    </p>
                </Col>
                <Col md={12} span={24} className="hp-profile-action-btn hp-text-right">
                    <Button type="primary" ghost onClick={() => setShowPermissions(true)}>
                        {hasEditAccess ? 'Edit' : 'View'} Permissions
                    </Button>
                </Col>
            </Row>
            <Row align="middle" justify="space-between" style={{ marginLeft: -20 }}>
                {!showAssignedUsers ? (
                    <Col md={12} span={24}>
                        <Button
                            type="link"
                            className="hp-text-color-primary-1 hp-hover-text-color-primary-3"
                            icon={<DownOutlined />}
                            onClick={() => {
                                setShowAssignedUsers(true);
                            }}>
                            &nbsp;&nbsp;Show current assigned users
                        </Button>
                    </Col>
                ) : (
                    <Col md={12} span={24}>
                        <Button
                            type="link"
                            className="hp-text-color-primary-1 hp-hover-text-color-primary-3"
                            icon={<UpOutlined />}
                            onClick={() => {
                                setShowAssignedUsers(false);
                            }}>
                            &nbsp;&nbsp;Hide current assigned users
                        </Button>
                    </Col>
                )}
            </Row>
            {
                showAssignedUsers
                    ? <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: 1000,
                            marginBottom: 20
                        }}>
                        <Table
                            className='hp-overflow-scroll hp-border-1 hp-border-color-dark-80 hp-border-bottom-none hp-border-radius'
                            columns={columns}
                            dataSource={assignedUsers}
                            size='middle'
                            pagination={false}
                        />
                    </div>
                    : null
            }
        
        </div>
    );
};

export default Permission;
