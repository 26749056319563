export interface IPhaseState {
  projectPhases: {},
  phasesArrayForProject: {},
}

export const initialPhaseState: IPhaseState = {
  projectPhases: {},
  phasesArrayForProject: {},
};

const phaseReducer = (state: IPhaseState, action: { type: string, payload }) => {
  switch (action.type) {
    case 'SET_PHASES':
      return {
        ...state,
        projectPhases: {
          ...state.projectPhases,
          ...(action.payload.projectId ? { [action.payload.projectId]: action.payload.phases } : {})
        },
        phasesArrayForProject: {
          ...state.phasesArrayForProject,
          ...(action.payload.projectId ? { [action.payload.projectId]: action.payload.phasesArrayForProject } : {})
        }
      };
    case 'UPDATE_PHASE':
      // todo
      return {
        ...state
      };
    default:
      return state || initialPhaseState;
  }
};

export default phaseReducer;