export const showLoader = (calledFrom, text = '', isBlocking = false, type = 'SPINNER') => {
    return {
        type: 'SHOW_LOADER',
        payload: {
            isVisible: true,
            calledFrom,
            text,
            isBlocking,
            type
        },
    }
}

export const hideLoader = () => {
    return {
        type: 'HIDE_LOADER',
        payload: {
            isVisible: false,
            text: '',
            isBlocking: false,
            type: 'SPINNER'
        },
    }
}