import { Input, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import ColorPicker from './ColorPicker';
import React, { useState } from 'react';

const ColorPickerButton = ({ color, setColor=(c) => {}, hideInput = false, disable= false }) => {
    const [chooseColor, setChooseColor] = useState(false);

    return (
        <li style={{ alignItems: 'center', gap: '20px', display: 'flex' }}>
            <Modal
                title='Choose  Color'
                width={300}
                centered
                visible={chooseColor}
                onCancel={() => setChooseColor(false)}
                footer={null}
                closeIcon={
                    <CloseOutlined
                        style={{ color: 'IndianRed' }}
                        size={24} />
                }
            >
                <ColorPicker
                    color={color}
                    setColor={(color) => {
                        setColor(color);
                        setChooseColor(false);
                    }}
                    onCancel={() => setChooseColor(false)}
                />
            </Modal>
            <div
                onClick={() => !disable ? setChooseColor(true) : null}
                style={{
                    backgroundColor: color,
                    padding: '10px',
                    border: '1px solid black',
                    borderRadius: '5px',
                }}
            />
            {
                !hideInput && !disable && <Input style={{ width: 100 }}
                       placeholder={'#ffffff'}
                       value={color}
                       onChange={(e) => setColor(e.target.value)}
                />
            }
        </li>
    );
};

export default ColorPickerButton;