export interface IOptionState {
    [key: string]: IOption[];
}

export interface IOption {
    uuid?: string;
    name?: string;
    hourRate?: number;
    type?: EOptionType;
    abbreviation?: string;
    billType?: 'billable' | 'non_billable';
    feeType?: 'fixed' | 'hourly';
    description?: string;
    typeUuid?: string;
    unit?: string;
    amount?: number;
    marked?: boolean;
}

export const initialOptionState: IOptionState = {
    role: [],
    activity: [],
    project: [],
    phase: [],
    consultant: [],
    expense: [],
    vendor: [],
    payment_mode: [],
    invoice_terms: [],
    task: [],
    milestone: [],
    report: [],
};

export interface IRole {
    uuid?: string;
    name?: string;
    hourRate?: number;
}

export enum EOptionType {
    role = 'role',
    activity = 'activity',
    project = 'project',
    phase = 'phase',
    consultant = 'consultant',
    expense = 'expense',
    vendor = 'vendor',
    payment_mode = 'payment_mode',
    invoice_terms = 'invoice_terms',
    task = 'task',
    milestone = 'milestone',
    report = 'report'
}

const optionReducer = (state: IOptionState, action: { type: string, payload }) => {
    switch (action.type) {
        case 'SET_ALL_OPTIONS':
        return {
            ...state,
            ...action.payload,
        }
        case 'SET_OPTIONS':
            return {
                ...state,
                [action.payload.type]: action.payload.options,
            };
        case 'UPDATE_OPTION':
            return {
                ...state,
                [action.payload.type]: state[action.payload.type].map(o => o.uuid === action.payload.uuid ? {...o, ...action.payload} : o),
            };
        default:
            return state || initialOptionState;
    }
};

export default optionReducer;
