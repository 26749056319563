import { Upload, UploadProps } from "antd";
import Colors from "../../constants/Colors";
import FileService, { EFileCategory } from "../../services/FileService";
import { useEffect, useState } from "react";
import IFile from "../../interfaces/IFile";

const { Dragger } = Upload;

let _files = {};

const FileDragger = ({ files = [] as IFile[], setFiles = (f) => { }, setfileIds = (f) => { }, disabled = false, multiple = true, category = EFileCategory.Other }) => {
    useEffect(() => {
        _files = {};
    }, [])

    const props: UploadProps = {
        name: 'file',
        multiple,
        openFileDialogOnClick: true,
        customRequest: async ({ file, onSuccess, onError, onProgress }) => {
            onProgress({ percent: 10 });
            const { data, error } = await FileService.uploadFile(file, category);
            if (error) {
                onError({ cause: error, message: error, name: 'Upload Failed' }, { error });
                return;
            }
            const f = { ..._files };
            // @ts-ignore
            f[file?.uid] = data;
            _files = f;
            onSuccess(data);
        },
        onRemove(file) {
            const f = { ..._files };
            delete f[file.uid];
            _files = f;
        },
        onChange({ file, fileList }) {
            const uploadedFiles = fileList.reduce((fs, f) => f.status === 'done' ? [...fs, _files[f.uid]] : fs, []);
            setFiles(uploadedFiles);
            setfileIds(uploadedFiles.map((f: IFile) => f?.uuid));
        },
    };

    return (
        <Dragger
            style={{
                backgroundColor: Colors.grayLighter,
                border: '1px solid',
                borderColor: Colors.primary,
                height: '100px',
            }}
            disabled={disabled}
            {...props}
        >
            <p>Click or drag file to this area to upload</p>
        </Dragger>
    )
};

export default FileDragger;