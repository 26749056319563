import IFirm from '../../interfaces/IFirm';

export interface IFirmState extends IFirm {
    firmsArray?: IFirm[];
    firms?: {};
    firm: IFirm;
    uuid: string;
}

export const initialFirmState: IFirmState = {
    firmsArray: [],
    firms: {},
    firm: {},
    uuid: null,
}

export interface IRole {
    uuid: string;
    name: string;
    hourRate: number;
}

const firmReducer = (state: IFirmState, action: {type: string, payload}) => {
    switch (action.type) {
        case 'SET_FIRM':
            return {
                ...state,
                firm: action.payload.firmData ? action.payload.firmData : state.firms[action.payload],
                uuid: action.payload.firmData ? action.payload.firmData.uuid : action.payload,
            }
        case 'SET_FIRMS':
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state || initialFirmState;
    }
}

export default firmReducer;