import { Table } from 'antd';
import React from 'react';
import Button from '../../components/button/Button';
import FormatUtils from '../../utils/FormatUtils';

const AllowanceHistory = ({ data1 = [], onClose = () => {} }) => {
    const data = [
        {
            date: '21/03/2023',
            action: 'accrued',
            ptoType: 'vacation',
            oldValue: '20',
            newValue: '30',
            username: 'sachin kumar'
        },
        {
            date: '21/03/2023',
            action: 'accrued',
            ptoType: 'vacation',
            oldValue: '20',
            newValue: '30',
            username: 'sachin kumar'
        },
        {
            date: '21/03/2023',
            action: 'accrued',
            ptoType: 'vacation',
            oldValue: '20',
            newValue: '30',
            username: 'sachin kumar'
        },
        {
            date: '21/03/2023',
            action: 'accrued',
            ptoType: 'vacation',
            oldValue: '20',
            newValue: '30',
            username: 'sachin kumar'
        }
    ];
    const columns = [
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text) => FormatUtils.getFormattedDateFromTimestamp(text)
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action'
        },
        {
            title: 'PTO Type',
            dataIndex: 'ptoType',
            key: 'ptoType',
            render: (text, data) => data?.type?.name || ''
        },
        {
            title: 'Old Value',
            dataIndex: 'oldCount',
            key: 'oldCount'
        },
        {
            title: 'New Value',
            dataIndex: 'newCount',
            key: 'newCount'
        },
        {
            title: 'User Name',
            dataIndex: 'username',
            key: 'username',
            render: (text, data) => data.teamMember.firstName + ' ' + data.teamMember.lastName
        }
    ];
    console.log(data1);
    return (
        <div style={{ paddingTop: 20 }}>
            <Table columns={columns} dataSource={data1} pagination={false} />
            <Button text={'close'} onClick={onClose} />
        </div>
    );
};

export default AllowanceHistory;
