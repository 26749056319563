import { Card, Divider } from 'antd';
import ProjectTimeSheetHeader from './projectTimeSheet/header/ProjectTimeSheetHeader';
import styles from './ProjectTimeSheet.scss';
import Rows from './projectTimeSheet/row/Row';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import TimeSheetService from '../../services/TimeSheetService';
import { teamMemberId$ } from './shared/TimesheetTab';
import { useObservable } from '../../hooks/Observable';
import MomentUtils from '../../utils/MomentUtils';

const ProjectTimeSheet = ({ project, weekCount, weekStart = new Date(), updateTimeLogData, addActivityRow, onSave }) => {
    const timeLogs = useSelector((state: IStore) => state.timeLogs);
    const { timesheet: userTimesheet } = timeLogs;
    const teamMemberId = useObservable(teamMemberId$)

    const deleteTimesheet = async (phaseKey: string, activity: any) => {
        const ids = [] as string[]
        const f = userTimesheet.projects[project.uuid][phaseKey][activity]
        if (f !== undefined) {
            Object.keys(f).forEach(a => {
                ids.push(...f[a].map(b => b.uuid))
            })
        }
        console.log(ids)
        await TimeSheetService.deleteTimesheet(ids, teamMemberId, MomentUtils.getGmtDayStart(weekStart))
    }

    return (
        <>
            {project ? (
                <Card className={styles.projectTimesheetContainer}>
                    <ProjectTimeSheetHeader project={project} />
                    <Divider className={styles.marginPadding0} />
                    <Rows
                        project={project}
                        weekCount={weekCount}
                        weekStart={weekStart}
                        onSave={onSave}
                        deleteTimesheet={deleteTimesheet}
                        updateTimeLogData={updateTimeLogData}
                        addActivityRow={addActivityRow}
                    />
                </Card>
            ) : (
                <LoadingOutlined />
            )}
        </>
    );
};

export default ProjectTimeSheet;
