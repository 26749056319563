import ApiURLs from '../constants/ApiURLs';
import { setLeaves } from '../redux/actions/LeavesActions';
import { APIUtil } from '../utils/ApiUtils';
import GenUtils from '../utils/GenUtils';

export class LeavesService {
    static async addLeave(leave) {
        const { data, error } = await APIUtil.makePostRequest(`${ApiURLs.GET_LEAVES}`, leave);

        if (error) {
            GenUtils.log('addLeave', error);
            return { error };
        }
        await this.fetchLeaves(leave.teamMemberId);
        return { data };
    }

    static async fetchLeaves(teamMemberId) {
        const { data, error } = await APIUtil.makeGetRequest(`${ApiURLs.GET_LEAVES}?teamMemberId=${teamMemberId}`);

        if (error) {
            GenUtils.log('get leave', error);
            return { error };
        }
        GenUtils.dispatch(setLeaves(data));
        return { data };
    }
    static async updateLeave(leaveId, leave) {
        const { data, error } = await APIUtil.makePutRequest(`${ApiURLs.GET_LEAVES}/${leaveId}`, leave);

        if (error) {
            GenUtils.log('addLeave', error);
            return { error };
        }
        await this.fetchLeaves(leave.teamMemberId);
        return { data };
    }
    static async applyLeave(leave) {
        const { data, error } = await APIUtil.makePostRequest(`${ApiURLs.APPLY_LEAVE}`, leave);

        if (error) {
            GenUtils.log('addLeave', error);
            return { error };
        }
        await this.fetchLeaves(leave.teamMemberId);
        return { data };
    }
}
