import { useEffect, useState } from "react";
import { Modal } from "antd";

import FileDragger from "./FileDragger"
import { RiCloseFill } from "react-icons/ri";

import styles from './ImageUploadPopup.scss';
import { EFileCategory } from "../../services/FileService";

const ImageUploadPopup = ({onHide, onUpload, show, category = EFileCategory.Other}) => {
    const [fileIds, setFileIds] = useState([]);

    useEffect(() => {
        if (fileIds.length > 0) {
            onUpload(fileIds[0]);
            onHide();
        }
    }, [fileIds])

    return (
        <Modal
            title="Upload Image"
            width={400}
            centered
            open={show}
            onCancel={onHide}
            footer={null}
            closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}>
            <div className={styles.container}>
                <FileDragger setfileIds={setFileIds} category={category}/>
            </div>
        </Modal>
    )
}

export default ImageUploadPopup;