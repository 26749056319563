import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';

import { Alert, Button, Form, Input } from 'antd';
import CopyrightFooter from '../../components/footer/CopyrightFooter';

import recoverPasswordStyles from './RecoverPassword.scss';
import styles from '../form.scss';
import GenUtils, { ENotificationType } from '../../utils/GenUtils';
import AuthService from '../../services/AuthService';

const RecoverPassword = () => {
    const navigateTo = useNavigate();

    const [email, setEmail] = useState(null);
    const [success, setSuccess] = React.useState(false);

    const sendPasswordResetLink = async () => {
        const { error } = await AuthService.sendPasswordResetLink(email);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed to send', error, <notificationIcon.icon style={notificationIcon.style} />);
            setSuccess(false);
            return;
        }
        setSuccess(true);
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Password reset link sent', <notificationIcon.icon style={notificationIcon.style} />);
    };

    return (
        <div className={classnames(recoverPasswordStyles.section, recoverPasswordStyles.form)}>
            <h1 className={recoverPasswordStyles.title}>Recover Password</h1>
            <p className={recoverPasswordStyles.subtitle}>We’ll e-mail you instructions on how to reset your password.</p>
            {success ? (
                <Alert style={{ marginTop: 10 }} message="Password reset link has been sent successfully to your email!" type="success" showIcon />
            ) : (
                <Form
                    name="normal_login"
                    initialValues={{ remember: true }}
                    // onFinish={sendPasswordResetLink}
                >
                    <Form.Item label="Email" className={styles.label}></Form.Item>
                    <Input placeholder={'you@email.com'} className={styles.input} onChange={(e) => setEmail(e.target.value)} value={email} />
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className={recoverPasswordStyles.loginButton} block onClick={sendPasswordResetLink}>
                            Reset Password
                        </Button>
                    </Form.Item>
                </Form>
            )}
            <div className={recoverPasswordStyles.createText}>
                Go back to
                <span
                    className={recoverPasswordStyles.create}
                    onClick={() => {
                        navigateTo('/login');
                    }}>
                    Login
                </span>
            </div>
            <CopyrightFooter />
        </div>
    );
};

export default RecoverPassword;
