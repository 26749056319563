import { useEffect, useState } from 'react';
import { Button, Select, Popover, Input, Checkbox } from 'antd';
import styles from './FiltersBar.scss';
import { useSearchParams } from 'react-router-dom';
import DropdownFilter from './filters/DropdownFilter';
import DateRangeFilter from './filters/DateRangeFilter';
import { getNestedData as getData } from '../../utils/GenUtils';
import moment from 'moment';
import TeamMemberSelector from '../selector/TeamMemberSelector';
import GenUtils from '../../utils/GenUtils';
import { FilterOutlined } from '@ant-design/icons';
export enum EFilterTypes {
    dropdown = 'dropdown',
    dateRange = 'dateRange'
}
export enum EFilterCategory {
    simple = 'simple',
    advance = 'advance'
}

const FiltersBarMultiselect = ({ filters = [], colummns = [], data = [], setFilteredData = (d) => null, renderRightOptions = null }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [simpleFilter, setSimpleFilter] = useState([]);
    const [advFilter, setAdvFilter] = useState([]);

    const setFilters = (filterOptions: Array<Object>) => {
        const [advance, simple] = GenUtils.partitionArrayConditionally(filterOptions, (e) => e.category === EFilterCategory.advance);
        setSimpleFilter(simple);
        setAdvFilter(advance);
    };

    useEffect(() => {
        setFilters(filters);
    }, [filters]);

    useEffect(() => {
        applyFilters();
    }, [searchParams, data]);

    const applyFilters = () => {
        let _data = data;
        filters.forEach((filter) => {
            let dI = filter.dataIndex;
            if (filter.name === 'Invoice Status' && searchParams.getAll('odStatus').length > 0) dI = 'odStatus';
            let params = searchParams.getAll(dI);
            if (params.length > 0) {
                if (filter.type === EFilterTypes.dateRange) {
                    _data = _data.filter(
                        (d) => moment(params[0]).isSameOrBefore(getData(filter.dataIndex, d)) && moment(params[1]).isSameOrAfter(getData(filter.dataIndex, d))
                    );
                } else {
                    _data = _data.filter((d) => {
                        return params.indexOf(getData(dI, d)) > -1;
                    });
                }
            }
        });

        setFilteredData(_data);
    };

    const updateFilter = (filter, values = []) => {
        if (searchParams.has(filter)) {
            searchParams.delete(filter);
        }
        if (values.indexOf('all') < 0) {
            values.forEach((v) => searchParams.append(filter, v));
        }
        setSearchParams(searchParams);
    };

    const renderFilter = (filter) => {
        switch (filter.type) {
            // case 'text':
            // return (
            //     <Input
            //         className={styles.dropdown}
            //         onChange={(e) => filter.onChange(e.target.value)}
            //         placeholder={filter.name}
            //         key={filter.name}
            //     />
            // );
            // case 'number':
            // return (
            //     <Input
            //         type='number'
            //         className={styles.dropdown}
            //         onChange={(e) => filter.onChange(e.target.value)}
            //         placeholder={filter.name}
            //         key={filter.name}
            //     />
            // );
            case EFilterTypes.dateRange:
                return <DateRangeFilter filter={filter} data={data} updateFilter={updateFilter} />;
            case EFilterTypes.dropdown:
                return <DropdownFilter filter={filter} data={data} updateFilter={updateFilter} />;
            default:
                return (
                    <Select showSearch className={styles.dropdown} onChange={filter.onSelect} key={filter.name} placeholder={filter.name}>
                        {filter.options?.map((option) => (
                            <Select.Option key={option.uuid} value={option.uuid}>
                                {option.name}
                            </Select.Option>
                        ))}
                    </Select>
                );
        }
    };
    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <div className={styles.filtersContainer}>
                    {simpleFilter.map(renderFilter)}
                    {!!advFilter.length && (
                        <Popover
                            trigger={['click']}
                            className={styles.advFilterPopup}
                            content={advFilter.map((filter) => (
                                <div className={styles.advOptions}>
                                    <p>{filter.name}</p>
                                    {renderFilter(filter)}
                                </div>
                            ))}
                            overlayClassName={styles.advFilterPopup}
                            placement="bottom">
                            <Button className={styles.advFilterBtn} icon={<FilterOutlined className={styles.filterIcon} />}>
                                <span className={styles.advBtnLabel}>Filters</span>
                            </Button>
                        </Popover>
                    )}
                </div>
            </div>
            <div className={styles.right}>{renderRightOptions && renderRightOptions()}</div>
        </div>
    );
};

export default FiltersBarMultiselect;
