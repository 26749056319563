import { charts } from "./charts/charts";

export const config = {
    team_capacity_forecast: {
        Chart: charts.team_capacity_forecast,
    },
    project_profitability: {
        Chart: charts.project_profitability,
    },
    team_utilization: {
        Chart: charts.team_utilization,
    },
    team_effective_rates: {
        Chart: charts.team_effective_rates,
    },
    revenue_forecast: {
        Chart: charts.revenue_forecast,
    },
    revenue_vs_cost: {
        Chart: charts.revenue_vs_cost,
    },
    hiring_forecast:{
        Chart: charts.hiring_forecast
    },
    '': {
        table: false,
        Chart: null,
        rows: null,
        columns: null,
        filters: null,
        groups: null,
    }
}
