import React from 'react';
import GroupedTable, { EColumnType } from '../../../components/table/groupedTable/GroupedTable';
import { EFilterTypes } from '../../../components/filterBars/FiltersBarMultiselect';

import styles from './Charts.scss';

const TeamEffectiveRates = ({ data }) => {

    console.log(data);

    const filters = [
        {
            name: 'Date',
            dataIndex: 'date',
            type: EFilterTypes.dateRange
        },
        {
            name: 'Team Member',
            dataIndex: 'memberId',
            type: EFilterTypes.dropdown
        },
        {
            name: 'Role',
            dataIndex: 'role',
            type: EFilterTypes.dropdown
        },
        {
            name: 'Group By',
            dataIndex: 'groupBy',
            type: EFilterTypes.dropdown,
        },
    ];

    const columns = [
        {
            title: 'Team Member',
            dataIndex: 'member.name',
            type: 'text'
        },
        {
            title: 'Role',
            dataIndex: 'role',
            type: 'text'
        },
        {
            title: 'Default Billable Rate',
            dataIndex: 'defaultRate',
            type: EColumnType.amount,
            // render: (projectFee) => <span> {projectFee}</span>
        },
        {
            title: 'Average Billable Rate',
            dataIndex: 'averageRate',
            type: EColumnType.amount,
            // render: (projectFee) => <span> {projectFee}</span>
        },
        {
            title: 'Effective Billable Rate',
            dataIndex: 'effectiveRate',
            type: EColumnType.amount,
            // render: (projectFee) => <span> {projectFee}</span>
        },
        {
            title: 'Job Cost',
            dataIndex: 'jobCost',
            type: EColumnType.amount,
            // render: (projectFee) => <span> {projectFee}</span>
        },
        {
            title: 'Invoiced Services',
            dataIndex: 'invoicedServices',
            type: EColumnType.amount,
            // render: (projectFee) => <span> {projectFee}</span>
        },
        {
            title: 'Profit (%)',
            dataIndex: 'profit',
            type: EColumnType.percent,
        },
    ];

    return (
        <div className={styles.container}>
            <GroupedTable columns={columns} data={data} filters={filters} />
        </div>
    );
};

export default TeamEffectiveRates;
