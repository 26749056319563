import { Popover } from 'antd';
import MemberPhaseAllottedHoursDonut from '../../../../components/progress/MemberPhaseAllottedHoursDonut';
import Colors from '../../../../constants/Colors';
import FormatUtils from '../../../../utils/FormatUtils';
import GenUtils from '../../../../utils/GenUtils';
import HourPopOver from '../../HourPopOver';
import styles from './styles.module.scss';
import Tag from '../../../../components/tag/Tag';

const LeftTextCell = ({ phaseData, project, phaseMemberData }) => {
    let loggedHours = phaseMemberData?.loggedSecs / 3600 || 0;
    let estimatedHours = phaseMemberData?.allottedSecs / 3600 || 0;
    return (
        <div className={styles.leftColumn}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                    <Tag color={project.color} text={phaseData?.type?.abbreviation?.toUpperCase()} style={{ fontSize: 12 }} />
                    {GenUtils.capitalizeInitials(phaseData?.type.name)}
                </div>
                <div>{phaseMemberData?.role?.name || <div style={{ color: Colors.orangeRed }}>No Role Assigned</div>}</div>
            </div>

            {phaseMemberData && (
                <Popover
                    trigger={['hover']}
                    content={<HourPopOver logged={Number(FormatUtils.getAmountInFormat(loggedHours, false, 2)) || 0} estimated={estimatedHours || 0} />}>
                    <>
                        <MemberPhaseAllottedHoursDonut
                            data={[
                                {
                                    hours: phaseMemberData?.loggedSecs,
                                    color: phaseMemberData?.loggedSecs > (phaseMemberData?.allottedSecs || 0) ? 'red' : 'blue'
                                }
                            ]}
                            budgeted={phaseMemberData?.allottedSecs || 0}
                        />
                    </>
                </Popover>
            )}
        </div>
    );
};

export default LeftTextCell;
