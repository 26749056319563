import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Modal, Card, Steps, Switch } from 'antd';

// utils
import NavigationUtils from '../../../utils/NavigationUtils';

// components
import HeaderSecondary from '../../../components/header/HeaderSecondary';
import Button from '../../../components/button/Button';
import Invoice from '../Invoice';
import { EInvoiceStatus } from '../../../interfaces/IInvoice';

// styles
import styles from './InvoicePreview.scss';
import { CloseOutlined, CopyOutlined, DownloadOutlined, MailOutlined, PrinterOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import GenUtils, { ENotificationType } from '../../../utils/GenUtils';
import { useSelector } from 'react-redux';
import { IStore } from '../../../redux/store';
import Tag from '../../../components/tag/Tag';
import PermissionUtils from '../../../utils/PermissionUtils';
import { InvoiceService } from '../../../services/InvoiceService';
import ActivityTimeline from '../../../components/timeline/ActivityTimeline';
import Colors from '../../../constants/Colors';
import RecordPayment from '../RecordPayment';

const InvoicePreview = () => {
    const [showPlanned, setShowPlanned] = useState(true);
    const [invoiceId, setInvoiceId] = useState(null);
    const [recordingPayment, setRecordingPayment] = useState(null);

    const invoice = useSelector((state: IStore) => state.invoices?.invoices[invoiceId]);
    const projectsMap = useSelector((state: IStore) => state.project.projects);
    // Location
    const { pathname, search } = useLocation();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        setInvoiceId(searchParams.get('invoiceId'));
    }, [searchParams, pathname]);

    const next = async () => {
        let status = EInvoiceStatus.Draft;
        if (invoice?.status === EInvoiceStatus.Draft) {
            status = projectsMap[invoice.projectId]?.invoiceApproval ? EInvoiceStatus.Submitted : EInvoiceStatus.Pending;
        } else if (invoice?.status === EInvoiceStatus.Submitted) {
            status = PermissionUtils.canApproveInvoicesFor() !== 'none' ? EInvoiceStatus.Approved : null;
        } else if (invoice?.status === EInvoiceStatus.Approved) {
            status = EInvoiceStatus.Pending;
        } else if (invoice?.status === EInvoiceStatus.Pending || invoice?.status === EInvoiceStatus.PartiallyPaid) {
            return setRecordingPayment(true);
        }
        // NavigationUtils.navigateTo(pathname.replace('design', 'preview') + search);

        if (!status) {
            return;
        }

        const { data, error } = await InvoiceService.updateInvoiceStatus(invoiceId, status);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification('Failed', error, <notificationIcon.icon style={notificationIcon.style} />);
            return;
        }

        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Success', 'Added Contact successfully', <notificationIcon.icon style={notificationIcon.style} />);
    };

    const renderButtonOption = ({ name, key, onChange, icon, isDisabled }: any) => {
        return (
            <button className={classnames(styles.option, styles.button)} key={key} onClick={(v) => onChange(v)} disabled={isDisabled}>
                {icon}
                {name}
            </button>
        );
    };

    const renderSwitchOption = ({ name, key, value, onChange }: any) => {
        return (
            <div className={styles.option} key={key}>
                <Switch checked={value} onChange={(v) => onChange(v)} />
                {name}
            </div>
        );
    };

    const SendToClient = () => {
        // NavigationUtils.navigateTo(pathname.replace('preview', 'sendMail') + search);
    };

    const CopyLink = () => {
        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification('Sharable Link copied to clipboard', '', <notificationIcon.icon style={notificationIcon.style} />);
    };

    const PrintInvoice = () => {
        downloadPdf();
    };

    const downloadPdf = () => {
        const printContents = document.getElementById('invoice').innerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
    };

    const shareAndExportOptions = [
        { head: 'Share', key: 'heading1' },
        { name: 'Send to Client', key: 'send', value: 'button', onChange: SendToClient, icon: <MailOutlined />, isDisabled: true },
        { name: 'Copy Link', key: 'copy', value: 'button', onChange: CopyLink, icon: <CopyOutlined /> },
        { name: 'Print', key: 'print', value: 'button', onChange: PrintInvoice, icon: <PrinterOutlined /> },
        { name: 'Download PDF', key: 'download', value: 'button', onChange: downloadPdf, icon: <DownloadOutlined /> }
        // { head: 'Export', key: 'heading2' },
        // { name: 'Quickbooks online', key: 'showBilled', value: 'button', onChange: setShowPlanned, icon: <DownloadOutlined /> },
        // { name: 'Quickbooks Desktop', key: 'showBilled', value: 'button', onChange: setShowPlanned, icon: <DownloadOutlined /> },
        // { name: 'Xero', key: 'showBilled', value: 'button', onChange: setShowPlanned, icon: <DownloadOutlined /> }
    ];

    const reminderOptions = [
        { head: 'Reminder', key: 'heading1' },
        { name: 'Send Now', key: 'showPlanned', value: 'button', onChange: setShowPlanned, icon: <MailOutlined /> }
    ];

    const settingsOptions = [
        { head: '', key: 'heading1' },
        { name: 'Apply interest after Due Date', key: 'showPlanned', value: showPlanned, onChange: setShowPlanned }
    ];

    const renderOptions = (options) => {
        return options.map((option) => {
            if (option.head) {
                return (
                    <b className={styles.head} key={option.key}>
                        {option.head}:
                    </b>
                );
            } else if (typeof option.value === 'boolean') {
                return renderSwitchOption(option);
            } else {
                return renderButtonOption(option);
            }
        });
    };

    const getText = () => {
        if (invoice?.status === EInvoiceStatus.Draft) {
            return projectsMap[invoice.projectId]?.invoiceApproval ? 'Submit for Approval' : 'Submit Invoice';
        } else if (invoice?.status === EInvoiceStatus.Submitted) {
            return PermissionUtils.canApproveInvoicesFor() !== 'none' ? 'Approve' : false;
        } else if (invoice?.status === EInvoiceStatus.Approved) {
            return 'Mark as Pending';
        } else if (invoice?.status === EInvoiceStatus.Pending || invoice?.status === EInvoiceStatus.PartiallyPaid) {
            return 'Record Payment';
        }
        return false;
    };

    return (
        <div className={styles.container}>
            <HeaderSecondary
                text={
                    <div style={{ display: 'flex', gap: 10 }}>
                        Invoice #{invoice?.serial} for{' '}
                        <div style={{ flex: 0, fontSize: 14 }}>
                            <Tag text={projectsMap[invoice?.projectId]?.serial} color={projectsMap[invoice?.projectId]?.color} />
                        </div>{' '}
                        {projectsMap[invoice?.projectId]?.name} :
                        <Tag style={{ fontSize: 14 }} text={invoice?.status} color={GenUtils.getTagColorByStatus(invoice?.status)} />
                    </div>
                }
                renderAction={() => getText() && <Button text={getText()} onClick={next} />}
            />
            {
                !(invoice && (invoice.status === "draft" || invoice.status === "submitted")) ?
                <></>:
                <Steps
                size='small'
                current={2}
                className={styles.steps}
                items={[{ title: 'Builder' }, { title: 'Design' }, { title: 'Preview' }]}
                onChange={(n) => {
                    if (n === 0) {
                        NavigationUtils.navigateTo(pathname.replace('preview', 'build') + search);
                    } else if (n === 1) {
                        NavigationUtils.navigateTo(pathname.replace('preview', 'design') + search);
                    }
                }}
            />
            }
            <div className={styles.content}>
                <div className={styles.left}>
                    {/*<div className={styles.optionsContainer}>*/}
                    {/*    <SharingOptions />*/}
                    {/*</div>*/}
                    <div className={styles.optionsContainer}>{renderOptions(shareAndExportOptions)}</div>
                    <div className={styles.optionsContainer}>{renderOptions(reminderOptions)}</div>
                    {/*<div className={styles.optionsContainer}>*/}
                    {/*    {renderOptions(settingsOptions)}*/}
                    {/*</div>*/}
                    <Card title="Activity">
                        <ActivityTimeline data={invoice?.history?.filter((h) => h.action !== 'updated') || []} />
                    </Card>
                </div>
                <div className={styles.right} id={'invoice'}>
                    <Invoice
                    // invoiceData={{}}
                    // options={
                    //     invoiceOptions
                    //         .reduce(
                    //             (acc, v) => {
                    //                 if (v.value) acc[v.key] = v.value;
                    //                 return acc;
                    //             }, {}
                    //         )}
                    />
                </div>
            </div>
            <Modal
                title={`Payment for ${invoice?.serial}`}
                width={500}
                centered
                footer={null}
                open={recordingPayment}
                onCancel={() => setRecordingPayment(null)}
                closeIcon={<CloseOutlined style={{ color: Colors.red }} className="remix-icon text-color-black-100" size={24} />}>
                <RecordPayment onHide={() => setRecordingPayment(null)} invoice={invoice} />
            </Modal>
        </div>
    );
};

export default InvoicePreview;
