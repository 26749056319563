import { Route, Routes } from 'react-router-dom';
import Dashboard from '../../pages/Dashboard';
import ErrorPage from '../../pages/Error';
import AllocateWorkPage from '../../pages/allocate/AllocateWorkPage';
import AllClientsPage from '../../pages/client/AllClientsPage';
import ClientPage from '../../pages/client/ClientPage';
import CommunicationPage from '../../pages/communication/CommunicationPage';
import ExpensesPage from '../../pages/expenses/ExpensesPage';
import FirmExpense from '../../pages/expenses/FirmExpense';
import ProjectExpense from '../../pages/expenses/ProjectExpense';
import FirmInfo from '../../pages/firmSettings/FirmInfo';
import FirmIntegration from '../../pages/firmSettings/FirmIntegration';
import FirmSettingsPage from '../../pages/firmSettings/FirmSettingsPage';
import Permissions from '../../pages/firmSettings/Permissions';
import Invoices from '../../pages/firmSettings/Invoices';
import Expenses from '../../pages/firmSettings/Expenses';
import Projects from '../../pages/firmSettings/Projects';
import Roles from '../../pages/firmSettings/Roles';
import TimeSheets from '../../pages/firmSettings/TimeSheets';
import ViewPermissions from '../../pages/firmSettings/ViewPermissions';
import InvoiceBuilder from '../../pages/invoices/InvoiceBuilder';
import InvoiceDesigner from '../../pages/invoices/InvoiceDesigner';
import InvoicesPage from '../../pages/invoices/InvoicesPage';
import AllInvoices from '../../pages/invoices/overview/AllInvoices';
import Payments from '../../pages/invoices/overview/Payments';
import Retainers from '../../pages/invoices/overview/Retainers';
import UnBilled from '../../pages/invoices/overview/UnBilled';
import InvoicePreview from '../../pages/invoices/preview/InvoicePreview';
import Access from '../../pages/profile/Access';
import ActivityMonitor from '../../pages/profile/ActivityMonitor';
import Compensation from '../../pages/profile/Compensation';
import PersonalInfo from '../../pages/profile/PersonalInfo';
import ProfileYoda from '../../pages/profile/ProfileYoda';
import Reports from '../../pages/profile/Reports';
import SecuritySettings from '../../pages/profile/SecuritySettings';
import AllProjectPage from '../../pages/project/AllProjectPage';
import EditProjectPage from '../../pages/project/EditProjectPage';
import Milestone from '../../pages/project/Milestone';
import ProjectDetailPage from '../../pages/project/ProjectDetailPage';
import ProjectOverview from '../../pages/project/ProjectOverview';
import TaskDetail from '../../pages/project/TaskDetail';
import AllReportsPage from '../../pages/reports/AllReportsPage';
import ReportPage from '../../pages/reports/ReportPage';
import AllTeamsPage from '../../pages/team/AllTeamsPage';
import AuditTimeSheetTab from '../../pages/timesheet/AuditTimeSheetTab';
import SubmissionsTab from '../../pages/timesheet/SubmissionsTab';
import TimeEntryTab from '../../pages/timesheet/TimeEntryTab';
import TimeSheet from '../../pages/timesheet/TimeSheet';
import TimeSheetTab from '../../pages/timesheet/TimeSheetTab';
import PermissionUtils from '../../utils/PermissionUtils';
import UnauthorizedPage from '../../pages/unauthorized/UnauthorizedPage';
import ProjectTask from '../../pages/project/projectTask';
import ProjectHead from '../../pages/project/projectHead';
import ProjectFinance from '../../pages/project/projectFinance';
import ProjectMilestone from '../../pages/project/projectMilestone';
import Task from '../../pages/project/Task';


function isPageAllowed(check,comp){
  if(check){
    return comp
  }
  return <UnauthorizedPage/>
}

export default function AppRoutes() {
    return (
        <Routes>
            <Route path="/:firmId/dashboard" element={<Dashboard />} />
            <Route path="/:firmId/timesheet" element={<TimeSheet />}>
                <Route path="/:firmId/timesheet" element={<TimeSheetTab />} />
                <Route path="/:firmId/timesheet/timesheets" element={<TimeSheetTab />} />
                <Route path="/:firmId/timesheet/submissions" element={<SubmissionsTab />} />
                <Route path="/:firmId/timesheet/entries" element={<TimeEntryTab />} />
                <Route path="/:firmId/timesheet/audits" element={isPageAllowed(PermissionUtils.canViewTimeSheetAuditTab(),<AuditTimeSheetTab />)} />
            </Route>
            <Route path="/:firmId/expenses" element={isPageAllowed(PermissionUtils.canSeeExpensesTab(),<ExpensesPage />)}>
                <Route path="/:firmId/expenses" element={<ProjectExpense />} />
                <Route path="/:firmId/expenses/project" element={<ProjectExpense />} />
                <Route path="/:firmId/expenses/firm" element={<FirmExpense />} />
            </Route>
            <Route path="/:firmId/communications" element={<CommunicationPage />} />
            <Route path="/:firmId/allocate" element={isPageAllowed(PermissionUtils.canSeeAllocationTab(),<AllocateWorkPage />)} />
            <Route path="/:firmId/projects" element={<AllProjectPage />}>
                <Route path="/:firmId/projects/" element={<ProjectOverview />} />
                <Route path="/:firmId/projects/overview" element={<ProjectOverview />} />
                <Route path="/:firmId/projects/task" element={<Task />} />
                <Route path="/:firmId/projects/milestone" element={<Milestone />} />
            </Route>
            <Route path="/:firmId/task/:taskId" element={<TaskDetail />} />
            <Route path='/:firmId/projects/:projectId' element={isPageAllowed(PermissionUtils.canViewFinancesInProjects(),<ProjectHead/>)} >
                <Route path='/:firmId/projects/:projectId' element={isPageAllowed(PermissionUtils.canViewFinancesInProjects(),<ProjectDetailPage/>)} />
                <Route path='/:firmId/projects/:projectId/overview' element={<ProjectDetailPage />} />
                <Route path='/:firmId/projects/:projectId/finance' element={<ProjectFinance/>}/>
                <Route path='/:firmId/projects/:projectId/task' element={<ProjectTask/>}/>\
                <Route path='/:firmId/projects/:projectId/milestone' element={<ProjectMilestone/>}/>
            </Route>
            <Route path="/:firmId/projects/:projectId/edit" element={<EditProjectPage />} />
            <Route path="/:firmId/clients" element={isPageAllowed(PermissionUtils.canSeeClientTab(),<AllClientsPage />)} />
            <Route path="/:firmId/clients/:clientId" element={isPageAllowed(PermissionUtils.canSeeClientTab(),<ClientPage />)} />
            <Route path="/:firmId/invoices" element={isPageAllowed(PermissionUtils.canSeeInvoicesTab(),<InvoicesPage />)}>
                <Route path="/:firmId/invoices/" element={<AllInvoices />} />
                <Route path="/:firmId/invoices/all" element={<AllInvoices />} />
                <Route path="/:firmId/invoices/un-billed" element={<UnBilled />} />
                <Route path="/:firmId/invoices/retainers" element={<Retainers />} />
                <Route path="/:firmId/invoices/payments" element={<Payments />} />
                <Route path="/:firmId/invoices/build/:invoiceId" element={<InvoiceBuilder />} />
                <Route path="/:firmId/invoices/design/:invoiceId" element={<InvoiceDesigner />} />
                <Route path="/:firmId/invoices/preview/:invoiceId" element={<InvoicePreview />} />
            </Route>
            <Route path="/:firmId/team" element={isPageAllowed(PermissionUtils.canSeeTeamTab(),<AllTeamsPage />)} />
            <Route path="/:firmId/team" element={ isPageAllowed(PermissionUtils.canSeeTeamTab(),<ProfileYoda />)}>
                <Route path="*" element={<PersonalInfo />} />
                <Route path="/:firmId/team/:userId" element={<PersonalInfo />} />
                <Route path="/:firmId/team/:userId/info" element={<PersonalInfo />} />
                <Route path="/:firmId/team/:userId/access" element={<Access />} />
                <Route path="/:firmId/team/:userId/compensation" element={<Compensation />} />
                <Route path="/:firmId/team/:userId/reports" element={<Reports />} />
                <Route path="/:firmId/team/:userId/activity" element={<ActivityMonitor />} />
                <Route path="/:firmId/team/:userId/security" element={<SecuritySettings />} />
            </Route>
            <Route path="/:firmId/firm-settings" element={isPageAllowed(PermissionUtils.canSeeFirmSettingsTab(),<FirmSettingsPage />)}>
                <Route path="/:firmId/firm-settings" element={<FirmInfo />} />
                <Route path="/:firmId/firm-settings/firm-info" element={<FirmInfo />} />
                <Route path="/:firmId/firm-settings/roles" element={<Roles />} />
                <Route path="/:firmId/firm-settings/invoices" element={<Invoices />} />
                <Route path="/:firmId/firm-settings/time-sheets" element={<TimeSheets />} />
                <Route path="/:firmId/firm-settings/permissions" element={<Permissions />}>
                    <Route path="/:firmId/firm-settings/permissions/view-permissions" element={<ViewPermissions />} />
                </Route>
                <Route path="/:firmId/firm-settings/projects" element={<Projects />} />
                <Route path="/:firmId/firm-settings/expenses" element={<Expenses />} />
                <Route path="/:firmId/firm-settings/integrations" element={<FirmIntegration />} />
            </Route>
            <Route path="/:firmId/reports" element={<AllReportsPage />} />
            <Route path="/:firmId/reports/:reportId" element={<ReportPage />} />
            <Route path="*" element={<ErrorPage />} />
        </Routes>
    );
}
