import React, { useEffect, useRef, useState } from 'react';
import Avvvatars from 'avvvatars-react';
import { Button, Col, Dropdown, Layout, MenuProps, Row } from 'antd';
import { RiCloseLine } from 'react-icons/ri';
import { SearchNormal1 } from 'iconsax-react';
import { motion } from 'framer-motion';

import HeaderSearch from './HeaderSearch';
import {
    BookOutlined,
    BugOutlined,
    CloudDownloadOutlined,
    CommentOutlined,
    DollarCircleOutlined,
    LogoutOutlined,
    PlusOutlined,
    TeamOutlined,
    UserAddOutlined
} from '@ant-design/icons';
import ProfileMenuItem from './ProfileMenuItem';
import styles from './Header.scss';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import NavigationUtils from '../../utils/NavigationUtils';
import ProfileMenuItemComingSoon from './ProfileMenuItemComingSoon';
import AppUtils from '../../utils/AppUtils';
import { getUserDetails } from '../../redux/selectors/UserSelector';
import GenUtils from '../../utils/GenUtils';
import PermissionUtils from '../../utils/PermissionUtils';

const AntDHeader = Layout?.Header;

const Header = () => {
    const user = useSelector((state: IStore) => getUserDetails(state));
    const firmId = useSelector((state: IStore) => state?.firm?.uuid);
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [searchHeader, setSearchHeader] = useState(false);
    const [searchActive, setSearchActive] = useState(false);

    useEffect(() => {
        if (!GenUtils.isEmpty(user)) {
            setTitle(''
                // `Welcome${user.firstName ? ',' : ''} ${user.firstName || ''} ${user.lastName || ''}!!`
            );
            setSubTitle('' +
                // 'Let’s get you right into work.' +
                '');
        }
    }, [user?.firstName]);

    // Focus
    const inputFocusRef = useRef(null);
    const inputFocusProp = {
        ref: inputFocusRef
    };

    const [addMenu, setAddMenu] = React.useState([
        {
            key: '1',
            label: (
                <ProfileMenuItem
                    text="Expense"
                    onClick={() => NavigationUtils.navigateTo(`${firmId}/expenses`, { state: { openCreateModal: true } })}
                    icon={<PlusOutlined style={{ color: 'blue' }} />}
                />
            )
        },
        {
            key: '2',
            label: (
                <ProfileMenuItem
                    text="Team Member"
                    onClick={() => NavigationUtils.navigateTo(`${firmId}/team`, { state: { openAddMember: true } })}
                    icon={<PlusOutlined style={{ color: 'blue' }} />}
                />
            )
        },
        {
            key: '3',
            label: (
                <ProfileMenuItem
                    text="Communication"
                    onClick={() => {
                        alert('you clicked communication');
                    }}
                    icon={<PlusOutlined style={{ color: 'blue' }} />}
                />
            )
        },
        {
            key: '4',
            label: (
                <ProfileMenuItem
                    text="Project"
                    onClick={() => NavigationUtils.navigateTo(`${firmId}/projects`, { state: { openAddProject: true } })}
                    icon={<PlusOutlined style={{ color: 'blue' }} />}
                />
            )
        },
        {
            key: '5',
            label: (
                <ProfileMenuItem
                    text="Client"
                    onClick={() => NavigationUtils.navigateTo(`${firmId}/clients`, { state: { openAddClient: true } })}
                    icon={<PlusOutlined style={{ color: 'blue' }} />}
                />
            )
        },
        {
            key: '6',
            label: (
                <ProfileMenuItem
                    text="Invoice"
                    onClick={() => NavigationUtils.navigateTo(`${firmId}/invoices`, { state: { openAddInvoice: true } })}
                    icon={<PlusOutlined style={{ color: 'blue' }} />}
                />
            )
        }
    ]);

    React.useMemo(() => {
        let removeable = [];
        if (!PermissionUtils.canSeeTeamTab()) {
            removeable.push('2');
        }
        if (!PermissionUtils.canCreateProjects()) {
            removeable.push('4');
        }
        if (!PermissionUtils.canCreateClient()) {
            removeable.push('5');
        }
        if (!PermissionUtils.canCreateInvoices()) {
            removeable.push('6');
        }
        setAddMenu((s) => s.filter((a) => !removeable.includes(a.key)));
    }, []);


    const profileMenu: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div
                    className={styles.nameContainer}
                    onClick={() => {
                        NavigationUtils.navigateTo(`/${firmId}/team/${user.uuid}/info`);
                    }}>
                    <div className={styles.name}>
                        {user.firstName} {user.lastName}
                    </div>
                    <div className={styles.viewProfile}>View Profile</div>
                </div>
            )
        },
        {
            type: 'divider'
        },
        {
            key: '2',
            label: <ProfileMenuItemComingSoon text="Community" link="https://www.google.com" icon={<TeamOutlined />} />
        },
        {
            key: '3',
            label: <ProfileMenuItemComingSoon text="Help Center" link="https://www.google.com" icon={<BookOutlined />} />
        },
        {
            key: '4',
            label: <ProfileMenuItemComingSoon text="Latest Updates" link="https://www.google.com" icon={<CloudDownloadOutlined />} />
        },
        {
            type: 'divider'
        },
        {
            key: '5',
            label: <ProfileMenuItem text="Request a feature" link="https://www.google.com" icon={<CommentOutlined />} />
        },
        {
            key: '6',
            label: <ProfileMenuItem text="Report a Bug" link="https://www.google.com" icon={<BugOutlined />} />
        },
        {
            type: 'divider'
        },
        {
            key: '7',
            label: <ProfileMenuItem text="Log Out" onClick={AppUtils.logOut} icon={<LogoutOutlined />} />
        }
    ];

    // Search Active
    setTimeout(() => setSearchActive(searchHeader), 100);

    const searchClick = () => {
        setSearchHeader(true);

        setTimeout(() => {
            inputFocusRef.current.focus({
                cursor: 'start'
            });
        }, 200);
    };

    // Children
    const headerChildren = () => {
        return (
            <Row className="hp-w-100 hp-position-relative gap-15" align="middle" justify="space-between">
                {searchHeader ? (
                    <Col flex="1" className={`hp-mr-md-0 hp-mr-16 hp-pr-0 hp-header-search ${searchActive && 'hp-header-search-active'}`}>
                        <HeaderSearch inputFocusProp={inputFocusProp} setSearchHeader={setSearchHeader} />
                    </Col>
                ) : (
                    // <HeaderText />
                    <div className={styles.headingContainer}>
                        <div className={styles.title}>{title}</div>
                        {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
                    </div>
                )}

                <Col>
                    <Row align="middle" className="gap-15">
                        <Col className="hp-d-flex-center">
                            {!searchHeader ? (
                                <Button
                                    ghost
                                    type="primary"
                                    className="hp-border-none hp-hover-bg-black-10 hp-hover-bg-dark-100"
                                    icon={<SearchNormal1 className="hp-text-color-black-80 hp-text-color-dark-30" />}
                                    onClick={() => searchClick()}
                                />
                            ) : (
                                <Button
                                    ghost
                                    type="primary"
                                    className="hp-border-none hp-hover-bg-black-10 hp-hover-bg-dark-100"
                                    icon={<RiCloseLine size={24} className="hp-text-color-black-80 hp-text-color-dark-30" />}
                                    onClick={() => setSearchHeader(false)}
                                />
                            )}
                        </Col>
                        <Dropdown menu={{ items: addMenu }} placement="bottomRight">
                            <Button
                                ghost
                                type="primary"
                                className="hp-border-none hp-hover-bg-black-10 hp-hover-bg-dark-100"
                                icon={
                                    <PlusOutlined
                                        style={{ fontSize: 22, marginRight: 50, marginLeft: 50 }}
                                        className={`hp-text-color-black-80 hp-text-color-dark-30 ${styles.icon}`}
                                    />
                                }
                            />
                        </Dropdown>
                        <Dropdown menu={{ items: profileMenu }} placement="bottomRight">
                            {/*<div className={styles.imageContainer}>*/}
                            {/*    <img className={styles.image} src={user?.image || require('../../assets/user.png')} />*/}
                            {/*</div>*/}

                            <div
                                className="hp-border-radius-lg hp-overflow-hidden hp-bg-info-4 hp-m-4 hp-d-flex"
                                style={{ width: 32, height: 32, borderRadius: '50%' }}>
                                {user.profilePicUrl ? <img src={user.profilePicUrl} alt="User" height="100%" /> : <Avvvatars value={user.firstName} />}
                            </div>
                        </Dropdown>
                    </Row>
                </Col>
            </Row>
        );
    };

    return (
        <AntDHeader className={styles.newmargin}>
            <Row justify="center" className="hp-w-100">
                <Col span={24}>
                    <motion.div
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ type: 'spring', duration: 0.5, delay: 0.1 }}
                        className="hp-w-100">
                        {headerChildren()}
                    </motion.div>
                </Col>
            </Row>
        </AntDHeader>
    );
};

export default Header;
