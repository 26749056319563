import { useState } from 'react';
import { Modal } from 'antd';
import { RiCloseFill } from 'react-icons/ri';
import AddPhaseRow from '../AddPhaseRow';

const AddRowModal = ({ isVisible, hideModal, onSuccess, teamMemberId }) => {
    const [showAddRowModal, setShowAddRowModal] = useState(() => isVisible);

    const handleCancel = () => {
        setShowAddRowModal(false);
        hideModal();
    };

    return (
        <Modal
            title="Add Row"
            width={800}
            centered
            open={showAddRowModal}
            onCancel={handleCancel}
            footer={null}
            closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
            destroyOnClose={true}>
            <AddPhaseRow
                hideModal={handleCancel}
                onSuccess={(data) => {
                    onSuccess(data);
                    handleCancel();
                }}
                teamMemberId={teamMemberId}
                includeActivity
            />
        </Modal>
    );
};

export default AddRowModal;
