import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, Modal, Popconfirm, Row, Table } from 'antd';
import { BankOutlined, BuildOutlined, CloseOutlined, DollarOutlined, MoreOutlined, ProfileOutlined, UserOutlined } from '@ant-design/icons';

// redux
import { IStore } from '../../../redux/store';
import { getUserDetails } from '../../../redux/selectors/UserSelector';

// utils
import FormatUtils from '../../../utils/FormatUtils';
import GenUtils, { ENotificationType } from '../../../utils/GenUtils';
import NavigationUtils from '../../../utils/NavigationUtils';
import PermissionUtils from '../../../utils/PermissionUtils';
import IInvoice, { EInvoiceStatus } from '../../../interfaces/IInvoice';
import { InvoiceService } from '../../../services/InvoiceService';

// components
import Colors from '../../../constants/Colors';
import RecordPayment from '../RecordPayment';
import Tag from '../../../components/tag/Tag';
import FeatureCard from '../../../components/card/FeatureCard';

// style
import styles from '../../client/AllClientsPage.scss';
import Button from '../../../components/button/Button';
import { EFilterCategory, EFilterTypes } from '../../../components/filterBars/FiltersBarMultiselect';
import GroupedTable, { EColumnType } from '../../../components/table/groupedTable/GroupedTable';

const AllInvoices = () => {

    const [invoiceSearch, setInvoiceSearch] = useState(null);
    const [editingInvoice, setEditingInvoice] = useState(null);
    const [recordingPayment, setRecordingPayment] = useState(null);

    const firmId = useSelector((state: IStore) => state?.firm?.uuid);
    const projectsMap = useSelector((state: IStore) => state.project.projects);
    const invoices = useSelector((state: IStore) => state.invoices.invoices);

    let invoicesArray = Object.values(invoices)?.sort((a, b) => {
        return a.date < b.date ? 1 : -1;
    });

    const updateInvoiceStatus = async (invoiceId, status) => {
        const { data, error } = await InvoiceService.updateInvoiceStatus(invoiceId, status);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification(
                'Failed',
                error,
                <notificationIcon.icon style={notificationIcon.style} />
            );
            return;
        }

        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification(
            'Success',
            '',
            <notificationIcon.icon style={notificationIcon.style} />
        );
    }

    const duplicateInvoice = (invoiceId) => {

    }

    const deleteInvoice = async (invoiceId) => {
        const { data, error } = await InvoiceService.deleteInvoice(invoiceId);

        if (error) {
            const notificationIcon = GenUtils.getIconForNotification(ENotificationType.ERROR);
            GenUtils.showNotification(
                'Failed',
                error,
                <notificationIcon.icon style={notificationIcon.style} />
            );
            return;
        }

        const notificationIcon = GenUtils.getIconForNotification(ENotificationType.SUCCESS);
        GenUtils.showNotification(
            'Success',
            '',
            <notificationIcon.icon style={notificationIcon.style} />
        );
    }

    const canApproveThisInvoice = (invoice: IInvoice) => {
        // todo permission, fix this after testing
        if (PermissionUtils.canApproveInvoicesFor() === 'all') return true;
        if (PermissionUtils.canApproveInvoicesFor() === 'assigned') {
            // todo: check for assigned projects
            return true;
        }
        return false;
    }

    const getMenuItems = (invoice: IInvoice) => {

        return ([
            ...((invoice.status === EInvoiceStatus.Draft || invoice.status === EInvoiceStatus.Submitted) && PermissionUtils.canEditInvoices() ? [{
                key: 0,
                label: (
                    <span
                        onClick={() => setEditingInvoice(invoice.uuid)}>
                        Edit
                    </span>
                )
            }] : []),
            ...(invoice.remaining > 0 && (invoice.status === EInvoiceStatus.Pending || invoice.status === EInvoiceStatus.PartiallyPaid) ? [
                {
                    key: 1,
                    label: (
                        <span
                            onClick={() => setRecordingPayment(invoice.uuid)}>
                            Record Payment
                        </span>
                    )
                },
            ] : []),
            ...(invoice.total === invoice.remaining ? [{
                key: 2,
                label: (
                    <span
                        onClick={() => updateInvoiceStatus(invoice.uuid, EInvoiceStatus.Void)}>
                        Mark as Void
                    </span>
                )
            }] : []),
            {
                key: 3,
                label: (
                    <span
                        onClick={() => updateInvoiceStatus(invoice.uuid, EInvoiceStatus.WriteOff)}>
                        Write Off
                    </span>
                )
            },
            // {
            //     key: 4,
            //     label: (
            //         <Popconfirm
            //             title='Are you sure to make a duplicate of this Invoice?'
            //             onConfirm={() => duplicateInvoice(invoice.uuid)}
            //             okText='Yes'
            //             cancelText='No'
            //         >
            //             <Button>Make a Copy</Button>
            //         </Popconfirm>
            //     )
            // },
            {
                key: 5,
                label: (
                    <Popconfirm
                        title='Are you sure to delete this Invoice?'
                        onConfirm={() => deleteInvoice(invoice.uuid)}
                        okText='Yes'
                        cancelText='No'
                    >
                        <Button
                            danger
                            onClick={() => {}}
                            text='Delete Invoice'
                        />
                    </Popconfirm>
                )
            },
        ]);
    }

    const columns = [
        {
            title: 'Invoice #',
            dataIndex: 'serial',
            render: (serial, invoice) => {
                if (invoice) {
                    if (invoice.status === "draft" || invoice.status === "submitted") {
                        return (
                            <a onClick={() => NavigationUtils.navigateTo(`/${firmId}/invoices/build/${invoice.uuid}?invoiceId=${invoice.uuid}`)}>
                            {GenUtils.capitalizeInitials(serial)}
                            </a>
                        );
                    } else {
                        return (
                            <a onClick={() => NavigationUtils.navigateTo(`/${firmId}/invoices/preview/${invoice.uuid}?invoiceId=${invoice.uuid}`)}>
                                {GenUtils.capitalizeInitials(serial)}
                            </a>
                        );
                    }
                } else {
                    return null;
                }
            }
        },
        {
            title: 'Project #',
            dataIndex: 'projectId',
            render: (projectId) => <div style={{ display: 'flex', gap: 10 }}>
                <Tag text={projectsMap[projectId]?.serial} color={projectsMap[projectId]?.color} />
                {projectsMap[projectId]?.name}
            </div>,
            type: EColumnType.project,
        },
        {
            title: 'Invoice Amount',
            dataIndex: 'total',
            render: (x, invoice) => FormatUtils.getAmountInFormat((invoice?.total || 0) - (invoice?.discount || 0) + (invoice?.taxed || 0), true, 2, '0'),
            type: EColumnType.amount,
        },
        {
            title: 'Pending Amount',
            dataIndex: 'remaining',
            render: (x) => FormatUtils.getAmountInFormat(x, true, 2, '0'),
            type: EColumnType.amount,
        },
        {
            title: 'Paid Amount',
            dataIndex: 'paid',
            render: (x) => FormatUtils.getAmountInFormat(x, true, 2, '0'),
            type: EColumnType.amount,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (x) => <div style={{ display: 'flex' }}>
                <Tag text={x === EInvoiceStatus.PartiallyPaid ? 'Partially Paid' : x} color={GenUtils.getTagColorByStatus(x)} />
            </div>,
            type: EColumnType.status,
        },
        {
            title: 'Issue Date',
            dataIndex: 'date',
            render: (x) => FormatUtils.getFormattedDateFromTimestamp(x),
            type: EColumnType.date,
        },
        {
            title: 'Due Date',
            dataIndex: 'dueDate',
            render: (x) => FormatUtils.getFormattedDateFromTimestamp(x),
            type: EColumnType.date,

        },
        ...(PermissionUtils.canApproveInvoicesFor() !== 'none' ? [
            {
                title: '',
                dataIndex: 'action',
                render: (text, invoice) => (
                    <Button
                        type='primary'
                        disable={invoice.status !== EInvoiceStatus.Submitted || !canApproveThisInvoice(invoice)}
                        onClick={() => updateInvoiceStatus(invoice.uuid, EInvoiceStatus.Approved)}
                        text='Approve'
                        small
                    />
                ),
            },
        ] : []),
        {
            title: '',
            dataIndex: 'menu',
            key: 'menu',
            render: (text, invoice) => (
                <Dropdown menu={{ items: getMenuItems(invoice) }} trigger={['click']}>
                    <MoreOutlined />
                </Dropdown>
            )
        }
    ];
    const filters = [
        {
            name: 'Invoice #',
            dataIndex: 'serial',
            type: EFilterTypes.dropdown,
            category: EFilterCategory.simple,
        },
        {
            name: 'Invoice Status',
            dataIndex: 'status',
            type: EFilterTypes.dropdown,
            category: EFilterCategory.advance,
        },
        {
            name: 'Project',
            dataIndex: 'projectId',
            type: EFilterTypes.dropdown,
            category: EFilterCategory.advance,
        },
        {
            name: 'Client',
            dataIndex: 'clientId',
            type: EFilterTypes.dropdown,
            category: EFilterCategory.advance,
        },
        {
            name: 'Project Status',
            dataIndex: 'project.status',
            type: EFilterTypes.dropdown,
            category: EFilterCategory.advance,
        },
        {
            name: 'Issue Date Range',
            dataIndex: 'date',
            type: EFilterTypes.dateRange,
            category: EFilterCategory.advance
        },
        {
            name: 'Due Date Range',
            dataIndex: 'dueDate',
            type: EFilterTypes.dateRange,
            category: EFilterCategory.simple
        }
    ]

    const GroupByFilters = [
        {
            name: 'Client',
            options: [
                {
                    name: 'Any Status',
                    uuid: 'any'
                },
                {
                    name: 'Active',
                    uuid: 'active'
                },
                {
                    name: 'Inactive',
                    uuid: 'inactive'
                }
            ]
        },
        {
            name: 'Export',
            options: [
                {
                    name: 'Any Status',
                    uuid: 'any'
                },
                {
                    name: 'Active',
                    uuid: 'active'
                },
                {
                    name: 'Inactive',
                    uuid: 'inactive'
                }
            ]
        }];

    const groups = [
        { title: 'Project', dataIndex: 'projectId', type: EColumnType.project },
        { title: 'Client', dataIndex: 'clientId', type: EColumnType.client },
        { title: 'Issued Month', dataIndex: 'month', type: EColumnType.month },
        { title: 'Aging', dataIndex: 'remDays', type: EColumnType.remDays },
    ]
    const renderCards=(filteredData)=>{
        return (
            <div className={styles.cardContainer}>
                <FeatureCard
                    title={FormatUtils.getAmountInFormat(Number(filteredData?.reduce((acc: number, p: IInvoice) => (acc + p.remaining || 0), 0)), true, 2, 0)}
                    subtitle='Total pending amount' Icon={DollarOutlined}
                />
                <FeatureCard
                    title={FormatUtils.getAmountInFormat(Number(filteredData?.reduce((acc: number, p: IInvoice) => p.status !== EInvoiceStatus.Paid ? acc + 1 : acc, 0)), false, 0, 0)}
                    subtitle='# Pending Invoices'
                    Icon={ProfileOutlined}
                />
                <FeatureCard
                    title={Object.keys(filteredData?.reduce((acc, p: IInvoice) => p.remaining ? { ...acc, [p.projectId]: true } : acc, {})).length || 0}
                    subtitle='# Projects with pending amounts'
                    Icon={BankOutlined}
                />
                <FeatureCard
                    title={Object.keys(filteredData?.reduce((acc, p: IInvoice) => p.remaining ? { ...acc, [p.clientId]: true } : acc, {})).length || 0}
                    subtitle='# Clients who owe'
                    Icon={UserOutlined}
                />
            </div>
        )
        
    }
    return (
        <div>
            <GroupedTable columns={columns} data={invoicesArray.map((invoice) => ({
                ...invoice,
                odStatus: (FormatUtils.getOverDueStatus(invoice.dueDate, invoice.status)),
                remDays: (FormatUtils.getRemDaysInFormat(invoice.dueDate)),
                month: FormatUtils.getMonthAndYearFromDateTime(invoice.date)
            }))} filters={filters} groups={groups} renderCards={renderCards}/>
            <Modal
                title={`Payment for ${invoices[recordingPayment]?.serial}`}
                width={500}
                centered
                footer={null}
                open={recordingPayment}
                onCancel={() => setRecordingPayment(null)}
                closeIcon={<CloseOutlined style={{ color: Colors.red }} className="remix-icon text-color-black-100" size={24} />}
            >
                <RecordPayment
                    onHide={() => setRecordingPayment(null)}
                    invoice={invoices[recordingPayment]}
                />
            </Modal>
        </div>
    );
};

export default AllInvoices;