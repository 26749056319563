import IProject from '../../interfaces/IProject';

export interface IProjectState {
    projects: {};
    projectsByClient: {};
    projectsArray: IProject[];
}

export const initialProjectState: IProjectState = {
    projects: {},
    projectsByClient: {},
    projectsArray: []
};

const projectReducer = (state: IProjectState, action: { type: string; payload }) => {
    switch (action.type) {
        case 'SET_PROJECTS':
            return {
                ...state,
                projects: {
                    ...state.projects,
                    ...action.payload.projects
                },
                projectsByClient: {
                    ...state.projectsByClient,
                    ...(action.payload.clientId
                        ? { [action.payload.clientId]: action.payload.projects }
                        : action.payload.projectsArray.reduce(
                              (x, p) => ({
                                  ...x,
                                  [p.clientId]: {
                                      ...(x[p.clientId] || {}),
                                      [p.uuid]: p
                                  }
                              }),
                              {}
                          ))
                },
                projectsArray: action.payload.projectsArray
            };
        case 'UPDATE_PROJECT':
            return {
                ...state,
                projects: {
                    ...state.projects,
                    [action.payload.uuid]: action.payload
                },
                projectsByClient: {
                    ...state.projectsByClient,
                    ...(action.payload.clientId
                        ? {
                              [action.payload.clientId]: {
                                  ...state.projectsByClient[action.payload.clientId],
                                  [action.payload.uuid]: {
                                      ...(state.projectsByClient[action.payload.clientId][action.payload.uuid]
                                          ? state.projectsByClient[action.payload.clientId][action.payload.uuid]
                                          : {}),
                                      ...action.payload
                                  }
                              }
                          }
                        : {})
                }
            };
        default:
            return state || initialProjectState;
    }
};

export default projectReducer;
