import { Popover } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomInput from './CustomInput';
import NotePopOver from '../../pages/timesheet/NotePopOver';
import { IStore } from '../../redux/store';
import FormatUtils from '../../utils/FormatUtils';

interface TimesheetPopOverInputProps {
    onChange: (keyName: string, value: number | string) => void;
    value: {
        time: number;
        note: string;
    };
    name: string;
    onSave: (seconds: number, note?: string) => void;
}

const TimesheetPopOverInput = ({ onChange, value, name, onSave }: TimesheetPopOverInputProps) => {
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState(value?.time);
    const { isLocked } = useSelector((state: IStore) => state.timeLogs.timesheet);

    useEffect(() => {
        setInputValue(value?.time);
    }, [value]);

    const handleChange = (keyName: string, value: number | string) => {
        setInputValue(Number(value));
        onChange(keyName, value);
        if (keyName.includes('time')) {
            onSave(Number(value)); 
        } else {
            onSave(undefined, typeof value === 'string' ? value : value.toString())
        }
    };

    const showCurrency = false;
    const maximumFractionDigits = 2;

    return (
        <Popover
            trigger="hover"
            open={open}
            onOpenChange={(e) => setOpen(e)}
            placement="bottom"
            content={<NotePopOver value={value?.note} onChange={(value) => handleChange(`${name}.note`, value)} />}>
            <div>
                <CustomInput
                    type="number"
                    value={FormatUtils.getAmountInFormat(inputValue, showCurrency, maximumFractionDigits)}
                    disabled={isLocked}
                    style={{ width: '100%', ...(inputValue < value?.time ? { borderColor: 'red' } : {}) }}
                    onChange={(e) => handleChange(`${name}.time`, Number(e))}
                    updateOnBlur
                />
            </div>
        </Popover>
    );
};

export default TimesheetPopOverInput;
