import { useSelector } from 'react-redux';
import HeaderSecondary from '../../components/header/HeaderSecondary';
import styles from './AllReportsPage.scss';
import { IStore } from '../../redux/store';
import GenUtils, { groupData } from '../../utils/GenUtils';
import GroupedTable, { EColumnType } from '../../components/table/groupedTable/GroupedTable';
import { useState } from 'react';
import { CaretDownOutlined, HeartFilled, HeartOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import Colors from '../../constants/Colors';
import OptionService from '../../services/OptionService';
import { EOptionType } from '../../redux/reducers/OptionReducer';
import SwitchTabs from '../../components/tabs/SwitchTabs';
import NavigationUtils from '../../utils/NavigationUtils';
import { EFilterCategory, EFilterTypes } from '../../components/filterBars/FiltersBarMultiselect';
import FiltersBar from '../../components/filterBars/FiltersBar';
import { Table } from 'antd';

const AllReportsPage = () => {
    const [liked, setLiked] = useState(false);
    const reports = useSelector((state: IStore) => state.options.report);
    const firmId = useSelector((state: IStore) => state.firm.firm.uuid);
    const groupedReports = groupData('unit', liked ? reports.filter(r => r.marked) : reports);
    const groups = useSelector((state: IStore) => state.options.report && Object.keys({ ...groupedReports }).sort((a, b) => a.localeCompare(b)));

    const [openGroup, setOpenGroup] = useState('');

    const updateReport = async (reportId, marked) => {
        OptionService.updateOption(reportId, { marked }, EOptionType.report);
    }

    const getIfMarked = (report) => groupedReports[report.unit].find(r => r.uuid === report.uuid)?.marked;

    const goToReport = (name) => {
        let x = GenUtils.getSimpleStringKey(name);
        switch (x) {
            case 'project_performance': 
                NavigationUtils.navigateTo(`/${firmId}/invoices/payments?group=project`);
                return;
            case 'client_performance': 
                NavigationUtils.navigateTo(`/${firmId}/invoices/payments?group=client`);
                return;
            default: NavigationUtils.navigateTo(`/${firmId}/reports/${x}`);
        }
    }
    
    const columns = [
        {
            title: 'Report',
            dataIndex: 'name',
            type: EColumnType.link,
            onClick: (data) => goToReport(data.name),
        }, {
            title: 'Description',
            dataIndex: 'description',
            type: EColumnType.text
        }, {
            title: '',
            dataIndex: 'marked',
            render: (m, report) => <div onClick={() => updateReport(report.uuid, !getIfMarked(report))} style={{ fontSize: 16 }}>
                {
                    getIfMarked(report) ? <HeartFilled style={{ color: Colors.red }} /> : <HeartOutlined color={Colors.red} />
                }
            </div>
        }
    ];
    const renderReportGroup = (group) => {
        const isOpen = openGroup === group;
        const reportArray = groupedReports[group];
        
        return (
            <div className={styles.groupContainer} key={group}>
                <div className={styles.header} onClick={() => setOpenGroup(isOpen ? '' : group)}>
                    <div className={styles.title}>{group}</div>
                    <div className={classnames(styles.icon, isOpen && styles.open)}>
                        <CaretDownOutlined />
                    </div>
                </div>
                {
                    isOpen && (
                        <div className={styles.body}>
                            {/* <FiltersBar filters={filters} selectedFilters={{}} /> */}
                            <GroupedTable data={reportArray.sort((a, b) => a.name.localeCompare(b.name))} 
                             columns={columns} pagination={false} />
                        </div>
                    )
                }
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <HeaderSecondary 
                text={'Reports'}
                renderAction={() => (
                    <SwitchTabs a='Liked' b='All' onChange={setLiked} initialValue={'All'}/>
                )}
            />
            <div className={styles.groups}>
            {
                groups.length 
                    ? groups.map(renderReportGroup) 
                    : <div style={{margin: 40}}>No reports here yet...</div>
            }
            </div>
        </div>
    )
}

export default AllReportsPage;